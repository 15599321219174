<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-4 col-sm-12">
        <table class="table table-bordered table-striped">
          <thead>
            <th colspan="4" class="text-center">Insurance</th>
          </thead>
          <thead>
            <th></th>
            <th class="text-center">OPD</th>
            <th class="text-center">IPD</th>
            <th class="text-center">TOTAL</th>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">LOCAL</td>
              <td class="text-center">{{ insuranceData[0].opd_quantity.toLocaleString() }}</td>
              <td class="text-center">{{ insuranceData[0].ipd_quantity.toLocaleString() }}</td>
              <td class="text-center">{{ insuranceData[0].quantity.toLocaleString() }}</td>
            </tr>
            <tr>
              <td class="text-center">INTER</td>
              <td class="text-center">{{ insuranceData[1].opd_quantity.toLocaleString() }}</td>
              <td class="text-center">{{ insuranceData[1].ipd_quantity.toLocaleString() }}</td>
              <td class="text-center">{{ insuranceData[1].quantity.toLocaleString() }}</td>
            </tr>
            <tr>
              <td class="text-center"></td>
              <td class="text-center text-red">{{ (insuranceData[0].opd_quantity + insuranceData[1].opd_quantity).toLocaleString() }}</td>
              <td class="text-center text-blue">{{ (insuranceData[0].ipd_quantity + insuranceData[1].ipd_quantity).toLocaleString() }}</td>
              <td class="text-center"></td>
            </tr>
            <tr>
              <td class="text-center" colspan="4">TOTAL {{ (insuranceData[0].quantity+insuranceData[1].quantity).toLocaleString() }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-4 col-sm-12">
        <h5 class="text-primary">Local Cases</h5>
        <Doughnut  :chart-options="chartOptions" :chart-data="localChart" :dataset-id-key="'local'" :chart-id="'insurance-local'" :height="300" />
      </div>
      <div class="col-md-4 col-sm-12">
        <h5 class="text-primary">International Cases </h5>
        <Doughnut  :chart-options="chartOptions" :chart-data="interChart" :dataset-id-key="'inter'" :chart-id="'insurance-inter'" :height="300" />
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-4 col-sm-12">
        <h5 class="text-primary">OPD and IPD</h5>
        <Bar  :chart-options="barChartOptions" :chart-data="totalChart" :dataset-id-key="'total'" :chart-id="'total-graph'"
        :width="150" :height="300" />
      </div>
      <div class="col-md-4 col-sm-12">
        <h5 class="text-primary">IPD Cases</h5>
        <Doughnut  :chart-options="chartOptions" :chart-data="ipdChart" :dataset-id-key="'ipd'" :chart-id="'ipd-local'" :height="300" />
      </div>
      <div class="col-md-4 col-sm-12">
        <h5 class="text-primary">OPD Cases </h5>
        <Doughnut  :chart-options="chartOptions" :chart-data="opdChart" :dataset-id-key="'opd'" :chart-id="'opd-inter'" :height="300" />
      </div>
    </div>
  </div>
</template>

<script>
import color from '../../assets/color.json'
import {db} from '../../db'
import {
  Doughnut,
  Bar
} from 'vue-chartjs/legacy'

const pallete = {
  opd_local: `rgba(255, 99, 132, 0.4)`,
  ipd_local: `rgba(255, 99, 132, 0.7)`,
  opd_inter: `rgba(54, 162, 235, 0.4)`,
  ipd_inter: `rgba(54, 162, 235, 0.7)`,
  total: `rgba(255, 205, 86, 0.5)`
}

export default {
  props: ['payments'],
  components: {
    Doughnut,
    Bar
  },
  data() {
    return {
      chartOptions: {
        maintainAspectRatio: false,
        maxHeight: 300,
        plugins: {
          datalabels: {
            color: '#000',
            anchor: 'center',
            align: 'center',
            formatter: (value, context) => {
              const dataset = context.dataset;
              const total = dataset.data.reduce((sum, currentValue) => sum + currentValue, 0);
              const percentage = ((value / total) * 100).toFixed(2);
              return `${value.toLocaleString()} \n(${Math.round(percentage)}%)`;
            }
          },
        }
      },
      barChartOptions: {
        maintainAspectRatio: false,
        maxHeight: 300,
        plugins: {
          datalabels: {
            color: '#000',
            anchor: 'center',
            align: 'center',
            formatter: (value, context) => {
              const dataset = context.dataset;
              const total = dataset.data.reduce((sum, currentValue) => sum + currentValue, 0);
              const percentage = ((value / total) * 100).toFixed(2);
              return `${value.toLocaleString()} \n(${Math.round(percentage)}%)`;
            }
          },
        }
      },
      preparedPayments: []
    }
  },
  watch: {
    payments: {
      immediate: true,
      async handler() {
        let loader = this.$loading.show({})

        this.preparedPayments = await Promise.all(this.payments.map(async (item) => {
          let p = await db.collection('Patient').doc(item.HN).get()
          if (p.data().is_resident) {
            item.p_type = 'local'
          } else {
            item.p_type = 'inter'
          }

          let i = await db.collection('Invoice').doc(item.invoice_id).get()
          if (i.data().type === 'OPD') {
            item.i_type = 'OPD'
          } else if( i.data().type=== 'IPD'){
            item.i_type = 'IPD'
          } else {
            item.i_type = 'Other'
          }

          return item
        }))

        loader.hide()
      },
    }
  },
  computed: {
    insuranceData() {
      let data = [
        {
          index: 0,
          label: 'local',
          quantity: 0,
          opd_quantity: 0,
          ipd_quantity: 0
        },
        {
          index: 1,
          label: 'inter',
          quantity: 0,
          opd_quantity: 0,
          ipd_quantity: 0
        },
      ]

      for (let item of this.preparedPayments) {
        
        if(item.insurance > 0) {

          if(item.p_type === 'local' && item.i_type) {
            data[0].quantity += item.insurance
            if(item.i_type === 'OPD') data[0].opd_quantity += item.insurance
            if(item.i_type === 'IPD') data[0].ipd_quantity += item.insurance
          }else if (item.p_type === 'inter' && item.i_type) {
            data[1].quantity += item.insurance
            if(item.i_type === 'OPD') data[1].opd_quantity += item.insurance
            if(item.i_type === 'IPD') data[1].ipd_quantity += item.insurance
          }

        }
      }

      return data
    },
    localChart() {

      let chartData = {
        labels: ['OPD', 'IPD'],
        datasets: [{
          label: 'Local',
          data: [this.insuranceData[0].opd_quantity, this.insuranceData[0].ipd_quantity],
          backgroundColor: [pallete.opd_local, pallete.ipd_local]
        }]
      }

      return chartData
    },
    interChart() {

      let chartData = {
        labels: ['OPD', 'IPD'],
        datasets: [{
          label: 'Inter',
          data: [this.insuranceData[1].opd_quantity, this.insuranceData[1].ipd_quantity],
          backgroundColor: [pallete.opd_inter, pallete.ipd_inter]
        }]
      }

      return chartData
    },
    opdChart() {

      let chartData = {
        labels: ['LOCAL', 'INTER'],
        datasets: [{
          label: 'OPD',
          data: [this.insuranceData[0].opd_quantity, this.insuranceData[1].opd_quantity],
          backgroundColor: [pallete.opd_local, pallete.opd_inter]
        }]
      }

      return chartData
    },
    ipdChart() {

      let chartData = {
        labels: ['LOCAL', 'INTER'],
        datasets: [{
          label: 'IPD',
          data: [this.insuranceData[0].ipd_quantity, this.insuranceData[1].ipd_quantity],
          backgroundColor: [pallete.ipd_local, pallete.ipd_inter]
        }]
      }

      return chartData
    },
    totalChart() {
      let chartData = {
        labels: ['LOCAL', ' INTER'],
        datasets: [{
          label: 'OPD',
          data: [this.insuranceData[0].opd_quantity, this.insuranceData[1].opd_quantity],
          backgroundColor: [pallete.opd_local, pallete.opd_inter]
        },{
          label: 'IPD',
          data: [this.insuranceData[0].ipd_quantity, this.insuranceData[1].ipd_quantity],
          backgroundColor: [pallete.ipd_local, pallete.ipd_inter]
        },{
          label: 'TOTAL',
          data: [this.insuranceData[0].quantity, this.insuranceData[1].quantity],
          backgroundColor: [pallete.total,pallete.total]
        }]
      }

      return chartData
    }
  }
}
</script>