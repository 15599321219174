<template>
  <b-modal :id="id" title="Admit" size="xl" hide-footer>
    <div class="mb-2">
      <div class="container-fluid">
        WIP.
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary mr-2">Confirm Order</button>
      <button class="btn btn-danger mr-2" @click.prevent="$bvModal.hide(id)">Cancel</button>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      id: 'order-admit'
    }
  },
  props: {
    onClose: {
      default: () => {}
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === this.id)
      this.onClose()
    })
  }
}
</script>