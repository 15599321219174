import { sum } from 'lodash'

export const getTotalDiscount = (invoice) => {
  const productDiscount = sum(invoice.product_items.map(item => {
    if (item.quantity && item.price) {
      return Number(item.quantity) * Number(item.price) * Number(item.discount) / 100
    } else {
      return 0
    }
  }))
  const billableItemsDiscount = sum(invoice.billable_items.map(item => {
    if (item.quantity && item.price) {
      return Number(item.quantity) * Number(item.price) * Number(item.discount) / 100
    } else {
      return 0
    }
  }))

  return billableItemsDiscount + productDiscount
}