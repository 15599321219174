<template>
  <div>
    <div class="row p-2">
      <div class="col-12">
        <h3 class="text-primary text-center">Demographic</h3>
        <hr>
      </div>
      <div class="col-12">
        <div class="d-flex">
          <label>between: </label>
          <MazPicker v-model="from" clearable no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" />
          <label>and</label>
          <MazPicker v-model="to" clearable no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" />
        </div>
      </div>
    </div>

    <b-nav tabs justified>
      <b-nav-item @click="(currentMenu=menu.name)" v-for="(menu, index) in menus" :key="index" :active="menu.name===currentMenu">{{menu.name}}</b-nav-item>
    </b-nav>

    <h3 class="text-center" v-if="currentMenu==='Age Range'">Age Range</h3>
    <div class="row p-2" v-if="currentMenu==='Age Range'">
      <div class="col-6 mt-2">
        <table class="table table-bordered">
          <thead>
            <th></th>
            <th># of Patients</th>
          </thead>
          <tr v-for="(label, index) in ageData.labels" :key="label">
            <td>{{  label  }}</td>
            <td>{{  ageData.datasets[0].data[index]  }}</td>
          </tr>
          <tr>
            <td>SUM</td>
            <td>{{ ageData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
          </tr>
        </table>
      </div>
      <div class="col-6 mt-2">
        <Pie :chart-options="chartOptions" :chart-data="ageData" :dataset-id-key="'label'"
          :chart-id="'patients_number_chart'" :width="400" :height="400" />
      </div>
    </div>

    <h3 class="text-center" v-if="currentMenu==='Religion'">Religion</h3>
    <div class="row p-2" v-if="currentMenu==='Religion'">
      <div class="col-6 mt-2">
        <table class="table table-bordered">
          <thead>
            <th></th>
            <th># of Patients</th>
          </thead>
          <tr v-for="(label, index) in religionData.labels" :key="label">
            <td>{{  label  }}</td>
            <td>{{  religionData.datasets[0].data[index]  }}</td>
          </tr>
          <tr>
            <td>SUM</td>
            <td>{{ religionData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
          </tr>
        </table>
      </div>
      <div class="col-6 mt-2">
        <Pie :chart-options="chartOptions" :chart-data="religionData" :dataset-id-key="'label'"
          :chart-id="'patients_number_chart'" :width="400" :height="400" />
      </div>
    </div>

    <h3 class="text-center" v-if="currentMenu==='Gender'">Gender</h3>
    <div class="row p-2" v-if="currentMenu==='Gender'">
      <div class="col-6 mt-2">
        <table class="table table-bordered">
          <thead>
            <th></th>
            <th># of Patients</th>
          </thead>
          <tr v-for="(label, index) in genderData.labels" :key="label">
            <td>{{  label  }}</td>
            <td>{{  genderData.datasets[0].data[index]  }}</td>
          </tr>
          <tr>
            <td>SUM</td>
            <td>{{ genderData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
          </tr>
        </table>
      </div>
      <div class="col-6 mt-2">
        <Pie :chart-options="chartOptions" :chart-data="genderData" :dataset-id-key="'label'"
          :chart-id="'patients_number_chart'" :width="400" :height="400" />
      </div>
    </div>

    <h3 class="text-center" v-if="currentMenu==='Marital Status'">Marital Status Statistics</h3>
    <div class="row p-2" v-if="currentMenu==='Marital Status'">
      <div class="col-6 mt-2">
        <table class="table table-bordered">
          <thead>
            <th></th>
            <th># of Patients</th>
          </thead>
          <tr v-for="(label, index) in maritalData.labels" :key="label">
            <td>{{  label  }}</td>
            <td>{{  maritalData.datasets[0].data[index]  }}</td>
          </tr>
          <tr>
            <td>SUM</td>
            <td>{{ maritalData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
          </tr>
        </table>
      </div>
      <div class="col-6 mt-2">
        <Pie :chart-options="chartOptions" :chart-data="maritalData" :dataset-id-key="'label'"
          :chart-id="'patients_number_chart'" :width="400" :height="400" />
      </div>
    </div>

    <h3 class="text-center" v-if="currentMenu==='Nationality'">Nationality Statistics</h3>
    <div class="row p-2" v-if="currentMenu==='Nationality'">
      <div class="col-6 mt-2">
        <table class="table table-bordered">
          <thead>
            <th></th>
            <th># of Patients</th>
          </thead>
          <tr v-for="(label, index) in nationalityData.labels" :key="label">
            <td>{{  label  }}</td>
            <td>{{  nationalityData.datasets[0].data[index]  }}</td>
          </tr>
          <tr>
            <td>SUM</td>
            <td>{{ nationalityData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
          </tr>
        </table>
      </div>
      <div class="col-6 mt-2">
        <Pie :chart-options="chartOptions" :chart-data="nationalityData" :dataset-id-key="'label'"
          :chart-id="'patients_number_chart'" :width="400" :height="400" />
      </div>
    </div>


  </div>
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'
import color from '../../assets/color.json'
import Chart from 'chart.js/auto';
import religions from '../../assets/religions.json'
import nationalities from '../../assets/nationalities.json'
const maritalStatus = [
  { name_en: "Single", name_th: "โสด" },
  { name_en: "Married", name_th: "แต่งงาน" },
  { name_en: "Engaged", name_th: "หมั้น" },
  { name_en: "Seperated", name_th: "แยกทาง" },
  { name_en: "Divorced", name_th: "หย่า" },
  { name_en: "Widowed", name_th: "หม้าย" }
];

const demoLabels = [
  {
    label: "Baby (0-4)",
    min: 0,
    max: 5
  },
  {
    label: "Child (5-12)",
    min: 5,
    max: 13
  },
  {
    label: "Teen (13-18)",
    min: 13,
    max: 19
  },
  {
    label: "Young (19-34)",
    min: 19,
    max: 35
  },
  {
    label: "Middle Age (35-54)",
    min: 35,
    max: 55
  },
  {
    label: "Old Age (55+)",
    min: 55,
    max: 200
  },
]

export default {
  props: ['patients'],
  components: {
    Pie
  },
  data() {
    return {
      from: this.$moment().add(-1, 'month').format('DD/MM/YYYY'),
      to: this.$moment().format('DD/MM/YYYY'),
      chartOptions: {
        responsive: true
      },
      currentMenu: 'Age Range',
      menus: [
        { name: 'Age Range' },
        { name: 'Religion' },
        { name: 'Gender' },
        { name: 'Marital Status' },
        { name: 'Nationality' },
      ]
    }
  },
  computed: {
    ageData() {

      let filteredPatients = []

      let fromMoment = this.$moment(this.from, 'DD/MM/YYYY')
      let toMoment = this.$moment(this.to, 'DD/MM/YYYY')

      for(let p of this.patients) {
        if (p.created_at && typeof p.created_at !== 'string') {
          if (
              this.$moment(p.created_at.toDate()).isSameOrBefore(toMoment) && 
              this.$moment(p.created_at.toDate()).isSameOrAfter(fromMoment)
            ) filteredPatients.push(p)
        }
      }

      //label
      let labels = demoLabels.map(l => l.label)
      let data = [0, 0, 0, 0, 0, 0]

      demoLabels.forEach((item, index) => {
        for (let p of filteredPatients) {
          if(p.date_of_birth && typeof p.date_of_birth !== 'string') {
            let age = this.$moment().diff(this.$moment(p.date_of_birth.toDate()), 'year' )
            if(age >= item.min && age < item.max) data[index]++
          }
        }
      })

      let sortedData = []
      for(let i = 0;  i < data.length; i++) {
        sortedData.push({
          label: labels[i],
          value: data[i]
        })
      }


      return {
        labels: this._.orderBy(sortedData, 'value', 'desc').map( obj => obj.label),
        datasets: [
          {
            backgroundColor: color,
            data: this._.orderBy(sortedData, 'value', 'desc').map( obj => obj.value)
          }
        ]
      }
    },
    religionData() {

      let filteredPatients = []

      let fromMoment = this.$moment(this.from, 'DD/MM/YYYY')
      let toMoment = this.$moment(this.to, 'DD/MM/YYYY')

      for(let p of this.patients) {
        if (p.created_at && typeof p.created_at !== 'string') {
          if (
              this.$moment(p.created_at.toDate()).isSameOrBefore(toMoment) && 
              this.$moment(p.created_at.toDate()).isSameOrAfter(fromMoment)
            ) filteredPatients.push(p)
        }
      }

      //label
      let labels = religions.map(l => l.name_en)
      let data = []

      religions.forEach((item, index) => {
        let count = 0
        for (let p of filteredPatients) {
          if(p.religion === item.name_en) {
            count++
          }
        }
        data.push(count)
      })

      let sortedData = []
      for(let i = 0;  i < data.length; i++) {
        sortedData.push({
          label: labels[i],
          value: data[i]
        })
      }


      return {
        labels: this._.orderBy(sortedData, 'value', 'desc').map( obj => obj.label),
        datasets: [
          {
            backgroundColor: color,
            data: this._.orderBy(sortedData, 'value', 'desc').map( obj => obj.value)
          }
        ]
      }
    },
    genderData() {

      let filteredPatients = []

      let fromMoment = this.$moment(this.from, 'DD/MM/YYYY')
      let toMoment = this.$moment(this.to, 'DD/MM/YYYY')

      for(let p of this.patients) {
        if (p.created_at && typeof p.created_at !== 'string') {
          if (
              this.$moment(p.created_at.toDate()).isSameOrBefore(toMoment) && 
              this.$moment(p.created_at.toDate()).isSameOrAfter(fromMoment)
            ) filteredPatients.push(p)
        }
      }

      //label
      let labels = ["Male", "Female"]
      let data = []

      labels.forEach((item, index) => {
        let count = 0
        for (let p of filteredPatients) {
          if(p.gender === item) {
            count++
          }
        }
        data.push(count)
      })

      let sortedData = []
      for(let i = 0;  i < data.length; i++) {
        sortedData.push({
          label: labels[i],
          value: data[i]
        })
      }


      return {
        labels: this._.orderBy(sortedData, 'value', 'desc').map( obj => obj.label),
        datasets: [
          {
            backgroundColor: color,
            data: this._.orderBy(sortedData, 'value', 'desc').map( obj => obj.value)
          }
        ]
      }
    },
    maritalData() {

      let filteredPatients = []

      let fromMoment = this.$moment(this.from, 'DD/MM/YYYY')
      let toMoment = this.$moment(this.to, 'DD/MM/YYYY')

      for(let p of this.patients) {
        if (p.created_at && typeof p.created_at !== 'string') {
          if (
              this.$moment(p.created_at.toDate()).isSameOrBefore(toMoment) && 
              this.$moment(p.created_at.toDate()).isSameOrAfter(fromMoment)
            ) filteredPatients.push(p)
        }
      }

      //label
      let labels = maritalStatus.map(l => l.name_en)
      let data = []

      labels.forEach((item, index) => {
        let count = 0
        for (let p of filteredPatients) {
          if(p.marital_status === item) {
            count++
          }
        }
        data.push(count)
      })

      let sortedData = []
      for(let i = 0;  i < data.length; i++) {
        sortedData.push({
          label: labels[i],
          value: data[i]
        })
      }


      return {
        labels: this._.orderBy(sortedData, 'value', 'desc').map( obj => obj.label),
        datasets: [
          {
            backgroundColor: color,
            data: this._.orderBy(sortedData, 'value', 'desc').map( obj => obj.value)
          }
        ]
      }
    },
    nationalityData() {

      let filteredPatients = []

      let fromMoment = this.$moment(this.from, 'DD/MM/YYYY')
      let toMoment = this.$moment(this.to, 'DD/MM/YYYY')

      for(let p of this.patients) {
        if (p.created_at && typeof p.created_at !== 'string') {
          if (
              this.$moment(p.created_at.toDate()).isSameOrBefore(toMoment) && 
              this.$moment(p.created_at.toDate()).isSameOrAfter(fromMoment)
            ) filteredPatients.push(p)
        }
      }

      //label
      let labels = nationalities
      let data = []

      labels.forEach((item, index) => {
        let count = 0
        for (let p of filteredPatients) {
          if(p.nationality === item) {
            count++
          }
        }
        data.push(count)
      })

      let sortedData = []
      for(let i = 0;  i < data.length; i++) {
        if(data[i] !== 0) {
          sortedData.push({
            label: labels[i],
            value: data[i]
          })
        }
      }


      return {
        labels: this._.orderBy(sortedData, 'value', 'desc').map( obj => obj.label),
        datasets: [
          {
            backgroundColor: color,
            data: this._.orderBy(sortedData, 'value', 'desc').map( obj => obj.value)
          }
        ]
      }
    },
  }
}
</script>