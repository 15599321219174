<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <div class="bg-white">
          <div class="pd10">
            <div>
              <transfer-header />
            </div>
            <div class="text-center mt-2">
              <span class="label-h">Medical Transport consent</span>
            </div>
            <div class="mt-5 transfer-txt">
              <p>
                I, (Mr./Mrs./Miss) <u> John Hasan </u> ,being <u> 28 </u> years
                of age.
              </p>
              <p>
                Holding Passport No./ ID card no./ Driving License
                <u> 1100500000000 </u>
              </p>
              <p>
                I voluntarily request the hospital’s authorized personnel to
                take care of my condition.
              </p>
              <p>
                I understand that the following process of medical transport is
                planned for me and I voluntarily consent to, and authorize this
                procedure:
              </p>
              <div class="d-flex">
                <div style="width: 140px;">
                  <span><b>Transport by:</b></span>
                </div>
                <div class="pl-3" style="width: 400px;">
                  <select class="form-control">
                    <option value disabled selected hidden></option>
                    <option
                      :value="dd.name"
                      v-for="(dd, index) in dropdown_transpot_by"
                      :key="index"
                      >{{ dd.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="d-flex mt-3">
                <div style="width: 140px;">
                  <span><b>From:</b></span>
                </div>
                <div class="pl-3" style="width: 300px;">
                  <select class="form-control">
                    <option value disabled selected hidden></option>
                    <option
                      :value="dd.name"
                      v-for="(dd, index) in dropdown_transpot_form"
                      :key="index"
                      >{{ dd.name }}</option
                    >
                  </select>
                </div>
                <div class="pl-5">
                  <span><b>To:</b></span>
                </div>
                <div class="pl-2" style="width: 300px;">
                  <select class="form-control">
                    <option value disabled selected hidden></option>
                    <option
                      :value="dd.name"
                      v-for="(dd, index) in dropdown_transpot_to"
                      :key="index"
                      >{{ dd.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="d-flex mt-3">
                <div style="width: 140px;">
                  <span><b>Escort by:</b></span>
                </div>

                <div class="pl-3" style="width: 300px;">
                  <select class="form-control">
                    <option value disabled selected hidden></option>
                    <option
                      :value="dd.name"
                      v-for="(dd, index) in dropdown_escort_by"
                      :key="index"
                      >{{ dd.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="mt-2">
                <p>
                  The medical risk and liabilities associated with
                  transportation have been explained by the physician.
                </p>
              </div>
              <div class="form-check" style="padding-left: 90px;">
                <input
                  class="form-check-input"
                  type="radio"
                  name="refuse-transport"
                  id="refuse-transport"
                  value="refuse-transport"
                />
                <label class="form-check-label pl-2" for="refuse-transport"
                  ><b>Refuse Transportation at this time.</b></label
                >
              </div>
              <div class="mt-3">
                <p>
                  I hereby acknowledge and confirm that I have been fully
                  informed by the physician and/or medical staff of Worldmed
                  Center about the transport process including indications,
                  benefits, possible risks, side effects and alternatives
                  needed. I have had an opportunity to discuss and receive
                  answers to all questions. I also understand that:
                </p>
              </div>
              <div style="padding-left: 90px;">
                <p>
                  -Limited space, noise, vibration, alteration of climate and
                  altitude is anticipated during transport.
                </p>
                <p>
                  -There is a risk of vehicle delay or malfunction caused by
                  unexpected events which can’t be controlled by medical staff.
                </p>
                <p>
                  -There is a risk of vehicle accident according to mode of
                  transport and traffic condition.
                </p>
                <p>
                  -I authorize the medical staff to access any of my medical
                  record that are pertinent to my treatment or condition for
                  this episode of transport.
                </p>
              </div>
              <hr />
              <div class="pt-2">
                <div class="form-check" style="padding-left: 90px;">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="guarantee"
                    id="guarantee"
                    value="guarantee"
                  />
                  <label class="form-check-label pl-2" for="guarantee"
                    >I guarantee that i have no Illegal substance or hazardous
                    materials in my possession.</label
                  >
                </div>

                <div class="form-check mt-2" style="padding-left: 90px;">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="permission"
                    id="permission"
                    value="permission"
                  />
                  <label class="form-check-label pl-2" for="permission"
                    >I give my permission to the clinic staff to search my
                    personal and all my belongings for illicit
                    substances.</label
                  >
                </div>
              </div>
              <div class="mt-4">
                <p>
                  The treating physician an/or his assistants are authorized to
                  provide additional services as they deem reasonable and
                  necessary including but not limited to administration and
                  maintenance of anesthesia, blood and/or blood products. As in
                  all traffic conditions there is a possibility of an accident
                  of any nature. The medical staff is authorized to change the
                  mode of transport for my benefit and safety.
                </p>
              </div>
              <div class="mt-2">
                <p>
                  <b
                    >I hereby acknowledge to receive the treatment detailed
                    above</b
                  >
                </p>
              </div>
              <div class="d-flex mt-3">
                <div class="flex-even p-3">
                  <input class="input-un" type="text" name="" id="" />
                  <label class="label">Signature</label>
                </div>
                <div class="flex-even p-3">
                  <input
                    class="input-un"
                    type="text"
                    name=""
                    id=""
                    value="John Hasan"
                  />
                  <label class="label">Patient's Name</label>
                </div>
                <div class="flex-even p-3">
                  <input
                    class="input-un"
                    type="text"
                    name=""
                    id=""
                    value="01/01/2020 13:00"
                  />
                  <label class="label">Date / Time</label>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-even p-3">
                  <input class="input-un" type="text" name="" id="" />
                  <label class="label">Signature</label>
                </div>
                <div class="flex-even p-3">
                  <input class="input-un" type="text" name="" id="" />
                  <label class="label">Witness Name</label>
                </div>
                <div class="flex-even p-3">
                  <input
                    class="input-un"
                    type="text"
                    name=""
                    id=""
                    value="01/01/2020 13:00"
                  />
                  <label class="label">Date / Time</label>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-even p-3">
                  <input class="input-un" type="text" name="" id="" />
                  <label class="label">Signature</label>
                </div>
                <div class="flex-even p-3 mt-3">
                  <select class="form-control">
                    <option value disabled selected hidden
                      >LIST OF DOCTORS</option
                    >
                    <option>A</option>
                    <option>B</option>
                    <option>C</option>
                  </select>
                  <label class="label">Physician's Name</label>
                </div>
                <div class="flex-even p-3">
                  <input
                    class="input-un"
                    type="text"
                    name=""
                    id=""
                    value="01/01/2020 13:00"
                  />
                  <label class="label">Date / Time</label>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-even p-3">
                  <input class="input-un" type="text" name="" id="" />
                  <label class="label">Signature</label>
                </div>
                <div class="flex-even p-3 mt-3">
                  <select class="form-control">
                    <option value disabled selected hidden
                      >LIST OF NURSE</option
                    >
                    <option>A</option>
                    <option>B</option>
                    <option>C</option>
                  </select>
                  <label class="label">Staff Witness Name</label>
                </div>
                <div class="flex-even p-3">
                  <input
                    class="input-un"
                    type="text"
                    name=""
                    id=""
                    value="01/01/2020 13:00"
                  />
                  <label class="label">Date / Time</label>
                </div>
              </div>
              <hr />
              <div>
                <p>
                  <b
                    >*State why the patient is not able to give consent
                    personally or sign this form.</b
                  >
                </p>
              </div>
              <div class="pt-2">
                <div class="form-check" style="padding-left: 90px;">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="unmarried"
                    id="unmarried"
                    value="unmarried"
                  />
                  <label class="form-check-label pl-2" for="unmarried"
                    >Minor or any unmarried male or female whose age has not
                    reached the age of consent ( 20 years old )</label
                  >
                </div>

                <div class="form-check mt-4" style="padding-left: 90px;">
                  <div class="d-flex">
                    <div>
                      <input
                        class="form-check-input"
                        type="radio"
                        name="Mental"
                        id="Mental"
                        value="Mental"
                      />
                      <label class="form-check-label pl-2" for="Mental"
                        >Physical/Mental Disorder</label
                      >
                    </div>
                    <div class="pl-3">
                      <input class="input-un" type="text" name="" id="" />
                    </div>
                  </div>
                </div>

                <div class="form-check mt-2" style="padding-left: 90px;">
                  <div class="d-flex">
                    <div>
                      <input
                        class="form-check-input"
                        type="radio"
                        name="Specify"
                        id="Specify"
                        value="Specify"
                      />
                      <label class="form-check-label pl-2" for="Specify"
                        >Others (Please Specify)</label
                      >
                    </div>
                    <div class="pl-3">
                      <input class="input-un" type="text" name="" id="" />
                    </div>
                  </div>
                </div>
                <div>
                  <p>
                    As the patient's condition reasonably precludes the ability
                    to grant informed consent, the above information has been
                    explained to the following responsible relative and the
                    treatment is hereby authorized:
                  </p>
                </div>

                <div class="d-flex mt-3">
                  <div class="flex-even p-3">
                    <input class="input-un" type="text" name="" id="" />
                    <label class="label">Name</label>
                  </div>
                  <div class="flex-even p-3">
                    <input class="input-un" type="text" name="" id="" />
                    <label class="label">Signature</label>
                  </div>
                  <div class="flex-even p-3">
                    <input class="input-un" type="text" name="" id="" />
                    <label class="label">Relationship</label>
                  </div>
                  <div class="flex-even p-3">
                    <input class="input-un" type="text" name="" id="" />
                    <label class="label">Date / Time</label>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="flex-even p-3">
                    <input class="input-un" type="text" name="" id="" />
                    <label class="label">Identification No.</label>
                  </div>
                  <div class="flex-even p-3">
                    <input class="input-un" type="text" name="" id="" />
                    <label class="label">Issue Place</label>
                  </div>
                  <div class="flex-even p-3">
                    <input class="input-un" type="text" name="" id="" />
                    <label class="label">Issue Date</label>
                  </div>
                  <div class="flex-even p-3">
                    <input class="input-un" type="text" name="" id="" />
                    <label class="label">Expiration Date</label>
                  </div>
                </div>
                <div>
                  <div class="p-3">
                    <input class="input-un" type="text" name="" id="" />
                    <label class="label">Current Address</label>
                  </div>
                </div>
                <div class="d-flex">
                  <div style="width: 120px;">
                    <label><b>Interpreter</b></label>
                  </div>
                  <div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="Interpreter"
                        id="Interpreter_no"
                        value="no"
                      />
                      <label class="form-check-label" for="Interpreter_no"
                        ><b>NO</b></label
                      >
                    </div>
                    <div class="form-check form-check-inline pl-5">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="Interpreter"
                        id="Interpreter_yes"
                        value="yes"
                      />
                      <label class="form-check-label" for="Interpreter_yes"
                        ><b>YES</b></label
                      >
                    </div>
                  </div>
                </div>
                <div>
                  <p>
                    <b>I have given a/an</b>
                    <select class="">
                      <option value disabled selected hidden
                        >LANGUAGE LIST</option
                      >
                      <option>English </option>
                      <option>German</option>
                    </select>
                    <b>
                      language translation of the consent form ( Consent for
                      medical transport) and/or any additional verbal and
                      written information given to the patient/parent or
                      guardian/ substitute decision maker by the doctor via
                    </b>
                    <select class="">
                      <option value disabled selected hidden
                        >e.g. Direct Communication</option
                      >
                      <option>Direct Communication</option>
                      <option>ETC</option>
                    </select>
                  </p>
                </div>

                <div class="d-flex">
                  <div class="p-3">
                    <input class="input-un" type="text" name="" id="" />
                    <label class="label">Printed name of Interpreter</label>
                  </div>
                  <div class="p-3">
                    <input class="input-un" type="text" name="" id="" />
                    <label class="label">Signature</label>
                  </div>
                  <div class="p-3">
                    <input
                      class="input-un"
                      type="text"
                      name=""
                      id=""
                      value="01/01/2020 13:00"
                    />
                    <label class="label">Date / Time</label>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="text-center">
              <button
                type="button"
                class="btn btn-primary btn-lg"
                style="color: black; width: 120px;"
              >
                Confirm
              </button>
              <button
                style="margin-left: 10px; width: 120px;"
                type="button"
                class="btn btn-outline-dark btn-lg"
              >
                Edit
              </button>
              <button
                style="margin-left: 10px; width: 120px;"
                type="button"
                class="btn btn-outline-dark btn-lg"
              >
                <i class="fa fa-print"></i>
              </button>
              <button
                style="margin-left: 10px; width: 120px;"
                type="button"
                class="btn btn-outline-danger btn-lg"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dropdown_consent_for_med from "../../assets/transpot-consent/dropdown-consent-for-med.json";
import dropdown_escort_by from "../../assets/transpot-consent/dropdown-escort-by.json";
import dropdown_transpot_by from "../../assets/transpot-consent/dropdown-transpot-by.json";
import dropdown_transpot_form from "../../assets/transpot-consent/dropdown-transpot-form.json";
import dropdown_transpot_to from "../../assets/transpot-consent/dropdown-transpot-to.json";

export default {
  data() {
    return {
      dropdown_consent_for_med,
      dropdown_escort_by,
      dropdown_transpot_by,
      dropdown_transpot_form,
      dropdown_transpot_to,
      // date picker
      stdDateConfig: {
        altInput: true,
        altFormat: "d/m/Y"
      },
      stdDateTimeConfig: {
        altInput: true,
        altFormat: "d/m/Y H:i",
        enableTime: true,
        enableSeconds: false,
        time_24hr: true
      },
      stdTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      }
    };
  }
};
</script>
<style lang="scss" scoped>
//Main css
.input-un {
  width: 100%;
  background-color: #fcfcfc;
  border: 0;
  border-bottom: 2px solid lightgrey;
  padding: 10px;
}
.transfer-txt {
  font-size: 20px;
}
.holder {
  height: 62px;
}
.label-inside {
  margin-bottom: -50px;
  padding-left: 5px;
  padding-top: 0px;
  position: absolute;
  color: rgb(116, 116, 116);
  font-size: 16px;
}
.input-inside {
  height: 100%;
  padding-bottom: -30px;
}
input[type="text"] {
  // font-size: 18px;
}
table,
th,
td {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-collapse: collapse;
}
//end main
.mid {
  padding: 20px;
  margin-top: 50px;
}
.btn {
  background-color: rgb(212, 212, 212);
}
.center {
  text-align: center;
}
//margin padding
.ml2 {
  margin-left: 8px;
}
.mt2 {
  margin-top: 8px;
}
.mt3 {
  margin-top: 10px;
}
.pd10 {
  padding: 10px;
}
.mg-t {
  margin-top: 18px;
}
.bg-gray {
  background-color: rgb(221, 221, 221);
}
.bg-light {
  background-color: #fff;
}
.bg-red {
  background-color: rgb(243, 187, 187);
}
.bg-blue {
  background-color: rgb(190, 220, 240);
}
.bg-w {
  background-color: #fff;
}
.bg-hblue {
  background-color: rgb(17, 85, 204);
}
.bg-horange {
  background-color: rgb(230, 145, 56);
}
.pd-side {
  padding-left: 5px;
  padding-right: 5px;
}
.label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}
.profile-box {
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  height: 170px; /* minimum height */
  width: 170px;
  position: relative;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background: #e6e6e6;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  p {
    font-size: 1em;
    text-align: center;
    padding: 50px 10px;
  }
}
.input-icon {
  position: absolute;
  color: rgb(214, 38, 38);
  top: 23px;
  right: 22px;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.2), 1px -1px 0 rgba(0, 0, 0, 0.2),
    -1px 1px 0 rgba(0, 0, 0, 0.2), 1px 1px 0 rgba(0, 0, 0, 0.2);
}
.input-icon-red {
  color: rgb(214, 38, 38);
}
.box-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.sign-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  width: 40%;
  height: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}
.sign-detail {
  padding: 20px;
  margin: auto;
  width: 40%;
  margin-bottom: 20px;
}
.label-b {
  color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}
.label-h {
  // color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 28px;
}
.esl-lv {
  border: 3px solid rgba(0, 0, 0, 0.3);
  width: 40px;
  height: 40px;
  margin-top: 10px;
  border-radius: 7px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  margin-left: 10px;
}

.form-check {
  margin-left: 10px;
}
button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.flex-even {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
</style>
