export default {
  data() {
    return {
      observation: {
        assessment_inver: [
          {
            date_time: null,
            bp_syst: null,
            bp_dias: null,
            map: null,
            hr: null,
            rr: null,
            temp: null,
            temp_unit: null,
            o2sat: null,
            pain_score: null,
            gcs_e: null,
            gcs_v: null,
            gcs_m: null,
            gcs_total: null,
            io_im: null,
            io_out: null,
            dtx: null,
            dtx_unit: null,
            intervention: null,
            evaluation: null,
            attending_nurse: null
          }
        ]
      }
    };
  },
  methods: {
    addRowList(modelGrp, model, value) {
      if (modelGrp && model) {
        this[modelGrp][model].push({});
        this[modelGrp][model + "Length"] += value;
      } else {
        this[model].push({});
        this[model + "Length"] += value;
      }
    },
    removeRowList(modelGrp, model, index) {
      if (modelGrp && model) {
        if (this[modelGrp][model].length > 1) {
          this[modelGrp][model].splice(index, 1);
        }
      }
    },
    getMap(sys, dias) {
      if (!isNaN(sys) && !isNaN(dias)) {
        var sys_n = Number(sys);
        var dias_n = Number(dias);
        // this.observation.assessment_inver[index].map = dias_n + sys_n
        // return (dias_n + ((sys_n - dias_n)*(1/3)))
        var res = (sys_n - dias_n) / 3 + dias_n;
        return res.toFixed(0);
      }
    },
    getGcsTotal(e, v, m) {
      if (e && v && m) {
        return Number(e) + Number(v) + Number(m);
      }
    }
  }
};
