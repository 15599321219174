<template>
  <div class="container-fluid">
    <div class="h5 text-secondary">
      <span class="text-primary">{{ value.name }}</span> Permission Management
    </div>
    <hr>

    <div class="row mt-2 backgrund-secondary">
      <div class="col-3 text-secondary">Page</div>
      <div class="col-2 text-secondary">View</div>
      <div class="col-2 text-secondary">Create</div>
      <div class="col-2 text-secondary">Edit</div>
      <div class="col-2 text-secondary">Delete</div>
    </div>
    <hr>

    <div v-for="(item, index) in pages" :key="index">
      <div class="row mt-2">
        <div class="col-3 text-secondary">
          <b v-if="item.isMain">{{ item.name }}</b>
          <span v-else>- {{ item.name }}</span>
        </div>
        <div class="col-2 text-secondary">
          <b-form-checkbox v-model="item.view" switch/>
        </div>
        <div class="col-2 text-secondary">
          <b-form-checkbox v-model="item.create" switch/>
        </div>
        <div class="col-2 text-secondary">
          <b-form-checkbox v-model="item.edit" switch/>
        </div>
        <div class="col-2 text-secondary">
          <b-form-checkbox v-model="item.delete" switch/>
        </div>
      </div>
      <hr>
    </div>

    <div class="row mt-2">
      <div class="col-6">
        <button class="btn btn-primary btn-block">
          <i class="fas fa-save"></i> Save
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-danger btn-block">
          <i class="fas fa-trash"></i> Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      pages: [
        {
          name: 'Registration',
          isMain: true,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Patient Summary',
          isMain: true,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Patient General Info',
          isMain: false,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Patient Visit',
          isMain: false,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Patient Billing',
          isMain: false,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Billing',
          isMain: true,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Billing Dashboard',
          isMain: false,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Billing Summary',
          isMain: false,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Expense Dashboard',
          isMain: false,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Expense Summary',
          isMain: false,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Statistic',
          isMain: true,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Patient Statistic',
          isMain: false,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Discovery Statistic',
          isMain: false,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Hotel Statistic',
          isMain: false,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Address Statistic',
          isMain: false,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Demographic Statistic',
          isMain: false,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Register Statistic',
          isMain: false,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Time Statistic',
          isMain: false,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Staff',
          isMain: true,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Staff Management',
          isMain: false,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
        {
          name: 'Role Management',
          isMain: false,
          view: true,
          create: true,
          edit: true,
          delete: true
        },
      ]
    }
  }
}
</script>