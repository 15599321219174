<template>
  <div>
    <other-graph :payments="payments"></other-graph>
  </div>
</template>

<script>
export default {
  props: ['payments']
}
</script>