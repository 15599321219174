<template>
  <div class="image-drawer">
    <div class="editor-container">
      <div class="editor">
        <div
          class="tool current-color"
          :style="`background-color: ${options.stroke}`"
        ></div>
        <div class="ml-2 tool clear">
          <i class="fas fa-trash-alt fa-lg" @click="clear()"></i>
        </div>
        <div class="ml-2 tool clear">
          <i class="fas fa-eraser fa-lg" @click="selectMode('eraser')"></i>
        </div>
        <div class="ml-2 tool free-drawing">
          <i class="fas fa-pencil-alt fa-lg" @click="selectMode('freeDrawing')"></i>
        </div>
        <div class="ml-2 tool custom-circle">
          <i class="far fa-circle fa-lg" @click="selectMode('circle')"></i>
        </div>
      </div>
      <div class="custom-editor">
        <Editor
          :canvasWidth="500"
          :canvasHeight="500"
          ref="editor"
          editorId="canvasId"
        />
      </div>
    </div>
    <div class="colors">
      <div class="color-container red" @click="selectColor(`#e40000`)"></div>
      <div class="color-container yellow" @click="selectColor(`#e8eb34`)"></div>
      <div class="color-container purple" @click="selectColor(`#a834eb`)"></div>
      <div class="color-container green" @click="selectColor(`#65c31a`)"></div>
      <div class="color-container lightblue" @click="selectColor(`#34b7eb`)"></div>
      <div class="color-container pink" @click="selectColor(`#eb34df`)"></div>
      <div class="color-container blue" @click="selectColor(`#1a10ad`)"></div>
      <div class="color-container black" @click="selectColor(`#000`)"></div>
    </div>
  </div>
</template>

<script>
import Editor from "vue-image-markup";

export default {
  data() {
    return {
      currentMode: 'freeDrawing',
      options: {
        stroke: '#000',
        overlayColor: '#000',
        borderColor: '#fff'
      }
    }
  },
  components: { Editor },
  mounted() {
    this.$refs.editor.set(this.currentMode, this.options);
  },
  methods: {
    selectColor(color) {
      this.options.stroke = color
      this.$refs.editor.set(this.currentMode, this.options);
    },
    selectMode(mode) {
      this.currentMode = mode
      this.$refs.editor.set(this.currentMode, this.options);
    },
    setImage(imageUrl) {
      this.$refs.editor.setBackgroundImage(imageUrl);
    },
    saveImage() {
      return this.$refs.editor.saveImage()
    },
    clear() {
      this.$refs.editor.clear()
      this.$refs.editor.set(this.currentMode, this.options);
    }
  }
};
</script>

<style lang="scss" scoped>
.image-drawer {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 50px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.image-drawer,
.editor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.editor {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: left;
}
.colors {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 40px 25px 0 25px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.color-container {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 5px 0;
}
.editor-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.editor-container,
.tool {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.tool {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 16px;
  padding: 2px 5px;
}
.current-color {
  border-radius: 5px;
  min-width: 28px;
  min-height: 28px;
}
.active-tool {
  cursor: pointer;
  color: #4287f5;
}
.red {
  background-color: #e40000;
}
.yellow {
  background-color: #e8eb34;
}
.purple {
  background-color: #a834eb;
}
.green {
  background-color: #65c31a;
}
.lightblue {
  background-color: #34b7eb;
}
.pink {
  background-color: #eb34df;
}
.blue {
  background-color: #1a10ad;
}
.black {
  background-color: #000;
}
.upload-image {
  max-width: 15px;
}
.custom-editor {
  margin-top: 20px;
}
canvas {
  border: 1px solid rgba(0, 0, 0, 0.13);
}
.tool-icon {
  height: 20px;
  width: 20px;
  -o-object-fit: contain;
  object-fit: contain;
}
.fa-lg:hover {
  cursor: pointer;
  color: #4287f5;
}
</style>