<template>
  <div>
    <div class="d-flex">
      <h3 class="text-primary mr-auto">Staff Management</h3>
      <router-link :to="{ name: 'New Staff' }" class="ml-auto btn btn-primary"
        ><i class="fas fa-plus"></i> Add</router-link
      >
    </div>
    <hr />
    <div class="input-group">
      <input type="text" class="form-control" v-model="keyword" :placeholder="`Search by Name, email, phone number, Role name...`"/>
      <div class="input-group-append">
        <span class="input-group-text"><i class="fas fa-search"></i></span>
      </div>
    </div>
    <div class="row mt-2" v-for="user in filteredUsers" :key="user.id">
      <div class="col-12">
        <div
          class="card"
          @click="
            $router.push({ name: 'Staff Info', params: { staffId: user.id } })
          "
        >
          <div class="card-body">
            <div class="d-flex">
              <img
                :src="user.photoURL || '/images/profile.svg'"
                class="avatar"
              />
              <div class="mr-auto">
                <div class="text-primary">
                  {{ user.displayName }}&nbsp;
                  <span class="fa-stack text-success" v-if="user.isApproved">
                    <i class="fas fa-circle fa-stack-1x"></i>
                    <i
                      class="fas fa-check fa-stack-1x fa-inverse"
                      style="font-size: 0.5em;"
                    ></i>
                  </span>
                </div>
                <div class="text-muted">{{ user.email }}</div>
                <div class="text-muted">{{ user.phoneNumber }}</div>
                <div class="d-flex mt-2">
                  <span class="badge badge-info mr-2" v-for="(role, index) in user.assigned_roles" :key="index">{{ role.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../../db";
import _ from "lodash";
export default {
  data() {
    return {
      users: [],
      keyword: null
    };
  },
  firestore() {
    return {
      users: db.collection("User")
    };
  },
  computed: {
    filteredUsers() {
      if (this.keyword) {
        return this.users.filter(user => {
          if(user.displayName) {
            return (
              user.displayName?.toLowerCase().includes(this.keyword.toLowerCase()) ||
              user.email?.toLowerCase().includes(this.keyword.toLowerCase()) || 
              user.phoneNumber?.toLowerCase().includes(this.keyword.toLowerCase()) || 
              this._.find(user.assigned_roles, r => {
                return  r.name?.toLowerCase().includes(this.keyword.toLowerCase())
              })
            );
          }
        });
      } else {
        return this.users;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-right: 25px;
}
</style>
