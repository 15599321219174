<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <div class="p-3">
          <general-header />
          <hr />
          <div>
            <span class="label-b">History of Medications</span>
            <button
              class="btn btn-outline-secondary mb-2"
              style="float: right;"
              v-if="hideVN"
              @click.prevent="hideVN = false"
            >
              <span>Show VN/AN</span>
            </button>
            <button
              class="btn btn-outline-secondary mb-2"
              style="float: right;"
              v-if="!hideVN"
              @click.prevent="hideVN = true"
            >
              <span>Hide VN/AN</span>
            </button>           
          </div>
          <div>
            <table class="table table-bordered">
              <thead class="bg-gray text-center">
                <tr>
                  <th v-if="!hideVN">VN/AN</th>
                  <th>Visit Date</th>
                  <th>ICD Code / Diagnosis</th>
                  <th>Dept.</th>
                  <th>Doctor</th>
                  <th>Medications</th>
                  <th width="15%">NOTE</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, index) in medicine_list" :key="index">
                  <td v-if="!hideVN">{{ i.vn_an }}</td>
                  <td>{{ i.visit_date }}</td>
                  <td>{{ i.icd_code }}</td>
                  <td>{{ i.dept }}</td>
                  <td>{{ i.doctor }}</td>
                  <td>
                    <ul>
                      <li v-for="(j, index) in i.medications" :key="index">
                        {{ j }}
                      </li>
                    </ul>
                  </td>
                  <td>{{ i.note }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hideVN: true,
      medicine_list: []
    };
  }
};
</script>
<style lang="scss" scoped>
.label-b {
  color: rgb(6, 132, 216);
  font-size: 20px;
}
.mw-3 {
  min-width: 300px;
}
</style>
