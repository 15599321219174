<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <div class="bg-white">
          <div class="p-3">
            <transfer-header />
          </div>
          <div class="bg-navy">
            <div class="p-3">
              <div class="d-flex">
                <div class="flex-fill">
                  <div>
                    <b>ESI:</b>
                  </div>
                  <select id="esi_level" class="form-control">
                    <option value disabled selected hidden></option>
                    <option value="1">Super red</option>
                    <option value="2">Red</option>
                    <option value="3">Yellow</option>
                    <option value="4">Green</option>
                    <option value="5" selected>White</option>
                  </select>
                </div>
                <div class="flex-fill ml-2">
                  <div>
                    <b>Transfer To:</b>
                  </div>
                  <select id="transfer_to" class="form-control">
                    <option value disabled selected hidden></option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                  </select>
                </div>
                <div class="flex-fill ml-2">
                  <div>
                    <b>Mode of Transfer:</b>
                  </div>
                  <select id="mode_of_transfer" class="form-control">
                    <option value disabled selected hidden></option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                  </select>
                </div>
                <div class="flex-fill ml-2">
                  <div>
                    <b>Escort:</b>
                  </div>
                  <select id="escort" class="form-control">
                    <option value disabled selected hidden></option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                  </select>
                </div>
                <div class="flex-fill ml-2">
                  <div>
                    <b>Reason for Transfer:</b>
                  </div>
                  <select id="reason_for_transfer" class="form-control">
                    <option value disabled selected hidden></option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                  </select>
                </div>
              </div>

              <div class="d-flex mt-3">
                <div class="flex-fill">
                  <div>
                    <b>Chief Complaint</b>
                  </div>
                  <textarea
                    class="form-control"
                    id
                    cols="30"
                    rows="3"
                  ></textarea>
                </div>
                <div class="flex-fill ml-2">
                  <div>
                    <b>Diagnosis</b>
                  </div>
                  <textarea
                    class="form-control"
                    id
                    cols="30"
                    rows="3"
                  ></textarea>
                </div>
              </div>

              <div class="d-flex mt-3">
                <div class="flex-fill">
                  <div>
                    <b>Problem List</b>
                  </div>
                  <textarea
                    class="form-control"
                    id
                    cols="30"
                    rows="3"
                  ></textarea>
                </div>
                <div class="flex-fill ml-2">
                  <div>
                    <b>etc</b>
                  </div>
                  <textarea
                    class="form-control"
                    id
                    cols="30"
                    rows="3"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div class="bg-fixed p-3 mt-3">
            <span class="label-b text-light">
              <b>Pre-Departure Preparation</b>
            </span>
          </div>

          <div class="bg-gray">
            <div class="p-3">
              <div class="d-flex mt-3">
                <div>
                  <p>
                    <b>Informed Consent signed:</b>
                  </p>
                </div>
                <div class="ml-5">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="is_informed"
                      id="is_informed_no"
                      value="no"
                    />
                    <label class="form-check-label" for="is_informed_no"
                      >No</label
                    >
                  </div>
                </div>
                <div class="ml-4">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="is_informed"
                      id="is_informed_yes"
                      value="yes"
                    />
                    <label class="form-check-label" for="is_informed_yes"
                      >Yes</label
                    >
                  </div>
                </div>
              </div>

              <div class="d-flex mt-2">
                <div class="flex-1">
                  <div>
                    <b>Patient Documents</b>
                  </div>
                  <div class="ml-5">
                    <div>
                      <input
                        class="form-check-input"
                        type="radio"
                        name="patient_doc"
                        id="patient_doc_transfer"
                        value="patient_doc_transfer"
                      />
                      <label
                        class="form-check-label ml-2 mt-1"
                        for="patient_doc_transfer"
                        >Transfer Form</label
                      >
                    </div>
                    <div>
                      <input
                        class="form-check-input"
                        type="radio"
                        name="patient_doc"
                        id="patient_doc_medcert"
                        value="patient_doc_medcert"
                      />
                      <label
                        class="form-check-label ml-2 mt-1"
                        for="patient_doc_medcert"
                        >Medical Certificate</label
                      >
                    </div>
                    <div>
                      <input
                        class="form-check-input"
                        type="radio"
                        name="patient_doc"
                        id="patient_doc_lab"
                        value="patient_doc_lab"
                      />
                      <label
                        class="form-check-label ml-2 mt-1"
                        for="patient_doc_lab"
                        >Lab</label
                      >
                    </div>
                    <div>
                      <input
                        class="form-check-input"
                        type="radio"
                        name="patient_doc"
                        id="patient_doc_ekg"
                        value="patient_doc_ekg"
                      />
                      <label
                        class="form-check-label ml-2 mt-1"
                        for="patient_doc_ekg"
                        >EKG</label
                      >
                    </div>
                    <div>
                      <input
                        class="form-check-input"
                        type="radio"
                        name="patient_doc"
                        id="patient_doc_xray"
                        value="patient_doc_xray"
                      />
                      <label
                        class="form-check-label ml-2 mt-1"
                        for="patient_doc_xray"
                        >X-Ray</label
                      >
                    </div>
                  </div>
                </div>
                <div class="flex-1">
                  <table class="table table-bordered">
                    <tbody class="bg-white">
                      <tr class="bg-general">
                        <td>Windguru wave (Rating/Height)</td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <input
                              type="radio"
                              name="patient_doc"
                              id="patient_doc_xray"
                              value="patient_doc_xray"
                            />
                            <label
                              class="form-check-label ml-2"
                              for="patient_doc_xray"
                              >No Star</label
                            >
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <input
                              type="radio"
                              name="patient_doc"
                              id="patient_doc_xray"
                              value="patient_doc_xray"
                            />
                            <label
                              class="form-check-label ml-2"
                              for="patient_doc_xray"
                            >
                              <i
                                class="fa fa-star"
                                style="color: yellow; text-shadow: 0 0 3px #000;"
                              ></i>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <input
                              type="radio"
                              name="patient_doc"
                              id="patient_doc_xray"
                              value="patient_doc_xray"
                            />
                            <label
                              class="form-check-label ml-2"
                              for="patient_doc_xray"
                            >
                              <i
                                class="fa fa-star"
                                style="color: yellow; text-shadow: 0 0 3px #000;"
                              ></i>
                              <i
                                class="fa fa-star"
                                style="color: yellow; text-shadow: 0 0 3px #000;"
                              ></i>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <input
                              type="radio"
                              name="patient_doc"
                              id="patient_doc_xray"
                              value="patient_doc_xray"
                            />
                            <label
                              class="form-check-label ml-2"
                              for="patient_doc_xray"
                            >
                              <i
                                class="fa fa-star"
                                style="color: yellow; text-shadow: 0 0 3px #000;"
                              ></i>
                              <i
                                class="fa fa-star"
                                style="color: yellow; text-shadow: 0 0 3px #000;"
                              ></i>
                              <i
                                class="fa fa-star"
                                style="color: yellow; text-shadow: 0 0 3px #000;"
                              ></i>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="flex-3 ml-2">
                  <table class="table table-bordered">
                    <thead class="bg-general">
                      <tr>
                        <th width="30%"></th>
                        <th>dd/mm/yyyy</th>
                        <th>Time</th>
                        <th width="20%">WMC Staff</th>
                        <th width="20%">Responder</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      <tr>
                        <td>First contact time</td>
                        <td>
                          <flat-pickr
                            class="form-control"
                            :config="stdDateConfig"
                            value
                          ></flat-pickr>
                        </td>
                        <td>
                          <flat-pickr
                            class="form-control"
                            :config="stdTimeConfig"
                            value
                          ></flat-pickr>
                        </td>
                        <td>
                          <select id="wmc_staff" class="form-control">
                            <option value disabled selected hidden></option>
                            <option value="A">AAAA</option>
                            <option value="B">BBBB</option>
                          </select>
                        </td>
                        <td>
                          <select id="responder" class="form-control">
                            <option value disabled selected hidden></option>
                            <option value="A">AAAA</option>
                            <option value="B">BBBB</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>Accept of transfer time</td>
                        <td>
                          <flat-pickr
                            class="form-control"
                            :config="stdDateConfig"
                            value
                          ></flat-pickr>
                        </td>
                        <td>
                          <flat-pickr
                            class="form-control"
                            :config="stdTimeConfig"
                            value
                          ></flat-pickr>
                        </td>
                        <td>
                          <select id="wmc_staff" class="form-control">
                            <option value disabled selected hidden></option>
                            <option value="A">AAAA</option>
                            <option value="B">BBBB</option>
                          </select>
                        </td>
                        <td>
                          <select id="responder" class="form-control">
                            <option value disabled selected hidden></option>
                            <option value="A">AAAA</option>
                            <option value="B">BBBB</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>Departure time from WMC hospital</td>
                        <td>
                          <flat-pickr
                            class="form-control"
                            :config="stdDateConfig"
                            value
                          ></flat-pickr>
                        </td>
                        <td>
                          <flat-pickr
                            class="form-control"
                            :config="stdTimeConfig"
                            value
                          ></flat-pickr>
                        </td>
                        <td>
                          <select id="wmc_staff" class="form-control">
                            <option value disabled selected hidden></option>
                            <option value="A">AAAA</option>
                            <option value="B">BBBB</option>
                          </select>
                        </td>
                        <td>
                          <select id="responder" class="form-control">
                            <option value disabled selected hidden></option>
                            <option value="A">AAAA</option>
                            <option value="B">BBBB</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="mt-2">
                <b>Patient’s Condition before departure:</b>
                <textarea
                  class="form-control"
                  id
                  cols="30"
                  rows="4"
                  placeholder
                ></textarea>
              </div>

              <div class="mt-4 text-center">
                <table class="table table-bordered">
                  <thead class="bg-general">
                    <tr>
                      <th width="8%">Date</th>
                      <th width="8%">Time</th>
                      <th>T</th>
                      <th>PR</th>
                      <th>RR</th>
                      <th width="12%">BP</th>
                      <th>O2Sat</th>
                      <th>EVM</th>
                      <th width="17%">
                        <span>Nurse Note</span
                        ><i
                          class="fas fa-file-alt"
                          style="float: right; font-size: 18px;"
                        ></i>
                      </th>
                      <th width="17%">
                        <span>Evaluation</span
                        ><i
                          class="fas fa-file-alt"
                          style="float: right; font-size: 18px;"
                        ></i>
                      </th>
                      <th>
                        <button
                          class="btn btn-primary"
                          @click="addRowList('transfer_nursing', 'vs_list', 1)"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(i, index) in transfer_nursing.vs_list"
                      :key="index"
                    >
                      <td>
                        <flat-pickr
                          class="form-control"
                          :config="stdDateConfig"
                          value
                        ></flat-pickr>
                      </td>
                      <td>
                        <flat-pickr
                          class="form-control"
                          :config="stdTimeConfig"
                          value
                        ></flat-pickr>
                      </td>
                      <td>
                        <input type="text" class="form-control" />
                      </td>
                      <td>
                        <input type="text" class="form-control" />
                      </td>
                      <td>
                        <input type="text" class="form-control" />
                      </td>
                      <td>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control border-right-0"
                            placeholder="syst"
                          />
                          <div class="input-group-append">
                            <span
                              class="input-group-text p-1 bg-white border-right-0"
                              >/</span
                            >
                          </div>
                          <input
                            type="text"
                            class="form-control border-left-0"
                            placeholder="diat"
                          />
                        </div>
                      </td>
                      <td>
                        <input type="text" class="form-control" />
                      </td>
                      <td>
                        <input type="text" class="form-control" />
                      </td>
                      <td>
                        <input type="text" class="form-control" />
                      </td>
                      <td>
                        <input type="text" class="form-control" />
                      </td>
                      <td>
                        <button
                          class="btn btn-secondary"
                          @click="
                            removeRowList('transfer_nursing', 'vs_list', index)
                          "
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="d-flex">
                <div>
                  <div class="form-check-inline">
                    <div class="form-check-label">
                      <b>OPD/ER Staff:</b>
                    </div>
                    <div
                      class="form-check-input ml-2"
                      style="min-width: 250px;"
                    >
                      <select id="opd_er_staff" class="form-control">
                        <option value disabled selected hidden></option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="ml-auto">
                  <div class="form-check-inline">
                    <div class="form-check-label">
                      <b>Endorsement to Transfer Staff:</b>
                    </div>
                    <div
                      class="form-check-input ml-2"
                      style="min-width: 250px;"
                    >
                      <select id="endor_staff" class="form-control">
                        <option value disabled selected hidden></option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-fixed p-3 mt-3">
            <span class="label-b text-light">
              <b>During Transfer Record</b>
            </span>
          </div>
          <div class="bg-gray">
            <div class="p-3">
              <div class="d-flex mt-4">
                <div class="flex-1">
                  <div class="d-flex">
                    <div class="mt-1">
                      <b>Departure from Phi Phi pier :</b>
                    </div>
                    <div class="ml-4 flex-1">
                      <flat-pickr
                        class="form-control"
                        :config="stdDateConfig"
                        value
                        placeholder="Date"
                      ></flat-pickr>
                    </div>
                    <div class="ml-2 flex-1">
                      <flat-pickr
                        class="form-control"
                        :config="stdTimeConfig"
                        value
                        placeholder="Time"
                      ></flat-pickr>
                    </div>
                  </div>
                  <div class="d-flex mt-2">
                    <div class="mt-1">
                      <b>Arrival in:</b>
                    </div>
                    <div class="ml-4 flex-1">
                      <select id="arrival_in" class="form-control">
                        <option value disabled selected hidden></option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                      </select>
                    </div>
                    <div class="ml-2 flex-1">
                      <flat-pickr
                        class="form-control"
                        :config="stdDateConfig"
                        value
                        placeholder="Date"
                      ></flat-pickr>
                    </div>
                    <div class="ml-2 flex-1">
                      <flat-pickr
                        class="form-control"
                        :config="stdTimeConfig"
                        value
                        placeholder="Time"
                      ></flat-pickr>
                    </div>
                  </div>
                </div>
                <div class="flex-1">
                  <div class="d-flex mt-2 ml-5">
                    <div class="mt-1">
                      <b>Transfer Staff:</b>
                    </div>
                    <div class="ml-4 flex-1">
                      <select id="transfer_staff" class="form-control">
                        <option value disabled selected hidden></option>
                        <option value="Doctor">Doctor</option>
                        <option value="Nurse">Nurse</option>
                      </select>
                    </div>
                    <div class="ml-2 flex-1">
                      <select id="staff_list" class="form-control">
                        <option value disabled selected hidden></option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                      </select>
                    </div>
                    <div class="ml-2">
                      <button class="btn btn-primary">
                        <i class="fa fa-plus"></i>
                      </button>
                      <button class="btn btn-secondary ml-2">
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-doctor p-3 ml-3 mr-3 mt-3">
                <span class="label-b text-light">
                  <b>For Physician</b>
                </span>
              </div>
              <div class="ml-3 mr-3">
                <table class="table table-bordered text-center">
                  <thead class="bg-general">
                    <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th width="25%">Progress note</th>
                      <th width="40%">Physician Order</th>
                      <th>Doctor's name</th>
                      <th>
                        <button class="btn btn-primary">
                          <i class="fa fa-plus"></i>
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(i,
                      index) in transfer_nursing.during_transfer_phys"
                      :key="index"
                    >
                      <td>
                        <flat-pickr
                          class="form-control"
                          :config="stdDateConfig"
                          value
                        ></flat-pickr>
                      </td>
                      <td>
                        <flat-pickr
                          class="form-control"
                          :config="stdTimeConfig"
                          value
                        ></flat-pickr>
                      </td>
                      <td>
                        <input type="text" class="form-control" />
                      </td>
                      <td>
                        <div class="d-flex">
                          <div class="flex-1">
                            <select class="form-control" name="" id="">
                              <option value hidden selected></option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                            </select>
                          </div>
                          <div class="flex-1 ml-1">
                            <select class="form-control" name="" id="">
                              <option value hidden selected></option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                            </select>
                          </div>
                        </div>
                      </td>
                      <td>
                        <input type="text" class="form-control" />
                      </td>
                      <td>
                        <button class="btn btn-secondary">
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="bg-nursing p-3 ml-3 mr-3 mt-3">
                <span class="label-b text-light">
                  <b>For Nurse</b>
                </span>
              </div>
              <div class="ml-3 mr-3">
                <table class="table table-bordered text-center">
                  <thead class="bg-general">
                    <tr>
                      <th width="8%">Date</th>
                      <th width="8%">Time</th>
                      <th>T</th>
                      <th>PR</th>
                      <th>RR</th>
                      <th width="12%">BP</th>
                      <th>O2Sat</th>
                      <th>EVM</th>
                      <th width="17%">
                        <span>Nurse Note</span
                        ><i
                          class="fas fa-file-alt"
                          style="float: right; font-size: 18px;"
                        ></i>
                      </th>
                      <th width="17%">
                        <span>Evaluation</span
                        ><i
                          class="fas fa-file-alt"
                          style="float: right; font-size: 18px;"
                        ></i>
                      </th>
                      <th>
                        <button
                          class="btn btn-primary"
                          @click="addRowList('transfer_nursing', 'vs_list', 1)"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(i, index) in transfer_nursing.vs_list"
                      :key="index"
                    >
                      <td>
                        <flat-pickr
                          class="form-control"
                          :config="stdDateConfig"
                          value
                        ></flat-pickr>
                      </td>
                      <td>
                        <flat-pickr
                          class="form-control"
                          :config="stdTimeConfig"
                          value
                        ></flat-pickr>
                      </td>
                      <td>
                        <input type="text" class="form-control" />
                      </td>
                      <td>
                        <input type="text" class="form-control" />
                      </td>
                      <td>
                        <input type="text" class="form-control" />
                      </td>
                      <td>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control border-right-0"
                            placeholder="syst"
                          />
                          <div class="input-group-append">
                            <span
                              class="input-group-text p-1 bg-white border-right-0"
                              >/</span
                            >
                          </div>
                          <input
                            type="text"
                            class="form-control border-left-0"
                            placeholder="diat"
                          />
                        </div>
                      </td>
                      <td>
                        <input type="text" class="form-control" />
                      </td>
                      <td>
                        <input type="text" class="form-control" />
                      </td>
                      <td>
                        <input type="text" class="form-control" />
                      </td>
                      <td>
                        <input type="text" class="form-control" />
                      </td>
                      <td>
                        <button
                          class="btn btn-secondary"
                          @click="
                            removeRowList('transfer_nursing', 'vs_list', index)
                          "
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="d-flex mt-4">
                <div>
                  <p>
                    <b>Transfer documents endorsed to receiving nurse:</b>
                  </p>
                </div>
                <div class="ml-5">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="transfer_doc_endo"
                      id="transfer_doc_endo_no"
                      value="no"
                    />
                    <label class="form-check-label" for="transfer_doc_endo_no"
                      >No</label
                    >
                  </div>
                </div>
                <div class="ml-4">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="transfer_doc_endo"
                      id="transfer_doc_endo_yes"
                      value="yes"
                    />
                    <label class="form-check-label" for="transfer_doc_endo_yes"
                      >Yes</label
                    >
                  </div>
                </div>
              </div>

              <div class="d-flex">
                <div class="flex-1">
                  <div class="d-flex">
                    <div class="mt-1">
                      <b>Endorsed By:</b>
                    </div>
                    <div class="ml-4" style="min-width: 300px;">
                      <select id="endo_by" class="form-control">
                        <option value disabled selected hidden></option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="flex-1">
                  <div class="d-flex">
                    <div class="mt-1">
                      <b>Endorsed To:</b>
                    </div>
                    <div class="ml-4 flex-fill">
                      <div class="d-flex">
                        <div class="flex-1">
                          <select id="endo_by" class="form-control">
                            <option value disabled selected hidden
                              >Receiving Hospital List</option
                            >
                            <option value="A">A</option>
                            <option value="B">B</option>
                          </select>
                        </div>
                        <div class="flex-1 ml-2">
                          <select id="endo_by" class="form-control">
                            <option value disabled selected hidden
                              >Staff</option
                            >
                            <option value="A">A</option>
                            <option value="B">B</option>
                          </select>
                        </div>
                      </div>
                      <div class="d-flex mt-2">
                        <div class="flex-1">
                          <flat-pickr
                            class="form-control"
                            :config="stdDateConfig"
                            placeholder="Date"
                          ></flat-pickr>
                        </div>
                        <div class="flex-1 ml-2">
                          <flat-pickr
                            class="form-control"
                            :config="stdTimeConfig"
                            placeholder="Time"
                          ></flat-pickr>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-fixed p-3 mt-3">
            <span class="label-b text-light">
              <b>After Transfer Record</b>
            </span>
          </div>
          <div class="bg-gray">
            <div class="p-3">
              <div class="d-flex mt-2">
                <div class="mt-1">
                  <b>Staff Arrival in Worldmed Center :</b>
                </div>
                <div class="ml-4">
                  <flat-pickr
                    class="form-control"
                    :config="stdDateConfig"
                    value
                    placeholder="Date"
                  ></flat-pickr>
                </div>
                <div class="ml-2">
                  <flat-pickr
                    class="form-control"
                    :config="stdTimeConfig"
                    value
                    placeholder="Time"
                  ></flat-pickr>
                </div>
              </div>
              <div class="mt-3">
                <b>Problem in Transfer Process</b>
                <div>
                  <textarea
                    class="form-control"
                    id
                    cols="30"
                    rows="4"
                    placeholder="Notes"
                  ></textarea>
                </div>
              </div>
              <div class="d-flex mt-3">
                <div class="ml-auto" style="min-width: 230px;">
                  <select id="rn_staff" class="form-control">
                    <option value disabled selected hidden>RN Staff</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import transferNursingMixins from "../../mixins/transfer-nursing";

export default {
  mixins: [transferNursingMixins],
  data() {
    return {
      // date picker
      stdDateConfig: {
        altInput: true,
        altFormat: "d/m/Y"
      },
      stdDateTimeConfig: {
        altInput: true,
        altFormat: "d/m/Y H:i",
        enableTime: true,
        enableSeconds: false,
        time_24hr: true
      },
      stdTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      }
    };
  }
};
</script>
<style lang="scss" scoped>
table,
th,
td {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-collapse: collapse;
  line-height: 17px;
}

.label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.box-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.label-b {
  color: rgb(6, 132, 216);
  font-size: 20px;
}

button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
input[type="radio"] {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}
</style>
