import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import occupations from "../assets/occupations.json";
import countries from "../assets/countries.json";
import relationships from "../assets/relationships.json";
// global dropdown json
import dd_mode_of_arrival from "../assets/mode_of_arrival.json";
import dd_fall_precaution from "../assets/fall_precaution.json";
import dd_type_contraception from "../assets/contraception.json";
import dd_family from "../assets/family.json";
import dd_general_app from "../assets/general_appearance.json";
import dd_purpose from "../assets/purpose.json";
import dd_rabies from "../assets/rabies.json";
import dd_surgery from "../assets/surgery.json";
import dd_tetanus from "../assets/tetanus.json";
import dd_underlying from "../assets/underlying.json";

// CC dropdown json
import dd_cc_cardio from "../assets/dd-chief-complaint/cc_cardiovascular.json";
import dd_cc_gastro from "../assets/dd-chief-complaint/cc_gastrointestinal.json";
import dd_cc_general from "../assets/dd-chief-complaint/cc_general.json";
import dd_cc_integu from "../assets/dd-chief-complaint/cc_integumentary.json";
import dd_cc_neuro from "../assets/dd-chief-complaint/cc_neurological.json";
import dd_cc_obstetric from "../assets/dd-chief-complaint/cc_obstetric.json";
import dd_cc_ocular from "../assets/dd-chief-complaint/cc_ocular.json";
import dd_cc_phyc from "../assets/dd-chief-complaint/cc_psychiatric.json";
import dd_cc_pulm from "../assets/dd-chief-complaint/cc_pulmonary.json";
import dd_cc_rheu from "../assets/dd-chief-complaint/cc_rheumatologic.json";
import dd_cc_urol from "../assets/dd-chief-complaint/cc_urologic.json";


const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    branch: 1,
    department: null,
    branches: [],
    departments: [],
    general_dropdown: [],
    reactions: [],
    current_version: null,
    stdTimeConfig: {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true
    },
    stdDateConfig: {
      dateFormat: "d/m/Y"
    },
    stdDateTimeConfig: {
      altInput: true,
      altFormat: "d/m/Y H:i",
      enableTime: true,
      enableSeconds: false,
      time_24hr: true
    },
    occupations,
    countries,
    relationships,
    allergies: [],
    places: [],
    insurances: [],
    
    dd_mode_of_arrival,
    dd_fall_precaution,
    dd_type_contraception,
    dd_family,
    dd_general_app,
    dd_purpose,
    dd_rabies,
    dd_surgery,
    dd_tetanus,
    dd_underlying,

    dd_cc_general,
    dd_cc_cardio,
    dd_cc_gastro,
    dd_cc_integu,
    dd_cc_neuro,
    dd_cc_obstetric,
    dd_cc_ocular,
    dd_cc_phyc,
    dd_cc_pulm,
    dd_cc_rheu,
    dd_cc_urol,

    nurses: [],
  },
  mutations: {
    setUser: (state, user) => {
      state.user = user;
      if(user) {
        state.roles = {}
        state.permission = {}
      }
    },
    addRole: (state, role) => {
      state.roles[role.id] = role
      for(let [permission, level] of Object.entries(role)) {
        if(typeof level === 'number') {
          if (!state.permission[permission] || state.permission[permission]<level) {
            state.permission[permission] = level
          }
        }
      }
    },
    setCurrentVersion: (state, current_version) => {
      state.current_version = current_version;
    },
    setBranch: (state, branch) => {
      state.branch = branch;
    },
    setDepartment: (state, department) => {
      state.department = department;
    },
    setBranches: (state, branches) => {
      state.branches = branches;
    },
    setDepartments: (state, departments) => {
      state.departments = departments;
    },
    setAllergies: (state, allergies) => {
      state.allergies = allergies;
    },
    setPlaces: (state, places) => {
      state.places = places;
    },
    setInsurances: (state, insurances) => {
      state.insurances = insurances;
    },

    setDDProcedure: (state, dd_procedure) => {
      state.dd_procedure = dd_procedure;
    },
    setDDMedicine: (state, dd_medicine) => {
      state.dd_medicine = dd_medicine;
    },
    setDDSupply: (state, dd_supply) => {
      state.dd_supply = dd_supply;
    },
    setNurses: (state, nurses) => {
      state.nurses = nurses
    },
    setReactions: (state, reactions) => {
      state.reactions = reactions
    }
    //end dropdown list
  },
  getters: {
    user: state => {
      return state.user;
    },
    roles: state => {
      return state.roles;
    },
    permission: state => {
      return state.permission;
    },
    current_version: state => {
      return state.current_version;
    },
    branch: state => {
      return state.branch;
    },
    department: state => {
      return state.department;
    },
    branches: state => {
      return state.branches;
    },
    departments: state => {
      return state.departments;
    },

    dd_procedure: state => {
      return state.dd_procedure;
    },
    dd_medicine: state => {
      return state.dd_medicine;
    },
    dd_supply: state => {
      return state.dd_supply;
    }
  },
  plugins: [vuexLocal.plugin]
});
