<template>
  <div class="card shadow mt-2 mb-2">
    <div class="card-body row mt-2">
      <div class="col-md-6 col-sm-12">
        <table class="table table-bordered table-striped">
          <thead>
            <th colspan="4" class="text-center">Card Payer</th>
          </thead>
          <thead>
            <th>Name</th>
            <th>Country</th>
            <th>Time</th>
            <th class="text-right">Amount</th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedPayments" :key="'inter'+index">
              <td><a :href="`/print/receipt/${item.payment_id}`" target="_blank">{{item.patient_name}}</a></td>
              <td>{{item.home_country}}</td>
              <td>{{item.issue_date.toDate() | moment('DD/MM/YYYY HH:mm a')}}</td>
              <td class="text-right">{{Math.round(item.credit).toLocaleString()}}</td>
            </tr>
            <tr>
              <td colspan="3">Total</td>
              <td class="text-right">{{ Math.round(_.sumBy(_.filter(this.preparedPayments, p=>p.credit>0&&p.home_country), item => item.credit)).toLocaleString()}}</td>
            </tr>
          </tbody>
        </table>
        <table-pagination :items="filteredPayments" :onChange="(items) => { paginatedPayments = items}" :perPage="10"/>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="d-flex mt-2 mb-2">
          <h5 class="text-primary">Countries Paid by Card</h5>
          <button class="btn btn-primary ml-auto" @click="$bvModal.show('card-by-country-setting')"><i class="fas fa-cog"></i></button>
        </div>
        <Bar  :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'" :chart-id="'card-by-country-chart'"
          :width="400" :height="400" />

        <modal-graph-color-setting 
          @change="saveColor"
          v-model="colorList" 
          :id="'card-by-country-setting'" 
          :legends="legendList"/>
      </div>
    </div>
  </div>
</template>

<script>
import {db} from '../../db'
import GraphColorSettingMixins from '../../mixins/graph-color-setting'
import ModalGraphColorSetting from '../../components/Modal/GraphColorSetting.vue'
import {
  Bar 
} from 'vue-chartjs/legacy'

export default {
  props: ['payments'],
  mixins: [GraphColorSettingMixins],
  components: {
    Bar,
    ModalGraphColorSetting
  },
  data() {
    return {
      GRAPH_ID: 'CardByCountryGraph',
      chartOptions: {
        responsive: true,
        indexAxis: 'x',
        plugins: {
          datalabels: {
            color: '#000',
            anchor: 'end',
            align: 'top',
          },
          legend: {
            display: false
          },
        },
        layout: {
          padding: {
            top: 50,
          },
        }
      },
      preparedPayments: [],
      paginatedPayments: []
    }
  },
  watch:{
    payments:{
      async handler(){
        let loader = this.$loading.show({})

        this.preparedPayments = await Promise.all(this.payments.map(async (item) => {
          let p = await db.collection('Patient').doc(item.HN).get()
          item.home_country = p.data().home_country

          return item
        }))
        this.setLegendList(this.preparedData.map( item => item.home_country))
        this.setColorList(this.generateBackgroundColor(this.preparedData, 'home_country'))
        loader.hide()
      },
      immediate: true
    },
    colorSetting: {
      handler() {
        this.setLegendList(this.preparedData.map( item => item.home_country))
        this.setColorList(this.generateBackgroundColor(this.preparedData, 'home_country'))
      },
      immediate: true
    },
  },
  computed: {
    filteredPayments() {
      return this._.filter(this.preparedPayments, p=>p.credit>0&&p.home_country)
    },
    preparedData() {
      let data = []

      for (let item of this.preparedPayments) {
        let findIndex = this._.findIndex(data, {home_country: item.home_country})
        if(item.credit > 0 && item.home_country) {
          if(findIndex>-1) {
            data[findIndex].count ++
            data[findIndex].amount += item.credit
          }else{
            data.push({
              home_country: item.home_country,
              count: 1,
              amount: 0+item.credit,
              index: data.length
            })
          }
        }
      }

      return data
    },
    chartData() {
      
      let chartData = {
        labels: this.preparedData.map(d => d.home_country),
        datasets: [{
          label: 'card',
          data: this.preparedData.map(d => d.count),
          backgroundColor: this.colorList
        }]
      }

      return chartData
    }
  }
}
</script>