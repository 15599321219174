<template>
    <div>
      <internetbanking-payment-graph :payments="payments"></internetbanking-payment-graph>
    </div>
  </template>
  
  <script>
  export default {
    props: ['payments']
  }
  </script>