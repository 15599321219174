<template>
  <div class="d-flex">
    <div class="logo-box align-middle">
      <img
        src="/images/worldmed-logo.jpg"
        class="img-fluid"
        style="margin-top: 30%;"
      />
    </div>
    <div class="detail-box flex-fill flex-row align-items-stretch">
      <div class="field d-flex align-items-stretch">
        <div class="sub-field pl-2">
          <div class="input-group">
            <label>Name : </label> 
            <input class="form-control ml-2" type="text" :value="`${patient.first_name} ${patient.last_name}`"/>
          </div>
        </div>
      </div>
      <div class="field d-flex align-items-stretch">
        <div class="sub-field flex-1 border-r pl-2">
          <div class="input-group">
            <label>Date of Birth :</label> 
            <input class="form-control ml-2" type="text" :value="patient.date_of_birth"/>
          </div>
        </div>
        <div class="sub-field flex-1 border-r pl-2">
          <div class="input-group">
            <label>Age :</label> 
            <input class="form-control ml-2" type="text" v-model="patient.age"/>
          </div>
        </div>
        <div class="sub-field flex-1 pl-2">
          <div class="input-group">
            <label>Gender :</label> 
            <input class="form-control ml-2" type="text" v-model="patient.gender"/>
          </div>
        </div>
      </div>
      <div class="field d-flex align-items-stretch">
        <div class="sub-field flex-1 border-r pl-2">
          <div class="input-group">
            <label>Nation :</label> 
            <input class="form-control ml-2" type="text" v-model="patient.nationality"/>
          </div>
        </div>
        <div class="sub-field flex-2 pl-2">
          <div class="input-group">
            <label>ID. No./ Passport :</label> 
            <input class="form-control ml-2" type="text" v-model="patient.passport_id"/>
          </div>
        </div>
        <div class="sub-field flex-1 pl-2"></div>
      </div>
      <div class="field pl-2">Emergency contact</div>
      <div class="field d-flex align-items-stretch">
        <div class="sub-field flex-1 border-r pl-2">
          <div class="input-group">
            <label>Name :</label> 
            <input class="form-control ml-2" type="text" :value="`${patient.emergency_first_name} ${patient.emergency_last_name}` "/>
          </div>
        </div>
        <div class="sub-field flex-1 border-r pl-2">
           <div class="input-group">
            <label>Relationship :</label> 
            <input class="form-control ml-2" type="text" v-model="patient.emergency_relationship"/>
          </div>
        </div>
        <div class="sub-field flex-1 pl-2">
           <div class="input-group">
            <label>Tel. No.:</label> 
            <input class="form-control ml-2" type="text" v-model="patient.emergency_phone"/>
          </div>
        </div>
      </div>
    </div>
    <div class="image-box align-middle">
      <img :src="`${patient.img_url}`" class="img-fluid" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      default: () => {
        return {}
      }
    },
    patient: {
      default: () => {
        return {}
      }
    }
  },
  created() {
    console.log({patient: this.patient})
  }
}
</script>
<style lang="scss" scoped>
.border-r {
  border-right: 1px solid #aaaaaa;
}
.logo-box {
  border-top: 1px solid #aaaaaa;
  border-left: 1px solid #aaaaaa;
  border-bottom: 1px solid #aaaaaa;
  padding: 5px;
  width: 200px;
}
.detail-box {
  border-left: 1px solid #aaaaaa;
  border-right: 1px solid #aaaaaa;
  border-top: 1px solid #aaaaaa;
  background-color: #ededed;
}
.image-box {
  border-top: 1px solid #aaaaaa;
  border-right: 1px solid #aaaaaa;
  border-bottom: 1px solid #aaaaaa;
  padding: 5px;
  width: 200px;
}

.field {
  // padding: 0.25em;
  line-height: 2.5em;
  border-bottom: 1px solid #aaaaaa;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
</style>
