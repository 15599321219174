<template>
  <div>
    <input
      class="form-control"
      ref="numberInput"
      type="text"
      v-model="formattedNumber"
      @input="updateNumber($event.target.value)"
      @blur="formatInput"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    }
  },
  computed: {
    formattedNumber: {
      get() {
        return this.formatValue(this.value);
      },
      set(value) {
        const newValue = value.replace(/,/g, '');
        this.$emit('input', newValue);
      }
    }
  },
  methods: {
    formatValue(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    updateNumber(value) {
      const newValue = value.replace(/,/g, '');
      this.$emit('input', newValue);
    },
    formatInput() {
      this.$refs.numberInput.value = this.formatValue(this.value);
    }
  }
};
</script>