import Registration from "../pages/Register/Registration.vue";
import RegistrationHome from "../pages/Register/RegistrationHome.vue";
import RegularRegistration from "../pages/Register/Regular.vue";
import EmergencyRegistration from "../pages/Register/Emergency.vue";

export default [
  {
    path: "/registration",
    name: "Registration",
    component: Registration,
    children: [
      {
        path: "",
        name: "Select Registration",
        component: RegistrationHome
      },
      {
        path: "regular",
        name: "Regular Registration",
        component: RegularRegistration
      },
      {
        path: "emergency",
        name: "Emergency Registration",
        component: EmergencyRegistration
      }
    ]
  }
];
