<template>
  <b-modal :id="id" title="Order Refer" size="xl" hide-footer>
    <div class="mb-2">
      <div class="container-fluid">
        <div class="row">
          <div class="col-2">
            <div class="label">ESI:</div>
            <MazSelect
              v-model="value.esi"
              search
              :options="esi.map(i => {
                return { value: i, label: i}
              })"
            />
          </div>
          <div class="col-2">
            <div class="label">Transfer To:</div>
            <MazSelect
              v-model="value.transfer_to"
              search
              :options="transfer_to.map(i => {
                return { value: i, label: i}
              })"
            />
            <input
              type="text"
              class="form-control"
              placeholder="Other"
              v-model="value.other_transfer_to"
              v-if="value.transfer_to === 'Other'"
            />
          </div>
          <div class="col-2">
            <div class="label">Mode Of Transfer:</div>
            <MazSelect
              v-model="value.mode_of_transfer"
              search
              :options="transfer_mode.map(i => {
                return { value: i, label: i}
              })"
            />
          </div>
          <div class="col-2">
            <div class="label">Escort:</div>
            <MazSelect
              v-model="value.escort"
              search
              :options="escort.map(i => {
                return { value: i, label: i}
              })"
            />
          </div>
          <div class="col-3">
            <div class="label">Reason for Transfer:</div>
            <MazInput
              v-model="value.reason"
            ></MazInput>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="label">Chief Complaint:</div>
            <MazInput
              v-model="value.cc"
            ></MazInput>
          </div>
          <div class="col-6">
            <div class="label">Diagnosis:</div>
            <MazInput
              v-model="value.diagnosis"
            ></MazInput>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="label">Problem List</div>
            <MazInput
              v-model="value.problem_list_1"
            ></MazInput>
          </div>
          <div class="col-6 mt-4">
            <MazInput
              v-model="value.problem_list_2"
            ></MazInput>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button class="btn btn-outline-success btn-block mt-2" v-b-modal.transfer-form>
            Physician Transfer Form
          </button>
          <button class="btn btn-outline-success btn-block ml-2" v-b-modal.mode-of-transfer>
            Mode of Transport
          </button>
        </div>
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary mr-2" @click="confirmOrder">Confirm Order</button>
      <button class="btn btn-danger mr-2" @click.prevent="$bvModal.hide(id)">Cancel</button>
    </div>
  </b-modal>
</template>

<script>
const esi = ["Super red", "Red", "Yellow", "Green", "White"]
const transfer_to = [
  'Other',
  'Bangkok Phuket Hospital',
  'Bangkok Siriroj Hospital (Phuket)',
  'Krabi Nakharin Hospital',
  'Phi Phi Hospital'
]
const transfer_mode = [
  'Ambulance',
  'Speed Boat',
  'Aircraft',
  'Ferry Boat'
]
const escort = [
  'Doctor',
  'Nurse',
  'Nurse Aide',
  'Doctor and Nurse'
]
import opdMixins from "../../../mixins/opd";
export default {
  mixins: [opdMixins],
  data() {
    return {
      id: 'order-refer',
      transfer_to,
      transfer_mode,
      escort,
      esi
    }
  },
  props: {
    value: {
      default: () => {
        return {}
      }
    },
    onClose: {
      default: () => {}
    },
    onConfirm: {
      default: () => {}
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === this.id)
      this.onClose()
    })
  },
  methods: {
    confirmOrder() {
      this.$bvModal.hide(this.id)
      this.onConfirm()
    }
  }
}
</script>