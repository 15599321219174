<template>
  <div class="container-fluid">
    <patient-info :esi="true" :patient="patient"></patient-info>
    <div class="d-flex p-3">
      <div class="flex-5">
        <div class="mt-2">
          <h3 class="text-primary">Visit Summary</h3>
          <vue-good-table
            class="mt-2"
            :columns="columns"
            :rows="visits"
            @on-row-click="onRowClick"
          />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-2">
      <new-visit-btn />
    </div>
  </div>
</template>

<script>
import { db } from "../../db";
export default {
  props: ["patient"],
  data() {
    return {
      headers: [{ type: "checkbox", sortable: false, class: "primary" }],
      columns: [
        {
          label: "Date",
          field: this.getVisitDate
        },
        {
          label: "VN",
          field: "VN"
        },
        {
          label: "Branch",
          field: this.getBranchName
        },
        {
          label: "Dept.",
          field: this.getDepartmentName
        },
        {
          label: "ICD-CODE",
          field: "icdCode"
        },
        {
          label: "Diagnosis",
          field: "diagnosis"
        },
        {
          label: "Attending Doctor",
          field: "doctor"
        }
      ],
      visits: [],
      isLoaded: false,
      nursing: {}
    };
  },
  firestore() {
    return {
      visits: db
        .collection("Visit")
        .where(
          "patient",
          "==",
          db.collection("Patient").doc(this.$route.params.id)
        )
    };
  },
  async created() {
    let loader = this.$loading.show({});

    this.branches = (await db.collection("Branch").get()).docs.map(b => {
      return {
        ...b.data()
      };
    });
    this.departments = (await db.collection("Department").get()).docs.map(d => {
      return {
        ...d.data()
      };
    });

    loader.hide();
  },
  methods: {
    onRowClick(params) {
      this.$router.push({
        name: "Nurse Assessment",
        params: {
          id: this.$route.params.id,
          visitId: params.row.VN
        }
      });
    },
    getVisitDate(item) {
      if(item.visit_date) {
        return this.$moment(item.visit_date.toDate()).format('DD/MM/YYYY HH:mm')
      }else{
        return ('-')
      }
    },
    getBranchName(item) {
      return item.branch.name_en;
    },
    getDepartmentName(item) {
      return item.department.name_en;
    }
  }
};
</script>
<style lang="scss" scoped>
.vgt-table thead th {
  background-color: #31538f !important;
}
</style>
