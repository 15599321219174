<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <div class="pb-4 pt-1">
          <span class="label-b">INVESTIGATION RESULTS</span>
        </div>
        <div class="bg-lab p-3">
          <span class="label-b text-light">
            <i class="fa fa-heartbeat" aria-hidden="true"></i> Investigations
          </span>
        </div>
        <div class="bg-gray p-3">
          <div class="mt-4">
            <span class="label-h"><b>X-Ray :</b></span>
            <span style="float: right;">
              <a href>X-Ray History List</a>
            </span>
          </div>
          <div class="pl-5 pr-5 pt-3 pb-2">
            <table class="table text-center" bordercolor="#dc0000ff">
              <thead class="bg-general">
                <tr>
                  <th>Requested Date</th>
                  <th>Time</th>
                  <th>VN</th>
                  <th>Organ & View</th>
                  <th>Side</th>
                  <th>Requested By</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody class="table-body">
                <tr v-for="(i, index) in x_ray" :key="index">
                  <td>{{ i.req_date }}</td>
                  <td>{{ i.time }}</td>
                  <td>{{ i.vn }}</td>
                  <td>{{ i.organ_view }}</td>
                  <td>{{ i.side }}</td>
                  <td>{{ i.req_by }}</td>
                  <td>
                    <a href :style="'color:' + i.link_color">{{ i.status }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-5">
            <span class="label-h"><b>EKG :</b></span>
            <span style="float: right;">
              <a href>EKG History List</a>
            </span>
          </div>
          <div class="pl-5 pr-5 pt-3 pb-2">
            <table class="table text-center" bordercolor="#dc0000ff">
              <thead class="bg-general">
                <tr>
                  <th>Request Date</th>
                  <th>Time</th>
                  <th>VN</th>
                  <th>Type</th>
                  <th>Requested By</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody class="table-body">
                <tr v-for="(i, index) in ekg" :key="index">
                  <td>{{ i.req_date }}</td>
                  <td>{{ i.time }}</td>
                  <td>{{ i.vn }}</td>
                  <td>{{ i.type }}</td>
                  <td>{{ i.req_by }}</td>
                  <td>
                    <a href :style="'color:' + i.link_color">{{ i.status }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="bg-lab p-3 mt-5">
          <span class="label-b text-light">
            <i class="fa fa-flask" aria-hidden="true"></i> Laboratory
          </span>
        </div>
        <div class="bg-gray p-3">
          <div class="mt-4">
            <span class="label-h"><b>In-House Lab :</b></span>
            <span style="float: right;">
              <a href>Laboratory History List</a>
            </span>
          </div>
          <div class="pl-5 pr-5 pt-3 pb-2">
            <table class="table text-center" bordercolor="#dc0000ff">
              <thead class="bg-general">
                <tr>
                  <th>Requested Date</th>
                  <th>Requested Time</th>
                  <th>VN</th>
                  <th>Test</th>
                  <th>Specimen</th>
                  <th>Turnaround time</th>
                  <th>Request by</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody class="table-body">
                <tr v-for="(i, index) in wmc_lab" :key="index">
                  <td>{{ i.req_date }}</td>
                  <td>{{ i.req_time }}</td>
                  <td>{{ i.vn }}</td>
                  <td>{{ i.test }}</td>
                  <td>{{ i.specimen }}</td>
                  <td>{{ i.turnaround_time }}</td>
                  <td>{{ i.req_by }}</td>
                  <td>
                    <a href :style="'color:' + i.link_color">{{ i.status }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-5">
            <span class="label-h"><b>Out Lab :</b></span>
          </div>
          <div class="pl-5 pr-5 pt-3 pb-2">
            <table class="table text-center" bordercolor="#dc0000ff">
              <thead class="bg-general">
                <tr>
                  <th>Requested Date</th>
                  <th>Requested Time</th>
                  <th>VN</th>
                  <th>Test</th>
                  <th>Specimen</th>
                  <th>Turnaround time</th>
                  <th>Request by</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody class="table-body">
                <tr v-for="(i, index) in out_lab" :key="index">
                  <td>{{ i.req_date }}</td>
                  <td>{{ i.req_time }}</td>
                  <td>{{ i.vn }}</td>
                  <td>{{ i.test }}</td>
                  <td>{{ i.specimen }}</td>
                  <td>{{ i.turnaround_time }}</td>
                  <td>{{ i.req_by }}</td>
                  <td>
                    <a href :style="'color:' + i.link_color">{{ i.status }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // date picker
      stdDateConfig: {
        altInput: true,
        altFormat: "d/m/Y"
      },
      stdDateTimeConfig: {
        altInput: true,
        altFormat: "d/m/Y H:i",
        enableTime: true,
        enableSeconds: false,
        time_24hr: true
      },
      stdTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      },
      x_ray: [
        {
          req_date: "",
          time: "",
          vn: "",
          organ_view: "",
          side: "",
          req_by: "",
          status: "",
          link_color: ""
        }
      ],
      ekg: [
        {
          req_date: "",
          time: "",
          vn: "",
          type: "",
          req_by: "",
          status: "",
          link_color: ""
        }
      ],
      wmc_lab: [
        {
          req_date: "",
          req_time: "",
          vn: "",
          test: "",
          specimen: "",
          turnaround_time: "",
          req_by: "",
          status: "",
          link_color: ""
        }
      ],
      out_lab: [
        {
          req_date: "",
          req_time: "",
          vn: "",
          test: "",
          specimen: "",
          turnaround_time: "",
          req_by: "",
          status: "",
          link_color: ""
        },
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
//Main css

.table-body {
  background-color: #fff;
}

table,
th,
td {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-collapse: collapse;
}

.bg-hblue {
  background-color: rgb(17, 85, 204);
}

.bg-horange {
  background-color: rgb(230, 145, 56);
}

.label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.label-b {
  color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}
.label-h {
  // color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}
button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
</style>
