import Expense from '../pages/Expense/index.vue'
import ExpenseDashboard from '../pages/Expense/Dashboard.vue'
import ExpenseSummary from '../pages/Expense/Summary.vue'
export default [
  {
    path: "/expense",
    name: "Expense",
    component: Expense,
    redirect: {
      name: "Expense Dashboard"
    },
    children: [      
      {
        path: "expense-dashboard",
        name: "Expense Dashboard",
        component: ExpenseDashboard
      },
      {
        path: "expense-summary",
        name: "Expense Summary",
        component: ExpenseSummary
      },
    ]
  }
];
