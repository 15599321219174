<template>
    <div class="container-fluid">
      <div class="card shadow">
        <div class="card-body">
          <div class="text-muted">Sale Management</div>  
          <hr />
          <b-nav class="mb-2" tabs>
            <b-nav-item :active="currentMenu === menu.name" v-for="(menu, index) in menus" :key="index"
              @click="currentMenu = menu.name">{{ menu.name }}</b-nav-item>
          </b-nav>
  
          <div v-if="currentMenu === 'Sale'">
            <report-tab />  
          </div>
  

        </div>
  
      </div>
      
    </div>
  </template>
  
  <script>

  import Sale from './Report.vue'

  
  export default {
    components: {
      
      "report-tab": Sale,
    
    },
    data() {
      return {
        currentMenu: "Sale",        
      }
    },
    firestore() {
      return {
      }
    },
    methods: {    
      
    },
    computed: {      
      menus() {
        const menus = [
          { name: "Sale" },
          
        ]
        return menus
      },
    },
  }
  </script>