<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div>
        <div style="padding-top: 20px; padding-right: 20px;">
          <a href="" @click.prevent="hideNursing = false">
            <button v-if="hideNursing" class="btn btn-secondary btn-lg" style="float: right;">
              <span style="float: right;"><i class="fas fa-eye"></i> View Nursing Assessment</span>
            </button>
          </a>
          <a href="" @click.prevent="hideNursing = true">
            <button v-if="!hideNursing" class="btn btn-secondary btn-lg" style="float: right;">
              <span style="float: right;"><i class="fas fa-eye"></i> Hide Nursing Assessment</span>
            </button>
          </a>
        </div>
      </div>
      <div class="main-menu mt-4">
        <div class="shadow text-success d-flex flex-wrap justify-content-center">
          <div class="menu-item mr-1" v-for="(menu, index) in menus" :key="index" @click.prevent="menu.action">
            <div class="menu-icon"
              :class="{ 'text-white': menu.name === currentMenu, 'bg-success': menu.name === currentMenu }">
              <i :class="menu.icon"></i>
            </div>
            <div class="mt-1">{{ menu.name }}</div>
          </div>
        </div>
      </div>

      <order-investigation :onClose="onClosed" :onConfirm="saveOpdCard" v-model="opd.invest_lab.invest_select" />
      <order-procedure :onClose="onClosed" :onConfirm="saveOpdCard" v-model="opd.treatment.procedure" />
      <order-medicine :onClose="onClosed" :onConfirm="saveOpdCard" v-model="opd.treatment.med_supply" />
      <order-special :onClose="onClosed" :onConfirm="saveOpdCard" v-model="opd.special_order" />
      <order-admit :onClose="onClosed" :onConfirm="saveOpdCard" v-model="opd.admit" />
      <order-dhf :onClose="onClosed" :onConfirm="saveOpdCard" v-model="opd.follow_up" />
      <order-lab :onClose="onClosed" :onConfirm="saveOpdCard" v-model="opd.invest_lab" />
      <order-ivf :onClose="onClosed" :onConfirm="saveOpdCard" v-model="opd.ivf" />
      <order-supplies :onClose="onClosed" :onConfirm="saveOpdCard" v-model="opd.supplies" />
      <order-refer :onClose="onClosed" :onConfirm="saveOpdCard" v-model="opd.refer" />
      <order-against-medical-advice :onClose="onClosed" :onConfirm="saveOpdCard" v-model="opd.against_medical_advice" />
      <mode-of-transfer :onClose="onClosed" :onConfirm="saveOpdCard" v-model="opd.refer" />
      <transfer-form :patient="patient" :onClose="onClosed" :onConfirm="saveOpdCard"
        v-model="opd.physician_transfer_form" />
      <div class="card-body">
        <!-- nursing assessment -->
        <transition name="fade">
          <div v-if="!hideNursing">
            <nurse-assessment-summary></nurse-assessment-summary>
          </div>
        </transition>
        <!-- nursing assessment end -->

        <div class="bg-gray">

          <div class="row ">
            <div class="col-6 mt-3 text-center">
              <div class="logo-box">
                <img src="/images/worldmed-logo-2.jpg" class="img-fluid" />
              </div>
              <div class="mt-2">
                <h3>
                  OPD Card
                </h3>
              </div>

            </div>
            <div class="col-6 detail-box flex-fill flex-row align-items-stretch mt-3">
              <div class="field">Name: {{ (patient.first_name || '') + ' ' + (patient.last_name || '') }}</div>
              <div class="field d-flex align-items-stretch">
                <div class="sub-field flex-2 border-r">Date of Birth: {{ patient.date_of_birth ?
                  formatDate(patient.date_of_birth) : '' }}</div>
                <div class="sub-field flex-1 border-r pl-2">Age: {{ patient.age || '' }}</div>
                <div class="sub-field flex-1 pl-2">Gender: {{ patient.gender || '' }}</div>
              </div>

              <div class="field d-flex align-items-stretch">
                <div class="sub-field flex-1 border-r">HN : {{ patient.HN }}</div>
                <div class="sub-field flex-1 border-r">VN : {{ $route.params.visitId }}</div>

              </div>
              <div class="field d-flex align-items-stretch">
                <div class="sub-field flex-1 ">Visit Date : {{
                  $route.params.visitId.slice(11, 13) + '/' + $route.params.visitId.slice(13, 15) + '/' +
                  $route.params.visitId.slice(15, 19)
                }}</div>
                <div class="sub-field flex-1"><span class="text-danger">Allergy** : </span>{{ patient.is_allergy.length
                  >
                  0 ? (patient.allergies || '') : '' }}
                </div>
              </div>


            </div>
          </div>

          <div class="p-3">
            <div class="row">
              <div class="col-6">
                <div>
                  <span class="label-b">Chief Complaint</span>
                </div>
                <div class="d-flex mt-2">
                  <!-- <div>
                    <div class="label">Duration before arrival</div>
                    <input type="text" class="form-control" v-model="opd.arrival_time" />
                  </div>
                  <div class="ml-2">
                    <div class="label">Unit</div>
                    <select id="rabies" class="form-control" v-model="opd.arrival_unit">
                      <option value selected></option>
                      <option :value="dd.name" v-for="(dd, index) in dd_duration_unit" :key="index">{{ dd.name }}
                      </option>
                    </select>
                  </div>
                  <div class="ml-2 flex-2">
                    <div class="label">Select Chief Complaint</div>
                    <input type="text" class="form-control w-100" v-model="opd.cc_name">
                  </div>
                  <div class="ml-2">
                    <div class="label">Notes</div>
                    <input id="cc_note" type="text" class="form-control" v-model="opd.cc_note" />
                  </div> -->
                  <textarea id="chief_complaint" cols="30" rows="4" class="form-control" style="min-height: 150px;"
                    v-model="opd.chief_complaint"></textarea>

                </div>
                <div class="mt-3">
                  <span class="label-b"><i class="fas fa-notes-medical"></i> Present Illness</span>
                  <textarea id="present_illness" cols="30" rows="4" class="form-control" style="min-height: 150px;"
                    v-model="opd.present_illness"></textarea>
                </div>

              </div>
              <div class="col-6">
                <div>
                  <span class="label-b">Investigation / X-ray ({{ opd.invest_lab.invest_select.length }})</span>
                </div>
                <div class="mt-2">
                  <div class="row mt-1" v-for="(i, index) in opd.invest_lab.invest_select" :key="'I' + index">
                    <div class="col" v-if="i.invest_date_time.toDate === 'function'">
                      {{ i.invest_date_time.toDate() | moment('DD-MM-YYYY HH:mm') }}
                    </div>
                    <div class="col" v-else>
                      {{ i.invest_date_time | moment('DD-MM-YYYY HH:mm') }}
                    </div>
                    <div class="col">
                      <b>{{ i.invest_type }}</b>
                    </div>
                    <div class="col" v-if="i.invest_ekg_type">
                      {{ i.invest_ekg_type }}
                    </div>
                    <div class="col" v-if="i.invest_organ_view">
                      {{ i.invest_organ_view }}
                    </div>
                  </div>
                  <!-- <textarea id="investigation" cols="30" rows="4" class="form-control" style="min-height: 150px;"
                    v-model="opd.investigation"></textarea> -->
                </div>
                <div class="mt-5">
                  <span class="label-b"><i class="fa fa-flask" aria-hidden="true"></i> Laboratory
                    ({{ opd.invest_lab.lab_select.length }})
                  </span>
                </div>
                <div>
                  <div class="mt-2">

                    <div class="row mt-1" v-for="(i, index) in opd.invest_lab.outlab_select" :key="'OL' + index">

                      <div class="col">
                        <b>{{ i.lab_test }}</b>
                      </div>

                      <div class="col">
                        {{ i.sample }}
                      </div>

                      <!-- <div class="col">
                        {{ i.turn_around_time }}
                      </div> -->
                    </div>
                    <div class="row mt-1" v-for="(i, index) in opd.invest_lab.lab_select" :key="'L' + index">

                      <div class="col">
                        <b>{{ i.lab_test }}</b>
                      </div>
                      <div class="col">
                        {{ i.sample }}
                      </div>

                      <!-- <div class="col">
                        {{ i.turn_around_time }}
                      </div> -->
                    </div>

                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-12">
                <div class="mt-4">
                  <span class="label-b"><i class="fas fa-stethoscope"></i> Physical Exam</span>
                  <div class="mt-2 pl-4">
                    <button class="btn btn-secondary" @click="allNormal">
                      All Normal
                    </button>
                  </div>
                  <div class="row mt-3">
                    <div class="col-4">
                      <div class="d-flex">
                        <div class="font-weight-bold flex-1">GA</div>
                        <div class="flex-fill">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="wnl" name="ga_check" id="ga_check_wnl"
                              v-model="opd.phys_exam.ga_check" />
                            <label class="form-check-label" for="ga_check_wnl">
                              WNL
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="abnormal" name="ga_check"
                              id="ga_check_ab" v-model="opd.phys_exam.ga_check" />
                            <label class="form-check-label" for="ga_check_ab">
                              Abnormal
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex" v-if="opd.phys_exam.ga_check !== 'wnl'">
                        <div class="flex-fill">
                          <input type="text" class="form-control" v-model="opd.phys_exam.ga" />
                        </div>
                      </div>
                    </div>

                    <div class="col-4">
                      <div class="d-flex">
                        <div class="font-weight-bold flex-1">Skin</div>
                        <div class="flex-fill">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="wnl" id="skin_check_wnl"
                              name="skin_check" v-model="opd.phys_exam.skin_check" />
                            <label class="form-check-label" for="skin_check_wnl">
                              WNL
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="abnormal" id="skin_check_ab"
                              name="skin_check" v-model="opd.phys_exam.skin_check" />
                            <label class="form-check-label" for="skin_check_ab">
                              Abnormal
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex" v-if="opd.phys_exam.skin_check !== 'wnl'">
                        <div class="flex-fill">
                          <input type="text" class="form-control" v-model="opd.phys_exam.skin" />
                        </div>
                      </div>

                    </div>
                    <div class="col-4">
                      <div class="d-flex">
                        <div class="font-weight-bold flex-1">HEENT</div>
                        <div class="flex-fill">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="wnl" id="heent_check_wnl"
                              name="heent_check" v-model="opd.phys_exam.heent_check" />
                            <label class="form-check-label" for="heent_check_wnl">
                              WNL
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="abnormal" id="heent_check_ab"
                              name="heent_check" v-model="opd.phys_exam.heent_check" />
                            <label class="form-check-label" for="heent_check_ab">
                              Abnormal
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex" v-if="opd.phys_exam.heent_check !== 'wnl'">
                        <div class="flex-fill">
                          <input type="text" class="form-control" v-model="opd.phys_exam.heent" />
                        </div>
                      </div>

                    </div>

                  </div>

                  <div class="row mt-3">
                    <div class="col-4">
                      <div class="d-flex">
                        <div class="font-weight-bold flex-1">RS</div>
                        <div class="flex-fill">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="wnl" id="rs_check_wnl" name="rs_check"
                              v-model="opd.phys_exam.rs_check" />
                            <label class="form-check-label" for="rs_check_wnl">
                              WNL
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="abnormal" id="rs_check_ab"
                              name="rs_check" v-model="opd.phys_exam.rs_check" />
                            <label class="form-check-label" for="rs_check_ab">
                              Abnormal
                            </label>
                          </div>
                        </div>
                        <div class="d-flex" v-if="opd.phys_exam.rs_check !== 'wnl'">
                          <div class="flex-fill">
                            <input type="text" class="form-control" v-model="opd.phys_exam.rs" />
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-4">
                      <div class="d-flex">
                        <div class="font-weight-bold flex-1">CVS</div>
                        <div class="flex-fill">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="wnl" id="cvs_check_wnl" name="cvs_check"
                              v-model="opd.phys_exam.cvs_check" />
                            <label class="form-check-label" for="cvs_check_wnl">
                              WNL
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="abnormal" id="cvs_check_ab"
                              name="cvs_check" v-model="opd.phys_exam.cvs_check" />
                            <label class="form-check-label" for="cvs_check_ab">
                              Abnormal
                            </label>
                          </div>

                        </div>
                      </div>
                      <div class="d-flex" v-if="opd.phys_exam.cvs_check !== 'wnl'">
                        <div class="flex-fill">
                          <input type="text" class="form-control" v-model="opd.phys_exam.cvs" />
                        </div>

                      </div>

                    </div>
                    <div class="col-4">
                      <div class="d-flex">
                        <div class="font-weight-bold flex-1">Abdoment</div>
                        <div class="flex-fill">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="wnl" id="abdomen_check_wnl"
                              name="abdomen_check" v-model="opd.phys_exam.abdomen_check" />
                            <label class="form-check-label" for="abdomen_check_wnl">
                              WNL
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="abnormal" id="abdomen_check_ab"
                              name="abdomen_check" v-model="opd.phys_exam.abdomen_check" />
                            <label class="form-check-label" for="abdomen_check_ab">
                              Abnormal
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex" v-if="opd.phys_exam.abdomen_check !== 'wnl'">
                        <div class="flex-fill">
                          <input type="text" class="form-control" v-model="opd.phys_exam.abdomen" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-4">
                      <div class="d-flex">
                        <div class="font-weight-bold flex-1">Extremities</div>
                        <div class="flex-fill">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="wnl" id="extrem_check_wnl"
                              name="extrem_check" v-model="opd.phys_exam.extrem_check" />
                            <label class="form-check-label" for="extrem_check_wnl">
                              WNL
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="abnormal" id="extrem_check_ab"
                              name="extrem_check" v-model="opd.phys_exam.extrem_check" />
                            <label class="form-check-label" for="extrem_check_ab">
                              Abnormal
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex" v-if="opd.phys_exam.extrem_check !== 'wnl'">
                        <div class="flex-fill">
                          <input type="text" class="form-control" v-model="opd.phys_exam.extrem" />
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="d-flex">
                        <div class="font-weight-bold flex-1">Neuro</div>
                        <div class="flex-fill">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="wnl" id="neuro_check_wnl"
                              name="neuro_check" v-model="opd.phys_exam.neuro_check" />
                            <label class="form-check-label" for="neuro_check_wnl">
                              WNL
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="abnormal" id="neuro_check_ab"
                              name="neuro_check" v-model="opd.phys_exam.neuro_check" />
                            <label class="form-check-label" for="neuro_check_ab">
                              Abnormal
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex" v-if="opd.phys_exam.neuro_check !== 'wnl'">
                        <div class="flex-fill">
                          <input type="text" class="form-control" v-model="opd.phys_exam.neuro" />
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="d-flex">
                        <div class=" font-weight-bold flex-1">Other</div>
                        <div class="flex-fill">
                          <input type="text" class="form-control" v-model="opd.phys_exam.other" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3 row">
                    <div class="col-7">
                      <div>
                        <span class="label-b">
                          <i class="fas fa-cloud-upload-alt"></i> Upload Image
                        </span>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12">
                          <input type="file" hidden ref="file_input" @input="handleFile" />
                          <button class="btn bg-white" style="height: 55px; width: 140px;"
                            @click.prevent="$refs.file_input.click()">
                            Add <i class="fa fa-camera"></i>
                          </button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-4 col-sm-12 mt-4" v-for="(file, index) in opd.files" :key="file.id">
                          <div class="show-img-box">
                            <a :href="file.url" target="_blank"><img class="img-preview" :src="file.url"
                                id="show-btn" /></a>
                          </div>
                          <div class="text-center mt-2">
                            <a :href="file.url" target="_blank"><i class="far fa-file"></i> {{ file.name }}</a>
                            <button class="btn btn-danger ml-2" @click.prevent="opd.files.splice(index, 1)">
                              <i class="fas fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-5">
                      <div class="row ">
                        <body-part v-model="opd.body_part_image" :id="'opd_bodypart'"
                          :onSelectImage="onSelectImage"></body-part>
                      </div>
                      <div class="row ml-2">
                        <image-drawer ref="opd_drawer"></image-drawer>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-3">
            <div class="row">
              <div class="col-7">
                <div class="ml-4">
                  <span class="label-b"> Diagnosis ({{ opd.diagnosis.length }})</span>
                </div>
                <div class="mt-2 ml-2" v-for="(i, index) in opd.diagnosis" :key="index">
                  <div class="mt-2 d-flex">
                    <div class="flex flex-fill">
                      <icd10-select v-model="opd.diagnosis[index]" :iNo="index" />
                    </div>
                    <div class="flex">
                      <button type="button" class="btn btn-primary" @click="addRowList('opd', 'diagnosis', 1)">
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                    <div class="flex ml-2">
                      <button type="button" class="btn btn-secondary" @click="removeRowList('opd', 'diagnosis', index)">
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-5">
                <img :src="opd.body_part_image_url">
              </div>
            </div>


          </div>

          <div class="row">
            <div class="col-12">
              <div class="p-3 mt-3">
                <span class="label-b "><i class="fas fa-syringe" aria-hidden="true"></i> Treatment / Procedure
                  ({{ opd.treatment.med_supply.length + opd.treatment.procedure.length }})
                </span>
              </div>
              <div class="bg-gray ml-5">
                <div class="d-flex " v-for="(i, index) in opd.treatment.procedure" :key="'P' + index">
                  <div class="flex-2 ml-1" v-if="i.procedure_date_time.toDate === 'function'">
                    {{ i.procedure_date_time.toDate() | moment('DD-MM-YYYY HH:mm') }}
                  </div>
                  <div class="flex-2 ml-1" v-else>
                    {{ i.procedure_date_time | moment('DD-MM-YYYY HH:mm') }}
                  </div>
                  <div class="flex-1 ml-3">
                    {{ i.procedure_name }}
                  </div>
                  <div class="pl-3 flex-2" style="min-width: 320px;">
                    {{ i.procedure_notes }}
                  </div>
                </div>
                <div class="d-flex" v-for="(i, index) in opd.treatment.med_supply" :key="'M' + index">
                  <div class="flex-2 ml-1" v-if="i.med_supply_date_time.toDate === 'function'">
                    {{ i.med_supply_date_time.toDate() | moment('DD-MM-YYYY HH:mm') }}
                  </div>
                  <div class="flex-2 ml-1" v-else>
                    {{ i.med_supply_date_time | moment('DD-MM-YYYY HH:mm') }}
                  </div>
                  <div class="flex-1 ml-3">
                    {{ i.med_supply_medicine }}
                  </div>
                  <div class="pl-3 flex-2" style="min-width: 320px;">
                    {{ i.med_supply_amount }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row p-3">
            <div class="col-6">
              <span class="label-b"> Advice</span>
              <textarea id="advice" cols="30" rows="4" class="form-control" style="min-height: 150px;"
                v-model="opd.advice"></textarea>
            </div>
            <div class="col-6">
              <span class="label-b"> Comment</span>
              <textarea id="comment" cols="30" rows="4" class="form-control" style="min-height: 150px;"
                v-model="opd.comment"></textarea>
            </div>
          </div>
          <div clas="row p-3">
            <div class="row">
              <div class="col-2">
                <span class="label-b ml-3">Follow up:</span>
                <div class="d-flex">
                  <div class="flex ml-3">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" value="no" id="follow_up_no" name="follow_up_no"
                        v-model="opd.follow_up" />
                      <label class="form-check-label" for="follow_up_no">
                        NO
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" value="yes" id="follow_up_yes" name="follow_up_yes"
                        v-model="opd.follow_up" />
                      <label class="form-check-label" for="follow_up_yes">
                        YES
                      </label>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-3" v-if="opd.follow_up === 'yes'">
                <div class="">
                  <span class="label-b">Date : </span>
                  <div class="flex">
                    <MazPicker v-model="follow_up_date" />
                  </div>
                </div>
              </div>
              <div class="col-2" v-if="opd.follow_up === 'yes'">
                <div class="">
                  <span class="label-b">Reason : </span>
                  <div class="flex">
                    <input v-model="follow_reason" />
                  </div>
                </div>
              </div>
              <div class="col-6">

              </div>
            </div>

            <div class="row ">
              <div class="col-6 w-100">
                <span class="label-b">Plan : </span>
                <div class="flex">
                  <input v-model="follow_plan" />
                </div>
              </div>
            </div>


          </div>

        </div>
        <hr />

        <order-summary class="mt-4" :opd="opd" />
        <div>
          <!-- <div class="mt-4">
            <b-tabs
              content-class=""
              class="tab-custom"
              active-nav-item-class="font-weight-bold text-light"
            >
              <div class="bg-office" style="height: 20px;"></div>
              <b-tab title="Investigation & Laboratory"
                ><investigation-lab v-model="opd.invest_lab"
              /></b-tab>
              <b-tab title="Treatment"
                ><treatment v-model="opd.treatment"
              /></b-tab>
              <b-tab title="Follow-Up & Recommendation"
                ><follow-up v-model="opd.follow_up"
              /></b-tab>
            </b-tabs>
          </div> -->
          <hr />
          <div class="text-center">
            <b-button variant="primary" class="btn-lg" style="width: 190px;" v-b-modal.new_opd>Confirm Order</b-button>
            <button style="width: 160px;" type="button" class="btn btn-danger btn-lg ml-2">
              Cancel
            </button>
            <router-link tag="button" :to="{
              name: 'Doctor Order',
              params: { id: $route.params.id, visitId: $route.params.visitId }
            }" style=" width: 230px;" type="button" class="btn btn-gray btn-lg ml-2">
              View Order Summary
            </router-link>
          </div>
          <b-modal id="new_opd" title="Create new OPD" hide-footer ref="new_opd">
            <div class="bg-red p-2">
              <div class="d-flex">
                <div class="p-2 text-center">
                  <div>
                    <i class="fa fa-exclamation-triangle" style="color: red; font-size: 50px;"></i>
                  </div>
                  <div>
                    <p style="color: red;"><b>WARNING:</b></p>
                  </div>
                </div>
                <div class="txt-warning-red mt-3">
                  <b><u>THIS PATIENT HAVE THE FOLLOWING:</u></b>
                </div>
              </div>
              <div class="p-2">
                <span v-if="nursing.underlying" class="txt-warning-red"><u>Underlying Disease: </u></span>
                <span v-for="(i, index) in nursing.underlying" :key="i.id">
                  {{ i.underlying }}
                  <span v-if="index < nursing.underlying.length - 1">, </span>
                </span>
                <span v-if="nursing.current_medication" class="txt-warning-red"><u>Medicine Taken: </u></span>
                <span v-for="(i, index) in nursing.current_medication" :key="i.id">
                  {{ i.medication }}
                  <span v-if="index < nursing.current_medication.length - 1">,
                  </span>
                </span>
              </div>
              <button class="btn btn-block bg-warning-red text-white mt-2" @click="saveOpdCard">
                <i class="fas fa-check"></i> Confirm
              </button>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import opdMixins from "../../../mixins/opd";
import dd_duration_unit from '../../../assets/duration_unit.json';
import moment from 'moment'
export default {
  mixins: [opdMixins],
  props: ["patient"],
  data() {
    return {
      hideNursing: true,
      dd_duration_unit,
      // date picker,
      menus: [
        { name: 'Investigation', icon: 'fas fa-x-ray', action: () => { this.$bvModal.show('order-investigation'); this.currentMenu = 'Investigation'; } },
        { name: 'Procedure', icon: 'fas fa-stethoscope', action: () => { this.$bvModal.show('order-procedure'); this.currentMenu = 'Procedure'; } },
        { name: 'Medicine', icon: 'fas fa-capsules', action: () => { this.$bvModal.show('order-medicine'); this.currentMenu = 'Medicine'; } },
        { name: 'Special Orders', icon: 'fas fa-paste', action: () => { this.$bvModal.show('order-special'); this.currentMenu = 'Special Orders'; } },
        { name: 'Admit', icon: 'fas fa-bed', action: () => { this.$bvModal.show('order-admit'); this.currentMenu = 'Admit'; } },
        { name: 'Laboratory', icon: 'fas fa-microscope', action: () => { this.$bvModal.show('order-laboratory'); this.currentMenu = 'Laboratory'; } },
        { name: 'IVF', icon: 'fas fa-syringe', action: () => { this.$bvModal.show('order-ivf'); this.currentMenu = 'IVF'; } },
        { name: 'Supplies', icon: 'fas fa-medkit', action: () => { this.$bvModal.show('order-supplies'); this.currentMenu = 'Supplies'; } },
        { name: 'Follow Up & Recommendations', icon: 'fas fa-book-medical', action: () => { this.$bvModal.show('order-dhf'); this.currentMenu = 'Discharge,Home Med,Follow Up'; } },
        { name: 'Refer', icon: 'fas fa-ambulance', action: () => { this.$bvModal.show('order-refer'); this.currentMenu = 'Refer'; } },
        { name: 'Against Medical Advice', icon: 'fas fa-user-md', action: () => { this.$bvModal.show('order-against'); this.currentMenu = 'Against Medical Advice'; } },
      ],
      rows: [],
      currentMenu: null
    };
  },
  methods: {
    formatDate(data) {
      return moment(data.toDate()).format("DD/MM/YYYY")
    },
    allNormal() {
      this.opd.phys_exam.ga_check = "wnl";
      this.opd.phys_exam.skin_check = "wnl";
      this.opd.phys_exam.heent_check = "wnl";
      this.opd.phys_exam.rs_check = "wnl";
      this.opd.phys_exam.cvs_check = "wnl";
      this.opd.phys_exam.abdomen_check = "wnl";
      this.opd.phys_exam.extrem_check = "wnl";
      this.opd.phys_exam.neuro_check = "wnl";
    },
    onClosed() {
      this.currentMenu = null
    },
    onSelectImage(image) {
      if (image) {
        this.$refs['opd_drawer'].setImage(image.url)
      }
    },
    mounted() {
      if (this.opd.body_part_image_url) {
        this.$refs['opd_drawer'].setImage(this.opd.body_part_image_url)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.logo-box {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}

.bg-allergy {
  background-image: linear-gradient(180deg, #fff, #f0ad6b 70%);
}

.holder {
  height: 62px;
}

.center {
  text-align: center;
}

.bg-w {
  background-color: #fff;
}

.label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.box-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.label-b {
  color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}

.esl-lv {
  border: 3px solid rgba(0, 0, 0, 0.3);
  width: 40px;
  height: 40px;
  margin-top: 10px;
  border-radius: 7px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  margin-left: 10px;
}

.form-check {
  margin-left: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}

button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

input[type="radio"] {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

input[type="checkbox"] {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

.show-img-box {
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  height: 370px;
  /* minimum height */
  width: 100%;
  position: relative;
  background-color: #fff;
  cursor: pointer;
  border-radius: 0.25em;
}

.img-preview {
  max-height: 370px;
  width: 100%;
  border-radius: 0.25em;
}

.form-control {
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
  height: 2.855rem;
  min-height: 2.855rem;
  margin-top: 0.032rem;
  border-color: #eee;
}

.main-menu {
  background-color: #efefef;
  padding: 5px;
  border-radius: 0.25em;
  min-height: 100px;
}

.menu-item {
  width: 120px;
  height: 160px;
  text-align: center;
  cursor: pointer;
  padding: 5px;
}

.menu-item-active {
  color: white;
  background-color: greenyellow;
}

.menu-icon {
  font-size: 48px;
  padding: 5px;
  border-radius: 0.25em;
  background-color: white;
}
</style>
