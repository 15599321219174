<template>
    <div class="container-fluid">
        <div class="d-flex">
            <h3 class="text-primary">Product Summary</h3>
            <button class="btn btn-success ml-auto" @click="$refs['products-csv'].click()">
                Import CSV
            </button>
            <input id="products-csv" ref="products-csv" type="file" hidden
                accept=".csv, .xls, .xlsx, text/csv, application/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.msexcel, text/anytext, application/vnd. ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                @change="uploadCsv" />
            <button class="btn btn-success ml-2" @click="exportCsv(stocks, 'Stocks_Data')">
                Download CSV
            </button>
            <button class="btn btn-primary ml-2" @click="showStockItem()">
                <i class="fas fa-plus"></i> Add
            </button>
 
        </div>

        <vue-good-table :columns="stockColumns" :rows="stocks" @on-row-click="showStockItem"
            :pagination-options="{ enabled: true, mode:'pages'}" class="stock-table" :search-options="{ enabled: true }"
            :sort-options="{ enabled: false }">


            <template v-slot:table-row="props">

                <span v-if="props.column.field === 'branch'">
                    {{ props.row.branch === 0 ? 'Main Stock' : $store.state.branches[props.row.branch - 1]?.name_en }}
                </span>
                <span v-else-if="props.column.field === 'item_name'" style="display: block;">
                    <span style="display: block;">{{ props.row.item_name }}</span>
                    <span style="font-size: smaller; text-decoration: underline; display: block;">
                        {{ props.row.item_other_name }}
                    </span>
                </span>
                <span v-else-if="props.column.field === 'expire_date'">
                    {{
                        formatDate(props.row.expire_date)

                    }}
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>


        </vue-good-table>



        <b-modal id="stock-item-modal" hide-footer size="lm" :title="'Stock Item'" no-close-on-backdrop>
            <div class="row">
                <div class="col-12 mt-2" v-if="!isEditStock">
                    <div v-if="videoInputDevices.length > 1" id="sourceSelectPanel">
                        <label for="sourceSelect">Select Camera: </label>
                        <select v-model="selectedDeviceId" id="sourceSelect">
                            <option v-for="device in videoInputDevices" :key="device.deviceId" :value="device.deviceId">
                                {{ device.label }}
                            </option>
                        </select>
                    </div>

                    <button @click="startScan" id="startButton" class="float-left">Start</button>
                    <button @click="resetScan" id="resetScan" class="float-right">Stop</button>

                    <div class="d-flex justify-content-center">
                        <video id="video" width="300" height="200" style="border: 1px solid gray"></video>
                    </div>
                    <label>Result:</label>
                    <pre><code> {{ scanResult }}</code></pre>

                    <div>
                        {{ stock.item_code }}
                    </div>
                    <MazInput :placeholder="'Barcode'" v-model="stock.barcode" />
                </div>
                <div class="col-12 mt-2">
                    <div v-if="isEditStock">
                        <v-select class="ml-auto" v-model="stock.item_code" :options="prepared_medicines"
                            label="Item name" :reduce="item => item.item_code" searchable filterable
                            :filter="filterProduct" disabled>
                            <template v-slot:option="option">
                                <div>
                                    <span>{{ option.item_name }}</span><br>
                                    <span v-if="option.item_other_name"
                                        style="font-size: smaller; text-decoration: underline;">
                                        {{ option.item_other_name }}
                                    </span>
                                </div>
                            </template>

                            <template v-slot:selected-option="option">
                                <div>
                                    <span style="display: block;">{{ option.item_name }}</span>
                                    <span v-if="option.item_other_name"
                                        style="font-size: smaller; text-decoration: underline; display: block;">
                                        {{ option.item_other_name }}
                                    </span>
                                </div>
                            </template>
                        </v-select>
                    </div>
                    <div v-else>
                        <v-select class="ml-auto" v-model="stock.item_code" :options="prepared_medicines"
                            label="Item name" :reduce="item => item.item_code" searchable filterable
                            :filter="filterProduct">
                            <template v-slot:option="option">
                                <div>
                                    <span>{{ option.item_name }}</span><br>
                                    <span v-if="option.item_other_name"
                                        style="font-size: smaller; text-decoration: underline;">
                                        {{ option.item_other_name }}
                                    </span>
                                </div>
                            </template>

                            <template v-slot:selected-option="option">
                                <div>
                                    <span style="display: block;">{{ option.item_name }}</span>
                                    <span v-if="option.item_other_name"
                                        style="font-size: smaller; text-decoration: underline; display: block;">
                                        {{ option.item_other_name }}
                                    </span>
                                </div>
                            </template>
                        </v-select>
                    </div>

                </div>
                <div class="col-12 mt-2">
                    <MazInput type="number" :placeholder="'Quantity'" v-model="stock.quantity" />
                </div>
                <div class="col-12 mt-2">
                    <div v-if="isEditStock">
                        <MazPicker v-model="stock.expire_date" :formatted="`DD/MM/YYYY`" placeholder="Expire Date"
                            no-time disabled />
                    </div>
                    <div v-else>
                        <MazPicker v-model="stock.expire_date" :formatted="`DD/MM/YYYY`" placeholder="Expire Date"
                            no-time />
                    </div>

                </div>
                <div class="col-12 mt-2">
                    <div v-if="isEditStock">
                        <select v-model="stock.branch" class="form-control form-select" disabled>
                            <option :value="0">Main Stock</option>
                            <option v-for="dd in $store.state.branches" :key="dd.id" :value="Number(dd.id)">{{
                                dd.name_en }}
                            </option>
                        </select>
                    </div>
                    <div v-else>
                        <select v-model="stock.branch" class="form-control form-select">
                            <option :value="0">Main Stock</option>
                            <option v-for="dd in $store.state.branches" :key="dd.id" :value="Number(dd.id)">{{
                                dd.name_en }}
                            </option>
                        </select>
                    </div>

                </div>

                <div class="col-12 mt-2">
                    <button class="btn btn-primary btn-block" @click="saveStock">
                        <i class="fas fa-save"></i> Save
                    </button>
                </div>
                <div class="col-12 mt-2" v-if="isEditStock">
                    <button class="btn btn-danger btn-block" @click="deleteStockItem(stock.id)">
                        <i class="fas fa-trash"></i> Delete Stock Item
                        {{ stock.item_code }}
                    </button>
                </div>
            </div>
        </b-modal>

        <b-modal id="import-item-modal" hide-footer size="xl" :title="'Import Item'" no-close-on-backdrop>

            <div class="col-12 mt-2">
                <draggable v-model="import_product_items" group="import_product_items" @start="drag = true"
                    @end="drag = false">
                    <div class="row mb-2" v-for="(item, index) in import_product_items" :key="index">
                        <div class="col-1">
                            <div class="m-4 p-2">
                                <i class="fas fa-bars" style="color: grey"></i>
                            </div>
                        </div>
                        <div class="col-2">
                            <label class="text-primary">Expire Date</label>
                            <MazPicker v-model="item.expire_date" format="DD/MM/YYYY" :formatted="`DD/MM/YYYY`"
                                no-time />
                        </div>
                        <div class="col-2">
                            <label class="text-primary">Branch</label>
                            <select v-model="item.branch" class="form-control form-select">
                                <option :value="0">Main Stock</option>
                                <option v-for="dd in $store.state.branches" :key="dd.id" :value="Number(dd.id)">{{
                                    dd.name_en }}
                                </option>
                            </select>
                        </div>
                        <div class="col-4">
                            <label class="text-primary">Item</label>
                            <v-select class="ml-auto" v-model="item.item_code" :options="prepared_medicines"
                                label="item_name" :reduce="item => item.item_code" searchable filterable
                                :filter="filterProduct">
                                <template v-slot:option="option">
                                    <div>
                                        <span>{{ option.item_name }}</span><br>
                                        <span v-if="option.item_other_name"
                                            style="font-size: smaller; text-decoration: underline;">
                                            {{ option.item_other_name }}
                                        </span>
                                    </div>
                                </template>

                                <template v-slot:selected-option="option">
                                    <div>
                                        <span style="display: block;">{{ option.item_name }}</span>
                                        <span v-if="option.item_other_name"
                                            style="font-size: smaller; text-decoration: underline; display: block;">
                                            {{ option.item_other_name }}
                                        </span>
                                    </div>
                                </template>
                            </v-select>
                        </div>

                        <div class="col-2">
                            <label class="text-primary">Quantity</label>
                            <input type="number" class="form-control" v-model="item.quantity">
                        </div>
                        <div class="col-1">
                            <button class="btn btn-light mt-4" @click="import_product_items.splice(index, 1)"><i
                                    class="fas fa-trash"></i></button>
                        </div>

                    </div>
                </draggable>
            </div>
            <div class="d-flex flex-row-reverse col-12 mt-2">
                <div class="p-3 col-3">
                    <button class="btn btn-success btn-block" @click="saveImport">
                        <i class="fas fa-save"></i> Save
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { db, storage } from "../../db"
import { BrowserMultiFormatReader, ZXing } from '@zxing/library'
import moment from "moment"
import Parser from "@json2csv/plainjs/dist/cjs/Parser"
import draggable from 'vuedraggable'


export default {
    components: {
        draggable,
    },
    data() {
        return {
            medicines: [],
            stocks: [],
            stock: {},
            import_product_items: [],
            isEditStock: false,
            stockColumns: [
                { label: "Item Code", field: "item_code" },
                { label: "Item Name", field: "item_name" },
                { label: "Branch", field: "branch" },
                {
                    label: "Expire Date", field: "expire_date",

                    tdClass: (row) => { return this.changeColorClass(row.expire_date) }
                },
                { label: "Barcode", field: "barcode" },
                { label: "Quantity", field: "quantity", thClass: 'text-right', tdClass: 'text-right' },
            ],
            codeReader: null,               // ZXing code reader instance
            videoInputDevices: [],          // List of video input devices (cameras)
            selectedDeviceId: '',           // ID of the selected camera
            scanResult: '',                 // Result of QR/Barcode scan
            barcodes: [],                   // Data barcodes
            stream: null,            
        }
    },
    firestore() {
        return {
            stocks: db.collection("Stock").orderBy('expire_date', 'asc'),
            medicines: db.collection("Product").orderBy('item_code', 'asc'),
        }
    },
    watch: {
        'stock.item_code': function (newItemCode) {
            if (!newItemCode) {
                this.stock.item_name = ''
                this.stock.item_other_name = ''
                this.stock.item_code = ''
            } else {
                const selectedMedicine = this.medicines.find(medicine => medicine.item_code === newItemCode)
                if (selectedMedicine) {
                    this.stock.item_code = selectedMedicine.item_code
                    this.stock.item_name = selectedMedicine.item_name
                    this.stock.item_other_name = selectedMedicine.item_other_name || ''
                }
            }
        },
        'scanResult': function (val) {
            this.stock.barcode = val
            if (val !== '') {
                const selectedMedicine = this.medicines.find(medicine => {
                    const valStr = String(val)
                    if (Array.isArray(medicine.barcode)) {
                        return medicine.barcode.some(code => String(code) === valStr)
                    } else {
                        return String(medicine.barcode).includes(valStr)
                    }
                })

                if (selectedMedicine) {
                    this.stock.item_code = selectedMedicine.item_code
                    this.stock.item_name = selectedMedicine.item_name
                    this.stock.item_other_name = selectedMedicine.item_other_name || ''
                } else {
                    this.stock.item_code = ''
                    this.stock.item_name = ''
                    this.stock.item_other_name = ''
                    this.stock.barcode = ''
                }
            }
        },
        'stock.barcode': function (val) {
            if (val !== '') {
                const selectedMedicine = this.medicines.find(medicine => {
                    const valStr = String(val)
                    if (Array.isArray(medicine.barcode)) {
                        return medicine.barcode.some(code => String(code) === valStr)
                    } else {
                        return String(medicine.barcode).includes(valStr)
                    }
                })

                if (selectedMedicine) {
                    this.stock.item_code = selectedMedicine.item_code
                    this.stock.item_name = selectedMedicine.item_name
                    this.stock.item_other_name = selectedMedicine.item_other_name || ''
                } else {
                    this.stock.item_code = ''
                    this.stock.item_name = ''
                    this.stock.item_other_name = ''
                }
            }
        },
    },
    methods: {
        exportCsv(datas, name) {
            try {
                const selectedData = datas.map((data) => ({
                    Item_code: data.item_code,
                    Item_name: data.item_name,
                    Generic_name: data.item_other_name,
                    Branch: data.branch,
                    Expire_date: moment(data.expire_date).format("DD/MM/YYYY"),
                    Barcode: data.barcode,
                    Quantity: data.quantity,                    
                }))

                const parser = new Parser()
                const myData = JSON.parse(JSON.stringify(selectedData))
                const csv = parser.parse(myData)
                const anchor = document.createElement("a")
                anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
                anchor.target = "_blank"
                anchor.download = "data_" + name + ".csv"
                anchor.click()
                anchor.remove()
            } catch (err) {
                console.error(err)
            }
        },
        async registerBarcode(item_code, barcode) {
            const result = await db.collection('Product').doc(item_code).get()
            const data = result.data()
            if ('barcode' in data) {
                if (!data.barcode.includes(barcode)) {
                    data.barcode.push(barcode)
                }
            }
            else {
                data.barcode = [barcode]
            }
            try {
                await db.collection('Product').doc(item_code).set(data)
            } catch (error) {
                console.log(error)
            }
        },
        CSVToArray(strData, strDelimiter) {

            strDelimiter = (strDelimiter || ",")

            var objPattern = new RegExp(
                (
                    // Delimiters.
                    "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
                    // Quoted fields.
                    "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
                    // Standard fields.
                    "([^\"\\" + strDelimiter + "\\r\\n]*))"
                ),
                "gi"
            )
            var arrData = [[]]
            var arrMatches = null
            while (arrMatches = objPattern.exec(strData)) {
                var strMatchedDelimiter = arrMatches[1]
                if (
                    strMatchedDelimiter.length &&
                    strMatchedDelimiter !== strDelimiter
                ) {
                    arrData.push([])
                }
                var strMatchedValue;
                if (arrMatches[2]) {
                    strMatchedValue = arrMatches[2].replace(
                        new RegExp("\"\"", "g"),
                        "\""
                    )
                } else {
                    strMatchedValue = arrMatches[3]
                }
                arrData[arrData.length - 1].push(strMatchedValue)
            }
            return (arrData)
        },
        csvArrayToObj(csvData) {
            return csvData
                .map((csvLine, csvIndex) => {
                    if (csvIndex === 0 || !csvLine.length) return null // skip header and empty lines
                    return csvLine.reduce((a, v, i) => ({ ...a, [csvData[0][i]]: v }), {})
                })
                .filter((filter) => !!filter) //filter empty lines
        },
        async saveImport() {
            let loader = this.$loading.show({})
            for (const stock of this.import_product_items) {
                try {
                    stock.expire_date = moment(stock.expire_date, "DD/MM/YYYY").format("YYYY-MM-DD 12:00 a")
                    stock.branch = Number(stock.branch)
                    stock.quantity = Number(stock.quantity)
                    const stocklogRef = await db.collection('Stock')
                        .where('item_code', '==', stock.item_code)
                        .where('expire_date', '==', stock.expire_date)
                        .where('branch', '==', stock.branch)
                        .get()
                    if (stock.barcode !== "") {
                        await this.registerBarcode(stock.item_code, stock.barcode)
                    }
                    let selectedMedicine = this.medicines.find(medicine => medicine.item_code === stock.item_code)
                    stock.item_name = selectedMedicine.item_name
                    stock.item_other_name = selectedMedicine.item_other_name

                    const result = await db.collection('Product').doc(stock.item_code).get()
                    const data = result.data()

                    if (!stocklogRef.empty) {
                        let doc = stocklogRef.docs[0]
                        let product = doc.data()
                        let updatedQuantity = (Number(product.quantity) || 0) + Number(stock.quantity)
                        await db.collection('Stock').doc(doc.id).update({ quantity: updatedQuantity })
                    } else {
                        const stockRef = await db.collection("Stock").add(stock)
                        await db.collection("Stock").doc(stockRef.id).update({ id: stockRef.id })
                    }
                    const updatedQuantity = (Number(data.quantity) || 0) + stock.quantity
                    const payload = {
                        ...data,
                        quantity: updatedQuantity
                    }
                    try {
                        await db.collection('Product').doc(stock.item_code).set(payload)
                    } catch (error) {
                        console.log(error)
                    }


                } catch (error) {
                    this.$alert(`error ${error}`, null, "error")
                    loader.hide()
                }
            }
            let transfer = []
            transfer.details = this.import_product_items
            transfer.issue_date = moment().format('DD/MM/YYYY HH:mm')
            transfer.tobranch = null
            transfer.frombranch = null
            transfer = { ...transfer }
            const transferRef = await db.collection("Transfer").add(transfer)
            await db.collection("Transfer").doc(transferRef.id).update({ id: transferRef.id })

            loader.hide()
            this.$alert("success", null, "success")
            this.$bvModal.hide("import-item-modal")
        },
        async uploadCsv() {
            let promise = new Promise((resolve, reject) => {
                const reader = new FileReader()
                const fileSelector = document.getElementById("products-csv")
                const file = fileSelector.files[0]
                reader.onload = (e) => {
                    resolve((this.productsFileData = reader.result))
                }
                reader.onerror = reject
                reader.readAsText(file)
                fileSelector.value = ""
            })
            promise.then(async (result) => {
                this.preparedProducts = this.CSVToArray(this.productsFileData)
                this.preparedProducts = this.csvArrayToObj(this.preparedProducts)
                this.$bvModal.show("import-item-modal")
                this.import_product_items = this.preparedProducts
            })


        },
        changeColorClass(value) {
            let givenDate = moment(value, "YYYY-MM-DD hh:mm A")
            let currentDate = moment()
            let diffInDays = givenDate.diff(currentDate, 'days')
            if (diffInDays < 90) {
                return 'bg-red'
            } else if (diffInDays >= 90 && diffInDays < 180) {
                return 'bg-yellow'
            } else {
                return ''
            }

        },
        formatDate(dateString) {
            dateString = dateString.replaceAll('-', '/').slice(0, 10)
            const myArray = dateString.split('/')
            dateString = myArray[2] + '/' + myArray[1] + '/' + myArray[0]
            return dateString
        },
        showStockItem(params) {
            if (params) {
                this.stock = { ...params.row }
                this.isEditStock = true
            } else {
                this.stock = { branch: 0, }
                this.isEditStock = false

            }
            this.$bvModal.show("stock-item-modal")
        },
        async saveStock() {
            let loader = this.$loading.show({})
            if (!this.stock.item_code || !this.stock.quantity || !this.stock.expire_date) {
                this.$alert("please enter item code and quantity and expire_date", null, "error")
                loader.hide()
                return
            }
            if (this.isEditStock) {
                let stock = { ...this.stock }
                const stocklogRef = await db.collection('Stock')
                    .where('item_code', '==', stock.item_code)
                    .where('expire_date', '==', stock.expire_date)
                    .where('branch', '==', stock.branch)
                    .get()
                let doc = stocklogRef.docs[0]
                let product = doc.data()
                let old_quantity = product.quantity
                let updatedQuantity = Number(this.stock.quantity)
                await db.collection('Stock').doc(doc.id).update({ quantity: updatedQuantity })

                product = await db.collection('Product').doc(this.stock.item_code).get()
                let data = product.data()
                const updatedQuantity_product = (Number(data.quantity) || 0) + Number(this.stock.quantity) - Number(old_quantity)
                const payload = {
                    ...data,
                    quantity: updatedQuantity_product
                }
                try {
                    await db.collection('Product').doc(this.stock.item_code).set(payload)
                } catch (error) {
                    console.log(error)
                }

                this.$bvModal.hide("stock-item-modal")
                loader.hide()
                this.$alert("success", null, "success")
            }
            else {
                try {
                    let stock = { ...this.stock }
                    await this.registerBarcode(this.stock.item_code, this.stock.barcode)
                    const stocklogRef = await db.collection('Stock')
                        .where('item_code', '==', stock.item_code)
                        .where('expire_date', '==', stock.expire_date)
                        .where('branch', '==', stock.branch)
                        .get()

                    if (!stocklogRef.empty) {
                        let doc = stocklogRef.docs[0]
                        let product = doc.data()
                        let updatedQuantity = (Number(product.quantity) || 0) + Number(stock.quantity)
                        await db.collection('Stock').doc(doc.id).update({ quantity: updatedQuantity })
                    } else {
                        const stockRef = await db.collection("Stock").add(stock)
                        await db.collection("Stock").doc(stockRef.id).update({ id: stockRef.id })
                    }


                    const result = await db.collection('Product').doc(this.stock.item_code).get()
                    const data = result.data()
                    const updatedQuantity = (Number(data.quantity) || 0) + this.stock.quantity
                    const payload = {
                        ...data,
                        quantity: updatedQuantity
                    }
                    try {
                        await db.collection('Product').doc(this.stock.item_code).set(payload)
                    } catch (error) {
                        console.log(error)
                    }

                    let transfer = []
                    transfer.details = []
                    transfer.details.push(this.stock)
                    transfer.issue_date = moment().format('DD/MM/YYYY HH:mm')
                    transfer.frombranch = null
                    transfer.tobranch = null
                    transfer = { ...transfer }
                    const transferRef = await db.collection("Transfer").add(transfer)
                    await db.collection("Transfer").doc(transferRef.id).update({ id: transferRef.id })
                    this.$bvModal.hide("stock-item-modal")
                    loader.hide()
                    this.$alert("success", null, "success")

                } catch (error) {
                    this.$alert(`error ${error}`, null, "error")
                    loader.hide()
                }

            }


        },
        deleteStockItem(id) {
            this.$confirm("do you want to delete this stock?").then(
                async () => {
                    let loader = this.$loading.show({})

                    const result = await db.collection('Product').doc(this.stock.item_code).get()
                    const data = result.data()
                    const updatedQuantity = (Number(data.quantity) || 0) - this.stock.quantity
                    const payload = {
                        ...data,
                        quantity: updatedQuantity
                    }
                    try {
                        await db.collection('Product').doc(this.stock.item_code).set(payload)
                    } catch (error) {
                        console.log(error)
                    }
                    await db.collection("Stock").doc(id).delete()
                    loader.hide()
                    this.$alert(`This Stock was deleted.`, null, "success")
                    this.$bvModal.hide("stock-item-modal")
                }
            )


        },
        async initializeCodeReader() {
            try {
                this.codeReader = new BrowserMultiFormatReader()
                //console.log('ZXing code reader initialized')

                // Get video input devices
                const devices = await this.codeReader.listVideoInputDevices()
                this.videoInputDevices = devices

                // Set the default selected device to the first camera
                if (devices.length > 0) {
                    this.selectedDeviceId = devices[0].deviceId
                }

                //console.log('Video input devices:', devices)
            } catch (err) {
                console.error('Error initializing the code reader:', err)
            }
        },
        // Start scanning for QR code/barcode from the selected camera
        startScan() {
            this.requestCameraPermission()
            this.initializeCodeReader()
            if (this.selectedDeviceId) {
                const constraints = {
                    video: {
                        deviceId: { exact: this.selectedDeviceId }, // Use the selected device
                        facingMode: { ideal: 'environment' } // Prefer rear camera on mobile
                    }
                }
                this.codeReader.decodeFromVideoDevice(this.selectedDeviceId, 'video', (result, err) => {
                    if (result) {
                        this.scanResult = result.text
                        this.resetScan()
                    }
                })
            } else {
                console.error('No camera selected')
            }
        },

        // Reset the scanner and clear the result
        resetScan() {
            this.beforeDestroy()
            //this.scanResult = ''
            if (this.stream) {
                this.stream.getTracks().forEach(track => track.stop())
                this.stream = null
            }
        },
        async requestCameraPermission() {
            try {
                // Request camera access explicitly
                this.stream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        facingMode: { ideal: 'environment' } // Rear camera
                    }
                })
            } catch (error) {
                alert('Please allow camera access in your device settings or browser.')
            }
        },
        beforeDestroy() {
            if (this.codeReader) {
                this.codeReader.reset() // Reset the code reader when the component is destroyed
            }
        },
        filterProduct(options, search) {
            return options.filter(option => {
                const label = option.item_name + ' ' + option.item_other_name
                return this.filterProductBy(option, label, search)
            });
        },
        filterProductBy(_, label, search) {
            return (label || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
        },
    },
    computed: {
        prepared_medicines() {
            return this.medicines.map(i => {
                return {
                    item_name: i.item_name || null,
                    item_code: i.item_code || null,
                    item_other_name: i.item_other_name || '',
                    barcode: i.barcode || [],
                }
            })
        },
    },

}
</script>
<style lang="scss">
.stock-table {
    .bg-red {
        background-color: #FF4C4C;
        color: white;
    }

    .bg-yellow {
        background-color: #FFEB3B;
        color: black;
    }
}
</style>