<template>
  <div>
    <div class="d-flex">
      <h3 class="text-primary mr-auto">Role Management</h3>
    </div>
    <hr />
    <b-card no-body>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <permission-table v-model="selectedRole"/>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-3 roles">
            <draggable v-model="roles" group="roles" @start="drag = true" @end="drag = false">
              <div class="role-item d-flex" v-for="(item, index) in roles" :key="index" >
                <i class="fas fa-bars mr-1 mt-auto mb-auto" style="color: grey"></i>
                <input v-model="item.name" type="text" class="form-control mr-auto" v-if="item.isEdit">
                <span class="mr-auto role-name mt-auto mb-auto" @click="onSelectRole(item)" v-else> {{ item.name }} </span>
                <i class="fas fa-check edit-btn mt-auto mb-auto" v-if="item.isEdit" @click="item.isEdit=!item.isEdit"></i>
                <i class="fas fa-pen edit-btn mt-auto mb-auto" v-else  @click="item.isEdit=!item.isEdit"></i>
              </div>
            </draggable>
            <div class="d-flex add-role" @click="addNewRole">
              <i class="mr-auto fas fa-plus ml-auto"></i>
            </div>
          </div>
          <div class="col-9">
            <permission v-model="selectedRole"/>
          </div>
        </div> -->
      </div>
    </b-card>
  </div>
</template>

<script>
import PermissionTable from '../../components/Table/PermissionTable.vue'
import draggable from 'vuedraggable'
export default {
  components: {
    PermissionTable,
    // draggable
  },
  data() {
    return {
      roles: [
        {
          name: 'Admin',
          isEdit: false,
        },
        {
          name: 'Director',
          isEdit: false,
        },
        {
          name: 'Staff',
          isEdit: false,
        },
        {
          name: 'Doctor',
          isEdit: false,
        },
        {
          name: 'Receiption',
          isEdit: false,
        },
        {
          name: 'Nurse',
          isEdit: false,
        }
      ],
      selectedRole: null
    }
  },
  mounted() {
    this.selectedRole = this.roles[0]
  },
  methods: {
    onSelectRole(role) {
      this.selectedRole = role
    },
    addNewRole() {
      this.roles.push({
        name: '',
        isEdit: true,
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container-fluid {
  min-height: 400px;
  min-width: 100px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.role-item {
  padding: 12px;
  border-radius: 0.5em;
  border: 0.2px solid #adadad;
  margin-top: 10px;
  height: 65px;
  color: #444444;
  align-self: flex-end;
}

.role-name {
  cursor: pointer;
}

.add-role {
  padding: 12px;
  border-radius: 0.5em;
  border: 0.2px dashed #adadad;
  margin-top: 10px;
  height: 65px;
  color: #444444;
  cursor: pointer;
}

.edit-btn {
  cursor: pointer;
}
</style>
