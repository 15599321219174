<template>
  <select
    class="form-control"
    id="country"
    name="country"
    v-model="scopedCountry"
  >
    <option value="" selected disabled hidden></option>
    <option v-for="(i, index) in ins" :value="i.name" :key="index">{{
      i.name
    }}</option>

    <!-- <option v-if="prefillCountry" :value="country" style="text-transform: capitalize;">{{ country }}</option> -->
  </select>
</template>

<script>
import SlimSelect from "slim-select";
import COUNTRIES from "@/assets/countries.json";

export default {
  name: "country-dropdown",
  props: {
    country: String
  },
  data() {
    return {
      scopedCountry: this.country,
      // prefillCountry: false,
      ins: COUNTRIES
    };
  }
  // created () {
  //   if (this.country) {
  //     this.prefillCountry = true
  //     // this.ins.forEach(i => {
  //       // if (this.country.toLowerCase() === i.name.toLowerCase()) {
  //         // found match
  //         // this.prefillCountry = true
  //         // this.scopedCountry = i.name
  //       // }
  //     // })
  //   }
  // },
  // mounted () {
  //   // const self = this
  //   /* eslint-disable no-new */
  //   new SlimSelect({
  //     select: '#country',
  //     addable: function (value) {
  //       // const _value = self.$lodash.capitalize(value)
  //       return `${value}`
  //     }
  //   })
  // },
  // watch: {
  //   scopedCountry: function (value) {
  //     this.$emit('input', value)
  //   }
  // }
};
</script>
