<template>
  <div class="container-fluid">
    <vue-good-table
      :columns="columns"
      :rows="patients"
      :pagination-options="{
        enabled: true
      }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'index'">
          {{ props.row.originalIndex + 1 }}
        </span>
        <div v-else-if="props.column.label == 'DR / RN'">
          <div>
            <b>{{ props.row.doctor }}</b>
          </div>
          <div>{{ props.row.nurse }}</div>
        </div>
        <div v-else-if="props.column.field == 'details'">
          <div class="text-dark">
            <b>{{ props.formattedRow.details.name }}</b>
          </div>
          <div
            class="esi-box"
            :style="{
              color: props.formattedRow.details.esi == 5 ? 'dark' : 'white',
              'background-color': getEsiColor(props.formattedRow.details.esi)
            }"
          >
            ESI level {{ props.formattedRow.details.esi }}
          </div>
          <div class="text-muted">
            <i>HN: {{ props.formattedRow.details.HN }}</i>
          </div>
          <div>
            <a :href="`tel:${props.formattedRow.details.tel}`"
              ><i class="fas fa-phone"></i>
              {{ props.formattedRow.details.tel }}</a
            >
          </div>
          <div>
            <a :href="`mailto:${props.formattedRow.details.email}`"
              ><i class="fas fa-envelope"></i>
              {{ props.formattedRow.details.email }}</a
            >
          </div>
        </div>
        <span v-else-if="props.column.field == 'esi'">
          <i class="fa-stack" :style="`color: ${props.formattedRow.esi};`">
            <i class="far fa-circle fa-stack-2x"></i>
            <i class="fas fa-circle fa-stack-1x"></i>
          </i>
        </span>
        <div v-else-if="props.column.field == 'refuse'">
          <div class="text-danger">{{ props.formattedRow.refuse.title }}</div>
          <div>
            <i>{{ props.formattedRow.refuse.description }}</i>
          </div>
        </div>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        {
          label: "No.",
          field: "id"
        },
        {
          label: "Time",
          field: "time"
        },
        {
          label: "Patient Details",
          field: "details"
        },
        {
          label: "Diagnosis",
          field: "diagnosis"
        },
        {
          label: "DR / RN",
          field: "staff"
        },
        {
          label: "Refused Item and Reason",
          field: "refuse"
        },
        {
          label: "Finance",
          field: "payment_status"
        },
        {
          label: "Follow up Staff",
          field: "follow_up_staff"
        },
        {
          label: "Note",
          field: "note"
        }
      ],
      patients: []
    };
  },
  methods: {
    onRowClick(params) {
      this.$router.push(`/patient/${params.row.hn}`);
    },
    getEsiColor(esi) {
      switch (esi) {
        case 1:
          return "#CB0000";
          break;
        case 2:
          return "#EA9A99";
          break;
        case 3:
          return "#F1C331";
          break;
        case 4:
          return "#629B49";
          break;
        case 5:
          return "#FFFFFF";
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.esi-box {
  padding: 5px;
  font-weight: 800;
  border-radius: 0.25em;
  border: 1px solid #adadad;
}
</style>
