<template>
    <div class="container-fluid">
      <div class="card shadow">
        <div class="card-body">
          <div class="text-muted">Product Management</div>  
          <hr />
          <b-nav class="mb-2" tabs>
            <b-nav-item :active="currentMenu === menu.name" v-for="(menu, index) in menus" :key="index"
              @click="currentMenu = menu.name">{{ menu.name }}</b-nav-item>
          </b-nav>
  
          <div v-if="currentMenu === 'Product'">
            <product-tab />  
          </div>
  
          <div v-if="currentMenu === 'Product Summary'">
            <productsummary-tab />  
          </div>

          <div v-if="currentMenu === 'Product Transfer'">
            <producttransfer-tab />
          </div>

          <div v-if="currentMenu === 'Transfer Report'">
            <transferreport-tab />
          </div>

        </div>
  
      </div>
      
    </div>
  </template>
  
  <script>

  import Product from './Product.vue'
  import ProductSummary from './ProductSummary.vue'
  import ProductTransfer from './ProductTransfer.vue'
  import TransferReport from './TransferReport.vue'
  export default {
    components: {
      
      "product-tab": Product,
      "productsummary-tab": ProductSummary,
      "producttransfer-tab": ProductTransfer,
      "transferreport-tab": TransferReport,
    },
    data() {
      return {
        currentMenu: "Product",        
      }
    },
    firestore() {
      return {
      }
    },
    methods: {    
      
    },
    computed: {      
      menus() {
        const menus = [
          { name: "Product" },
          { name: "Product Summary" },
          { name: "Product Transfer" },
          { name: "Transfer Report"}
        ]
        return menus
      },
    },
  }
  </script>