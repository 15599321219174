<template>
  <div>
    <card-by-country :payments="payments"></card-by-country>
    <card-by-bank :payments="payments"></card-by-bank>
  </div>
</template>

<script>
export default {
  props: ['payments']
}
</script>