var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('b-nav',{staticClass:"mt-2",attrs:{"tabs":"","justified":""}},[_c('b-nav-item-dropdown',{attrs:{"text":"ADMISSION SHEETS"}},[_c('b-dropdown-item',{attrs:{"to":{
            name: 'Admission Form',
            params: { id: _vm.$route.params.id, admissionId: _vm.$route.params.admissionId }
          }}},[_vm._v(" Admission Form ")]),_c('b-dropdown-item',{attrs:{"to":{
            name: 'Consent Form',
            params: { id: _vm.$route.params.id, admissionId: _vm.$route.params.admissionId }
          }}},[_vm._v(" Consent Form ")])],1),_c('b-nav-item-dropdown',{attrs:{"text":"Doctor Sheet"}},[_c('b-dropdown-item',{attrs:{"to":{
            name: 'Progress Note List',
            params: { id: _vm.$route.params.id, admissionId: _vm.$route.params.admissionId }
          }}},[_vm._v(" Progress Note List ")]),_c('b-dropdown-item',{attrs:{"to":{
            name: `Physician's Order Sheet`,
            params: { id: _vm.$route.params.id, admissionId: _vm.$route.params.admissionId }
          }}},[_vm._v(" Physician's Order Sheet ")]),_c('b-dropdown-item',{attrs:{"to":{
            name: `View Lab`,
            params: { id: _vm.$route.params.id, admissionId: _vm.$route.params.admissionId }
          }}},[_vm._v(" View Lab ")]),_c('b-dropdown-item',{attrs:{"to":{
            name: `View Investigation`,
            params: { id: _vm.$route.params.id, admissionId: _vm.$route.params.admissionId }
          }}},[_vm._v(" View Investigation ")]),_c('b-dropdown-item',{attrs:{"to":{
            name: `View History`,
            params: { id: _vm.$route.params.id, admissionId: _vm.$route.params.admissionId }
          }}},[_vm._v(" View History ")])],1),_c('b-nav-item',{attrs:{"active":_vm.$route.name === 'Patient Admission IPD doc',"to":{
            name: 'Patient Admission IPD doc',
            params: { id: _vm.$route.params.id, admissionId: _vm.$route.params.admissionId }
          }}},[_vm._v(" NURSING SHEETS ")]),_c('b-nav-item',{attrs:{"active":_vm.$route.name === 'Patient Admission IPD doc',"to":{
            name: 'Patient Admission IPD doc',
            params: { id: _vm.$route.params.id, admissionId: _vm.$route.params.admissionId }
          }}},[_vm._v(" CERTIFICATES ")])],1)],1),_vm._m(0)]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('router-view',{staticStyle:{"margin-top":"15px"},attrs:{"patient":_vm.patient}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('div',{staticClass:"d-flex mt-4"},[_c('button',{staticClass:"btn btn-secondary ml-auto mr-2"},[_vm._v("DOCUMENTS")]),_c('button',{staticClass:"btn btn-secondary"},[_vm._v("VIEW PATIENT DATA")])])])
}]

export { render, staticRenderFns }