<template>
  <div class="container-fluid">
    <h3 class="text-secondary text-center pt-4">Patient Full Details</h3>
    <div class="row mt-5">
      <div class="col-4">
        <div class="photo-box p-2 text-center">
          <img
            v-if="patient.img_url"
            :src="patient.img_url"
            alt="profile"
            class="avatar"
          />
          <img v-else src="/images/profile.svg" alt="profile" class="avatar" />
          <h5 class="text-dark text-center">HN: {{ patient.HN }}</h5>
        </div>

        <div class="bg-grey pt-2 pb-1 pl-2">
          <h5 class="text-danger text-uppercase">
            <i class="fas fa-exclamation-triangle"></i> Allergies
            <span v-if="patient.allergies"
              >({{ patient.allergies.length }})</span
            >
          </h5>
        </div>
        <div class="bg-white p-2 content-box">
          <ul>
            <li v-for="(item, index) in patient.allergies" :key="index">
              <div class="text-danger allergy-title">
                <b>{{ item.name }}</b>
              </div>
            </li>
          </ul>
        </div>

        <div class="bg-grey pt-2 pb-1 pl-2">
          <h5 class="text-danger text-uppercase">
            <i class="fas fa-exclamation-triangle"></i> Other Allergies
            <span v-if="patient.other_allergies"
              >({{ patient.other_allergies.length }})</span
            >
          </h5>
        </div>
        <div class="bg-white p-2 content-box">
          <ul>
            <li v-for="(item, index) in patient.other_allergies" :key="index">
              <div class="text-danger allergy-title">
                <b>{{ item.name }}</b>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-8">
        <div class="bg-grey pt-2 pb-1 pl-2">
          <h5 class="text-primary text-uppercase">Personal</h5>
        </div>
        <div class="bg-white p-2 content-box">
          <div class="row p-1">
            <div class="offset-1 col-4"><b>First Name:</b></div>
            <div class="col-4">{{ patient.first_name }}</div>
          </div>
          <div class="row p-1">
            <div class="offset-1 col-4"><b>Last Name:</b></div>
            <div class="col-4">{{ patient.last_name }}</div>
          </div>
          <div class="row p-1">
            <div class="offset-1 col-4"><b>Age:</b></div>
            <div class="col-4">{{ patient.age }}</div>
          </div>
          <div class="row p-1">
            <div class="offset-1 col-4"><b>Date of Birth:</b></div>
            <div class="col-4" v-if="patient.date_of_birth">
              {{ patient.date_of_birth ? formatDate(patient.date_of_birth) : '-' }}
            </div>
          </div>
          <div class="row p-1">
            <div class="offset-1 col-4"><b>Marital Status:</b></div>
            <div class="col-4">{{ patient.marital_status }}</div>
          </div>
          <div class="row p-1">
            <div class="offset-1 col-4"><b>Nationality:</b></div>
            <div class="col-4">{{ patient.nationality }}</div>
          </div>
          <div class="row p-1">
            <div class="offset-1 col-4"><b>Passport No.:</b></div>
            <div class="col-4">{{ patient.passport_id }}</div>
          </div>
          <div class="row p-1">
            <div class="offset-1 col-4"><b>Occupation:</b></div>
            <div class="col-4">{{ patient.occupation }}</div>
          </div>
          <div class="row p-1">
            <div class="offset-1 col-4"><b>Religion:</b></div>
            <div class="col-4">{{ patient.religion }}</div>
          </div>
        </div>

        <div class="bg-grey pt-2 pb-1 pl-2">
          <h5 class="text-primary text-uppercase">Contact</h5>
        </div>
        <div class="bg-white p-2 content-box">
          <div class="row p-1">
            <div class="offset-1 col-4"><b>Thai Mobile Phone:</b></div>
            <div class="col-4">{{ patient.phone_th }}</div>
          </div>
          <div class="row p-1">
            <div class="offset-1 col-4"><b>International Mobile Phone:</b></div>
            <div class="col-4">{{ patient.phone_inter }}</div>
          </div>
          <div class="row p-1">
            <div class="offset-1 col-4"><b>E-Mail:</b></div>
            <div class="col-4">{{ patient.email }}</div>
          </div>
          <div class="row p-1">
            <div class="offset-1 col-4"><b>Address:</b></div>
            <div class="col-4">
              <div>{{ patient.home_address }}, {{ patient.home_city }}</div>
              <div>{{ patient.home_country }}, {{ patient.home_zipcode }}</div>
            </div>
          </div>
        </div>

        <div class="bg-grey pt-2 pb-1 pl-2">
          <h5 class="text-primary text-uppercase">Emergency contact person</h5>
        </div>
        <div class="bg-white p-2 content-box">
          <div class="row p-1">
            <div class="offset-1 col-4"><b>Full Name:</b></div>
            <div class="col-4">
              {{ patient.emergency_first_name }}
              {{ patient.emergency_last_name }}
            </div>
          </div>
          <div class="row p-1">
            <div class="offset-1 col-4"><b>Relationship:</b></div>
            <div class="col-4">{{ patient.emergency_relationship }}</div>
          </div>
          <div class="row p-1">
            <div class="offset-1 col-4"><b>tel:</b></div>
            <div class="col-4">{{ patient.emergency_phone }}</div>
          </div>
          <div class="row p-1">
            <div class="offset-1 col-4"><b>Address:</b></div>
            <div class="col-4">
              <div>
                {{ patient.emergency_address }}, {{ patient.emergency_city }}
              </div>
              <div>
                {{ patient.emergency_country }}, {{ patient.emergency_zipcode }}
              </div>
            </div>
          </div>
        </div>

        <div class="bg-grey pt-2 pb-1 pl-2">
          <h5 class="text-primary text-uppercase">Insurance</h5>
        </div>
        <div class="bg-white p-2 content-box">
          <div class="row p-1">
            <div class="offset-1 col-4"><b>Insurance Name:</b></div>
            <div class="col-4" v-if="patient.travel_insurance && patient.travel_insurance.id !== 'Other'">
              {{ patient.travel_insurance.name_en }}
              <span class="badge badge-info"
                >{{ patient.travel_insurance.type }}
              </span>
            </div>
            <div class="col-4" v-else-if="patient.travel_insurance && patient.travel_insurance.id === 'Other'">
              {{ patient.travel_insurance.name_en }}
            </div>
            <div class="col-4" v-else>
              -
            </div>
          </div>
        </div>
        <div class="bg-grey pt-2 pb-1 pl-2" v-if="patient.signature_url">
          <h5 class="text-primary text-uppercase">Signature</h5>
        </div>
        <div class="bg-white p-2 content-box" v-if="patient.signature_url">
          <img :src="patient.signature_url" height="200" />
        </div>

        <div class="d-flex justify-item-center mt-2">
          <router-link
            :to="{ name: 'Patient Edit', params: { id: patient.HN } }"
            tag="button"
            class="btn btn-light mr-2"
            style="width: 200px;"
            ><i class="fas fa-edit"></i> Edit</router-link
          >
          <button
            class="btn btn-light"
            style="width: 200px;"
            @click="cancelPatient(patient)"
          >
            <i class="fas fa-times"></i> Reject
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../../../db";
import moment from "moment"
export default {
  props: ["patient"],
  methods: {
    async cancelPatient(patient) {
      try {
        await this.$confirm(
          `Do you want to cancel patient: ${patient.first_name} ${patient.last_name}?`,
          `Reject HN: ${patient.HN}`
        );
        await db
          .collection("Patient")
          .doc(patient.HN)
          .update({
            rejected_at: new Date(),
            rejected_by: db.collection("User").doc(this.$store.getters.user.uid)
          });
        await this.$alert(null, `HN: ${patient.HN} rejected`, "success");
        this.$router.push({ name: "Patient Summary" });
      } catch (error) {
        //do nothing if cancel
      }
    },
    formatDate(data) {
      return moment(data.toDate()).format("DD/MM/YYYY")
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  height: 200px;
  width: 200px;
  border-radius: 0.25em;
}

.photo-box {
  background-color: #0b97e555;
  border-radius: 0.25em;
}

.content-box {
  border: 1px solid #dddddd;
}

.bg-grey {
  background-color: #dddddd;
}
</style>

<style lang="scss" scoped>
.container-fluid {
  margin-bottom: 50px;
}
</style>
