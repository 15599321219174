<template>
  <div class="container-fluid">
    <div>
      <span class="label-b">List of all Radiology</span>
      <button
        class="btn btn-outline-secondary mb-2"
        style="float: right;"
        v-if="hideVN"
        @click.prevent="hideVN = false"
      >
        <span>Show VN/AN</span>
      </button>
      <button
        class="btn btn-outline-secondary mb-2"
        style="float: right;"
        v-if="!hideVN"
        @click.prevent="hideVN = true"
      >
        <span>Hide VN/AN</span>
      </button>
    </div>
    <div class="mt-2">
      <table class="table table-bordered">
        <thead class="text-center">
          <tr>
            <th>Date</th>
            <th v-if="!hideVN">VN/AN</th>
            <th>Compare</th>
            <th>Tests</th>
            <th>Requesting Docter</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(i, index) in lab_test_list" :key="index">
            <td>{{ i.date }}</td>
            <td v-if="!hideVN">{{ i.vn_an }}</td>
            <td class="text-center"><input type="checkbox" /></td>
            <td>
              <a href="">{{ i.tests }}</a>
            </td>
            <td>{{ i.req_doc }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-center">
      <button
        type="button"
        class="btn btn-primary btn-lg"
        style="width: 150px;"
      >
        <i class="fa fa-compress"></i> Compare
      </button>

      <button
        style="margin-left: 10px; width: 150px;"
        type="button"
        class="btn btn-danger btn-lg"
      >
        <i class="fas fa-times"></i> Cancel
      </button>
    </div>
    <div class="mt-4 mb-5">
      <div>
        <span class="label-b">X-Ray Comparison Chart</span>
      </div>
      <div class="mt-2">
        <table class="table table-bordered">
          <thead class="text-center">
            <tr>
              <th class="bg-gray">X-Ray</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="bg-gray"><b>Findings</b></td>
              <td>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hideVN: true,
      lab_test_list: []
    };
  }
};
</script>

<style lang="scss" scoped>
.label-b {
  color: rgb(6, 132, 216);
  font-size: 20px;
}

input[type="checkbox"] {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}
</style>
