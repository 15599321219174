<template>
  <div class="container">
    <div class="card shadow">
      <div class="card-body">
        <h3 class="text-center text-primary">Patient Search</h3>
        <form @submit.prevent="search">
          <div class="form-group">
            <div class="input-group">
              <input
                focus
                type="text"
                class="form-control"
                v-model="keyword"
                :placeholder="`search with HN`"
              />
              <div class="input-group-append">
                <span class="input-group-text"
                  ><i class="fas fa-search"></i
                ></span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../db";
export default {
  data() {
    return {
      keyword: null
    };
  },
  methods: {
    async search() {
      let loader = this.$loading.show({});
      let result = await db
        .collection("Patient")
        .doc(this.keyword)
        .get();
      loader.hide();
      if (result.exists) {
        this.$router.push(`/patient/${this.keyword}`);
      } else {
        this.$alert(`Patient ${this.keyword} not found.`, null, "error");
      }
    }
  }
};
</script>
