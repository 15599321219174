<template>
  <div class="container-fluid">
    <vue-good-table
      :columns="columns"
      :rows="patients"
      :pagination-options="{
        enabled: true
      }"
    >
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.label =='Daily Meds Summary'">
            <div class="text-center">Daily Meds</div>
            <div class="text-center">Summary</div>
        </span>
        <span v-else-if="props.column.label =='V/S I/O Diet Endorsement'">
            <div class="text-center">V/S</div>
            <div class="text-center">I/O</div>
            <div class="text-center">Diet</div>
            <div class="text-center mt-2">Endorsement</div>
        </span>
        <span v-else-if="props.column.label =='Admission Date/Time Discharge Plan'">
            <div class="text-center">Admission</div>
            <div class="text-center">Date/Time</div>
            <div class="text-center mt-4">Discharge Plan</div>
        </span>
        <span v-else-if="props.column.label =='Finance Passport Travel Plan'">
            <div class="text-center">Finance</div>
            <div class="text-center">Passport</div>
            <div class="text-center mt-4">Travel Plan</div>
        </span>
        <span v-else>
            {{props.column.label}}
        </span>
      </template>
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'details'">
          <div class="text-dark">
            <b>{{ props.formattedRow.details.name }}</b>
          </div>
          <div class="text-dark">
            <b>{{ props.formattedRow.details.age }}</b>
          </div>
          <div
            class="esi-box"
            :style="{
              color: props.formattedRow.details.esi == 5 ? 'dark' : 'white',
              'background-color': getEsiColor(props.formattedRow.details.esi)
            }"
          >
            ESI level {{ props.formattedRow.details.esi }}
          </div>
          <div class="text-muted">
            <i>AN: {{ props.formattedRow.details.AN }}</i>
          </div>
        </div>
        <div v-else-if="props.column.field == 'allergies'">
          <div class="text-danger" v-for="item of props.formattedRow.allergies" :key="item"><b>{{ item }}</b></div>
        </div>
        <div v-else-if="props.column.field == 'actions'">
          <button class="btn btn-block btn-primary mt-2 shadow" v-b-modal.new-admission>Register Admission</button>
          <button class="btn btn-block btn-light mt-2 shadow">Cancel</button>
        </div>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <b-modal id="new-admission" size="lg" hide-footer>
      <h3 class="text-center text-success">Admission Registration Form</h3>
      <h5 class="text-center text-success">{{ Date() | moment('DD MMMM YYYY HH:mm')}}</h5>
      <div class="row">
        <div class="col-4">
           <MazSelect
              v-model="admission.branch"
              placeholder="Branch"
              :options="[
                { label: 'Beach Front (Hospital)', value: '001' },
                { label: 'Loh Dalum (Clinic)', value: '002' },
              ]"
            />
        </div>
        <div class="col-4">
          <MazSelect
            class="mb-2"
              v-model="admission.type"
              placeholder="Visit Type"
              :options="[
                { label: 'Others', value: '000' },
                { label: 'OPD', value: '001' },
                { label: 'ER', value: '002' },
                { label: 'Scheduled', value: '003' },
                { label: 'Hotel Call', value: '004' },
              ]"
            />
        </div>
        <div class="col-4">
          <MazPicker
            class="mb-2"
            placeholder="Admission Date/Time"
            v-model="admission.date"
            @formatted="pickerFormatted = $event"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <MazSelect
            class="mb-2"
            v-model="admission.from"
            placeholder="Admit From"
            :options="[
              { label: 'OPD', value: 'OPD' },
              { label: 'ER', value: 'ER' },
            ]"
          />
          <MazPicker
            class="mb-2"
            placeholder="Time Received from ER/OPD"
            v-model="admission.received_time"
            @formatted="pickerFormatted = $event"
          />
          <MazSelect
            class="mb-2"
            v-model="admission.from"
            placeholder="Transfer type (to room)"
            :options="[
              { label: 'Others', value: 'Others' },
              { label: 'Ambulatory', value: 'Ambulatory' },
              { label: 'Wheelchair', value: 'Wheelchair' },
              { label: 'Stretcher', value: 'Stretcher' },
              { label: 'Crutches', value: 'Crutches' },
            ]"
          />
          <MazSelect
            class="mb-2"
            v-model="admission.admittingg_doctor"
            placeholder="Admitting Doctor"
            :options="[
              { label: 'Others', value: 'Others' },
              { label: 'Dr. Aphilak Kritsanasakul', value: 'Dr. Aphilak Kritsanasakul' },
              { label: 'Dr. Watcharobon Kritsanasakul', value: 'Dr. Watcharobon Kritsanasakul' },
              { label: 'Dr. Santisuk Wongprasert', value: 'Dr. Santisuk Wongprasert' },
            ]"
          />
        </div>
        <div class="col-6">
          <MazSelect
            class="mb-2"
            v-model="admission.room_no"
            placeholder="Room Number"
            :options="[
              { label: '201 A', value: '201 A' },
              { label: '201 B', value: '201 B' },
              { label: '201 C', value: '201 C' },
              { label: '202 A', value: '202 A' },
              { label: '202 B', value: '202 B' },
              { label: '203 A', value: '203 A' },
              { label: '203 B', value: '203 B' },
              { label: '204 A', value: '204 A' },
              { label: '204 B', value: '204 B' },
              { label: '205 A', value: '205 A' },
              { label: '205 B', value: '205 B' },
              { label: '206 A', value: '206 A' },
              { label: '206 B', value: '206 B' },
              { label: '301 A', value: '301 A' },
              { label: '301 B', value: '301 B' },
              { label: '302 A', value: '302 A' },
              { label: '302 B', value: '302 B' },
              { label: '303 A', value: '303 A' },
              { label: '303 B', value: '303 B' },
              { label: '304 A', value: '304 A' },
              { label: '304 B', value: '304 B' },
              { label: '305 A', value: '305 A' },
              { label: '305 B', value: '305 B' },
              { label: '306 A', value: '306 A' },
              { label: '306 B', value: '306 B' },
            ]"
          />
          <MazSelect
            class="mb-2"
            v-model="admission.payment_type"
            placeholder="Type of payment"
            :options="[
              { label: 'Others', value: 'Others' },
              { label: 'Insurance', value: 'Insurance' },
              { label: 'Self Pay', value: 'Self Pay' },
            ]"
          />
          <div class="row">
            <div class="col-4">
              <label>Staff on duty (Receiving)</label>
            </div>
            <div class="col-4">
              <MazSelect
                class="mb-2"
                v-model="admission.rn"
                placeholder="RN list"
                :options="[]"
              />
            </div>
            <div class="col-4">
              <MazSelect
                class="mb-2"
                v-model="admission.na"
                placeholder="NA list"
                :options="[]"
              />
            </div>
          </div>
          <MazSelect
            class="mb-2"
            v-model="admission.attending_doctor"
            placeholder="Attending Doctor"
            :options="[
              { label: 'Others', value: 'Others' },
              { label: 'Dr. Aphilak Kritsanasakul', value: 'Dr. Aphilak Kritsanasakul' },
              { label: 'Dr. Watcharobon Kritsanasakul', value: 'Dr. Watcharobon Kritsanasakul' },
              { label: 'Dr. Santisuk Wongprasert', value: 'Dr. Santisuk Wongprasert' },
            ]"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6">
          <button class="btn-block btn-primary">Save</button>
        </div>
        <div class="col-6">
          <button class="btn-block btn-danger">Cancel</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        {
          label: "Patient Details",
          field: "details"
        },
        {
          label: "Allergy",
          field: "allergies"
        },
        {
          label: "Diagnosis",
          field: "diagnosis"
        },
        {
          label: "Order Admission Date/Time Doctor",
          field: "admission_order"
        },
        {
          label: "Actions",
          field: "actions"
        },
      ],
      patients: [],
      admission: {}
    };
  },
  methods: {
    onRowClick(params) {
      this.$router.push(`/patient/${params.row.hn}`);
    },
    getEsiColor(esi) {
      switch (esi) {
        case 1:
          return "#CB0000";
          break;
        case 2:
          return "#EA9A99";
          break;
        case 3:
          return "#F1C331";
          break;
        case 4:
          return "#629B49";
          break;
        case 5:
          return "#FFFFFF";
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.esi-box {
  padding: 5px;
  font-weight: 800;
  border-radius: 0.25em;
  border: 1px solid #adadad;
}
</style>
