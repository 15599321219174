import Insurance from '../pages/Insurance/index.vue'

export default [
  {
    path: "/insurance",
    name: "Insurance",
    component: Insurance,

  }
];
