<template>
  <div class="container-fluid">
    <patient-info :patient="patient"></patient-info>
    <h3 class="text-primary mt-2">
      Admission Summary <i class="fas fa-filter"></i>
    </h3>
    <vue-good-table
      class="mt-3"
      :columns="columns"
      :rows="admissions"
      :fixed-header="true"
      :sort-options="{
        enabled: false
      }"
      :group-options="{
        enabled: true
      }"
      @on-row-click="onRowClick"
      v-if="showAdmission===false"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.label == 'ICD-10 & Principal Diagnosis'">
          <div
            class="esi-box"
            style="background-color: #629B49; color: white;"
          >
            ESI level 3
          </div>
          <div>Acute gastroenteritis, A09</div>
        </div>
        <div v-else>
          {{ props.formattedRow[props.column.field] }}
        </div>
      </template>
    </vue-good-table>
    <div v-if="showAdmission===true">
      <div class="d-flex mt-2" >
        <b-nav class="mt-2" tabs justified>
          <b-nav-item
            :active="$route.name === 'Patient Admission OPD doc'"
            :to="{
              name: 'Patient Admission OPD doc',
              params: { id: $route.params.id, visitId: $route.params.visitId }
            }"
            class="text-success"
          >
            OPD DOCS
          </b-nav-item>
          <b-nav-item
            :active="$route.name === 'Patient Admission IPD doc'"
            :to="{
              name: 'Patient Admission IPD doc',
              params: { id: $route.params.id, visitId: $route.params.visitId }
            }"
          >
            IPD DOCS
          </b-nav-item>
        </b-nav>
      </div>
      <router-view style="margin-top: 15px;" :patient="patient"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  props: ['patient'],
  data() {
    return {
      columns: [
        {
          label: "Date & Time",
          field: 'datetime',
        },
        {
          label: "Branch",
          field: "branch",
        },
        {
          label: "ICD-10 & Principal Diagnosis",
          field: "icd10",
        },
        {
          label: "Attending Doctor",
          field: 'doctor',
        }
      ],
      admissions: [
        // {
        //   datetime: '21 Oct 2020',
        //   children: [
        //     {
        //       datetime: '15:00',
        //       branch: 'Hospital ER/IPD',
        //       icd10: 'Acute gastro',
        //       doctor: 'Dr. Aphilak Kritsanasakul'
        //     }
        //   ]
        // }

      ],
      showAdmission: false
    }
  },
  methods: {
    onRowClick() {
      this.showAdmission = true
    }
  }
}
</script>

<style lang="scss" scoped>
.esi-box {
  padding: 5px;
  font-weight: 800;
  width: 200px;
  border-radius: 0.25em;
  border: 1px solid #adadad;
}
</style>