<template>
  <div>
    <section class="sheet padding-10mm">
      <med-cert-header></med-cert-header>
      <h5 class="text-center mt-2">Medical Certificate</h5>

      <section class="box">
        <div class="title-box">
          <h5 class="text-center">CHIEF CONPLAINT AND PRESENT ILLNESS</h5>
        </div>
        <div class="content-box">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta nihil,
          iste voluptatum officiis minima eligendi saepe aperiam explicabo
          aliquid, delectus tenetur eveniet vero? Molestias voluptas eligendi
          quia recusandae esse. Consectetur.
        </div>
      </section>

      <section class="box">
        <div class="title-box">
          <h5 class="text-center">PHYSICAL EXAMINATION</h5>
        </div>
        <div class="content-box">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel sunt
          reprehenderit iusto odit cumque cum soluta, sed autem alias id officia
          corporis eveniet consequuntur possimus sapiente accusantium neque
          quaerat. Laboriosam. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Vel sunt reprehenderit iusto odit cumque cum soluta,
          sed autem alias id officia corporis eveniet consequuntur possimus
          sapiente accusantium neque quaerat. Laboriosam. Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Vel sunt reprehenderit iusto odit
          cumque cum soluta, sed autem alias id officia corporis eveniet
          consequuntur possimus sapiente accusantium neque quaerat. Laboriosam.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel sunt
          reprehenderit iusto odit cumque cum soluta, sed autem alias id officia
          corporis eveniet consequuntur possimus sapiente accusantium neque
          quaerat. Laboriosam.
        </div>
      </section>

      <section class="box">
        <div class="title-box">
          <h5 class="text-center">INVESTIGATION</h5>
        </div>
        <div class="content-box">
          <div class="d-flex">
            <div class="mr-4">
              <b>X-RAY</b>
            </div>
            <div>Left Foot (AP, Lateral)</div>
          </div>
          <div class="d-flex">
            <div class="mr-4">
              <b>X-RAY</b>
            </div>
            <div>Left Foot (AP, Lateral)</div>
          </div>
          <div class="d-flex">
            <div class="mr-4">
              <b>EKG</b>
            </div>
            <div>12 Lead</div>
          </div>
          <div class="d-flex">
            <div class="mr-4">
              <b>Laboratory</b>
            </div>
            <div>CBC, BUN, Crea, E’Lyte</div>
          </div>
        </div>
      </section>

      <section class="box">
        <div class="title-box">
          <h5 class="text-center">DIAGNOSIS</h5>
        </div>
        <div class="content-box"></div>
      </section>
    </section>

    <section class="sheet padding-10mm">
      <section class="box">
        <div class="title-box">
          <h5 class="text-center">TREATMENT</h5>
        </div>
        <div class="content-box">
          <ul>
            <li>
              <div class="d-flex">
                <b class="mr-4">NSS 1000 ml IV</b>
                <div class="mr-4">100 cc/hr</div>
                <div class="mr-4"># 5 bottle</div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <b class="mr-4">Augmentin 1.2 g IV</b>
                <div class="mr-4">1.2 g/IV q8</div>
                <div class="mr-4"># 3 ampules</div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <b class="mr-4">Dynastat 40mg/2ml IV</b>
                <div class="mr-4">40 mg/ IV STAT</div>
                <div class="mr-4"># 1 ampules</div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <b class="mr-4">Celebrex 400 mg/tab </b>
                <div class="mr-4">1 tab po OD</div>
                <div class="mr-4"># 10 capsules</div>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section class="box">
        <div class="title-box">
          <h5 class="text-center">FOLLOW UP & RECOMMENDATION</h5>
        </div>
        <div class="content-box">
          <h5>ADVICE</h5>
          <ul>
            <li>Avoid salty & fatty food</li>
            <li>Regular Exercise</li>
          </ul>
          <h5>RECOMMENDATION</h5>
          <ul>
            <li>Rest from 27/07/2020 to 30/07/2020</li>
            <li>
              Re-visit doctor as soon as possible if condition doesn’t get
              better or is getting worse.
            </li>
          </ul>
          <h5>FOLLOW-UP</h5>
          <ul>
            <li>Date : 01/08/2020</li>
            <li>Time : 14:00</li>
            <li>Doctor : Dr. Aphilak Kritsanasakul</li>
            <li>
              Reason : Repeat labs, follow up symptom, adjust medication dosage
            </li>
            <li>F/U Plan : CBC, BUN, Creatinine, E’lyte before doctor visit</li>
          </ul>
          <h5>PLAN OF TREATMENT</h5>
          <p>
            After control BP not exceed 160/100 mmHg , then sent patient to
            visit general surgery department for evaluation for Colectomy.
          </p>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.className = "A4";
  }
};
</script>

<style lang="scss" scoped>
.box {
  border: 1px solid #adadad;
}
.title-box {
  padding: 10px;
  border-bottom: 1px solid #adadad;
  background-color: #ededed;
}

.content-box {
  padding: 10px;
  min-height: 50px;
}
</style>
