var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"d-flex"},[_c('h3',{staticClass:"text-primary"},[_vm._v("Package")]),_c('button',{staticClass:"btn btn-primary ml-auto",on:{"click":function($event){return _vm.showPackage()}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" New Package ")])]),_c('div',{staticClass:"input-group mt-2 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword3),expression:"keyword3"}],staticClass:"form-control",attrs:{"type":"text","focus":"","placeholder":"Scan Barcode or search by ID, generic name, brand name"},domProps:{"value":(_vm.keyword3)},on:{"input":function($event){if($event.target.composing)return;_vm.keyword3=$event.target.value}}}),_vm._m(0)]),_c('vue-good-table',{attrs:{"columns":_vm.packageColumns,"rows":_vm.filteredPackages,"pagination-options":{
    enabled: true,
  }},on:{"on-row-click":_vm.showPackage}}),_c('b-modal',{attrs:{"id":"package-modal","title":"package","size":"xl","hide-footer":""}},[_c('div',{staticClass:"d-flex mb-2 mt-2"},[_c('label',[_vm._v("Package Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.med_package.package_code),expression:"med_package.package_code"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.med_package.package_code)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.med_package, "package_code", $event.target.value)}}})]),_c('div',{staticClass:"d-flex mb-2 mt-2"},[_c('label',[_vm._v("Package Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.med_package.package_name),expression:"med_package.package_name"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.med_package.package_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.med_package, "package_name", $event.target.value)}}})]),_c('div',{staticClass:"d-flex p-2 bg-light"},[_c('h5',{staticClass:"text-primary"},[_vm._v("BILLABLE ITEMS")])]),_c('div',{staticClass:"container-fluid"},[_c('draggable',{attrs:{"group":"billable_items"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.med_package.billable_items),callback:function ($$v) {_vm.$set(_vm.med_package, "billable_items", $$v)},expression:"med_package.billable_items"}},_vm._l((_vm.med_package.billable_items),function(item,index){return _c('div',{key:index,staticClass:"row mb-2"},[_c('div',{staticClass:"col-1"},[_c('div',{staticClass:"m-4 p-2"},[_c('i',{staticClass:"fas fa-bars",staticStyle:{"color":"grey"}})])]),_c('div',{staticClass:"col-7"},[_c('label',{staticClass:"text-primary"},[_vm._v("Item")]),_c('MazSelect',{staticClass:"ml-auto",attrs:{"search":"","options":_vm.prepared_billable_items.map((i) => {
              return { value: i.item_code, label: i.item_name };
            })},model:{value:(item.item_code),callback:function ($$v) {_vm.$set(item, "item_code", $$v)},expression:"item.item_code"}})],1),_c('div',{staticClass:"col-1"},[_c('label',{staticClass:"text-primary"},[_vm._v("Quantity")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.quantity),expression:"item.quantity"}],staticClass:"form-control",attrs:{"type":"number"},domProps:{"value":(item.quantity)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(item, "quantity", $event.target.value)}}})]),_c('div',{staticClass:"col-2"},[_c('label',{staticClass:"text-primary"},[_vm._v("Discount")]),_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"type":"number"},model:{value:(item.discount),callback:function ($$v) {_vm.$set(item, "discount", $$v)},expression:"item.discount"}})],1)],1),_c('div',{staticClass:"col-1"},[_c('button',{staticClass:"btn btn-light",on:{"click":function($event){return _vm.med_package.billable_items.splice(index, 1)}}},[_c('i',{staticClass:"fas fa-trash"})])]),_c('hr')])}),0)],1),_c('div',{staticClass:"d-flex p-2 bg-light"},[_c('h5',{staticClass:"text-primary"},[_vm._v("PRODUCTS ITEMS")])]),_c('div',{staticClass:"container-fluid"},[_c('draggable',{attrs:{"group":"med_package.products"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.med_package.product_items),callback:function ($$v) {_vm.$set(_vm.med_package, "product_items", $$v)},expression:"med_package.product_items"}},_vm._l((_vm.med_package.product_items),function(item,index){return _c('div',{key:index,staticClass:"row mb-2"},[_c('div',{staticClass:"col-1"},[_c('div',{staticClass:"m-4 p-2"},[_c('i',{staticClass:"fas fa-bars",staticStyle:{"color":"grey"}})])]),_c('div',{staticClass:"col-7"},[_c('label',{staticClass:"text-primary"},[_vm._v("Item")]),_c('MazSelect',{staticClass:"ml-auto",attrs:{"search":"","options":_vm.prepared_products.map((i) => {
              return { value: i.item_code, label: i.item_name };
            })},model:{value:(item.item_code),callback:function ($$v) {_vm.$set(item, "item_code", $$v)},expression:"item.item_code"}})],1),_c('div',{staticClass:"col-1"},[_c('label',{staticClass:"text-primary"},[_vm._v("Quantity")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.quantity),expression:"item.quantity"}],staticClass:"form-control",attrs:{"type":"number"},domProps:{"value":(item.quantity)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(item, "quantity", $event.target.value)}}})]),_c('div',{staticClass:"col-2"},[_c('label',{staticClass:"text-primary"},[_vm._v("Discount")]),_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"type":"number"},model:{value:(item.discount),callback:function ($$v) {_vm.$set(item, "discount", $$v)},expression:"item.discount"}})],1)],1),_c('div',{staticClass:"col-1"},[_c('button',{staticClass:"btn btn-light",on:{"click":function($event){return _vm.med_package.product_items.splice(index, 1)}}},[_c('i',{staticClass:"fas fa-trash"})])]),_c('hr')])}),0)],1),_c('div',{staticClass:"d-flex p-2 bg-gray"},[_c('button',{staticClass:"btn btn-primary mr-2",on:{"click":_vm.addBillableItem}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Add Billable Item ")]),_c('button',{staticClass:"btn btn-primary mr-2",on:{"click":_vm.addProduct}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Add Product ")])]),_c('div',{staticClass:"d-flex mt-2"},[_c('button',{staticClass:"btn btn-primary btn-block",on:{"click":_vm.savePackage}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" Save ")])]),(_vm.isEditPackage)?_c('div',{staticClass:"d-flex mt-2"},[_c('button',{staticClass:"btn btn-danger btn-block",on:{"click":function($event){return _vm.deletePackage(_vm.med_package.package_code)}}},[_c('i',{staticClass:"fas fa-trash"}),_vm._v(" Delete Package: "+_vm._s(_vm.med_package.package_code)+" ")])]):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fas fa-search"})])])
}]

export { render, staticRenderFns }