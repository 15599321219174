<template>
  <div>
    <div class="card shadow mt-4 mb-4 p-2">
      <div class="card-body row">
        <div class="row mt-2">
          <div class="col-md-8 col-sm-12">
            <income-graph :payments="payments" :period="period"></income-graph>
          </div>
          <div class="col-md-4 col-sm-12">
            <live-billing-update :payments="payments"></live-billing-update>
          </div>
        </div>
      </div>
    </div>
    <income-expense :payments="payments"></income-expense>
    <payment-method-cash :payments="payments"></payment-method-cash>
    <payment-method-credit :payments="payments"></payment-method-credit>
    <payment-method-internetbanking :payments="payments"></payment-method-internetbanking>
    <payment-method-insurance :payments="payments" :invoices="invoices"></payment-method-insurance>
    <other-overall :payments="payments"></other-overall>
  </div>
</template>

<script>
export default {
  props: ['payments', 'period', 'invoices']
}
</script>