<template>
  <b-modal :id="id" title="Color Picker" hide-footer>
    <chrome v-model="selectedColor"/>
    <button class="btn btn-primary btn-block mt-2" @click="onSelect">
      <i class="fas fa-save"></i> Save
    </button>
  </b-modal>
</template>

<script>
import { Chrome } from 'vue-color'
export default {
  props: ['id','color','legend'],
  components:{
    Chrome
  },
  data(){
    return{
      selectedColor: {}
    }
  },
  mounted(){
    this.selectedColor = this.color
  },
  methods:{
    onSelect(){
      this.$emit('input', {
        color: `rgba(${this.selectedColor.rgba.r},${this.selectedColor.rgba.g},${this.selectedColor.rgba.b},${this.selectedColor.rgba.a})`,
        legend: this.legend
      })
      this.$bvModal.hide(this.id)
    }
  }
}
</script>