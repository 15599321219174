<template>
  <b-modal id="invoice-payment-summary-modal" hide-footer size="l" header-bg-variant="gray" :title="`Print ${menu} Summary`">
    <div class="row">
      <div class="col-md-6 col-sm-12 text-center">
        <MazPicker
          v-model="startDate"
          no-time
          format="DD/MM/YYYY"
          formatted="DD/MM/YYYY"
          placeholder="Start Date"
        />
      </div>
      <div class="col-md-6 col-sm-12 text-center">
        <MazPicker
          v-model="endDate"
          no-time
          format="DD/MM/YYYY"
          formatted="DD/MM/YYYY"
          placeholder="End Date"
        />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 text-right">
        <a target="_blank" :href="`/print/${routeMenu}?start=${startDate}&end=${endDate}`" class="btn btn-primary"><i class="fas fa-print"></i> Print</a>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['menu'],
  data() {
    return {
      startDate: this.$moment().add(-1, 'month').format('DD/MM/YYYY'),
      endDate: this.$moment().format('DD/MM/YYYY')
    }
  },
  computed: {
    routeMenu() {
      return this.menu === 'Invoice' ? 'invoices' : 'payments'
    }
  }
}
</script>