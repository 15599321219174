<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <div class="mt-3">
          <table class="table table-bordered">
            <thead class="text-center" style="background-color: #4472c4;">
              <tr class="text-white">
                <th rowspan="2">Time</th>
                <th colspan="2">BP</th>
                <th rowspan="2" style="width: 80px;">
                  PR
                  <br />/min
                </th>
                <th rowspan="2" style="width: 80px;">
                  RR
                  <br />/min
                </th>
                <th rowspan="2" style="width: 80px;">
                  Temp
                  <br />°C
                </th>
                <th rowspan="2" style="width: 80px;">
                  O2Sat
                  <br />%
                </th>
                <th rowspan="2" style="width: 80px;">
                  Pain
                  <br />Score
                </th>
                <th rowspan="2" style="width: 150px;">
                  Pain
                  <br />Location
                </th>
                <th rowspan="2" style="width: 100px;">DTX (mg/dl)</th>
                <th colspan="3" style="width: 70px;">GCS</th>
                <th colspan="4" style="width: 250px;">Pupil</th>
              </tr>
              <tr class="text-center">
                <th class="text-white">Sys</th>
                <th class="text-white">Dias</th>
                <th class="text-black" style="background-color: #cdd4ea;">E</th>
                <th class="text-black" style="background-color: #cdd4ea;">V</th>
                <th class="text-black" style="background-color: #cdd4ea;">M</th>
                <th
                  class="text-black"
                  style="background-color: #cdd4ea; width: 50px;"
                >
                  Lt
                </th>
                <th class="text-black" style="background-color: #cdd4ea;"></th>
                <th
                  class="text-black"
                  style="background-color: #cdd4ea; width: 50px;"
                >
                  Rt
                </th>
                <th class="text-black" style="background-color: #cdd4ea;"></th>
              </tr>
            </thead>
            <tbody class="text-center" style="background-color: #d0cece;">
              <tr>
                <td></td>
                <td style="color: #b30000;"></td>
                <td style="color: #b30000;"></td>
                <td></td>
                <td style="color: #b30000;"></td>
                <td></td>
                <td style="color: #b30000;"></td>
                <td style="color: #b30000;"></td>
                <td style="color: #b30000;"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bg-gray">
          <div class="pd10">
            <div class="mt-5">
              <b-tabs
                content-class="mt-3"
                active-nav-item-class="font-weight-bold text-light bg-warning"
              >
                <b-tab title="Physician Transfer form" active>
                  <physician-transfer-form />
                </b-tab>
                <b-tab title="Mode of Transport">
                  <mode-of-transport />
                </b-tab>
                <b-tab title="Nurse Transport Record">
                  <nurse-transport-record />
                </b-tab>
                <b-tab title="Consent for Transfer">
                  <patient-medical-transport-consent />
                </b-tab>
                <b-tab title="Upload Forms">
                  <upload-forms />
                </b-tab>
                <b-tab title="Transfer Equipment List">
                  <transfer-equipment-list />
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showEng: false,
      showTh: false,
      hideMenu: false,
      first_name: null,
      last_name: null,
      how_did_you_find_us: [],
      first_visited_date: null,
      phone_number_th: null,
      phone_number_g: null,
      phone_number_emer: null,
      temp: 22,
      pregnancy_status: null,
      temp_unit: null,
      showNursing: false,
      allergyList: [
        {
          allergy: ""
        }
      ],
      // date picker
      stdDateConfig: {
        altInput: true,
        altFormat: "d/m/Y"
      },
      stdDateTimeConfig: {
        altInput: true,
        altFormat: "d/m/Y H:i",
        enableTime: true,
        enableSeconds: false,
        time_24hr: true
      },
      stdTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      }
    };
  }
};
</script>
<style lang="scss" scoped>
//Main css
.holder {
  height: 62px;
}
.label-inside {
  margin-bottom: -50px;
  padding-left: 5px;
  padding-top: 0px;
  position: absolute;
  color: rgb(116, 116, 116);
  font-size: 16px;
}
.input-inside {
  height: 100%;
  padding-bottom: -30px;
}
input[type="text"] {
  // font-size: 18px;
}
table,
th,
td {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-collapse: collapse;
}
//end main
.mid {
  padding: 20px;
  margin-top: 50px;
}
.btn {
  background-color: rgb(212, 212, 212);
}
.center {
  text-align: center;
}
//margin padding
.ml2 {
  margin-left: 8px;
}
.mt2 {
  margin-top: 8px;
}
.mt3 {
  margin-top: 10px;
}
.pd10 {
  padding: 10px;
}
.mg-t {
  margin-top: 18px;
}
.bg-gray {
  background-color: rgb(221, 221, 221);
}
.bg-light {
  background-color: #fff;
}
.bg-red {
  background-color: rgb(243, 187, 187);
}
.bg-blue {
  background-color: rgb(190, 220, 240);
}
.bg-w {
  background-color: #fff;
}
.bg-hblue {
  background-color: rgb(17, 85, 204);
}
.bg-horange {
  background-color: rgb(230, 145, 56);
}
.pd-side {
  padding-left: 5px;
  padding-right: 5px;
}
.label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}
.profile-box {
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  height: 170px; /* minimum height */
  width: 170px;
  position: relative;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background: #e6e6e6;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  p {
    font-size: 1em;
    text-align: center;
    padding: 50px 10px;
  }
}
.input-icon {
  position: absolute;
  color: rgb(214, 38, 38);
  top: 23px;
  right: 22px;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.2), 1px -1px 0 rgba(0, 0, 0, 0.2),
    -1px 1px 0 rgba(0, 0, 0, 0.2), 1px 1px 0 rgba(0, 0, 0, 0.2);
}
.input-icon-red {
  color: rgb(214, 38, 38);
}
.box-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.sign-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  width: 40%;
  height: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}
.sign-detail {
  padding: 20px;
  margin: auto;
  width: 40%;
  margin-bottom: 20px;
}
.label-b {
  color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}
.label-h {
  // color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}
.esl-lv {
  border: 3px solid rgba(0, 0, 0, 0.3);
  width: 40px;
  height: 40px;
  margin-top: 10px;
  border-radius: 7px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  margin-left: 10px;
}

.form-check {
  margin-left: 10px;
}
button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.flex-even {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: white !important;
  background-color: #e78318;
}
</style>
