<template>
  <div class="row">
    <div class="col-md-7 col-sm-12">
      <div class="header-box">
        {{ patient.first_name }} {{ patient.last_name }}
      </div>
      <div class="content-box">
        <div class="d-flex">
          <div class="img-box mr-2">
            <div
              v-if="patient.img_url"
              class="avatar"
              :style="`background-image: url(${patient.img_url})`"
            />
            <img v-else class="avatar" src="/images/profile.svg" />
          </div>
          <div class="content-style flex-1">
            <div class="d-flex">
              <label class="content-label"><b>HN</b></label>
              <div class="text-muted">{{ patient.HN }}</div>
            </div>
            <div class="d-flex">
              <label class="content-label"><b>Age</b></label>
              <div
                class="text-muted"
                v-if="patient.age "
              >
                {{ patient.age }}
              </div>
            </div>
            <div class="d-flex">
              <label class="content-label"><b>Nationality</b></label>
              <div class="text-muted">{{ patient.nationality }}</div>
            </div>
            <div class="d-flex">
              <label class="content-label"><b>Insurance</b></label>
              <div class="text-muted" v-if="patient.travel_insurance && patient.travel_insurance.id !== 'Other'">
                {{ patient.travel_insurance.name_en }} ({{
                  patient.travel_insurance.type
                }})
              </div>
              <div class="text-muted" v-else-if="patient.travel_insurance && patient.travel_insurance.id === 'Other'">
                {{ patient.travel_insurance_other }}
              </div>
              <div class="text-muted" v-else>
                -
              </div>
            </div>

            <div class="header-box mt-2">Visit Information</div>
            <div class="content-box" v-if="nursing">
              <div class="d-flex">
                <label class="content-label"><b>Branch</b></label>
                <div
                  class="text-muted"
                  v-if="nursing.branch"
                >
                  {{nursing.branch.name_en}}
                </div>
              </div>
              <div class="d-flex">
                <label class="content-label"><b>Department</b></label>
                <div
                  class="text-muted"
                  v-if="nursing.department"
                >
                  {{nursing.department.name_en}}
                </div>
              </div>
              <div class="d-flex">
                <label class="content-label"><b>Time</b></label>
                <div
                  class="text-muted"
                  v-if="nursing.visit_date"
                >
                  {{nursing.visit_date.toDate() | moment('DD MMM YYYY HH:mm')}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5 col-sm-12">
      <div class="header-box d-flex">
        <div class="mr-auto">Diagnosis</div>
        <div v-if="nursing.esi_level" class="esi" :class="`esi-${nursing.esi_level}`">
          ESI {{nursing.esi_level}}
        </div>
      </div>
      <div class="content-box" style="min-height: 90px;">
        <div class="diagnosis-box" v-if="opd && opd.principal_diagnosis">
          {{opd.principal_diagnosis.desc}}
        </div>
      </div>
      <div class="header-box-red">
        <i class="fas fa-exclamation-triangle"></i> Medical Alert
      </div>
      <div class="content-box">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="medical-alert-header">Drug Allergy</div>
            <ul>
              <li v-for="item in patient.allergies" :key="item.id">
                {{ item.name }} ({{ item.type }})
              </li>
              <li v-for="item in patient.other_allergies" :key="item.id">
                {{ item.name }} ({{ item.type }})
              </li>
            </ul>
            <div class="medical-alert-header">Underlying Disease</div>
            <ul>
              <li v-for="(item, index) in nursing.underlying" :key="`underlying_${index}`">
                {{ item.code+' '+item.title }}
              </li>
            </ul>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="medical-alert-header">Taken Medication</div>
            <ul>
              <li v-for="(item, index) in nursing.current_medication" :key="`medication_${index}`">
                {{ item.medication }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    allergy_alert: {
      default: false,
      type: Boolean
    },
    patient: {
      default: () => {
        return {};
      }
    },
    nursing: {
      default: () => {
        return {};
      }
    },
    diagnosis: {
      default: () => {
        return null
      }
    },
    opd: {
      default: () => {
        return null
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.allergy-title {
  font-weight: 700;
  font-size: 20px;
}
.esi {
  border-radius: 0.25em;
  padding: 3px;
  color: white;
  background-color: lightgray;
  min-width: 150px;
  font-size: 32;
  font-weight: 800;
  text-align: center;
}

.esi-1 {
  background-color: #b30000;
}
.esi-2 {
  background-color: #ff8080;
}
.esi-3 {
  background-color: #ffcc00;
}
.esi-4 {
  background-color: #2eb82e;
}
.esi-5 {
  background-color: lightgrey;
}

.text-esi-1 {
  color: #b30000;
}
.text-esi-2 {
  color: #ff8080;
}
.text-esi-3 {
  color: #ffcc00;
}
.text-esi-4 {
  color: #2eb82e;
}
.text-esi-5 {
  color: grey;
}
.avatar {
  border-radius: 0.25em;
  height: 200px;
  width: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.dialog-border {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  font-size: 13px;
}

.allergy-box-header {
  padding: 8px;
  color: white;
  background-color: #ef0000;
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
}

.allergy-box-content {
  padding: 8px;
  color: #ef0000;
  font-weight: 600;
  background-color: lightgrey;
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  min-height: 100px;
}

.header-box {
  height: 60px;
  background-color: #adadad;
  color: white;
  padding: 10px;
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
}
.header-box-red {
  height: 60px;
  background-color: red;
  color: white;
  padding: 10px;
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
}

.content-box {
  background-color: #ededed;
  padding: 10px;
  width: 100%;
}

.content-style {
  font-weight: 600;
  min-width: 300px;
}

.img-box {
  min-width: 100px;
  min-height: 100px;
}

.diagnosis-box {
  color: #2244aa;
  font-style: italic;
}

.content-label {
  width: 150px;
}

.medical-alert-header {
  color: red;
  font-weight: 600;
  font-size: 20px;
}
</style>
