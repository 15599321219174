<template>
  <div>
    <b-modal :id="id" title="Order Laboratory" size="xl" hide-footer>
      <div class="mb-2">
        <div class="container-fluid">
          <h3 class="text-success mt-2">Worldmed Center Lab</h3>
          <table class="table table-bordered">
            <thead class="text-white" style="background-color: lightslategrey">
              <th></th>
              <th style="width: 500px;">Test</th>
              <th>Specimen</th>
              <th>Container</th>
              <th>Turn around time</th>
              <th>Collection Remarks/ Notes</th>
              <th></th>
            </thead>
            <tbody class="bg-lightgrey" style="background-color: lightgrey">
              <tr v-for="(item, index) in value.lab_select" :key="item.id">
                <td>
                  {{ item.date_time | moment('DD-MM-YYYY HH:mm')}}
                </td>
                <td>
                  {{ item.lab_test }}
                </td>
                <td>
                  {{ item.specimen }}
                </td>
                <td>
                  {{ item.sample }}
                </td>
                <td>
                  {{ item.turn_around_time }}
                </td>
                <td>
                  {{ item.note }}
                </td>
                <td>
                  <div class="text-center">
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      @click.prevent="value.splice(index, 1)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <h3 class="text-success mt-2">Out Lab</h3>
          <table class="table table-bordered">
            <thead class="text-white" style="background-color: lightslategrey">
              <th></th>
              <th style="width: 500px;">Test</th>
              <th>Specimen</th>
              <th>Container</th>
              <th>Turn around time</th>
              <th>Collection Remarks/ Notes</th>
              <th></th>
            </thead>
            <tbody class="bg-lightgrey" style="background-color: lightgrey">
              <tr v-for="(item, index) in value.outlab_select" :key="item.id">
                <td>
                  {{ item.date_time | moment('DD-MM-YYYY HH:mm')}}
                </td>
                <td>
                  {{ item.lab_test }}
                </td>
                <td>
                  {{ item.specimen }}
                </td>
                <td>
                  {{ item.sample }}
                </td>
                <td>
                  {{ item.turn_around_time }}
                </td>
                <td>
                  {{ item.note }}
                </td>
                <td>
                  <div class="text-center">
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      @click.prevent="value.splice(index, 1)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <button @click="$bvModal.show('select-lab')" class="btn btn-outline-success btn-block mt-2">
            <i class="fas fa-plus"></i> Order
          </button>
        </div>
      </div>
      <hr>
      <div class="d-flex justify-content-center">
        <button class="btn btn-primary mr-2" @click="onConfirm">Confirm Order</button>
        <button class="btn btn-danger mr-2" @click.prevent="$bvModal.hide(id)">Cancel</button>
      </div>
    </b-modal>
    <b-modal
      size="xl"
      id="select-lab"
      title="Select Laboratory Type"
      hide-footer
    >
      <div class="lab-group-name text-center d-flex justify-content-center" style="font-size: 24px">
        <span class="ml-auto mr-auto"></span>FREQUENTLY USE LAB
        <div style="color: white; margin-left: auto;cursor: pointer;" @click="full_layout=true" v-if="full_layout===false">View full layout</div>
        <div style="color: white; margin-left: auto;cursor: pointer;" @click="full_layout=false" v-if="full_layout!==false">Hide full layout</div>
      </div>
      <div class="text-center" style="font-size: 18; font-weight: 600;">1.2 all in-house lab done within 45 minutes. All out-lab takes longer and labeled by clock icon.</div>
      <div class="d-flex mt-2" v-if="full_layout===false">
        <div class="flex-fill">
          <div class="mt-2" v-for="group in dd_labgrp.filter(group => group === 'Hematology' || group === 'Chemistry')" :key="group.id">
            <div class="lab-group-name">{{ group }}</div>
            <div class="mt-2" v-for="lab in dd_invest_lab.filter(lab => lab.group === group)" :key="lab.id">
              <label>
                <input :checked="temp_lab_select.includes(lab)" type="checkbox" :value="lab" v-model="temp_lab_select" @click="toggleCheck(temp_lab_select, lab)">
                <span class="ml-1">{{ lab.lab_test }}</span>
              </label>
              <div class="mt-2 ml-4" v-for="sub_lab in lab.sub_labs" :key="sub_lab.id">
                <label>
                  <input :disabled="temp_lab_select.includes(lab)" :checked="temp_lab_select.includes(sub_lab)" type="checkbox" :value="sub_lab" v-model="temp_lab_select" @click="toggleCheck(temp_lab_select, sub_lab)">
                  <span class="ml-1">{{ sub_lab.lab_test }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-fill">
          <div class="mt-2" v-for="group in dd_labgrp.filter(group => group === 'Immunology' || group === 'Immunology : Cardiac Marker' || group === 'Immunology : Rapid Test')" :key="group.id">
            <div class="lab-group-name">{{ group }}</div>
            <div class="mt-2" v-for="lab in dd_invest_lab.filter(lab => lab.group === group)" :key="lab.id">
              <label>
                <input type="checkbox" :value="lab" v-model="temp_lab_select" @click="toggleCheck(temp_lab_select, lab)">
                <span class="ml-1">
                  {{ lab.lab_test }} 
                  <i class="fas fa-clock" v-if="lab.more_than_day"></i> <span class="text-muted" v-if="lab.more_than_day">{{lab.turn_around_time}}</span>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="flex-fill">
          <div class="mt-2" v-for="group in dd_labgrp.filter(group => group === 'Microscopy : Urine' || group === 'Microscopy : Stool' || group === 'Microscopy' || group === 'STD' || group === 'COVID Test' )" :key="group.id">
            <div class="lab-group-name">{{ group }}</div>
            <div class="mt-2" v-for="lab in dd_invest_lab.filter(lab => lab.group === group)" :key="lab.id">
              <label>
                <input type="checkbox" :value="lab" v-model="temp_lab_select" @click="toggleCheck(temp_lab_select, lab)">
                <span class="ml-1">
                  {{ lab.lab_test }}
                  <i class="fas fa-clock" v-if="lab.more_than_day"></i> <span class="text-muted" v-if="lab.more_than_day">{{lab.turn_around_time}}</span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <table class="table-bordered" v-if="full_layout!==false">
        <thead>
          <th>Laboratory Test</th>
          <th>Item Group</th>
          <th>Turn Around Time</th>
          <th>Specimen</th>
          <th>Sample/Type/Volume</th>
          <th>Storage condition</th>
          <th>Collection Remarks/ Notes</th>
        </thead>
        <tbody v-for="group in dd_labgrp.filter(group => group === 'Hematology' || group === 'Microscopy : Urine' || group === 'Microscopy : Stool' || group === 'Microscopy' || group === 'STD' || group === 'Chemistry')" :key="group.id">
          <tr >
            <td class="lab-group-name" colspan="7">{{group}}</td>
          </tr>
          <tr v-for="lab in dd_invest_lab.filter(lab => lab.group === group)" :key="lab.id">
            <td><input :checked="temp_lab_select.includes(lab)" type="checkbox" :value="lab" v-model="temp_lab_select" @click="toggleCheck(temp_lab_select, lab)"> {{lab.lab_test}}</td>
            <td>{{lab.lab_type}}</td>
            <td>{{lab.turn_around_time}}</td>
            <td>{{lab.specimen}}</td>
            <td>{{lab.sample}}</td>
            <td>{{lab.storage_condition}}</td>
            <td>{{lab.collection_remark}}</td>
          </tr>
        </tbody>
        <tbody v-for="group in dd_labgrp.filter(group => group === 'Immunology' || group === 'Immunology : Cardiac Marker' || group === 'Immunology : Rapid Test')" :key="group.id">
          <tr >
            <td class="lab-group-name" colspan="7">{{group}}</td>
          </tr>
          <tr v-for="lab in dd_invest_lab.filter(lab => lab.group === group)" :key="lab.id">
            <td><input :checked="temp_lab_select.includes(lab)" type="checkbox" :value="lab" v-model="temp_lab_select" @click="toggleCheck(temp_lab_select, lab)"> {{lab.lab_test}}</td>
            <td>{{lab.lab_type}}</td>
            <td>{{lab.turn_around_time}}</td>
            <td>{{lab.specimen}}</td>
            <td>{{lab.sample}}</td>
            <td>{{lab.storage_condition}}</td>
            <td>{{lab.collection_remark}}</td>
          </tr>
        </tbody>
        <tbody v-for="group in dd_labgrp.filter(group => group === 'COVID Test' )" :key="group.id">
          <tr >
            <td class="lab-group-name" colspan="7">{{group}}</td>
          </tr>
          <tr v-for="lab in dd_invest_lab.filter(lab => lab.group === group)" :key="lab.id">
            <td><input :checked="temp_lab_select.includes(lab)" type="checkbox" :value="lab" v-model="temp_lab_select" @click="toggleCheck(temp_lab_select, lab)"> {{lab.lab_test}}</td>
            <td>{{lab.lab_type}}</td>
            <td>{{lab.turn_around_time}}</td>
            <td>{{lab.specimen}}</td>
            <td>{{lab.sample}}</td>
            <td>{{lab.storage_condition}}</td>
            <td>{{lab.collection_remark}}</td>
          </tr>
        </tbody>
      </table>

      <div class="lab-group-name text-center">OTHER SEND-OUT LAB</div>
      <div class="row">
        <div class="col-8 mt-1">
          <MazSelect
            v-model="selected_outlab"
            search
            :options="
              outlab.map(item => {
                return {
                  label: item.lab_test,
                  value: item
                };
              })
            "
          />
        </div>
        <div class="col-4">
          <button class="btn btn-primary btn-block mt-2" @click="addOutlab(selected_outlab)"><i class="fas fa-plus"></i> Add</button>
        </div>
      </div>

      <div class="d-flex">
        <b-dropdown id="dropdown-right" right text="Show Columns" variant="primary" class="ml-auto">
          <b-dropdown-item @click.native.capture.stop="show_turn_around_time=!show_turn_around_time">
            <i class="fas fa-check-square text-primary" v-if="show_turn_around_time"></i><i class="far fa-square" v-else></i> Turn around time
          </b-dropdown-item>
          <b-dropdown-item @click.native.capture.stop="show_sample=!show_sample">
            <i class="fas fa-check-square text-primary" v-if="show_sample"></i><i class="far fa-square" v-else></i> Sample
          </b-dropdown-item>
          <b-dropdown-item @click.native.capture.stop="show_storage_condition=!show_storage_condition">
            <i class="fas fa-check-square text-primary" v-if="show_storage_condition"></i><i class="far fa-square" v-else></i> Storage Condition
          </b-dropdown-item>
          <b-dropdown-item @click.native.capture.stop="show_collection_remark=!show_collection_remark">
            <i class="fas fa-check-square text-primary" v-if="show_collection_remark"></i><i class="far fa-square" v-else></i> Collection Remark
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <table class="table table-bordered mt-2">
        <thead>
          <th style="width: 500px;">Laboratory Test</th>
          <th v-if="show_turn_around_time">Turn Around Time</th>
          <th v-if="show_sample">Sample type/Voume (ml)</th>
          <th v-if="show_storage_condition">Storage Condition</th>
          <th v-if="show_collection_remark">Collection Remarks/Note</th>
          <th>Action</th>
        </thead>
        <tbody>
          <tr v-for="(item, index) in selected_outlabs" :key="index">
            <td>{{item.lab_test}}</td>
            <td v-if="show_turn_around_time">{{item.turn_around_time}}</td>
            <td v-if="show_sample">{{item.sample}}</td>
            <td v-if="show_storage_condition">{{item.storage_condition}}</td>
            <td v-if="show_collection_remark">{{item.collection_remark}}</td>
            <td><button class="btn btn-light" @click="selected_outlabs.splice(index, 1)"><i class="fas fa-trash"></i></button></td>
          </tr>
        </tbody>
      </table>
      <div style="float: right;">
        <button
          style="width: 150px;"
          class="btn btn-primary mt-3 ml-2"
          @click.prevent="orderLab"
        >
          Confirm
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import opdMixins from "../../../mixins/opd";
import _ from "lodash";
const lab_grp = [
  "Hematology",
  "Microscopy : Urine",
  "Microscopy : Stool",
  "Microscopy",
  "STD",
  "Chemistry",
  "Immunology",
  "Immunology : Cardiac Marker",
  "Immunology : Rapid Test",
  "COVID Test",
  "Other tests"
]
const invest_type = ["EKG", "X-Ray"];
import dd_invest_lab from "../../../assets/invest_lab.json";
import outlab from  "../../../assets/outlab.json";
export default {
  mixins: [opdMixins],
  data() {
    return {
      id: 'order-laboratory',
      dd_labgrp: lab_grp,
      dd_invest_type: invest_type,
      dd_invest_lab,
      selected_outlab: null,
      outlab,
      selected_outlabs: [],
      full_layout: false,
      show_turn_around_time: true,
      show_sample: true,
      show_storage_condition: true,
      show_collection_remark: true,
    }
  },
  props: {
    value: {
      default: () => {
        return {
          lab_select: [],
          outlab_select: []
        }
      }
    },
    onClose: {
      default: () => {}
    },
    onConfirm: {
      default: () => {}
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === this.id)
      this.onClose()
    })
  },
  methods: {
    async orderLab() {
      if (this.temp_lab_select) {
        let labTestList = this.value.lab_select.map(m => m.lab_test)
        await this.temp_lab_select.map(item => {
          if (!labTestList.includes(item.lab_test)) {
            let obj = {
              lab_test: item.lab_test,
              is_input: item.is_input,
              is_sub: item.is_sub,
              group: item.group,
              lab_type: item.lab_type,
              turn_around_time: item.turn_around_time,
              specimen: item.specimen,
              sample: item.sample,
              storage_condition: item.storage_condition,
              is_against: false,
              against_reason:  null
            }
            this.value.lab_select.push(obj)
          }

        })
        let dif_lab = _.difference( this.value.lab_select.map(m => m.lab_test), this.temp_lab_select.map(m => m.lab_test))
        if (dif_lab.length > 0) {
          this.value.lab_select.map(f => {
            if (dif_lab.includes(f.lab_test)) {
              dif_lab.forEach(d => {
                _.remove(this.value.lab_select, item => 
                  item.lab_test === d
                )
              })
            }
          })
        }

        this.value.outlab_select = [...this.selected_outlabs]
        this.$bvModal.hide("select-lab");
        this.$bvModal.hide(id);
      }
      this.$mount()
    },
    addOutlab(lab) {
      if(lab) {
        this.selected_outlabs.push(lab);
        this.selected_outlab=null
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.lab-group-name {
  background-color: #A4C964;
  padding: 8px;
  border-radius: 0.25em;
  margin-right: 5px;
  font-weight: 600;
}

td {
  padding: 5px;
}

th {
  padding: 5px;
}
</style>