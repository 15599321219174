<template>
  <div class="mt-4 mb-4">
    <h5 class="text-primary">Payment Method: Insurance</h5>
    <hr>
    <div class="card shadow">
      <div class="card-body row mt-2">
        <div class="col-md-8 col-sm-12">
          <h5>Invoice ( Expected Amount )</h5>
          <table class="table table-bordered table-striped">
            <thead>
              <th>Name</th>
              <th>Insurance name</th>
              <th class="text-right">Amount</th>
            </thead>
            <tbody>
              <tr v-for="(item, index) in paginatedExpects" :key="index">
                <td>
                  {{ item.patient_name }}
                  <!-- <a :href="`/print/receipt/${item.payment_id}?mode=hide`" target="_blank">{{ item.patient_name }}</a> -->
                </td>
                <td>{{ item.insurance_name === 'Other' ? (item.insurance_name_other === null ? item.insurance_name :
                  item.insurance_name_other) : item.insurance_name }}</td>
                <td class="text-right">{{ item.outstanding || item.outstanding === 0 ?
                  (Math.round(item.total_invoiced) - Math.round(item.payment_applied)).toLocaleString() :
                  Math.round(item.insurance).toLocaleString() }}</td>
              </tr>
              <tr>
                <td colspan="2">Total</td>
                <td class="text-right">{{ Math.round(_.sumBy(filteredPayments, item =>
                  item.insurance) + _.sumBy(filteredInvoices, item =>
                    Math.round(item.total_invoiced) - Math.round(item.payment_applied))).toLocaleString() }}</td>
              </tr>
            </tbody>
          </table>
          <table-pagination :items="filteredExpects" :onChange="(items) => { paginatedExpects = items }"
            :perPage="10" />

          <h5>Payment ( Actual Amount )</h5>
          <table class="table table-bordered table-striped">
            <thead>
              <th>Name</th>
              <th>Insurance name</th>
              <th class="text-right">Amount</th>
            </thead>
            <tbody>
              <tr v-for="(item, index) in paginatedPayments" :key="index">
                <td><a :href="`/print/receipt/${item.payment_id}?mode=hide`" target="_blank">{{ item.patient_name }}</a>
                </td>
                <td>{{ item.insurance_name === 'Other' ? item.insurance_name_other : item.insurance_name }}</td>
                <td class="text-right">{{ Math.round(item.insurance).toLocaleString() }}</td>
              </tr>
              <tr>
                <td colspan="2">Total</td>
                <td class="text-right">{{ Math.round(_.sumBy(filteredPayments, item => item.insurance)
                ).toLocaleString() }}</td>
              </tr>
            </tbody>
          </table>
          <table-pagination :items="filteredPayments" :onChange="(items) => { paginatedPayments = items }"
            :perPage="10" />
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="d-flex mt-2 mb-2">
            <h5 class="text-primary">Insurance</h5>
            <button class="btn btn-primary ml-auto" @click="$bvModal.show('payment-insurance-setting')">
              <i class="fas fa-cog"></i>
            </button>
          </div>
          <Doughnut :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'"
            :chart-id="'insurance'" :width="400" :height="400" />

          <modal-graph-color-setting @change="saveColor" v-model="colorList" :id="'payment-insurance-setting'"
            :legends="legendList" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GraphColorSettingMixins from '../../mixins/graph-color-setting'
import ModalGraphColorSetting from '../../components/Modal/GraphColorSetting.vue'
import {
  Doughnut
} from 'vue-chartjs/legacy'

export default {
  props: ['payments', 'invoices'],
  mixins: [GraphColorSettingMixins],
  components: {
    Doughnut,
    ModalGraphColorSetting
  },
  data() {
    return {
      GRAPH_ID: 'PaymentInsuranceGraph',
      chartOptions: {
        responsive: true,
        indexAxis: 'x',
        plugins: {
          datalabels: {
            color: '#000',
            anchor: 'center',
            align: 'center',
            formatter: (value, context) => {
              const dataset = context.dataset;
              const total = dataset.data.reduce((sum, currentValue) => sum + currentValue, 0);
              const percentage = ((value / total) * 100).toFixed(2);
              return `${Math.round(value).toLocaleString()} \n(${Math.round(percentage)}%)`;
            }
          },
          legend: {
            display: true // Set this to false to hide the legend
          },
        }
      },
      preparedPayments: [],
      paginatedPayments: [],
      paginatedExpects: [],
    }
  },
  watch: {
    payments: {
      handler() {
        this.setLegendList(this.preparedData.map(item => item.type))
        this.setColorList(this.generateBackgroundColor(this.preparedData, 'type'))
      },
      immediate: true
    },
    colorSetting: {
      handler() {
        this.setLegendList(this.preparedData.map(item => item.type))
        this.setColorList(this.generateBackgroundColor(this.preparedData, 'type'))
      },
      immediate: true
    },
  },
  computed: {
    filteredPayments() {
      return this._.filter(this.payments, p => p.insurance > 0)
    },
    filteredInvoices() {
      return this._.filter(this.invoices, p => p.status === 'gop')
    },
    filteredExpects() {
      return [...this.filteredInvoices, ...this.filteredPayments]
    },
    preparedData() {
      let data = [{
        type: 'Expected Amount',
        amount: 0,
        index: 0
      }, {
        type: 'Actual Amount',
        amount: 0,
        index: 1
      },]

      for (let item of this.filteredInvoices) {
        data[0].amount += item.total_invoiced - item.payment_applied
        
      }
      for (let item of this.filteredPayments) {
        data[0].amount += item.insurance
        data[1].amount += item.insurance
      }

      return data
    },
    chartData() {

      let chartData = {
        labels: ['Expected Amount', 'Actual Amount'],
        datasets: [{
          label: 'Insurance',
          data: this.preparedData.map(d => d.amount),
          backgroundColor: this.colorList
        }]
      }

      return chartData
    }
  }
}
</script>