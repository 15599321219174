<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <div v-if="!is_edit">
          <nurse-assessment-summary :patient="patient" />
          <div class="text-center mt-3">
            <button style="margin-left: 10px; width: 150px;" type="button" class="btn btn-gray btn-lg"
              @click="is_edit = true">
              <i class="fas fa-edit"></i> Edit
            </button>

          </div>
        </div>
        <div v-if="is_edit === true">
          <div class="d-flex">
            <div class="ml-auto">
              <button style="width: 200px;" type="button" class="btn btn-secondary btn-lg" @click="is_edit = false"
                v-if="is_nursing">
                <i class="fas fa-eye"></i> View
              </button>
            </div>
          </div>
          <div class="bg-general mt-2">
            <div class="p-2">
              <!-- <button @click.prevent="pushDD">PUSh</button> -->
              <div class="d-flex flex-row justify-content-center">
                <div class="col-6 text-center">
                  <div class="logo-box">
                    <img src="/images/worldmed-logo-2.jpg" class="img-fluid" />
                  </div>
                  <div class="align-self-end">
                    <h3>
                      ER / OPD NURSING ASSESSMENT
                    </h3>
                  </div>
                  <div class="row">
                    <div class="radio-wrapper-23">
                      <input id="radio-1" type="radio" value="1" v-model="nursing.esi_level" class="radio-1 ">
                      <label for="radio-1">
                        1. Super red
                      </label>
                      <input id="radio-2" type="radio" value="2" v-model="nursing.esi_level" class="radio-2 ">
                      <label for="radio-2">
                        2. Red
                      </label>
                      <input id="radio-3" type="radio" value="3" v-model="nursing.esi_level" class="radio-3 ">
                      <label for="radio-3">
                        3. Yellow
                      </label>
                      <input id="radio-4" type="radio" value="4" v-model="nursing.esi_level" class="radio-4 ">
                      <label for="radio-4">
                        4. Green
                      </label>
                      <input id="radio-5" type="radio" value="5" v-model="nursing.esi_level" class="radio-5 ">
                      <label for="radio-5">
                        5. White
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-6 detail-box flex-fill flex-row align-items-stretch mt-3">
                  <div class="field">Name: {{ patient.first_name + ' ' + patient.last_name || '' }}</div>
                  <div class="field d-flex align-items-stretch">
                    <div class="sub-field flex-2 border-r">Date of Birth: {{ patient.date_of_birth ?
                      formatDate(patient.date_of_birth) : '' }}</div>
                    <div class="sub-field flex-1 border-r pl-2">Age: {{ patient.age }}</div>
                    <div class="sub-field flex-1 pl-2">Gender: {{ patient.gender }}</div>
                  </div>

                  <div class="field d-flex align-items-stretch">
                    <div class="sub-field flex-1 border-r">HN : {{ patient.HN }}</div>
                    <div class="sub-field flex-1 border-r">VN : {{ $route.params.visitId }}</div>

                  </div>
                  <div class="field d-flex align-items-stretch">
                    <div class="sub-field flex-1 ">Visit Date : {{
                      $route.params.visitId.slice(11, 13) + '/' + $route.params.visitId.slice(13, 15) + '/' +
                      $route.params.visitId.slice(15, 19)
                    }}</div>
                  </div>
                  <div class="field d-flex align-items-stretch">
                    <div class="sub-field flex-1 ">When will you leave Phi Phi : {{ patient.deeparture_date ?
                      formatDate(patient.departure_date) : '-' }}
                    </div>
                  </div>
                  <div class="field d-flex align-items-stretch">
                    <input type="text" class="form-control" placeholder="Where is your next destination"
                      v-model="nursing.next_destination" />
                  </div>

                </div>
              </div>
              <div class="d-flex flex-row align-self-start">
                <div class="">
                  <div class="input-group">
                    <MazPicker v-model="nursing.time_to_arrival" format="DD/MM/YYYY HH:mm" formatted="DD/MM/YYYY HH:mm"
                      placeholder="Time to Arrival" />
                  </div>
                </div>
                <div class="ml-5">
                  <div class="input-group">
                    <MazPicker v-model="nursing.time_to_physician" format="DD/MM/YYYY HH:mm"
                      formatted="DD/MM/YYYY HH:mm" placeholder="Time to Physician" />
                  </div>
                </div>
                <div class="ml-5">
                  <div class="d-flex mt-2">
                    <div>
                      <div class="input-group" style="width: 180px;">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label">Height</span>
                        </div>
                        <input type="text" class="form-control border-right-0 p-1" v-model="nursing.height" />
                        <div class="input-group-append">
                          <select class="form-control input-group-text bg-white" v-model="nursing.height_unit">
                            <option value="cm" selected>cm</option>
                            <option value="ft">ft</option>
                          </select>
                        </div>
                      </div>
                      <div class="text-center">
                        {{ getHeight }}
                      </div>
                    </div>
                    <div class="pl-2">
                      <div class="input-group" style="width: 180px;">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label">Weight</span>
                        </div>
                        <input type="text" class="form-control border-right-0 p-1" v-model="nursing.weight" />
                        <div class="input-group-append">
                          <select class="form-control input-group-text bg-white" v-model="nursing.weight_unit">
                            <option value="kg" selected>kg</option>
                            <option value="lb">lb</option>
                            <option value="st">st</option>
                          </select>
                        </div>
                      </div>
                      <div class="text-center">
                        {{ getWeight }}
                      </div>
                    </div>
                    <div class="pl-2">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label">BMI</span>
                        </div>
                        <input type="text" class="form-control border-left-0 border-right-0" :value="getBmi()"
                          :class="checkBmi(getBmi())" :v-model="nursing.bmi" />
                        <div class="input-group-append">
                          <span class="input-group-text p-1 bg-white label">(auto)</span>
                        </div>
                      </div>
                      <div v-if="getBmi()">
                        <input class="form-control" type="text" :value="alertBmi(getBmi())" readonly />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex mt-3">
                <div class="label-b">
                  <i class="fas fa-stethoscope"></i>
                  Vital Signs and Evaluation
                  <button type="button" class="btn btn-primary" @click="addRowList('nursing', 'vital_sign', 1)">
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>

              <!-- vital sign list -->
              <div class="card shadow mt-2" v-for="(i, index) in nursing.vital_sign" :key="index">
                <div class="card-body" style="background-color: #f5f5f0;">
                  <div class="row">
                    <div class="col">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label">Time</span>
                        </div>

                        <flat-pickr class="form-control" :config="stdTimeConfig" style="background-color: #fff;"
                          v-model="nursing.vital_sign[index].time"></flat-pickr>
                      </div>
                    </div>
                    <div class="col">
                      <div class="input-group" style="min-width: 220px;">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label">BP</span>
                        </div>
                        <input type="text" class="form-control border-right-0 p-1" placeholder="Sys"
                          v-model="nursing.vital_sign[index].bp_syst"
                          :class="checkBPSys(i.bp_syst, patient.ageMonth, patient.ageDay)"
                          @change="alertBPSys(i.bp_syst, patient.ageMonth, patient.ageDay)" />
                        <div class="input-group-append">
                          <span class="input-group-text p-1 bg-white label border-right-0">/</span>
                        </div>
                        <input type="text" class="form-control border-left-0 p-1" placeholder="Dias"
                          v-model="nursing.vital_sign[index].bp_dias" :class="checkBPDias(i.bp_dias, patient.ageMonth)"
                          @blur="alertBPDias(i.bp_dias, patient.ageMonth)" />
                        <div class="input-group-append">
                          <span class="input-group-text p-1 bg-white label">mmHg</span>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label">PR</span>
                        </div>
                        <input type="text" class="form-control p-1" v-model="nursing.vital_sign[index].pr"
                          :class="checkHR(i.pr, patient.ageMonth)" @blur="alertHR(i.pr, patient.ageMonth)" />
                        <div class="input-group-append">
                          <span class="input-group-text p-1 bg-white label">/min</span>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label">RR</span>
                        </div>
                        <input type="text" class="form-control p-1" v-model="nursing.vital_sign[index].rr"
                          :class="checkRR(i.rr, patient.ageMonth)" @blur="alertRR(i.rr, patient.ageMonth)" />
                        <div class="input-group-append">
                          <span class="input-group-text p-1 bg-white label">/min</span>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="input-group" style="min-width: 170px;">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label">Temp</span>
                        </div>
                        <input type="text" class="form-control p-1" v-model="nursing.vital_sign[index].temp"
                          :class="checkTemp(i.temp, i.temp_unit)" @blur="alertTemp(i.temp, i.temp_unit)" />
                        <div class="input-group-append">
                          <select class="form-control input-group-text bg-white p-2"
                            v-model="nursing.vital_sign[index].temp_unit" @blur="alertTemp(i.temp, i.temp_unit)">
                            <!-- <option value disabled selected></option> -->
                            <option value="C" selected>°C</option>
                            <option value="F">°F</option>
                          </select>
                        </div>
                      </div>
                      <div class="text-center">
                        {{
                          i.temp && i.temp_unit
                            ? `(${getTemp(i.temp, i.temp_unit)})`
                            : null
                        }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label">O2Sat</span>
                        </div>
                        <input type="text" class="form-control p-1" v-model="nursing.vital_sign[index].o2sat"
                          @blur="alertO2Sat(i.o2sat)" :class="checkO2Sat(i.o2sat)" />
                        <div class="input-group-append">
                          <span class="input-group-text p-1 bg-white label">%</span>
                        </div>
                      </div>
                    </div>
                    <div class="col" style="min-width: 150px;">
                      <select class="form-control" v-model="nursing.vital_sign[index].o2sat_select">
                        <option value selected></option>
                        <option :value="dd.name" v-for="(dd, index) in dd_o2sat" :key="`${dd.id}_${index}`">{{ dd.name
                          }}</option>
                      </select>
                    </div>
                    <div class="col">
                      <button type="button" class="btn btn-secondary"
                        @click="removeRowList('nursing', 'vital_sign', index)">
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-2">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label">DTX</span>
                        </div>
                        <input type="text" class="form-control p-1" :id="'dtx_' + index"
                          v-model="nursing.vital_sign[index].dtx" :class="checkDTX(i.dtx, i.dtx_unit)"
                          @change="alertDTX(i.dtx, i.dtx_unit)" style="width: 70px;" />
                        <div class="input-group-append">
                          <select class="form-control input-group-text bg-white p-2"
                            v-model="nursing.vital_sign[index].dtx_unit" :id="'dtx_unit_' + index">
                            <!-- <option value disabled selected></option> -->
                            <option value="mg/dl" selected>mg/dl</option>
                            <option value="mmol/L">mmol/L</option>
                          </select>
                        </div>
                      </div>
                      <div class="text-center">
                        {{
                          i.dtx && i.dtx_unit
                            ? `(${getDtx(i.dtx, i.dtx_unit)})`
                            : null
                        }}
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row">
                        <div class="input-group col">
                          <div class="input-group-prepend">
                            <span class="input-group-text p-1 bg-white label">E</span>
                          </div>
                          <select class="form-control non-arrow" v-model="nursing.vital_sign[index].vs_e"
                            :class="checkGcsE(i.vs_e)">
                            <option value selected></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                          </select>
                        </div>

                        <div class="input-group col">
                          <div class="input-group-prepend">
                            <span class="input-group-text p-1 bg-white label">V</span>
                          </div>
                          <select class="form-control non-arrow" v-model="nursing.vital_sign[index].vs_v"
                            :class="checkGcsV(i.vs_v)">
                            <option value selected></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>

                        <div class="input-group col">
                          <div class="input-group-prepend">
                            <span class="input-group-text p-1 bg-white label">M</span>
                          </div>
                          <select class="form-control non-arrow" v-model="nursing.vital_sign[index].vs_m"
                            :class="checkGcsM(i.vs_m)">
                            <option value selected></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row">
                        <label>Pupil</label>
                        <div class="input-group col">
                          <div class="input-group-prepend">
                            <span class="input-group-text p-1 bg-white label">Lt.</span>
                          </div>
                          <input type="text" class="form-control p-1"
                            v-model="nursing.vital_sign[index].pupil_lt_value" />
                          <div class="input-group-append">
                            <span class="input-group-text p-1 bg-white label">mm</span>
                          </div>
                        </div>

                        <div class="input-group col">
                          <div class="input-group-prepend">
                            <span class="input-group-text p-1 bg-white label">Lt.</span>
                          </div>
                          <select class="form-control non-arrow" v-model="nursing.vital_sign[index].pupil_lt">
                            <option value selected></option>
                            <option value="R">R</option>
                            <option value="S">S</option>
                            <option value="F">F</option>
                          </select>
                        </div>

                        <div class="input-group col">
                          <div class="input-group-prepend">
                            <span class="input-group-text p-1 bg-white label">Rt.</span>
                          </div>
                          <input type="text" class="form-control p-1"
                            v-model="nursing.vital_sign[index].pupil_rt_value" />
                          <div class="input-group-append">
                            <span class="input-group-text p-1 bg-white label">mm</span>
                          </div>
                        </div>

                        <div class="input-group col">
                          <div class="input-group-prepend">
                            <span class="input-group-text p-1 bg-white label">Rt.</span>
                          </div>
                          <select class="form-control non-arrow" v-model="nursing.vital_sign[index].pupil_rt">
                            <option value selected></option>
                            <option value="R">R</option>
                            <option value="S">S</option>
                            <option value="F">F</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="flex-fill ml-2">
                      <div class="input-group" style="min-width: 130px;">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label">Painscore</span>
                        </div>
                        <select id="pain_score" class="form-control non-arrow"
                          v-model="nursing.vital_sign[index].pain_score" :class="checkPainScore(i.pain_score)"
                          @blur="alertPainScore(i.pain_score)">
                          <option value disabled selected hidden></option>
                          <option value="0" selected>0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>

                    <div class="pl-2 flex-fill">
                      <div class="input-group" style="min-width: 170px;">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label">Location</span>
                        </div>
                        <input type="text" class="form-control p-1" v-model="nursing.vital_sign[index].pain_location" />
                      </div>
                    </div> -->

                  </div>
                </div>
              </div>
              <!-- <hr /> -->

            </div>
          </div>
          <hr />
          <div class="bg-general">
            <div class="pd10">
              <div class="d-flex flex-row">
                <div class="col-6">
                  <div class="input-group" style="min-width: 320px;">
                    <div class="input-group-prepend">
                      <span class="input-group-text p-1 bg-white label">Mode of Arrival</span>
                    </div>
                    <div class="input-group-text">
                      <div v-for="(dd, index) in $store.state.dd_mode_of_arrival" :key="index">
                        <input :id="dd.name" type="radio" :value="dd.name" v-model="nursing.mode_of_arrival"
                          class="mx-2">
                        <label :for="dd.name">{{ dd.name }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="input-group mt-2" style="min-width: 320px;">
                    <div class="input-group-prepend">
                      <span class="input-group-text p-1 bg-white label">Fall Precaution</span>
                    </div>
                    <div class="input-group-text">
                      <div v-for="(dd, index) in $store.state.dd_fall_precaution" :key="index">
                        <input :id="dd.name" type="radio" :value="dd.name" v-model="nursing.fall_precaution"
                          class="mx-2">
                        <label :for="dd.name">{{ dd.name }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="input-group mt-2" style="min-width: 320px;">
                    <div class="input-group-prepend">
                      <span class="input-group-text p-1 bg-white label">Purpose</span>
                    </div>
                    <div class="input-group-text">
                      <div v-for="(dd, index) in $store.state.dd_purpose" :key="index">
                        <input :id="dd.name" type="radio" :value="dd.name" v-model="nursing.purpose" class="mx-2">
                        <label :for="dd.name">{{ dd.name }}</label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <input type="text" class="form-control mt-2" placeholder="Ambulance pick up from"
                      v-if="nursing.purpose === 'Ambulance pick up from'" v-model="nursing.purpose_amb_pick_from" />
                  </div>

                  <div class="input-group mt-2" style="min-width: 320px;">
                    <div class="input-group-prepend">
                      <span class="input-group-text p-1 bg-white label">Escort</span>
                    </div>

                    <select class="form-control" v-model="nursing.escort" style="max-width: 250px;">
                      <option value selected></option>
                      <option :value="dd.escort_name" v-for="(dd, index) in dd_escort" :key="index">{{ dd.escort_name }}
                      </option>
                    </select>

                  </div>
                  <div>
                    <input class="form-control mt-2" type="text" id="escort_other_input"
                      v-model="nursing.escort_other_input" v-if="nursing.escort === 'Others'" placeholder="Others" />
                  </div>
                  <div class="input-group mt-2" style="min-width: 320px;">
                    <div class="input-group-prepend">
                      <span class="input-group-text p-1 bg-white label">General Appearance</span>
                    </div>
                    <div class="input-group-text">
                      <div v-for="(dd, index) in $store.state.dd_general_app" :key="index">
                        <input :id="dd.name" type="radio" :value="dd.name" v-model="nursing.general_appearance"
                          class="mx-2">
                        <label :for="dd.name">{{ dd.name }}</label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <input type="text" class="form-control mt-2" placeholder="Other"
                      v-if="nursing.general_appearance === 'Other'" v-model="nursing.general_appearance_other" />
                  </div>
                  <div class="row mt-3">
                    <label class="ml-3">Vaccination (For Trauma Case Only)</label>
                    <div class="d-flex mt-2">
                      <div class="pl-3 mt-1">
                        Tetanus
                      </div>
                      <div class="pl-5" style="min-width: 300px;">
                        <div>
                          <select id="tetanus" class="form-control" v-model="nursing.tetanus">
                            <option value selected></option>
                            <option :value="dd.name" v-for="(dd, index) in $store.state.dd_tetanus" :key="index">{{
                              dd.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="pl-2" style="min-width: 250px;">
                        <input type="text" class="form-control" v-model="nursing.tetanus_more" placeholder="Note" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="d-flex mt-2">
                      <div class="pl-3 mt-1">
                        Rabies
                      </div>
                    </div>
                    <div class="pl-3" style="min-width: 300px;">
                      <div class="d-flex mt-2" v-for="(i, index) in nursing.rabies" :key="index">
                        <div class="pl-3">
                          <select id="rabies" class="form-control" v-model="nursing.rabies[index].rabies_value">
                            <option value selected></option>
                            <option :value="dd.name" v-for="(dd, index) in $store.state.dd_rabies" :key="index">
                              {{ dd.name }}</option>
                          </select>
                        </div>
                        <div class="pl-2">
                          <input class="form-control" type="text" placeholder="Note"
                            v-model="nursing.rabies[index].rabies_note" />
                        </div>
                        <div class="pl-2">
                          <button type="button" class="btn btn-primary" @click="addRowList('nursing', 'rabies', 1)">
                            <i class="fa fa-plus"></i>
                          </button>
                        </div>
                        <div class="pl-2">
                          <button type="button" class="btn btn-secondary"
                            @click="removeRowList('nursing', 'rabies', index)">
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="row mt-3">
                    <div class="col-2 mt-2">
                      <label>Underlying</label>
                    </div>
                    <div class="col-10">
                      <div class="d-flex flex-row" v-for="(i, index) in nursing.underlying" :key="index">

                        <div class='flex-fill'>
                          <icd10-select v-model="nursing.underlying[index]" :iNo="index" />
                        </div>
                        <div class="ml-2">
                          <button type="button" class="btn btn-primary" @click="addRowList('nursing', 'underlying', 1)">
                            <i class="fa fa-plus"></i>
                          </button>
                        </div>
                        <div class="ml-2">
                          <button type="button" class="btn btn-secondary"
                            @click="removeRowList('nursing', 'underlying', index)">
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-2">
                      <label>Surgery</label>
                    </div>
                    <div class="col-10">
                      <div class="d-flex flex-row">
                        <div class="flex-fill" v-for="(i, index) in nursing.surgery" :key="index">
                          <select id="surgery" class="form-control" style="min-width: 350px;"
                            v-model="nursing.surgery[index].surgery_name">
                            <option value selected></option>
                            <option :value="dd.name" v-for="(dd, i) in $store.state.dd_surgery" :key="i">{{ dd.name
                              }}
                            </option>
                          </select>
                        </div>
                        <div class="ml-2">
                          <button type="button" class="btn btn-primary" @click="addRowList('nursing', 'surgery', 1)">
                            <i class="fa fa-plus"></i>
                          </button>
                        </div>
                        <div class="ml-2">
                          <button type="button" class="btn btn-secondary"
                            @click="removeRowList('nursing', 'surgery', index)">
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>


                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="row">
                      <div class="col-3">
                        <div class="pl-3">
                          <span>Family History</span>
                        </div>
                      </div>
                      <div class="col-9">
                        <div class="d-flex " v-for="(i, index) in nursing.family" :key="index">
                          <div class="flex-4">
                            <select class="form-control" :id="`family_history_${index}`"
                              v-model="nursing.family[index].family_history">
                              <option value selected></option>
                              <option value="Other">Other</option>
                              <option :value="dd.name" v-for="(dd, index) in $store.state.dd_family" :key="index">{{
                                dd.name }}</option>
                            </select>
                            <input type="text" class="form-control" v-if="
                              nursing.family[index].family_history === 'Other'
                            " v-model="nursing.family[index].family_history_other" placeholder="Other" />
                          </div>
                          <div class="flex-2 pl-2">
                            <select class="form-control" :id="`family_history_${index}`"
                              v-model="nursing.family[index].family_relation">
                              <option value selected></option>
                              <option value="Other">Other</option>
                              <option :value="dd.family_relation" v-for="(dd, index) in dd_family_relation"
                                :key="index">
                                {{ dd.family_relation }}</option>
                            </select>
                            <div>
                              <input type="text" :id="`family_relation_other_${index}`" class="form-control"
                                placeholder="Other" v-model="nursing.family[index].family_relation_other"
                                v-if="nursing.family[index].family_relation === 'Other'" />
                            </div>
                          </div>
                          <div class="pl-2">
                            <button type="button" class="btn btn-primary" @click="addRowList('nursing', 'family', 1)">
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                          <div class="pl-2">
                            <button type="button" class="btn btn-secondary"
                              @click="removeRowList('nursing', 'family', index)">
                              <i class="fa fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-2">
                      <div>
                        <label>Social</label>
                      </div>
                    </div>
                    <div class="col">
                      <label class="label">Smoking</label>
                    </div>
                    <div class="col d-flex">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="is_smoking" id="is_smoking_no" value="no"
                          v-model="nursing.is_smoking" />
                        <label class="form-check-label" for="is_smoking_no">No</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="is_smoking" id="is_smoking_yes" value="yes"
                          v-model="nursing.is_smoking" />
                        <label class="form-check-label" for="is_smoking_yes">Yes</label>
                      </div>
                    </div>
                    <div class="col">
                      <input class="form-control" type="text" v-model="nursing.smoking_value" />
                    </div>
                    <div class="col">
                      <select id="allergen" class="form-control" v-model="nursing.smoking_unit"
                        style="min-width: 150px;">
                        <option value selected></option>
                        <option :value="dd" v-for="dd in smokingUnit" :key="dd.id">{{ dd }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-2"></div>
                    <div class="col">
                      <label class="label">Alcohol</label>
                    </div>
                    <div class="col d-flex">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="is_alcohol" id="is_alcohol_no" value="no"
                          v-model="nursing.is_alcohol" />
                        <label class="form-check-label" for="is_alcohol_no">No</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="is_alcohol" id="is_alcohol_yes" value="yes"
                          v-model="nursing.is_alcohol" />
                        <label class="form-check-label" for="is_alcohol_yes">Yes</label>
                      </div>
                    </div>
                    <div class="col">
                      <input class="form-control" type="text" v-model="nursing.alcohol_value" />
                    </div>
                    <div class="col">
                      <select id="allergen" class="form-control" v-model="nursing.alcohol_unit"
                        style="min-width: 150px;">
                        <option value selected></option>
                        <option :value="dd" v-for="dd in alcoholUnit" :key="dd.id">{{ dd }}</option>
                      </select>
                    </div>
                  </div>

                </div>
                <div class="col-6">
                  <div class="row">

                    <div class="label-b">
                      <i class="fas fa-user-nurse"></i> Pregnancy
                    </div>
                    <div class="d-flex mt-2">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="is_pregnancy" id="pregnancy-status-na"
                          value="na" v-model="nursing.is_pregnancy" />
                        <label class="form-check-label" for="pregnancy-status-na">N/A</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="is_pregnancy" id="pregnancy-status-no"
                          value="no" v-model="nursing.is_pregnancy" />
                        <label class="form-check-label" for="pregnancy-status-no">No</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="is_pregnancy" id="pregnancy-status-yes"
                          value="yes" v-model="nursing.is_pregnancy" />
                        <label class="form-check-label" for="pregnancy-status-yes">Yes</label>
                      </div>
                    </div>
                    <div class="d-flex mt-2">
                      <div class="d-flex" v-if="nursing.is_pregnancy === 'yes'">
                        <div>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text p-1 bg-white label">G</span>
                            </div>
                            <input type="text" class="form-control p-1" v-model="nursing.pregnancy_g" />
                          </div>
                        </div>
                        <div class="pl-2">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text p-1 bg-white label">P</span>
                            </div>
                            <input type="text" class="form-control p-1" v-model="nursing.pregnancy_p" />
                          </div>
                        </div>
                        <div class="pl-2">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text p-1 bg-white label">A</span>
                            </div>
                            <input type="text" class="form-control p-1" v-model="nursing.pregnancy_a" />
                          </div>
                        </div>
                        <div class="pl-2">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text p-1 label bg-white">GA</span>
                            </div>
                            <input type="text" class="form-control" v-model="nursing.pregnancy_ga_wk" />
                            <div class="input-group-append">
                              <span class="input-group-text p-1 label bg-white">wk</span>
                            </div>
                            <input type="text" class="form-control border-left-0" v-model="nursing.pregnancy_ga_day" />
                            <div class="input-group-append">
                              <span class="input-group-text p-1 label bg-white">day</span>
                            </div>
                          </div>
                        </div>
                        <div class="pl-2">
                          <div class="input-group" style="min-width: 130px;">
                            <div class="input-group-prepend">
                              <span class="input-group-text p-1 bg-white label">By</span>
                            </div>
                            <select class="form-control" v-model="nursing.pregnancy_by">
                              <option value selected></option>
                              <option value="Ultrasound">Ultrasound</option>
                              <option value="LMP">LMP</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex mt-2">
                      <div>
                        <div class="input-group" style="min-width: 270px;">
                          <div class="input-group-prepend">
                            <span class="input-group-text p-1 bg-white label">LMP</span>
                          </div>
                          <flat-pickr class="form-control" :config="$store.state.stdDateConfig"
                            style="background-color: #fff;" v-model="nursing.pregnancy_lmp"></flat-pickr>
                        </div>
                      </div>
                      <div class="pl-2">
                        <div class="input-group" style="min-width: 320px;">
                          <div class="input-group-prepend">
                            <span class="input-group-text p-1 bg-white label">Contraception</span>
                          </div>
                          <select class="form-control" v-model="nursing.pregancy_contraception">
                            <option value selected></option>
                            <option :value="dd.name" v-for="(dd, index) in $store.state
                              .dd_type_contraception" :key="index">{{ dd.name }}</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                        <div class="mt-1" v-if="nursing.pregancy_contraception === 'Other'">
                          <input type="text" class="form-control" placeholder="Other"
                            v-model="nursing.pregancy_contraception_other_input" />
                        </div>
                      </div>

                    </div>

                  </div>
                  <div class="row">
                    <div class="d-flex mt-2 justify-content-start w-100">

                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label">Painscore</span>
                        </div>
                        <select id="pain_score" class="form-control non-arrow" v-model="nursing.pain_score">
                          <option value disabled selected hidden></option>
                          <option value="0" selected>0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>

                      <div class="input-group pl-2">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label">Location</span>
                        </div>
                        <input type="text" class="form-control p-1" v-model="nursing.pain_location" />
                      </div>

                      <div class="input-group pl-2">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label">Duration</span>
                        </div>
                        <input type="text" class="form-control p-1" v-model="nursing.duration" />
                      </div>

                    </div>
                  </div>

                  <div class="row">
                    <div class="d-flex mt-2 align-items-center justify-content-between w-100">
                      <div class="input-group-prepend">
                        <span class="input-group-text p-1 bg-white label">Frequency</span>
                      </div>
                      <div class="d-flex justify-content-around w-100">
                        <div class="d-flex align-items-center">
                          <input id="Continuous" type="radio" value="Continuous" v-model="nursing.frequency">
                          <label for="Continuous" class="ml-2">Continuous</label>
                        </div>
                        <div class="d-flex align-items-center">
                          <input id="Intermittent" type="radio" value="Intermittent" v-model="nursing.frequency">
                          <label for="Intermittent" class="ml-2">Intermittent</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2 ">
                    <div class="row w-100">
                      <div class="input-group-prepend col-4">
                        <span class="input-group-text p-1 bg-white label">Assessment tool</span>
                      </div>
                      <div class="col-4">
                        <input id="nips" type="radio" value="NIPS (0-3 mo)" v-model="nursing.assessmenttool">
                        <label for="nips" class="ml-2">NIPS (0-3 mo)</label>
                      </div>
                      <div class="col-4">
                        <input id="flacc" type="radio" value="FLACC (3 mos-4yr)" v-model="nursing.assessmenttool">
                        <label for="flacc" class="ml-2">FLACC (3 mos-4yr)</label>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="row w-100">
                      <div class="col-4">
                        <input id="faces" type="radio" value="FACES (4-8 yrs)" v-model="nursing.assessmenttool">
                        <label for="faces" class="ml-2">FACES (4-8 yrs)</label>
                      </div>
                      <div class="col-4">
                        <input id="nrs" type="radio" value="NRS (>8 yr)" v-model="nursing.assessmenttool">
                        <label for="nrs" class="ml-2">NRS (>8 yr)</label>
                      </div>
                      <div class="col-4">
                        <input id="pps" type="radio" value="PPS (Cognitive, Impaired)" v-model="nursing.assessmenttool">
                        <label for="pps" class="ml-2">PPS (Cognitive, Impaired)</label>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="pd10">
                      <i class="input-icon-red fa fa-exclamation-triangle"></i>
                      <label class="label-b" style="margin-left: 10px;">Allergies ({{ cntAllergy() }})</label>
                      <div class="d-flex mt-2">
                        <div style="width: 120px;">
                          <label>Drug Allergy</label>
                        </div>
                        <div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="is_drug_allergy" id="drug_allergy_yes"
                              value="yes" v-model="nursing.is_drug_allergy" />
                            <label class="form-check-label" for="drug_allergy_yes">Yes</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="is_drug_allergy" id="drug_allergy_no"
                              value="no" v-model="nursing.is_drug_allergy" />
                            <label class="form-check-label" for="drug_allergy_no">No</label>
                          </div>
                        </div>
                      </div>
                      <table class="table table-bordered text-center" style="margin-top: 15px;"
                        v-if="nursing.is_drug_allergy === 'yes'">
                        <thead style="background-color: #bfbfbf">
                          <tr>
                            <th width="45%" scope="col">Allergen</th>
                            <th width="45%" scope="col">Reaction</th>
                            <th width="5%" scope="col">
                              <button type="button" class="btn btn-primary"
                                @click="addRowList('nursing', 'drug_allergy', 1)">
                                <i class="fa fa-plus"></i>
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody style="background-color: white;">
                          <tr v-for="(i, index) in nursing.drug_allergy" :key="index">
                            <td>
                              <select :id="`allergen_${index}`" class="form-control"
                                v-model="nursing.drug_allergy[index].name">
                                <option value selected></option>
                                <option :value="dd.name" v-for="dd in $store.state.allergies" :key="dd.id">{{ dd.name }}
                                </option>
                              </select>
                            </td>
                            <td>
                              <MazSelect v-model="nursing.drug_allergy[index].reaction" :placeholder="'Select Reaction'"
                                :options="reactions.map(item => {
                                  return {
                                    label: item,
                                    value: item
                                  };
                                })
                                  " />
                              <MazInput class="mt-2" placeholder="Enter Reaction"
                                v-if="nursing.drug_allergy[index].reaction === 'Other...'"
                                v-model="nursing.drug_allergy[index].reaction_other" />
                            </td>
                            <td>
                              <button type="button" class="btn btn-secondary"
                                @click="removeRowList('nursing', 'drug_allergy', index)">
                                <i class="fa fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <hr />
                      <div class="d-flex mt-4">
                        <div style="width: 120px;">
                          <label>Other</label>
                        </div>
                        <div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="is_other_allergy"
                              id="drug_allergy_other_yes" value="yes" v-model="nursing.is_other_allergy" />
                            <label class="form-check-label" for="drug_allergy_other_yes">Yes</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="is_other_allergy"
                              id="drug_allergy_other_no" value="no" v-model="nursing.is_other_allergy" />
                            <label class="form-check-label" for="drug_allergy_other_no">No</label>
                          </div>
                        </div>
                      </div>
                      <table class="table table-bordered text-center" style="margin-top: 15px;"
                        v-if="nursing.is_other_allergy === 'yes'">
                        <thead style="background-color: #bfbfbf;">
                          <tr>
                            <th width="45%" scope="col">Allergen</th>
                            <th width="45%" scope="col">Reaction</th>
                            <th width="5%" scope="col">
                              <button type="button" class="btn btn-primary"
                                @click="addRowList('nursing', 'other_allergy', 1)">
                                <i class="fa fa-plus"></i>
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody style="background-color: white;">
                          <tr v-for="(i, index) in nursing.other_allergy" :key="index">
                            <td>
                              <select :id="`other_allergen_${index}`" class="form-control"
                                v-model="nursing.other_allergy[index].other_allergen">
                                <option value selected></option>
                                <option value="Other">Other</option>
                                <optgroup label="Food">
                                  <option :value="dd.name" v-for="(dd, index) in dd_allergy_food" :key="index">{{
                                    dd.name }}
                                  </option>
                                </optgroup>
                                <optgroup label="Environmental">
                                  <option :value="dd.name" v-for="(dd, index) in dd_allergy_envi" :key="index">{{
                                    dd.name }}
                                  </option>
                                </optgroup>
                                <optgroup label="Contact">
                                  <option :value="dd.name" v-for="(dd, index) in dd_allergy_contact" :key="index">{{
                                    dd.name
                                  }}
                                  </option>
                                </optgroup>
                              </select>
                              <input v-if="
                                nursing.other_allergy[index].other_allergen ===
                                'Other'
                              " type="text" class="form-control mt-1" placeholder="Type other allergen"
                                v-model="nursing.other_allergy[index].other_allergy_pop" />
                            </td>
                            <td>
                              <MazSelect v-model="nursing.other_allergy[index].other_reaction"
                                :placeholder="'Select Reaction'" :options="other_reactions.map(item => {
                                  return {
                                    label: item,
                                    value: item
                                  };
                                })
                                  " />
                              <MazInput class="mt-2" placeholder="Enter Reaction"
                                v-if="nursing.other_allergy[index].other_reaction === 'Other...'"
                                v-model="nursing.other_allergy[index].other_reaction_other" />
                            </td>
                            <td>
                              <button type="button" class="btn btn-secondary" @click="
                                removeRowList('nursing', 'other_allergy', index)
                                ">
                                <i class="fa fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div>
                    <label>Current Medication</label>
                    <div class="d-flex mt-1" v-for="(i, index) in nursing.current_medication" :key="index">
                      <div style="min-width: 350px;">
                        <select id="medication" class="form-control"
                          v-model="nursing.current_medication[index].medication">
                          <option value selected></option>
                          <option value="Aspirin">Aspirin</option>
                          <option value="Clonidine">Clonidine</option>
                          <option value="Metformin">Metformin</option>
                          <option value="Rifampicin">Rifampicin</option>
                          <option value="Isoniazid">Isoniazid</option>
                          <option value="Diazepam - Valium">Diazepam - Valiums</option>
                          <option value="Paracetamol">Paracetamol</option>
                          <option value="Metoprolol">Metoprolol</option>
                          <option value="Domperidone">Domperidone</option>
                          <option value="Amoxycillin">Amoxycillin</option>
                          <option value="Buscopan">Buscopan</option>
                          <option value="TS6">TS6</option>
                          <option value="ORS">ORS</option>
                          <option value="IBUPROFEN">IBUPROFEN</option>
                          <option value="AZITHROMYCIN">AZITHROMYCIN</option>
                          <option value="TRAMADOL">TRAMADOL</option>
                          <option value="Salol et Menthol Mixture">Salol et Menthol Mixture</option>
                        </select>
                      </div>
                      <div class="pl-2">
                        <select id="current-med" class="form-control"
                          v-model="nursing.current_medication[index].medication_unit">
                          <option value disabled selected hidden></option>
                          <option>tablet</option>
                          <option>unit</option>
                          <option>cc</option>
                        </select>
                      </div>
                      <div class="pl-2">
                        <button type="button" class="btn btn-primary"
                          @click="addRowList('nursing', 'current_medication', 1)">
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                      <div class="pl-2">
                        <button type="button" class="btn btn-secondary" @click="
                          removeRowList('nursing', 'current_medication', index)
                          ">
                          <i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div class="bg-general">
            <div class="pd10">
              <div class="label-b">
                <i class="fas fa-first-aid"></i> Chief Complaint
              </div>
              <div class="d-flex mt-3" v-for="(i, index) in nursing.cc" :key="index">
                <div>
                  <div class="label">Duration before arrival</div>
                  <input type="text" class="form-control" v-model="nursing.cc[index].cc_duration_times" />
                </div>
                <div class="ml-2">
                  <div class="label">Unit</div>
                  <select id="rabies" class="form-control" v-model="nursing.cc[index].cc_duration_unit">
                    <option value selected></option>
                    <option :value="dd.name" v-for="(dd, index) in dd_duration_unit" :key="index">{{ dd.name }}
                    </option>
                  </select>
                </div>

                <div class="ml-2">
                  <div class="label">Select Chief Complaint</div>
                  <select class="form-control" id="chief-complaint" v-model="nursing.cc[index].cc_name">
                    <option value selected></option>
                    <option value="other">Other</option>
                    <optgroup label="General">
                      <option :value="dd.name" v-for="(dd, index) in $store.state.dd_cc_general" :key="index">{{
                        dd.name
                      }}</option>
                    </optgroup>
                    <optgroup label="Cardiovascular">
                      <option :value="dd.name" v-for="(dd, index) in $store.state.dd_cc_cardio" :key="index">{{
                        dd.name
                      }}</option>
                    </optgroup>
                    <optgroup label="Gastrointestinal">
                      <option :value="dd.name" v-for="(dd, index) in $store.state.dd_cc_gastro" :key="index">{{
                        dd.name
                      }}</option>
                    </optgroup>
                    <optgroup label="Integumentary">
                      <option :value="dd.name" v-for="(dd, index) in $store.state.dd_cc_integu" :key="index">{{
                        dd.name
                      }}</option>
                    </optgroup>
                    <optgroup label="Neurological">
                      <option :value="dd.name" v-for="(dd, index) in $store.state.dd_cc_neuro" :key="index">{{ dd.name
                        }}</option>
                    </optgroup>
                    <optgroup label="Obstetric / Gynaecological">
                      <option :value="dd.name" v-for="(dd, index) in $store.state.dd_cc_obstetric" :key="index">{{
                        dd.name }}</option>
                    </optgroup>
                    <optgroup label="Ocular">
                      <option :value="dd.name" v-for="(dd, index) in $store.state.dd_cc_ocular" :key="index">{{
                        dd.name
                      }}</option>
                    </optgroup>
                    <optgroup label="Psychiatric">
                      <option :value="dd.name" v-for="(dd, index) in $store.state.dd_cc_phyc" :key="index">{{ dd.name
                        }}
                      </option>
                    </optgroup>
                    <optgroup label="Pulmonary">
                      <option :value="dd.name" v-for="(dd, index) in $store.state.dd_cc_pulm" :key="index">{{ dd.name
                        }}
                      </option>
                    </optgroup>
                    <optgroup label="Rheumatologic">
                      <option :value="dd.name" v-for="(dd, index) in $store.state.dd_cc_rheu" :key="index">{{ dd.name
                        }}
                      </option>
                    </optgroup>
                    <optgroup label="Urologic">
                      <option :value="dd.name" v-for="(dd, index) in $store.state.dd_cc_urol" :key="index">{{ dd.name
                        }}
                      </option>
                    </optgroup>
                  </select>
                  <div class="" v-if="nursing.cc[index].cc_name === 'other'">
                    <input type="text" :id="`cc_name_other_${index}`" class="form-control" placeholder="Other"
                      v-model="nursing.cc[index].cc_name_other" />
                  </div>
                </div>
                <div class="ml-2">
                  <div class="label">Notes</div>
                  <input type="text" class="form-control" v-model="nursing.cc[index].cc_note" />
                </div>
                <div class="ml-2 mt-1">
                  <button style="margin-top: 18px;" type="button" class="btn btn-primary"
                    @click="addRowList('nursing', 'cc', 1)">
                    <i class="fa fa-plus"></i>
                  </button>
                  <button style="margin-top: 18px;" type="button" class="btn btn-secondary ml-2"
                    @click="removeRowList('nursing', 'cc', index)">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
              <div class="d-flex mt-3">
                <div>
                  <div class="label-b">Affected part</div>

                </div>
                <div class="pl-2 pt-2">
                  <div class="row mt-2">
                    <body-part v-model="nursing.body_part_image" :id="'nurse_bodypart'"
                      :onSelectImage="onSelectImage"></body-part>

                  </div>
                  <div class="row mt-2 ml-2">
                    <div class="col">
                      <image-drawer ref="nurse_drawer"></image-drawer>
                    </div>
                    <div class="col ">
                      <img :src="nursing.body_part_image_url" style="margin-top: 120px;">
                    </div>



                  </div>
                </div>
              </div>
              <!-- <div class="row mt-3">
                <div
                  class="col-xl-5 ml-2 mt-2"
                  v-for="(i, index) in nursing.body_drawing_urls"
                  :key="index"
                >
                  <div class="body-box text-center">
                    <div>
                      <VueSignaturePad
                        :id="`body_drawing_${index}`"
                        class="body-drawing"
                        width="500px"
                        height="500px"
                        ref="body_drawing"
                        :options="{ penColor, onEnd: onDrawEnd(index) }"
                      />
                    </div>
                    <div class="text-center mt-2">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        @click="clear(index)"
                      >
                        <i class="fa fa-times"></i> Clear
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary ml-1"
                        @click="penColor = 'red'"
                      >
                        Red pen
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary ml-1"
                        @click="penColor = 'black'"
                      >
                        Black pen
                      </button>

                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-xl-4 col-sm-12 mt-4"
                  v-for="(file, index) in nursing.organ_pic_files"
                  :key="index"
                >
                  <div class="show-img-box">
                    <a :href="file.url" target="_blank"
                      ><img class="img-preview" :src="file.url" id="show-btn"
                    /></a>
                  </div>
                  <div class="text-center mt-2">
                    <a :href="file.url" target="_blank"
                      ><i class="far fa-file"></i> {{ file.name }}</a
                    >
                    <button
                      class="btn btn-danger ml-2"
                      @click.prevent="nursing.organ_pic_files.splice(index, 1)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div> -->
              <!-- <div class="mt-5">
                <div>
                  <span class="label-b">
                    <i class="fas fa-cloud-upload-alt"></i> Upload Files
                  </span>
                </div>
                <div class="row mt-2" v-for="(file, index) in nursing.files" :key="index">
                  <div class="col-2">
                    <a :href="file.url" target="_blank"><i class="far fa-file"></i> {{ file.name }}</a>
                  </div>
                  <div class="col-2">
                    <select class="form-control mr-2" v-model="nursing.files[index].type">
                      <option value="document">Document</option>
                      <option value="image">Image</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div class="col-2" v-if="nursing.files[index].type === 'other'">
                    <input type="text" class="form-control" v-model="nursing.files[index].other_type"
                      placeholder="input file type">
                  </div>
                  <div class="col-3">
                    <input type="text" class="form-control mr-2" v-model="nursing.files[index].name"
                      placeholder="file name" />
                  </div>
                  <div class="col-3">
                    <div class="d-flex">
                      <button class="btn btn-danger mr-2" @click.prevent="nursing.files.splice(index, 1)">
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <input type="file" hidden ref="file_input" @input="handleFile" />
                    <button class="btn btn-primary" @click.prevent="$refs.file_input.click()">
                      <i class="fas fa-plus"></i> Add file
                    </button>
                  </div>
                </div>
              </div> -->
              <div class="row">
                <div class="col-xl-4 offset-xl-8">
                  <input v-model="nursing.rn_name" />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="text-center">
            <button type="button" class="btn btn-primary btn-lg" @click="saveNursingAssessment" style="width: 150px;">
              <i class="fas fa-save"></i> Save
            </button>

            <router-link :to="{
              name: 'Print Medical Certificate',
              params: { id: $route.params.visitId }
            }" target="_blank" class="btn btn-lg btn-gray" style="margin-left: 10px; width: 150px;">
              <i class="fas fa-print"></i> Print
            </router-link>

            <button style="margin-left: 10px; width: 150px;" type="button" class="btn btn-lg btn-danger">
              <i class="fas fa-times"></i> Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import nurseAssessmentMixins from "../../../mixins/nurse-assessment";
import vitalSignMixins from "../../../mixins/vital-sign";
import moment from "moment"
const smokingUnit = ["Pack/day", "Pack/week", "stick/day", "stick/week"];
const alcoholUnit = [
  "shot/day",
  "shot/week",
  "shot/month",
  "glass/day",
  "glass/week",
  "glass/month",
  "Bottle/day",
  "Bottle/week",
  "Bottles/month"
];
const reactions = [
  'Other...',
  'Acute generalized exanthematous pustulosis(AGEP)',
  'Anaphylaxis',
  'Anticoagulant-induced skin necrosis',
  'Drug rash with eosinophilia and systemic symptoms(DRESS)/Drug-induced hypersensitivity syndrome',
  'Erythema multiforme',
  'Generalized bullous fixed drug eruption',
  'Stevens-Johnson syndrome and toxic epidermal necrolysis'
]

const other_reactions = [
  'Other...',
  'Acneiform/Folliculitis',
  'Allergic Contact Dermatitis',
  'Angioedema',
  'Bullous drug eruption',
  'Chemotherapy-induced acral erythema',
  'Cutaneous lymphoid hyperplasia(pseudolymphoma)',
  'Dermatomyositis-like eruption',
  'Drug-induced alopecia',
  'Drug-induced Lupus erythematosus(systemic and cutaneous)',
  'Drug-induced subacute cutaneous lupus erythematosus',
  'Drug-induced thrombocytopenia',
  'Erythroderma',
  'Exfoliative dermatitis',
  'Exfoliative erythroderma',
  'Fixed drug eruption',
  'Gingival enlargement',
  `Goodpasture's`,
  'Granulomatous (interstitial)',
  'Hemolytic anemia',
  'Henoch-Schonlein Purpura',
  'Hyper- and hypohidrosis',
  'Hyperpigmentation and dyschromatosis',
  'Hypertrichosis and hirsutism',
  'Hypopigmentation(skin and hair)',
  'Internal-external reactions',
  'Irritant Contact Dermatitis',
  'Lichenoid drug reactions',
  'Linear IgA bullous pemphigoid',
  'Maculopapular exanthema',
  'Morbilliform',
  'Mucosal hyperpigmentation',
  'Nail abnormalities',
  'Papulosquamous',
  'Pemphigus and bullous pemphigoid',
  'Photosensitive reaction/Phototoxic/photoallergic',
  'Pseudoallergy',
  'Pseudoporphyria',
  'Psoriasiform',
  'Pustular eruptions',
  'Serum Sickness',
  'Serum Sickness-like Reaction',
  'Urticaria',
  'Vasculitis'
]
export default {
  props: {
    patient: {
      default: () => {
        return {};
      }
    }
  },
  mixins: [nurseAssessmentMixins, vitalSignMixins],
  data() {
    return {
      penColor: "black",
      is_edit: true,
      smokingUnit: smokingUnit,
      alcoholUnit: alcoholUnit,
      stdTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        defaultDate: new Date()
      },
      reactions,
      other_reactions,

    };
  },
  methods: {
    onDrawEnd(index) {
      if (this.$refs.body_drawing && this.$refs.body_drawing[index]) {
        const { data } = this.$refs.body_drawing[index].saveSignature();
        this.bodyDrawingFile[index] = data;
      }
    },
    removeDraw(index) {
      this.nursing.body_drawing_urls.splice(index, 1);
      delete this.bodyDrawingFile[index];
    },
    undo() {
      this.$refs.bodyDrawing.undoSignature();
    },
    clear(index) {
      this.$refs.body_drawing[index].clearSignature();
    },
    cntAllergy() {
      return (
        this.nursing.drug_allergy.length + this.nursing.other_allergy.length
      );
    },
    onSelectImage(image) {
      if (image) {
        this.$refs['nurse_drawer'].setImage(image.url)
      }
    },
    formatDate(data) {
      return moment(data.toDate()).format("DD/MM/YYYY")
    }
  }
};
</script>
<style lang="scss" scoped>
.logo-box {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}

.vital-red {
  background-color: #dc0000ff;
  color: #fff;
}

.vital-mid-red {
  background-color: #ea9999;
}

.vital-soft-red {
  background-color: #f4cccc;
}

.body-box {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  // margin: auto;
  width: 545px;
  height: 600px;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.body-drawing {
  background-image: url("/images/Body-chart.png");
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-repeat: no-repeat;
  background-position: center;
  // width: 700px;
  // height: 700px;
}

.center {
  text-align: center;
}

.pd10 {
  padding: 10px;
}

.label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.input-icon-red {
  color: rgb(214, 38, 38);
}

.label-b {
  color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}

.esi-lv {
  border: 3px solid rgba(0, 0, 0, 0.3);
  width: 40px;
  height: 40px;
  margin-top: 10px;
  border-radius: 7px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  margin-left: 10px;
}

.esi-show {
  border: 2px solid rgba(0, 0, 0, 0.3);
  width: 40px;
  height: 40px;
  border-radius: 7px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
}

.form-check {
  margin-left: 10px;
}

input[type="file"] {
  width: 600px;
}

input[type="radio"] {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.non-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.show-img-box {
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  height: 370px;
  /* minimum height */
  width: 100%;
  position: relative;
  background-color: #fff;
  cursor: pointer;
  border-radius: 0.25em;
}

.img-preview {
  max-height: 370px;
  width: 100%;
  border-radius: 0.25em;
}

.radio-wrapper-23 label {
  background: #fff;
  padding: .75rem 1rem;
  border: 2px solid #e5e5e5;
  border-radius: 0.25rem;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  font-weight: 500;
  position: relative;
  min-width: 140px;
  text-align: left;
  margin-left: 2px;
}

.radio-wrapper-23 input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  border-width: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.radio-wrapper-23 input.radio-1:checked+label {
  box-shadow: #000000 0 0 0 2px;
  color: #fff;
  background: #b30000;
  border-color: transparent;
}

.radio-wrapper-23 input.radio-2:checked+label {
  box-shadow: #000000 0 0 0 2px;
  color: #000000;
  background: #ff8080;
  border-color: transparent;
}

.radio-wrapper-23 input.radio-3:checked+label {
  box-shadow: #000000 0 0 0 2px;
  color: #000000;
  background: #ffcc00;
  border-color: transparent;
}

.radio-wrapper-23 input.radio-4:checked+label {
  box-shadow: #000000 0 0 0 2px;
  color: #000000;
  background: #2eb82e;
  border-color: transparent;
}

.radio-wrapper-23 input.radio-5:checked+label {
  box-shadow: #000000 0 0 0 2px;
  color: #000000;
  background: #fff;
  border-color: transparent;
}
</style>
