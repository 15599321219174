<template>
  <div>
    <cash-payment-graph :payments="payments"></cash-payment-graph>
  </div>
</template>

<script>
export default {
  props: ['payments']
}
</script>