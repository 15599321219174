<template>
  <div class="container-fluid">
    <div>
      <table class="table table-bordered">
        <thead class="bg-gray text-center">
          <tr>
            <th>Date created</th>
            <th>Time</th>
            <th>Report No.</th>
            <th>VN</th>
            <th>Branch</th>
            <th>Dept</th>
            <th>Report type</th>
            <th>Attending Doctor</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>14/01/2020</td>
            <td>24:00</td>
            <td>04-01-2020-000016</td>
            <td>123-456-789</td>
            <td>Hospital</td>
            <td>ER/IPD</td>
            <td>Medical report for work permit</td>
            <td>Dr. Malavee Suknan</td>
          </tr>
          <tr>
            <td>12/03/2018</td>
            <td>24.00</td>
            <td>04-02-2018-001254</td>
            <td>587-965-478</td>
            <td>Hospital</td>
            <td>OPD</td>
            <td>Medical report for work permit</td>
            <td>Dr. Malavee Suknan</td>
          </tr>
        </tbody>
      </table>
      <div class="text-center pb-5">
        <button type="button" class="btn btn-primary btn-lg">
          <i class="fa fa-plus"></i> Create New Fit to fly certificate
        </button>
      </div>
    </div>
    <div class="card shadow">
      <div class="card-body">
        <div class="bg-white">
          <div class="pd10">
            <med-cert-header></med-cert-header>
            <h5 class="text-center label-topic m-3">FIT TO FLY</h5>
            <section>
              <div class="bg-gray text-center box-border p-3 mt-3">
                <span class="label-h">
                  <b>To Whom It May Concern</b>
                </span>
              </div>
              <div class="box-border">
                <p>
                  This is to certify that above named patient has been examined
                  and treated at our hospital as an:
                </p>
                <div class="form-check form-check-inline ml-5">
                  <p>Outpatient</p>
                </div>
                <div class="form-check form-check-inline ml-5">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="outpatient_inpatient"
                    value="outpatient_inpatient"
                  />
                  <label class="form-check-label" for="outpatient_inpatient"
                    >Inpatient</label
                  >
                </div>
                <div class="form-check form-check-inline ml-5">
                  <p>on/during</p>
                </div>
                <div class="form-check-inline ml-5">
                  <flat-pickr
                    class="form-control"
                    :config="stdDateConfig"
                    value
                  ></flat-pickr>
                </div>
                <div class="form-check form-check-inline ml-3">
                  <p>until</p>
                </div>
                <div class="form-check-inline ml-3">
                  <flat-pickr
                    class="form-control"
                    :config="stdDateConfig"
                    value
                  ></flat-pickr>
                </div>
              </div>
            </section>

            <section>
              <div class="bg-gray box-border p-3 text-center">
                <div
                  class="d-flex"
                  style="padding-left: 200px; padding-right: 200px;"
                >
                  <div class="mt-1">
                    DIAGNOSIS
                  </div>
                  <div class="ml-2 flex-fill">
                    <input
                      class="form-control"
                      type="text"
                      id="ftf_diagnosis"
                      placeholder="Auto-link from medical report diagnosis"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div class="box-border">
                <b>Travel recommendation: ( Please tick the box)</b>
                <div class="pl-5">
                  <div class="form-check mt-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="travel_rec"
                      id="travel_rec_nor"
                      value="travel_rec_nor"
                    />
                    <label
                      class="form-check-label ml-2 mt-1"
                      for="travel_rec_nor"
                    >
                      Fit to fly as a normal passenger
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="travel_rec"
                      id="travel_rec_medical"
                      value="travel_rec_medical"
                    />
                    <label
                      class="form-check-label ml-2 mt-1"
                      for="travel_rec_medical"
                    >
                      Fit to fly with medical escort only
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="travel_rec"
                      id="travel_rec_non_medical"
                      value="travel_rec_non_medical"
                    />
                    <label
                      class="form-check-label ml-2 mt-1"
                      for="travel_rec_non_medical"
                    >
                      Fit to fly with non-medical escort only
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="travel_rec"
                      id="travel_rec_not_ftf"
                      value="travel_rec_not_ftf"
                    />
                    <label
                      class="form-check-label ml-2 mt-1"
                      for="travel_rec_not_ftf"
                    >
                      Not fit to fly/ Travel only at patient’s own risk
                    </label>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div class="box-border">
                <b>Special requirement (s) ( Please tick the box)</b>
                <div class="pl-5">
                  <div class="d-flex mt-3">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="special_req_none"
                        value="special_req_none"
                      />
                      <label class="form-check-label" for="special_req_none"
                        >None</label
                      >
                    </div>
                  </div>
                  <div class="d-flex mt-3">
                    <div class="form-check form-check-inline flex-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="special_req_eco"
                        value="special_req_eco"
                      />
                      <label class="form-check-label" for="special_req_eco"
                        >Economy Class</label
                      >
                    </div>
                    <div class="form-check form-check-inline flex-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="special_req_fc"
                        value="special_req_fc"
                      />
                      <label class="form-check-label" for="special_req_fc"
                        >First Class</label
                      >
                    </div>
                    <div class="form-check form-check-inline flex-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="special_req_str"
                        value="special_req_str"
                      />
                      <label class="form-check-label" for="special_req_str"
                        >Stretcher</label
                      >
                    </div>
                  </div>
                  <div class="d-flex mt-3">
                    <div class="form-check form-check-inline flex-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="special_req_oxy"
                        value="special_req_oxy"
                      />
                      <label class="form-check-label" for="special_req_oxy"
                        >Oxygen Supply</label
                      >
                    </div>
                    <div class="form-check form-check-inline flex-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="special_req_oxy_yes"
                        value="special_req_oxy_yes"
                      />
                      <label class="form-check-label" for="special_req_oxy_yes">
                        Yes
                      </label>
                      <div class="input-group mr-5" style="min-width: 70px;">
                        <input
                          type="text"
                          class="form-control border-right-0 ml-2"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text p-1 bg-white label"
                            >lpm</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="form-check form-check-inline flex-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="special_req_oxy_no"
                        value="special_req_oxy_no"
                      />
                      <label class="form-check-label" for="special_req_oxy_no"
                        >No</label
                      >
                    </div>
                  </div>
                  <div class="d-flex mt-3">
                    <div class="form-check form-check-inline flex-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="special_req_oxy_other"
                        value="special_req_oxy_other"
                      />
                      <label
                        class="form-check-label"
                        for="special_req_oxy_other"
                        >Other (Please specify)</label
                      >
                    </div>
                    <div class="flex-4 mr-5">
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div class="box-border">
                <b
                  >I understand the risk involved in air travel and accept full
                  responsibility for myself.</b
                >
                <div class="d-flex mt-3">
                  <div class="flex-even text-center p-3">
                    <input
                      class="input-un text-center"
                      type="text"
                      name=""
                      id=""
                    />
                    <label class="label">Patient's Signature</label>
                  </div>
                  <div class="flex-even text-center p-3">
                    <input
                      class="input-un text-center"
                      type="text"
                      name=""
                      id=""
                      value="John Hasan"
                    />
                    <label class="label">Patient's Full Name</label>
                  </div>
                  <div class="flex-even text-center p-3">
                    <input
                      class="input-un text-center"
                      type="text"
                      name=""
                      id=""
                      value="01/01/2020 13:00"
                    />
                    <label class="label">Date</label>
                  </div>
                </div>
                <div class="d-flex mt-3">
                  <div class="flex-even text-center p-3">
                    <input
                      class="input-un text-center"
                      type="text"
                      name=""
                      id=""
                    />
                    <label class="label">Other legally authorization</label>
                  </div>
                  <div class="flex-even text-center p-3">
                    <input
                      class="input-un text-center"
                      type="text"
                      name=""
                      id=""
                    />
                    <label class="label">ID number/ Passport number</label>
                  </div>
                  <div class="flex-even text-center p-3">
                    <input
                      class="input-un text-center"
                      type="text"
                      name=""
                      id=""
                    />
                    <label class="label">Relationship to the patient</label>
                  </div>
                </div>
                <div class="d-flex mt-3">
                  <div class="flex-even text-center p-3">
                    <input
                      class="input-un text-center"
                      type="text"
                      name=""
                      id=""
                    />
                    <label class="label">Language used in Translation</label>
                  </div>
                  <div class="flex-even text-center p-3">
                    <input
                      class="input-un text-center"
                      type="text"
                      name=""
                      id=""
                    />
                    <label class="label">Witness/ Translator</label>
                  </div>
                  <div class="flex-even text-center p-3">
                    <input
                      class="input-un text-center"
                      type="text"
                      name=""
                      id=""
                    />
                    <label class="label">Witness</label>
                  </div>
                </div>
              </div>
            </section>
            <med-cert-footer></med-cert-footer>

            <hr />
            <div class="text-center">
              <button
                type="button"
                class="btn btn-success btn-lg"
                style="width: 150px;"
              >
                <i class="fas fa-save"></i> Save
              </button>
              <button
                style="margin-left: 10px; width: 150px;"
                type="button"
                class="btn btn-warning btn-lg"
              >
                <i class="fas fa-edit"></i> Edit
              </button>

              <router-link
                :to="{
                  name: 'Print Medical Certificate',
                  params: { id: $route.params.visitId }
                }"
                target="_blank"
                class="btn btn-outline-dark btn-lg"
                style="margin-left: 10px; width: 150px;"
              >
                <i class="fas fa-print"></i> Print
              </router-link>

              <button
                style="margin-left: 10px; width: 150px;"
                type="button"
                class="btn btn-danger btn-lg"
              >
                <i class="fas fa-times"></i> Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // date picker
      stdDateConfig: {
        altInput: true,
        altFormat: "d/m/Y"
      },
      stdDateTimeConfig: {
        altInput: true,
        altFormat: "d/m/Y H:i",
        enableTime: true,
        enableSeconds: false,
        time_24hr: true
      },
      stdTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.container-fluid {
  margin-bottom: 50px;
}

// .box {
//   border: 1px solid #adadad;
// }
// .title-box {
//   padding: 10px;
//   border-bottom: 1px solid #adadad;
//   background-color: #ededed;
// }

// .content-box {
//   padding: 10px;
//   min-height: 50px
// }
.label-topic {
  font-size: 35px;
}

.label-h {
  padding-top: 10px;
  font-size: 25px;
}

.box-border {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
}

input[type="radio"] {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

// input[type=checkbox] {
//   height:20px;
//   width:20px;
//   vertical-align: middle;
// }

.input-un {
  width: 100%;
  background-color: #fcfcfc;
  border: 0;
  border-bottom: 2px solid lightgrey;
  padding: 10px;
}
</style>
