import Dashboard from "../pages/Dashboard/Dashboard.vue";
import PatientQueue from "../pages/Dashboard/PatientQueue.vue";
import Appointments from "../pages/Dashboard/Appointments.vue";
import FinishCase from "../pages/Dashboard/FinishCase.vue";
import AgainstAdvice from "../pages/Dashboard/AgainstAdvice.vue";

import IPDDashboard from "../pages/Dashboard/IPD/Dashboard.vue";
import IPDAdmissionList from "../pages/Dashboard/IPD/AdmissionList.vue"
import IPDNewAdmission from "../pages/Dashboard/IPD/NewAdmission.vue"
import IPDNewOrder from "../pages/Dashboard/IPD/NewOrder.vue"
import IPDMedSheet from "../pages/Dashboard/IPD/MedSheet.vue"
import IPDDischargeList from "../pages/Dashboard/IPD/DischargeList.vue"

export default [
  {
    path: "/dashboard/opd",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard"
    },
    children: [
      {
        path: "",
        redirect: { name: "Patient Queue" }
      },
      {
        path: "patient-queue",
        name: "Patient Queue",
        component: PatientQueue,
        meta: {
          title: "Dashboard"
        }
      },
      {
        path: "appointments",
        name: "Appointments",
        component: Appointments,
        meta: {
          title: "Appointments"
        }
      },
      {
        path: "finish-case",
        name: "Finish Case",
        component: FinishCase,
        meta: {
          title: "Finished cases"
        }
      },
      {
        path: "against-advice",
        name: "Against Advice",
        component: AgainstAdvice,
        meta: {
          title: "Against medical advice"
        }
      }
    ],
  },
  {
    path: "/dashboard/ipd",
    name: "IPD Dashboard",
    component: IPDDashboard,
    meta: {
      title: "IPD Dashboard"
    },
    children: [
      {
        path: "",
        redirect: { name: "IPD Admission List" }
      },
      {
        path: "admission-list",
        name: "IPD Admission List",
        component: IPDAdmissionList,
        meta: {
          title: "Admission List"
        },
      },
      {
        path: "new-admission",
        name: "IPD New Admission",
        component: IPDNewAdmission,
        meta: {
          title: "New Admission"
        },
      },
      {
        path: "new-order",
        name: "IPD New Order",
        component: IPDNewOrder,
        meta: {
          title: "New Order"
        },
      },
      {
        path: "med-sheet",
        name: "IPD Med Sheet",
        component: IPDMedSheet,
        meta: {
          title: "Medsheet"
        },
      },
      {
        path: "discharge-list",
        name: "IPD Discharge List",
        component: IPDDischargeList,
        meta: {
          title: "Discharge List"
        },
      },
    ]
  },
];
