<template>
  <div class="container-fluid">
    <div>
      <button
        class="btn btn-outline-secondary mb-2"
        style="float: right;"
        v-if="hideVN"
        @click.prevent="hideVN = false"
      >
        <span>Show VN/AN</span>
      </button>
      <button
        class="btn btn-outline-secondary mb-2"
        style="float: right;"
        v-if="!hideVN"
        @click.prevent="hideVN = true"
      >
        <span>Hide VN/AN</span>
      </button>
    </div>
    <div class="mt-2">
      <table class="table table-bordered">
        <thead class="text-center bg-gray">
          <tr>
            <th v-if="!hideVN">VN/AN</th>
            <th>Appointment Date</th>
            <th>ICD Code / Diagnosis</th>
            <th>Dept.</th>
            <th>Doctor</th>
            <th width="20%">Note</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-if="!hideVN"><a href=""></a></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr />
    <div class="text-center">
      <button
        type="button"
        class="btn btn-primary btn-lg"
        style="width: 350px;"
      >
        <i class="fa fa-plus"></i> Create New Appointment
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hideVN: true
    };
  }
};
</script>


<style lang="scss" scoped>
.label-b {
  color: rgb(6, 132, 216);
  font-size: 20px;
}

input[type="checkbox"] {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}
</style>
