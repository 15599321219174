<template>
    <div>
        <div class="row mt-2">
            <div class="col-md-4 col-sm-12">
                <vue-good-table :columns="insuranceColumns" :rows="insurance" :sort-options="{ enabled: false, }" />
            </div>
            <div class="col-md-4 col-sm-12">
                <h5 class="text-primary">Insurance Cases</h5>
                <Doughnut :chart-options="chartOptions" :chart-data="caseChart" :dataset-id-key="'case'"
                    :chart-id="'insurance-case'" :height="300" />
            </div>
            <div class="col-md-4 col-sm-12">
                <h5 class="text-primary">Insurance Amount</h5>
                <Doughnut :chart-options="chartOptions" :chart-data="amountChart" :dataset-id-key="'case'"
                    :chart-id="'insurance-case'" :height="300" />
            </div>
        </div>

    </div>
</template>

<script>
import color from '../../assets/color.json'
import { db } from '../../db'
import {
    Doughnut,
    Bar
} from 'vue-chartjs/legacy'





export default {
    props: ['payments', 'invoices'],
    components: {
        Doughnut
    },
    data() {
        return {
            insuranceColumns: [
                {
                    label: "Insurance",
                    field: "insurance_name",
                },
                {
                    label: "# of case",
                    field: "thenumberofcase",
                    thClass: 'text-right',
                    tdClass: 'text-right',
                },
                {
                    label: "Amount",
                    field: "insurance",
                    thClass: 'text-right',
                    tdClass: 'text-right',
                },
                {
                    label: "Composition",
                    type: "percentage",
                    field: "composition"
                }
            ],

            chartOptions: {
                maintainAspectRatio: false,
                maxHeight: 300,
                plugins: {
                    datalabels: {
                        color: '#000',
                        anchor: 'center',
                        align: 'center',
                        formatter: (value, context) => {
                            const dataset = context.dataset;
                            const total = dataset.data.reduce((sum, currentValue) => sum + currentValue, 0);
                            const percentage = ((value / total) * 100).toFixed(2);
                            return `${value.toLocaleString()} \n(${Math.round(percentage)}%)`;
                        }
                    },
                }
            },
            barChartOptions: {
                maintainAspectRatio: false,
                maxHeight: 300,
                plugins: {
                    datalabels: {
                        color: '#000',
                        anchor: 'center',
                        align: 'center',
                        formatter: (value, context) => {
                            const dataset = context.dataset;
                            const total = dataset.data.reduce((sum, currentValue) => sum + currentValue, 0);
                            const percentage = ((value / total) * 100).toFixed(2);
                            return `${value.toLocaleString()} \n(${Math.round(percentage)}%)`;
                        }
                    },
                }
            },
            preparedPayments: [],
            predefinedColors: ['#8BC1F7', '#BDE2B9', '#A2D9D9',  '#B2B0EA', '#F9E0A2' , '#F4B678' , '#C9190B' , '#F0F0F0'],
        }
    },
    methods: {
        pallete(length) {
            return Array.from({ length }, (_, i) => this.predefinedColors[i % this.predefinedColors.length])
        },
        reversepallete(length) {        
            const reversedColors = [...this.predefinedColors].reverse()          
            return Array.from({ length }, (_, i) => reversedColors[i % reversedColors.length])
        }

    },
    watch: {
        payments: {
            immediate: true,
            async handler() {
                let loader = this.$loading.show({})

                this.preparedPayments = await Promise.all(this.payments.map(async (item) => {
                    let p = await db.collection('Patient').doc(item.HN).get()
                    if (p.data().is_resident) {
                        item.p_type = 'local'
                    } else {
                        item.p_type = 'inter'
                    }

                    let i = await db.collection('Invoice').doc(item.invoice_id).get()
                    if (i.data().type === 'OPD') {
                        item.i_type = 'OPD'
                    } else if (i.data().type === 'IPD') {
                        item.i_type = 'IPD'
                    } else {
                        item.i_type = 'Other'
                    }

                    return item
                }))

                loader.hide()
            },
        }
    },
    computed: {
        insurance() {
            let data = []            
           
            for (let item of this.preparedPayments) {

                if (item.insurance > 0) {
                    let ref_data = {}
                    ref_data.insurance_name = item.insurance_name === 'Other' ? (item.insurance_name_other === '' ? 'Other' : item.insurance_name_other) : item.insurance_name                    
                    ref_data.insurance = item.insurance                                     
                    data.push(ref_data)
                }
            }           
            
            const summedData = _.chain(data)
                .groupBy("insurance_name")
                .map((item, name) => ({
                    insurance_name: name,
                    thenumberofcase: item.length,
                    insurance: _.sumBy(item, "insurance"),
                })).value()
            
            const totalSum = _.sumBy(summedData, "insurance")

            data = summedData.map(item => ({
                insurance_name: item.insurance_name,
                thenumberofcase: item.thenumberofcase,
                insurance: item.insurance,
                composition: ((item.insurance / totalSum)).toFixed(2)
            }))

            data.sort((a, b) => {
                if (a.composition < b.composition) return 1
                if (a.composition > b.composition) return -1
            })
            return data


        },

        caseChart() {

            let chartData = {
                labels: this.insurance.map(d => d.insurance_name),
                datasets: [{
                    label: 'Insurance Distribution',
                    data: this.insurance.map(d => d.thenumberofcase),
                    backgroundColor: this.pallete(this.insurance.length),
                }]
            }

            return chartData
        },

        amountChart() {

            let chartData = {
                labels: this.insurance.map(d => d.insurance_name),
                datasets: [{
                    label: 'Insurance Distribution',
                    data: this.insurance.map(d => d.insurance),
                    backgroundColor: this.reversepallete(this.insurance.length),
                }]
            }

            return chartData
        },


    }
}
</script>