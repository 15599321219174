<template>
  <div class="row mt-2">
    <div class="col-md-6 col-sm-12">
      <table class="table table-bordered table-striped">
        <thead>
          <th colspan="4" class="text-center">Other Payment Gateway</th>
        </thead>
        <thead>
          <th>Name</th>
          <th>Payment Type</th>
          <th>Time</th>
          <th class="text-right">Amount</th>
        </thead>
        <tbody>
          <tr v-for="(item, index) in _.filter(this.payments, p => p.other>0)" :key="'inter'+index">
            <td><a :href="`/print/receipt/${item.payment_id}`" target="_blank" rel="noopener noreferrer">{{item.patient_name}}</a></td>
            <td>{{item.other_name}}</td>
            <td>{{item.issue_date.toDate() | moment('DD/MM/YYYY HH:mm a')}}</td>
            <td class="text-right">{{item.other.toLocaleString()}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-6 col-sm-12">
      <h5 class="text-primary">Other Payment Gateway</h5>
      <Bar  :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'" :chart-id="'other-graph'"
        :width="400" :height="400" />
    </div>
  </div>
</template>

<script>
import color from '../../assets/color.json'
import {
  Bar 
} from 'vue-chartjs/legacy'

export default {
  props: ['payments'],
  components: {
    Bar 
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        indexAxis: 'x',
        plugins: {
          datalabels: {
            color: '#000',
            anchor: 'end',
            align: 'top',
            formatter: value => {
              return value.toLocaleString()
            }
          },
        }
      },
      preparedPayments: []
    }
  },
  computed: {
    chartData() {
      let data = []

      for (let item of this.payments) {
        if(item.other > 0) {
          let findIndex = this._.findIndex(data, {other_name: item.other_name})
          if(findIndex>-1) {
            data[findIndex].amount += item.other
          }else{
            data.push({
              other_name: item.other_name,
              amount: 0+item.other,
              index: data.length
            })
          }
        }
      }

      let chartData = {
        labels: data.map(d => d.other_name),
        datasets: [{
          label: 'Other',
          data: data.map(d => d.amount),
          backgroundColor: data.map(d => color[d.index+2])
        }]
      }

      return chartData
    }
  }
}
</script>