import Service from "../pages/Service/";


export default [
  {
    path: "/service",
    name: "Service",
    component: Service
  },

]