var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"card shadow"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"bg-white"},[_c('div',{staticClass:"pd10"},[_c('med-cert-header'),_c('h5',{staticClass:"text-center label-topic m-3"},[_vm._v(" MEDICAL CERTIFICATE FOR INSURANCE ")]),_c('div',{staticClass:"box-border"},[_c('div',{staticClass:"d-flex"},[_vm._m(2),_c('div',{staticClass:"ml-3"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.stdDateConfig},model:{value:(_vm.medcert_for_insurance.admitted_date),callback:function ($$v) {_vm.$set(_vm.medcert_for_insurance, "admitted_date", $$v)},expression:"medcert_for_insurance.admitted_date"}})],1)]),_c('div',{staticClass:"d-flex mt-3"},[_vm._m(3),_c('div',{staticClass:"ml-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.medcert_for_insurance.estimated_admission_day),expression:"medcert_for_insurance.estimated_admission_day"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"e.g. 2 days"},domProps:{"value":(_vm.medcert_for_insurance.estimated_admission_day)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.medcert_for_insurance, "estimated_admission_day", $event.target.value)}}})])]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_c('div',{staticClass:"d-flex mt-3"},[_vm._m(10),_vm._m(11),_c('div',{staticClass:"ml-2"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.addRowList('medcert_for_insurance', 'medication', 1)}}},[_c('i',{staticClass:"fa fa-plus"})]),_c('button',{staticClass:"btn btn-secondary ml-2",on:{"click":function($event){return _vm.removeRowList(
                      'medcert_for_insurance',
                      'medication',
                      _vm.index
                    )}}},[_c('i',{staticClass:"fa fa-trash"})])])]),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16)]),_c('med-cert-footer'),_c('hr'),_c('div',{staticClass:"text-center"},[_vm._m(17),_vm._m(18),_c('router-link',{staticClass:"btn btn-outline-dark btn-lg",staticStyle:{"margin-left":"10px","width":"150px"},attrs:{"to":{
                name: 'Print Medical Certificate',
                params: { id: _vm.$route.params.visitId }
              },"target":"_blank"}},[_c('i',{staticClass:"fas fa-print"}),_vm._v(" Print ")]),_vm._m(19)],1)],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticClass:"table table-bordered"},[_c('thead',{staticClass:"bg-gray text-center"},[_c('tr',[_c('th',[_vm._v("Date created")]),_c('th',[_vm._v("Time")]),_c('th',[_vm._v("Report No.")]),_c('th',[_vm._v("VN")]),_c('th',[_vm._v("Branch")]),_c('th',[_vm._v("Dept")]),_c('th',[_vm._v("Report type")]),_c('th',[_vm._v("Attending Doctor")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("14/01/2020")]),_c('td',[_vm._v("24:00")]),_c('td',[_vm._v("04-01-2020-000016")]),_c('td',[_vm._v("123-456-789")]),_c('td',[_vm._v("Hospital")]),_c('td',[_vm._v("ER/IPD")]),_c('td',[_vm._v("Medical report for work permit")]),_c('td',[_vm._v("Dr. Malavee Suknan")])]),_c('tr',[_c('td',[_vm._v("12/03/2018")]),_c('td',[_vm._v("24.00")]),_c('td',[_vm._v("04-02-2018-001254")]),_c('td',[_vm._v("587-965-478")]),_c('td',[_vm._v("Hospital")]),_c('td',[_vm._v("OPD")]),_c('td',[_vm._v("Medical report for work permit")]),_c('td',[_vm._v("Dr. Malavee Suknan")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center pb-5"},[_c('button',{staticClass:"btn btn-primary btn-lg",attrs:{"type":"button"}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("Create New Medical certificate for insurance ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-1"},[_c('b',[_vm._v("ADMITTED DATE:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-1"},[_c('b',[_vm._v("ESTIMATED ADMISSION DAYS:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mt-3"},[_c('div',{staticStyle:{"min-width":"150px"}},[_c('b',{staticClass:"txt-blue"},[_c('u',[_vm._v("CHIEF COMPLAIN:")])])]),_c('div',{staticClass:"ml-3",staticStyle:{"min-width":"600px"}},[_c('input',{staticClass:"form-control",attrs:{"type":"text","value":"diarrhea, weakness, nausea, fever, loss of appetite, vomiting, stomach cramp"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mt-3"},[_c('div',{staticStyle:{"min-width":"150px"}},[_c('b',{staticClass:"txt-blue"},[_c('u',[_vm._v("PRESENT ILLNESS:")])])]),_c('div',{staticClass:"ml-3",staticStyle:{"min-width":"600px"}},[_c('input',{staticClass:"form-control",attrs:{"type":"text","value":"present condition"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mt-3"},[_c('div',{staticStyle:{"min-width":"150px"}},[_c('b',{staticClass:"txt-blue"},[_c('u',[_vm._v("PATIENT HISTORY:")])])]),_c('div',{staticClass:"ml-3",staticStyle:{"min-width":"600px"}},[_c('input',{staticClass:"form-control",attrs:{"type":"text","value":"No known allergy, No known illness"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mt-3"},[_c('div',{staticStyle:{"min-width":"150px"}},[_c('b',{staticClass:"txt-blue"},[_c('u',[_vm._v("PHYSICAL EXAM")])])]),_c('div',{staticClass:"ml-3"},[_c('p',[_vm._v("Temp: "),_c('u',{staticClass:"ml-1 mr-1"},[_vm._v("37")]),_vm._v(" C,")])]),_c('div',{staticClass:"ml-2"},[_c('p',[_vm._v("PR: "),_c('u',{staticClass:"ml-1 mr-1"},[_vm._v("80")]),_vm._v(" bpm,")])]),_c('div',{staticClass:"ml-2"},[_c('p',[_vm._v("RR: "),_c('u',{staticClass:"ml-1 mr-1"},[_vm._v("100")]),_vm._v(" bpm,")])]),_c('div',{staticClass:"ml-2"},[_c('p',[_vm._v("BP: "),_c('u',{staticClass:"ml-1 mr-1"},[_vm._v("80/100")]),_vm._v(" mmHg")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pl-5"},[_c('div',{staticClass:"d-flex mt-2"},[_c('div',[_c('b',[_vm._v("GA:")])]),_c('div',{staticClass:"ml-4"},[_vm._v(" Appears weak, tired and dehydrated, no dyspnea ")])]),_c('div',{staticClass:"d-flex mt-2"},[_c('div',[_c('b',[_vm._v("HEENT:")])]),_c('div',{staticClass:"ml-4"},[_vm._v(" Appears pale, no jaundice, dry lips mucosa, sunken eyeballs, mild redness of pharynx ")])]),_c('div',{staticClass:"d-flex mt-2"},[_c('div',[_c('b',[_vm._v("RS:")])]),_c('div',{staticClass:"ml-4"},[_vm._v(" Clear, regular breath sound, no wheezing, symmetrical breath sounds ")])]),_c('div',{staticClass:"d-flex mt-2"},[_c('div',[_c('b',[_vm._v("CVS:")])]),_c('div',{staticClass:"ml-4"},[_vm._v(" normal S1S2, no murmur ")])]),_c('div',{staticClass:"d-flex mt-2"},[_c('div',[_c('b',[_vm._v("ABDOMEN:")])]),_c('div',{staticClass:"ml-4"},[_vm._v(" Soft, with mild tenderness, No guarding sound. No mass , no rigidity, no rebound , no hyper bowel sound. ")])]),_c('div',{staticClass:"d-flex mt-2"},[_c('div',[_c('b',[_vm._v("EXTREMITIES:")])]),_c('div',{staticClass:"ml-4"},[_vm._v(" No significant findings ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mt-5"},[_c('div',{staticStyle:{"min-width":"150px"}},[_c('b',{staticClass:"txt-blue"},[_c('u',[_vm._v("INITIAL DIAGNOSIS:")])])]),_c('div',{staticClass:"ml-3",staticStyle:{"min-width":"600px"}},[_c('select',{staticClass:"form-control",attrs:{"id":"pain_score"}},[_c('option',{attrs:{"value":"","disabled":"","selected":"","hidden":""}}),_c('option',{attrs:{"value":"ACUTE GASTROENTERITIS with MILD DEHYDRATION","selected":""}},[_vm._v("ACUTE GASTROENTERITIS with MILD DEHYDRATION")]),_c('option',{attrs:{"value":""}},[_vm._v("ETC")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"min-width":"150px"}},[_c('b',{staticClass:"txt-blue"},[_c('u',[_vm._v("MEDICATIONS:")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-3",staticStyle:{"min-width":"600px"}},[_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"medication"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mt-3"},[_c('div',{staticStyle:{"min-width":"150px"}},[_c('b',{staticClass:"txt-blue"},[_c('u',[_vm._v("LABORATORIES:")])])]),_c('div',{staticClass:"ml-3",staticStyle:{"min-width":"600px"}},[_c('select',{staticClass:"form-control",attrs:{"id":"laboratory"}},[_c('option',{attrs:{"value":"","disabled":"","selected":"","hidden":""}}),_c('option',{attrs:{"value":"A"}},[_vm._v("A")]),_c('option',{attrs:{"value":"B"}},[_vm._v("B")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pl-5 mt-4"},[_c('div',{staticClass:"d-flex mt-2"},[_c('div',[_c('b',[_vm._v("X-RAY")])]),_c('div',{staticClass:"ml-4"},[_vm._v(" Left Foot (AP, Lateral) ")])]),_c('div',{staticClass:"d-flex mt-2"},[_c('div',[_c('b',[_vm._v("EKG")])]),_c('div',{staticClass:"ml-4"},[_vm._v(" 12 Lead ")])]),_c('div',{staticClass:"d-flex mt-2"},[_c('div',[_c('b',[_vm._v("LABORATORY")])]),_c('div',{staticClass:"ml-4"},[_vm._v(" CBC, BUN, Crea, E’Lyte ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mt-4"},[_c('div',{staticStyle:{"min-width":"150px"}},[_c('b',{staticClass:"txt-blue"},[_c('u',[_vm._v("FOLLOW UP LABORATORIES:")])])]),_c('div',{staticClass:"ml-4"},[_vm._v(" Follow up ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex p-5"},[_c('div',[_c('b',[_vm._v("STOOL EXAMINATION")])]),_c('div',{staticClass:"ml-4"},[_vm._v(" - waiting for the pt to collect ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mt-3"},[_c('div',{staticStyle:{"min-width":"150px"}},[_c('b',{staticClass:"txt-blue"},[_c('u',[_vm._v("PLAN:")])])]),_c('div',{staticClass:"ml-3",staticStyle:{"min-width":"600px"}},[_c('textarea',{staticClass:"form-control",attrs:{"id":"","cols":"30","rows":"4"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-success btn-lg",staticStyle:{"width":"150px"},attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" Save ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-warning btn-lg",staticStyle:{"margin-left":"10px","width":"150px"},attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-edit"}),_vm._v(" Edit ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-danger btn-lg",staticStyle:{"margin-left":"10px","width":"150px"},attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-times"}),_vm._v(" Cancel ")])
}]

export { render, staticRenderFns }