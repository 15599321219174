var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-2"},[_c('div',{staticClass:"card shadow"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"text-primary"},[_vm._v("Hotels")]),_c('div',{staticClass:"h-flex container--hotel"},_vm._l((_vm.zones),function(zone){return _c('div',{key:zone.id,staticClass:"table--hotel flex-1"},[_c('div',[_c('div',{staticClass:"text-center my-2"},[_c('strong',[_vm._v("Zone "+_vm._s(zone.name))])]),_c('table',{staticClass:"table table-bordered"},[_vm._m(0,true),((zone.hotels && zone.hotels.length))?_c('tbody',_vm._l((zone.hotels),function(row,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(row))]),_c('td',{staticClass:"d-flex justify-content-center"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.showHotel(row, zone)}}},[_c('i',{staticClass:"fas fa-edit"})])])])}),0):_vm._e()])])])}),0),_c('button',{staticClass:"btn btn-primary btn-block mt-2",on:{"click":function($event){return _vm.showHotel(null)}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" add zone ")])])])])]),_c('b-modal',{ref:"hotel-modal",attrs:{"id":"hotel-modal","hide-footer":"","title":"Hotel","size":"lg"}},[_c('MazInput',{staticClass:"mt-2",attrs:{"placeholder":'Hotel Name'},model:{value:(_vm.hotel.name),callback:function ($$v) {_vm.$set(_vm.hotel, "name", $$v)},expression:"hotel.name"}}),_c('MazSelect',{staticClass:"mt-2",attrs:{"options":_vm.dd_zone.map(item => {
          return {
            label: item,
            value: item
          };
        })},model:{value:(_vm.hotel.zone),callback:function ($$v) {_vm.$set(_vm.hotel, "zone", $$v)},expression:"hotel.zone"}}),_c('button',{staticClass:"btn btn-success btn-block mt-2",on:{"click":_vm.saveHotel}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" Save")])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Action")])])
}]

export { render, staticRenderFns }