import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

import { BootstrapVue } from "bootstrap-vue";

import { firestorePlugin } from "vuefire";

import "bootstrap-vue/dist/bootstrap-vue.css";

import "@fortawesome/fontawesome-free/css/all.css";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import VuePhoneNumberInput from "vue-phone-number-input";
import VueGoodTablePlugin from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import i18n from "./i18n";
import CountryFlag from "vue-country-flag";
import Loading from "vue-loading-overlay";
import VueSignaturePad from "vue-signature-pad";
import VueSimpleAlert from "vue-simple-alert";
import {
  MazPhoneNumberInput,
  MazInput,
  MazPicker,
  MazSelect,
  MazCheckbox,
  MazRadio,
  MazSearch,
  MazInputTags
} from 'maz-ui'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import Chart from 'chart.js/auto';
import { registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Multiselect from 'vue-multiselect'

import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import "slim-select/dist/slimselect.min.css";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "vue-good-table/dist/vue-good-table.css";
import "vue-loading-overlay/dist/vue-loading.css";
import "paper-css/paper.css";
import "./assets/scss/main.scss";
import "vue-loading-overlay/dist/vue-loading.css";
import 'vue-multiselect/dist/vue-multiselect.min.css'

Vue.use(MazPhoneNumberInput)
Vue.use(MazInput)
Vue.use(MazPicker)
Vue.use(MazSelect)
Vue.use(MazRadio)
Vue.use(MazCheckbox)
Vue.use(MazSearch)
Vue.use(MazInputTags)
Vue.use(VueSignaturePad);
Vue.use(VueSidebarMenu);
Vue.use(BootstrapVue);
Vue.use(firestorePlugin);
Vue.component("v-select", vSelect);
Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.component('multiselect', Multiselect)
Vue.use(require("vue-moment"));
Vue.use(VueGoodTablePlugin);
Vue.use(flatPickr);
Vue.component("country-flag", CountryFlag);
Vue.use(VueSimpleAlert);
Vue.use(Loading);
Vue.use(VueLodash, { name: "custom", lodash: lodash });

Chart.register(...registerables);
Chart.register(ChartDataLabels);

import "./global-components";

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
