<template>
  <div class="container-fluid">
    <vue-good-table
      :columns="columns"
      :rows="visits"
      :pagination-options="{
        enabled: true
      }"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.label == 'No.'">
          {{props.row.originalIndex + 1}}
        </div>
        <div v-else-if="props.column.label == 'Regis. Time'">
          {{props.row.visit_date}}
        </div>
        <div v-else-if="props.column.label == 'Patient Name ESI HN/VN/AN'">
          <div class="text-dark">
            <b>{{ props.row.name }}</b>
          </div>
          <div class="text-muted">
            <router-link target="_blank" :to="`/patient/${props.row.HN}`"><i>HN: {{ props.row.HN }}</i></router-link>
          </div>
          <div class="text-muted" v-if="props.row.VN">
            <router-link target="_blank" :to="`/patient/${props.row.HN}/${props.row.VN}`"><i>VN: {{ props.row.VN }}</i></router-link>
          </div>
          <div
            class="text-primary"
            v-if="props.formattedRow.details.appointment"
          >
            <i>Appointment: {{ props.formattedRow.details.appointment }}</i>
          </div>
        </div>
        <span v-else-if="props.column.label == 'CC / DX'">
          <div
            class="esi-box"
            :style="{
              color: props.row.esi == 5 ? 'dark' : 'white',
              'background-color': getEsiColor(props.row.esi)
            }"
            v-if="props.row.esi"
          >
            ESI level {{ props.row.esi }}
          </div>
          <div class="text-dark">
            <b>{{ props.row.cc }}</b>
            <b>{{ props.row.pi }}</b>
          </div>
        </span>
        <div v-else-if="props.column.field == 'tasks'" class="d-flex">
          <div
            class="task-box mr-2"
            :style="{ color: status ? 'orange' : 'lightgrey' }"
            v-for="(status, task_name) in props.row.tasks"
            :key="task_name"
          >
            <i class="fas fa-capsules fa-2x" v-if="task_name === 'MEDS'"></i>
            <i class="fas fa-x-ray fa-2x" v-if="task_name === 'XRAY'"></i>
            <i class="fas fa-flask fa-2x" v-if="task_name === 'LAB'"></i>
            <router-link
              target="_blank" 
              style="text-decoration: none; color: inherit;" 
              :to="`/patient/${props.row.HN}/${props.row.VN}/opd-card`"
            >
              <i class="fas fa-user-md fa-2x" v-if="task_name === 'DR'"></i>
            </router-link>
            <router-link
              target="_blank" 
              style="text-decoration: none; color: inherit;" 
              :to="`/patient/${props.row.HN}/${props.row.VN}/nurse-assessment`"
            >
              <i class="fas fa-user-nurse fa-2x" v-if="task_name === 'RN'"></i>
            </router-link>
            <router-link 
              target="_blank" 
              style="text-decoration: none; color: inherit;" 
              :to="`/patient/${props.row.HN}/details`"
            >
              <i class="fas fa-id-card fa-2x" v-if="task_name === 'REG'"></i>
            </router-link>
            <div>
              <b>{{ task_name }}</b>
            </div>
          </div>
        </div>
        <div
          class="d-flex"
          style="padding: 0px;"
          v-else-if="props.column.field == 'patient_status'"
        >
          <div class="text-center" style="width: 60px">
            <i
              class="fas fa-3x mr-2"
              :class="getIcon(props.formattedRow.patient_status.status)"
              :style="{
                color: getStatusColor(props.formattedRow.patient_status.status),
                'line-height': '100px'
              }"
            ></i>
          </div>
          <div class="patient-status-box-container">
            <div
              class="patient-status-box"
              :style="{
                'background-color': getStatusColor(
                  props.formattedRow.patient_status.status
                ),
                width: `${props.formattedRow.patient_status.progress * 100}px`
              }"
            >
              <div>
                <b>{{ props.formattedRow.patient_status.status }}</b>
              </div>
              <div>
                <i>{{ props.formattedRow.patient_status.staff }}</i>
              </div>
            </div>
          </div>
        </div>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  props: ['visits'],
  data() {
    return {
      columns: [
        {
          label: "No.",
          field: "id"
        },
        {
          label: "Regis. Time",
          field: "created_at"
        },
        {
          label: "Patient Name ESI HN/VN/AN",
          field: "details"
        },
        {
          label: "CC / DX",
          field: "cc_dx"
        },
        {
          label: "Patient Status",
          field: "patient_status"
        },
        {
          label: "Pending / Completed",
          field: "tasks",
          width: "300px"
        },
        {
          label: "Finance",
          field: "finance"
        },
        {
          label: "DR",
          field: "doctor"
        },
        {
          label: "Note",
          field: "note"
        }
      ],
    };
  },
  methods: {
    onRowClick(params) {
      this.$router.push(`/patient/${params.row.HN}`);
    },
    getEsiColor(esi) {
      switch (esi) {
        case 1:
          return "#CB0000";
          break;
        case 2:
          return "#EA9A99";
          break;
        case 3:
          return "#F1C331";
          break;
        case 4:
          return "#629B49";
          break;
        case 5:
          return "#FFFFFF";
          break;
      }
    },
    getStatusColor(status) {
      switch (status) {
        case "On Billing":
          return "#3C78D8";
          break;
        case "Wait for Results":
          return "#A4C964";
          break;
        case "Finish Registration":
          return "#1C800F";
          break;
        case "On Nursing Asssessment":
          return "#1C800F";
          break;
        case "On Interpretation with doctor":
          return "#274E12";
          break;
        default:
          return "#adadad";
          break;
      }
    },
    getIcon(status) {
      switch (status) {
        case "On Billing":
          return "fa-file-invoice-dollar";
          break;
        case "Wait for Results":
          return "fa-clock";
          break;
        case "Finish Registration":
          return "fa-clock";
          break;
        case "On Nursing Asssessment":
          return "fa-user-nurse";
          break;
        case "On Interpretation with doctor":
          return "fa-user-md";
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.billing {
  color: tomato;
}

.esi-box {
  padding: 5px;
  font-weight: 800;
  border-radius: 0.25em;
  border: 1px solid #adadad;
}

.patient-status-box-container {
  border-radius: 0.25em;
  border: 1px solid #adadad;
  position: relative;
  width: 300px;
  height: 100px;
}
.patient-status-box {
  border-radius: 0.25em;
  color: white;
  padding: 5px;
  height: 100px;
  position: absolute;
  overflow-wrap: break-word;
}

.table.vgt-table td {
  padding: 0px !important;
}

.task-box {
  text-align: center;
  height: 60px;
  width: 60px;
}
</style>
