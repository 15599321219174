<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <div class="pd10">
          <div class="row">
            <div class="col-xl-6">
              <table class="table table-bordered bg-white text-center">
                <thead>
                  <tr>
                    <th colspan="6" class="bg-nursing label-h">BREATING</th>
                  </tr>
                  <tr>
                    <th width="10%">No</th>
                    <th width="50%">List</th>
                    <th width="10%">QTY</th>
                    <th width="10%">Used</th>
                    <th width="10%">Returned</th>
                    <th width="10%">Refilled</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(i, index) in transfer_eqiupment_list.breating_list"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="text-left">{{ i.name }}</td>
                    <td>{{ i.qty }}</td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        style="width: 50px;"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="form-control ml-auto mr-auto"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="form-control ml-auto mr-auto"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-bordered bg-white text-center">
                <thead>
                  <tr>
                    <th colspan="6" class="bg-nursing label-h">AIRWAY</th>
                  </tr>
                  <tr>
                    <th width="10%">No</th>
                    <th width="50%">List</th>
                    <th width="10%">QTY</th>
                    <th width="10%">Used</th>
                    <th width="10%">Returned</th>
                    <th width="10%">Refilled</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(i, index) in transfer_eqiupment_list.airway_list"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="text-left">{{ i.name }}</td>
                    <td>{{ i.qty }}</td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        style="width: 50px;"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="form-control ml-auto mr-auto"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="form-control ml-auto mr-auto"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-bordered bg-white text-center">
                <thead>
                  <tr>
                    <th colspan="6" class="bg-nursing label-h">
                      MEDICAL EQUIPMENTS 1
                    </th>
                  </tr>
                  <tr>
                    <th width="10%">No</th>
                    <th width="50%">List</th>
                    <th width="10%">QTY</th>
                    <th width="10%">Used</th>
                    <th width="10%">Returned</th>
                    <th width="10%">Refilled</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(i,
                    index) in transfer_eqiupment_list.med_equip_list_1"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="text-left">{{ i.name }}</td>
                    <td>{{ i.qty }}</td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        style="width: 50px;"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="form-control ml-auto mr-auto"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="form-control ml-auto mr-auto"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-bordered bg-white text-center">
                <thead>
                  <tr>
                    <th colspan="6" class="bg-nursing label-h">
                      MEDICAL EQUIPMENTS 2
                    </th>
                  </tr>
                  <tr>
                    <th width="10%">No</th>
                    <th width="50%">List</th>
                    <th width="10%">QTY</th>
                    <th width="10%">Used</th>
                    <th width="10%">Returned</th>
                    <th width="10%">Refilled</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(i,
                    index) in transfer_eqiupment_list.med_equip_list_2"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="text-left">{{ i.name }}</td>
                    <td>{{ i.qty }}</td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        style="width: 50px;"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="form-control ml-auto mr-auto"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="form-control ml-auto mr-auto"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-xl-6">
              <table class="table table-bordered bg-white text-center">
                <thead>
                  <tr>
                    <th colspan="6" class="bg-nursing label-h">
                      MEDICINES (IV)
                    </th>
                  </tr>
                  <tr>
                    <th width="10%">No</th>
                    <th width="50%">List</th>
                    <th width="10%">QTY</th>
                    <th width="10%">Used</th>
                    <th width="10%">Returned</th>
                    <th width="10%">Refilled</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(i,
                    index) in transfer_eqiupment_list.medicine_iv_list"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="text-left">{{ i.name }}</td>
                    <td>{{ i.qty }}</td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        style="width: 50px;"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="form-control ml-auto mr-auto"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="form-control ml-auto mr-auto"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-bordered bg-white text-center">
                <thead>
                  <tr>
                    <th colspan="6" class="bg-nursing label-h">
                      MEDICINE (ORAL)
                    </th>
                  </tr>
                  <tr>
                    <th width="10%">No</th>
                    <th width="50%">List</th>
                    <th width="10%">QTY</th>
                    <th width="10%">Used</th>
                    <th width="10%">Returned</th>
                    <th width="10%">Refilled</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(i,
                    index) in transfer_eqiupment_list.medicine_oral_list"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="text-left">{{ i.name }}</td>
                    <td>{{ i.qty }}</td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        style="width: 50px;"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="form-control ml-auto mr-auto"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="form-control ml-auto mr-auto"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-bordered bg-white text-center">
                <thead>
                  <tr>
                    <th colspan="6" class="bg-nursing label-h">SUPPLIES</th>
                  </tr>
                  <tr>
                    <th width="10%">No</th>
                    <th width="50%">List</th>
                    <th width="10%">QTY</th>
                    <th width="10%">Used</th>
                    <th width="10%">Returned</th>
                    <th width="10%">Refilled</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(i, index) in transfer_eqiupment_list.supplies_list"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="text-left">{{ i.name }}</td>
                    <td>{{ i.qty }}</td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        style="width: 50px;"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="form-control ml-auto mr-auto"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="form-control ml-auto mr-auto"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-bordered bg-white text-center">
                <thead>
                  <tr>
                    <th colspan="7" class="bg-nursing label-h">EXTRAS</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th width="40%">List</th>
                    <th>QTY</th>
                    <th>Used</th>
                    <th>Returned</th>
                    <th>Refilled</th>
                    <th>
                      <button
                        class="btn btn-primary"
                        @click="
                          addRowList('transfer_eqiupment_list', 'extra_list', 1)
                        "
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(i, index) in transfer_eqiupment_list.extra_list"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="text-left">
                      <input type="text" class="form-control" id="" />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        style="width: 50px;"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        style="width: 50px;"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="form-control ml-auto mr-auto"
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        class="form-control ml-auto mr-auto"
                      />
                    </td>
                    <td>
                      <button
                        class="btn btn-secondary"
                        @click="
                          removeRowList(
                            'transfer_eqiupment_list',
                            'extra_list',
                            index
                          )
                        "
                      >
                        <i class="fa fa-minus"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="p-2 mt-3">
                <textarea
                  class="form-control"
                  id=""
                  cols="30"
                  rows="4"
                  placeholder="Notes"
                ></textarea>
              </div>
              <div class="d-flex mt-2">
                <div class="ml-auto" style="min-width: 200px;">
                  <label for="">Record by:</label>
                  <select class="form-control" id="record_by">
                    <option value disabled selected hidden></option>
                    <option value="">N1</option>
                    <option value="">N2</option>
                    <option value="">N3</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import transferEquipMixins from "../../mixins/transfer-equipment-list";

export default {
  mixins: [transferEquipMixins],

  data() {
    return {
      // date picker
      stdDateConfig: {
        altInput: true,
        altFormat: "d/m/Y"
      },
      stdDateTimeConfig: {
        altInput: true,
        altFormat: "d/m/Y H:i",
        enableTime: true,
        enableSeconds: false,
        time_24hr: true
      },
      stdTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      },
      breating: []
    };
  }
};
</script>
<style lang="scss" scoped>
input[type="checkbox"] {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

table,
th,
td {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-collapse: collapse;
}

// table, tr {
//   border: 1px solid rgba(0, 0, 0, 0.3);
//   border-collapse: collapse;
// }

.label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.box-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.label-b {
  color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}
.label-h {
  // color: rgb(6, 132, 216);
  // padding-top: 10px;
  font-size: 20px;
  color: #fff;
}

.form-check {
  margin-left: 10px;
}
button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
</style>
