<template>
    <div class="container-fluid">
      <div class="card shadow">
        <div class="card-body">
          <div class="text-muted">Service Management</div>  
          <hr />
          <b-nav class="mb-2" tabs>
            <b-nav-item :active="currentMenu === menu.name" v-for="(menu, index) in menus" :key="index"
              @click="currentMenu = menu.name">{{ menu.name }}</b-nav-item>
          </b-nav>
  
          <div v-if="currentMenu === 'Service'">
            <service-tab />  
          </div>

        </div>
  
      </div>
      
    </div>
  </template>
  
  <script>

  import Service from './Service.vue'
  
  export default {
    components: {
      
      "service-tab": Service,
    },
    data() {
      return {
        currentMenu: "Service",        
      }
    },
    firestore() {
      return {
      }
    },
    methods: {    
      
    },
    computed: {      
      menus() {
        const menus = [
          { name: "Service" },
        ]
        return menus
      },
    },
  }
  </script>