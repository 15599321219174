<template>
    <div>
      <table class="table table-bordered bg-white text-center data.form-table" v-if="data.billable_items.length > 0">
          <thead class="bg-table-header-print" style="color: #306797;">
            <tr>
              <th width="55%" class="text-left">Services</th>
              <!-- <th width="15%">Type</th>
              <th width="15%">Unit Price</th>
              <th width="10%">Quantity</th>              
              <th width="15%" v-if="totalDiscount>0">Discount (%)</th> -->
              <th width="15%" v-if="totalDiscount>0">Amount</th>
              <th width="15%" v-if="totalDiscount>0">Discount</th>
              <th width="15%">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in data.billable_items" :key="row.id">
              <td class="text-left">{{ row.item.item_name }}</td>
              <!-- <td>{{ row.item.item_type }}</td>
              <td class="text-right">฿{{ Number(row.price).toLocaleString(undefined, {minimumFractionDigits: 2}) }}</td>
              <td class="text-right">{{ row.quantity }}</td>              
              <td class="text-right" v-if="totalDiscount > 0">{{ Number(row.discount) }} %</td>  -->
              <td class="text-right" v-if="totalDiscount > 0">฿{{ ((Number(row.price))*Number(row.quantity)).toLocaleString(undefined) }}</td>
              <td class="text-right" v-if="totalDiscount > 0">฿{{ (Number(row.price) * Number(row.discount) / 100 * Number(row.quantity)).toLocaleString(undefined) }}</td>
              <td class="text-right">฿{{ ((Number(row.price) - (Number(row.price) * row.discount / 100))*Number(row.quantity)).toLocaleString(undefined) }}</td>
            </tr>
          </tbody>
        </table>
        <table class="table table-bordered bg-white text-center data.form-table" v-if="data.product_items.length > 0">
          <thead class="bg-table-header-print" style="color: #306797;">
            <tr>
              <th width="55%" class="text-left">Product Item</th>
              <!-- <th width="15%">Type</th>
              <th width="15%">Unit Price</th>
              <th width="10%">Quantity</th>               
              <th width="15%" v-if="totalDiscount > 0">Discount %</th> -->
              <th width="15%" v-if="totalDiscount > 0">Amount</th>
              <th width="15%" v-if="totalDiscount > 0">Discount</th>
              <th width="15%">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in data.product_items" :key="row.id">
              <td class="text-left">{{ row.item.item_name }}</td>
              <!-- <td>{{ row.item.item_type }}</td>
              <td class="text-right">฿{{ Number(row.price).toLocaleString(undefined, {minimumFractionDigits: 2}) }}</td>
              <td class="text-right">{{ row.quantity }}</td>               
              <td class="text-right" v-if="totalDiscount > 0">{{ Number(row.discount)}} %</td> -->
              <td class="text-right" v-if="totalDiscount > 0">฿{{ (Number(row.price)*Number(row.quantity)).toLocaleString(undefined) }}</td>
              <td class="text-right" v-if="totalDiscount > 0">฿{{ (Number(row.price) * Number(row.discount) / 100 * Number(row.quantity)).toLocaleString(undefined) }}</td>
              <td class="text-right">฿{{ ((Number(row.price) - (Number(row.price) * row.discount /
                100))*Number(row.quantity)).toLocaleString(undefined) }}</td>
            </tr>
          </tbody>
        </table>
    </div>
  </template>
  
  <script>
  export default { 
    props: ['data'],
    computed: {
      totalDiscount() {
        let sum_product = _.sum(this.data.product_items.map(item => {
          if (item.quantity && item.price) {
            return Number(item.quantity) * Number(item.price) * Number(item.discount) / 100
          } else {
            return 0
          }
        }))
        let sum_billable_items = _.sum(this.data.billable_items.map(item => {
          if (item.quantity && item.price) {
            return Number(item.quantity) * Number(item.price) * Number(item.discount) / 100
          } else {
            return 0
          }
        }))
  
        return sum_product + sum_billable_items
      },
    }
  }
  </script>
  
  <style scoped lang="css">
  .receipt-form {
    font-size: 13px;
  }
  .receipt-form-table {
    font-size: 11px;
  }
  .table th, .table td {
    padding: .3rem;
  }
  
  @page {
      size: A4;
      margin: 5mm 0mm ;
  }
  .sheet {
      overflow: visible;
      height: auto !important;
  }
  </style>