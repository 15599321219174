<template>
  <div>
    <div class="d-flex mt-2 mb-2">
      <!-- <button class="shadow btn mr-2" :class="{'btn-primary': mode==='quantity'}" @click="mode='quantity'">Quantity</button>
      <button class="shadow btn mr-2" :class="{'btn-primary': mode==='amount'}" @click="mode='amount'">Amount</button> -->
      <button class="shadow btn" :class="{'btn-primary': mode==='insurance'}" @click="mode='insurance'">Insurance</button>
    </div>
    <!-- <div class="card shadow" v-if="mode==='quantity'">
      <div class="card-body">
        <insurance-quantity :payments="payments"/>
      </div>
    </div>
    <div class="card shadow" v-if="mode==='amount'">
      <div class="card-body">
        <insurance-amount :payments="payments"/>
      </div>
    </div> -->
    <div class="card shadow" v-if="mode==='insurance'">
      <div class="card-body">
        <insurance-by-type :payments="payments" :invoices="invoices"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['payments','invoices'],
  data() {
    return {
      mode: 'insurance'
    }
  }
}
</script>