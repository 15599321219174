import Sale from "../pages/Sale/";


export default [
  {
    path: "/sale",
    name: "Sale",
    component: Sale
  },

]