<template>
  <b-modal :id="id" title="Mode of Transfer" size="xl" hide-footer>
    <div class="container-fluid">
      <div class="header mt-2">
        <i class="fas fa-user-md"></i> <i class="fas fa-user-nurse"></i> Indications that require a doctor and at least 1 nurse during transfer :
      </div>
      <b-form-group>
        <b-form-checkbox-group
          v-model="value.indications"
          :options="indications"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
      <div class="header mt-2">
        <i class="fas fa-user-nurse"></i> Indications that require at least 1 nurse during transfer :
      </div>
      <b-form-group>
        <b-form-checkbox-group
          v-model="value.indications2"
          :options="indications2"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
      <div class="header mt-2">
        Condition that is safe. Nurse aid can facilitate transfer
      </div>
      <b-form-group>
        <b-form-checkbox-group
          v-model="value.conditions"
          :options="conditions"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
      <div class="mt-2">
        Please choose appropriate mode of transfer according to indication of patient. 
      </div>
      <b-form-group>
        <b-form-checkbox-group
          v-model="value.mode"
          :options="modes"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
    </div>



     <div class="d-flex justify-content-center">
      <button class="btn btn-primary mr-2" @click="confirmOrder">Confirm Order</button>
      <button class="btn btn-danger mr-2" @click.prevent="$bvModal.hide(id)">Cancel</button>
    </div>
  </b-modal>
</template>

<script>
const indications = [
  'Unstable vital sign or have tendency of unstable vital sign.',
  'AIRWAY : Intubated patient or patient with tendency of airway obstruction.',
  'BREATHING : respiratory distress, desaturation',
  'CIRCULATION : hypotension, sign of shock',
  'DISABILITY : patient has alteration of consciousness.',
  'Patient has possibility for need of life saving intervention while transfer (e.g. Patient with anaphylaxis who may need adrenaline injection or intubation during transfer.)',
  'Major active bleeding that may lead to hypovolemic shock.',
  'Moderate or Severe head injury/ Mild head injury with high risks (alteration of consciousness, history of lost of consciousness,  neurodeficit, seizure,sign of base of skull injury, vomiting, lost of balance, Vertigo, abnormal behavior, history of high-impact accident ) ',
  'Patient receiving high alert drug (e.g. KCl intravenous, Vasopressin intravenous)',
  'ESI 1,2',
  'Patient suspected with condition that may lead to Cardiac arrest (5H,5T)',
  'Coronary artery disease',
  'Stroke',
  'Patient in severe pain that may need Opioids during transfer',
  'Possibility of emergency delivery',
  'Patient suspected with ectopic pregnancy, aortic dissection, abdominal aortic aneurysm',
  'Patient on Intercostal drainage',
  'Patient suspected with Arterial gas embolism, Pulmonary embolism',
  'Patient requires cardiac pacing',
  'Psychiatric patient with risk of self-harm or harm to another.',
  'High risk patient from doctor evaluation.'
]

const indications2 = [
  'ESI 3 : Patient can wait 4-6 hours without life threatening condition (e.g. Appendicitis without risk of ectopic pregnancy or ruptured  appendicitis)',
  'Patient need emergency operation and have no unstable vital sign or life threatening condition (e.g. Torsion testis, Hernia with stable vital sign, Hemorrhoid grade 4, Cholecystitis, Cholangitis )',
  'Mild head injury without red flag sign',
  'Spine injury (transfer with ferry only)',
  'Calculi in KUB system that need specialist evaluation (Patient may need pain control injection during transfer)'
]

const conditions = [
  'ESI 4,5 : Patient is safe. Patient can wait more than 24 hours without any risk of complication.',
  'Close fracture that can be waited and have no risk of complication e.g. Metatarsal fracture, Nasal fracture with no airway problem.',
  'Stable patient who requires treatment, investigation or evaluation with specialist doctors in mainland and have no life threatening condition. i.e. Patient with acute gastroenteritis with mild dehydration whose symptom is not getting better after many days of observation.'
]

const modes = [
  'Ferry boat : Usually takes 2-4 hours from Phi Phi islands to mainland and only depart in schedule time(please ask for time schedule of ferry boat). Preferable mode of transfer due to safety concern but takes longer transfer time. May not be suitable for emergency case. Spine injury',
  'Speed boat : Usually takes 1-2 hours from Phi Phi islands to mainland. Not recommend during rough weather (strom, waves more than 1 meter ). Contraindication for spine injury. Used for emergency case.'
]
export default {
  data() {
    return {
      id: 'mode-of-transfer',
      indications,
      indications2,
      conditions,
      modes,
    }
  },
  props: {
    value: {
      default: () => {
        return {
          indications: [],
          indications2: [],
          conditions: [],
          modes: []
        }
      }
    },
    onClose: {
      default: () => {}
    },
    onConfirm: {
      default: () => {}
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === this.id)
      this.onClose()
    })
  },
  methods: {
    confirmOrder() {
      this.$bvModal.hide(this.id)
      this.onConfirm()
    }
  }
}
</script>

<style lang="scss" scoped>
.header { 
  padding: 5px;
  color: white;
  background-color: green;
}
</style>