import Staffing from "../pages/Staff/index.vue";
import StaffSummary from "../pages/Staff/Summary.vue";
import Add from "../pages/Staff/Add.vue";
import Info from "../pages/Staff/Info.vue";
import Role from "../pages/Staff/Role.vue";

export default [
  {
    path: "/staffing",
    name: "Staffing",
    component: Staffing,
    redirect: { name: "Staff Summary" },
    children: [
      {
        path: "",
        name: "Staff Summary",
        component: StaffSummary,
        meta: {
          parent: "Staff"
        }
      },
      {
        path: "new",
        name: "New Staff",
        component: Add,
        meta: {
          parent: "Staff"
        }
      },
      {
        path: "role",
        name: "Role Summary",
        component: Role,
        meta: {
          parent: "Role"
        }
      },
      {
        path: ":staffId",
        name: "Staff Info",
        component: Info,
        meta: {
          parent: "Staff"
        }
      },
    ]
  }
];
