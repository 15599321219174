import Product from "../pages/Product/";


export default [
  {
    path: "/product",
    name: "Product",
    component: Product
  },

]