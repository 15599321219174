<template>
  <section>
    <select
      id="occupation"
      name="occupation"
      v-model="scopedOccupation"
      v-on:input="updateValue($event.target.value)"
      v-if="scopedOccupation !== 'Other Occupation'"
    >
      <option value="" selected="selected" disabled="disabled"
        >Select Occupation</option
      >
      <optgroup label="Healthcare Practitioners and Technical Occupations:">
        <option value="Chiropractor">- Chiropractor</option>
        <option value="Dentist">- Dentist</option>
        <option value="Nutritionist">- Nutritionist</option>
        <option value="Optometrist">- Optometrist</option>
        <option value="Pharmacist">- Pharmacist</option>
        <option value="Physician">- Physician</option>
        <option value="Physician Assistant">- Physician Assistant</option>
        <option value="Podiatrist">- Podiatrist</option>
        <option value="Registered Nurse">- Registered Nurse</option>
        <option value="Therapist">- Therapist</option>
        <option value="Veterinarian">- Veterinarian</option>
        <option value="Health Technician">- Health Technician</option>
        <option value="Healthcare Practitioner"
          >- Healthcare Practitioner</option
        >
      </optgroup>
      <!-- <optgroup label="Healthcare Support Occupations:">
        <option value="14">-  Nursing, Psychiatric, or Home Health Aide</option>
        <option value="15">-  Occupational and Physical Therapist Assistant or Aide</option>
        <option value="16">-  Other Healthcare Support Occupation</option>
      </optgroup> -->
      <optgroup
        label="Business, Executive, Management, and Financial Occupations:"
      >
        <option value="Chief Executive">- Chief Executive</option>
        <option value="General and Operations Manager"
          >- General and Operations Manager</option
        >
        <option value="Manager"
          >- Advertising, Marketing, Promotions, Public Relations, and Sales
          Manager</option
        >
        <option value="Operations Specialties Manager"
          >- Operations Specialties Manager (e.g., IT or HR Manager)</option
        >
        <!-- <option value="21">-  Construction Manager</option>
        <option value="22">-  Engineering Manager</option> -->
        <option value="Accountant, Auditor">- Accountant, Auditor</option>
        <option value="Financial Specialist">- Financial Specialist</option>
        <option value="Business Owner">- Business Owner</option>
        <!-- <option value="26">-  Other Business, Executive, Management, Financial Occupation</option> -->
      </optgroup>
      <optgroup label="Architecture and Engineering Occupations:">
        <option value="Architect"
          >- Architect, Surveyor, or Cartographer</option
        >
        <option value="Engineer">- Engineer</option>
        <option value="Surveyor">- Surveyor</option>
        <option value="Cartographer">- Cartographer</option>
        <!-- <option value="29">-  Other Architecture and Engineering Occupation</option> -->
      </optgroup>
      <optgroup label="Education, Training, and Library Occupations:">
        <option value="Postsecondary Teacher"
          >- Postsecondary Teacher (e.g., College Professor)</option
        >
        <option value="Teacher"
          >- Primary, Secondary, or Special Education School Teacher</option
        >
        <option value="Student">- Student</option>
        <!-- <option value="32">-  Other Teacher or Instructor</option>
        <option value="33">-  Other Education, Training, and Library Occupation</option> -->
      </optgroup>
      <optgroup label="Other Professional Occupations:">
        <!-- <option value="34">-  Arts, Design, Entertainment, Sports, and Media Occupations</option> -->
        <option value="Computer Specialist">- Computer Specialist</option>
        <option value="Social Service Specialist"
          >- Counselor, Social Worker, or Other Community and Social Service
          Specialist</option
        >
        <option value="Lawyer">- Lawyer</option>
        <option value="Judge">- Judge</option>
        <option value="Life Scientist"
          >- Life Scientist (e.g., Animal, Food, Soil, or Biological Scientist,
          Zoologist)</option
        >
        <option value="Physical Scientist"
          >- Physical Scientist (e.g., Astronomer, Physicist, Chemist,
          Hydrologist)</option
        >
        <option value="Religious Worker"
          >- Religious Worker (e.g., Clergy, Director of Religious Activities or
          Education)</option
        >
        <option value="Social Scientist"
          >- Social Scientist and Related Worker</option
        >
        <!-- <option value="42">-  Other Professional Occupation</option> -->
      </optgroup>
      <optgroup label="Office and Administrative Support Occupations:">
        <!-- <option value="43">-  Supervisor of Administrative Support Workers</option> -->
        <option value="Financial Clerk">- Financial Clerk</option>
        <option value="Administrative Assistant"
          >- Secretary or Administrative Assistant</option
        >
        <option value="Dispatching Worker"
          >- Material Recording, Scheduling, and Dispatching Worker</option
        >
        <!-- <option value="47">-  Other Office and Administrative Support Occupation</option> -->
      </optgroup>
      <optgroup label="Services Occupations:">
        <option value="Protective Service"
          >- Protective Service (e.g., Fire Fighting, Police Officer,
          Correctional Officer)</option
        >
        <option value="Chef or Head Cook">- Chef or Head Cook</option>
        <option value="Cook or Food Preparation Worker"
          >- Cook or Food Preparation Worker</option
        >
        <option value="Food and Beverage Serving Worker"
          >- Food and Beverage Serving Worker (e.g., Bartender, Waiter,
          Waitress)</option
        >
        <!-- <option value="Building and Grounds Cleaning and Maintenance">-  Building and Grounds Cleaning and Maintenance</option> -->
        <option value="Personal Care and Service"
          >- Personal Care and Service (e.g., Hairdresser, Concierge)</option
        >
        <option value="Airline Cabin Crew">- Airline Cabin Crew</option>
        <option value="Sales Supervisor"
          >- Sales Supervisor, Retail Sales</option
        >
        <option value="Retail Sales Worker">- Retail Sales Worker</option>
        <option value="Insurance Sales Agent">- Insurance Sales Agent</option>
        <option value="Sales Representative">- Sales Representative</option>
        <option value="Real Estate Sales Agent"
          >- Real Estate Sales Agent</option
        >
        <!-- <option value="59">-  Other Services Occupation</option> -->
      </optgroup>
      <optgroup
        label="Agriculture, Maintenance, Repair, and Skilled Crafts Occupations:"
      >
        <option value="Construction and Extraction"
          >- Construction and Extraction (e.g., Construction Laborer,
          Electrician)</option
        >
        <option value="Farming, Fishing, and Forestry"
          >- Farming, Fishing, and Forestry</option
        >
        <option value="Installation, Maintenance and Repair"
          >- Installation, Maintenance, and Repair</option
        >
        <option value="Production Occupations">- Production Occupations</option>
        <!-- <option value="64">-  Other Agriculture, Maintenance, Repair, and Skilled Crafts Occupation</option> -->
      </optgroup>
      <optgroup label="Transportation Occupations:">
        <option value="Aircraft Pilot">- Aircraft Pilot</option>
        <option value="Motor Vehicle Operator"
          >- Motor Vehicle Operator (e.g., Ambulance, Bus, Taxi, or Truck
          Driver)</option
        >
        <option value="Boat Driver">- Boat Driver, Coxswain</option>
        <!-- <option value="67">-  Other Transportation Occupation</option> -->
      </optgroup>
      <optgroup label="Other Occupations:">
        <option value="Military">- Military</option>
        <option value="Merchant">- Merchant</option>
        <option value="Homemaker">- Homemaker</option>
        <!-- <option value="Other Occupation">-  Other Occupation</option> -->
        <option value="Not Applicable">- Not Applicable</option>
        <option v-if="prefillOccupation" :value="occupation"
          >- {{ occupation }}</option
        >
      </optgroup>
    </select>
    <!-- <div v-else-if="scopedOccupation === 'Other Occupation'" class="input-with-icon">
      <input class="pure-u-23-24" type="text" v-model="scopedOccupationOther" placeholder="Please specify your occupation" v-on:input="updateValue($event.target.value)" required>
      <a v-on:click="clearOther">
        <icon name="times"></icon>
      </a>
    </div> -->
  </section>
</template>

<script>
import SlimSelect from "slim-select";

export default {
  name: "occupation-dropdown",
  props: {
    occupation: String
  },
  data() {
    return {
      scopedOccupation: this.occupation,
      scopedOccupationOther: "",
      prefillOccupation: false
    };
  },
  created() {
    if (this.occupation) this.prefillOccupation = true;
  },
  mounted() {
    const self = this;
    /* eslint-disable no-new */
    new SlimSelect({
      select: "#occupation",
      addable: function(value) {
        const _value = self.$lodash.capitalize(value);
        return `- ${_value}`;
      }
    });
  },
  watch: {
    scopedOccupation: function(value) {
      // console.info('value changed: ', value)
      this.$emit("input", value);
    }
  },
  methods: {
    updateValue(value) {
      if (value === "Other Occupation") {
        this.$emit("input", "");
      } else {
        console.info("emit input: ", value);
      }
    },
    clearOther() {
      this.scopedOccupation = "";
      this.scopedOccupationOther = "";
    }
  }
};
</script>
