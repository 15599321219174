<template>
  <div class="container-fluid">
    <div class="d-flex">
      <h3 class="text-primary">Products</h3>
      <button class="btn btn-primary ml-auto" @click="$bvModal.show('import-product')">
        <i class="fas fa-file-import"></i> Import
      </button>
      <button class="btn btn-primary ml-2" @click="registerBarcode">
        <i class="fas fa-plus"></i> Register Barcode
      </button>
      <button class="btn btn-primary ml-2" @click="showProduct()">
        <i class="fas fa-plus"></i> New Products
      </button>
    </div>
    <b-modal id="import-product" title="Import Product" hide-footer>
      <div class="d-flex mt-2">
        <button class="btn btn-primary btn-block" @click="exportCsv(medicines, 'Products_Data')">
          Download CSV
        </button>
      </div>
      <hr />
      <hr />
      <div class="d-flex mt-2">
        <button class="btn btn-primary btn-block" @click="$refs['products-csv'].click()">
          Upload CSV
        </button>
        <input id="products-csv" ref="products-csv" type="file" hidden
          accept=".csv, .xls, .xlsx, text/csv, application/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.msexcel, text/anytext, application/vnd. ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          @change="uploadCsv" />
      </div>
    </b-modal>


    <vue-good-table :columns="medicineColumns" :rows="medicines" @on-row-click="showProduct" :search-options="{ enabled: true,}" :pagination-options="{ enabled: true, }" />
    <b-modal id="product-modal" hide-footer size="xl" :title="'Product'">
      <div class="row">
        <div class="col-12 mt-2">
          <MazInput :placeholder="'Item code'" v-model="product.item_code" />
        </div>
        <div class="col-12 mt-2">
          <MazInput :placeholder="'Item name'" v-model="product.item_name" />
        </div>
        <div class="col-12 mt-2">
          <MazInput :placeholder="'Generic name'" v-model="product.item_other_name" />
        </div>
        <div class="col-12 mt-2">
          <MazInput :placeholder="'Item type'" v-model="product.item_type" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="number" :placeholder="'Price (Thai)'" v-model="product.price_th" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="number" :placeholder="'Price (Foreigner)'" v-model="product.price_inter" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="number" :placeholder="'Price (Insurance)'" v-model="product.price_insurance" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="text" :placeholder="'Supplier'" v-model="product.supplier" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="number" :placeholder="'Cost'" v-model="product.cost" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="number" :placeholder="'Quantity'" v-model="product.quantity" />
        </div>
        <div class="col-12 mt-2">
          <button class="btn btn-primary btn-block" @click="saveProduct">
            <i class="fas fa-save"></i> Save {{ product.id }}
          </button>
        </div>
        <div class="col-12 mt-2" v-if="isEditProduct">
          <button class="btn btn-danger btn-block" @click="deleteProduct(product.item_code)">
            <i class="fas fa-trash"></i> Delete Product {{ product.item_code }}
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal id="register-barcode-modal" hide-footer size="lm" :title="'Register Barcode'" no-close-on-backdrop>
      <div class="row">

        <div class="col-12 mt-2">
          <div v-if="videoInputDevices.length > 1" id="sourceSelectPanel">
            <label for="sourceSelect">Select Camera: </label>
            <select v-model="selectedDeviceId" id="sourceSelect">
              <option v-for="device in videoInputDevices" :key="device.deviceId" :value="device.deviceId">
                {{ device.label }}
              </option>
            </select>
          </div>

          <button @click="startScan" id="startButton" class="float-left">Start</button>
          <button @click="resetScan" id="resetScan" class="float-right">Stop</button>

          <div class="d-flex justify-content-center">
            <video id="video" width="300" height="200" style="border: 1px solid gray"></video>
          </div>
          <label>Result:</label>
          <pre><code> {{ scanResult }}</code></pre>

          <div>
            {{ barcode.item_code }}
          </div>
          <MazInput :placeholder="'Barcode'" v-model="barcode.barcode" />
        </div>

        <div class="col-12 mt-2">
          <v-select class="ml-auto" v-model="barcode.item_code" :options="prepared_products" label="item_name"
                :reduce="item => item.item_code" searchable filterable :filter="filterProduct">
                <template v-slot:option="option">
                  <div>
                    <span>{{ option.item_name }}</span><br>
                    <span v-if="option.item_other_name" style="font-size: smaller; text-decoration: underline;">
                      {{ option.item_other_name }}
                    </span>
                  </div>
                </template>

                <template v-slot:selected-option="option">
                  <div>
                    <span style="display: block;">{{ option.item_name }}</span>
                    <span v-if="option.item_other_name"
                      style="font-size: smaller; text-decoration: underline; display: block;">
                      {{ option.item_other_name }}
                    </span>
                  </div>
                </template>
              </v-select>
        </div>


        <div class="col-12 mt-2">
          <button class="btn btn-primary btn-block" @click="saveBarcode">
            <i class="fas fa-save"></i> Save
          </button>
        </div>

      </div>
    </b-modal>
  </div>

</template>
<script>
import { db, Timestamp } from "../../db"
import Parser from "@json2csv/plainjs/dist/cjs/Parser"
import { BrowserMultiFormatReader, ZXing } from '@zxing/library'
export default {
  components: {
  },
  data() {
    return {
      keyword1: "",
      medicines: [],
      productsFileData: "",
      preparedProductsLength: null,
      preparedProducts: {},
      product: {},
      isEditProduct: false,
      barcode: {},
      medicineColumns: [
        {
          label: "Item Code",
          field: "item_code",
        },
        {
          label: "Item Type",
          field: "item_type",
        },
        {
          label: "Item Name",
          field: "item_name",
        },
        {
          label: "Generic Name",
          field: "item_other_name",
        },

        {
          label: "Price",
          field: "price_inter",
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: "Quantity",
          field: "quantity",
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: 'Barcode',
          field: 'barcode',
          type: 'text',
          formatter: (value) => value.map(item => Object.values(item)).join(', ')
        }
      ],
      productsheetTitle: "PRODUCT",
      codeReader: null,               // ZXing code reader instance
      videoInputDevices: [],          // List of video input devices (cameras)
      selectedDeviceId: '',           // ID of the selected camera
      scanResult: '',                 // Result of QR/Barcode scan
      barcodes: [],                   // Data barcodes 
      stream: null,
    }
  },
  firestore() {
    return {
      medicines: db.collection("Product"),
    }
  },
  mounted() {
    
    
  },
  watch: {
    'scanResult': function (val) {
      this.barcode.barcode = val

    },
    'barcode.item_name': function (newItemName) {
      const selectedMedicine = this.medicines.find(medicine => medicine.item_name === newItemName)
      if (selectedMedicine) {
        this.barcode.item_code = selectedMedicine.item_code
        this.barcode.item_other_name = selectedMedicine.item_other_name || ''
      } else {
        this.barcode.item_code = ''
        this.barcode.item_other_name = ''
      }
    },
  },
  methods: {
    registerBarcode(params) {
      this.barcode = {}
      this.$bvModal.show("register-barcode-modal")
    },
    async saveBarcode() {
      let loader = this.$loading.show({})

      if (!this.barcode.item_code) {
        this.$alert("please enter item code", null, "error")
        loader.hide()
        return
      }
      const result = await db.collection('Product').doc(this.barcode.item_code).get()
      const data = result.data()
      if ('barcode' in data) {
        if (!data.barcode.includes(this.barcode.barcode)) {
          data.barcode.push(this.barcode.barcode)
        }

      }
      else {
        data.barcode = [this.barcode.barcode]

      }
      loader.hide()
      this.$alert("success", null, "success")
      this.$bvModal.hide("register-barcode-modal")
      try {
        await db.collection('Product').doc(this.barcode.item_code).set(data)

      } catch (error) {
        console.log(error)

      }
    },
    CSVToArray(strData, strDelimiter) {

      strDelimiter = (strDelimiter || ",")

      var objPattern = new RegExp(
        (
          // Delimiters.
          "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
          // Quoted fields.
          "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
          // Standard fields.
          "([^\"\\" + strDelimiter + "\\r\\n]*))"
        ),
        "gi"
      )
      var arrData = [[]]
      var arrMatches = null
      while (arrMatches = objPattern.exec(strData)) {
        var strMatchedDelimiter = arrMatches[1]
        if (
          strMatchedDelimiter.length &&
          strMatchedDelimiter !== strDelimiter
        ) {
          arrData.push([])
        }
        var strMatchedValue;
        if (arrMatches[2]) {
          strMatchedValue = arrMatches[2].replace(
            new RegExp("\"\"", "g"),
            "\""
          )
        } else {
          strMatchedValue = arrMatches[3]
        }
        arrData[arrData.length - 1].push(strMatchedValue)
      }
      return (arrData)
    },
    csvArrayToObj(csvData) {
      return csvData
        .map((csvLine, csvIndex) => {
          if (csvIndex === 0 || !csvLine.length) return null // skip header and empty lines
          return csvLine.reduce((a, v, i) => ({ ...a, [csvData[0][i]]: v }), {})
        })
        .filter((filter) => !!filter) //filter empty lines
    },
    exportCsv(datas, name) {
      try {
        const selectedData = datas.map((data) => ({
          Item_code: data.item_code,
          Item_name: data.item_name,
          Generic_name: data.item_other_name,
          Item_type: data.item_type,
          Price_th: data.price_th,
          Price_inter: data.price_inter,
          Price_insurance: data.price_insurance,
          Quantity: data.quantity,
        }))
        const parser = new Parser()
        const myData = JSON.parse(JSON.stringify(selectedData))
        const csv = parser.parse(myData)
        const anchor = document.createElement("a")
        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
        anchor.target = "_blank"
        anchor.download = "data_" + name + ".csv"
        anchor.click()
        anchor.remove()
      } catch (err) {
        console.error(err)
      }
    },
    async uploadCsv() {
      let promise = new Promise((resolve, reject) => {
        const reader = new FileReader()
        const fileSelector = document.getElementById("products-csv")
        const file = fileSelector.files[0]
        reader.onload = (e) => {
          resolve((this.productsFileData = reader.result))
        }
        reader.onerror = reject
        reader.readAsText(file)
      })
      promise.then(async (result) => {
        this.preparedProducts = this.CSVToArray(this.productsFileData)
        this.preparedProducts = this.csvArrayToObj(this.preparedProducts)
        for (const product of this.preparedProducts) {
          const quantity = Number(product.Quantity) || 0
          if (quantity <= 0) return

          const result = await db.collection('Product').doc(product.Item_code).get()
          const data = result.data()
          const updatedQuantity = (Number(data.quantity) || 0) + quantity
          const payload = {
            ...data,
            quantity: updatedQuantity
          }

          try {
            await db.collection('Product').doc(product.Item_code).set(payload)
          } catch (error) {
            console.log(error)
          }
        }


      })

    },
    showProduct(params) {
      if (params) {
        //console.log({ params })
        this.product = {
          id: params.row.id,
          ...params.row,
        };
        this.isEditProduct = true
      } else {
        this.isEditProduct = false
        this.product = {}
      }
      this.$bvModal.show("product-modal")
    },
    async saveProduct() {
      let loader = this.$loading.show({})
      if (!this.product.item_code || !this.product.item_name) {
        this.$alert("please enter item code and item name", null, "error")
        loader.hide()
      }

      try {
        let product = { ...this.product }
        product.id = product.id || null
        product.cost = product.cost || null
        product.price_insurance = product.price_insurance || null
        product.price_th = product.price_th || null
        product.price_inter = product.price_inter || null
        product.item_other_name = product.item_other_name || null
        product.item_type = product.item_type || null
        product.quantity = product.quantity || null

        await db.collection("Product").doc(product.item_code).set(product)
        loader.hide()
        this.$alert("success", null, "success")
      } catch (error) {
        this.$alert(`error ${error}`, null, "error")
        loader.hide()
      }
    },
    async initializeCodeReader() {
      try {
        this.codeReader = new BrowserMultiFormatReader()
        //console.log('ZXing code reader initialized')

        // Get video input devices
        const devices = await this.codeReader.listVideoInputDevices()
        this.videoInputDevices = devices

        // Set the default selected device to the first camera
        if (devices.length > 0) {
          this.selectedDeviceId = devices[0].deviceId
        }

        //console.log('Video input devices:', devices)
      } catch (err) {
        console.error('Error initializing the code reader:', err)
      }
    },
    // Start scanning for QR code/barcode from the selected camera
    startScan() {
      this.requestCameraPermission()
      this.initializeCodeReader()
      if (this.selectedDeviceId) {
        const constraints = {
          video: {
            deviceId: { exact: this.selectedDeviceId }, // Use the selected device
            facingMode: { ideal: 'environment' } // Prefer rear camera on mobile
          }
        }
        this.codeReader.decodeFromVideoDevice(this.selectedDeviceId, 'video', (result, err) => {
          if (result) {
            this.scanResult = result.text
            this.resetScan()
          }
        })
      } else {
        console.error('No camera selected')
      }
    },

    // Reset the scanner and clear the result
    resetScan() {
      this.beforeDestroy()
      //this.scanResult = ''
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop())
        this.stream = null;
      }

    },
    async requestCameraPermission() {
      try {
        // Request camera access explicitly
         this.stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: { ideal: 'environment' } // Rear camera
          }
        })
      } catch (error) {
        alert('Please allow camera access in your device settings or browser.')
      }
    },
    beforeDestroy() {
      if (this.codeReader) {
        this.codeReader.reset() // Reset the code reader when the component is destroyed
      }
    },
    filterProduct(options, search) {
      return options.filter(option => {
        const label = option.item_name + ' ' + option.item_other_name
        return this.filterProductBy(option, label, search)
      });
    },
    filterProductBy(_, label, search) {
      return (label || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
    },

  },
  computed: {
    prepared_products() {
      return this.medicines.map((i) => {
        return {
          id: i.id,
          item_name: i.item_name || null,
          item_other_name: i.item_other_name || null,
          item_type: i.item_type || null,
          item_code: i.item_code || null,
          price_th: Number(i.price_th) || null,
          price_inter: Number(i.price_inter) || null,
          price_insurance: Number(i.price_insurance) || null,
        }
      })
    },

    

  },
}
</script>