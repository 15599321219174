<template>
  <div class="row p-2">
    <div class="col-12">
      <h3 class="text-primary text-center">Hotel</h3>
      <hr>
    </div>
    <div class="col-12">
      <div class="d-flex">
        <label>between: </label>
        <MazPicker v-model="from" clearable no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" />
        <label>and</label>
        <MazPicker v-model="to" clearable no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" />
      </div>
    </div>
    <div class="col-6 mt-2">
      <table class="table table-bordered">
        <thead>
          <th></th>
          <th># of Patients</th>
        </thead>
        <tr v-for="(label, index) in chartData.labels" :key="`${label}${index}`">
          <td>{{ label }}</td>
          <td>{{ chartData.datasets[0].data[index] }}</td>
        </tr>
        <tr>
          <td>SUM</td>
          <td>{{ chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
        </tr>
      </table>
    </div>
    <div class="col-6 mt-2">
      <Pie :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'"
        :chart-id="'patients_number_chart'" :width="400" :height="400" />
    </div>
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'
import color from '../../assets/color.json'
import Chart from 'chart.js/auto';

export default {
  props: ['patients'],
  components: {
    Pie
  },
  data() {
    return {
      hotels: _.filter(this.$store.state.places, { type: 'hotel' }).map(c => {
        return c.name_en;
      }),
      from: this.$moment().add(-1, 'month').format('DD/MM/YYYY'),
      to: this.$moment().format('DD/MM/YYYY'),
      chartOptions: {
        responsive: true
      }
    }
  },
  computed: {
    chartData() {

      let filteredPatients = []

      let fromMoment = this.$moment(this.from, 'DD/MM/YYYY')
      let toMoment = this.$moment(this.to, 'DD/MM/YYYY')

      for(let p of this.patients) {
        if (p.created_at && typeof p.created_at !== 'string') {
          if (
              this.$moment(p.created_at.toDate()).isSameOrBefore(toMoment) && 
              this.$moment(p.created_at.toDate()).isSameOrAfter(fromMoment)
            ) filteredPatients.push(p)
        }
      }

      //label
      let labels = this.hotels
      let data = []

      for (let l of labels) {
        let count = 0
        for (let p of filteredPatients) {
          if (p.tourist_hotel === l) count++;
        }
        data.push(count)
      }


      let sortedData = []
      for(let i = 0;  i < data.length; i++) {
        sortedData.push({
          label: labels[i],
          value: data[i]
        })
      }


      return {
        labels: this._.orderBy(sortedData, 'value', 'desc').map( obj => obj.label),
        datasets: [
          {
            backgroundColor: color,
            data: this._.orderBy(sortedData, 'value', 'desc').map( obj => obj.value)
          }
        ]
      }
    }
  }
}
</script>