import { render, staticRenderFns } from "./PatientSearch.vue?vue&type=template&id=ebd368be"
import script from "./PatientSearch.vue?vue&type=script&lang=js"
export * from "./PatientSearch.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports