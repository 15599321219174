var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":_vm.id,"title":"Order Medicine","size":"xl","hide-footer":""}},[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"container-fluid"},[_vm._l((_vm.value),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"row d-flex"},[_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"label"},[_vm._v("Time")]),_c('MazPicker',{attrs:{"format":"DD/MM/YYYY HH:mm","formatted":"DD/MM/YYYY HH:mm"},model:{value:(item.med_supply_date_time),callback:function ($$v) {_vm.$set(item, "med_supply_date_time", $$v)},expression:"item.med_supply_date_time"}})],1),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"label"},[_vm._v("Medicine / Supply")]),_c('MazSelect',{attrs:{"search":"","options":[..._vm.$store.state.dd_medicine.map(item => {
                  return {
                    label: `${item.brand_name} (${item.generic_name})`,
                    value: item.brand_name
                  };
                }), {label: 'Other', value: 'Other'}]},model:{value:(item.med_supply_medicine),callback:function ($$v) {_vm.$set(item, "med_supply_medicine", $$v)},expression:"item.med_supply_medicine"}}),(item.med_supply_medicine === 'Other')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.med_supply_other_input),expression:"item.med_supply_other_input"}],staticClass:"form-control mt-2",attrs:{"type":"text","placeholder":"Other"},domProps:{"value":(item.med_supply_other_input)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(item, "med_supply_other_input", $event.target.value)}}}):_vm._e()],1),_c('div',{staticClass:"col-1"},[_c('div',{staticClass:"label"},[_vm._v("Amount")]),_c('MazInput',{attrs:{"placeholder":""},model:{value:(item.med_supply_amount),callback:function ($$v) {_vm.$set(item, "med_supply_amount", $$v)},expression:"item.med_supply_amount"}})],1),_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"label"},[_vm._v("Unit")]),_c('MazSelect',{attrs:{"placeholder":"select unit","search":"","options":_vm.units.map(item => {
                  return {
                    label: item,
                    value: item
                  };
                })},model:{value:(item.med_supply_unit),callback:function ($$v) {_vm.$set(item, "med_supply_unit", $$v)},expression:"item.med_supply_unit"}})],1),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"label"},[_vm._v("Dosage")]),_c('MazInput',{attrs:{"placeholder":"enter dosage"},model:{value:(item.med_supply_dosage),callback:function ($$v) {_vm.$set(item, "med_supply_dosage", $$v)},expression:"item.med_supply_dosage"}})],1),_c('div',{staticClass:"ml-auto col-1"},[_c('button',{staticClass:"btn btn-light",on:{"click":function($event){return _vm.value.splice(index, 1)}}},[_c('i',{staticClass:"fas fa-trash"})])])])])}),_c('button',{staticClass:"btn btn-outline-success btn-block mt-2",on:{"click":function($event){_vm.value.push({med_supply_date_time: new Date()})}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Order ")])],2)]),_c('hr'),_c('div',{staticClass:"d-flex justify-content-center"},[_c('button',{staticClass:"btn btn-primary mr-2",on:{"click":function($event){$event.preventDefault();return _vm.confirmOrder.apply(null, arguments)}}},[_vm._v("Confirm Order")]),_c('button',{staticClass:"btn btn-danger mr-2",on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.hide(_vm.id)}}},[_vm._v("Cancel")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }