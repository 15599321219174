<template>
  <b-modal :id="id" title="Order Investigation" size="full" hide-footer>
    <div class="mb-2">
      <label>Select Investigation Type:</label>
      <div class="container-fluid">
        <div class="d-flex mt-2" v-for="(item, index) in value" :key="index">
          <div class="col-2">
            <div class="label">
             Date&Time
            </div>
            <MazPicker
              v-model="item.invest_date_time"
              format="DD/MM/YYYY HH:mm"
              formatted="DD/MM/YYYY HH:mm"
            />
          </div>
          <div class="col-2">
            <div class="label">
              Investigation Type
            </div>
            <MazSelect
              v-model="item.invest_type"
              search
              :placeholder="'Type'"
              :options="
                dd_invest_type.map(item => {
                  return {
                    label: item,
                    value: item
                  };
                })
              "
            />
          </div>
          <div class="col-2" v-if="item.invest_type === 'EKG'">
            <div class="label">
              EKG Type
            </div>
            <MazSelect
              v-model="item.invest_ekg_type"
              placeholder="EKG Type"
              search
              :options="
                dd_ekg.map(item => {
                  return {
                    label: item.ekg_name,
                    value: item.ekg_name
                  };
                })
              "
            />
          </div>
          <div class="col-2" v-if="item.invest_ekg_type === 'Others'">
            <div class="label">
              Others
            </div>
            <input
              type="text"
              class="form-control"
              v-model="item.invest_ekg_type_other"
              placeholder="Others"
            />
          </div>


          <div v-if="item.invest_type === 'X-Ray'">
            <div class="label">
              Select Organ & View
            </div>
            <select
              style="width: 350px;"
              @change="getViews(index)"
              :id="'invest_organ_view_' + index"
              class="form-control"
              v-model="item.invest_organ_view"
            >
              <option value selected></option>
              <optgroup label="HEAD & NECK">
                <option
                  :value="dd.name"
                  v-for="dd in ddOrganHead"
                  :key="dd.id"
                  >{{ dd.name }}</option
                >
              </optgroup>
              <optgroup label="CHEST">
                <option
                  :value="dd.name"
                  v-for="dd in ddOrganChest"
                  :key="dd.id"
                  >{{ dd.name }}</option
                >
              </optgroup>
              <optgroup label="ABDOMEN">
                <option
                  :value="dd.name"
                  v-for="dd in ddOrganAbdomen"
                  :key="dd.id"
                  >{{ dd.name }}</option
                >
              </optgroup>
              <optgroup label="EXTREMITIES">
                <option
                  :value="dd.name"
                  v-for="dd in ddOrganExtrem"
                  :key="dd.id"
                  >{{ dd.name }}</option
                >
              </optgroup>
              <optgroup label="SPINE">
                <option
                  :value="dd.name"
                  v-for="dd in ddOrganSpine"
                  :key="dd.id"
                  >{{ dd.name }}</option
                >
              </optgroup>
            </select>
            <div class="mt-1">
              <div
                class="form-check form-check-inline ml-3"
                v-for="dd in item.show_organ_view"
                :key="dd.id"
              >
                <label class="form-check-label">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="dd"
                    :checked="item.views.includes(dd)"
                    v-model="item.views"
                  />
                  <span>{{ dd }}</span></label
                >
              </div>
            </div>
            <div class="mt-1">
              <input
                type="text"
                class="form-control"
                v-if="item.invest_organ_view === 'Other'"
                placeholder="Other"
                v-model="item.invest_organ_view_other"
              />
            </div>
          </div>
          <div
            class="ml-1"
            v-if="item.invest_type === 'X-Ray'"
            style="min-width: 156px;"
          >
            <div class="label">
              Select Side
            </div>
            <select
              :id="'invest_side_' + index"
              class="form-control"
              v-model="index.invest_side"
            >
              <option value
                >No side select</option
              >
              <option value="Rt."
                >Rt.</option
              >
              <option value="Lt."
                >Lt.</option
              >
              <option value="Lt. & Rt."
                >Lt. & Rt.</option
              >
            </select>
          </div>
          <div
            class="ml-2"
            v-if="item.invest_type === 'X-Ray'"
          >
            <div class="label">Detail</div>
            <MazInput
              placeholder="detail"
              v-model="item.invest_detail"
            ></MazInput>
          </div>


          <div class="col-1 ml-auto">
            <button class="btn btn-light" @click.prevent="value.splice(index, 1);"><i class="fas fa-trash"></i></button>
          </div>
        </div>
        <button class="btn btn-outline-success btn-block mt-2" @click.prevent="value.push({invest_date_time: new Date()})">
          <i class="fas fa-plus"></i> Order
        </button>
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary mr-2" @click.prevent="confirmOrder">Confirm Order</button>
      <button class="btn btn-danger mr-2" @click.prevent="$bvModal.hide(id)">Cancel</button>
    </div>
  </b-modal>
</template>

<script>
import dd_ekg from "../../../assets/ekg.json";
import dd_organ_view from '../../../assets/organview.json'
import dd_invest_lab from "../../../assets/invest_lab.json";
const invest_type = ["EKG", "X-Ray"];
export default {
  data() {
    return {
      id: 'order-investigation',
      dd_organ_view,
      dd_invest_lab,
      dd_ekg,
      dd_invest_type: invest_type
    }
  },
  props: {
    onClose: {
      default: () => {}
    },
    onConfirm: {
      default: () => {}
    },
    value: {
      default: () => {
        return [{invest_date_time: new Date()}]
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === this.id)
      this.onClose()
    })
  },
  methods: {
    getViews(index) {
      if (this.value[index].invest_organ_view) {
        let res = dd_organ_view.filter(
          f => f.name === this.value[index].invest_organ_view
        );
        this.value[index].show_organ_view = res[0].views;
        this.value[index].views = res[0].views;
        this.value[index].is_side = res[0].is_side;
      }
    },
    confirmOrder() {
      this.$bvModal.hide(this.id)
      this.onConfirm()
    }
  },
  computed: {
    ddOrganHead() {
      let res = dd_organ_view.filter(f => f.group === "HEAD & NECK");
      return res;
    },
    ddOrganChest() {
      let res = dd_organ_view.filter(f => f.group === "CHEST");
      return res;
    },
    ddOrganAbdomen() {
      let res = dd_organ_view.filter(f => f.group === "ABDOMEN");
      return res;
    },
    ddOrganExtrem() {
      let res = dd_organ_view.filter(f => f.group === "EXTREMITIES");
      return res;
    },
    ddOrganSpine() {
      let res = dd_organ_view.filter(f => f.group === "SPINE");
      return res;
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.label-b {
  color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}

.label-h {
  // color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}

.form-control {
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
  height: 2.855rem;
  min-height: 2.855rem;
  margin-top: 0.032rem;
  border-color: #eee;
}
</style>