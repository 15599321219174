<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <b-nav tabs>
          <b-nav-item   
            :active="$route.meta.parent === 'Staff'"
            :to="{ name: 'Staff Summary' }">Staff</b-nav-item>
          <b-nav-item   
            :active="$route.meta.parent === 'Role'"
            :to="{ name: 'Role Summary' }">Role</b-nav-item>
        </b-nav>
        <router-view class="mt-2"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted(){
    console.log(this.$route)
  }
}
</script>


