<template>
  <div>    
    <expense-graph :expenses="filteredItems"></expense-graph>
  </div>
</template>

<script>
import {db} from '../../db'
export default {
  props: ['filter'],
  firestore() {
    return {
      expenses: db.collection('Expense').orderBy("created_at", "desc")
    }
  },
  data() {
    return {
      expenses: []
    }
  },
  computed: {
    filteredItems() {
      let from = this.$moment(this.filter.from, 'DD/MM/YYYY')
      let to = this.$moment(this.filter.to, 'DD/MM/YYYY')

      return this._.filter(this.expenses, item => {
        if (item.issue_date) {
          return this.$moment(item.issue_date.toDate()).isAfter(from.startOf('day')) && this.$moment(item.issue_date.toDate()).isBefore(to.endOf('day'))
        } else {
          return this.$moment(item.created_at.toDate()).isAfter(from.startOf('day')) && this.$moment(item.created_at.toDate()).isBefore(to.endOf('day'))
        }
      })
    }
  }
}
</script>