<template>
  <div class="container-fluid">
    <div class="bg-gray">
      <div class="p-3">
        <div>
          <span class="label-h"
            >Advice
            <a href="" title="Select Templade"
              ><i
                class="fas fa-file-medical text-primary ml-5"
                style="font-size: 30px;"
                aria-hidden="true"
              ></i></a
          ></span>
          <textarea
            class="form-control mt-2"
            id="advice"
            v-model="value.advice"
          ></textarea>
        </div>
        <div class="mt-3">
          <span class="label-h mt-2">Against Medical Advice</span>
          <div class="d-flex">
            <div class="flex-fill">
              <div class="label">Detail</div>
              <input
                id="againt_med_detail"
                type="text"
                class="form-control"
                v-model="value.againt_med_detail"
              />
            </div>
            <div class="pl-2 flex-fill">
              <div class="label">Reason</div>
              <input
                id="againt_med_reason"
                type="text"
                class="form-control"
                v-model="value.againt_med_reason"
              />
            </div>
          </div>
        </div>
        <div class="mt-3">
          <span class="label-h">Recommendation</span>
          <div class="d-flex mt-2 ">
            <div style="min-width: 200px;">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="is_recommend_rest"
                  :value="value.is_recommend_rest"
                  v-model="value.is_recommend_rest"
                />
                <label class="form-check-label ml-2" for="is_recommend_rest"
                  >Rest</label
                >
              </div>
            </div>
            <div class="pl-2">
              <div class="form-group row">
                <div class="col-xl-9">
                  <input
                    type="text"
                    class="form-control"
                    id="recommend_rest"
                    v-model="value.recommend_rest"
                  />
                </div>
                <label class="col-xl-3 col-form-label">days</label>
              </div>
            </div>
            <div class="pl-2">
              <input
                type="text"
                class="form-control"
                id="recommend_days"
                placeholder="auto date complete"
                v-model="value.recommend_days"
              />
            </div>
          </div>
          <div class="d-flex">
            <div style="min-width: 200px;">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="is_recommend_wound_dressing"
                  v-model="value.is_recommend_wound_dressing"
                />
                <label
                  class="form-check-label ml-2"
                  for="is_recommend_wound_dressing"
                  >Wound dressing</label
                >
              </div>
            </div>
            <div class="pl-2">
              <select v-model="value.recommend_wound_dressing" class="form-control">
                <option value=""></option>
                <option value="once a day">once a day</option>
                <option value="twice daily">twice daily</option>
                <option value="every 2 days">every 2 days</option>
                <option value="every 3 days">every 3 days</option>
                <option value="every 5 days">every 5 days</option>
                <option value="once a week">once a week</option>
              </select>
            </div>
          </div>
          <div class="d-flex mt-2">
            <div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="is_recommend_wound_dressing_other"
                  v-model="value.is_recommend_wound_dressing_other"
                />
                <label
                  class="form-check-label ml-2"
                  for="is_recommend_wound_dressing_other"
                  >Other</label
                >
              </div>
            </div>
            <div
              class="pl-4"
              style="min-width: 500px;"
              v-if="value.is_recommend_wound_dressing_other"
            >
              <div
                class="d-flex mt-1"
                v-for="(i, index) in value.recommend_wound"
                :key="index"
              >
                <div class="col-xl-9">
                  <select v-model="value.recommend_wound[index].recommend_wound_detail" class="form-control">
                    <option value></option>
                    <option :value="dd.name" v-for="dd in dd_followp_recommend" :key="dd.id">{{ dd.name }}</option>
                  </select>
                  <!-- <input type="text" class="form-control" v-model="value.recommend_wound_detail"> -->
                </div>
                <div class="col-xl-3">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="addChildRowList('recommend_wound', 1)"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary ml-2"
                    @click="removeChildRowList('recommend_wound', index)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="is_re_visit"
                v-model="value.is_re_visit"
              />
              <label class="form-check-label ml-2" for="is_re_visit"
                >Re-visit doctor as soon as possible if condition doesn't get
                better or is getting worse.</label
              >
            </div>
          </div>
          <div class="mt-3">
            <span class="label-h">Follow-Up</span>
            <div class="d-flex mt-2">
              <div>
                <div class="form-check">
                  
                  <input
                    class="form-check-input"
                    type="radio"
                    name="is_follow_up"
                    id="is_follow_up_yes"
                    value="yes"
                    v-model="value.is_follow_up"
                  />
                  <label
                    class="form-check-label ml-2 mt-1"
                    for="is_follow_up_yes"
                  >
                    YES
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="is_follow_up"
                    id="is_follow_up_no"
                    value="no"
                    v-model="value.is_follow_up"
                  />
                  <label
                    class="form-check-label ml-2 mt-1"
                    for="is_follow_up_no"
                  >
                    NO
                  </label>
                </div>
              </div>
              <div class="pl-5" style="min-width: 200px;">
                <flat-pickr
                  class="form-control border-left-0 border-right-0"
                  :config="$store.state.stdDateConfig"
                  style="background-color: #fff;"
                  placeholder="Date"
                  id="follow_up_date"
                  v-model="value.follow_up_date"
                  v-if="value.is_follow_up === 'yes'"
                ></flat-pickr>
                <flat-pickr
                  class="form-control border-left-0 border-right-0"
                  :config="$store.state.stdTimeConfig"
                  style="background-color: #fff;"
                  placeholder="Time"
                  id="follow_up_time"
                  v-model="value.follow_up_time"
                  v-if="value.is_follow_up === 'yes'"
                ></flat-pickr>
              </div>
              <div class="pl-2" style="min-width: 200px;">
                <select
                  id="follow_up_department"
                  class="form-control"
                  v-model="value.follow_up_department"
                  v-if="value.is_follow_up === 'yes'"
                >
                  <option value disabled selected hidden>Department</option>
                  <option>OPD</option>
                  <option>ER</option>
                </select>
                <select
                  id="follow_up_doctor"
                  class="form-control"
                  v-model="value.follow_up_doctor"
                  v-if="value.is_follow_up === 'yes'"
                >
                  <option value disabled selected hidden>Doctor</option>
                  <option>Dr. Aphilak</option>
                  <option>Dr. Santisuk</option>
                  <option>Dr. Watcharobon</option>
                  <option>Other</option>
                </select>
              </div>
              <div class="flex-fill pl-5" v-if="value.is_follow_up === 'yes'">
                <div
                  class="d-flex mt-1"
                  v-for="(i, index) in value.reason_list"
                  :key="index"
                >
                  <div class="flex-1">
                    <p>Reason</p>
                    <p>Follow-Up plan</p>
                  </div>
                  <div class="flex-4">
                    <select
                      :id="'reason_list_reason_' + index"
                      class="form-control"
                      v-model="value.reason_list[index].reason"
                    >
                      <option value disabled selected hidden></option>
                      <option>A</option>
                      <option>B</option>
                      <option>C</option>
                    </select>
                    <input
                      class="form-control"
                      type="text"
                      :id="'reason_list_plan_' + index"
                      v-model="value.reason_list[index].plan"
                    />
                  </div>
                  <div class="flex-1 pl-2 mt-auto mb-auto">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="addChildRowList('reason_list', 1)"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary ml-2"
                      @click="removeChildRowList('reason_list', index)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex mt-2">
              <div style="min-width: 500px;">
                <label for="plan_of_treatment" class="label-h"
                  >Plan of treatment</label
                >
                <textarea
                  class="form-control"
                  id="plan_of_treatment"
                  cols="30"
                  rows="3"
                  v-model="value.plan_of_treatment"
                ></textarea>
              </div>
              <div class="pl-5" style="min-width: 500px;">
                <label for="comment" class="label-h">Comment</label>
                <textarea
                  class="form-control"
                  id="comment"
                  cols="30"
                  rows="3"
                  v-model="value.comment"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import opdMixins from "../../mixins/opd";

export default {
  mixins: [opdMixins],
  props: ["value"]
};
</script>
<style lang="scss" scoped>
input[type="radio"] {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

input[type="checkbox"] {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.label-h {
  // color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}

.form-check {
  margin-left: 10px;
}

button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
</style>
