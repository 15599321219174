<template>
  <div>
    <multiselect
      v-model="internalValue"
      :options="options"
      :loading="isLoading"
      :internal-search="false"
      @search-change="search"
      placeholder="Type to search patient..."
      noResult="Patient not found."
      noOptions="Type to search patient..."
      label="name"
      value="HN"
      :preserveSearch="true"
      selectLabel=""
    />
  </div>
</template>

<script>
import {db} from '../../db';

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      internalValue: this.value,
      options: [],
      isLoading: false,
    };
  },
  watch: {
    value(newVal) {
      // Update the internalValue when the v-model value changes externally
      this.internalValue = newVal;
    },
    internalValue(newVal) {
      // Emit the updated internalValue to the parent component using v-model
      this.$emit("input", newVal);
    },
  },
  methods: {
    search(query) {
      if (query.length > 2) {
        this.isLoading = true;
        db.collection("Patient")
          .where("first_name", ">=", query)
          .where("first_name", "<=", query + "\uf8ff")
          // .wher("last_name", ">=", query)
          // .where("last_name", "<=", query + "\uf8ff")
          .get()
          .then((querySnapshot) => {
            let response = querySnapshot
            this.options = response.docs.map((item) => ({
              name: item.data().first_name + " " + item.data().last_name,
              HN: item.data().HN
            }));
            this.isLoading = false;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            this.isLoading = false;
          });
      } else {
        this.options = [];
      }
    }
  },
};
</script>

<style>
  /* Customize styles as needed */
</style>