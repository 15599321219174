<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <div class="d-flex">
          <div class="mr-auto">
            <h3 class="text-primary">Worldmed beachfront Phiphi</h3>
            <h3 class="text-primary">DASHBOARD</h3>
            <h3 class="text-primary">OPD Colorectal</h3>
          </div>
          <div class="timer">
            <h5 class="text-primary text-center">
              {{ timer | moment("dddd") }}
            </h5>
            <h1 class="text-primary text-center">
              {{ timer | moment("HH:mm A") }}
            </h1>
            <div class="text-primary text-center">
              {{ timer | moment("DD/MMMM/YYYY") }}
            </div>
          </div>
        </div>
        <hr />
        <div class="d-flex justify-content-center">
          <div
            class="dashboard-menu-item mr-2"
            @click="$router.push({ name: 'Patient Queue' })"
          >
            <div
              class="dashboard-menu-item-icon"
              :class="{ active: $route.name === 'Patient Queue' }"
            >
              <i class="fa-stack fa-2x">
                <i class="fas fa-sync-alt fa-stack-2x"></i>
                <i class="fas fa-user fa-stack-1x"></i>
              </i>
            </div>
            <h5>Patient Queue</h5>
          </div>
          <div
            class="dashboard-menu-item mr-2"
            @click="$router.push({ name: 'Appointments' })"
          >
            <div
              class="dashboard-menu-item-icon"
              :class="{ active: $route.name === 'Appointments' }"
            >
              <i class="fas fa-clock fa-lg fa-5x"></i>
            </div>
            <h5>Appointments</h5>
          </div>
          <div
            class="dashboard-menu-item mr-2"
            @click="$router.push({ name: 'Finish Case' })"
          >
            <div
              class="dashboard-menu-item-icon"
              :class="{ active: $route.name === 'Finish Case' }"
            >
              <i class="fas fa-home fa-lg fa-5x"></i>
            </div>
            <h5>Finish case</h5>
          </div>
          <div
            class="dashboard-menu-item"
            @click="$router.push({ name: 'Against Advice' })"
          >
            <div
              class="dashboard-menu-item-icon"
              :class="{ active: $route.name === 'Against Advice' }"
            >
              <i class="fa-stack fa-2x">
                <i class="far fa-comment fa-stack-2x"></i>
                <i class="fas fa-times fa-stack-1x"></i>
              </i>
            </div>
            <h5>Against Advice</h5>
          </div>
        </div>

        <router-view :visits="visits"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import {db} from '../../db'
export default {
  data() {
    return {
      timer: new Date(),
      visits: []
    };
  },
  async mounted() {
    var timerID = setInterval(this.updateTime, 60000);
    this.visits = await this.getVisits()
  },
  methods: {
    updateTime() {
      this.timer = new Date();
    },

    async getVisits() {
      let visits = []

      let loader = this.$loading.show({});
      let visitsRef = await db.collection('Visit').get()

      visits = await Promise.all(visitsRef.docs.map(async visit => {

        let visitId = visit.id

        let patientId = visit.data().patient.id
        let patientRef = await db.collection('Patient').doc(patientId).get()

        let visit_date = ''
        if(visit.data().visit_date) {
          if(typeof visit.data().visit_date === 'object')
            visit_date = this.$moment(visit.data().visit_date.toDate()).format('DD/MM/YYYY HH:mm')
          else
            visit_date = visit.data().visit_date
        }
        
        return {
          HN: patientRef.data().HN,
          phone_th: patientRef.data().phone_th,
          phone_inter: patientRef.data().phone_inter,
          email: patientRef.data().email,
          visitId: visitId,
          VN: visitId,
          name: `${patientRef.data().first_name} ${patientRef.data().last_name}`,
          visit_date,
          patient_status: {
            "status": "pending",
            "staff": "",
            "progress": 1
          },
          tasks: {
            "REG": true,
            "RN": false,
            "DR": false,
            "LAB": false,
            "MEDS": false,
            "XRAY": false,
          },
          esi: null
        }
      }))


      let nursingAssessmentRef = await db.collection('NursingAssessment').get()

      for(let na of nursingAssessmentRef.docs) {
        for(let visit of visits) {
          let naVisitId = na.data().visit.id
          if(visit.visitId===naVisitId) {
            visit.tasks.RN = true

            if(typeof na.data().esi_level==='string') {
              switch (na.data().esi_level) {
                case 'Super red':
                  visit.esi = 1
                  break;
                case 'Red':
                  visit.esi = 2
                  break;
                case 'Yellow':
                  visit.esi = 3
                  break;
                case 'Green':
                  visit.esi = 4
                  break;
                case 'White':
                  visit.esi = 5
                  break;
              }
            }else{
              visit.esi = na.data().esi_level
            }
          }
        }
      }

      let opdRef = await db.collection('OpdCard').get()

      for(let opd of opdRef.docs) {
        for(let visit of visits) {
          let opdVisitId = opd.data().visit.id
          if(visit.visitId===opdVisitId) {
            visit.tasks.DR = true
            visit.follow_up = opd.data().follow_up
            visit.cc = opd.data().cc_name
            visit.dx = opd.data().diagnosis.diagnosis_name
          }
        }
      }

      loader.hide()

      const sortVisits  = visits.sort((a,b) => new this.$moment(b.visit_date).format('YYYYMMDDHHmm') - new this.$moment(a.visit_date).format('YYYYMMDDHHmm'))

      return sortVisits
    },
  }
};

function zeroPadding(num, digit) {
  var zero = "";
  for (var i = 0; i < digit; i++) {
    zero += "0";
  }
  return (zero + num).slice(-digit);
}
</script>

<style lang="scss" scoped>
.container-fluid {
  margin-bottom: 50px;
}
.timer {
  border-radius: 0.25em;
  padding: 15px;
  min-width: 300px;
  height: 150px;
  box-shadow: 2px 2px 10px #bfbfbf;
}

.dashboard-menu-item {
  border-radius: 0.25em;
  padding: 15px;
  text-align: center;
  color: #999999;
  cursor: pointer;
}

.dashboard-menu-item-icon {
  border: 2px solid #999999;
  border-radius: 0.25em;
  padding: 25px;
  margin-bottom: 10px;
}

.dashboard-menu-item > .active {
  color: white !important;
  background-color: #0b97e5 !important;
  border: 2px solid #0b97e5 !important;
}
</style>
