var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card shadow mt-2"},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"text-center",class:_vm.$store.state.branch == 3 ? 'text-wa' : 'text-primary'},[_vm._v("Visit Summary")]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-md-8 offset-md-2 col-sm-12"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.triggerKeywordSearch.apply(null, arguments)}}},[_c('div',{staticClass:"input-group mt-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword),expression:"keyword"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Search patient by first name, last name, passport id","readonly":_vm.isLoading},domProps:{"value":(_vm.keyword)},on:{"input":function($event){if($event.target.composing)return;_vm.keyword=$event.target.value}}}),_vm._m(0)])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"d-flex mx-auto gap-x-2 mt-2 mx-2"},[(_vm.$store.state.branch == 3)?_c('div',{staticClass:"span3 text-center"},[_c('MazSelect',{staticStyle:{"z-index":"10000"},attrs:{"placeholder":"select branch","options":[
                { label: _vm.$store.state.branches[2].name_en, value: Number(3) },                  
              ]},on:{"change":_vm.handleFilterBranch},model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}})],1):_c('div',{staticClass:"span3 text-center"},[_c('MazSelect',{staticStyle:{"z-index":"10000"},attrs:{"placeholder":"select branch","options":[
                { label: 'All', value: 'all' },
                ..._vm.$store.state.branches.filter(b=> b.id !== '3').map(b => {
                  return {
                    label: `${b.name_en}`,
                    value: Number(b.id)
                  };
                })
              ]},on:{"change":_vm.handleFilterBranch},model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}})],1),_c('div',{staticClass:"span3 text-center mx-2"},[_c('MazSelect',{staticStyle:{"z-index":"10000"},attrs:{"placeholder":"select date filter type","options":[
                { label: 'Search by day', value: 'by-day' },
                { label: 'Search by range', value: 'by-range' },
              ]},on:{"change":_vm.handleFilterDateSearchType},model:{value:(_vm.search_date_type),callback:function ($$v) {_vm.search_date_type=$$v},expression:"search_date_type"}})],1),(_vm.search_date_type === 'by-day')?_c('div',{staticClass:"span3 text-center d-flex mx-3"},[_c('button',{staticClass:"btn btn-primary mx-1",on:{"click":function($event){$event.preventDefault();return _vm.triggerSearchDate(-1)}}},[_c('i',{staticClass:"fa fa-angle-left",attrs:{"aria-hidden":"true"}})]),_c('MazPicker',{staticStyle:{"z-index":"10000"},attrs:{"no-time":"","format":"DD/MM/YYYY","formatted":"DD/MM/YYYY","placeholder":"Period Date"},model:{value:(_vm.search_date_value),callback:function ($$v) {_vm.search_date_value=$$v},expression:"search_date_value"}}),_c('button',{staticClass:"btn btn-primary mx-1",on:{"click":function($event){$event.preventDefault();return _vm.triggerSearchDate(1)}}},[_c('i',{staticClass:"fa fa-angle-right",attrs:{"aria-hidden":"true"}})])],1):_vm._e(),(_vm.search_date_type === 'by-range')?_c('div',{staticClass:"span3 text-center"},[_c('MazPicker',{staticStyle:{"z-index":"10000"},attrs:{"no-time":"","format":"DD/MM/YYYY","formatted":"DD/MM/YYYY","placeholder":"Period Date Range","range":""},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}})],1):_vm._e()])]),_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"btn btn-primary mx-auto mt-4 px-4",on:{"click":function($event){$event.preventDefault();return _vm.triggerInitData.apply(null, arguments)}}},[_c('h5',[_vm._v("Search")])])]),_c('visit-table',{ref:"visitTableComponent",attrs:{"dateStart":_vm.search_date_type === 'by-day' ? _vm.search_date_value : _vm.filterDate.start,"dateEnd":_vm.search_date_type === 'by-day' ? _vm.search_date_value : _vm.filterDate.end,"keyword":_vm.keyword,"branch":_vm.branch,"mode":_vm.mode},on:{"onLoading":function($event){_vm.isLoading=true},"onLoaded":function($event){_vm.isLoading=false}}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","aria-expanded":"false"}},[_c('i',{staticClass:"fas fa-search"})])])
}]

export { render, staticRenderFns }