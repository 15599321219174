<template>
    <div class="container-fluid">
        <div class="d-flex mt-2 mb-2">
            <h3 class="text-primary">Insurance</h3>

            <button class="btn btn-primary ml-auto" @click="showInsurance()">
                <i class="fas fa-plus"></i> Insurance
            </button>
        </div>

        <vue-good-table :columns="InsuranceColumns" :rows="insurances" @on-row-click="showInsurance"
            :pagination-options="{
                enabled: true,
                mode: 'pages'
            }" :search-options="{
                enabled: true,
            }" />

        <b-modal id="insurance-item-modal" hide-footer size="lg" :title="'Insurance'">
            <div class="row">
                <div class="col-12 mt-2">
                    <MazInput :placeholder="'Id'" v-model="insurance.id" />
                </div>
                <div class="col-12 mt-2">
                    <MazInput :placeholder="'Item name'" v-model="insurance.name_en" />
                </div>
                <div class="col-12 mt-2">
                    <MazSelect v-model="insurance.type" label="Item type"
                        :options="[{ label: 'Inter', value: 'Inter' }, { label: 'Local', value: 'Local' }, { label: 'Other', value: 'Other' }]" />
                </div>
                <div class="d-flex justify-content-end mt-2 col-12">
                    <div class="p-2">
                        <button class="btn btn-primary btn-block" @click="saveInsurance">
                            <i class="fas fa-save"></i> Save
                        </button>
                    </div>
                    <div class="p-2" v-if="isEditInsurance">
                        <button class="btn btn-danger btn-block" @click="deleteInsurance(insurance.id)">
                            <i class="fas fa-trash"></i> Delete Insurance
                            {{ deleteInsurance.id }}
                        </button>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { db, Timestamp } from "../../db"

export default {
    data() {
        return {
            insurances: [],
            insurance: {},
            isEditInsurance: false,

            InsuranceColumns: [
                {
                    label: "Id",
                    field: "id",
                },
                {
                    label: "name",
                    field: "name_en",
                },
                {
                    label: "type",
                    field: "type",
                },
            ],
        }
    },
    firestore() {
        return {
            insurances: db.collection("Insurance").withConverter({toFirestore: (data) => data,fromFirestore: (snap) => ({ ...snap.data(), id: snap.id })})             
        }            
    },

methods: {
    showInsurance(params) {        
        if (params) {
            this.insurance = { ...params.row }
            this.isEditInsurance = true
        } else {
            this.isEditInsurance = false
            this.insurance = {}
        }
        this.$bvModal.show("insurance-item-modal")
    },
        async saveInsurance() {
        let loader = this.$loading.show({})
        if (!this.insurance.id || !this.insurance.name_en) {
            this.$alert("please enter id and name", null, "error")
            loader.hide()
        }
        try {            
            let insurance = { ...this.insurance }
            await db
                .collection("Insurance")
                .doc(this.insurance.id)
                .set(insurance)
            loader.hide()
            this.$alert("success", null, "success")
            this.$bvModal.hide("insurance-item-modal")
        } catch (error) {
            this.$alert(`error ${error}`, null, "error")
            loader.hide()
        }
    },
    deleteInsurance(id) {
        this.$confirm("do you want to delete this insurance?").then(
            async () => {
                let loader = this.$loading.show({})
                await db.collection("Insurance").doc(id).delete()

                loader.hide()
                this.$bvModal.hide("insurance-item-modal")

                this.$alert(`insurance ${id} was deleted.`, null, "success")
            }
        )
    },
},

}
</script>