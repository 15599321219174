<template>
  <div class="mt-4">
    <div class="d-flex mb-2">
      <h3 class="text-primary">Payment</h3>
    </div>
    <div class="mt-3">
      <payment-table :data="payments" @displayPaymentModal="showPayment" />
    </div>


  </div>
</template>

<script>
import { db } from '../../db'
import { capitalizeFirstChar } from '../../helper'
import moment from 'moment'
export default {
  props: ['start', 'end'],
  data() {
    return {
      payments: [],
    }
  },
  mounted() {
    this.fetchPayments()
  },
  methods: {
    async fetchPayments() {
      const loader = this.$loading.show({})
      const start = this.$moment(this.start, 'DD/MM/YYYY').startOf('day').toDate()
      const end = this.$moment(this.end, 'DD/MM/YYYY').endOf('day').toDate()
      const payments = []

      const paymentsCollection = await db.collection('Payment')
        .where('issue_date', '>', start)
        .where('issue_date', '<', end)
        .get()

      const isBranch03 = this.$store.state.branch === 3
      const filter_payment = paymentsCollection.docs.filter(doc => {
        const idPrefix = doc.data().payment_id.slice(0, 2)
        return isBranch03 ? idPrefix === "03" : idPrefix !== "03"
      })      

      filter_payment.forEach(paymentCollection => {
        const payment = paymentCollection.data()
        const preparedPayments = []
        if (payment.cash > 0) preparedPayments.push({ name: 'Cash', amount: payment.cash })
        if (payment.credit > 0) preparedPayments.push({ name: `Credit ${payment.credit_bank_name ? capitalizeFirstChar(payment.credit_bank_name) : ''}`, amount: payment.credit })
        if (payment.insurance > 0) preparedPayments.push({ name: 'Insurance', amount: payment.insurance })
        if (payment.internet_banking > 0) preparedPayments.push({ name: 'Internet banking', amount: payment.internet_banking })
        if (payment.other > 0) preparedPayments.push({ name: payment.other_name ? capitalizeFirstChar(payment.other_name) : 'Other', amount: payment.other })

        payment.total_amount = _.sum([payment.cash, payment.credit, payment.other, payment.insurance, payment.internet_banking])
        const rawString = payment.VN
        const dateString = rawString.substring(rawString.lastIndexOf('-') + 1)
        const visit_date = moment(`${parseInt(dateString.substring(0, 2))}/${dateString.substring(2, 4)}/${dateString.substring(4)}`,"DD/MM/YYYY")
        payment.visit_date = visit_date                
        payment.preparedPayments = preparedPayments

        payments.push(payment)
      })
            
      const paymentsByDate = _.groupBy(payments, payment => this.$moment(payment.issue_date.toDate()).format("DD MMM YYYY"))
      this.payments = Object.entries(paymentsByDate).map(([date, groupedPayments]) => ({
        mode: 'span',
        label: date,
        html: false,
        children: groupedPayments,
      }))

      loader.hide()
    },
    showPayment(paymentId) {
      this.$emit('displayPaymentModal', paymentId)
    },



  },
  watch: {
    start() {
      this.fetchPayments()

    },

  }
}
</script>