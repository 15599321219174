var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.visits,"pagination-options":{
      enabled: true
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.label == 'No.')?_c('div',[_vm._v(" "+_vm._s(props.row.originalIndex + 1)+" ")]):(props.column.label == 'Regis. Time')?_c('div',[_vm._v(" "+_vm._s(props.row.visit_date)+" ")]):(props.column.label == 'Patient Name ESI HN/VN/AN')?_c('div',[_c('div',{staticClass:"text-dark"},[_c('b',[_vm._v(_vm._s(props.row.name))])]),_c('div',{staticClass:"text-muted"},[_c('router-link',{attrs:{"target":"_blank","to":`/patient/${props.row.HN}`}},[_c('i',[_vm._v("HN: "+_vm._s(props.row.HN))])])],1),(props.row.VN)?_c('div',{staticClass:"text-muted"},[_c('router-link',{attrs:{"target":"_blank","to":`/patient/${props.row.HN}/${props.row.VN}`}},[_c('i',[_vm._v("VN: "+_vm._s(props.row.VN))])])],1):_vm._e(),(props.formattedRow.details.appointment)?_c('div',{staticClass:"text-primary"},[_c('i',[_vm._v("Appointment: "+_vm._s(props.formattedRow.details.appointment))])]):_vm._e()]):(props.column.label == 'CC / DX')?_c('span',[(props.row.esi)?_c('div',{staticClass:"esi-box",style:({
            color: props.row.esi == 5 ? 'dark' : 'white',
            'background-color': _vm.getEsiColor(props.row.esi)
          })},[_vm._v(" ESI level "+_vm._s(props.row.esi)+" ")]):_vm._e(),_c('div',{staticClass:"text-dark"},[_c('b',[_vm._v(_vm._s(props.row.cc))]),_c('b',[_vm._v(_vm._s(props.row.pi))])])]):(props.column.field == 'tasks')?_c('div',{staticClass:"d-flex"},_vm._l((props.row.tasks),function(status,task_name){return _c('div',{key:task_name,staticClass:"task-box mr-2",style:({ color: status ? 'orange' : 'lightgrey' })},[(task_name === 'MEDS')?_c('i',{staticClass:"fas fa-capsules fa-2x"}):_vm._e(),(task_name === 'XRAY')?_c('i',{staticClass:"fas fa-x-ray fa-2x"}):_vm._e(),(task_name === 'LAB')?_c('i',{staticClass:"fas fa-flask fa-2x"}):_vm._e(),_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"target":"_blank","to":`/patient/${props.row.HN}/${props.row.VN}/opd-card`}},[(task_name === 'DR')?_c('i',{staticClass:"fas fa-user-md fa-2x"}):_vm._e()]),_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"target":"_blank","to":`/patient/${props.row.HN}/${props.row.VN}/nurse-assessment`}},[(task_name === 'RN')?_c('i',{staticClass:"fas fa-user-nurse fa-2x"}):_vm._e()]),_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"target":"_blank","to":`/patient/${props.row.HN}/details`}},[(task_name === 'REG')?_c('i',{staticClass:"fas fa-id-card fa-2x"}):_vm._e()]),_c('div',[_c('b',[_vm._v(_vm._s(task_name))])])],1)}),0):(props.column.field == 'patient_status')?_c('div',{staticClass:"d-flex",staticStyle:{"padding":"0px"}},[_c('div',{staticClass:"text-center",staticStyle:{"width":"60px"}},[_c('i',{staticClass:"fas fa-3x mr-2",class:_vm.getIcon(props.formattedRow.patient_status.status),style:({
              color: _vm.getStatusColor(props.formattedRow.patient_status.status),
              'line-height': '100px'
            })})]),_c('div',{staticClass:"patient-status-box-container"},[_c('div',{staticClass:"patient-status-box",style:({
              'background-color': _vm.getStatusColor(
                props.formattedRow.patient_status.status
              ),
              width: `${props.formattedRow.patient_status.progress * 100}px`
            })},[_c('div',[_c('b',[_vm._v(_vm._s(props.formattedRow.patient_status.status))])]),_c('div',[_c('i',[_vm._v(_vm._s(props.formattedRow.patient_status.staff))])])])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }