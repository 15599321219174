<template>
    <div class="row mt-2">
      <div class="col-md-8 col-sm-12">
        <h5 class="text-primary">Cash Type of Internet Banking</h5>
        <table class="table table-bordered table-striped">
          <thead>
            <th colspan="3" class="text-center">Local</th>
          </thead>
          <thead>
            <th class="text-center">Names</th>
            <th class="text-center">Time</th>
            <th class="text-center">Amount</th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedLocalPayments" :key="'inter'+index">
              <td><a :href="`/print/receipt/${item.payment_id}`" target="_blank" >{{item.patient_name}}</a></td>
              <td>{{item.issue_date.toDate() | moment('DD/MM/YYYY HH:mm')}}</td>
              <td class="text-right">{{Math.round(item.internet_banking).toLocaleString()}}</td>
            </tr>
            <tr>
              <td colspan="2">Total</td>
              <td class="text-right">{{Math.round(_.sumBy(_.filter(this.preparedPayments, (p) => {
                return p.p_type==='local' && p.internet_banking > 0
              }), item => item.internet_banking)).toLocaleString()}}</td>
            </tr>
          </tbody>
        </table>
        <table-pagination :items="localPayments" :onChange="(items) => { paginatedLocalPayments = items}" :perPage="5"/>
  
        <table class="table table-bordered table-striped">
          <thead>
            <th colspan="4" class="text-center">Inter</th>
          </thead>
          <thead>
            <th class="text-center">Names</th>
            <th class="text-center">Time</th>
            <th class="text-center">Country</th>
            <th class="text-center">Amount</th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedInterPayments" :key="'inter'+index">
              <td><a :href="`/print/receipt/${item.payment_id}`" target="_blank" >{{item.patient_name}}</a></td>
              <td>{{item.issue_date.toDate() | moment('DD/MM/YYYY HH:mm')}}</td>
              <td>{{item.country}}</td>
              <td class="text-right">{{Math.round(item.internet_banking).toLocaleString()}}</td>
            </tr>
            <tr>
              <td colspan="3">Total</td>
              <td class="text-right">{{Math.round(_.sumBy(_.filter(this.preparedPayments, (p) => {
                return p.p_type==='inter' && p.internet_banking > 0
              }), item => item.internet_banking)).toLocaleString()}}</td>
            </tr>
          </tbody>
        </table>
        <table-pagination :items="interPayments" :onChange="(items) => { paginatedInterPayments = items}" :perPage="5"/>
        
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="d-flex mt-2 mb-2">
          <h5 class="text-primary">Internet Banking</h5>
          <button class="btn btn-primary ml-auto" @click="$bvModal.show('cash-payment-setting')"><i class="fas fa-cog"></i></button>
        </div>
        <Doughnut :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'" :chart-id="'income-expenses'"
          :width="800" :height="400" />
  
        <internetbanking-payment-log class="mt-4" :payments="payments"></internetbanking-payment-log>
  
        <modal-graph-color-setting 
          @change="saveColor"
          v-model="colorList" 
          :id="'cash-payment-setting'" 
          :legends="legendList"/>
      </div>
    </div>
  </template>
  
  <script>
  import GraphColorSettingMixins from '../../mixins/graph-color-setting'
  import ModalGraphColorSetting from '../../components/Modal/GraphColorSetting.vue'
  import {db} from '../../db'
  import {
    Doughnut
  } from 'vue-chartjs/legacy'
  
  export default {
    props: ['payments'],
    mixins: [GraphColorSettingMixins],
    components: {
      Doughnut,
      ModalGraphColorSetting
    },
    data() {
      return {
        GRAPH_ID: 'CashPaymentGraph',
        chartOptions: {
          responsive: true,
          indexAxis: 'y',
          plugins: {
            datalabels: {
              color: '#000',
              anchor: 'center',
              align: 'center',
              formatter: value => {
                return Math.round(value).toLocaleString();
              }
            },
          }
        },
        preparedPayments: [],
        paginatedLocalPayments: [],
        paginatedInterPayments: []
      }
    },
    watch:{
      payments:{
        async handler(){
          let loader = this.$loading.show({})
  
          this.preparedPayments = await Promise.all(this.payments.map(async (item) => {
            let p = await db.collection('Patient').doc(item.HN).get()
            if (p.data().is_resident) {
              item.p_type = 'local'
            } else {
              item.p_type = 'inter'
              item.country = p.data().home_country
            }
  
            return item
          }))
  
          this.setLegendList(this.preparedData.map( item => item.label))
          this.setColorList(this.generateBackgroundColor(this.preparedData, 'label'))
  
          loader.hide()
        },
        immediate: true
      },
      colorSetting: {
        handler() {
          this.setLegendList(this.preparedData.map( item => item.label))
          this.setColorList(this.generateBackgroundColor(this.preparedData, 'label'))
        },
        immediate: true
      },
    },
    computed: {
      localPayments() {
        return this._.filter(this.preparedPayments, (p) => {
          return p.p_type==='local' && p.internet_banking > 0
        })
      },
      interPayments() {
        return this._.filter(this.preparedPayments, (p) => {
          return p.p_type==='inter' && p.internet_banking > 0
        })
      },
      preparedData() {
        let data = [
          {
            label: 'Inter',
            amount: 0,
            index: 0
          },
          {
            label: 'Local',
            amount: 0,
            index: 1
          }
        ]
  
        for (let item of this.preparedPayments) {
          if(item.p_type==='inter') {
            data[0].amount += item.internet_banking
          }
          if(item.p_type==='local') {
            data[1].amount += item.internet_banking
          }
        }
  
        return data
      },
      chartData() {
  
        let chartData = {
          labels: this.preparedData.map(item => item.label),
          datasets: [{
            label: 'Internet Banking',
            data: this.preparedData.map(d => d.amount),
            backgroundColor: this.colorList
          }]
        }
  
        return chartData
      }
    }
  }
  </script>