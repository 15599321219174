<template>
  <div class="container-fluid">
    <div class="card shadow mt-2">
      <div class="card-body">
        <h3 class="text-center" :class="$store.state.branch == 3 ? 'text-wa' : 'text-primary'">Patient Summary</h3>

        <div class="row">
          <div class="d-flex mx-auto gap-x-2 mt-2 mx-2">
            
            
            
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import { TimeScale } from "chart.js";
import _ from "lodash";
import moment from "moment";

export default {
  components: {
    
  },
  data() {
    return {
      mode: "all",
      keyword: null,
      branch: this.$store.state.branch
        ? this.$store.state.branch
        : "all",
      filterDate: {
        start: moment(new Date(), 'DD/MM/YYYY').toDate(),
        end: moment(new Date(), 'DD/MM/YYYY').toDate()
      },
      // dateStart: new Date(),
      // dateEnd: new Date(),
      isLoading: false,
      search_date_value: moment(new Date(), 'DD/MM/YYYY').toDate(),
      search_date_type: 'by-day'
    };
  },
  mounted() {
    console.log("🚀 ~ mounted ~ this.$store.state.selectedBranch:", this.$store.state.branch)
    this.triggerInitData()
    
  },
  methods: {
    onRowClick(params) {
      let HN = params.row.HN;
      this.$router.push(`/patient/${HN}`);
    },
    getAllergyList(rowObj) {
      let allergies = rowObj.allergies;
      let other_allergies = rowObj.other_allergies;
      if (allergies && other_allergies) {
        let allergies_string = allergies
          .map(item => {
            return item.name;
          })
          .join(", ");
        let other_allergies_string = other_allergies
          .map(item => {
            return item.name;
          })
          .join(", ");
        return [allergies_string, other_allergies_string].join(", ");
      } else {
        return "-";
      }
    },
    getInsurance(item) {
      if (item.travel_insurance) return item.travel_insurance.name_en;
      else return item.has_travel_insurance;
    },
    time(rowObj) {
      if(rowObj.visit_date) {
        try {
          return this.$moment(rowObj.visit_date.toDate()).format("HH:mm");
        } catch (error) {
          return '-'          
        }
      }else{
        return '-'
      }
    },
    getEsiColor(esi) {
      switch (esi) {
        case 1:
          return "#CB0000";
          break;
        case 2:
          return "#EA9A99";
          break;
        case 3:
          return "#F1C331";
          break;
        case 4:
          return "#629B49";
          break;
        case 5:
          return "#FFFFFF";
          break;
      }
    },
    handleFilterBranch() {

    },
    triggerInitData() {
      this.$refs.visitTableComponent.initData()
    },
    handleFilterDateSearchType(type) {
      this.search_date_type = type
    },
    triggerSearchDate(step) {
      let currentDateSearch = moment(this.search_date_value, 'DD/MM/YYYY')
      this.search_date_value = currentDateSearch.clone().add(step, 'days').toDate();
    },
    triggerKeywordSearch() {
      this.$refs.visitTableComponent.handleKeywordSearch()
    }
  }
};
</script>


