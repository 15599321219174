<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <div class="d-flex">
          <div class="mr-auto">
            <h3 class="text-primary">Worldmed beachfront Phiphi</h3>
            <h3 class="text-primary">DASHBOARD</h3>
            <h3 class="text-primary">IPD</h3>
          </div>
          <div class="timer">
            <h5 class="text-primary text-center">
              {{ timer | moment("dddd") }}
            </h5>
            <h1 class="text-primary text-center">
              {{ timer | moment("HH:mm A") }}
            </h1>
            <div class="text-primary text-center">
              {{ timer | moment("DD/MMMM/YYYY") }}
            </div>
          </div>
        </div>
        <hr />
        <div class="d-flex justify-content-center">
          <div
            class="dashboard-menu-item mr-2"
            @click="$router.push({ name: 'IPD Admission List' })"
          >
            <div
              class="dashboard-menu-item-icon"
              :class="{ active: $route.name === 'IPD Admission List' }"
            >
              <i class="fa-stack fa-2x">
                <i class="fas fa-sync-alt fa-stack-2x"></i>
                <i class="fas fa-user fa-stack-1x"></i>
              </i>
            </div>
            <h5>Admission List</h5>
          </div>
          <div
            class="dashboard-menu-item mr-2"
            @click="$router.push({ name: 'IPD New Admission' })"
          >
            <div
              class="dashboard-menu-item-icon"
              :class="{ active: $route.name === 'IPD New Admission' }"
            >
              <i class="fas fa-hospital-user fa-4x"></i>
            </div>
            <h5>New Admission</h5>
          </div>
          <div
            class="dashboard-menu-item mr-2"
            @click="$router.push({ name: 'IPD New Order' })"
          >
            <div
              class="dashboard-menu-item-icon"
              :class="{ active: $route.name === 'IPD New Order' }"
            >
              <i class="fas fa-user-md fa-4x"></i>
            </div>
            <h5>New Order</h5>
          </div>
          <div
            class="dashboard-menu-item"
            @click="$router.push({ name: 'IPD Med Sheet' })"
          >
            <div
              class="dashboard-menu-item-icon"
              :class="{ active: $route.name === 'IPD Med Sheet' }"
            >
              <i class="fas fa-pills fa-4x"></i>
            </div>
            <h5>Med Sheet</h5>
          </div>
          <div
            class="dashboard-menu-item"
            @click="$router.push({ name: 'IPD Discharge List' })"
          >
            <div
              class="dashboard-menu-item-icon"
              :class="{ active: $route.name === 'IPD Discharge List' }"
            >
              <i class="fas fa-clipboard-list fa-4x"></i>
            </div>
            <h5>Discharge List</h5>
          </div>
        </div>

        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: new Date()
    };
  },
  mounted() {
    var timerID = setInterval(this.updateTime, 60000);
  },
  methods: {
    updateTime() {
      this.timer = new Date();
    }
  }
};

function zeroPadding(num, digit) {
  var zero = "";
  for (var i = 0; i < digit; i++) {
    zero += "0";
  }
  return (zero + num).slice(-digit);
}
</script>

<style lang="scss" scoped>
.container-fluid {
  margin-bottom: 50px;
}
.timer {
  border-radius: 0.25em;
  padding: 15px;
  min-width: 300px;
  height: 150px;
  box-shadow: 2px 2px 10px #bfbfbf;
}

.dashboard-menu-item {
  border-radius: 0.25em;
  padding: 15px;
  text-align: center;
  color: #999999;
  cursor: pointer;
}

.dashboard-menu-item-icon {
  border: 2px solid #999999;
  border-radius: 0.25em;
  padding: 25px;
  margin-bottom: 10px;
}

.dashboard-menu-item > .active {
  color: white !important;
  background-color: #0b97e5 !important;
  border: 2px solid #0b97e5 !important;
}
</style>
