<template>
  <div class="d-flex mb-2">
    <b-pagination class="m-auto" v-model="currentPage" :total-rows="items.length" :per-page="perPage"></b-pagination>
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue';
export default {
  components: {
    BPagination,
  },
  props: {
    items: {
      default: [],
      require: true
    },
    perPage: {
      default: 10
    },
    onChange: {
      require: true
    }
  },
  data() {
    return {
      currentPage: 1
    }
  },
  watch: {
    items: {
      handler() {
        this.onChange(this.paginatedItems)
      },
      immediate: true
    },
    currentPage: {
      handler() {
        this.onChange(this.paginatedItems)
      },
      immediate: true
    }
  },
  computed: {
    totalPages() {
      if(this.items?.length) {
        return Math.ceil(this.items.length / this.perPage)
      }else{
        return 1
      }
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage
      return this.items.slice(start, start + this.perPage)
    },
  }
}
</script>