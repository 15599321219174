<template>
  <div class="container-fluid">
    <patient-info :patient="patient" :nursing="nursing" :visit="visit"></patient-info>
    <div class="d-flex mt-2">
      <router-link
        :to="{ name: 'Patient Full Details' }"
        class="btn btn-outline-dark mr-2"
      >
        <i class="fas fa-file"></i> Full Details
      </router-link>
      <router-link
        :to="{ name: 'Patient Print Form' }"
        class="btn btn-outline-dark mr-2"
      >
        <i class="fas fa-print"></i> Print Form
      </router-link>
      <router-link
        :to="{ name: 'Patient Sticker' }"
        class="btn btn-outline-dark mr-2"
      >
        <i class="fas fa-print"></i> Print Sticker
      </router-link>
    </div>
    <div class="d-flex justify-content-center mt-5">
      <!-- <new-visit-btn class="mr-2" /> -->
      <!-- <new-admission-btn /> -->
    </div>
    <router-view style="margin-top: 50px;" :patient="patient"></router-view>
  </div>
</template>

<script>
export default {
  props: ["patient", "nursing", "visit"]
};
</script>

<style lang="scss" scoped>
.profile {
  border-radius: 0.25em;
}
</style>
