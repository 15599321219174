<template>
  <sidebar-menu
    :menu="menu"
    :relative="true"
    :theme="'white-theme'"
    class="shadow"
    :width="'250px'"
    @item-click="onItemClick"
  />
</template>

<script>
import SidebarHr from "./SidebarHr";
import WorldmedLogo from "./WorldmedLogo";
import { fb } from "../db";
import permission from '../mixins/permission'
export default {
  mixins: [permission],
  data() {
    return {
      menu: [
        {
          component: WorldmedLogo
        },
        // {
        //   href: "/dashboard/opd",
        //   title: "Dashboard",
        //   icon: "fas fa-desktop",
        //   child: [
        //     {
        //       href: '/dashboard/opd',
        //       title: 'OPD'
        //     },
        //     {
        //       href: '/dashboard/ipd',
        //       title: 'Admission'
        //     }
        //   ],
        //   hidden: this.matchRoles(['admin', 'nurse'])
        // },
        {
          href: "/registration",
          title: "Registration",
          icon: "far fa-address-card",
          hidden: this.$store.getters.permission['Registration'] == 1
        },
        {
          href: "/patient",
          title: "Visit Summary",
          icon: "fas fa-hospital-user",
          hidden: this.$store.getters.permission['Visit Summary'] == 1
        },
        {
          href: "/patientsummary",
          title: "Patient Summary",
          icon: "fas fa-hospital-user",
          hidden: this.$store.getters.permission['Patient Summary'] == 1
        },
        // {
        //   href: "/patient-chart",
        //   title: "Pending Chart",
        //   icon: "fas fa-notes-medical",
        //   hidden: this.$store.getters.permission['Pending Chart'] <= 1
        // },
        // {
        //   href: "/operation-report",
        //   title: "Operation Report",
        //   icon: "fas fa-chart-line",
        //   hidden: this.$store.getters.permission['Operation Report'] <= 1
        // },        
        {
          href: "/billing",
          title: "Billing",
          icon: "fas fa-file-invoice-dollar",
          hidden: this.$store.getters.permission['Billing'] == 1
        },
        {
          href: "/insurance",
          title: "Insurance",
          icon: "fas fa-file-invoice-dollar",
          hidden: this.$store.getters.permission['Insurance'] == 1
        },
        {
          href: "/expense",
          title: "Expense",
          icon: "fas fa-file-invoice-dollar",
          hidden: this.$store.getters.permission['Expense'] == 1
        },        
        {
          href: "/product",
          title: "Product",
          icon: "fas fa-warehouse",
          hidden: this.$store.getters.permission['Product'] == 1
        },
        {
          href: "/service",
          title: "Service",
          icon: "fas fa-warehouse",
          hidden: this.$store.getters.permission['Service'] == 1
        },
        {
          href: "/package",
          title: "Package",
          icon: "fas fa-warehouse",
          hidden: this.$store.getters.permission['Package'] == 1
        },
        {
          href: "/sale",
          title: "Sale",
          icon: "fas fa-chart-pie",
          hidden: this.$store.getters.permission['Sale'] == 1
        },
        {
          href: "/statistic",
          title: "Statistic",
          icon: "fas fa-chart-pie",
          hidden: this.$store.getters.permission['Statistic'] == 1
        },
        {
          href: "/staffing",
          title: "Staff",
          icon: "fas fa-user-md",
          hidden: this.$store.getters.permission['Staff'] == 1
        },
        {
          component: SidebarHr
        },
        {
          href: "/setting",
          title: "Setting",
          icon: "fas fa-cogs",
          hidden: this.$store.getters.permission['Setting'] == 1
        },
        {
          href: "/account",
          title: "Account",
          icon: "fas fa-user"
        },
        {
          title: "Logout",
          icon: "fas fa-sign-out-alt"
        }
      ]
    };
  },
  methods: {
    async onItemClick(event, item, node) {
      if (item.title === "Logout") {
        this.$confirm("do you want to logout?").then(async () => {
          await fb.auth().signOut();
        });
      }
    }
  }
};
</script>
