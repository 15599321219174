<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <div class="bg-white">
          <div class="pd10">
            <span class="label-h"
              >Please choose appropriate staffs according to indication of
              patient.</span
            >
            <div>
              <div class="bg-doctor p-3 mt-3">
                <span class="label-h text-light">
                  <i class="fas fa-user-md  text-white"></i
                  ><i class="fas fa-user-nurse ml-2 mr-2 text-white"></i>
                  Indications that require a doctor and at least 1 nurse during
                  transfer :
                </span>
              </div>
              <div class="bg-general p-3 body-border">
                <div
                  class="form-check p-1"
                  v-for="(i, index) in transferDocNurse"
                  :key="index"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="'transferDocNurse_' + index"
                  />
                  <label
                    class="form-check-label ml-1"
                    :for="'transferDocNurse_' + index"
                  >
                    {{ i }}
                  </label>
                </div>
              </div>
            </div>
            <div>
              <div class="bg-nursing p-3 mt-3">
                <span class="label-h text-light">
                  <i class="fas fa-user-nurse mr-2 text-white"></i> Indications
                  that require at least 1 nurse during transfer :
                </span>
              </div>
              <div class="bg-general p-3 body-border">
                <div
                  class="form-check p-1"
                  v-for="(i, index) in transferNurse"
                  :key="index"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="'transferNurse_' + index"
                  />
                  <label
                    class="form-check-label ml-1"
                    :for="'transferNurse_' + index"
                  >
                    {{ i }}
                  </label>
                </div>
              </div>
            </div>
            <div>
              <div class="bg-nursing p-3 mt-3">
                <span class="label-h text-light">
                  Condition that is safe. Nurse aid can facilitate transfer.
                </span>
              </div>
              <div class="bg-general p-3 body-border">
                <div
                  class="form-check p-1"
                  v-for="(i, index) in transferCond"
                  :key="index"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="'transferCond_' + index"
                  />
                  <label
                    class="form-check-label ml-1"
                    :for="'transferCond_' + index"
                  >
                    {{ i }}
                  </label>
                </div>
              </div>
            </div>
            <div class="p-4">
              <span class="label-h ml-2"
                >Please choose appropriate mode of transfer according to
                indication of patient.</span
              >
              <div class="form-check p-1">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="mode_transfer_1"
                />
                <label class="form-check-label ml-1" for="mode_transfer_1">
                  <b>Ferry boat</b> : Usually takes 2-4 hours from Phi Phi
                  islands to mainland and only depart in schedule time(please
                  ask for time schedule of ferry boat). Preferable mode of
                  transfer due to safety concern but takes longer transfer time.
                  May not be suitable for emergency case. Spine injury
                </label>
              </div>
              <div class="form-check p-1">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="mode_transfer_2"
                />
                <label class="form-check-label ml-1" for="mode_transfer_2">
                  <b>Speed boat</b> : Usually takes 1-2 hours from Phi Phi
                  islands to mainland. Not recommend during rough weather
                  (strom, waves more than 1 meter ). Contraindication for spine
                  injury. Used for emergency case.
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const transferDocNurse = [
  "Unstable vital sign or have tendency of unstable vital sign.",
  "AIRWAY : Intubated patient or patient with tendency of airway obstruction.",
  "BREATHING : respiratory distress, desaturation",
  "CIRCULATION : hypotension, sign of shock",
  "DISABILITY : patient has alteration of consciousness.",
  "Patient has possibility for need of life saving intervention while transfer (e.g. Patient with anaphylaxis who may need adrenaline injection or intubation during transfer.)",
  "Major active bleeding that may lead to hypovolemic shock.",
  "Moderate or Severe head injury/ Mild head injury with high risks (alteration of consciousness, history of lost of consciousness, neurodeficit, seizure,sign of base of skull injury, vomiting, lost of balance, Vertigo, abnormal behavior, history of high-impact accident )",
  "Patient receiving high alert drug (e.g. KCl intravenous, Vasopressin intravenous)",
  "ESI 1,2",
  "Patient suspected with condition that may lead to Cardiac arrest (5H,5T)",
  "Coronary artery disease",
  "Stroke",
  "Patient in severe pain that may need Opioids during transfer",
  "Possibility of emergency delivery",
  "Patient suspected with ectopic pregnancy, aortic dissection, abdominal aortic aneurysm",
  "Patient on Intercostal drainage",
  "Patient suspected with Arterial gas embolism, Pulmonary embolism",
  "Patient requires cardiac pacing",
  "Psychiatric patient with risk of self-harm or harm to another.",
  "High risk patient from doctor evaluation."
];
const transferNurse = [
  "ESI 3 : Patient can wait 4-6 hours without life threatening condition (e.g. Appendicitis without risk of ectopic pregnancy or ruptured appendicitis)",
  "Patient need emergency operation and have no unstable vital sign or life threatening condition (e.g. Torsion testis, Hernia with stable vital sign, Hemorrhoid grade 4, Cholecystitis, Cholangitis )",
  "Mild head injury without red flag sign",
  "Spine injury (transfer with ferry only)",
  "Calculi in KUB system that need specialist evaluation (Patient may need pain control injection during transfer)"
];
const transferCond = [
  "ESI 4,5 : Patient is safe. Patient can wait more than 24 hours without any risk of complication.",
  "Close fracture that can be waited and have no risk of complication e.g. Metatarsal fracture, Nasal fracture with no airway problem.",
  "Stable patient who requires treatment, investigation or evaluation with specialist doctors in mainland and have no life threatening condition. i.e. Patient with acute gastroenteritis with mild dehydration whose symptom is not getting better after many days of observation."
];
export default {
  data() {
    return {
      transferDocNurse: transferDocNurse,
      transferNurse: transferNurse,
      transferCond: transferCond,
      // date picker
      stdDateConfig: {
        altInput: true,
        altFormat: "d/m/Y"
      },
      stdDateTimeConfig: {
        altInput: true,
        altFormat: "d/m/Y H:i",
        enableTime: true,
        enableSeconds: false,
        time_24hr: true
      },
      stdTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      }
    };
  }
};
</script>
<style lang="scss" scoped>
.body-border {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.form-check-label {
  font-size: 18px;
}

.label-h {
  // color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 25px;
}

.form-check {
  margin-left: 10px;
}
button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

input[type="checkbox"] {
  height: 17px;
  width: 17px;
  vertical-align: middle;
}
</style>
