<template>
  <div class="mt-4">
    <div class="d-flex mb-2">
      <h3 class="text-primary">Invoice</h3>      
    </div>
    <div class="mt-3">
      <invoice-table :data="invoices" @showInvoice="showInvoice" @displayPaymentModal="showPayment" />  
    </div>

    <edit-patient-invoice-modal :invoice="invoice" :visits="visits" @refetchInvoices="fetchInvoices"
      @showNewPayment="showNewPayment" />
  </div>
</template>

<script>
import { db } from "../../db"
import { getTotalDiscount } from "../../helper/invoice"

export default {
  props: ["start", "end"],
  data() {
    return {
      invoices: [],
      rawInvoices: [],
      invoice: {
        invoice_id: null,
        product_items: [],
        billable_items: [],
      },
      visits: [],
      services: [],
    }
  },
  mounted() {
    this.fetchInvoices()
  },
  methods: {

    async fetchInvoices() {
      const loader = this.$loading.show({})

      const start = this.$moment(this.start, "DD/MM/YYYY")
        .startOf("day")
        .toDate()
      const end = this.$moment(this.end, "DD/MM/YYYY").endOf("day").toDate()
      const invoices = []
      let invoicesCollection = await db
        .collection("Invoice")
        .where("issue_date", ">", start)
        .where("issue_date", "<", end)
        .where("concession_type","==",'Insurance')
        .get()
      let filter_invoice = null
      if (this.$store.state.branch != 3) {        
        filter_invoice = invoicesCollection.docs.filter(doc => {
          return doc.data().invoice_id.slice(0, 2) !== "03"
        })
      }
      else {        
        filter_invoice = invoicesCollection.docs.filter(doc => {
          return doc.data().invoice_id.slice(0, 2) === "03"
        })
      }


      const invoicePromises = filter_invoice.map(
        async (invoiceDoc) => {
          const invoiceData = invoiceDoc.data()

          if (invoiceData && invoiceData.invoice_id) {
            const paymentsCollection = await db
              .collection("Payment")
              .where("invoice_id", "==", invoiceData.invoice_id)
              .get()

            const payments = paymentsCollection.docs.map((paymentDoc) =>
              paymentDoc.data()
            )
            invoiceData.payments = payments
          }
          invoiceData.discount = Math.round(getTotalDiscount(invoiceData))

          return invoiceData
        }
      )

      const resolvedInvoices = await Promise.all(invoicePromises)
      invoices.push(...resolvedInvoices)

      this.rawInvoices = invoices

      const invoicesByDate = _.groupBy(invoices, (item) => {
        return this.$moment(item.issue_date.toDate()).format("DD MMM YYYY")
      })

      this.invoices = Object.entries(invoicesByDate).map((data) => {
        return {
          mode: "span",
          label: data[0],
          html: false,
          children: data[1],
        }
      })

      loader.hide()
    },
    async showInvoice(invoiceId) {
      const selectedInvoice = this.rawInvoices.filter(
        (invoice) => invoice.invoice_id === invoiceId
      )[0]

      this.invoice = {
        ...selectedInvoice,
        issue_date: this.$moment(selectedInvoice.issue_date.toDate()).format(
          "DD/MM/YYYY HH:mm"
        ),
        visit_date: this.$moment(selectedInvoice.visit_date.toDate()).format(
          "DD/MM/YYYY HH:mm"
        ),
      }
      this.visits = await this.fetchVisits(selectedInvoice.HN)
      setTimeout(() => this.$bvModal.show("new-patient-invoice-modal"), 1)
      this.$bvModal.show("new-patient-invoice-modal")
    },
    async showNewPayment(invoice) {
      this.$emit("displayNewPaymentModal", invoice)
    },
    async showPayment(paymentId) {
      this.$emit("displayPaymentModal", paymentId)
    },
    async fetchVisits(patientId) {
      const visits = (
        await db
          .collection("Visit")
          .where("patient", "==", db.collection("Patient").doc(patientId))
          .orderBy("visit_date", "desc")
          .get()
      ).docs.map((visit) => visit.data())

      if (!visits.length)
        this.$alert(
          "No visit information, patient is required to register new visit first before proceed.",
          null,
          "error"
        )

      return visits
    },
  },
  watch: {
    start() {
      this.fetchInvoices()
    },
  },
}
</script>