<template>
  <div class="mt-4">
    <div class="d-flex mb-2">
      <h3 class="text-primary">Payment</h3>
      <a target="_blank" :href="`/print/payments?patientId=${$route.params.id}`" class="btn btn-light ml-auto"><i
          class="fas fa-print"></i> Print Payment Summary</a>
    </div>

    <payment-table :data="payments" @displayPaymentModal="showPayment" />
  </div>
</template>

<script>
import { db } from '../../../db'
import { capitalizeFirstChar } from '../../../helper'

export default {
  props: ["patient"],
  data() {
    return {
      payments: []
    }
  },
  mounted() {
    this.fetchPayments()
  },
  methods: {
    async fetchPayments() {
      const loader = this.$loading.show({});
      const patientId = this.$route.params.id;
      const payments = []

      const paymentsCollection = await db.collection('Payment').where('HN', '==', patientId).orderBy('issue_date', 'desc').get()
      
      //temporary hide payment    
      let filter_payment = null
      filter_payment = paymentsCollection.docs.filter(doc => {
        return doc.data().payment_id !== "01-2024-0008-11-05EA2"
      })

      for (let i = 0; i < filter_payment.length; i++) {
        const paymentCollection = filter_payment[i]
        const payment = paymentCollection.data()
        const preparedPayments = []
        if (payment.cash > 0) {
          preparedPayments.push({ name: 'Cash', amount: payment.cash })
        }
        if (payment.credit > 0) {
          preparedPayments.push({ name: `Credit ${payment.credit_bank_name ? capitalizeFirstChar(payment.credit_bank_name) : ''}`, amount: payment.credit })
        }
        if (payment.insurance > 0) {
          preparedPayments.push({ name: 'Insurance', amount: payment.insurance })
        }
        if (payment.internet_banking > 0) {
          preparedPayments.push({ name: 'Internet banking', amount: payment.internet_banking })
        }
        if (payment.other > 0) {
          preparedPayments.push({
            name: payment.other_name ? capitalizeFirstChar(payment.other_name) : 'Other', amount: payment.other
          })
        }
        payment.total_amount = [payment.cash, payment.credit, payment.other, payment.insurance, payment.internet_banking].reduce((sum, value) => sum + Number(value || 0), 0)
        let invoice = (
          await db
            .collection("Invoice")
            .where("invoice_id", "==", payment.invoice_id)
            .get()
        ).docs.map((invoice) => invoice.data())

        payment.visit_date = invoice[0].visit_date

        payment.preparedPayments = preparedPayments
        payments.push(payment)
      }

      const paymentsByDate = _.groupBy(payments, payment => {
        return this.$moment(payment.issue_date.toDate()).format("DD MMM YYYY");
      });

      this.payments = Object.entries(paymentsByDate).map(data => {
        return {
          mode: 'span',
          label: data[0],
          html: false,
          children: data[1]
        }
      })
      loader.hide()
    },
    showPayment(paymentId) {
      this.$emit('displayPaymentModal', paymentId)
    }
  }
}
</script>