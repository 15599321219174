import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/functions";

import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

const config = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID
};
firebase.initializeApp(config);
// Get a Firestore instance
export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();
export const ui = new firebaseui.auth.AuthUI(firebase.auth());
export const fb = firebase;
// firebase.functions().useEmulator("127.0.0.1", 5001);
export const fb_function = firebase.functions()
// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore;
export { Timestamp, GeoPoint };
