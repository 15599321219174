<template>
  <div class="container-fluid" id="element-to-convert">
    <div class="row">
      <h3 class="text-primary">Sale Report</h3>
    </div>

    <div class="row mt-2">
      <MazPicker v-model="filterDate" no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" placeholder="Date Range"
        range />
      <div class="text-center d-flex mx-3">
        <MazSelect v-model="branch" label="Select branch" placeholder="Select a branch" :options="branchOptions" />
      </div>
      <div class="text-center d-flex mx-3">
        <button class="btn btn-primary mx-auto ml-2 px-4" @click.prevent="search">
          <i class="fas fa-search"></i> Search
        </button>
      </div>
      <div class="text-center d-flex mx-3">
        <button class="btn btn-primary mx-auto ml-2 px-4" @click.prevent="exportCsv">
          <i class="fas fa-file-excel"></i> Export CSV
        </button>
      </div>
      <div class="text-center d-flex mx-3">
        <button class="btn btn-primary mx-auto ml-2 px-4" @click.prevent="printReport">
          <i class="fas fa-file-pdf"></i> Print
        </button>
      </div>
    </div>

    <div class="row mt-4 d-flex flex-column">
      <h4 class="text-primary">Summary Of Revenue</h4>
      <vue-good-table :columns="summaryColumns" :rows="[...serviceSummary, ...productSummary, ...totalSummary]"
        :use-dynamic-row-height="true" styleClass="table condensed table-bordered vgt-table-report-print" :sort-options="{
          enabled: false,
        }" :group-options="{
          enabled: false,
        }" :pagination-options="{
          dropdownAllowAll: false,
        }" />
    </div>

    <div class="row mt-4 d-flex flex-column">
      <div class="col-4" style="padding-left: 0px;">
        <h4 class="text-primary">Summary Of Revenue By Type</h4>
        <vue-good-table :columns="summarybytypeColumns" :rows="payments_group" :use-dynamic-row-height="true"
          :sort-options="{
            enabled: false,
          }" :group-options="{
            enabled: false,
          }" :pagination-options="{
            dropdownAllowAll: false,
          }" />
      </div>


    </div>

    <div class="row mt-4 d-flex flex-column">
      <h4 class="text-primary">Service Revenue</h4>
      <vue-good-table ref="ServiceTable" class="report-table" :columns="serviceColumns" :rows="services_group"
        :row-style-class="changeColorClass" :sort-options="{
          enabled: false,
        }" :group-options="{
          enabled: true,
          collapsable: true,
        }" :pagination-options="{
          dropdownAllowAll: false,
        }" />
    </div>

    <div class="row mt-4 d-flex flex-column">
      <h4 class="text-primary">Product Revenue</h4>
      <vue-good-table ref="ProductTable" class="report-table" :columns="serviceColumns" :rows="products_group"
        :row-style-class="changeColorClass" :sort-options="{
          enabled: false,
        }" :group-options="{
          enabled: true,
          collapsable: true,
        }" :pagination-options="{
          dropdownAllowAll: false,
        }" />
    </div>

  </div>

</template>

<script>
import { db } from "../../db"
import Parser from "@json2csv/plainjs/dist/cjs/Parser"

export default {
  data() {
    return {
      filterDate: {
        start: this.$moment(new Date(), "DD/MM/YYYY"),
        end: this.$moment(new Date(), "DD/MM/YYYY"),
      },
      services: [],
      products: [],
      services_group: [],
      products_group: [],
      payments_group: [],
      serviceColumns: [
        {
          label: "Item",
          field: "item_name",
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          label: "Item Code",
          field: "item_code",
          thClass: 'text-left',
          tdClass: 'text-left',
          width: '140px',
        },
        {
          label: "# of items",
          field: "quantity",
          formatFn: (value) => value.toLocaleString(),
          thClass: 'text-right',
          tdClass: 'text-right',
          width: '95px',
        },
        {
          label: "Amount",
          field: "amount",
          formatFn: (value) => value.toLocaleString() + "฿",
          thClass: 'text-right',
          tdClass: 'text-right',
          width: '130px',
        },
        {
          label: "Discount",
          field: "discount",
          formatFn: (value) => value.toLocaleString() + "฿",
          thClass: 'text-right',
          tdClass: 'text-right',
          width: '130px',
        },
        {
          label: "Total",
          field: "total",
          formatFn: (value) => value.toLocaleString() + "฿",
          thClass: 'text-right',
          tdClass: 'text-right',
          width: '135px',
        },
      ],
      summaryColumns: [
        {
          label: "Type",
          field: "item_name",
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          label: "# of items",
          field: "quantity",
          formatFn: (value) => value.toLocaleString(),
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          label: "Amount",
          field: "amount",
          formatFn: (value) => value.toLocaleString() + "฿",
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          label: "Discount",
          field: "discount",
          formatFn: (value) => value.toLocaleString() + "฿",
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          label: "Total",
          field: "total",
          formatFn: (value) => value.toLocaleString() + "฿",
          thClass: 'text-right',
          tdClass: 'text-right'
        },
      ],
      summarybytypeColumns: [
        {
          label: "Type",
          field: "item_type",
          tdClass: 'text-left',
        },
        {
          label: "Amount",
          field: "amount",
          formatFn: (value) => value.toLocaleString(),
          thClass: 'text-right',
          tdClass: 'text-right',
        }

      ],
      WA_ITEM_CODES: ['WA', 'PR', 'WAP', 'PRO'],
      branch: null,
      branchOptions: [
        { label: 'All', value: 'all' },
        { label: 'WM', value: 'wm' },
        { label: 'WA', value: 'wa' },
      ]
    }
  },
  methods: {
    changeColorClass(row) {
      if (this.WA_ITEM_CODES.includes(row.item_code)) return 'bg-wa'
      if (!row.item_code) return 'bg-summary'
    },
    printReport() {
      const moment = require('moment')
      let start = ""
      let end = ""
      if (moment.isMoment(this.filterDate.start)) {
        start = this.$moment(this.filterDate.start).format('DD/MM/YYYY')
        end = this.$moment(this.filterDate.end).format('DD/MM/YYYY')
      }
      else {
        start = this.filterDate.start
        end = this.filterDate.end
      }
      window.open(`/print/inventory-report/?start=${start}&end=${end}`)
    },
    exportCsv() {
      try {
        let datas = [...this.services, ...this.products]
        const SumData = datas.map((data) => ({
          Item_code: data.item_code,
          Item: data.item_name,
          Quantity: data.quantity,
          Amount: data.amount,
          Discount: data.discount,
          Total: data.total,
        }))
        const parser = new Parser()
        const myData = JSON.parse(JSON.stringify(SumData))
        const csv = parser.parse(myData)
        const anchor = document.createElement("a")
        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
        anchor.target = "_blank"
        anchor.download = "data.csv"
        anchor.click()
        anchor.remove()
      } catch (err) {
        console.error(err)
      }
    },
    async search() {
      const loader = this.$loading.show({})
      await this.fetchInvoices(
        this.$moment(this.filterDate.start, "DD/MM/YYYY").startOf("day").toDate(),
        this.$moment(this.filterDate.end, "DD/MM/YYYY").endOf("day").toDate()
      )

      loader.hide()
    },
    async fetchInvoices(start, end) {
      
      let services = []
      let products = []
      const invoicesRef = await db
        .collection("Invoice")
        .where("issue_date", ">=", start)
        .where("issue_date", "<", end)
        .where("status", "in", ["paid", "outstanding", "gop"])
        .get()

      let filter_invoice = invoicesRef.docs
      if (this.branch === 'all') {
        filter_invoice = invoicesRef.docs
      }
      else if (this.branch === 'wm') {
        filter_invoice = invoicesRef.docs.filter(doc => {
          return doc.data().invoice_id.slice(0, 2) !== "03"
        })
      }
      else if (this.branch === 'wa') {
        filter_invoice = invoicesRef.docs.filter(doc => {
          return doc.data().invoice_id.slice(0, 2) === "03"
        })
      }

      const invoiceIds = filter_invoice.map(invoice => invoice.id)
      const paymentRef = await db
        .collection("Payment")
        .where("issue_date", ">=", start)
        .where("issue_date", "<", this.$moment().endOf("day").toDate())
        .get()
      
      const filter_payment = paymentRef.docs
        .map(doc => doc.data())
        .filter(payment => invoiceIds.includes(payment.invoice_id))

      filter_invoice.forEach((invoice) => {
        const data = invoice.data()
        services = services.concat(this.getServiceRevenue(data.billable_items))
        products = products.concat(this.getServiceRevenue(data.product_items))
      })

      const formattedServices = _.chain(services)
        .groupBy("item_code")
        .map((service, code) => ({
          item_code: code,
          item_name: service[0].item_name,
          quantity: _.sumBy(service, "quantity"),
          amount: _.sumBy(service, "amount"),
          discount: _.sumBy(service, "discount"),
          total: _.sumBy(service, "total"),
        }))

      const formattedProducts = _.chain(products)
        .groupBy("item_code")
        .map((service, code) => ({
          item_code: code,
          item_name: service[0].item_name,
          quantity: _.sumBy(service, "quantity"),
          amount: _.sumBy(service, "amount"),
          discount: _.sumBy(service, "discount"),
          total: _.sumBy(service, "total"),
        }))

      this.products = formattedProducts.value()
      this.services = formattedServices.value()

      this.products.sort((a, b) => a.item_code.localeCompare(b.item_code))
      this.services.sort((a, b) => a.item_code.localeCompare(b.item_code))

      this.services_group = this.ServiceGroupData(this.services)
      this.products_group = this.ProductGroupData(this.products)

      const sum_service = this.services_group[this.services_group.length - 1]
      const sum_product = this.products_group[this.products_group.length - 1] 

      this.products_group = [...this.products_group.slice(0, -1).sort((a, b) => a.item_name.localeCompare(b.item_name)), sum_product]
      this.services_group = [...this.services_group.slice(0, -1).sort((a, b) => a.item_name.localeCompare(b.item_name)), sum_service]      

      const cashTotal = _.sumBy(filter_payment, 'cash')
      const creditTotal = _.sumBy(filter_payment, 'credit')
      const internetBankingTotal = _.sumBy(filter_payment, 'internet_banking')
      const insuranceTotal = _.sumBy(filter_payment, 'insurance')
      const otherTotal = _.sumBy(filter_payment, 'other')

      this.payments_group = [
        { "item_type": "Cash", "amount": cashTotal },
        { "item_type": "Credit", "amount": creditTotal },
        { "item_type": "Internet Banking", "amount": internetBankingTotal },
        { "item_type": "Insurance", "amount": insuranceTotal },
        { "item_type": "Other", "amount": otherTotal }
      ]

    },
    ServiceGroupData(data) {
      let quantity = 0
      let amount = 0
      let discount = 0
      let total = 0
      let service_name = [{ code: 'F0', name: 'Doctor Evaluation Fee' }, { code: 'F1', name: 'Nursing Fee' }, { code: 'F2', name: 'Packaged Medical Charge' }, { code: 'F3', name: 'Doctor Procedure Fee' },
      { code: 'F4', name: 'Doctor Escort' }, { code: 'F5', name: 'Nurse Escort' }, { code: 'F6', name: 'Special Equipments' }, { code: 'F7', name: 'Transportation' }, { code: 'F8', name: 'Patient Room' }, { code: 'F9', name: 'Others' },
      { code: 'PR', name: 'WA Promo' }, { code: 'WA', name: 'WA' }
      ]
      const groupedData = data.reduce((acc, item) => {
        const groupKey = item.item_code.slice(0, 2)
        const service = service_name.find(s => s.code === groupKey)
        if (!acc[groupKey]) {
          acc[groupKey] = {
            item_name: service ? service.name : 'Unknown',
            item_code: groupKey,
            quantity: 0,
            amount: 0,
            discount: 0,
            total: 0,
            children: []
          }
        }
        acc[groupKey].quantity += item.quantity
        acc[groupKey].amount += item.amount
        acc[groupKey].discount += item.discount
        acc[groupKey].total += item.total
        quantity += item.quantity
        amount += item.amount
        discount += item.discount
        total += item.total
        acc[groupKey].children.push(item)
        return acc
      }, {})
      groupedData['Service'] = {
        item_name: 'Services',
        quantity,
        amount,
        discount,
        total,
        children: []
      }
      const tableData = Object.values(groupedData)
      return tableData
    },
    ProductGroupData(data) {
      let quantity = 0
      let amount = 0
      let discount = 0
      let total = 0
      let product_name = [{ code: 'L24', name: 'Laboratory' }, { code: 'IVB', name: 'IV Med Bag' }, { code: 'M24', name: 'Medicine' }, { code: 'S24', name: 'Supply' },
      { code: 'DME', name: 'Durable Medical Equipment' }, { code: 'PRO', name: 'WA Promo' }, { code: 'WAP', name: 'WA Product' }, { code: 'PAC', name: 'WM Promo' }
      ]
      const groupedData = data.reduce((acc, item) => {
        const groupKey = item.item_code.slice(0, 3)
        const product = product_name.find(s => s.code === groupKey)
        if (!acc[groupKey]) {
          acc[groupKey] = {
            item_name: product ? product.name : 'Unknown',
            item_code: groupKey,
            quantity: 0,
            amount: 0,
            discount: 0,
            total: 0,
            children: []
          }
        }
        acc[groupKey].quantity += item.quantity
        acc[groupKey].amount += item.amount
        acc[groupKey].discount += item.discount
        acc[groupKey].total += item.total
        quantity += item.quantity
        amount += item.amount
        discount += item.discount
        total += item.total
        acc[groupKey].children.push(item)
        return acc
      }, {})
      groupedData['Products'] = {
        item_name: 'Products',
        quantity,
        amount,
        discount,
        total,
        children: []
      }
      const tableData = Object.values(groupedData)
      return tableData

    },
    getServiceRevenue(services) {
      if (!services || !services.length) return []

      return services.map((item) => {
        const amount = Number(item.price * item.quantity)
        const discount = this.getDiscount(item)
        return {
          item_code: item.item_code,
          item_name: item.item.item_name,
          quantity: item.quantity,
          amount,
          discount,
          total: amount - discount,
        }
      })
    },
    getDiscount(item) {
      if (item.quantity && item.price) {
        return (
          Math.round(Number(item.quantity) *
            Number(item.price) *
            (Number(item.discount) / 100))
        )
      } else {
        return 0
      }
    },
    getSum(item, title) {
      return {
        item_name: title,
        quantity: _.sumBy(item, "quantity"),
        amount: _.sumBy(item, "amount"),
        discount: _.sumBy(item, "discount"),
        total: _.sumBy(item, "total"),
      }
    },
  },
  computed: {
    serviceSummary() {
      return [this.getSum(this.services, "Service")]
    },
    productSummary() {
      return [this.getSum(this.products, "Products")]
    },
    totalSummary() {
      return [
        this.getSum([...this.serviceSummary, ...this.productSummary], "Total"),
      ]
    },
  },
}
</script>
<style lang="scss">
.no-link {
  text-decoration: none;
}

.vgt-table-report-print thead th {
  background-color: #2F6EBA;
  color: white;
}

.vgt-table-report-print tr:last-child td {
  background-color: #DAE2E8 !important;
  color: black !important;
  font-weight: bold;
}

.vgt-table tr.bg-wa>th.vgt-row-header {
  background-color: #fbf7d5;
  color: black;
}

.vgt-table tr.bg-summary>th.vgt-row-header {
  background-color: #DAE2E8;

  .triangle {
    display: none;
  }
}
</style>