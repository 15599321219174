<template>
  <div class="container-fluid">
    <h3 class="text-center text-success">
      ADMISSION REGISTRATION FORM
    </h3>
    <h4 class="text-center text-success">
      {{new Date() | moment('DD MMM YYYY HH:mm')}}
    </h4>
    <hr>
    <div class="bg-gray">
      <div class="row">
        <div class="col-4">
          <MazSelect
            v-model="admission.branch"
            placeholder="Branch"
            :options="[
              { label: 'Beach Front (Hospital)', value: '001' },
              { label: 'Loh Dalum (Clinic)', value: '002' },
            ]"
          />
        </div>
        <div class="col-4">
            <MazSelect
              class="mb-2"
                v-model="admission.type"
                placeholder="Visit Type"
                :options="[
                  { label: 'Others', value: '000' },
                  { label: 'OPD', value: '001' },
                  { label: 'ER', value: '002' },
                  { label: 'Scheduled', value: '003' },
                  { label: 'Hotel Call', value: '004' },
                ]"
              />
          </div>
          <div class="col-4">
            <MazPicker
              class="mb-2"
              placeholder="Admission Date/Time"
              v-model="admission.date"
              @formatted="pickerFormatted = $event"
            />
          </div>
      </div>
      <div class="row">
          <div class="col-6">
            <MazSelect
              class="mb-2"
              v-model="admission.from"
              placeholder="Admit From"
              :options="[
                { label: 'OPD', value: 'OPD' },
                { label: 'ER', value: 'ER' },
              ]"
            />
            <MazPicker
              class="mb-2"
              placeholder="Time Received from ER/OPD"
              v-model="admission.received_time"
              @formatted="pickerFormatted = $event"
            />
            <MazSelect
              class="mb-2"
              v-model="admission.from"
              placeholder="Transfer type (to room)"
              :options="[
                { label: 'Others', value: 'Others' },
                { label: 'Ambulatory', value: 'Ambulatory' },
                { label: 'Wheelchair', value: 'Wheelchair' },
                { label: 'Stretcher', value: 'Stretcher' },
                { label: 'Crutches', value: 'Crutches' },
              ]"
            />
            <MazSelect
              class="mb-2"
              v-model="admission.admittingg_doctor"
              placeholder="Admitting Doctor"
              :options="[
                { label: 'Others', value: 'Others' },
                { label: 'Dr. Aphilak Kritsanasakul', value: 'Dr. Aphilak Kritsanasakul' },
                { label: 'Dr. Watcharobon Kritsanasakul', value: 'Dr. Watcharobon Kritsanasakul' },
                { label: 'Dr. Santisuk Wongprasert', value: 'Dr. Santisuk Wongprasert' },
              ]"
            />
          </div>
          <div class="col-6">
            <MazSelect
              class="mb-2"
              v-model="admission.room_no"
              placeholder="Room Number"
              :options="[
                { label: '201 A', value: '201 A' },
                { label: '201 B', value: '201 B' },
                { label: '201 C', value: '201 C' },
                { label: '202 A', value: '202 A' },
                { label: '202 B', value: '202 B' },
                { label: '203 A', value: '203 A' },
                { label: '203 B', value: '203 B' },
                { label: '204 A', value: '204 A' },
                { label: '204 B', value: '204 B' },
                { label: '205 A', value: '205 A' },
                { label: '205 B', value: '205 B' },
                { label: '206 A', value: '206 A' },
                { label: '206 B', value: '206 B' },
                { label: '301 A', value: '301 A' },
                { label: '301 B', value: '301 B' },
                { label: '302 A', value: '302 A' },
                { label: '302 B', value: '302 B' },
                { label: '303 A', value: '303 A' },
                { label: '303 B', value: '303 B' },
                { label: '304 A', value: '304 A' },
                { label: '304 B', value: '304 B' },
                { label: '305 A', value: '305 A' },
                { label: '305 B', value: '305 B' },
                { label: '306 A', value: '306 A' },
                { label: '306 B', value: '306 B' },
              ]"
            />
            <MazSelect
              class="mb-2"
              v-model="admission.payment_type"
              placeholder="Type of payment"
              :options="[
                { label: 'Others', value: 'Others' },
                { label: 'Insurance', value: 'Insurance' },
                { label: 'Self Pay', value: 'Self Pay' },
              ]"
            />
            <div class="row">
              <div class="col-4">
                <label>Staff on duty (Receiving)</label>
              </div>
              <div class="col-4">
                <MazSelect
                  class="mb-2"
                  v-model="admission.rn"
                  placeholder="RN list"
                  :options="[]"
                />
              </div>
              <div class="col-4">
                <MazSelect
                  class="mb-2"
                  v-model="admission.na"
                  placeholder="NA list"
                  :options="[]"
                />
              </div>
            </div>
            <MazSelect
              class="mb-2"
              v-model="admission.attending_doctor"
              placeholder="Attending Doctor"
              :options="[
                { label: 'Others', value: 'Others' },
                { label: 'Dr. Aphilak Kritsanasakul', value: 'Dr. Aphilak Kritsanasakul' },
                { label: 'Dr. Watcharobon Kritsanasakul', value: 'Dr. Watcharobon Kritsanasakul' },
                { label: 'Dr. Santisuk Wongprasert', value: 'Dr. Santisuk Wongprasert' },
              ]"
            />
          </div>
      </div>
    </div>
    <hr>

    <h5 class="text-success">
      Vital Signs and Evaluation
    </h5>
    <table class="table table-bordered ">
      <thead class="text-success text-center">
        <tr>
          <th rowspan="2">Time</th>
          <th colspan="2">BP</th>
          <th rowspan="2">PR/min</th>
          <th rowspan="2">RR/min</th>
          <th rowspan="2">Temp °C</th>
          <th rowspan="2">O2Sat %</th>
          <th rowspan="2">Pain Score</th>
          <th rowspan="2">Pain Location</th>
          <th rowspan="2">DTX(mg/dl)</th>
          <th colspan="3">GCS</th>
          <th colspan="4">Pupil</th>
        </tr>
        <tr>
          <th>Sys</th>
          <th>Dias</th>
          <th>E</th>
          <th>V</th>
          <th>M</th>
          <th>Lt</th>
          <th></th>
          <th>Rt</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>3</td>
          <td>react</td>
          <td>3</td>
          <td>react</td>
        </tr>
      </tbody>
    </table>

    <div class="bg-red">
      <h5 class="text-success"><i class="fas fa-exclamation-triangle text-danger"></i> Allergies</h5>
      <div class="d-flex">
        <label class="mr-4">Drug Allergy</label>
        <b-form-radio-group label="Drug Allergy" v-slot="{ ariaDescribedby }">
          <b-form-radio v-model="is_drug_allergy" :aria-describedby="ariaDescribedby" :value="true">Yes</b-form-radio>
          <b-form-radio v-model="is_drug_allergy" :aria-describedby="ariaDescribedby" :value="false">No</b-form-radio>
        </b-form-radio-group>
      </div>
      <div class="d-flex">
        <label class="mr-4">Others Allergy</label>
        <b-form-radio-group label="Drug Allergy" v-slot="{ ariaDescribedby }">
          <b-form-radio v-model="is_other_allergy" :aria-describedby="ariaDescribedby" :value="true">Yes</b-form-radio>
          <b-form-radio v-model="is_other_allergy" :aria-describedby="ariaDescribedby" :value="false">No</b-form-radio>
        </b-form-radio-group>
      </div>
    </div>
    
    <div class="bg-gray">
      <div class="row">
        <div class="col-6">
          <h5 class="text-success">Past History</h5>

        </div>

        <div class="col-6">
          <h5 class="text-success">Pregnancy</h5>
          <div class="d-flex">
            <b-form-radio-group label="Drug Allergy">
              <b-form-radio v-model="prenancy" :value="'n/a'">N/A</b-form-radio>
              <b-form-radio v-model="prenancy" :value="'No'">No</b-form-radio>
              <b-form-radio v-model="prenancy" :value="'Yes'">Yes</b-form-radio>
            </b-form-radio-group>
            <MazInput
              v-model="pregnancy_g"
              placeholder="G"
              size="sm"
              style="width: 80px;"
            />
            <MazInput
              v-model="pregnancy_p"
              placeholder="P"
              size="sm"
              style="width: 80px;"
            />
            <MazInput
              v-model="pregnancy_a"
              placeholder="A"
              size="sm"
              style="width: 80px;"
            />
            <MazInput
              v-model="pregnancy_ga"
              placeholder="GA wk"
              size="sm"
              style="width: 100px;"
            />
            <MazInput
              v-model="pregnancy_by"
              placeholder="By: "
              size="sm"
              style="width: 120px;"
            />
          </div>
          <div class="d-flex">
            
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <button class="btn btn-primary mr-2">Save</button>
      <button class="btn btn-secondary mr-2">Edit</button>
      <button class="btn btn-danger">Cancel</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      admission: {},
      is_drug_allergy: false,
      is_other_allergy: false,
      pregnancy: 'n/a',
      pregnancy_g: null,
      pregnancy_p: null,
      pregnancy_a: null,
      pregnancy_ga: null,
      pregnancy_by: null
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-red {
  background-color: #ffcdcd;
  padding: 15px;
}
.bg-gray {
  background-color: #ececec;
  padding: 15px;
}
</style>