export default {
  methods: {
    alertTemp(val, val_unit) {
      if (val && val_unit) {
        if (
          (val >= 39.5 && val_unit === "C") ||
          (val >= 103.1 && val_unit === "F")
        ) {
          //this.$alert("High Fever");
        }
        if (
          (val >= 38.4 && val < 39.5 && val_unit === "C") ||
          (val >= 101.12 && val < 103.1 && val_unit === "F")
        ) {
          //this.$alert("Moderate Fever");
        }
        if (
          (val >= 37.3 && val < 38.4 && val_unit === "C") ||
          (val >= 99.14 && val < 101.12 && val_unit === "F")
        ) {
          //this.$alert("Low Fever");
        }
        if (
          (val > 0 && val < 36.5 && val_unit === "C") ||
          (val > 0 && val < 97.7 && val_unit === "F")
        ) {
          //this.$alert("Hypothermia");
        }
      }
    },
    checkTemp(val, val_unit) {
      if (
        (val > 0 && val < 36.5 && val_unit === "C") ||
        (val > 0 && val < 97.7 && val_unit === "F") ||
        (val >= 37.3 && val_unit === "C") ||
        (val >= 99.14 && val_unit === "F")
      ) {
        return "vital-red";
      }
    },
    alertO2Sat(val) {
      if (val >= 90 && val < 95) {
        this.$alert("Mild Hypoxemia");
      }
      if (val >= 85 && val < 90) {
        this.$alert("Moderate Hypoxemia");
      }
      if (val > 0 && val < 85) {
        this.$alert("Severe Hypoxemia");
      }
    },
    checkO2Sat(val) {
      if (val >= 90 && val < 95) {
        return "vital-soft-red";
      }
      if (val >= 85 && val < 90) {
        return "vital-mid-red";
      }
      if (val > 0 && val < 85) {
        return "vital-red";
      }
    },
    // alertPainScore(val) {
    //   if (val >= 7 && val <= 10) {
    //     this.$alert("Severe Pain");
    //   }
    //   if (val >= 4 && val <= 6) {
    //     this.$alert("Moderat Pain");
    //   }
    //   if (val >= 1 && val <= 3) {
    //     this.$alert("Mid Pain");
    //   }
    // },
    checkPainScore(val) {
      if (val >= 7 && val <= 10) {
        return "vital-red";
      }
      if (val >= 4 && val <= 6) {
        return "vital-mid-red";
      }
      if (val >= 1 && val <= 3) {
        return "vital-soft-red";
      }
    },
    alertHR(val, ageMonth) {
      if (
        val > 0 &&
        ((val < 90 && ageMonth >= 1 && ageMonth < 12) ||
          (val < 80 && ageMonth >= 12 && ageMonth < 36) ||
          (val < 65 && ageMonth >= 36 && ageMonth < 72) ||
          (val < 58 && ageMonth >= 72 && ageMonth < 144) ||
          (val < 50 && ageMonth >= 144 && ageMonth < 216))
      ) {
        //this.$alert("Bradycardia");
      }
      if (
        val > 0 &&
        ((val > 190 && ageMonth >= 1 && ageMonth < 12) ||
          (val > 140 && ageMonth >= 12 && ageMonth < 36) ||
          (val > 120 && ageMonth >= 36 && ageMonth < 72) ||
          (val > 118 && ageMonth >= 72 && ageMonth < 144) ||
          (val > 100 && ageMonth >= 144))
      ) {
        //this.$alert("Tachycardia");
      }
      if (
        val > 0 &&
        ((val < 60 && ageMonth >= 216 && ageMonth < 780) ||
          (val > 90 && ageMonth >= 216 && ageMonth < 780))
      ) {
        //this.$alert("Abnormal");
      }
    },
    checkHR(val, ageMonth) {
      if (
        val > 0 &&
        ((val < 90 && ageMonth >= 1 && ageMonth < 12) ||
          (val > 190 && ageMonth >= 1 && ageMonth < 12) ||
          (val < 80 && ageMonth >= 12 && ageMonth < 36) ||
          (val > 140 && ageMonth >= 12 && ageMonth < 36) ||
          (val < 65 && ageMonth >= 36 && ageMonth < 72) ||
          (val > 120 && ageMonth >= 36 && ageMonth < 72) ||
          (val < 58 && ageMonth >= 72 && ageMonth < 144) ||
          (val > 118 && ageMonth >= 72 && ageMonth < 144) ||
          (val < 50 && ageMonth >= 144 && ageMonth < 216) ||
          (val > 100 && ageMonth >= 144 && ageMonth < 216) ||
          //adult
          (val < 60 && ageMonth >= 216 && ageMonth < 780) ||
          (val > 90 && ageMonth >= 216 && ageMonth < 780))
      ) {
        return "vital-red";
      }
    },
    alertRR(val, ageMonth) {
      if (
        val > 0 &&
        ((val < 35 && ageMonth >= 0 && ageMonth < 3) ||
          (val > 55 && ageMonth >= 0 && ageMonth < 3) ||
          (val < 30 && ageMonth >= 3 && ageMonth < 6) ||
          (val > 45 && ageMonth >= 3 && ageMonth < 6) ||
          (val < 22 && ageMonth >= 6 && ageMonth < 12) ||
          (val > 38 && ageMonth >= 6 && ageMonth < 12) ||
          (val < 22 && ageMonth >= 12 && ageMonth < 36) ||
          (val > 30 && ageMonth >= 12 && ageMonth < 36) ||
          (val < 20 && ageMonth >= 36 && ageMonth < 72) ||
          (val > 24 && ageMonth >= 36 && ageMonth < 72) ||
          (val < 16 && ageMonth >= 72 && ageMonth < 144) ||
          (val > 22 && ageMonth >= 72 && ageMonth < 144) ||
          (val < 12 && ageMonth >= 144) ||
          (val > 20 && ageMonth >= 144))
      ) {
        //this.$alert("Abnormal");
      }
    },
    checkRR(val, ageMonth) {
      if (
        val > 0 &&
        ((val < 35 && ageMonth >= 0 && ageMonth < 3) ||
          (val > 55 && ageMonth >= 0 && ageMonth < 3) ||
          (val < 30 && ageMonth >= 3 && ageMonth < 6) ||
          (val > 45 && ageMonth >= 3 && ageMonth < 6) ||
          (val < 22 && ageMonth >= 6 && ageMonth < 12) ||
          (val > 38 && ageMonth >= 6 && ageMonth < 12) ||
          (val < 22 && ageMonth >= 12 && ageMonth < 36) ||
          (val > 30 && ageMonth >= 12 && ageMonth < 36) ||
          (val < 20 && ageMonth >= 36 && ageMonth < 72) ||
          (val > 24 && ageMonth >= 36 && ageMonth < 72) ||
          (val < 16 && ageMonth >= 72 && ageMonth < 144) ||
          (val > 22 && ageMonth >= 72 && ageMonth < 144) ||
          (val < 12 && ageMonth >= 144) ||
          (val > 20 && ageMonth >= 144))
      ) {
        return "vital-red";
      }
    },
    alertBPSys(val, ageMonth, ageDay) {
      if (
        val > 0 &&
        ((val < 60 & ageDay >= 0 && ageDay <= 28) ||
          (val < 70 && ageMonth >= 1 && ageMonth < 12) ||
          (val < 72 && ageMonth >= 12 && ageMonth < 24) ||
          (val < 74 && ageMonth >= 24 && ageMonth < 36) ||
          (val < 76 && ageMonth >= 36 && ageMonth < 48) ||
          (val < 78 && ageMonth >= 48 && ageMonth < 60) ||
          (val < 80 && ageMonth >= 60 && ageMonth < 72) ||
          (val < 82 && ageMonth >= 72 && ageMonth < 84) ||
          (val < 84 && ageMonth >= 84 && ageMonth < 96) ||
          (val < 86 && ageMonth >= 96 && ageMonth < 108) ||
          (val < 88 && ageMonth >= 108 && ageMonth < 120) ||
          (val < 90 && ageMonth >= 120 && ageMonth < 216))
      ) {
        //this.$alert("Hypertension");
      }
      // if (val > 140 && ageMonth >= 12 && ageMonth < 156) {
      //   this.$alert("Hypertension Stage 2");
      // }
      // if (val >= 130 && val < 140 && ageMonth >= 12 && ageMonth < 156) {
      //   this.$alert("Hypertension Stage 1");
      // }
      // if (val >= 120 && val < 130 && ageMonth >= 12 && ageMonth < 156) {
      //   this.$alert("Prehypertension");
      // }

      // high value
      if (
        val > 0 &&
        ((val >= 100 && ageMonth >= 36 && ageMonth < 48) ||
          (val >= 100 && ageMonth >= 48 && ageMonth < 60) ||
          (val >= 100 && ageMonth >= 60 && ageMonth < 72) ||
          (val >= 105 && ageMonth >= 72 && ageMonth < 84 ) ||
          (val >= 105 && ageMonth >= 84 && ageMonth < 96) ||
          (val >= 105 && ageMonth >= 96 && ageMonth < 108) ||
          (val >= 110 && ageMonth >= 108 && ageMonth < 120) ||
          (val >= 110 && ageMonth >= 120 && ageMonth < 132) ||
          (val >= 110 && ageMonth >= 132 && ageMonth < 144) ||
          (val >= 115 && ageMonth >= 144 && ageMonth < 156) ||
          (val >= 115 && ageMonth >= 156 && ageMonth < 168) ||
          (val >= 115 && ageMonth >= 168 && ageMonth < 180) ||
          (val >= 120 && ageMonth >= 180 && ageMonth < 192) ||
          (val >= 120 && ageMonth >= 192 && ageMonth < 204) ||
          (val >= 120 && ageMonth >= 204 && ageMonth < 216))
          // (val >= 120 && ageMonth >= 216) use case below
       ){
          //this.$alert("Hypotension");
        }
      // age 18+
      if (val > 0 && val < 90 && ageMonth >= 216) {
        //this.$alert("Hypotension");
      }
      if (val >= 120 && val < 130 && ageMonth >= 216) {
        //this.$alert("Prehypertension");
      }
      if (val >= 130 && val < 140 && ageMonth >= 216) {
        //this.$alert("Hypertension Stage 1");
      }
      if (val >= 140 && val < 180 && ageMonth >= 216) {
        //this.$alert("Hypertension Stage 2");
      }
      if (val >= 180 && ageMonth >= 216) {
        //this.$alert("Hypertensive Crisis");
      }
      
    },
    checkBPSys(val, ageMonth, ageDay) {
      if (
        val > 0 &&
        ((val < 60 & ageDay >= 0 && ageDay <= 28 ||
          val < 70 && ageMonth >= 1 && ageMonth < 12) ||
          (val < 72 && ageMonth >= 12 && ageMonth < 24) ||
          (val < 74 && ageMonth >= 24 && ageMonth < 36) ||
          (val < 76 && ageMonth >= 36 && ageMonth < 48) ||
          (val < 78 && ageMonth >= 48 && ageMonth < 60) ||
          (val < 80 && ageMonth >= 60 && ageMonth < 72) ||
          (val < 82 && ageMonth >= 72 && ageMonth < 84) ||
          (val < 84 && ageMonth >= 84 && ageMonth < 96) ||
          (val < 86 && ageMonth >= 96 && ageMonth < 108) ||
          (val < 88 && ageMonth >= 108 && ageMonth < 120) ||
          (val < 90 && ageMonth >= 120 && ageMonth < 216))
      ) {
        return "vital-soft-red";
      }
      
      // if (val >= 140 && ageMonth >= 12 && ageMonth < 156) {
      //   return "vital-red";
      // }
      // if (val >= 130 && val < 140 && ageMonth >= 12 && ageMonth < 156) {
      //   return "vital-mid-red";
      // }
      // if (val >= 120 && val < 130 && ageMonth >= 12 && ageMonth < 156) {
      //   return "vital-soft-red";
      // }
      // high value
      if (
        val > 0 &&
        ((val >= 100 && ageMonth >= 36 && ageMonth < 48) ||
          (val >= 100 && ageMonth >= 48 && ageMonth < 60) ||
          (val >= 100 && ageMonth >= 60 && ageMonth < 72) ||
          (val >= 105 && ageMonth >= 72 && ageMonth < 84 ) ||
          (val >= 105 && ageMonth >= 84 && ageMonth < 96) ||
          (val >= 105 && ageMonth >= 96 && ageMonth < 108) ||
          (val >= 110 && ageMonth >= 108 && ageMonth < 120) ||
          (val >= 110 && ageMonth >= 120 && ageMonth < 132) ||
          (val >= 110 && ageMonth >= 132 && ageMonth < 144) ||
          (val >= 115 && ageMonth >= 144 && ageMonth < 156) ||
          (val >= 115 && ageMonth >= 156 && ageMonth < 168) ||
          (val >= 115 && ageMonth >= 168 && ageMonth < 180) ||
          (val >= 120 && ageMonth >= 180 && ageMonth < 192) ||
          (val >= 120 && ageMonth >= 192 && ageMonth < 204) ||
          (val >= 120 && ageMonth >= 204 && ageMonth < 216))
          // (val >= 120 && ageMonth >= 216) use case below
       ){
          return "vital-soft-red";
        }

      // age 18+
      if (val > 0 && val < 90 && ageMonth >= 216) {
        return "vital-red";
      }
      if (val > 120 && val < 130 && ageMonth >= 216) {
        return "vital-soft-red";
      }
      if (val >= 130 && val < 140 && ageMonth >= 216) {
        return "vital-mid-red";
      }
      if (val >= 140 && val < 180 && ageMonth >= 216) {
        return "vital-mid-red";
      }
      if (val >= 180 && ageMonth >= 216) {
        return "vital-red";
      }
    },
    alertBPDias(val, ageMonth) {
      // high value
      if (
      val > 0 &&
      ((val > 60 && ageMonth >= 36 && ageMonth < 48) ||
        (val > 60 && ageMonth >= 48 && ageMonth < 60) ||
        (val > 60 && ageMonth >= 60 && ageMonth < 72) ||
        (val > 70 && ageMonth >= 72 && ageMonth < 84 ) ||
        (val > 70 && ageMonth >= 84 && ageMonth < 96) ||
        (val > 70 && ageMonth >= 96 && ageMonth < 108) ||
        (val > 75 && ageMonth >= 108 && ageMonth < 120) ||
        (val > 75 && ageMonth >= 120 && ageMonth < 132) ||
        (val > 75 && ageMonth >= 132 && ageMonth < 144) ||
        (val > 75 && ageMonth >= 144 && ageMonth < 156) ||
        (val > 75 && ageMonth >= 156 && ageMonth < 168) ||
        (val > 75 && ageMonth >= 168 && ageMonth < 180) ||
        (val > 80 && ageMonth >= 180 && ageMonth < 192) ||
        (val > 80 && ageMonth >= 192 && ageMonth < 204) ||
        (val > 80 && ageMonth >= 204 && ageMonth < 216))
        // (val > 80 && ageMonth >= 216) use case below
      ){
        //this.$alert("Hypotension");
      }
      // age 18+
      if (val > 0 && val < 60 && ageMonth >= 216) {
        //this.$alert("Hypotension");
      }
      // Duplicate data
      // if (val > 80 && val < 90 && ageMonth >= 216) {
      //   this.$alert("Prehypertension");
      // }
      if (val > 80 && val < 90 && ageMonth >= 216) {
        //this.$alert("Hypertension Stage 1");
      }
      if (val >= 90 && val < 120 && ageMonth >= 216) {
        //this.$alert("Hypertension Stage 2");
      }
      if (val >= 120 && ageMonth >= 216) {
        //this.$alert("Hypertensive Crisis");
      }
    },
    checkBPDias(val, ageMonth) {
    // high value
    if (
      val > 0 &&
      ((val > 60 && ageMonth >= 36 && ageMonth < 48) ||
        (val > 60 && ageMonth >= 48 && ageMonth < 60) ||
        (val > 60 && ageMonth >= 60 && ageMonth < 72) ||
        (val > 70 && ageMonth >= 72 && ageMonth < 84 ) ||
        (val > 70 && ageMonth >= 84 && ageMonth < 96) ||
        (val > 70 && ageMonth >= 96 && ageMonth < 108) ||
        (val > 75 && ageMonth >= 108 && ageMonth < 120) ||
        (val > 75 && ageMonth >= 120 && ageMonth < 132) ||
        (val > 75 && ageMonth >= 132 && ageMonth < 144) ||
        (val > 75 && ageMonth >= 144 && ageMonth < 156) ||
        (val > 75 && ageMonth >= 156 && ageMonth < 168) ||
        (val > 75 && ageMonth >= 168 && ageMonth < 180) ||
        (val > 80 && ageMonth >= 180 && ageMonth < 192) ||
        (val > 80 && ageMonth >= 192 && ageMonth < 204) ||
        (val > 80 && ageMonth >= 204 && ageMonth < 216))
        // (val > 80 && ageMonth >= 216) use case below
      ){
        return "vital-soft-red";
      }
      // age 18+
      if (val > 0 && val < 60 && ageMonth >= 216) {
        return "vital-red";
      }
      // Duplicate data
      // if (val > 80 && val < 90 && ageMonth >= 216) {
      //   return "vital-soft-red";
      // } 
      if (val >= 80 && val < 90 && ageMonth >= 216) {
        return "vital-mid-red";
      }
      if (val >= 90 && val < 120 && ageMonth >= 216) {
        return "vital-mid-red";
      }
      if (val >= 120 && ageMonth >= 216) {
        return "vital-red";
      }
    },
    checkBmi(val) {
      if (val > 0 && val < 16) {
        return "vital-red";
      }
      if (val >= 16 && val < 17) {
        return "vital-red";
      }
      if (val >= 17 && val < 18.5) {
        return "vital-red";
      }
      if (val > 25 && val <= 30) {
        return "vital-red";
      }
      if (val > 30 && val <= 35) {
        return "vital-red";
      }
      if (val > 35 && val <= 40) {
        return "vital-red";
      }
      if (val > 40) {
        return "vital-red";
      }
    },
    alertBmi(val) {
      if (val > 0 && val < 16) {
        return "Severe Thinness (Underweight)";
      }
      if (val >= 16 && val < 17) {
        return "Moderate Thinness (Underweight)";
      }
      if (val >= 17 && val < 18.5) {
        return "Mild Thinness (Underweight)";
      }
      if (val > 25 && val <= 30) {
        return "Overweight";
      }
      if (val > 30 && val <= 35) {
        return "Obese Class 1";
      }
      if (val > 35 && val <= 40) {
        return "Obese Class 2";
      }
      if (val > 40) {
        return "Obese Class 3";
      }
    },
    alertDTX(val, val_unit) {
      if (
        (val >= 30 && val <= 70 && val_unit === "mg/dl") ||
        (val >= 1.7 && val <= 3.9 && val_unit === "mmol/L")
      ) {
        //this.$alert("Hypoglycemia (Low Blood Sugar)");
      }
      if (
        (val >= 101 && val <= 125 && val_unit === "mg/dl") ||
        (val >= 5.6 && val <= 6.9 && val_unit === "mmol/L")
      ) {
        //this.$alert("Pre-Diabetic Range");
      }
      if (
        (val >= 126 && val_unit === "mg/dl") ||
        (val >= 7 && val_unit === "mmol/L")
      ) {
        //this.$alert("Diabetic Range");
      }
    },
    checkDTX(val, val_unit) {
      if (
        (val >= 30 && val <= 70 && val_unit === "mg/dl") ||
        (val >= 1.7 && val <= 3.9 && val_unit === "mmol/L")
      ) {
        return "vital-red";
      }
      if (
        (val >= 101 && val <= 125 && val_unit === "mg/dl") ||
        (val >= 5.6 && val <= 6.9 && val_unit === "mmol/L")
      ) {
        return "vital-red";
      }
      if (
        (val >= 126 && val_unit === "mg/dl") ||
        (val >= 7 && val_unit === "mmol/L")
      ) {
        return "vital-red";
      }
    },
    checkGcsE(val) {
      if (val > 0 && val < 4) {
        return "vital-red";
      }
    },
    checkGcsV(val) {
      if (val > 0 && val < 5) {
        return "vital-red";
      }
    },
    checkGcsM(val) {
      if (val > 0 && val < 6) {
        return "vital-red";
      }
    },
    checkGcsTotal(val) {
      if (val >= 9 && val <= 12) {
        return "vital-mid-red";
      }
      if (val >= 3 && val <= 8) {
        return "vital-red";
      }
    }
  }
};
