<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 mt-2">
        <div class="card shadow">
          <div class="card-body">
            <h5 class="text-primary">Role</h5>
            <table class="table table-bordered">
              <thead>
                <th>ID</th>
                <th>Name</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr v-for="(role,index) in roles" :key="index">
                  <td>{{role.id}}</td>
                  <td>{{role.name}}</td>
                  <td><button class="btn btn-primary" @click="showRole(role)"><i class="fas fa-edit"></i></button></td>
                </tr>
              </tbody>
            </table>
            <button class="btn btn-primary btn-block mt-2" @click="showRole(null)">
              <i class="fas fa-plus"/> add role
            </button>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="role-modal" ref="role-modal" hide-footer title="Role" size="xl">
      <MazInput
        class="mt-2"
        :placeholder="'Role ID'"
        v-model="role.id"
      />
      <MazInput
        class="mt-2"
        :placeholder="'Role Name'"
        v-model="role.name"
      />
      <MazInput
        class="mt-2"
        :placeholder="'Role Description'"
        v-model="role.description"
      />
      <MazInputTags
        class="mt-2"
        :placeholder="'Permissions'"
        v-model="role.permissions"
        clearable
      />
      <button class="btn btn-success btn-block mt-2" @click="saveRole"><i class="fas fa-save"></i> Save</button>
    </b-modal>
  </div>
</template>

<script>
import {db} from '../../db'
export default {
  data() {
    return {
      roles: [],
      role: {
        id: null,
        name: null,
        description: null,
        permissions: []
      },
    }
  },
  firestore() {
    return {
      roles: db.collection('Role')
    }
  },
  methods: {
    showRole(role) {
      if(role) {
        this.role.id = role.id
        this.role.name = role.name
        this.role.description = role.description
        this.role.permissions = role.permissions
      }else{
        this.role = {
          id: null,
          name: null,
          description: null,
          permissions: []
        }
      }

      this.$bvModal.show('role-modal')
    },
    async saveRole() {
      let loader = this.$loading.show({})
      try {
        let result = await db.collection('Role').doc(this.role.id).set({
          name: this.role.name || null,
          description: this.role.description || null,
          permissions: this.role.permissions || null,
        })
      } catch (error) {
        console.log(error)
      }
      loader.hide()
      this.$bvModal.hide('role-modal')
    }
  }
}
</script>
