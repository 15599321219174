<template>
  <div class="container">
    <h3 class="text-primary text-center">Patient Sticker</h3>
    <div class="sticker shadow">
      <div class="logo"></div>
      <div class="d-flex top">
        <img
          v-if="patient.img_url"
          :src="patient.img_url"
          class="avatar mr-2"
        />
        <img v-else src="/images/profile.svg" class="avatar mr-2" />
        <div class="content">
          <b>{{ patient.first_name }} {{ patient.last_name }}</b>
          <div v-if="patient.date_of_birth">
            Date of Birth:
            {{ patient.date_of_birth.toDate() | moment("DD/MM/YYYY") }}
          </div>
          <div>Age: {{ patient.age }}</div>
          <div>HN/AN: {{ patient.HN }}</div>
        </div>
      </div>
      <div class="bottom bg-danger text-white">
        <div class="text-truncate">
          Drug Allergy:
          <span :key="index" v-for="(item, index) in patient.allergies"
            >{{ item.name
            }}{{ index !== patient.allergies.length - 1 ? "," : "" }}
          </span>
        </div>
        <div class="text-truncate">
          Other Allergy:
          <span :key="index" v-for="(item, index) in patient.other_allergies"
            >{{ item.name
            }}{{ index !== patient.other_allergies.length - 1 ? "," : "" }}
          </span>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button class="btn btn-primary mt-2">
        <i class="fas fa-print"></i> Print
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["patient"]
};
</script>

<style lang="scss" scoped>
.sticker {
  height: 38mm;
  width: 100mm;
  margin-top: 50px;
  border-radius: 0.25em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.avatar {
  height: 20mm;
  width: 20mm;
  border-radius: 0.25em;
}

.top {
  height: 24mm;
  padding: 1mm;
}

.logo {
  position: absolute;
  top: 2mm;
  right: 2mm;
  background-image: url("/images/logo.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 20mm;
  width: 20mm;
  opacity: 0.2;
}

.bottom {
  height: 14mm;
  border-bottom-right-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
  padding: 1mm;
  font-size: 3mm;
}

.content {
  font-size: 4mm;
}
</style>
