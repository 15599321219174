<template>
  <multiselect
    :options="options"
    track-by="id"
    label="name"
    selectLabel=""
    v-model="selectedItems"
    @input="emitValue"
    :multiple="multiple"
    :class="{'required': mode === 'required'}"
  ></multiselect>
</template>

<script>
export default {
  props: {
    value: {
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    mode:{
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      handler(value) {
        if(this.multiple) {
          if(value) {
            this.selectedItems = value
          }
        }else{
          if(value) {
            this.selectedItems = this.options.filter(
              option => value === option.id
            );
          }
        }
      },  
      immediate: true
    }
  },
  data() {
    return {
      selectedItems: this.getSelectedIds(),
    };
  },
  methods: {
    getSelectedIds() {
      if(this.multiple) {
        return this.value
      }else{
        return this.value?.id;
      }
    },
    emitValue(newItems) {
      if(this.multiple) {
        this.$emit("input", newItems);
      }else{
        this.$emit("input", newItems?.id);
      }
    },
  },
  computed:{
    getCustomClass(){
      if(this.mode==='required'){
        return 'required'
      }else{
        return ''
      }
    }
  }
};
</script>

<style lang="scss" >
.required .multiselect__tags{
  background-color: rgb(11, 151, 229);
  // color: #FFFFFF;
}
.required .multiselect__single{
  background-color: rgb(11, 151, 229);
  color: #FFFFFF;
}
</style>
