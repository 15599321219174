import { db } from "../db";

export default {
  data() {
    return {
      transfer_nursing: {
        vs_list: [
          {
            date: null,
            time: null,
            temp: null,
            pr: null,
            rr: null,
            bp_syst: null,
            bp_diat: null,
            evm: null,
            nursing_note: null,
            evaluation: null
          }
        ],
        during_transfer_phys: [
          {
            date: null,
            time: null,
            examination: null,
            phys_order: null,
            signature: null
          }
        ],
        during_transfer_vs: [
          {
            date: null,
            time: null,
            temp: null,
            pr: null,
            rr: null,
            bp_syst: null,
            bp_diat: null,
            evm: null,
            nursing_note: null,
            evaluation: null
          }
        ]
      }
    };
  },
  methods: {
    addRowList(modelGrp, model, value) {
      if (modelGrp && model) {
        this[modelGrp][model].push({});
        this[modelGrp][model + "Length"] += value;
      } else {
        this[model].push({});
        this[model + "Length"] += value;
      }
    },
    removeRowList(modelGrp, model, index) {
      if (modelGrp && model) {
        if (this[modelGrp][model].length > 1) {
          this[modelGrp][model].splice(index, 1);
        }
      }
    }
  }
};
