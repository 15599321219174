import { render, staticRenderFns } from "./OrderSpecial.vue?vue&type=template&id=a27fb554&scoped=true"
import script from "./OrderSpecial.vue?vue&type=script&lang=js"
export * from "./OrderSpecial.vue?vue&type=script&lang=js"
import style0 from "./OrderSpecial.vue?vue&type=style&index=0&id=a27fb554&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a27fb554",
  null
  
)

export default component.exports