<template>

  <div class="container-fluid">
    <button style="margin-left: 10px; width: 150px;" type="button" class="btn btn-gray btn-lg" @click="generatePDF">
      <i class="fas fa-print"></i> Print
    </button>
    <div ref="contentToPrint">
      <div>
        <!-- <button @click.prevent="pushDD">PUSh</button> -->
        <div class="d-flex flex-row justify-content-center ">
          <div class="col-6 text-center mt-3">
            <div class="logo-box">
              <img src="/images/worldmed-logo-2.jpg" class="img-fluid" />
            </div>
            <div class="align-self-end">
              <h3>
                ER / OPD NURSING ASSESSMENT
              </h3>
            </div>
            <div class="row">
              <div class="radio-wrapper-23">
                <input id="radio-1" type="radio" value="1" v-model="nursing.esi_level" class="radio-1 ">
                <label for="radio-1">
                  1. Super red
                </label>
                <input id="radio-2" type="radio" value="2" v-model="nursing.esi_level" class="radio-2 ">
                <label for="radio-2">
                  2. Red
                </label>
                <input id="radio-3" type="radio" value="3" v-model="nursing.esi_level" class="radio-3 ">
                <label for="radio-3">
                  3. Yellow
                </label>
                <input id="radio-4" type="radio" value="4" v-model="nursing.esi_level" class="radio-4 ">
                <label for="radio-4">
                  4. Green
                </label>
                <input id="radio-5" type="radio" value="5" v-model="nursing.esi_level" class="radio-5 ">
                <label for="radio-5">
                  5. White
                </label>
              </div>
            </div>
          </div>
          <div class="col-6 detail-box flex-fill flex-row align-items-stretch mt-3">
            <div class=""><b>Name:</b> {{ patient.first_name || '' + ' ' + patient.last_name || '' }}</div>

            <div class="d-flex align-items-stretch mt-2">
              <div class="flex-2 border-r"><b>Date of Birth:</b> {{ patient.date_of_birth ?
                formatDate(patient.date_of_birth) : '' }}</div>
              <div class="flex-1 border-r pl-2"><b>Age:</b> {{ patient.age || '' }}</div>
              <div class="flex-1 pl-2"><b>Gender:</b> {{ patient.gender || '' }}</div>
            </div>

            <div class="d-flex align-items-stretch mt-2">
              <div class="flex-1 border-r"><b>HN :</b> {{ patient.HN || '' }}</div>
              <div class="flex-1 border-r"><b>VN :</b> {{ $route.params.visitId || '' }}</div>

            </div>
            <div class="d-flex align-items-stretch mt-2">
              <div class="flex-1 "><b>Visit Date :</b> {{
                $route.params.visitId.slice(11, 13) + '/' + $route.params.visitId.slice(13, 15) + '/' +
                $route.params.visitId.slice(15, 19) 
              }}</div>
            </div>
            <div class="d-flex align-items-stretch mt-2">
              <div class="flex-1 "><b>When will you leave Phi Phi :</b> {{ patient.deeparture_date ?
                formatDate(patient.departure_date) : '-' }}
              </div>
            </div>
            <div class="d-flex align-items-stretch mt-2">
              <div class="flex-1"><b>Where is your next destination :</b> {{ nursing.next_destination || '-' }}
              </div>
            </div>

          </div>
        </div>

        <div class="d-flex flex-row align-items-stretch mt-2">

          <div class="d-flex flex-column col-6">
            <div class="input-group">
              <div><b>Time of arrival :</b> {{ nursing.time_to_arrival || '' }}
              </div>

            </div>
            <div class="input-group">
              <div><b>Time to physician :</b> {{ nursing.time_to_physician || '' }}
              </div>

            </div>

          </div>
          <div class="d-flex flex-row col-6">
            <div class="flex"><b>Height :</b> {{ (nursing.height || '  ') + ' ' + (nursing.height_unit || '  ') }}
              <span class="text-muted" v-if="getHeight">{{ getHeight }}</span>
            </div>
            <div class="flex ml-3"><b>Weight :</b> {{ (nursing.weight || ' ') + ' ' + (nursing.weight_unit || ' ') }}
              <span class="text-muted" v-if="getWeight">
                {{ getWeight }}</span>
            </div>
            <div class="flex ml-3"><b>BMI :</b> {{ getBmi() ? getBmi() : " " }}
            </div>
          </div>

        </div>
      </div>
      <div class="p-3">
        <div class="text-center mb-1">
          <label class="label-b "> NURSING ASSESSMENT</label>
        </div>

        <table class="table table-bordered">
          <thead class="text-center" style="background-color: #4472c4;">
            <tr class="text-white">
              <th rowspan="2">Time</th>
              <th colspan="2">BP</th>
              <th rowspan="2" style="min-width: 70px;">
                PR /min
              </th>
              <th rowspan="2" style="min-width: 70px;">
                RR /min
              </th>
              <th rowspan="2" style="min-width: 70px;">
                Temp °C
              </th>
              <th rowspan="2" style="min-width: 70px;">
                O2Sat %
              </th>

              <th rowspan="2" style="min-width: 70px;">DTX (mg/dl)</th>
              <th colspan="3" style="width: 70px;">GCS</th>
              <th colspan="2" style="width: 250px;">Pupil</th>
            </tr>
            <tr class="text-center">
              <th class="text-white">Sys</th>
              <th class="text-white">Dias</th>
              <th class="text-black" style="background-color: #cdd4ea;">
                E
              </th>
              <th class="text-black" style="background-color: #cdd4ea;">
                V
              </th>
              <th class="text-black" style="background-color: #cdd4ea;">
                M
              </th>
              <th class="text-black" style="background-color: #cdd4ea; width: 50px;">
                Lt
              </th>
              <th class="text-black" style="background-color: #cdd4ea; width: 50px;">
                Rt
              </th>

            </tr>
          </thead>
          <tbody class="text-center" style="background-color: #d0cece;">
            <tr v-for="(i, index) in nursing.vital_sign" :key="`${i}_${index}`">
              <td>{{ i.time || ''}}</td>
              <td :class="checkBPSys(i.bp_syst, patient.ageMonth, patient.ageDay)
                ">
                {{ i.bp_syst || '' }}
              </td>
              <td :class="checkBPDias(i.bp_dias, patient.ageMonth)">
                {{ i.bp_dias || '' }}
              </td>
              <td :class="checkHR(i.pr, patient.ageMonth)">
                {{ i.pr || '' }}
              </td>
              <td :class="checkRR(i.rr, patient.ageMonth)">
                {{ i.rr || '' }}
              </td>
              <td :class="checkTemp(i.temp, i.temp_unit)">
                <div v-if="i.temp_unit === 'C'">
                  {{ i.temp || '' }} {{ i.temp_unit || '' }}
                </div>
                <div v-if="i.temp_unit === 'F'">
                  {{
                    i.temp && i.temp_unit
                      ? getTemp(i.temp, i.temp_unit)
                      : null
                  }}
                </div>
              </td>
              <td :class="checkO2Sat(i.o2sat)">
                {{ (i.o2sat || '') + '% ' + (i.o2sat_select || '')}}
              </td>
              <td :class="checkDTX(i.dtx, i.dtx_unit)">
                <div v-if="i.dtx_unit === 'mg/dl'">
                  {{ i.dtx || '' }} {{ i.dtx_unit || '' }}<br />
                </div>
                <div v-if="i.dtx_unit === 'mmol/L'">
                  {{
                    i.dtx && i.dtx_unit ? getDtx(i.dtx, i.dtx_unit) : null
                  }}
                </div>
              </td>
              <td :class="checkGcsE(i.vs_e)">
                {{ i.vs_e || '' }}
              </td>
              <td :class="checkGcsV(i.vs_v)">
                {{ i.vs_v || '' }}
              </td>
              <td :class="checkGcsM(i.vs_m)">
                {{ i.vs_m || '' }}
              </td>
              <td>{{ (i.pupil_lt_value || '') + ' ' + (i.pupil_lt || '') }}</td>
              <td>{{ (i.pupil_rt_value || '') + ' ' + (i.pupil_rt || '') }}</td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-6">
            <div class="row">

              <div class="col"><b>Mode of Arrival:</b> {{ nursing.mode_of_arrival || '' }}</div>
              <div class="col"><b>Fall Precaution:</b> {{ nursing.fall_precaution || '' }}</div>

            </div>
            <div class="row">

              <div class="col"><b>Purpose:</b> {{
                nursing.purpose === "Ambulance pick up from"
                  ? `${nursing.purpose} ${nursing.purpose_amb_pick_from}`
                  : nursing.purpose
              }}</div>

              <div class="col"><b>Escort:</b> {{
                nursing.escort !== "Others"
                  ? nursing.escort
                  : nursing.escort_other_input
              }}</div>


            </div>

            <div class="row">

              <div class="col "><b>General Appearance:</b> {{
                nursing.general_appearance !== "Other"
                  ? nursing.general_appearance
                  : nursing.general_appearance_other
              }}</div>


            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col">
                <span class="label-b"><i class="fas fa-user-nurse"></i> Pregnancy</span>
              </div>
              <div class="col">
                <div>
                  <b>{{ nursing.is_pregnancy === "na" ? "N/A" : null }}</b>
                  <b class="text-capitalize">{{
                    nursing.is_pregnancy !== "na"
                      ? nursing.is_pregnancy
                      : null
                  }}</b>
                </div>
                <div v-if="
                  nursing.pregnancy_ga_wk &&
                  nursing.pregnancy_ga_day &&
                  nursing.is_pregnancy === 'yes'
                ">
                  <b>Gestational Age = {{ nursing.pregnancy_ga_wk
                    }}<span> + </span>
                    {{ nursing.pregnancy_ga_day }} weeks</b>
                </div>
                <div v-if="
                  nursing.pregnancy_ga_wk &&
                  !nursing.pregnancy_ga_day &&
                  nursing.is_pregnancy === 'yes'
                ">
                  <b>Gestational Age =
                    {{ nursing.pregnancy_ga_wk }} weeks</b>
                </div>
                <div v-if="
                  !nursing.pregnancy_ga_wk &&
                  nursing.pregnancy_ga_day &&
                  nursing.is_pregnancy === 'yes'
                ">
                  <b>Gestational Age =
                    {{ nursing.pregnancy_ga_day }} days</b>
                </div>
                <div v-if="nursing.is_pregnancy === 'yes'">
                  <b>By :
                    {{
                      nursing.pregnancy_by ? nursing.pregnancy_by : "-"
                    }}</b>
                </div>
                <div>
                  <b>LMP :
                    {{
                      nursing.pregnancy_lmp ? nursing.pregnancy_lmp : "-"
                    }}</b>
                </div>
                <div>
                  <b>Contraception :
                    {{
                      nursing.pregancy_contraception
                        ? nursing.pregancy_contraception
                        : "-"
                    }}</b>
                </div>
              </div>
              <div class="col">
                <div class="pl-5" v-if="nursing.is_pregnancy === 'yes'">
                  <div>
                    <b>Gravida =
                      {{ nursing.pregnancy_g ? nursing.pregnancy_g : "-" }}</b>
                  </div>
                  <div>
                    <b>Parity =
                      {{ nursing.pregnancy_p ? nursing.pregnancy_p : "-" }}</b>
                  </div>
                  <div>
                    <b>Abortion =
                      {{ nursing.pregnancy_a ? nursing.pregnancy_a : "-" }}</b>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>
      <div class="pl-3 row">
        <div class="col-6">
          <div class="label-b"><b>Vaccination:</b></div>
          <div class="row">
            <div class="col">
              <b>Tetanus</b>
            </div>
            <div class="col">
              <span class="text-muted pl-5">{{ nursing.tetanus || '' }} </span>
              <span v-if="nursing.tetanus_more">
                {{ nursing.tetanus_more || '' }}</span>
            </div>
            
          </div>
          <div class="row">
            <div class="col">
              <b>Rabies</b>
            </div>
            <div class="col">
              <span class="text-muted" v-bind:class="{ 'pl-5': index === 0 }" v-for="(i, index) in nursing.rabies"
                :key="index">
                {{ (i.rabies_value || '') + ' ' + (i.rabies_note || '') }}
              </span>
            </div>

          </div>
        </div>
        <div class="col-6">
          <div class="label-b"><b>Pain assessment:</b></div>
          <div class="row">
            <div class="col">
              <b>Pain score: </b> {{ nursing.pain_score || '' }}
            </div>
            <div class="col">
              <b>Location: </b> {{ nursing.pain_location || '' }}
            </div>
          </div>
          <div class="row">
            <div class="col">
              <b>Duration: </b> {{ nursing.duration || '' }}
            </div>
            <div class="col">
              <b>Frequency: </b> {{ nursing.frequency || '' }}
            </div>
          </div>
        </div>
        <div class="col-6 mt-3">
          <label class="label-b mt-2"><i class="fas fa-id-card-alt"></i> Past History
            <span style="color: #b30000;">{{ '(' + (nursing.underlying.length + nursing.surgery.length) + ')'
              }}</span></label>

          <div class="row">
            <div class="col d-flex">
              <div class="flex-1">
                <b>Underlying history:</b>
              </div>
              <div>
                <p v-for="(i, index) in nursing.underlying" :key="index">
                  {{
                    (i.code !== null ? i.code+' '+i.title : '')
                  }}
                  
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex">
              <div class="flex-1">
                <b>Surgery history:</b>
              </div>
              <div>
                <span v-for="(i, index) in nursing.surgery" :key="index">
                  {{ i.surgery_name || '' }}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex">
              <div class="flex-1">
                <b>Family history:</b>
              </div>
              <div>
                <span v-for="(i, index) in nursing.family" :key="index">
                  {{
                    i.family_history !== "Other"
                      ? (i.family_history ? i.family_history : '')
                      : i.family_history_other
                  }}
                  <span v-if="i.family_relation || i.family_relation_other">({{
                    i.family_relation !== "Other"
                      ? (i.family_relation ? i.family_relation : '')
                      : i.family_relation_other
                  }})
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="row">

            <div class="col">
              <span><b>Smoking</b></span>
              <span class="pl-3">
                <span v-if="nursing.is_smoking === 'yes'"> {{ nursing.smoking_value || '' }} {{ nursing.smoking_unit ||
                  ''
                  }}</span>
                <span v-else>{{ nursing.is_smoking }} </span>
              </span>
            </div>
            <div class="col">
              <b>Alcohol</b>
              <span class="pl-3">

                <span v-if="nursing.is_alcohol === 'yes'"> {{ nursing.alcohol_value || '' }} {{ nursing.alcohol_unit ||
                  ''
                  }}</span>
                <span v-else>{{ nursing.is_alcohol }} </span>
              </span>
            </div>
          </div>



        </div>
        <div class="col-6 mt-3">
          <div class="row">
            <div class="col">
              <b class="label-b">Assessment tool:</b> {{ nursing.assessmenttool || '' }}
            </div>

          </div>

          <div>
            <div class="p-3">
              <div>
                <i class="input-icon-red fa fa-exclamation-triangle" style="color:#b30000;"></i>
                <label class="label-b" style="margin-left: 10px;">
                  Allergies
                  <span style="color: #b30000;">({{ nursing.drug_allergy.length + nursing.other_allergy.length
                    }})</span>
                </label>

                <div>
                  Drug Allergy
                  <span class="pl-5" style="color: #b30000;" v-if="nursing.is_drug_allergy === 'no'">
                    <b class="text-capitalize">{{ nursing.is_drug_allergy }}</b>
                  </span>

                </div>
              </div>
              <div v-if="nursing.is_drug_allergy === 'yes'">
                <table class="table table-bordered mt-2">
                  <thead class="text-center text-white" style="background-color: #4472c4;">
                    <tr>
                      <th width="50%">Allergy</th>
                      <th width="50%">Reaction</th>
                    </tr>
                  </thead>
                  <tbody class="text-center" style="background-color: #d0cece;">
                    <tr v-for="(i, index) in nursing.drug_allergy" :key="index">
                      <td>{{ i.name }}</td>
                      <td>
                        {{ i.reaction === 'Other...' ? i.reaction_other : i.reaction }}
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="mt-2">
                <div>
                  Others
                  <span class="pl-5" style="color: #b30000;" v-if="nursing.is_drug_allergy === 'no'">
                    <b class="text-capitalize">{{ nursing.is_other_allergy }}</b>
                  </span>
                </div>
              </div>
              <div v-if="nursing.is_other_allergy === 'yes'">
                <table class="table table-bordered mt-2">
                  <thead class="text-center text-white" style="background-color: #4472c4;">
                    <tr>
                      <th width="50%">Allergy</th>
                      <th width="50%">Reaction</th>
                    </tr>
                  </thead>
                  <tbody class="text-center" style="background-color: #d0cece;">
                    <tr v-for="(i, index) in nursing.other_allergy" :key="index">
                      <td>{{ i.other_allergen }}</td>
                      <td>
                        {{ i.other_reaction === 'Other...' ? i.other_reaction_other : i.other_reaction }}
                      </td>


                    </tr>
                  </tbody>
                </table>

              </div>

            </div>
          </div>

          <div class="row mt-2">
            <div class="col">
              <b class="label-b">Current Medication: </b>
              <span v-for="(i, index) in nursing.current_medication" :key="index">
                {{ i.medication || '' }} {{ i.medication_unit || '' }}
                <span v-if="index < nursing.current_medication.length - 1">,
                </span>
              </span>
            </div>


          </div>
        </div>
      </div>


      <div class="p-3">
        <div class="text-center">
          <label class="label-b mt-2"><i class="fas fa-first-aid"></i> Chief Complaint And Present ILLNESS</label>
        </div>
        <table class="table table-bordered mt-2">
          <thead class="text-center text-white" style="background-color: #4472c4;">
            <tr>
              <th>Duration before arrival</th>
              <th>Complaint</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody class="text-center" style="background-color: #d0cece;">
            <tr v-for="(i, index) in nursing.cc" :key="index">
              <td>{{ (i.cc_duration_times || '') + ' ' + (i.cc_duration_unit || '') }}</td>
              <td>
                {{ i.cc_name !== "other" ? (i.cc_name ? i.cc_name : '') : i.cc_name_other }}
              </td>
              <td>{{ i.cc_note || '' }}</td>
            </tr>
          </tbody>
        </table>
        <div>
          <img :src="nursing.body_part_image_url" style="display: block;margin-right: auto;width: 25%" alt="Image">
          
        </div>
        <!-- <div class="mt-4">
          <div>
            <span class="label-b">
              <i class="fas fa-cloud-upload-alt"></i> Upload Files
            </span>
          </div>
          <div class="row mt-2" v-for="(file, index) in nursing.files" :key="index">
            <div class="col-2">
              <a :href="file.url" target="_blank"><i class="far fa-file"></i> {{ file.name }}</a>
            </div>
            <div class="col-2">
              <div class="form-control">
                {{ nursing.files[index].type }}
              </div>
            </div>
            <div class="col-5">
              <div class="form-control">
                {{ nursing.files[index].name }}
              </div>
            </div>
          </div>
        </div> -->
        <div style="position: relative; height: 100px;">
          <div class="" style="position: absolute; bottom: 0; right: 0; border-radius: 0px;">
            <span class="p-4"><b>Assessed By: {{ nursing.rn_name }}</b></span>
          </div>
        </div>
      </div>

    </div>




  </div>

</template>

<script>
import nurseAssessmentMixins from "../mixins/nurse-assessment";
import vitalSignMixins from "../mixins/vital-sign";
import html2pdf from 'html2pdf.js'
import moment from 'moment'
import { db, storage } from "../db"
export default {
  mixins: [nurseAssessmentMixins, vitalSignMixins],
  props: ['patient'],
  methods: {

    async loadImageAsBase64(url) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.crossOrigin = 'Anonymous' // For external images
        img.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          canvas.getContext('2d').drawImage(img, 0, 0)
          resolve(canvas.toDataURL('image/png'))
        }
        img.onerror = reject
        img.src = url;
      })
    },
    generatePDF() {
      // Ensure the reference is not null
      const element = this.$refs.contentToPrint
      // Define options
      const options = {
        margin: 0.5,                 // Set margins for the PDF
        filename: 'NURSING_ASSESSMENT.pdf',      // Name of the PDF file
        image: { type: 'png', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },   // Increase scale for higher resolution
        pagebreak: { before: '.newPage', avoid: ['h2', 'h3', 'h4', '.field'] },
        jsPDF: { unit: 'mm', format: [463, 600], orientation: 'portrait' }
      }
      html2pdf().set(options).from(element).save()
    },
    formatDate(data) {
      return moment(data.toDate()).format("DD/MM/YYYY")
    }
  },
};
</script>
<style lang="scss" scoped>
.vital-red {
  background-color: #dc0000ff;
  color: #fff;
}

.vital-mid-red {
  background-color: #ea9999;
}

.vital-soft-red {
  background-color: #f4cccc;
}

.center {
  text-align: center;
}

.label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.box-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.label-b {
  color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}

.label-buildin {
  position: relative;
  display: block;
  padding-right: 76px;
  width: 135px;
  box-sizing: border-box;
}

.logo-box {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}

.radio-wrapper-23 label {
  background: #fff;
  padding: .75rem 1rem;
  border: 2px solid #e5e5e5;
  border-radius: 0.25rem;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  font-weight: 500;
  position: relative;
  min-width: 140px;
  text-align: left;
  margin-left: 2px;
}

.radio-wrapper-23 input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  border-width: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.radio-wrapper-23 input.radio-1:checked+label {
  box-shadow: #000000 0 0 0 2px;
  color: #fff;
  background: #b30000;
  border-color: transparent;
}

.radio-wrapper-23 input.radio-2:checked+label {
  box-shadow: #000000 0 0 0 2px;
  color: #000000;
  background: #ff8080;
  border-color: transparent;
}

.radio-wrapper-23 input.radio-3:checked+label {
  box-shadow: #000000 0 0 0 2px;
  color: #000000;
  background: #ffcc00;
  border-color: transparent;
}

.radio-wrapper-23 input.radio-4:checked+label {
  box-shadow: #000000 0 0 0 2px;
  color: #000000;
  background: #2eb82e;
  border-color: transparent;
}

.radio-wrapper-23 input.radio-5:checked+label {
  box-shadow: #000000 0 0 0 2px;
  color: #000000;
  background: #fff;
  border-color: transparent;
}
</style>
