<template>
    <div class="container-fluid">
        <div class="d-flex">
            <h3 class="text-primary">Product Transfer</h3>
            <button class="btn btn-primary ml-auto" @click="showTransferModal()">
                <i class="fas fa-plus"></i> Add
            </button>
        </div>
        <b-modal id="transfer-item-modal" hide-footer size="lg" :title="'Transfer Item'" no-close-on-backdrop>
            <div v-if="!isEditCard" class="d-flex flex-row-reverse">
                <button class="btn btn-primary mr-2" @click="addProduct">
                    <i class="fas fa-plus"></i> Add Product
                </button>
            </div>
            <div class="row">
                <div class="col-6 mt-2">
                    <label class="text-primary">from Branch</label>
                    <select v-model="transfer.frombranch" class="form-control form-select">
                        <option :value="0">Main Stock</option>
                        <option v-for="dd in $store.state.branches" :key="dd.id" :value="Number(dd.id)">{{ dd.name_en }}
                        </option>
                    </select>
                </div>
                <div class="col-6 mt-2">
                    <label class="text-primary">to Branch</label>
                    <select v-model="transfer.tobranch" class="form-control form-select">
                        <option :value="0">Main Stock</option>
                        <option v-for="dd in $store.state.branches" :key="dd.id" :value="Number(dd.id)">{{ dd.name_en }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="col-12 mt-2">
                <draggable v-model="transfer.product_items" group="transfer.product_items" @start="drag = true"
                    @end="drag = false">
                    <div class="row mb-2" v-for="(item, index) in transfer.product_items" :key="index">
                        <div class="col-1">
                            <div class="m-4 p-2">
                                <i class="fas fa-bars" style="color: grey"></i>
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="text-primary">Item</label>
                            <v-select class="ml-auto" v-model="item.item_code" :options="prepared_products"
                                label="item_name" :reduce="item => item.item_code" searchable filterable
                                :filter="filterProduct" @input="handleProductChanged(index)">
                                <template v-slot:option="option">
                                    <div>
                                        <span>{{ option.item_name }}</span><br>
                                        <span v-if="option.item_other_name"
                                            style="font-size: smaller; text-decoration: underline;">
                                            {{ option.item_other_name }}
                                        </span>
                                    </div>
                                </template>

                                <template v-slot:selected-option="option">
                                    <div>
                                        <span style="display: block;">{{ option.item_name }}</span>
                                        <span v-if="option.item_other_name"
                                            style="font-size: smaller; text-decoration: underline; display: block;">
                                            {{ option.item_other_name }}
                                        </span>
                                    </div>
                                </template>
                            </v-select>
                        </div>

                        <div class="col-3">
                            <label class="text-primary">Quantity ({{ item.quantity ? item.quantity : 0 + " lefts"
                                }})</label>
                            <input type="number" class="form-control" v-model="item.transfer">
                        </div>
                        <div v-if="!isEditCard" class="col-1">
                            <button class="btn btn-light mt-4" @click="transfer.product_items.splice(index, 1)"><i
                                    class="fas fa-trash"></i></button>
                        </div>
                        <hr>
                    </div>
                </draggable>
            </div>
            <div class="d-flex flex-row-reverse col-12 mt-2">
                <!-- <div v-if="isEditCard" class="p-3 col-3">
                    <button class="btn btn-danger btn-block" @click="deleteTransferItem(transfer.id)">
                        <i class="fas fa-trash"></i> Delete Transfer Item
                    </button>
                </div> -->
                <div v-if="!isEditCard" class="p-3 col-3">
                    <button class="btn btn-success btn-block" @click="saveTransfer">
                        <i class="fas fa-save"></i> Save
                    </button>
                </div>
            </div>
        </b-modal>

        <vue-good-table :columns="stockColumns" :rows="prepared_transfers" @on-row-click="showTransferModal"
            :pagination-options="{ enabled: true, }" class="stock-table" :search-options="{ enabled: false }"
            :sort-options="{ enabled: true }">
            <template v-slot:table-row="props">
                <!-- <span v-if="props.column.field === 'issue_date'">
                    {{ formatDate(props.row.issue_date) }}
                </span> -->
                <span v-if="props.column.field === 'frombranch'">
                    {{ props.row.frombranch === null ? '' : (props.row.frombranch === 0 ? 'Main Stock' :
                        $store.state.branches[props.row.frombranch - 1]?.name_en) }}

                </span>
                <span v-else-if="props.column.field === 'tobranch'">
                    {{ props.row.tobranch === null ? '' : (props.row.tobranch === 0 ? 'Main Stock' :
                        $store.state.branches[props.row.tobranch - 1]?.name_en) }}

                </span>
                <span v-else-if="props.column.field === 'product_items'">
                    {{ props.row.product_items === null ? '' : props.row.product_items.length + ' items' }}
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>


        </vue-good-table>
    </div>

</template>

<script>
import { db } from "../../db"
import moment from "moment"
import draggable from 'vuedraggable'

export default {
    components: {
        draggable,
    },
    data() {
        return {
            medicines: [],
            stocks: [],
            transfers: [],
            transfer: {},
            isEditCard: false,
            summary: [],
            stockColumns: [
                { label: "Datetime", field: "issue_date" },
                { label: "From Branch", field: "frombranch" },
                { label: "To Branch", field: "tobranch" },
                {
                    label: 'Transfer',
                    field: 'product_items',
                },

            ],
        }
    },
    firestore() {
        return {
            stocks: db.collection("Stock").orderBy('branch', 'asc').orderBy('expire_date', 'asc'),
            medicines: db.collection("Product").orderBy('item_code', 'asc'),
            transfers: db.collection("Transfer"),
        }
    },
    watch: {
        stocks(newStocks) {
            if (newStocks.length > 0) {
                this.summarizeData()
            }
        },
        'transfer.frombranch'(newVal, oldVal) {
            this.transfer.product_items.forEach((_, index) => {
                this.handleProductChanged(index)
            })
        }
    },

    methods: {
        async DeductStock() {

            this.transfer.details = []
            for (let item of this.transfer.product_items) {

                let resultfrom = await db.collection('Stock').orderBy('expire_date', 'asc').where('item_code', '==', item.item_code).where('branch', '==', this.transfer.frombranch).get()
                let transfer = Number(item.transfer)
                let stock = []
                let resultto = await db.collection('Stock').orderBy('expire_date', 'asc').where('item_code', '==', item.item_code).where('branch', '==', this.transfer.tobranch).get()
                let receive = []

                for (let element of resultfrom.docs) {
                    stock = element.data()
                    if (transfer > 0) {

                        if (stock.quantity <= transfer) {
                            transfer = transfer - stock.quantity

                            if (resultto.docs.length !== 0) {
                                let found = false
                                for (let element2 of resultto.docs) {
                                    receive = element2.data()
                                    if (stock.expire_date == receive.expire_date) {
                                        let updatedQuantity = (Number(receive.quantity) || 0) + Number(stock.quantity)
                                        await db.collection('Stock').doc(receive.id).update({ quantity: updatedQuantity })
                                        found = true
                                        this.transfer.details.push(stock)
                                    }
                                }
                                if (!found) {
                                    stock.branch = this.transfer.tobranch
                                    stock.quantity = stock.quantity
                                    this.transfer.details.push(stock)
                                    const stockRef = await db.collection("Stock").add(stock)
                                    await db.collection("Stock").doc(stockRef.id).update({ id: stockRef.id })

                                }
                            }
                            else {
                                stock.branch = this.transfer.tobranch
                                stock.quantity = stock.quantity
                                this.transfer.details.push(stock)
                                const stockRef = await db.collection("Stock").add(stock)
                                await db.collection("Stock").doc(stockRef.id).update({ id: stockRef.id })

                            }

                            await db.collection('Stock').doc(stock.id).delete()
                        } else if (stock.quantity > transfer) {
                            stock.quantity = stock.quantity - transfer
                            if (resultto.docs.length !== 0) {
                                let found = false
                                for (let element2 of resultto.docs) {
                                    receive = element2.data()

                                    if (stock.expire_date == receive.expire_date) {
                                        await db.collection('Stock').doc(stock.id).update({ quantity: stock.quantity })
                                        let updatedQuantity = (Number(receive.quantity) || 0) + Number(transfer)
                                        receive.quantity = Number(transfer)
                                        this.transfer.details.push(receive)
                                        await db.collection('Stock').doc(receive.id).update({ quantity: updatedQuantity })
                                        found = true

                                    }
                                }
                                if (!found) {
                                    await db.collection('Stock').doc(stock.id).update({ quantity: stock.quantity })
                                    receive = stock
                                    receive.quantity = Number(transfer)
                                    delete receive.id
                                    receive.branch = this.transfer.tobranch
                                    this.transfer.details.push(receive)
                                    const stockRef = await db.collection("Stock").add(receive)
                                    await db.collection("Stock").doc(stockRef.id).update({ id: stockRef.id })

                                }
                            } else {
                                await db.collection('Stock').doc(stock.id).update({ quantity: stock.quantity })
                                receive = stock
                                receive.quantity = Number(transfer)
                                delete receive.id
                                receive.branch = this.transfer.tobranch
                                this.transfer.details.push(receive)
                                const stockRef = await db.collection("Stock").add(receive)
                                await db.collection("Stock").doc(stockRef.id).update({ id: stockRef.id })

                            }

                            transfer = 0

                        }
                    }
                }

            }

        },
        formatDate(dateString) {
            return moment(dateString).format("DD/MM/YYYY")
        },
        summarizeData() {
            const summaryMap = {}
            this.stocks.forEach(item => {
                const key = `${item.branch}-${item.item_code}`
                if (summaryMap[key]) {
                    summaryMap[key].quantity += item.quantity
                } else {
                    summaryMap[key] = {
                        branch: item.branch,
                        item_code: item.item_code,
                        quantity: item.quantity
                    }
                }
            })
            this.summary = Object.values(summaryMap)
        },
        handleProductChanged(index) {
            const item = this.transfer.product_items[index]

            const selectedProduct = this.summary.find(product => product.item_code === item.item_code && product.branch === this.transfer.frombranch)

            if (selectedProduct) {
                this.$set(this.transfer.product_items, index, { ...item, quantity: selectedProduct.quantity, })
            } else {
                this.$set(this.transfer.product_items, index, { ...item, quantity: 0, })
            }

        },
        addProduct() {
            this.transfer.product_items.push({})
        },
        showTransferModal(params) {
            if (params) {
                this.transfer = { ...params.row }
                this.isEditCard = true

            } else {
                this.transfer = { frombranch: null, tobranch: null, product_items: [] }
                this.isEditCard = false
            }
            this.$bvModal.show("transfer-item-modal")
            
        },
        async saveTransfer() {
            let loader = this.$loading.show({})

            if ((!this.transfer.frombranch && this.transfer.frombranch !== 0) || (!this.transfer.tobranch && this.transfer.tobranch !== 0)) {
                this.$alert("please enter Branch completely", null, "error")
                loader.hide()
                return
            }
            if (this.transfer.frombranch == this.transfer.tobranch) {
                this.$alert("please dont enter same Branch", null, "error")
                loader.hide()
                return
            }
            for (let i of this.transfer.product_items) {
                if (i.transfer > i.quantity || i.quantity == 0) {
                    this.$alert("please enter transfer < stock left", null, "error")
                    loader.hide()
                    return 
                }
                i.transfer = Number(i.transfer)
            }
            this.transfer.issue_date = moment().format('DD/MM/YYYY HH:mm')
            await this.DeductStock()

            let transfer = { ...this.transfer }
            const transferRef = await db.collection("Transfer").add(transfer)
            await db.collection("Transfer").doc(transferRef.id).update({ id: transferRef.id })
            this.transfer.id = transferRef.id

            loader.hide()
            this.$alert("success", null, "success")
            this.$bvModal.hide("transfer-item-modal")
        },
        deleteTransferItem(id) {

        },
        filterProduct(options, search) {
            return options.filter(option => {
                const label = option.item_name + ' ' + option.item_other_name
                return this.filterProductBy(option, label, search)
            })
        },
        filterProductBy(_, label, search) {
            return (label || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
        },


    },
    computed: {
        prepared_products() {
            return this.medicines.map(i => {
                return {
                    item_name: i.item_name || null,
                    item_code: i.item_code || null,
                    item_other_name: i.item_other_name || '',
                    quantity: i.quantity || 0,
                }
            })
        },
        prepared_transfers() {
            const filteredData = this.transfers.filter(item => item.frombranch !== null && item.tobranch !== null)
            return filteredData.sort((a,b)=>{
                const dateA = moment(a.issue_date, 'DD/MM/YYYY HH:mm')
                const dateB = moment(b.issue_date, 'DD/MM/YYYY HH:mm')
                return dateB - dateA
            })
        },

    }
}
</script>