import Statistic from '../pages/Statistic/index.vue'
import Address from '../pages/Statistic/Address.vue'
import Demographic from '../pages/Statistic/Demographic.vue'
import Discovery from '../pages/Statistic/Discovery.vue'
import Hotel from '../pages/Statistic/Hotel.vue'
import Patient from '../pages/Statistic/Patient.vue'
import Register from '../pages/Statistic/Register.vue'
import Time from '../pages/Statistic/Time.vue'
import Visit from '../pages/Statistic/Visit.vue'
export default [
  {
    path: "/statistic",
    name: "Statistic",
    component: Statistic,
    redirect: { name: "Statistic Patient" },
    children: [
      {
        path: "",
        name: "Statistic Patient",
        component: Patient
      },
      {
        path: "/discovery",
        name: "Statistic Discovery",
        component: Discovery
      },
      {
        path: "/hotel",
        name: "Statistic Hotel",
        component: Hotel
      },
      {
        path: "/address",
        name: "Statistic Address",
        component: Address
      },
      {
        path: "/demographic",
        name: "Statistic Demographic",
        component: Demographic
      },
      {
        path: "/register",
        name: "Statistic Register",
        component: Register
      },
      {
        path: "/visit",
        name: "Statistic Visit",
        component: Visit
      },
      {
        path: "/time",
        name: "Statistic Time",
        component: Time
      }
    ]
  }
];

