<template>
  <div class="container-fluid">
    <!-- <div class="card shadow">
      <div class="card-body"> -->
    <div class="bg-white">
      <div class="pd10">
        <div class="p-3">
          <transfer-header :form="form" :patient="patient"/>
        </div>
        <div class="text-center mt-3">
          <span class="label-h">Transfer Form</span>
        </div>
        <div>
          <div class="input-group">
            <label>Transfer date : </label> 
            <input class="form-control ml-2" type="text" v-model="transfer_date"/>
          </div>
        </div>
        <div class="mt-2">
          <div class="input-group">
            <label>From : </label> 
            <input class="form-control ml-2" type="text" v-model="from"/>
          </div>
        </div>
        <div class="mt-2">
          <div class="input-group">
            <label>To Physician : </label> 
            <input class="form-control ml-2" type="text" v-model="to_physician"/>
          </div>
        </div>
        <div class="mt-2">
          <div class="d-flex">
            <div>
              <span>Transfer for :</span>
            </div>
            <div>
              <div class="form-check form-check-inline ml-5">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                />
                <label class="form-check-label" for="inlineCheckbox1"
                  >Treatment</label
                >
              </div>
              <div class="form-check form-check-inline ml-5">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox2"
                />
                <label class="form-check-label" for="inlineCheckbox2"
                  >Investigation</label
                >
              </div>
              <div class="form-check form-check-inline ml-5">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox3"
                />
                <label class="form-check-label" for="inlineCheckbox3"
                  >Observation</label
                >
              </div>
              <div class="form-check form-check-inline ml-5">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox4"
                />
                <label class="form-check-label" for="inlineCheckbox4"
                  >other</label
                >
              </div>
            </div>
            <div>
              <input class="form-control ml-2" type="text" id="input4" />
            </div>
          </div>
          <div class="bg-gray text-center box-border p-3 mt-3">
            <span class="label-h ml-5"
              ><b>CHIEF COMPLAINT AND PRESENT ILLNESS</b></span
            >
            <button class="btn btn-outline-primary" style="float: right">
              EDIT
            </button>
          </div>
          <div class="box-border">
            <div class="input-group mt-2">
              <label>CC : </label> 
              <input class="form-control ml-2" type="text" v-model="form.cc_name"/>
            </div>
            <div class="input-group mt-2">
              <label>PI : </label> 
              <input class="form-control ml-2" type="text" v-model="form.present_illness"/>
            </div>
          </div>
          <div class="bg-gray text-center box-border p-3">
            <span class="label-h ml-5"><b>PHYSICAL EXAMINATION</b></span>
            <!-- <button class="btn btn-outline-primary" style="float: right">
              EDIT
            </button> -->
          </div>
          <div class="pl-4 pr-4 box-border">
            <table class="table table-bordered text-center">
              <thead class="bg-gray">
                <tr>
                  <th rowspan="2">Time</th>
                  <th colspan="2">BP</th>
                  <th rowspan="2">PR<br />/min</th>
                  <th rowspan="2">RR<br />/min</th>
                  <th rowspan="2">Temp<br />°C</th>
                  <th rowspan="2">O2Sat (RA)<br />%</th>
                </tr>
                <tr>
                  <th>Systolic</th>
                  <th>Diastolic</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div>
              <span><b>Physical Exam</b></span>
              <div class="d-flex mt-2">
                <div class="flex-even">
                  <table class="table table-bordered">
                    <tr>
                      <td class="bg-gray" width="250">
                        <b>General Appearance</b>
                      </td>
                      <td class="text-center"></td>
                    </tr>
                    <tr>
                      <td class="bg-gray" width="250"><b>Skin</b></td>
                      <td class="text-center"></td>
                    </tr>
                    <tr>
                      <td class="bg-gray" width="250"><b>HEENT</b></td>
                      <td class="text-center"></td>
                    </tr>
                    <tr>
                      <td class="bg-gray" width="250">
                        <b>Respiratory (RS)</b>
                      </td>
                      <td class="text-center"></td>
                    </tr>
                    <tr>
                      <td class="bg-gray" width="250">
                        <b>Cardiocascular (CVS)</b>
                      </td>
                      <td class="text-center"></td>
                    </tr>
                    <tr>
                      <td class="bg-gray" width="250"><b>Abdomen</b></td>
                      <td class="text-center"></td>
                    </tr>
                    <tr>
                      <td class="bg-gray" width="250"><b>Extremities</b></td>
                      <td class="text-center"></td>
                    </tr>
                    <tr>
                      <td class="bg-gray" width="250"><b>Neuro</b></td>
                      <td class="text-center"></td>
                    </tr>
                    <tr>
                      <td class="bg-gray" width="250"><b>Other</b></td>
                      <td class="text-center"></td>
                    </tr>
                  </table>
                </div>
                <div class="flex-2 text-center">
                  <img src="/images/body.png" height="350" />
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray text-center box-border p-3 d-flex">
            <div class="flex-even">
              <!-- <button class="btn btn-outline-primary" style="float: left">
                Print result of current Visit and Admission
                <i class="fa fa-print"></i>
              </button> -->
            </div>
            <div class="flex-even">
              <span class="label-h"><b>INVESTIGATION</b></span>
            </div>
            <div class="flex-even">
              <!-- <button class="btn btn-outline-primary" style="float: right">
                EDIT
              </button> -->
            </div>
          </div>
          <div class="box-border pl-5 pr-5">
            <!-- <div class="d-flex mt-3">
              <div class="flex-even"><b>X-Ray</b></div>
              <div class="flex-even">Left Foot (AP, Lateral)</div>
              <div class="flex-even">05/08/2020</div>
              <div class="flex-even">10:00</div>
              <div class="flex-even">Result attached</div>
            </div>
            <div class="d-flex mt-3">
              <div class="flex-even"><b>EKG</b></div>
              <div class="flex-even">12 Lead</div>
              <div class="flex-even">05/08/2020</div>
              <div class="flex-even">10:15</div>
              <div class="flex-even">Result attached</div>
            </div>
            <div class="d-flex mt-3">
              <div class="flex-even"><b>Laboratory</b></div>
              <div class="flex-even">CBC, BUN, Crea, E'Lyte</div>
              <div class="flex-even">05/08/2020</div>
              <div class="flex-even">10:16</div>
              <div class="flex-even">Result attached</div>
            </div>
            <div class="d-flex mt-3">
              <div class="flex-even"><b>Laboratory</b></div>
              <div class="flex-even">PT,PTT,INR</div>
              <div class="flex-even">05/08/2020</div>
              <div class="flex-even">10:16</div>
              <div class="flex-even">Pending</div>
            </div> -->
          </div>
          <!-- <div> -->
          <table class="table table-bordered mb-0">
            <thead class="bg-gray box-border">
              <tr>
                <th class="text-center" width="20%">
                  <span class="label-h">ICD-10 CODE</span>
                </th>
                <th class="text-center" width="60%" style="border-right: none;">
                  <span class="label-h">DIAGNOSIS</span>
                </th>
                <th width="20%" style="border-left: none;">
                  <button class="btn btn-outline-primary" style="float: right">
                    EDIT
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center"><b></b></td>
                <td colspan="2">
                  
                </td>
              </tr>
            </tbody>
          </table>
          <!-- </div> -->
          <div class="bg-gray text-center box-border p-3">
            <span class="label-h ml-5"><b>PROBLEM LIST</b></span>
            <button class="btn btn-outline-primary" style="float: right">
              EDIT
            </button>
          </div>
          <div class="box-border pl-4 pt-4">
            <ul>
              <!-- <li></li> -->
            </ul>
          </div>
          <div class="bg-gray text-center box-border p-3">
            <span class="label-h ml-5"><b>TREATMENT</b></span>
            <button class="btn btn-outline-primary" style="float: right">
              EDIT
            </button>
          </div>
          <div class="box-border pl-5 pt-4">
            <!-- <div class="d-flex">
              <div class="flex-even">
                <span
                  ><b><u>One day order</u></b></span
                >
              </div>
              <div class="flex-even"></div>
              <div class="flex-even"></div>
              <div class="flex-even">
                <b><u>Start</u></b>
              </div>
              <div class="flex-even"></div>
            </div>
            <div class="d-flex">
              <div class="flex-even">● Admit</div>
              <div class="flex-even"></div>
              <div class="flex-even"></div>
              <div class="flex-even">01/08/2020 04:00</div>
              <div class="flex-even"></div>
            </div>
            <div class="d-flex">
              <div class="flex-even">● Suture</div>
              <div class="flex-even">
                Left Foot, under local anesthesia, Nylon 3-0
              </div>
              <div class="flex-even"># 5 stitches</div>
              <div class="flex-even">05/08/2020 05:32</div>
              <div class="flex-even"></div>
            </div>
            <div class="d-flex">
              <div class="flex-even">● Debridement</div>
              <div class="flex-even">Right Foot, under local anesthesia</div>
              <div class="flex-even"></div>
              <div class="flex-even">05/08/2020 05:50</div>
              <div class="flex-even"></div>
            </div>
            <div class="d-flex mt-5">
              <div class="flex-even">
                <span
                  ><b><u>Continue order</u></b></span
                >
              </div>
              <div class="flex-even"></div>
              <div class="flex-even"></div>
              <div class="flex-even">
                <b><u>Start</u></b>
              </div>
              <div class="flex-even">
                <b><u>End</u></b>
              </div>
            </div>
            <div class="d-flex">
              <div class="flex-even">● record vital sign</div>
              <div class="flex-even"></div>
              <div class="flex-even"></div>
              <div class="flex-even">01/08/2020 04:00</div>
              <div class="flex-even">Continue</div>
            </div>
            <div class="d-flex">
              <div class="flex-even">● NPO</div>
              <div class="flex-even"></div>
              <div class="flex-even"></div>
              <div class="flex-even">01/08/2020 04:00</div>
              <div class="flex-even">Continue</div>
            </div>
            <div class="d-flex">
              <div class="flex-even">● Elevate head 45 degree</div>
              <div class="flex-even"></div>
              <div class="flex-even"></div>
              <div class="flex-even">01/08/2020 04:00</div>
              <div class="flex-even">02/08/2020 10:00</div>
            </div>
            <div class="d-flex">
              <div class="flex-even">● Ceftriaxone 2 g</div>
              <div class="flex-even">IV OD q 24 hours</div>
              <div class="flex-even"># 10 ampules</div>
              <div class="flex-even">01/08/2020 04:00</div>
              <div class="flex-even">04/08/2020 10:52</div>
            </div>
            <div class="d-flex">
              <div class="flex-even">● Paracetamol(500)</div>
              <div class="flex-even">2 tablets PO prn for pain q 6 hours</div>
              <div class="flex-even"># 20 tablets</div>
              <div class="flex-even">01/08/2020 04:00</div>
              <div class="flex-even">Continue</div>
            </div> -->
          </div>
          <med-cert-footer></med-cert-footer>
        </div>
      </div>
    </div>
    <!-- </div>
    </div> -->
  </div>
</template>

<script>
export default {
  mounted() {
    console.log('[+]patient', this.patient)
  },
  props: {
    form: {
      default: () => {
        return {}
      }
    },
    refer: {
      default: () => {
        return {}
      }
    },
    patient: {
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      transfer_date: this.$moment().format('DD/MM/YYYY'),
      from: null,
      to_physician: null,
      // date picker
      stdDateConfig: {
        altInput: true,
        altFormat: "d/m/Y"
      },
      stdDateTimeConfig: {
        altInput: true,
        altFormat: "d/m/Y H:i",
        enableTime: true,
        enableSeconds: false,
        time_24hr: true
      },
      stdTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      }
    };
  }
};
</script>
<style lang="scss" scoped>
//Main css
table.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
table.table-bordered > thead > tr > th {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
// table.table-bordered > tbody > tr > td{
//   border:1px solid rgba(0, 0, 0, 0.2);
// }

.input-un {
  width: 100%;
  background-color: #fcfcfc;
  border: 0;
  border-bottom: 2px solid lightgrey;
  padding: 10px;
}
.transfer-txt {
  font-size: 20px;
}
.holder {
  height: 62px;
}
.label-inside {
  margin-bottom: -50px;
  padding-left: 5px;
  padding-top: 0px;
  position: absolute;
  color: rgb(116, 116, 116);
  font-size: 16px;
}
.input-inside {
  height: 100%;
  padding-bottom: -30px;
}

//end main
.mid {
  padding: 20px;
  margin-top: 50px;
}
.btn {
  background-color: rgb(212, 212, 212);
}
.center {
  text-align: center;
}
//margin padding
.ml2 {
  margin-left: 8px;
}
.mt2 {
  margin-top: 8px;
}
.mt3 {
  margin-top: 10px;
}
.pd10 {
  padding: 10px;
}
.mg-t {
  margin-top: 18px;
}
.bg-gray {
  background-color: #efefef;
}
.bg-light {
  background-color: #fff;
}
.bg-red {
  background-color: rgb(243, 187, 187);
}
.bg-orange {
  background-color: #fff2cc;
}
.bg-blue {
  background-color: #cfe2f3;
}
.bg-w {
  background-color: #fff;
}
.bg-hblue {
  background-color: rgb(17, 85, 204);
}
.bg-horange {
  background-color: rgb(230, 145, 56);
}
.pd-side {
  padding-left: 5px;
  padding-right: 5px;
}
.label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}
.box-border {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
}
.sign-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  width: 40%;
  height: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}
.sign-detail {
  padding: 20px;
  margin: auto;
  width: 40%;
  margin-bottom: 20px;
}
.label-b {
  color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}
.label-h {
  // color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 25px;
}
.esl-lv {
  border: 3px solid rgba(0, 0, 0, 0.3);
  width: 40px;
  height: 40px;
  margin-top: 10px;
  border-radius: 7px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  margin-left: 10px;
}

.form-check {
  margin-left: 10px;
}
button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.flex-even {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
input[type="checkbox"] {
  height: 17px;
  width: 17px;
  vertical-align: middle;
}
</style>
