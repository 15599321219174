var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2 alter"},[_c('patient-info',{attrs:{"patient":_vm.patient,"allergy_alert":true,"nursing":_vm.nursing,"opd":_vm.opd}}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"title-tab"},[_c('router-link',{attrs:{"custom":"","to":{
        name: 'Nurse Assessment',
        params: { id: _vm.$route.params.id, visitId: _vm.$route.params.visitId }
      }}},[_vm._v("OPD Docs")])],1)]),(_vm.$route.meta.is_opd_docs)?_c('div',[_c('div',{staticClass:"green-bar"}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"dropdown menu-tab",class:{ 'menu-tab-active': _vm.$route.meta.nursing_sheet === true ? 'active' : '' }},[_vm._v(" NURSING SHEETS "),_c('div',{staticClass:"dropdown-content"},[_c('div',{staticClass:"dropdown-item"},[_c('router-link',{attrs:{"custom":"","to":{
              name: 'Nurse Assessment',
              params: { id: _vm.$route.params.id, visitId: _vm.$route.params.visitId }
            }}},[_vm._v("Nurse Assessment")])],1),_c('div',{staticClass:"dropdown-item disabled-item"},[_vm._v(" Observation Sheet (WIP.) ")]),_c('div',{staticClass:"dropdown-item"},[_c('router-link',{attrs:{"to":{
              name: 'Discharge Status',
              params: { id: _vm.$route.params.id, visitId: _vm.$route.params.visitId }
            }}},[_vm._v("Discharge Status")])],1),_c('div',{staticClass:"dropdown-item"},[_c('router-link',{attrs:{"to":{
              name: 'Transfer',
              params: { id: _vm.$route.params.id, visitId: _vm.$route.params.visitId }
            }}},[_vm._v("Transfer Form")])],1)])]),_c('div',{staticClass:"dropdown menu-tab",class:{ 'menu-tab-active': _vm.$route.meta.doctor_sheet === true ? 'active' : '' }},[_vm._v(" DOCTOR SHEETS "),_c('div',{staticClass:"dropdown-content"},[_c('div',{staticClass:"dropdown-item"},[_c('router-link',{attrs:{"to":{
              name: 'OPD Card',
              params: { id: _vm.$route.params.id, visitId: _vm.$route.params.visitId }
            }}},[_vm._v("OPD Card")])],1)])]),_c('div',{staticClass:"menu-tab",class:{ 'menu-tab-active': _vm.$route.name === 'Investigation Results' ? 'active' : '' }},[_c('router-link',{attrs:{"to":{
          name: 'Investigation Results',
          params: { id: _vm.$route.params.id, visitId: _vm.$route.params.visitId }
        }}},[_vm._v("INVESTIGATION")])],1),_c('div',{staticClass:"dropdown menu-tab",class:{ 'menu-tab-active': _vm.$route.meta.medcert === true ? 'active' : '' }},[_vm._v(" CERTIFICATES "),_c('div',{staticClass:"dropdown-content"},[_c('div',{staticClass:"dropdown-item",staticStyle:{"opacity":"0.5"}},[_vm._v(" General Medical Certificate ")]),_c('div',{staticClass:"dropdown-item",staticStyle:{"opacity":"0.5"}},[_vm._v(" Fit to fly ")]),_c('div',{staticClass:"dropdown-item",staticStyle:{"opacity":"0.5"}},[_vm._v(" Work Permit ")]),_c('div',{staticClass:"dropdown-item",staticStyle:{"opacity":"0.5"}},[_vm._v(" Insurance "),_c('router-link',{attrs:{"to":{
              name: 'Medical Certificate for Insurance',
              params: { id: _vm.$route.params.id, visitId: _vm.$route.params.visitId }
            }}},[_vm._v("Insurance")])],1),_c('div',{staticClass:"dropdown-item"},[_c('router-link',{attrs:{"to":{
              name: 'Medical Certificate for SCUBA PADI',
              params: { id: _vm.$route.params.id, visitId: _vm.$route.params.visitId }
            }}},[_vm._v("SCUBA (PADI)")])],1)])]),_vm._m(0),_c('div',{staticClass:"ml-2"},[_c('div',{staticClass:"right-item"},[_c('router-link',{attrs:{"to":{
            name: 'Doctor Order',
            params: { id: _vm.$route.params.id, visitId: _vm.$route.params.visitId }
          }}},[_vm._v("VIEW ORDER SUMMARY")])],1)])]),_c('div',{staticClass:"green-bar"})]):_vm._e(),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('router-view',{staticClass:"mt-2",attrs:{"patient":_vm.patient,"opd":_vm.opd,"nursing":_vm.nursing}})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-auto"},[_c('div',{staticClass:"right-item"},[_vm._v(" DOCUMENTS ")])])
}]

export { render, staticRenderFns }