<template>
  <div class="container-fluid">
    <vue-good-table
      :columns="columns"
      :rows="patients"
      :pagination-options="{
        enabled: true
      }"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'details'">
          <div class="text-primary">
            <b>RM: {{props.formattedRow.details.RM}}</b>
          </div>
          <div class="text-dark">
            <b>{{ props.formattedRow.details.name }}</b>
          </div>
          <div
            class="esi-box"
            :style="{
              color: props.formattedRow.details.esi == 5 ? 'dark' : 'white',
              'background-color': getEsiColor(props.formattedRow.details.esi)
            }"
          >
            ESI level {{ props.formattedRow.details.esi }}
          </div>
          <div class="text-muted">
            <i>AN: {{ props.formattedRow.details.AN }}</i>
          </div>
        </div>
        <div v-else-if="props.column.field == 'allergies'">
          <div class="text-danger" v-for="item of props.formattedRow.allergies" :key="item"><b>{{ item }}</b></div>
        </div>
        <div v-else-if="props.column.field == 'orders'">
          <div class="mb-2"  v-for="item of props.formattedRow.orders" :key="item">
            <button class="btn btn-light btn-block shadow">{{ item }} <span class="badge badge-danger">1</span></button>
          </div>
        </div>
        <div v-else-if="props.column.field == 'admission_date'">
          <div class="text-center">{{props.formattedRow.admission_date.date}}</div>
          <div class="text-center">{{props.formattedRow.admission_date.time}}</div>
        </div>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        {
          label: "Patient Details",
          field: "details"
        },
        {
          label: "Allergy",
          field: "allergies"
        },
        {
          label: "Admission Date/Time",
          field: "admission_date"
        },
        {
          label: "New Order",
          field: "orders"
        }
      ],
      patients: []
    };
  },
  methods: {
    onRowClick(params) {
      this.$router.push(`/patient/${params.row.hn}`);
    },
    getEsiColor(esi) {
      switch (esi) {
        case 1:
          return "#CB0000";
          break;
        case 2:
          return "#EA9A99";
          break;
        case 3:
          return "#F1C331";
          break;
        case 4:
          return "#629B49";
          break;
        case 5:
          return "#FFFFFF";
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.esi-box {
  padding: 5px;
  font-weight: 800;
  border-radius: 0.25em;
  border: 1px solid #adadad;
}
</style>
