<template>
  <b-modal id="new-patient-payment-modal" hide-footer size="xl" header-bg-variant="gray"
    :title="`${payment.payment_id ? 'Payment #' + payment.payment_id : 'New Payment'}`">
    <div class="container-fluid">      
      <div class="row mt-2">
        <div class="col-1">
          <div class="text-primary">From</div>
        </div>
        <div class="col-3">
          <b>WORLDMED CENTER</b>
          <p>125/131-133, M.7 Phi Phi Island, T.Aonang A.MuangKrabi Krabi, 81210 Thailand</p>
          <div class="mt-2">
            <b>WORLDMED CENTER Co., Ltd.</b>
            <div>0835557004354</div>
          </div>
        </div>
        <div class="col-4">
          <div class="d-flex mb-2">
            <label class="text-primary mr-auto">Patient:</label>
            <p>{{ payment.patient_name }}</p>
          </div>
          <div class="d-flex mb-2">
            <label class="text-primary mr-auto">Billed to:</label>
            <p>{{ payment.billed_to }}</p>
          </div>
          <div class="d-flex mb-2">
            <label class="text-primary">Concession type:</label>
            <multiselect class="ml-2" selectLabel="" v-model="payment.concession_type"
              :options="['Resident', 'Foreigner', 'Insurance']" />
          </div>
          <div class="d-flex mb-2" v-if="payment.concession_type === 'Insurance'">
            <label class="text-primary">Insurance </label>
            <multiselect class="ml-2" selectLabel="" v-model="payment.insurance_name" :options="[
              'Other',
              ..._.sortBy($store.state.insurances.map(c => {
                return c.name_en
              }))
            ]" />
          </div>
          <div class="d-flex mb-2" v-if="payment.concession_type === 'Insurance' && payment.insurance_name === 'Other'">
              <label class="text-primary">Other insurance: </label>
              <input type="text" class="form-control ml-2" v-model="payment.insurance_name_other"
                placeholder="Other Insurance Name">
            </div>
            <div class="d-flex mb-2" v-if="payment.concession_type === 'Insurance'">
              <label class="text-primary">Assist insurance: </label>
              <input type="text" class="form-control ml-2" v-model="payment.assist_insurance"
                placeholder="Assist Insurance Name">
            </div>
        </div>

        <div class="col-4">
          <div class="d-flex mb-2">
            <label class="text-secondary mr-auto">Issue Date</label>
            <!-- <div class="text-right" v-if="!$moment(payment.issue_date)">{{ payment.issue_date.toDate() | moment('HH:mm DD MMM YYYY') }} IF</div> -->
            <MazPicker v-if="(!payment.payment_id) && $route.name == 'Insurance'" v-model="payment.issue_date" :format="`DD/MM/YYYY HH:mm`" :formatted="`DD/MM/YYYY HH:mm`"
            placeholder="Issue Date" />
            <div class="text-right" v-if="payment.issue_date.toDate">{{ payment.issue_date.toDate() | moment('HH:mm DD MMM YYYY') }}</div>
          </div>
          <div class="d-flex mb-2" v-if="payment.payment_id">
            <label class="text-primary mr-auto">Payment#</label>
            <div class="text-right">{{ payment.payment_id }}</div>
          </div>
          <div class="d-flex mb-2" v-if="payment.invoice_id">
            <label class="text-primary mr-auto">Invoice#</label>
            <div class="text-right">{{ payment.invoice_id }}</div>
          </div>
          <div class="d-flex mb-2" v-if="payment.HN">
            <label class="text-secondary mr-auto">HN</label>
            <div class="text-right">{{ payment.HN }}</div>
          </div>
          <div class="d-flex mb-2" v-if="payment.VN">
            <label class="text-secondary mr-auto">Visit Number (VN)</label>
            <div class="text-right">{{ payment.VN }}</div>
          </div>          
          <div class="d-flex mb-2">
            <label class="text-secondary mr-auto">Practitioner</label>
            <multiselect class="ml-2" selectLabel="" v-model="payment.practitioner" :options="doctors.map(d => {
              return d.displayName
            })" />
          </div>
          <div class="d-flex mb-2">
            <label class="text-secondary mr-auto">Type:</label>
            <p>{{ payment.type }}</p>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="container-fluid">
      <div class="row">
        <div class="col-8">
          <h3 class="text-primary">Payment Source</h3>
          <div class="row mt-2">
            <div class="col-4">
              <addable-select v-model="payment.credit_bank_name" :model="'CreditCard'" :placeholder="`Select Bank`"
                :options="creditcards.map(item => item.name)" />
            </div>
            <div class="col-8">
              <input-number v-model="payment.credit"></input-number>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <label>CASH</label>
            </div>
            <div class="col-8">
              <input-number v-model="payment.cash"></input-number>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <label>INSURANCE</label>
            </div>
            <div class="col-8">
              <input-number v-model="payment.insurance"></input-number>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <label>INTERNET BANKING</label>
            </div>
            <div class="col-8">
              <input-number v-model="payment.internet_banking"></input-number>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <multiselect :style="`width: 100%`" selectLabel="" v-model="payment.other_name" :options="[
                'Other',
                'Alipay',
                'Apple Pay',
                'LINE',
                'Payoneer',
                'Paypal',
                'True wallet',
                'Wechat',
                'Wire',
                'Promptpay'
              ]" />
            </div>
            <div class="col-8">
              <input-number v-model="payment.other"></input-number>
            </div>
          </div>
          <div class="row mt-2" v-if="payment.credit > 0">
            <div class="col-4">
              Extra Charge
            </div>
            <div class="col-8">
              <select v-model="payment.extra_charge" class="form-control">
                <option :value="0.03">3% BANK FEE FOR VISA/MASTER CARD</option>
                <option :value="0.05">5% BANK FEE FOR AMERICAN EXPRESS/ UNION PAY</option>
              </select>
            </div>
          </div>
          <hr>
          <div class="d-flex mt-2">
            <label style="width: 120px;">Payment Total</label>
            <div class="ml-2">{{ (Number(payment.credit) + Number(payment.cash) + Number(payment.other) +
              Number(payment.insurance) + Number(payment.internet_banking)).toLocaleString() }}</div>
          </div>
        </div>
        <div class="col-4">
          <h3 class="text-primary">Payment Summary</h3>
          <div class="d-flex mt-2">
            <label style="width: 120px;">Total Invoiced</label>
            <div class="ml-auto">{{ total_invoiced.toLocaleString() }}</div>
          </div>
          <div class="d-flex mt-2">
            <label style="width: 120px;">Payment Applied</label>
            <div class="ml-auto">{{ payment_applied.toLocaleString() }}</div>
          </div>
          <div class="d-flex mt-2">
            <label style="width: 120px;">Outstanding Balance</label>
            <div class="ml-auto">{{ total_outstanding.toLocaleString() }}</div>
          </div>
          <div class="mt-2">
            <label class="text-primary">Note:</label>
            <textarea class="form-control" v-model="payment.note"></textarea>
          </div>
          <div class="mt-2">
            <label class="text-secondary">Note For Team:</label>
            <textarea class="form-control" v-model="payment.noteforteam"></textarea>
          </div>
        </div>
      </div>
    </div>

    <label class="text-primary mt-4" v-if="payment.history">History</label>
    <div class="row mt-2" v-if="payment.history && payment.history.length > 0">
      <div class="col-12 text-grey" v-for="(item, index) of payment.history" :key="index">
        [{{ item.datetime.toDate() | moment('DD/MM/YYYY HH:mm') }}] User: <span
          class="text-primary">{{ item.user.displayName }} ({{ item.user.uid }})</span> Action:<span
          class="text-primary">{{ item.type }}</span>
      </div>
    </div>


    <div class="d-flex mt-2 mb-2">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="HidePriceChecked" v-model="hidePrice">
        <label class="form-check-label" for="HidePriceChecked">Hide Details</label>
      </div>
      <router-link v-if="payment.payment_id && !hidePrice" :to="`/print/receipt/${payment.payment_id}`" target="_blank"
        class="btn btn-light" style="margin-left: 10px; width: 150px;">
        <i class="fas fa-print"></i> Print Full Receipt
      </router-link>
      <router-link v-if="payment.payment_id && hidePrice" :to="`/print/receipt/${payment.payment_id}?mode=hide`" target="_blank"
        class="btn btn-light" style="margin-left: 10px; width: 150px;">
        <i class="fas fa-print"></i> Print Full Receipt
      </router-link>
      <router-link v-if="payment.payment_id" :to="`/print/receipt/${payment.payment_id}?mode=short`" target="_blank"
        class="btn btn-primary" style="margin-left: 10px; width: 150px;">
        <i class="fas fa-print"></i> Print Receipt
      </router-link>
      <button v-if="total_outstanding !== 0" class="btn btn-primary ml-auto" @click="savePayment"><i
          class="fas fa-save"></i> Pay</button>
    </div>
  </b-modal>
</template>

<script>
import { db, Timestamp } from '../../db'
import { generateRandomString } from '../../helper'
import moment from "moment"
export default {
  props: ['payment', 'total_invoiced', 'payment_applied', 'total_outstanding'],
  data() {
    return {
      hidePrice: true,
      doctors: [],
      creditcards: []
    }
  },
  firestore() {
    return {
      doctors: db.collection('User').where('roles', 'array-contains', 'doctor').where('isApproved', '==', true),
      creditcards: db.collection('CreditCard')
    }
  },
  methods: {
    async savePayment() {
      const loader = this.$loading.show({});
      const totalPayment = Number(this.payment.cash) + Number(this.payment.credit) + Number(this.payment.insurance) + Number(this.payment.internet_banking) + Number(this.payment.other)

      if (totalPayment <= 0) {
        this.$alert('Invalid payment value')
        loader.hide()
        return
      }

      const payment = this.payment

      if (!payment.payment_id) {
        payment.history = [{
          type: 'create',
          datetime: Timestamp.fromDate(new Date()),
          user: {
            uid: this.$store.state.user.uid,
            displayName: this.$store.state.user.displayName,
            email: this.$store.state.user.email
          }
        }]
      } else {
        payment.history.push({
          type: 'edit',
          datetime: Timestamp.fromDate(new Date()),
          user: {
            uid: this.$store.state.user.uid,
            displayName: this.$store.state.user.displayName,
            email: this.$store.state.user.email
          }
        })
      }

      try {
        let sod = this.$moment().startOf('day').toDate()
        let eod = this.$moment().endOf('day').toDate()
        let latestPayment = await db.collection('Payment')
          .where('issue_date', '<', eod)
          .where('issue_date', '>=', sod)
          .get()
        let paymentsToday = latestPayment.docs.length
        let payment_id
        
        const randomset = generateRandomString()
        if (this.payment.payment_id) {
          payment_id = this.payment.payment_id
        } else {
          payment_id = `${String(this.$store.state.branch).padStart(2, '0')}-${this.$moment().format('YYYY')}-${String(paymentsToday + 1).padStart(4, '0')}-${this.$moment().format('MM-DD')}${randomset}`
        }
        if (!this.payment.insurance_name) payment.insurance_name = null
        if (!this.payment.insurance_name_other) payment.insurance_name_other = null
        payment.payment_id = payment_id
        if(this.$route.name == 'Insurance'){
          payment.issue_date = moment(payment.issue_date, 'DD/MM/YYYY HH:mm').toDate()
        }
        
        payment.cash = Number(this.payment.cash) || 0
        payment.credit = Number(this.payment.credit) || 0
        payment.insurance = Number(this.payment.insurance) || 0
        payment.internet_banking = Number(this.payment.internet_banking) || 0
        payment.other = Number(this.payment.other) || 0
        payment.total_payment = payment.cash + payment.credit + payment.insurance + payment.internet_banking + payment.other

        await db.collection('Payment').doc(payment_id).set(payment)

        //update status
        const invoiceRef = await db.collection('Invoice').doc(payment.invoice_id).get()
        const invoice = {
          ...invoiceRef.data()
        }
        const payment_applied = await this.totalPaymentApplied(invoice)
        if (Number(invoice.total_invoiced) === Number(payment_applied)) {
          await db.collection('Invoice').doc(payment.invoice_id).update({
            status: 'paid',
            payment_applied
          })
        } else {
          await db.collection('Invoice').doc(payment.invoice_id).update({
            status: 'outstanding',
            payment_applied
          })
        }
        loader.hide()
        this.$alert("Payment completed.", null, "success");
        // this.showPayment(payment_id)
        this.$emit('displayPaymentModal', payment_id)
      } catch (error) {
        console.error(error)
        this.$alert(`error`, error)
        loader.hide()
      }
    },
    async totalPaymentApplied(invoice) {
      if (invoice.invoice_id) {
        try {
          let payments = await db.collection('Payment').where('invoice_id', '==', invoice.invoice_id).get()
          let total_payment_applied = 0
          for (let payment of payments.docs) {
            total_payment_applied += payment.data().total_payment
          }
          return total_payment_applied
        } catch (error) {
          return 0
        }
      } else {
        return 0
      }
    },
  },

}
</script>