<template>
  <b-modal :id="id" title="Graph Color Setting" hide-footer>
    <div class="row mt-3" v-for="(item, index) of legends" :key="index" style="height: 30px;">
      <div class="col-4 text-right">
        {{  item  }}
      </div>
      <div class="col-8">
        <div class="select-color" @click="showColorPicker(index)" :style="`background-color: ${colors[index]}`">{{colors[index]}}</div>
        <color-picker :id="`color-picker-${index}`" :color="colors[index]" @input="onColorSelect" :legend="item"></color-picker>
      </div>
    </div>
  </b-modal>
</template>

<script>
import ColorPicker from '../../components/Modal/ColorPicker.vue'
export default {
  props: ['value', 'id', 'graphId', 'legends'],
  components: {
    ColorPicker,
  },
  data() {
    return {
      colors: {},
      selectedColor: null,
    }
  },
  methods: {
    showColorPicker(index){
      this.$bvModal.show(`color-picker-${index}`)
    },
    onColorSelect({color, legend }) {
      this.$emit('change', { color, legend })
    },
  },
  watch: {
    value: {
      handler() {
        this.colors = this._.clone(this.value)
      },
      immediate: true
    }
  },
}
</script>

<style lang="scss" scoped>
.select-color {
  border-radius: 0.5em;
  height: 35px;
  width: auto;
  cursor: pointer;
  padding: 5px;
  z-index: -1;
}
</style>