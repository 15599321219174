<template>
  <div class="container-fluid">
    <vue-good-table
      :columns="columns"
      :rows="patients"
      :pagination-options="{
        enabled: true
      }"
    >
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.label =='Daily Meds Summary'">
            <div class="text-center">Daily Meds</div>
            <div class="text-center">Summary</div>
        </span>
        <span v-else-if="props.column.label =='V/S I/O Diet Endorsement'">
            <div class="text-center">V/S</div>
            <div class="text-center">I/O</div>
            <div class="text-center">Diet</div>
            <div class="text-center mt-2">Endorsement</div>
        </span>
        <span v-else-if="props.column.label =='Admission Date/Time Discharge Plan'">
            <div class="text-center">Admission</div>
            <div class="text-center">Date/Time</div>
            <div class="text-center mt-4">Discharge Plan</div>
        </span>
        <span v-else-if="props.column.label =='Finance Passport Travel Plan'">
            <div class="text-center">Finance</div>
            <div class="text-center">Passport</div>
            <div class="text-center mt-4">Travel Plan</div>
        </span>
        <span v-else>
            {{props.column.label}}
        </span>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'index'">
          {{ props.row.originalIndex + 1 }}
        </span>
        <span v-else-if="props.column.field == 'discharge_date'">
          <div class="text-center text-primary"><b>RM: {{props.row.discharge_date.RM}}</b></div>
          <div class="text-center">{{props.row.discharge_date.date}}</div>
          <div class="text-center">{{props.row.discharge_date.time}}</div>
        </span>
        <div v-else-if="props.column.field == 'details'">
          <div class="text-dark">
            <b>{{ props.formattedRow.details.name }}</b>
          </div>
          <div class="text-dark">
            HN: {{ props.formattedRow.details.HN }}
          </div>
          <div>
            <a :href="`tel:${props.formattedRow.details.tel}`"
              ><i class="fas fa-phone"></i>
              {{ props.formattedRow.details.tel }}</a
            >
          </div>
          <div>
            <a :href="`mailto:${props.formattedRow.details.email}`"
              ><i class="fas fa-envelope"></i>
              {{ props.formattedRow.details.email }}</a
            >
          </div>
        </div>
        <div v-else-if="props.column.label == 'DR/RN'">
          <div>
            <b>{{ props.row.doctor }}</b>
          </div>
          <div>{{ props.row.nurse }}</div>
        </div>
        <div v-else-if="props.column.label == 'Follow up'">
          <div class="text-center">{{props.row.follow_up.date}}</div>
          <div class="text-center">{{props.row.follow_up.time}}</div>
          <div class="text-center">{{ props.row.doctor }}</div>
        </div>
        <div v-else-if="props.column.label == 'Finance/Note'">
          <div class="text-center mb-2 text-uppercase" :class="{
            'paid': props.row.finance_info.status === 'paid',
            'unpaid': props.row.finance_info.status === 'unpaid',
            'insurance': props.row.finance_info.status === 'insurance',
          }">
            <b>{{props.row.finance_info.status}}</b>
          </div>
          <textarea rows="3" class="form-control" placeholder="Note"></textarea>
        </div>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        {
          label: "No.",
          field: "id"
        },
        {
          label: "Discharge Date/Time",
          field: "discharge_date"
        },
        {
          label: "Patient Details",
          field: "details"
        },
        {
          label: "Diagnosis ICD 10 Code",
          field: "icd10"
        },
        {
          label: "DR/RN",
          field: "dr_rn"
        },
        {
          label: "Follow up",
          field: "follow_up"
        },
        {
          label: "Finance/Note",
          field: "finance_info"
        }
      ],
      patients: []
    };
  },
  methods: {
    onRowClick(params) {
      this.$router.push(`/patient/${params.row.hn}`);
    },
    getEsiColor(esi) {
      switch (esi) {
        case 1:
          return "#CB0000";
          break;
        case 2:
          return "#EA9A99";
          break;
        case 3:
          return "#F1C331";
          break;
        case 4:
          return "#629B49";
          break;
        case 5:
          return "#FFFFFF";
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.esi-box {
  padding: 5px;
  font-weight: 800;
  border-radius: 0.25em;
  border: 1px solid #adadad;
}

.paid {
  padding: 5px;
  border-radius: 0.25em;
}

.unpaid {
  padding: 5px;
  border-radius: 0.25em;
  color: white;
  background-color: red;
}

.insurance {
  padding: 5px;
  border-radius: 0.25em;
  color: white;
  background-color: #0b97e5;
}
</style>
