<template>
  <div class="container-fluid">
    <div>
      <table class="table table-bordered">
        <thead class="bg-gray text-center">
          <tr>
            <th>Date created</th>
            <th>Time</th>
            <th>Report No.</th>
            <th>VN</th>
            <th>Branch</th>
            <th>Dept</th>
            <th>Report type</th>
            <th>Attending Doctor</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>14/01/2020</td>
            <td>24:00</td>
            <td>04-01-2020-000016</td>
            <td>123-456-789</td>
            <td>Hospital</td>
            <td>ER/IPD</td>
            <td>Medical report for work permit</td>
            <td>Dr. Malavee Suknan</td>
          </tr>
          <tr>
            <td>12/03/2018</td>
            <td>24.00</td>
            <td>04-02-2018-001254</td>
            <td>587-965-478</td>
            <td>Hospital</td>
            <td>OPD</td>
            <td>Medical report for work permit</td>
            <td>Dr. Malavee Suknan</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-center pb-5">
      <button type="button" class="btn btn-primary btn-lg">
        <i class="fa fa-plus"></i>Create New Medical certificate for insurance
      </button>
    </div>
    <div class="card shadow">
      <div class="card-body">
        <div class="bg-white">
          <div class="pd10">
            <med-cert-header></med-cert-header>
            <h5 class="text-center label-topic m-3">
              MEDICAL CERTIFICATE FOR INSURANCE
            </h5>
            <div class="box-border">
              <div class="d-flex">
                <div class="pt-1">
                  <b>ADMITTED DATE:</b>
                </div>
                <div class="ml-3">
                  <flat-pickr
                    class="form-control"
                    :config="stdDateConfig"
                    v-model="medcert_for_insurance.admitted_date"
                  ></flat-pickr>
                </div>
              </div>

              <div class="d-flex mt-3">
                <div class="pt-1">
                  <b>ESTIMATED ADMISSION DAYS:</b>
                </div>
                <div class="ml-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="e.g. 2 days"
                    v-model="medcert_for_insurance.estimated_admission_day"
                  />
                </div>
              </div>

              <div class="d-flex mt-3">
                <div style="min-width: 150px;">
                  <b class="txt-blue"><u>CHIEF COMPLAIN:</u></b>
                </div>
                <div class="ml-3" style="min-width: 600px;">
                  <input
                    type="text"
                    class="form-control"
                    value="diarrhea, weakness, nausea, fever, loss of appetite, vomiting, stomach cramp"
                  />
                </div>
              </div>

              <div class="d-flex mt-3">
                <div style="min-width: 150px;">
                  <b class="txt-blue"><u>PRESENT ILLNESS:</u></b>
                </div>
                <div class="ml-3" style="min-width: 600px;">
                  <input
                    type="text"
                    class="form-control"
                    value="present condition"
                  />
                </div>
              </div>

              <div class="d-flex mt-3">
                <div style="min-width: 150px;">
                  <b class="txt-blue"><u>PATIENT HISTORY:</u></b>
                </div>
                <div class="ml-3" style="min-width: 600px;">
                  <input
                    type="text"
                    class="form-control"
                    value="No known allergy, No known illness"
                  />
                </div>
              </div>

              <div class="d-flex mt-3">
                <div style="min-width: 150px;">
                  <b class="txt-blue"><u>PHYSICAL EXAM</u></b>
                </div>
                <div class="ml-3">
                  <p>Temp: <u class="ml-1 mr-1">37</u> C,</p>
                </div>
                <div class="ml-2">
                  <p>PR: <u class="ml-1 mr-1">80</u> bpm,</p>
                </div>
                <div class="ml-2">
                  <p>RR: <u class="ml-1 mr-1">100</u> bpm,</p>
                </div>
                <div class="ml-2">
                  <p>BP: <u class="ml-1 mr-1">80/100</u> mmHg</p>
                </div>
              </div>

              <div class="pl-5">
                <div class="d-flex mt-2">
                  <div>
                    <b>GA:</b>
                  </div>
                  <div class="ml-4">
                    Appears weak, tired and dehydrated, no dyspnea
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div>
                    <b>HEENT:</b>
                  </div>
                  <div class="ml-4">
                    Appears pale, no jaundice, dry lips mucosa, sunken eyeballs,
                    mild redness of pharynx
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div>
                    <b>RS:</b>
                  </div>
                  <div class="ml-4">
                    Clear, regular breath sound, no wheezing, symmetrical breath
                    sounds
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div>
                    <b>CVS:</b>
                  </div>
                  <div class="ml-4">
                    normal S1S2, no murmur
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div>
                    <b>ABDOMEN:</b>
                  </div>
                  <div class="ml-4">
                    Soft, with mild tenderness, No guarding sound. No mass , no
                    rigidity, no rebound , no hyper bowel sound.
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div>
                    <b>EXTREMITIES:</b>
                  </div>
                  <div class="ml-4">
                    No significant findings
                  </div>
                </div>
              </div>

              <div class="d-flex mt-5">
                <div style="min-width: 150px;">
                  <b class="txt-blue"><u>INITIAL DIAGNOSIS:</u></b>
                </div>
                <div class="ml-3" style="min-width: 600px;">
                  <select id="pain_score" class="form-control">
                    <option value disabled selected hidden></option>
                    <option
                      value="ACUTE GASTROENTERITIS with MILD DEHYDRATION"
                      selected
                      >ACUTE GASTROENTERITIS with MILD DEHYDRATION</option
                    >
                    <option value="">ETC</option>
                  </select>
                </div>
              </div>

              <div class="d-flex mt-3">
                <div style="min-width: 150px;">
                  <b class="txt-blue"><u>MEDICATIONS:</u></b>
                </div>
                <div class="ml-3" style="min-width: 600px;">
                  <input class="form-control" type="text" id="medication" />
                </div>
                <div class="ml-2">
                  <button
                    class="btn btn-primary"
                    @click="
                      addRowList('medcert_for_insurance', 'medication', 1)
                    "
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                  <button
                    class="btn btn-secondary ml-2"
                    @click="
                      removeRowList(
                        'medcert_for_insurance',
                        'medication',
                        index
                      )
                    "
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>

              <div class="d-flex mt-3">
                <div style="min-width: 150px;">
                  <b class="txt-blue"><u>LABORATORIES:</u></b>
                </div>
                <div class="ml-3" style="min-width: 600px;">
                  <select id="laboratory" class="form-control">
                    <option value disabled selected hidden></option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                  </select>
                </div>
              </div>

              <div class="pl-5 mt-4">
                <div class="d-flex mt-2">
                  <div>
                    <b>X-RAY</b>
                  </div>
                  <div class="ml-4">
                    Left Foot (AP, Lateral)
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div>
                    <b>EKG</b>
                  </div>
                  <div class="ml-4">
                    12 Lead
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div>
                    <b>LABORATORY</b>
                  </div>
                  <div class="ml-4">
                    CBC, BUN, Crea, E’Lyte
                  </div>
                </div>
              </div>

              <div class="d-flex mt-4">
                <div style="min-width: 150px;">
                  <b class="txt-blue"><u>FOLLOW UP LABORATORIES:</u></b>
                </div>
                <div class="ml-4">
                  Follow up
                </div>
              </div>

              <div class="d-flex p-5">
                <div>
                  <b>STOOL EXAMINATION</b>
                </div>
                <div class="ml-4">
                  - waiting for the pt to collect
                </div>
              </div>

              <div class="d-flex mt-3">
                <div style="min-width: 150px;">
                  <b class="txt-blue"><u>PLAN:</u></b>
                </div>
                <div class="ml-3" style="min-width: 600px;">
                  <textarea
                    class="form-control"
                    id=""
                    cols="30"
                    rows="4"
                  ></textarea>
                </div>
              </div>
            </div>
            <med-cert-footer></med-cert-footer>
            <hr />
            <div class="text-center">
              <button
                type="button"
                class="btn btn-success btn-lg"
                style="width: 150px;"
              >
                <i class="fas fa-save"></i> Save
              </button>
              <button
                style="margin-left: 10px; width: 150px;"
                type="button"
                class="btn btn-warning btn-lg"
              >
                <i class="fas fa-edit"></i> Edit
              </button>
              <router-link
                :to="{
                  name: 'Print Medical Certificate',
                  params: { id: $route.params.visitId }
                }"
                target="_blank"
                class="btn btn-outline-dark btn-lg"
                style="margin-left: 10px; width: 150px;"
              >
                <i class="fas fa-print"></i> Print
              </router-link>

              <button
                style="margin-left: 10px; width: 150px;"
                type="button"
                class="btn btn-danger btn-lg"
              >
                <i class="fas fa-times"></i> Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import insuranceMixins from "../../../../mixins/medcert-for-insurance";

export default {
  mixins: [insuranceMixins],
  data() {
    return {
      // date picker
      stdDateConfig: {
        altInput: true,
        altFormat: "d/m/Y"
      },
      stdDateTimeConfig: {
        altInput: true,
        altFormat: "d/m/Y H:i",
        enableTime: true,
        enableSeconds: false,
        time_24hr: true
      },
      stdTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.container-fluid {
  margin-bottom: 50px;
}

.label-topic {
  font-size: 35px;
}
.bg-gray {
  background-color: #efefef;
}

.label-h {
  padding-top: 10px;
  font-size: 25px;
}

.box-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.txt-blue {
  color: rgb(43, 122, 195);
}
</style>
