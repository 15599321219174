<template>
  <div class="container-fluid">
    <div class="card shadow" v-if="patient">
      <div class="card-body">
        <h1 class="text-primary text-center">Edit HN: {{ patient.HN }}</h1>
        <hr />
        <register-form :initData="patient" mode="edit"></register-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["patient"],
  mounted() {
    console.log("[+] edit patient");
    console.log(this.patient);
  }
};
</script>
