<template>
  <div class="container">
    <div class="d-flex justify-content-center">
      <div class="card shadow">
        <div class="card-body">
          <div class="text-center">
            <div class="m-2">
              <img
                class="logo img-fluid"
                src="/images/worldmed-logo.jpg"
                height="100"
                alt="worldmed logo"
              /><br />
            </div>
          </div>
          <form @submit.prevent="login">
            <div class="form-group">
              <label>Email</label>
              <input
                type="email"
                class="form-control"
                v-model="email"
                placeholder="email@example.com"
              />
            </div>
            <div class="form-group">
              <label>Password</label>
              <input
                type="password"
                class="form-control"
                v-model="password"
                placeholder="password"
              />
            </div>
            <div class="form-group">
              <label>Branch</label>
              <select v-model="branch" class="form-control form-select" id="inputGroupSelect01">
                <option v-for="dd in $store.state.branches" :key="dd.id" :value="Number(dd.id)">{{ dd.name_en }}</option>
                <!-- <option :value="1">HOSPITAL PHIPHI</option>
                <option :value="2">CLINIC PHIPHI</option>
                <option :value="3">AESTHETIC - PP</option>
                <option :value="4">WM REFFERAL - PP PIER</option> -->
              </select>
            </div>
            <button class="btn btn-block btn-primary">
              <i class="fas fa-sign-in-alt"></i> Login
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="text-center">
      <small class="text-muted text-center">
        Copyright © 2020 WORLDMED CENTER
      </small>
    </div>
  </div>
</template>

<script>
import { fb } from "../db";
export default {
  data() {
    return {
      email: null,
      password: null,
      branch: 1,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    async login() {
      this.$store.commit('setBranch', this.branch)
      let loader = this.$loading.show({});
      try {
        await fb.auth().signInWithEmailAndPassword(this.email, this.password);
      } catch (error) {
        console.log(error);
        loader.hide();
        await this.$alert("Login failed", error.message, "error");
      }
      loader.hide();
    }
  },
  watch: {
    user() {
      if (this.user) this.$router.push("/");
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  margin-top: 50px;
  padding: 50px;
}

.card-body {
  max-width: 300px;
}
</style>
