<template>
  <div class="container-fluid mb-2">
    <div class="d-flex mb-2">
      <div>
        <label>Select Date</label>
        <flat-pickr
          class="form-control"
          :config="{
            altFormat: 'd m Y',
            dateFormat: 'd-m-Y'
          }"
          placeholder="Select date"
          v-model="selectedDate"
        ></flat-pickr>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table-responsive pb-4">
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedDate: null
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-red {
  background-color: red;
}

.bg-gray {
  background-color: lightgray;
}

.bg-blue {
  background-color: lightskyblue;
}

.bordered {
  border: 1px dotted black;
}
</style>