<template>
  <div>
    <!-- <print-regis></print-regis> -->
    <iframe
      :src="`/print/register-form/${patient.HN}`"
      frameborder="0"
      height="1200"
      width="100%"
    ></iframe>
    <div class="text-center">
      <button
        style="margin-left: 10px; width: 150px;"
        type="button"
        class="btn btn-warning btn-lg"
      >
        <i class="fas fa-edit"></i> Edit
      </button>
      <!-- <router-link :to="{name: 'Print Register Form', params: { id: $route.params.id}}" target="_blank" class="btn btn-outline-dark btn-lg" style="margin-left: 10px; width: 150px;">
        <i class="fas fa-print"></i> Print
      </router-link> -->
      <button
        style="margin-left: 10px; width: 150px;"
        type="button"
        class="btn btn-danger btn-lg"
      >
        <i class="fas fa-times"></i> Cancel
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ["patient"],
  mounted() {
    document.body.className = "A4";
  },
  computed: {
    getVisitDate(item) {
      return this.$moment(item).format("DD/MM/YYYY HH:mm");
    }
  }
};
</script>
<style lang="scss" scoped>
.form-control {
  padding: 5px;
  font-size: 9px;
  height: 26px;
}
.label {
  font-size: 9px;
}
.label-head {
  font-size: 12px;
}
.text-head {
  font-size: 9x;
}
.box {
  border: 1px solid #adadad;
}
.title-box {
  padding: 10px;
  border-bottom: 1px solid #adadad;
  background-color: #ededed;
}

.content-box {
  padding: 10px;
  min-height: 50px;
}

#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-color: white;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.img-preview {
  height: 100%;
  width: 100%;
  border-radius: 0.25em;
}

.profile-box {
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  height: 110px; /* minimum height */
  width: 110px;
  position: relative;
  background-color: #fff;
  border-radius: 0.25em;

  &:hover {
    background: #e6e6e6;
  }

  p {
    font-size: 1em;
    text-align: center;
    padding: 30px 10px;
  }
}

.box-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  margin: auto;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  // margin-bottom: 20px;
}

.sign-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  // padding: 10px;
  margin: auto;
  width: 100%;
  height: 170px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.sign-detail {
  padding: 10px;
  margin: auto;
  width: 40%;
}

.text-allergy {
  color: #dc0000ff;
}

.fade-noselect {
  color: rgba(0, 0, 0, 0.3);
}
</style>
<i18n>
{
  "en": {
    "pageTitle": "Regular Registration Form",
    "patient_info": "Patient Information",
    "branch_select": "Branch",
    "branch_hospital": "Hospital",
    "branch_clinic": "Clinic",
    "visit_date": "First Visit Date",
    "firstName": "First Name",
    "lastName": "Last Name",
    "dob": "Date of Birth",
    "male": "Male",
    "female": "Female",
    "gender": "Gender",
    "marital_status": "Marital Status",
    "nation": "Nationality",
    "id_no": "I.D. No./ Passport No.",
    "occupation": "Occupation",
    "religion": "Religion",
    "phone_th": "Thai Mobile Phone",
    "phone_inter": "International Mobile Phone",
    "phone": "Mobile Phone",
    "email": "Email Address",
    "allergy": "Allergy",
    "drug_allergy": "Drug Allergy",
    "yes": "Yes",
    "no": "No",
    "other_allergy": "Other Allergy",
    "other_allergy_ph": "",
    "home_addr": "Home Address",
    "city": "City",
    "country": "Country",
    "zipcode": "Zip Code",
    "type_stay_phiphi": "Type of Stay in Phiphi",
    "tourist": "Tourist",
    "arrival_date_in": "Arrival Date in Phiphi",
    "departure_date_out": "Departure Date out in Phiphi",
    "present_addr_th": "Present Address in Thailand",
    "resident": "Resident",
    "work_place": "Work Place",
    "hotel_name": "Hotel Name",
    "emergency_contact": "Emergency Contact Person",
    "relation": "Relation",
    "same_home_addr": "Same as my home address",
    "address_placeholder": "Bldg. No. / Blk No. / Street / District",
    "insurance": "Travel Insurance",
    "not_sure": "Not Sure",
    "how_did_you_find_us": "How did you find us?",
    "note": "Note",
    "certify_check": "I certify that the information provided above is true and correct.",
    "signature": "Signature",
    "patient": "Patient",
    "parent": "Parents / Guardian",
    "spouse": "Spouse / Friend",
    "other": "Other",
    "relation_to_patient": "Relationship to patient",
    "fullname_information": "Full Name of person who give the information",
    "create_patient": "Create Patient",
    "cancel": "Cancel"
  },
  "th": {
    "pageTitle": "ลงทะเบียนผู้ป่วยใหม่",
    "patient_info": "ข้อมูลพื้นฐาน",
    "branch_select": "สาขา",
    "branch_hospital": "สาขา เวิลด์เมดบีชฟร้อน (ข้างสถานีตำรวจ)",
    "branch_clinic": "สาขา เวิลด์เมดคลินิก (ซอยสลิงกี้)",
    "visit_date": "วันที่ลงทะเบียน",
    "firstName": "ชื่อ",
    "lastName": "นามสกุล",
    "dob": "วันเกิด",
    "male": "ชาย",
    "female": "หญิง",
    "gender": "เพศ",
    "marital_status": "สถานภาพ",
    "nation": "สัญชาติ",
    "id_no": "หมายเลขบัตรประชาชน/ หนังสือเดินทาง",
    "occupation": "อาชีพ",
    "religion": "ศาสนา",
    "phone_th": "เบอร์โทรศัพท์ไทย",
    "phone_inter": "",
    "phone": "เบอร์โทรศัพท์",
    "email": "อีเมล",
    "allergy": "ประวัติการแพ้",
    "drug_allergy": "แพ้ยา",
    "yes": "มี",
    "no": "ไม่มี",
    "other_allergy": "แพ้สิ่งอื่นๆ",
    "other_allergy_ph": "",
    "home_addr": "ที่อยู่ปัจจุบัน",
    "city": "จังหวัด",
    "country": "ประเทศ",
    "zipcode": "รหัสไปรษณี",
    "type_stay_phiphi": "วัตถุประสงค์ในเกาะพีพี",
    "tourist": "ท่องเที่ยว",
    "arrival_date_in": "วันที่เดินทางมาถึงเกาะพีพี",
    "departure_date_out": "วันที่เดินทางออกจากเกาะพีพี",
    "present_addr_th": "ที่พักในเกาะพีพี",
    "resident": "ทำงาน/อาศัยอยู่",
    "work_place": "สถานที่ทำงาน",
    "hotel_name": "ที่พักในเกาะพีพี",
    "emergency_contact": "บุคคลติดต่อกรณีฉุกเฉิน",
    "relation": "ความสัมพันธ์กับผู้ป่วย",
    "same_home_addr": "ที่อยู่เดียวกันกับข้าพเจ้า",
    "address_placeholder": "เลขที่/ ตรอก/ ซอย/ ถนน/ ตำบล/ อำเภอ",
    "insurance": "ประกันสุขภาพ",
    "not_sure": "ไม่แน่ใจ",
    "how_did_you_find_us": "รู้จักเราได้อย่างไร",
    "note": "เพิ่มเติม",
    "certify_check": "ข้าพเจ้ารับรองว่าข้อมูลดังกล่าวข้างต้นเป็นความจริง",
    "signature": "ลายมือชื่อผู้ให้ข้อมูล",
    "patient": "ผู้ป่วย",
    "parent": "ผู้ปกครอง",
    "spouse": "คู่สมรส/เพื่อน",
    "other": "อื่นๆ",
    "relation_to_patient": "ความสัมพันธ์กับคนไข้",
    "fullname_information": "ชื่อ-นามสกุล ผู้ให้ข้อมูลแทนผู้ป่วย",
    "create_patient": "บันทึก",
    "cancel": "ยกเลิก"
  }
}
</i18n>
