<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 mt-2">
        <div class="card shadow">
          <div class="card-body">
            <h5 class="text-primary">Branch</h5>
            <table class="table table-bordered">
              <thead>
                <th>ID</th>
                <th>Name EN</th>
                <th>Name TH</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr v-for="(branch,index) in branches" :key="index">
                  <td>{{branch.id}}</td>
                  <td>{{branch.name_en}}</td>
                  <td>{{branch.name_th}}</td>
                  <td><button class="btn btn-primary" @click="showBranch(branch)"><i class="fas fa-edit"></i></button></td>
                </tr>
              </tbody>
            </table>

            <button class="btn btn-primary btn-block mt-2" @click="showBranch(null)">
              <i class="fas fa-plus"/> add branch
            </button>
          </div>
        </div>
      </div>
    </div>


    <b-modal id="branch-modal" ref="branch-modal" hide-footer title="Branch" size="xl">
      <MazInput
        class="mt-2"
        :placeholder="'Branch ID'"
        v-model="branch.id"
      />
      <MazInput
        class="mt-2"
        :placeholder="'Branch Name (EN)'"
        v-model="branch.name_en"
      />
      <MazInput
        class="mt-2"
        :placeholder="'Branch Name (TH)'"
        v-model="branch.name_th"
      />
      <MazInput
        class="mt-2"
        :placeholder="'Phone Number'"
        v-model="branch.phone"
      />
      <MazInput
        class="mt-2"
        :placeholder="'Email'"
        v-model="branch.email"
      />
      <MazInput
        class="mt-2"
        :placeholder="'Address'"
        v-model="branch.address"
        textarea
      />
      <button class="btn btn-success btn-block mt-2" @click="saveBranch"><i class="fas fa-save"></i> Save</button>
    </b-modal>
  </div>
</template>

<script>
import {db} from '../../db'
export default {
  data() {
    return {
      branches: [],
      branch: {
        id: null,
        name_th: null,
        name_en: null,
        address: null,
        phone: null,
        email: null
      },
    }
  },
  firestore() {
    return {
      branches: db.collection('Branch'),
    }
  },
  methods: {
    showBranch(branch) {
      if(branch) {
        this.branch.id = branch.id
        this.branch.name_en = branch.name_en
        this.branch.name_th = branch.name_th
        this.branch.adress = branch.address
        this.branch.phone = branch.phone
        this.branch.email = branch.email
      }else{
        this.branch = {
          id: null,
          name_th: null,
          name_en: null,
          address: null,
          phone: null,
          email: null
        }
      }

      this.$bvModal.show('branch-modal')
    },
    async saveBranch() {
      let loader = this.$loading.show({})
      try {
        let result = await db.collection('Branch').doc(this.branch.id).set({
          name_th: this.branch.name_th || null,
          name_en: this.branch.name_en || null,
          email: this.branch.email || null,
          phone: this.branch.phone || null,
          address: this.branch.address || null,
        })
      } catch (error) {
        console.log(error)
      }
      loader.hide()
      this.$bvModal.hide('branch-modal')
    },
  }
}
</script>
