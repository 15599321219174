<template>
  <div>
    <multiselect
      v-model="value"
      :options="filteredOptions"
      :custom-label="customLabel"
      @search-change="onSearchChange"
      @close="onDropdownClose"
      :taggable="true"
      :tag-placeholder="tagPlaceholder"
      :show-labels="false"
      placeholder="Search"
      @select="onSelect"
    ></multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import {db} from '../db'

export default {
  components: {
    Multiselect,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String
    },
    model: {
      type: String
    }
  },
  data() {
    return {
      searchInput: '',
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter((option) =>
        option?.toLowerCase().includes(this.searchInput.toLowerCase())
      );
    },
    tagPlaceholder() {
      return this.searchInput !== '' ? `Add "${this.searchInput}"` : 'Add a new option';
    },
  },
  methods: {
    customLabel(option) {
      return option;
    },
    onSearchChange(searchQuery) {
      this.searchInput = searchQuery;
    },
    async onDropdownClose() {
      if (this.searchInput.trim() !== '') {
        const newOption = this.searchInput.trim();
        if (!this.options.map(option=> option.toLowerCase()).includes(newOption.toLowerCase())) {
          if(this.model && newOption) {
            await db.collection(this.model).doc().set({
              name: newOption
            })
          } else {
            this.options.push(newOption);
          }
          this.value = newOption;
        }
      }
      this.searchInput = '';
      this.$emit('input', this.value)
    },
    onSelect() {
      this.$emit('input', this.value)
    }
  },
};
</script>