<template>
  <b-breadcrumb :items="crumbs"></b-breadcrumb>
</template>
<script>
export default {
  computed: {
    crumbs: function() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        try {
          breadcrumbArray.push({
            path: path,
            to: breadcrumbArray[idx - 1]
              ? "/" + breadcrumbArray[idx - 1].path + "/" + path
              : "/" + path,
            text: this.$route.matched[idx].name || path
          });
        } catch (error) {
          breadcrumbArray.push({
            path: path,
            to: breadcrumbArray[idx - 1]
              ? "/" + breadcrumbArray[idx - 1].path + "/" + path
              : "/" + path,
            text: 'NA'
          })
        }
        return breadcrumbArray;
      }, []);
      return breadcrumbs;
    }
  }
};
</script>
