<template>
  <div class="container-fluid">
    <div>
      <table class="table table-bordered">
        <thead class="bg-gray text-center">
          <tr>
            <th>Date created</th>
            <th>Time</th>
            <th>Report No.</th>
            <th>VN</th>
            <th>Branch</th>
            <th>Dept</th>
            <th>Report type</th>
            <th>Attending Doctor</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>14/01/2020</td>
            <td>24:00</td>
            <td>04-01-2020-000016</td>
            <td>123-456-789</td>
            <td>Hospital</td>
            <td>ER/IPD</td>
            <td>Medical report for work permit</td>
            <td>Dr. Malavee Suknan</td>
          </tr>
          <tr>
            <td>12/03/2018</td>
            <td>24.00</td>
            <td>04-02-2018-001254</td>
            <td>587-965-478</td>
            <td>Hospital</td>
            <td>OPD</td>
            <td>Medical report for work permit</td>
            <td>Dr. Malavee Suknan</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-center pb-5">
      <button type="button" class="btn btn-primary btn-lg">
        <i class="fa fa-plus"></i> Create New Medical report for work
        permit/driving license
      </button>
    </div>
    <div class="card shadow">
      <div class="card-body">
        <div class="bg-white">
          <h3 class="text-center mt-3">ใบรับรองแพทย์</h3>
        </div>
        <div class="label pb-5">
          <div class="d-flex">
            <div class="ml-auto">
              <p>เลขที่</p>
            </div>
            <div>
              <p class="dotted">123456</p>
            </div>
          </div>
          <b>ส่วนที่ 1 ของผู้ขอรับใบรับรองสุขภาพ</b>

          <div class="d-flex mt-3">
            <div class="mt-1">
              <p>ข้าพเจ้า นาย/นาง/นางสาว</p>
            </div>
            <div class="ml-2 flex-fill">
              <input type="text" class="form-control input-un" id="" />
            </div>
          </div>

          <div class="d-flex mt-1">
            <div class="mt-1">
              <p>สถานที่อยู่ (ที่สามารถติดต่อได้)</p>
            </div>
            <div class="ml-2 flex-fill">
              <input type="text" class="form-control input-un" id="" />
            </div>
          </div>

          <div class="d-flex mt-1">
            <div class="mt-1">
              <p>หมายเลขบัตรประชาชน</p>
            </div>
            <div class="ml-2 flex-fill">
              <input type="text" class="form-control input-un" id="" />
            </div>
          </div>

          <div class="d-flex mt-2">
            <div class="mt-1" style="min-width: 320px;">
              <p>1.โรคประจำตัว</p>
            </div>
            <div class="mt-1 ml-5">
              <label for="check_option1">
                <input type="checkbox" id="check_option1" />
                ไม่มี
              </label>
            </div>
            <div class="mt-1 ml-5">
              <label for="check_option2">
                <input type="checkbox" id="check_option2" />
                มี (ระบุ)
              </label>
            </div>
            <div class="ml-2 flex-fill">
              <input type="text" class="form-control input-un" id="" />
            </div>
          </div>

          <div class="d-flex mt-1">
            <div class="mt-1" style="min-width: 320px;">
              <p>2.อุบัติเหตุ และ ผ่าตัด</p>
            </div>
            <div class="mt-1 ml-5">
              <label for="check_option3">
                <input type="checkbox" id="check_option3" />
                ไม่มี
              </label>
            </div>
            <div class="mt-1 ml-5">
              <label for="check_option4">
                <input type="checkbox" id="check_option4" />
                มี (ระบุ)
              </label>
            </div>
            <div class="ml-2 flex-fill">
              <input type="text" class="form-control input-un" id="" />
            </div>
          </div>

          <div class="d-flex mt-1">
            <div class="mt-1" style="min-width: 320px;">
              <p>3.เคยเข้ารับการรักษาในโรงพยาบาล</p>
            </div>
            <div class="mt-1 ml-5">
              <label for="check_option5">
                <input type="checkbox" id="check_option5" />
                ไม่มี
              </label>
            </div>
            <div class="mt-1 ml-5">
              <label for="check_option6">
                <input type="checkbox" id="check_option6" />
                มี (ระบุ)
              </label>
            </div>
            <div class="ml-2 flex-fill">
              <input type="text" class="form-control input-un" id="" />
            </div>
          </div>

          <div class="d-flex mt-1">
            <div class="mt-1">
              <p>4.ประวัติอื่นที่สำคัญ</p>
            </div>
            <div class="ml-2 flex-fill">
              <input type="text" class="form-control input-un" id="" />
            </div>
          </div>

          <div class="d-flex mt-4 ml-5">
            <div class="mt-1 ml-5">
              <p>ลงชื่อ</p>
            </div>
            <div class="ml-1 flex-4">
              <p class="dotted"></p>
            </div>
            <div class="mt-1">
              <p>วันที่</p>
            </div>
            <div class="ml-2 flex-1">
              <input type="text" class="form-control input-un" id="" />
            </div>
            <div class="mt-1">
              <p>เดือน</p>
            </div>
            <div class="ml-2 flex-1">
              <input type="text" class="form-control input-un" id="" />
            </div>
            <div class="mt-1">
              <p>พ.ศ.</p>
            </div>
            <div class="ml-2  flex-1">
              <input type="text" class="form-control input-un" id="" />
            </div>
          </div>

          <div class="mt-3">
            <b>ส่วนที่ 2 ของแพทย์</b>
          </div>

          <div class="d-flex mt-3">
            <div class="mt-1">
              <p>สถานที่ตรวจ</p>
            </div>
            <div class="ml-1 flex-4">
              <select id="med_location" class="form-control dd-un">
                <option value disabled selected hidden></option>
                <option>Walk-in</option>
                <option>Follow-up</option>
              </select>
            </div>
            <div class="mt-1">
              <p>วันที่</p>
            </div>
            <div class="ml-2 flex-1">
              <input type="text" class="form-control input-un" id="" />
            </div>
            <div class="mt-1">
              <p>เดือน</p>
            </div>
            <div class="ml-2 flex-1">
              <input type="text" class="form-control input-un" id="" />
            </div>
            <div class="mt-1">
              <p>พ.ศ.</p>
            </div>
            <div class="ml-2  flex-1">
              <input type="text" class="form-control input-un" id="" />
            </div>
          </div>

          <div class="d-flex mt-3">
            <div>
              <p>ข้าพเจ้า นายแพทย์/แพทย์หญิง</p>
            </div>
            <div class="ml-1 flex-fill">
              <select id="doc_name" class="form-control dd-un">
                <option value disabled selected hidden></option>
                <option>A</option>
                <option>Bp</option>
              </select>
            </div>
          </div>

          <div class="d-flex mt-3">
            <div>
              <p>ใบอนุญาตประกอบวิชาชีพเวชกรรมเลขที่</p>
            </div>
            <div class="ml-1 flex-fill">
              <input type="text" class="form-control input-un" id="" />
            </div>
          </div>

          <div class="d-flex mt-3">
            <div>
              <p>สถานที่ประกอบวิชาชีพเวชกรรม</p>
            </div>
            <div class="ml-1 flex-fill">
              <input type="text" class="form-control input-un" id="" />
            </div>
          </div>

          <div class="d-flex mt-3">
            <div>
              <p>ได้ตรวจร่างกาย นาย/นาง/นางสาว</p>
            </div>
            <div class="ml-1 flex-fill">
              <input type="text" class="form-control input-un" id="" />
            </div>
          </div>

          <div class="d-flex mt-3">
            <div>
              <b>แล้วเมื่อวันที่</b>
            </div>
            <div class="ml-1">
              <input
                type="text"
                class="form-control input-un"
                id=""
                style="min-width: 40px;"
              />
            </div>
            <div>
              <b>เดือน</b>
            </div>
            <div class="ml-1">
              <input type="text" class="form-control input-un" id="" />
            </div>
            <div>
              <b>พ.ศ</b>
            </div>
            <div class="ml-1">
              <input type="text" class="form-control input-un" id="" />
            </div>
            <div>
              <b>มีรายละเอียดดังนี้</b>
            </div>
          </div>

          <div class="d-flex mt-3">
            <div style="width: 90px;">
              <p>น้ำหนักตัว</p>
            </div>
            <div class="ml-1" style="width: 150px;">
              <input
                type="text"
                class="form-control input-un"
                id=""
                style="min-width: 40px;"
              />
            </div>
            <div style="width: 120px;">
              <p>กก. - ความสูง</p>
            </div>
            <div class="ml-1" style="width: 150px;">
              <input type="text" class="form-control input-un" id="" />
            </div>
            <div style="width: 220px;">
              <p>เซนติเมตร - ความดันโลหิต</p>
            </div>
            <div class="ml-1" style="width: 150px;">
              <input type="text" class="form-control input-un" id="" />
            </div>
            <div style="width: 150px;">
              <p>มม.ปรอท - ชีพจร</p>
            </div>
            <div class="ml-1" style="width: 150px;">
              <input type="text" class="form-control input-un" id="" />
            </div>
            <div style="width: 120px;">
              <p>ครั้ง/นาที</p>
            </div>
          </div>

          <div class="d-flex mt-3">
            <div>
              <p>สภาพร่างกายทั่วไป</p>
            </div>
            <div class="ml-5">
              <p>อยู่ในเกณฑ์</p>
            </div>

            <div class="ml-5">
              <label for="check_option7">
                <input type="checkbox" id="check_option7" />
                ปกติ
              </label>
            </div>
            <div class="ml-5">
              <label for="check_option8">
                <input type="checkbox" id="check_option8" />
                ผิดปกติ (ระบุ)
              </label>
            </div>
            <div class="ml-1 flex-fill">
              <input type="text" class="form-control input-un" id="" />
            </div>
          </div>

          <div class="mt-3" style="text-indent: 150px;">
            <p>
              ขอรับรองว่าบุคคลดังกล่าว
              ไม่เป็นผู้มีร่างกายทุพพลภาพจนไม่สามารถปฏิบัติหน้าที่ได้
              ไม่ปรากฏอาการของโรคจิต หรือจิตฟั่นเฟือน หรือปัญญาอ่อน ไม่ปรากฏ
              อาการของการติดยาเสพติดให้โทษ และอาการของโรคพิษสุราเรื้อรัง
              และไม่ปรากฏอาการและอาการแสดงของโรคต่อไปนี้
            </p>
          </div>

          <div style="text-indent: 150px;">
            <p>
              (๑)
              โรคเรื้อนในระยะติดต่อหรือในระยะที่ปรากฏอาการเป็นที่รังเกียจแก่สังคม
            </p>
            <p>(๒) วัณโรคในระยะอันตราย</p>
            <p>(๓) โรคเท้าช้างในระยะที่ปรากฏอาการเป็นที่รังเกียจแก่สังคม</p>
            <p>
              (๔)
              <b
                >(ถ้าจำเป็นต้องตรวจหาโรคที่เกี่ยวข้องกับการปฏิบัติงานของผู้รับการตรวจให้ระบุข้อนี้)</b
              >
            </p>
          </div>

          <input type="text" class="form-control input-un" id="" />

          <div class="d-flex mt-3">
            <div>
              <p>สรุปความเห็นและข้อแนะนำของแพทย์</p>
            </div>
            <div class="flex-fill">
              <input type="text" class="form-control input-un" id="" />
            </div>
          </div>

          <div class="d-flex mt-3">
            <div class="ml-auto">
              <p>ลงชื่อ</p>
            </div>
            <div style="width: 300px;">
              <p class="dotted"></p>
            </div>
            <div>
              <p>แพทย์ผู้ตรวจร่างกาย</p>
            </div>
          </div>

          <div class="d-flex mt-3">
            <div>
              <span>หมายเหตุ</span>
            </div>
            <div class="pl-5">
              <p>
                (๑)
                ต้องเป็นแพทย์ซึ่งได้ขึ้นทะเบียนรับใบอนุญาตประกอบวิชาชีพเวชกรรม<br />
                (๒) ให้แสดงว่าเป็นผู้มีร่างกายสมบูรณ์เพียงใด
                ใบรับรองแพทย์ฉบับนี้ให้ใช้ได้ ๑ เดือนนับแต่วันที่ตรวจร่างกาย
                แบบฟอร์มนี้อ้างอิงจากแบบฟอร์มใบรับรองแพทย์ที่ได้รับการรับรองจากมติคณะกรรมการแพทยสภาในการประชุมครั้งที่
                8/2551 วันที่ 14 สิงหาคม 2551
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import insuranceMixins from "../../../../mixins/medcert-for-insurance";
export default {
  mixins: [insuranceMixins],
  data() {
    return {
      // date picker
      stdDateConfig: {
        altInput: true,
        altFormat: "d/m/Y"
      },
      stdDateTimeConfig: {
        altInput: true,
        altFormat: "d/m/Y H:i",
        enableTime: true,
        enableSeconds: false,
        time_24hr: true
      },
      stdTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.dotted {
  border-bottom: 2px dotted;
  padding-left: 20px;
  padding-right: 20px;
  height: 35px;
  border-color: rgba(0, 0, 0, 0.6);
}

.container-fluid {
  margin-bottom: 50px;
}

.ul-dot {
  border-bottom: 2px dotted;
  // display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  height: 35px;
  border-color: rgba(0, 0, 0, 0.6);
}
.label {
  font-size: 20px;
}
.input-un {
  width: 100%;
  background-color: #fff;
  border: 0;
  // border-bottom: 2px solid lightgrey;
  border-bottom: 2px dotted;
  border-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  height: 37px;
}
.dd-un {
  width: 100%;
  background-color: #fff;
  border: 0;
  // border-bottom: 2px solid lightgrey;
  border-bottom: 2px dotted;
  border-color: rgba(0, 0, 0, 0.7);
  padding-left: 10px;
  height: 37px;
  // padding-bottom: 5px;
}
input[type="text"] {
  font-size: 22px;
}
</style>
