import PatientSummary from "../pages/PatientSummary/";


export default [
  {
    path: "/patientsummary",
    name: "Patient Summary",
    component: PatientSummary
  },

]