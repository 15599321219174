<template>
  <div>
    <div class="row p-2 mb-3">
      <div class="col-12">
        <h3 class="text-primary text-center">Patients</h3>
        <hr>
      </div>
      <div class="col-12">
        <div class="d-flex">
          <label class="my-auto mx-2">{{ currentMenu === 'Compare' ? 'first month' : 'between' }} </label>
          <MazPicker v-if="currentMenu === 'Compare'" v-model="compare_first_m" clearable no-day no-time format="MM/YYYY" formatted="MM/YYYY" placeholder="Select first month" />
          <MazPicker v-else v-model="from" clearable no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" />
          <label class="my-auto mx-2">{{ currentMenu === 'Compare' ? 'second month' : 'and' }} </label>
          <MazPicker v-if="currentMenu === 'Compare'" v-model="compare_sec_m" clearable no-day no-time format="MM/YYYY" formatted="MM/YYYY" placeholder="Select second month" />
          <MazPicker v-else v-model="to" clearable no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" />
        </div>
      </div>

      <b-nav tabs justified class="mt-2">
        <b-nav-item @click="(currentMenu=menu.name)" v-for="(menu, index) in menus" :key="index" :active="menu.name===currentMenu">{{menu.name}}</b-nav-item>
      </b-nav>
      
    </div>

    <div class="row p-2" v-if="currentMenu==='Summary'">
      <div class="col-6 mt-2">
        <table class="table table-bordered">
          <thead>
            <th></th>
            <th>HOSPITAL</th>
            <th>Slinky Refferral</th>
            <th>Aesthetic</th>
            <th>Peir Referral</th>
            <th>Central Referral</th>
            <th>TOTAL</th>
          </thead>
          <tr v-for="(label, index) in chartData.labels" :key="label">
            <td>{{label}}</td>
            <td>{{chartData.datasets[0].data[index]}}</td>
            <td>{{chartData.datasets[1].data[index]}}</td>
            <td>{{chartData.datasets[2].data[index]}}</td>
            <td>{{chartData.datasets[3].data[index]}}</td>
            <td>{{chartData.datasets[4].data[index]}}</td>
            <td>{{chartData.datasets[0].data[index]+chartData.datasets[1].data[index]+chartData.datasets[2].data[index]+chartData.datasets[3].data[index]+chartData.datasets[4].data[index]}}</td>
          </tr>
          <tr>
            <td>SUM</td>
            <td>{{ chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
            <td>{{ chartData.datasets[1].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
            <td>{{ chartData.datasets[2].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
            <td>{{ chartData.datasets[3].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
            <td>{{ chartData.datasets[4].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
            <td>{{ chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) + chartData.datasets[1].data.reduce((partialSum, a) => partialSum + a, 0) + chartData.datasets[2].data.reduce((partialSum, a) => partialSum + a, 0)+ chartData.datasets[3].data.reduce((partialSum, a) => partialSum + a, 0)+ chartData.datasets[4].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
          </tr>
        </table>
      </div>
      <div class="col-6 mt-2">
        <Bar 
          :chart-options="chartOptions" 
          :chart-data="chartData" 
          :dataset-id-key="'label'"
          :chart-id="'patients_number_chart'" 
          :width="400" 
          :height="400" />
      </div>
    </div>

    <div class="row p-2" v-if="currentMenu==='Growth'">
      <div class="col-12 mt-2">
        <LineChartGenerator 
          :chart-options="chartOptions" 
          :chart-data="growthChartData" 
          :dataset-id-key="'label'"
          :chart-id="'patients_number_chart'" 
          :width="400" 
          :height="200" />
      </div>
    </div>
    <div class="row p-2" v-if="currentMenu === 'Compare'">
      <div class="col-12 mt-2">
        <LineChartGenerator 
          :chart-options="chartOptions" 
          :chart-data="compareChartData" 
          :dataset-id-key="'label'"
          :chart-id="'patients_number_chart'" 
          :width="400" 
          :height="200" />
      </div>
    </div>
  </div>
</template>

<script>
import { 
  Bar,
  Line as LineChartGenerator
} from 'vue-chartjs/legacy'
import Chart from 'chart.js/auto';

export default {
  props: ['patients'],
  components: {
    Bar,
    LineChartGenerator
  },
  data() {
    return {
      from: this.$moment().add(-1, 'month').format('DD/MM/YYYY'),
      to: this.$moment().format('DD/MM/YYYY'),
      compare_first_m: this.$moment().add(-1, 'month').format('MM/YYYY'),
      compare_sec_m: this.$moment().format('MM/YYYY'),
      chartOptions: {
        responsive: true
      },
      menus: [
        { name: 'Summary' },
        { name: 'Growth'},
        { name: 'Compare'},
      ],
      currentMenu: 'Summary'
    }
  },
  created() {
    // this.compareChartData()
  },
  computed: {
    chartData() {

      let filteredPatients = []

      let fromMoment = this.$moment(this.from, 'DD/MM/YYYY')
      let toMoment = this.$moment(this.to, 'DD/MM/YYYY')
      
      for(let p of this.patients) {
        if (p.created_at && typeof p.created_at !== 'string') {
          if (
              this.$moment(p.created_at.toDate()).isSameOrBefore(toMoment) && 
              this.$moment(p.created_at.toDate()).isSameOrAfter(fromMoment)
            ) filteredPatients.push(p)
        }
      }
      
      
      
      // generate months labels
      //label
      let labels = []
      let startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')

      while (startLabelMoment.isSameOrBefore(toMoment)) {
        labels.push(startLabelMoment.format('MMM YYYY'))
        startLabelMoment.add(1, 'month')
      }

      //data
      startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')
      let hospitalData = []
      let slinkyData = []
      let aestheticData = []
      let peirData = []
      let centralData = []
      let totalData = []
      while (startLabelMoment.isSameOrBefore(toMoment)) {
        let hcount = 0
        let scount = 0
        let acount = 0
        let pcount = 0
        let ccount = 0
        for (let p of filteredPatients) {
          if (p.created_at && typeof p.created_at !== 'string') {
            if (
              this.$moment(p.created_at.toDate()).month() === startLabelMoment.month() &&
              this.$moment(p.created_at.toDate()).year() === startLabelMoment.year() &&
              p.branch===1
            ) hcount++
            if (
              this.$moment(p.created_at.toDate()).month() === startLabelMoment.month() &&
              this.$moment(p.created_at.toDate()).year() === startLabelMoment.year() &&
              p.branch===2
            ) scount++
            if (
              this.$moment(p.created_at.toDate()).month() === startLabelMoment.month() &&
              this.$moment(p.created_at.toDate()).year() === startLabelMoment.year() &&
              p.branch===3
            ) acount++
            if (
              this.$moment(p.created_at.toDate()).month() === startLabelMoment.month() &&
              this.$moment(p.created_at.toDate()).year() === startLabelMoment.year() &&
              p.branch===4
            ) pcount++
            if (
              this.$moment(p.created_at.toDate()).month() === startLabelMoment.month() &&
              this.$moment(p.created_at.toDate()).year() === startLabelMoment.year() &&
              p.branch===5
            ) ccount++
          }
        }
        hospitalData.push(hcount)      
        slinkyData.push(scount)
        aestheticData.push(acount)
        peirData.push(pcount)
        centralData.push(ccount)
        totalData.push(hcount+scount+acount+pcount+ccount)
        startLabelMoment.add(1, 'month')
      }


      return {
        labels,
        datasets: [
          {
            label: 'Hospital',
            data: hospitalData,
            backgroundColor: [
              'rgba(54, 162, 235, 0.2)',
            ],
            borderColor: [
              'rgb(54, 162, 235)',
              
            ],
            borderWidth: 1
          },
          {
            label: 'Slinky Refferral',
            data: slinkyData,
            backgroundColor: [
              'rgba(248, 11, 11, 0.2)',
            ],
            borderColor: [
              'rgb(248, 11, 11)',
            ],
            borderWidth: 1
          },
          {
            label: 'Aesthetic',
            data: aestheticData,
            backgroundColor: [
              'rgba(162, 139, 93, 0.2)',
            ],
            borderColor: [
              'rgb(162, 139, 93)',
            ],
            borderWidth: 1
          },
          {
            label: 'Peir Referral',
            data: peirData,
            backgroundColor: [
              'rgba(24, 197, 16, 0.2)',
            ],
            borderColor: [
              'rgb(24, 197, 16)',
            ],
            borderWidth: 1
          },
          {
            label: 'Central Referral',
            data: centralData,
            backgroundColor: [
              'rgba(146, 52, 234, 0.2)',
            ],
            borderColor: [
              'rgb(146, 52, 234)',
            ],
            borderWidth: 1
          },
          {
            label: 'Total',
            data: totalData,
            borderWidth: 1
          },

        ]
      }
    },
    growthChartData() {
      let filteredPatients = []

      let fromMoment = this.$moment(this.from, 'DD/MM/YYYY')
      console.log('fromMoment: ', fromMoment);
      let toMoment = this.$moment(this.to, 'DD/MM/YYYY')
      console.log('toMoment: ', toMoment);

      for(let p of this.patients) {
        if (p.created_at && typeof p.created_at !== 'string') {
          if (
              this.$moment(p.created_at.toDate()).isSameOrBefore(toMoment) && 
              this.$moment(p.created_at.toDate()).isSameOrAfter(fromMoment)
            ) filteredPatients.push(p)
        }
      }
      // generate months labels
      //label
      let labels = []
      let startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')

      while (startLabelMoment.isSameOrBefore(toMoment)) {
        labels.push(startLabelMoment.format('DD/MM/YYYY'))
        startLabelMoment.add(1, 'day')
      }

      //data
      startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')
      let hospitalData = []
      let slinkyData = []
      let aestheticData = []
      let peirData = []
      let centralData = []
      let totalData = []
      while (startLabelMoment.isSameOrBefore(toMoment)) {
        let hcount = 0
        let scount = 0
        let acount = 0
        let pcount = 0
        let ccount = 0
        for (let p of filteredPatients) {
          if (p.created_at && typeof p.created_at !== 'string') {
            if (
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date')  &&
              p.branch===1
            ) hcount++
            if (
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              p.branch===2
            ) scount++
            if (
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              p.branch===3
            ) acount++
            if (
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              p.branch===4
            ) pcount++
            if (
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              this.$moment(p.created_at.toDate()).isSame(startLabelMoment, 'Date') &&
              p.branch===5
            ) ccount++
          }
        }
        hospitalData.push(hcount)      
        slinkyData.push(scount)
        aestheticData.push(acount)
        peirData.push(pcount)
        centralData.push(ccount)
        totalData.push(hcount+scount+acount+pcount+ccount)
        startLabelMoment.add(1, 'day')
      }
      return {
        labels,
        datasets: [
          {
            label: 'Hospital',
            data: hospitalData,
            backgroundColor: [
              'rgba(54, 162, 235, 0.2)',
            ],
            borderColor: [
              'rgb(54, 162, 235)',
              
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: 'Slinky Refferral',
            data: slinkyData,
            backgroundColor: [
              'rgba(248, 11, 11, 0.2)',
            ],
            borderColor: [
              'rgb(248, 11, 11)',
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: 'Aesthetic',
            data: aestheticData,
            backgroundColor: [
              'rgba(162, 139, 93, 0.2)',
            ],
            borderColor: [
              'rgb(162, 139, 93)',
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: 'Peir Referral',
            data: peirData,
            backgroundColor: [
              'rgba(24, 197, 16, 0.2)',
            ],
            borderColor: [
              'rgb(24, 197, 16)',
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: 'Central Referral',
            data: centralData,
            backgroundColor: [
              'rgba(146, 52, 234, 0.2)',
            ],
            borderColor: [
              'rgb(146, 52, 234)',
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: 'Total',
            data: totalData,
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
        ]
      }
    },
    compareChartData() {
      let labels = []
      // label data from 1 to 31
      for (let date = 1; date <= 31; date++) {
        labels.push(date)
      }
      let firstMonthPatientData = Array(31).fill(0)
      let secMonthPatientData = Array(31).fill(0)
      let totalData = Array(31).fill(0)
      let filteredPatientsInFirstMonth = []
      let filteredPatientsInSecMonth = []
      let firstMonthMoment = this.$moment(`${this.compare_first_m}`, 'MM/YYYY')
      let secMonthMoment = this.$moment(`${this.compare_sec_m}`, 'MM/YYYY')
      for(let p of this.patients) {
        if (p.created_at && typeof p.created_at !== 'string') {
          if (this.$moment(p.created_at.toDate()).isSame(firstMonthMoment, 'month')) filteredPatientsInFirstMonth.push(p)
          if (this.$moment(p.created_at.toDate()).isSame(secMonthMoment, 'month')) filteredPatientsInSecMonth.push(p)
        }
      }
      for(let p of filteredPatientsInFirstMonth) {
        if (p.created_at && typeof p.created_at !== 'string') {
          let dayValue = this.$moment(p.created_at.toDate()).date()
          console.log('dayValue: ', dayValue);
          let dayValueIndex = dayValue - 1
          firstMonthPatientData[dayValueIndex] += 1
          totalData[dayValueIndex]+=1
        }
      }
      for(let p of filteredPatientsInSecMonth) {
        if (p.created_at && typeof p.created_at !== 'string') {
          let dayValue = this.$moment(p.created_at.toDate()).date()
          let dayValueIndex = dayValue - 1
          secMonthPatientData[dayValueIndex] += 1
          totalData[dayValueIndex]+=1
        }
      }
      const displayLabelFirstMonth = this.$moment(this.compare_first_m, 'MM/YYYY').format('MMM YYYY')
      const displayLabelSecMonth = this.$moment(this.compare_sec_m, 'MM/YYYY').format('MMM YYYY')
      return {
        labels,
        datasets: [
          {
            label: displayLabelFirstMonth,
            data: firstMonthPatientData,
            backgroundColor: [
              'rgba(54, 162, 235, 0.2)',
            ],
            borderColor: [
              'rgb(54, 162, 235)',
              
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: displayLabelSecMonth,
            data: secMonthPatientData,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
              'rgb(255, 99, 132)',
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: 'Total',
            data: totalData,
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
        ]
      }
    }
  },
  methods: {

  }
}
</script>