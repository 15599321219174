<template>
  <div class="row p-2">
    <div class="col-12">
      <h3 class="text-primary text-center">Register Time Range</h3>
      <hr>
    </div>
    <div class="col-12">
      <div class="d-flex">
        <label>between: </label>
        <MazPicker v-model="from" clearable no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" />
        <label>and</label>
        <MazPicker v-model="to" clearable no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" />
      </div>
    </div>
    <div class="col-6 mt-2">
      <table class="table table-bordered">
        <thead>
          <th></th>
          <th>HOSPITAL</th>
          <th>Slinky Refferral</th>
          <th>Aesthetic</th>
          <th>Peir Referral</th>
          <th>Central Referral</th>
          <th>TOTAL</th>
        </thead>
        <tr v-for="(label, index) in chartData.labels" :key="label">
          <td>{{ label }}</td>
          <td>{{ chartData.datasets[0].data[index] }}</td>
          <td>{{ chartData.datasets[1].data[index] }}</td>
          <td>{{ chartData.datasets[2].data[index] }}</td>
          <td>{{ chartData.datasets[3].data[index] }}</td>
          <td>{{ chartData.datasets[4].data[index] }}</td>
          <td>{{ chartData.datasets[0].data[index] + chartData.datasets[1].data[index] +
            chartData.datasets[2].data[index] + chartData.datasets[3].data[index] + chartData.datasets[4].data[index] }}
          </td>
        </tr>
        <tr>
          <td>SUM</td>
          <td>{{ chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
          <td>{{ chartData.datasets[1].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
          <td>{{ chartData.datasets[2].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
          <td>{{ chartData.datasets[3].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
          <td>{{ chartData.datasets[4].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
          <td>{{ chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) +
            chartData.datasets[1].data.reduce((partialSum, a) => partialSum + a, 0) +
            chartData.datasets[2].data.reduce((partialSum, a) => partialSum + a, 0) +
            chartData.datasets[3].data.reduce((partialSum, a) => partialSum + a, 0) +
            chartData.datasets[4].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
        </tr>
        <tr>
          <td>AVG</td>
          <td>{{ (chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) / sum_days).toFixed(2) }}</td>
          <td>{{ (chartData.datasets[1].data.reduce((partialSum, a) => partialSum + a, 0) / sum_days).toFixed(2) }}</td>
          <td>{{ (chartData.datasets[2].data.reduce((partialSum, a) => partialSum + a, 0) / sum_days).toFixed(2) }}</td>
          <td>{{ (chartData.datasets[3].data.reduce((partialSum, a) => partialSum + a, 0) / sum_days).toFixed(2) }}</td>
          <td>{{ (chartData.datasets[4].data.reduce((partialSum, a) => partialSum + a, 0) / sum_days).toFixed(2) }}</td>
          <td>{{ ((chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) +
            chartData.datasets[1].data.reduce((partialSum, a) => partialSum + a, 0) +
            chartData.datasets[2].data.reduce((partialSum, a) => partialSum + a, 0) +
            chartData.datasets[3].data.reduce((partialSum, a) => partialSum + a, 0) +
            chartData.datasets[4].data.reduce((partialSum, a) => partialSum + a, 0)) / sum_days).toFixed(2) }}</td>
        </tr>
      </table>
    </div>
    <div class="col-6 mt-2">
      <LineChartGenerator :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'"
        :chart-id="'patients_number_chart'" :width="400" :height="400" />
    </div>
  </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import Chart from 'chart.js/auto';

export default {
  props: ['patients'],
  components: {
    LineChartGenerator
  },
  data() {
    return {
      from: this.$moment().add(-1, 'month').format('DD/MM/YYYY'),
      to: this.$moment().format('DD/MM/YYYY'),
      sum_days: 30,
      chartOptions: {
        responsive: true
      },

    }
  },
  watch: {
    from: 'calculateSumDays',
    to: 'calculateSumDays'
  },
  computed: {
    chartData() {

      let filteredPatients = []

      let fromMoment = this.$moment(this.from, 'DD/MM/YYYY')
      let toMoment = this.$moment(this.to, 'DD/MM/YYYY')


      for (let p of this.patients) {
        if (p.created_at && typeof p.created_at !== 'string') {
          if (
            this.$moment(p.created_at.toDate()).isSameOrBefore(toMoment) &&
            this.$moment(p.created_at.toDate()).isSameOrAfter(fromMoment)
          ) filteredPatients.push(p)
        }
      }

      // generate months labels
      //label
      let labels = []
      let startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')

      for (let i = 0; i < 24; i++) {
        labels.push(startLabelMoment.format('HH:mm'))
        startLabelMoment.add(1, 'hour')
      }

      //data
      startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')
      let hospitalData = []
      let slinkyData = []
      let aestheticData = []
      let peirData = []
      let centralData = []
      let totalData = []

      for (let i = 0; i < 24; i++) {
        hospitalData.push(0)
        slinkyData.push(0)
        aestheticData.push(0)
        peirData.push(0)
        centralData.push(0)
        totalData.push(0)
      }


      for (let p of filteredPatients) {
        if (p.created_at && typeof p.created_at !== 'string') {
          let hour = this.$moment(p.created_at.toDate()).hour()
          if (p.branch === 1) hospitalData[hour]++
          if (p.branch === 2) slinkyData[hour]++
          if (p.branch === 3) aestheticData[hour]++
          if (p.branch === 4) peirData[hour]++
          if (p.branch === 5) centralData[hour]++
          totalData[hour]++
        }
      }

      return {
        labels,
        datasets: [
          {
            label: 'Hospital',
            data: hospitalData,
            backgroundColor: [
              'rgba(54, 162, 235, 0.2)',
            ],
            borderColor: [
              'rgb(54, 162, 235)',

            ],
            borderWidth: 1,
            suggestedMin: -10,
            suggestedMax: 200,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: 'Slinky Refferral',
            data: slinkyData,
            backgroundColor: [
              'rgba(248, 11, 11, 0.2)',
            ],
            borderColor: [
              'rgb(248, 11, 11)',
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: 'Aesthetic',
            data: aestheticData,
            backgroundColor: [
              'rgba(162, 139, 93, 0.2)',
            ],
            borderColor: [
              'rgb(162, 139, 93)',
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: 'Peir Referral',
            data: peirData,
            backgroundColor: [
              'rgba(24, 197, 16, 0.2)',
            ],
            borderColor: [
              'rgb(24, 197, 16)',
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: 'Central Referral',
            data: centralData,
            backgroundColor: [
              'rgba(146, 52, 234, 0.2)',
            ],
            borderColor: [
              'rgb(146, 52, 234)',
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: 'Total',
            data: totalData,
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },

        ]
      }
    }
  },
  methods: {
    calculateSumDays() {
      let fromMoment = this.$moment(this.from, 'DD/MM/YYYY');
      let toMoment = this.$moment(this.to, 'DD/MM/YYYY');
      this.sum_days = toMoment.diff(fromMoment, 'days');
    }
  }
}
</script>