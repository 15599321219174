<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <div class="bg-gray">
          <div class="pd10">
            <div class="label-b">DISCHARGE CONDITION</div>
            <div class="label-h">Vital Signs</div>
            <div class="card shadow mt-2">
              <div class="card-body" style="background-color: #f5f5f0;">
                <div class="d-flex">
                  <div>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text p-1 bg-white label"
                          >Time</span
                        >
                      </div>
                      <flat-pickr
                        class="form-control"
                        :config="stdTimeConfig"
                        style="background-color: #fff;"
                        v-model="discharge_status.time"
                      ></flat-pickr>
                    </div>
                  </div>
                  <div class="pl-2">
                    <div class="input-group" style="min-width: 220px;">
                      <div class="input-group-prepend">
                        <span class="input-group-text p-1 bg-white label"
                          >BP</span
                        >
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Systolic"
                        v-model="discharge_status.bp_syst"
                        :class="checkBPSys(discharge_status.bp_syst, patient.ageMonth, patient.ageDay)"
                        @change="alertBPSys(discharge_status.bp_syst, patient.ageMonth, patient.ageDay)"
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text p-1 bg-white label border-right-0"
                          >/</span
                        >
                      </div>
                      <input
                        type="text"
                        class="form-control border-left-0 border-right-0"
                        placeholder="Diatolic"
                        v-model="discharge_status.bp_dias"
                        :class="checkBPDias(discharge_status.bp_dias, patient.ageMonth)"
                        @blur="alertBPDias(discharge_status.bp_dias, patient.ageMonth)"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text p-1 bg-white label"
                          >mmHg</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="pl-2">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text p-1 bg-white label"
                          >PR</span
                        >
                      </div>
                      <input
                        type="text"
                        class="form-control border-left-0 border-right-0"
                        v-model="discharge_status.pr"
                        :class="checkHR(discharge_status.pr, patient.ageMonth)"
                        @blur="alertHR(discharge_status.pr, patient.ageMonth)"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text p-1 bg-white label"
                          >/min</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="pl-2">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text p-1 bg-white label"
                          >RR</span
                        >
                      </div>
                      <input
                        type="text"
                        class="form-control border-left-0 border-right-0"
                        v-model="discharge_status.rr"
                        :class="checkRR(discharge_status.rr, patient.ageMonth)"
                        @blur="alertRR(discharge_status.rr, patient.ageMonth)"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text p-1 bg-white label"
                          >/min</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="pl-2">
                    <div class="input-group" style="min-width: 170px;">
                      <div class="input-group-prepend">
                        <span class="input-group-text p-1 bg-white label"
                          >Temp</span
                        >
                      </div>
                      <input
                        type="text"
                        class="form-control border-left-0 border-right-0"
                        v-model="discharge_status.temp"
                        :class="checkTemp(discharge_status.temp, discharge_status.temp_unit)"
                        @blur="alertTemp(discharge_status.temp, discharge_status.temp_unit)"
                      />
                      <div class="input-group-append">
                        <select
                          id="pregnency-by"
                          class="form-control input-group-text bg-white"
                          v-model="discharge_status.temp_unit"
                        >
                          <!-- <option value disabled selected></option> -->
                          <option value="C" selected>°C</option>
                          <option value="F">°F</option>
                        </select>
                      </div>
                    </div>
                    <div class="text-center">
                      {{
                        discharge_status.temp && discharge_status.temp_unit
                          ? `(${getTemp(discharge_status.temp, discharge_status.temp_unit)})`
                          : null
                      }}
                    </div>
                  </div>
                  <div class="pl-2">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text p-1 bg-white label"
                          >O2Sat</span
                        >
                      </div>
                      <input
                        type="text"
                        class="form-control border-left-0 border-right-0"
                        v-model="discharge_status.o2sat"
                        @blur="alertO2Sat(discharge_status.o2sat)"
                        :class="checkO2Sat(discharge_status.o2sat)"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text p-1 bg-white label"
                          >%</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="pl-2">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text p-1 bg-white label"
                          >Painscore</span
                        >
                      </div>
                      <input
                        type="text"
                        class="form-control border-left-0 border-right-0"
                        v-model="discharge_status.pain_score"
                        :class="checkPainScore(discharge_status.pain_score)"
                        @blur="alertPainScore(discharge_status.pain_score)"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text p-1 bg-white label"
                          >/10</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="pl-2">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text p-1 bg-white label"
                          >Location</span
                        >
                      </div>
                      <input
                        type="text"
                        class="form-control border-left-0"
                        v-model="discharge_status.pain_location"
                      />
                    </div>
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text p-1 bg-white label"
                          >E</span
                        >
                      </div>
                      <select
                        id="vs_e"
                        class="form-control border-left-0"
                        v-model="discharge_status.vs_e"
                        :class="checkGcsE(discharge_status.vs_e)"
                      >
                        <option value disabled selected></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                      </select>
                    </div>
                  </div>
                  <div class="pl-2">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text p-1 bg-white label"
                          >V</span
                        >
                      </div>
                      <select
                        id="vs_v"
                        class="form-control border-left-0"
                        v-model="discharge_status.vs_v"
                        :class="checkGcsV(discharge_status.vs_v)"
                      >
                        <option value disabled selected></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </div>
                  </div>
                  <div class="pl-2">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text p-1 bg-white label"
                          >M</span
                        >
                      </div>
                      <select
                        id="vs_m"
                        class="form-control border-left-0"
                        v-model="discharge_status.vs_m"
                        :class="checkGcsM(discharge_status.vs_m)"
                      >
                        <option value disabled selected></option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                      </select>
                    </div>
                  </div>
                  <div class="pl-5">
                    <label>Pupil</label>
                  </div>
                  <div class="pl-2" style="width: 130px;">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text p-1 bg-white label"
                          >Lt.</span
                        >
                      </div>
                      <input
                        type="text"
                        class="form-control border-left-0 border-right-0"
                        v-model="discharge_status.pupil_lt_value"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text p-1 bg-white label"
                          >mm</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="pl-2">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text p-1 bg-white label"
                          >Lt.</span
                        >
                      </div>
                      <select
                        id="pupil_lt"
                        class="form-control border-left-0"
                        v-model="discharge_status.pupil_lt"
                      >
                        <option value disabled selected hidden></option>
                        <option>R</option>
                        <option>S</option>
                        <option>F</option>
                      </select>
                    </div>
                  </div>
                  <div class="pl-2" style="width: 130px;">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text p-1 bg-white label"
                          >Rt.</span
                        >
                      </div>
                      <input
                        type="text"
                        class="form-control border-left-0 border-right-0"
                        v-model="discharge_status.pupil_rt_value"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text p-1 bg-white label"
                          >mm</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="pl-2">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text p-1 bg-white label"
                          >Rt.</span
                        >
                      </div>
                      <select
                        id="pupil_rt"
                        class="form-control border-left-0"
                        v-model="discharge_status.pupil_rt"
                      >
                        <option value disabled selected hidden></option>
                        <option>R</option>
                        <option>S</option>
                        <option>F</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <label class="label">Note</label>
                  <textarea
                    class="form-control"
                    id=""
                    rows="4"
                    v-model="discharge_status.note"
                  ></textarea>
                </div>
                <div class="mt-4">
                  <span class="label-h">Discharge to:</span>
                  <div class="d-flex">
                    <div style="min-width: 450px;">
                      <select
                        id="discharge_to"
                        class="form-control"
                        v-model="discharge_status.discharge_to"
                      >
                        <option value disabled selected hidden>Select Discharge to...</option>
                        <option value="Home">Home</option>
                        <option value="Admission Room">Admission Room</option>
                        <option value="Against Medical Advice">Against Medical Advice</option>
                        <option value="Refer">Refer</option>
                        <option value="Hospital">Hospital</option>
                        <option value="Dead (See CPR Record)">Dead (See CPR Record)</option>
                      </select>
                      <input type="text" class="form-control mt-2" placeholder="reason" v-if="discharge_status.discharge_to==='Against Medical Advice'">
                      <input type="text" class="form-control mt-2" placeholder="refer to" v-if="discharge_status.discharge_to==='Refer'">
                      <input type="text" class="form-control mt-2" placeholder="reason" v-if="discharge_status.discharge_to==='Refer'">
                      <input type="text" class="form-control mt-2" placeholder="Sent pt with film/CD/Other" v-if="discharge_status.discharge_to==='Refer'">
                      <input type="text" class="form-control mt-2" placeholder="hospital name" v-if="discharge_status.discharge_to==='Hospital'">
                      <input type="text" class="form-control mt-2" placeholder="for" v-if="discharge_status.discharge_to==='Hospital'">
                    </div>
                  </div>
                  <div class="mt-4">
                    <span class="label-h"
                      >Patient Instruction prior to discharge:</span
                    >
                  </div>
                  <div class="d-flex">
                    <div class="pt-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="prior_to_discharge"
                          id="prior_to_discharge_patient"
                          value="patient"
                          v-model="discharge_status.prior_to_discharge"
                        />
                        <label
                          class="form-check-label pt-1 pl-1"
                          for="prior_to_discharge_patient"
                          >Patient</label
                        >
                      </div>
                    </div>
                    <div class="pl-3 pt-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="prior_to_discharge"
                          id="prior_to_discharge_other"
                          value="other"
                          v-model="discharge_status.prior_to_discharge"
                        />
                        <label
                          class="form-check-label pt-1 pl-1"
                          for="prior_to_discharge_other"
                          >Other</label
                        >
                      </div>
                    </div>
                    <div class="pl-3 pt-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="prior_to_discharge"
                          id="prior_to_discharge_relative"
                          value="relative"
                          v-model="discharge_status.prior_to_discharge"
                        />
                        <label
                          class="form-check-label pt-1 pl-1"
                          for="prior_to_discharge_relative"
                          >Relative</label
                        >
                      </div>
                    </div>
                    <div class="pl-5" style="min-width: 400px;">
                      <label class="label">Full Name</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="discharge_status.prior_to_discharge_fullname"
                      />
                    </div>
                    <div class="pl-5" style="min-width: 450px;">
                      <label class="label">Select all that apply</label>
                      <select
                        id="prior_to_discharge_select"
                        class="form-control"
                        v-model="discharge_status.prior_to_discharge_select"
                      >
                        <option value disabled selected hidden></option>
                        <option>Blood Collection</option>
                        <option>Health education</option>
                        <option>Medication</option>
                        <option>NPO 6 - 8 hrs</option>
                        <option>Observe Neuro Signs</option>
                        <option>Splint Care</option>
                        <option>Wound care</option>
                        <option>Other</option>
                      </select>
                    </div>
                  </div>
                  <div class="mt-4">
                    <span class="label-h"
                      >Instruction document given to patient:</span
                    >
                    <div class="d-flex">
                      <div class="mt-2">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="is_instruction_doc"
                            id="is_instruction_doc_no"
                            value="no"
                            v-model="discharge_status.is_instruction_doc"
                          />
                          <label
                            class="form-check-label pt-1 pl-1"
                            for="is_instruction_doc_no"
                            >NO</label
                          >
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="is_instruction_doc"
                            id="is_instruction_doc_yes"
                            value="yes"
                            v-model="discharge_status.is_instruction_doc"
                          />
                          <label
                            class="form-check-label pt-1 pl-1"
                            for="is_instruction_doc_yes"
                            >YES</label
                          >
                        </div>
                      </div>
                      <div
                        class="pl-4"
                        style="padding-top: 30px; min-width: 300px;"
                      >
                        <div>
                          <input
                            class="form-control"
                            type="text"
                            id="instruction_doc"
                            placeholder="Please Specify"
                            v-model="discharge_status.instruction_doc"
                          />
                        </div>
                      </div>
                      <div class="pl-5" style="min-width: 450px;">
                        <label class="label">Education Perception</label>
                        <select
                          id="instruction_doc_edu"
                          class="form-control"
                          v-model="discharge_status.instruction_doc_edu"
                        >
                          <option value disabled selected hidden></option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4">
                    <span class="label-h">Primary Language</span>
                    <div class="d-flex">
                      <div style="min-width: 450px;">
                        <select
                          id="primary_language"
                          class="form-control"
                          v-model="discharge_status.primary_language"
                        >
                          <option value disabled selected hidden></option>
                          <option>English</option>
                          <option>French</option>
                          <option>German</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4">
                    <span class="label-h">Refer to </span>

                    <div class="d-flex">
                      <div class="mt-2">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="is_refer_to"
                            id="is_refer_to_no"
                            value="no"
                            v-model="discharge_status.is_refer_to"
                          />
                          <label
                            class="form-check-label pt-1 pl-1"
                            for="is_refer_to_no"
                            >NO</label
                          >
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="is_refer_to"
                            id="is_refer_to_yes"
                            value="yes"
                            v-model="discharge_status.is_refer_to"
                          />
                          <label
                            class="form-check-label pt-1 pl-1"
                            for="is_refer_to_yes"
                            >YES</label
                          >
                        </div>
                      </div>
                      <div class="pl-5" style="min-width: 400px;">
                        <div>
                          <label class="label">Full Name</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="discharge_status.refer_to_fullname"
                          />
                        </div>
                      </div>
                      <div class="ml-auto" style="padding-top: 30px;">
                        <div class="d-flex">
                          <div>
                            <input
                              id="discharge_status_rn"
                              type="text"
                              class="form-control"
                              placeholder="RN"
                              v-model="discharge_status.rn"
                            />
                          </div>
                          <div class="pl-2">
                            <flat-pickr
                              class="form-control"
                              :config="stdTimeConfig"
                              placeholder="Time"
                              v-model="discharge_status.doc_time"
                            ></flat-pickr>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dischargeStatusMixins from "../../../mixins/discharge-status";
import vitalSignMixins from "../../../mixins/vital-sign";
export default {
  props: {
    patient: {
      default: () => {
        return {};
      }
    }
  },
  mixins: [dischargeStatusMixins, vitalSignMixins],
  data() {
    return {
      showEng: false,
      showTh: false,
      hideMenu: false,
      first_name: null,
      last_name: null,
      how_did_you_find_us: [],
      first_visited_date: null,
      phone_number_th: null,
      phone_number_g: null,
      phone_number_emer: null,
      temp: 22,
      pregnancy_status: null,
      temp_unit: null,
      showNursing: false,
      allergyList: [
        {
          allergy: ""
        }
      ],
      // date picker
      stdDateConfig: {
        altInput: true,
        altFormat: "d/m/Y"
      },
      stdDateTimeConfig: {
        altInput: true,
        altFormat: "d/m/Y H:i",
        enableTime: true,
        enableSeconds: false,
        time_24hr: true
      },
      stdTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      }
    };
  }
};
</script>
<style lang="scss" scoped>
//Main css
.holder {
  height: 62px;
}
.label-inside {
  margin-bottom: -50px;
  padding-left: 5px;
  padding-top: 0px;
  position: absolute;
  color: rgb(116, 116, 116);
  font-size: 16px;
}
.input-inside {
  height: 100%;
  padding-bottom: -30px;
}
input[type="text"] {
  // font-size: 18px;
}
table,
th,
td {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-collapse: collapse;
}
//end main
.mid {
  padding: 20px;
  margin-top: 50px;
}
.btn {
  background-color: rgb(212, 212, 212);
}
.center {
  text-align: center;
}
//margin padding
.ml2 {
  margin-left: 8px;
}
.mt2 {
  margin-top: 8px;
}
.mt3 {
  margin-top: 10px;
}
.pd10 {
  padding: 10px;
}
.mg-t {
  margin-top: 18px;
}
.bg-gray {
  background-color: rgb(221, 221, 221);
}
.bg-light {
  background-color: #fff;
}
.bg-red {
  background-color: rgb(243, 187, 187);
}
.bg-blue {
  background-color: rgb(190, 220, 240);
}
.bg-w {
  background-color: #fff;
}
.bg-hblue {
  background-color: rgb(17, 85, 204);
}
.bg-horange {
  background-color: rgb(230, 145, 56);
}
.pd-side {
  padding-left: 5px;
  padding-right: 5px;
}
.label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}
.profile-box {
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  height: 170px; /* minimum height */
  width: 170px;
  position: relative;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background: #e6e6e6;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  p {
    font-size: 1em;
    text-align: center;
    padding: 50px 10px;
  }
}
.input-icon {
  position: absolute;
  color: rgb(214, 38, 38);
  top: 23px;
  right: 22px;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.2), 1px -1px 0 rgba(0, 0, 0, 0.2),
    -1px 1px 0 rgba(0, 0, 0, 0.2), 1px 1px 0 rgba(0, 0, 0, 0.2);
}
.input-icon-red {
  color: rgb(214, 38, 38);
}
.box-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.sign-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  width: 40%;
  height: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}
.sign-detail {
  padding: 20px;
  margin: auto;
  width: 40%;
  margin-bottom: 20px;
}
.label-b {
  color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}
.label-h {
  // color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}
.esl-lv {
  border: 3px solid rgba(0, 0, 0, 0.3);
  width: 40px;
  height: 40px;
  margin-top: 10px;
  border-radius: 7px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  margin-left: 10px;
}

.form-check {
  margin-left: 10px;
}
button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.flex-even {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.vital-red {
  background-color: #dc0000ff;
  color: #fff;
}
.vital-mid-red {
  background-color: #ea9999;
}
.vital-soft-red {
  background-color: #f4cccc;
}
</style>
