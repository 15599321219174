var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"sheet padding-10mm invoice-form"},[_c('div',{staticClass:"d-flex row mt-2"},[_c('h3',{staticClass:"text-primary"},[_vm._v("Transfer Report")]),_c('div',{staticClass:"ml-auto"},[_vm._v(" "+_vm._s(this.$route.query.from === 'null' ? '' : (this.$route.query.from === '0' ? 'Main Stock' : _vm.$store.state.branches[this.$route.query.from - 1]?.name_en) + ' ->')+" "+_vm._s(this.$route.query.to === 'null' ? '' : (this.$route.query.to === '0' ? 'Main Stock' : _vm.$store.state.branches[this.$route.query.to - 1]?.name_en))+" ")])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ml-auto"},[_vm._v(" "+_vm._s(_vm.$route.query.start)+" - "+_vm._s(_vm.$route.query.end)+" ")])]),_c('div',{staticClass:"row mt-4 d-flex flex-column"},[_c('vue-good-table',{attrs:{"columns":_vm.transferColumns,"rows":_vm.transfers,"use-dynamic-row-height":true,"sort-options":{
                    enabled: false,
                },"group-options":{
                    enabled: false,
                },"pagination-options":{
                    dropdownAllowAll: false,
                }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'item_name')?_c('div',{staticStyle:{"display":"block"}},[_c('span',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(props.row.item_name))]),_c('span',{staticStyle:{"font-size":"smaller","text-decoration":"underline","display":"block"}},[_vm._v(" "+_vm._s(props.row.item_other_name)+" ")])]):(props.column.field === 'expire_date')?_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(props.row.expire_date))+" ")]):_vm._e()]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }