<template>
  <div class="container">
    <h3 class="text-primary mb-2 mt-2">Staff Information</h3>
    <hr>
    <staff-form :staffId="staffId"></staff-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      staffId: this.$route.params.staffId
    }
  }
}
</script>