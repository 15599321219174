export const generateRandomString = () => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const first_digit = alphabet[Math.floor(Math.random() * alphabet.length)];
  const second_digit = alphabet[Math.floor(Math.random() * alphabet.length)];
  const third_digit = Math.floor(Math.random() * 10);

  return `${first_digit}${second_digit}${third_digit}`
}

export const capitalizeFirstChar = (text) => text.charAt(0).toUpperCase() + text.slice(1);
