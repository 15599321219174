import Package from "../pages/Package/";


export default [
  {
    path: "/package",
    name: "Package",
    component: Package
  },

]