<template>
  <b-modal id="order-procedure" title="Order Procedure" size="full" hide-footer>
    <div class="mb-2">
      <div class="container-fluid">
        <div v-for="(item, index) in value" :key="index">
          <!-- <div class="row">
            <div class="col-1">
              Time:
            </div>
            <div class="col-3">
              Procedure Name:
            </div>
            <div class="col-3">
              Organ:
            </div>
            <div class="col-2">
              Unit:
            </div>
            <div class="col-1">
              
            </div>
            <div class="col-2">
              Consent Signed:
            </div>
          </div> -->
          <div class="row">
            <div class="col-3">
              <div class="label">Time</div>
              <MazPicker
                v-model="item.procedure_date_time"
                format="DD/MM/YYYY HH:mm"
                formatted="DD/MM/YYYY HH:mm"
              />
            </div>
            <div class="col-3">
              <div class="label">Procedure Name</div>
              <MazSelect
                v-model="item.procedure_name"
                search
                :options="
                  $store.state.dd_procedure.map(item => {
                    return {
                      label: item.name,
                      value: item.name
                    };
                  })
                "
              />
              <input
                type="text"
                class="form-control"
                placeholder="Other"
                v-model="item.procedure_name_other_input"
                v-if="item.procedure_name === 'Other'"
              />
            </div>
            <div class="col-3">
              <div class="label">Note</div>
              <MazInput
                v-model="item.procedure_notes"
              ></MazInput>
            </div>
            <div class="col-2">
              <div class="label">Amount</div>
              <MazInput
                v-model="item.procedure_amount"
              ></MazInput>
            </div>
            <div class="ml-auto col-1">
              <button class="btn btn-light" @click="value.splice(index, 1)"><i class="fas fa-trash"></i></button>
            </div>
          </div>
        </div>
        <button class="btn btn-outline-success btn-block mt-2" @click="value.push({procedure_date_time: new Date()})">
          <i class="fas fa-plus"></i> Order
        </button>
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary mr-2" @click.prevent="confirmOrder">Confirm Order</button>
      <button class="btn btn-danger mr-2" @click.prevent="$bvModal.hide(id)">Cancel</button>
    </div>
  </b-modal>
</template>

<script>
import opdMixins from "../../../mixins/opd";
export default {
  mixins: [opdMixins],
  data() {
    return {
      id: 'order-procedure',
      rows: [{}]
    }
  },
  props: {
    value: {
      default: () => {
        return [{procedure_date_time: new Date()}]
      }
    },
    onClose: {
      default: () => {}
    },
    onConfirm: {
      default: () => {}
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === this.id)
      this.onClose()
    })
  },
  methods: {
    confirmOrder() {
      this.$bvModal.hide(this.id)
      this.onConfirm()
    }
  }
}
</script>