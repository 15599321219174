<template>
  <div class="container-fluid">
    <div class="bg-lab p-3 mt-3 d-flex">
      <div>
        <span class="label-b text-light">
          <i class="fa fa-heartbeat" aria-hidden="true"></i> Investigations
        </span>
      </div>
      <div class="ml-auto">
        <button class="btn bg-white" @click="againstAllItem('invest_select')">
          <span class="txt-warning-red">
            <i class="fa fa-times" aria-hidden="true"></i> Against All Advice
          </span>
        </button>
      </div>
    </div>
    <div class="bg-gray p-3">
      <span class="label-h">Select Investigation Type</span>
      <div class="d-flex">
        <div class="ml-auto"><b>Against Medical Advice</b></div>
      </div>
      <div v-for="(i, index) in value.invest_select" :key="i.id">
        <div class="d-flex mt-2">
          <div class="mt-3" style="min-width: 35px;">
            <i
              v-if="value.invest_select[index].against_reason"
              class="far fa-times-circle"
              style="font-size: 27px; color: red; margin-top: 10px;"
            ></i>
          </div>
          <div class="ml-1" style="min-width: 170px;">
            <div class="label">Date&Time</div>
            <MazPicker
              v-model="value.invest_select[index].invest_date_time"
              format="DD/MM/YYYY HH:mm"
              formatted="DD/MM/YYYY HH:mm"
              :disabled="checkDisabled('invest_select', index)"
            />
          </div>
          <div class="ml-1" style="min-width: 170px; margin-top: 19px;">
            <MazSelect
              v-model="value.invest_select[index].invest_type"
              search
              :options="
                dd_invest_type.map(item => {
                  return {
                    label: item,
                    value: item
                  };
                })
              "
              :disabled="checkDisabled('invest_select', index)"
            />
          </div>
          <div
            class="pl-1"
            style="min-width: 250px;"
            v-if="value.invest_select[index].invest_type === 'EKG'"
          >
            <div class="label" for="'invest_ekg_type_' + index">Type</div>
            <MazSelect
              v-model="value.invest_select[index].invest_ekg_type"
              search
              :options="
                dd_ekg.map(item => {
                  return {
                    label: item.ekg_name,
                    value: item.ekg_name
                  };
                })
              "
              :disabled="checkDisabled('invest_select', index)"
            />
            <div v-if="value.invest_select[index].invest_ekg_type === 'Others'">
              <input
                type="text"
                class="form-control"
                :id="`invest_ekg_type_other_${index}`"
                v-model="value.invest_select[index].invest_ekg_type_other"
                placeholder="Others"
              />
            </div>
          </div>
          <div
            class="ml-1"
            v-if="value.invest_select[index].invest_type === 'X-Ray'"
          >
            <div class="label" for="'invest_organ_view_' + index">
              Select Organ & View
            </div>
            <select
              style="width: 350px;"
              @change="getViews(index)"
              :id="'invest_organ_view_' + index"
              class="form-control"
              v-model="value.invest_select[index].invest_organ_view"
              :disabled="checkDisabled('invest_select', index)"
            >
              <option value selected></option>
              <optgroup label="HEAD & NECK">
                <option
                  :value="dd.name"
                  v-for="dd in ddOrganHead"
                  :key="dd.id"
                  >{{ dd.name }}</option
                >
              </optgroup>
              <optgroup label="CHEST">
                <option
                  :value="dd.name"
                  v-for="dd in ddOrganChest"
                  :key="dd.id"
                  >{{ dd.name }}</option
                >
              </optgroup>
              <optgroup label="ABDOMEN">
                <option
                  :value="dd.name"
                  v-for="dd in ddOrganAbdomen"
                  :key="dd.id"
                  >{{ dd.name }}</option
                >
              </optgroup>
              <optgroup label="EXTREMITIES">
                <option
                  :value="dd.name"
                  v-for="dd in ddOrganExtrem"
                  :key="dd.id"
                  >{{ dd.name }}</option
                >
              </optgroup>
              <optgroup label="SPINE">
                <option
                  :value="dd.name"
                  v-for="dd in ddOrganSpine"
                  :key="dd.id"
                  >{{ dd.name }}</option
                >
              </optgroup>
            </select>
            <div class="mt-1">
              <div
                class="form-check form-check-inline ml-3"
                v-for="dd in value.invest_select[index].show_organ_view"
                :key="dd.id"
              >
                <label class="form-check-label">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="dd"
                    :checked="value.invest_select[index].views.includes(dd)"
                    v-model="value.invest_select[index].views"
                  />
                  <span>{{ dd }}</span></label
                >
              </div>
            </div>
            <div class="mt-1">
              <input
                type="text"
                class="form-control"
                v-if="value.invest_select[index].invest_organ_view === 'Other'"
                placeholder="Other"
                v-model="value.invest_select[index].invest_organ_view_other"
              />
            </div>
          </div>
          <div
            class="ml-1"
            v-if="value.invest_select[index].invest_type === 'X-Ray'"
            style="min-width: 156px;"
          >
            <div class="label" for="'invest_side_' + index">
              Select Side
            </div>
            <select
              :id="'invest_side_' + index"
              class="form-control"
              v-model="value.invest_select[index].invest_side"
              :disabled="checkDisabled('invest_select', index)"
            >
              <option value disabled
                >No side select</option
              >
              <option value="Rt."
                >Rt.</option
              >
              <option value="Lt."
                >Lt.</option
              >
            </select>
          </div>
          <div
            class="ml-1"
            v-if="value.invest_select[index].invest_type === 'X-Ray'"
          >
            <div class="label" for="'invest_detail_' + index">Detail</div>
            <MazInput
              v-model="value.invest_select[index].invest_detail"
              :disabled="checkDisabled('invest_select', index)"
            ></MazInput>
          </div>
          <div class="ml-auto">
            <button
              type="button"
              class="btn btn-outline-secondary ml-1 mt-4"
              title="Delete Advice"
              @click="removeChildRowList('invest_select', index)"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
          <div
            class="mt-3 against-advice"
            style="width: 150px;"
            v-if="!value.invest_select[index].against_reason"
          >
            <input
              type="checkbox"
              class="form-control center-block"
              style="margin-top: 3px;"
              v-model="value.invest_select[index].is_against"
            />
          </div>
          <div
            class="pl-1 mt-4 text-center"
            style="width: 150px;"
            v-if="value.invest_select[index].against_reason"
          >
            {{ value.invest_select[index].against_reason }}
          </div>
        </div>
        <hr />
      </div>
      <div class="d-flex mt-2">
        <div class="ml-auto">
          <b-button
            variant="danger"
            v-b-modal.against-advice-invest
            @click="passingModal('invest_select')"
            >Against Advice</b-button
          >
        </div>
      </div>
      <div class="text-center">
        <button
          class="btn btn-primary m-2"
          @click="addChildRowList('invest_select', 1)"
        >
          <i class="fa fa-plus"></i> ADD ORDER
        </button>
      </div>
    </div>

    <div class="bg-lab mt-4 p-3 d-flex">
      <div>
        <span class="label-b text-light">
          <i class="fa fa-flask" aria-hidden="true"></i> Laboratory
        </span>
      </div>
      <div class="ml-auto">
        <button class="btn bg-white" @click="againstAllItem('lab_select')">
          <span class="txt-warning-red">
            <i class="fa fa-times" aria-hidden="true"></i> Against All Advice
          </span>
        </button>
      </div>
    </div>
    <div class="bg-gray p-3">
      <div class="mt-2">
        <b-button
          variant="outline-secondary"
          v-b-modal.select-lab-type
          >Select Laboratory Type</b-button
        >
      </div>
      <div class="mt-2">
        <span class="label-h">Worldmed center Lab</span>
        <table class="table" style="background-color: #fff;">
          <thead>
            <tr>
              <th width="20%">Lab Test</th>
              <th width="15%">Specimen</th>
              <th width="15%">Storage condition</th>
              <th width="15%">Turn around time</th>
              <th width="5%"></th>
              <th width="10%">Against Medical Advice</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, index) in value.lab_select" :key="i.id">
              <td v-if="value.lab_select[index].lab_type === 'In-House'" :class="checkDisabledByClass('lab_select', index)">
                <div class="d-flex">
                  <div class="mr-1">
                  <i
                    v-if="value.lab_select[index].against_reason"
                    class="far fa-times-circle"
                    style="font-size: 27px; color: red;"
                  ></i>
                  </div>
                  <div>
                    {{ i.lab_test }}
                  </div>
                </div>
              </td>
              <td v-if="value.lab_select[index].lab_type === 'In-House'" :class="checkDisabledByClass('lab_select', index)">
                {{ i.specimen }}
              </td>
              <td v-if="value.lab_select[index].lab_type === 'In-House'" :class="checkDisabledByClass('lab_select', index)">
                {{ i.storage_condition }}
              </td>
              <td v-if="value.lab_select[index].lab_type === 'In-House'" :class="checkDisabledByClass('lab_select', index)">
                {{ i.turn_around_time }}
              </td>
              <td v-if="value.lab_select[index].lab_type === 'In-House'" :class="checkDisabledByClass('lab_select', index)">
                <div class="text-center">
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    title="Delete Advice"
                    @click.prevent="removeLabShow(i)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </td>
              <td v-if="value.lab_select[index].lab_type === 'In-House'" :class="checkDisabledByClass('lab_select', index)">
                <div
                  class="against-advice"
                  style="width: 150px;"
                  v-if="value.lab_select"
                >
                  <input
                    type="checkbox"
                    class="form-control center-block"
                    style="margin-top: 3px;"
                    v-model="value.lab_select[index].is_against"
                    v-if="!value.lab_select[index].against_reason"
                  />
                </div>
                <div
                  class="pt-1 flex-2 text-center"
                  v-if="value.lab_select"
                >
                  {{ value.lab_select[index].against_reason ? value.lab_select[index].against_reason : null}}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-2">
        <span class="label-h">Out lab</span>
        <table class="table" style="background-color: #fff;">
          <thead>
            <tr>
              <th width="20%">Lab Test</th>
              <th width="15%">Specimen</th>
              <th width="15%">Storage condition</th>
              <th width="15%">Turn around time</th>
              <th width="5%"></th>
              <th width="10%">Against Medical Advice</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, index) in value.lab_select" :key="i.id">
              <td v-if="value.lab_select[index].lab_type === 'Out-Lab'" :class="checkDisabledByClass('lab_select', index)">
                <div class="d-flex">
                  <div class="mr-1">
                  <i
                    v-if="value.lab_select[index].against_reason"
                    class="far fa-times-circle"
                    style="font-size: 27px; color: red;"
                  ></i>
                  </div>
                  <div>
                    {{ i.lab_test }}
                  </div>
                </div>
              </td>
              <td v-if="value.lab_select[index].lab_type === 'Out-Lab'" :class="checkDisabledByClass('lab_select', index)">
                {{ i.specimen }}
              </td>
              <td v-if="value.lab_select[index].lab_type === 'Out-Lab'" :class="checkDisabledByClass('lab_select', index)">
                {{ i.storage_condition }}
              </td>
              <td v-if="value.lab_select[index].lab_type === 'Out-Lab'" :class="checkDisabledByClass('lab_select', index)">
                {{ i.turn_around_time }}
              </td>
              <td v-if="value.lab_select[index].lab_type === 'Out-Lab'" :class="checkDisabledByClass('lab_select', index)">
                <div class="text-center">
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    title="Delete Advice"
                    @click="removeLabShow(i)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </td>
              <td v-if="value.lab_select[index].lab_type === 'Out-Lab'" :class="checkDisabledByClass('lab_select', index)">
                <div
                  class="against-advice"
                  style="width: 150px;"
                  v-if="value.lab_select"
                >
                  <input
                    type="checkbox"
                    class="form-control center-block"
                    style="margin-top: 3px;"
                    v-model="value.lab_select[index].is_against"
                    v-if="!value.lab_select[index].against_reason"
                  />
                </div>
                <div
                  class="pl-1 pt-2 flex-2 text-center"
                  v-if="value.lab_select"
                >
                  {{ value.lab_select[index].against_reason ? value.lab_select[index].against_reason : null }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex mt-2">
        <div class="ml-auto">
          <b-button
            variant="danger"
            v-b-modal.against-advice-invest
            @click="passingModal('lab_select')"
            >Against Advice</b-button
          >
        </div>
      </div>
      <!-- modal -->
      <b-modal
        id="against-advice-invest"
        title="Against Medical Advice"
        hide-footer
      >
        <div class="mt-2">
          <label>Enter Against Advice reason for selected item:</label>
          <select class="form-control" v-model="value.temp_against_reason">
            <option v-for="dd in dd_reason" :key="dd.id" :value="dd">{{
              dd
            }}</option>
          </select>
        </div>
        <button
          class="btn btn-block btn-primary mt-3"
          @click="confirmAgainstAdvice(value.modal_focus)"
        >
          Confirm
        </button>
      </b-modal>
      <!-- lab select -->
      <b-modal
        size="xl"
        id="select-lab-type"
        title="Select Laboratory Type"
        hide-footer
      >
        <div class="lab-group-name text-center" style="font-size: 24px">FREQUENTLY USE LAB</div>
        <div class="text-center" style="font-size: 18; font-weight: 600;">1.2 all in-house lab done within 45 minutes. All out-lab takes longer and labeled by clock icon.</div>
        <div class="d-flex mt-2">
          <div class="flex-fill">
            <div class="mt-2" v-for="group in dd_labgrp.filter(group => group === 'Hematology' || group === 'Chemistry')" :key="group.id">
              <div class="lab-group-name">{{ group }}</div>
              <div class="mt-2" v-for="lab in dd_invest_lab.filter(lab => lab.group === group)" :key="lab.id">
                <label>
                  <input :checked="temp_lab_select.includes(lab)" type="checkbox" :value="lab" v-model="temp_lab_select" @click="toggleCheck(temp_lab_select, lab)">
                  <span class="ml-1">{{ lab.lab_test }}</span>
                </label>
                <div class="mt-2 ml-4" v-for="sub_lab in lab.sub_labs" :key="sub_lab.id">
                  <label>
                    <input :disabled="temp_lab_select.includes(lab)" :checked="temp_lab_select.includes(sub_lab)" type="checkbox" :value="sub_lab" v-model="temp_lab_select" @click="toggleCheck(temp_lab_select, sub_lab)">
                    <span class="ml-1">{{ sub_lab.lab_test }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-fill">
            <div class="mt-2" v-for="group in dd_labgrp.filter(group => group === 'Immunology' || group === 'Immunology : Cardiac Marker' || group === 'Immunology : Rapid Test')" :key="group.id">
              <div class="lab-group-name">{{ group }}</div>
              <div class="mt-2" v-for="lab in dd_invest_lab.filter(lab => lab.group === group)" :key="lab.id">
                <label>
                  <input type="checkbox" :value="lab" v-model="temp_lab_select" @click="toggleCheck(temp_lab_select, lab)">
                  <span class="ml-1">
                    {{ lab.lab_test }} 
                    <i class="fas fa-clock" v-if="lab.more_than_day"></i> <span class="text-muted" v-if="lab.more_than_day">{{lab.turn_around_time}}</span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="flex-fill">
            <div class="mt-2" v-for="group in dd_labgrp.filter(group => group === 'Microscopy : Urine' || group === 'Microscopy : Stool' || group === 'Microscopy' || group === 'STD')" :key="group.id">
              <div class="lab-group-name">{{ group }}</div>
              <div class="mt-2" v-for="lab in dd_invest_lab.filter(lab => lab.group === group)" :key="lab.id">
                <label>
                  <input type="checkbox" :value="lab" v-model="temp_lab_select" @click="toggleCheck(temp_lab_select, lab)">
                  <span class="ml-1">
                    {{ lab.lab_test }}
                    <i class="fas fa-clock" v-if="lab.more_than_day"></i> <span class="text-muted" v-if="lab.more_than_day">{{lab.turn_around_time}}</span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="lab-group-name text-center">Other tests</div>
        <div style="float: right;">
          <button
            style="width: 150px;"
            class="btn btn-primary mt-3 ml-2"
            @click.prevent="confirmLabSelected"
          >
            Confirm
          </button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import opdMixins from "../../mixins/opd";
import dd_ekg from "../../assets/ekg.json";
import dd_organ_view from "../../assets/organview.json";
import dd_invest_lab from "../../assets/invest_lab.json";

import _ from "lodash";
const invest_type = ["EKG", "X-Ray"];
const lab_grp = [
  "Hematology",
  "Microscopy : Urine",
  "Microscopy : Stool",
  "Microscopy",
  "STD",
  "Chemistry",
  "Immunology",
  "Immunology : Cardiac Marker",
  "Immunology : Rapid Test",
  "Other tests"
]
export default {
  mixins: [opdMixins],
  props: ["value"],
  data() {
    return {
      dd_organ_view,
      dd_ekg,
      dd_invest_type: invest_type,
      dd_labgrp: lab_grp,
      dd_invest_lab,
    };
  },
  methods: {
    getViews(index) {
      // console.log(this.value.invest_select[index].invest_organ_view)
      if (this.value.invest_select[index].invest_organ_view) {
        let res = dd_organ_view.filter(
          f => f.name === this.value.invest_select[index].invest_organ_view
        );
        console.log(res);
        this.value.invest_select[index].show_organ_view = res[0].views;
        this.value.invest_select[index].views = res[0].views;
        this.value.invest_select[index].is_side = res[0].is_side;
      }
    },
  },
  computed: {
    ddOrganHead() {
      let res = dd_organ_view.filter(f => f.group === "HEAD & NECK");
      return res;
    },
    ddOrganChest() {
      let res = dd_organ_view.filter(f => f.group === "CHEST");
      return res;
    },
    ddOrganAbdomen() {
      let res = dd_organ_view.filter(f => f.group === "ABDOMEN");
      return res;
    },
    ddOrganExtrem() {
      let res = dd_organ_view.filter(f => f.group === "EXTREMITIES");
      return res;
    },
    ddOrganSpine() {
      let res = dd_organ_view.filter(f => f.group === "SPINE");
      return res;
    }
  }
};
</script>
<style lang="scss" scoped>
.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}
table,
th,
td {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-collapse: collapse;
}

.label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.label-b {
  color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}

.label-h {
  // color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}

.form-check {
  margin-left: 10px;
}

a:hover {
  color: #dc0000ff;
  background-color: transparent;
  text-decoration: underline;
}

button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

input[type="checkbox"] {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

.against-advice {
  input[type="checkbox"] {
    -webkit-appearance: initial;
    appearance: initial;
    background: gray;
    width: 45px;
    height: 40px;
    border: none;
    position: relative;
  }
  input[type="checkbox"]:checked {
    background: red;
  }
  input[type="checkbox"]:checked:after {
    /* Heres your symbol replacement */
    content: "X";
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
.center-block {
  margin: auto;
  display: block;
}

.form-control {
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
  height: 2.855rem;
  min-height: 2.855rem;
  margin-top: 0.032rem;
  border-color: #eee;
}
.form-control:disabled {
  cursor: not-allowed;
  background-color: #f2f2f2 !important;
}
.is-against-active {
  color: rgb(144, 144, 144);
  background-color: #F2F2F2;
}

.lab-group-name {
  background-color: #A4C964;
  padding: 8px;
  border-radius: 0.25em;
  margin-right: 5px;
  font-weight: 600;
}
</style>
