<template>
  <div class="container-fluid">
    <h3 class="text-primary">HN: {{ patient.HN }}</h3>
    <h3 class="text-primary">VN: {{ $route.params.visitId }}</h3>
    <billing-table :patient="patient" :visitId="$route.params.visitId"/>
  </div>
</template>

<script>
// import draggable from 'vuedraggable'
import BillingTable from '../../../components/Billing/BillingTable.vue'
export default {
  props: ["patient", "nursing"],
  components: {
    BillingTable
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.timer {
  border-radius: 0.25em;
  padding: 15px;
  min-width: 300px;
  height: 150px;
  box-shadow: 2px 2px 10px #bfbfbf;
}

.card {
  min-height: 800px;
}
</style>