<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <div class="text-center pb-3 pt-2 box-border">
          <span class="label-h">Assessment and Intervention</span>
        </div>
        <div class="mt-4">
          <b-tabs
            content-class=""
            class="tab-vital"
            active-nav-item-class="font-weight-bold text-light"
          >
            <b-tab title="VITAL SIGN">
              <table class="table table-bordered mt-2">
                <thead class="text-center bg-general">
                  <tr>
                    <th width="15%">Date</th>
                    <th colspan="2">BLOOD PRESSURE</th>
                    <th>MEAN ARTERIAL PRESSSURE</th>
                    <th>HEART RATE</th>
                    <th>RESPIRATORY RATE</th>
                    <th>TEMPERATURE</th>
                    <th>OXYGEN SATURATION</th>
                    <th>PAIN SCORE</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(i, index) in observation.assessment_inver"
                    :key="index"
                  >
                    <td>
                      <flat-pickr
                        class="form-control"
                        :config="$store.state.stdDateTimeConfig"
                        style="background-color: #fff;"
                        v-model="observation.assessment_inver[index].date_time"
                      ></flat-pickr>
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="observation.assessment_inver[index].bp_syst"
                        placeholder="SYS"
                        :class="checkBPSys(i.bp_syst, patient.ageMonth, patient.ageDay)"
                        @change="alertBPSys(i.bp_syst, patient.ageMonth, patient.ageDay)"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="observation.assessment_inver[index].bp_dias"
                        placeholder="DIAS"
                        :class="checkBPDias(i.bp_dias, patient.ageMonth)"
                        @blur="alertBPDias(i.bp_dias, patient.ageMonth)"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        :value="
                          i.bp_syst && i.bp_dias
                            ? getMap(i.bp_syst, i.bp_dias)
                            : null
                        "
                        :class="{
                          'vital-red':
                            i.bp_syst &&
                            i.bp_dias &&
                            getMap(i.bp_syst, i.bp_dias) > 0 &&
                            (getMap(i.bp_syst, i.bp_dias) < 70 ||
                              getMap(i.bp_syst, i.bp_dias) > 100)
                        }"
                      />
                    </td>
                    <td>
                      <input
                        :id="'vital_sign_hr_' + index"
                        type="text"
                        class="form-control"
                        v-model="observation.assessment_inver[index].hr"
                        @blur="alertHR(i.hr, patient.ageMonth)"
                        :class="checkHR(i.hr, patient.ageMonth)"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="observation.assessment_inver[index].rr"
                        :class="checkRR(i.rr, patient.ageMonth)"
                        @blur="alertRR(i.rr, patient.ageMonth)"
                      />
                    </td>
                    <td>
                      <div class="input-group">
                        <input
                          type="text"
                          :id="'observation_temp_' + index"
                          class="form-control"
                          v-model="observation.assessment_inver[index].temp"
                          @blur="alertTemp(i.temp)"
                          :class="checkTemp(i.temp)"
                        />
                        <div class="input-group-append">
                          <select
                            class="form-control input-group-text bg-white p-2"
                            v-model="
                              observation.assessment_inver[index].temp_unit
                            "
                            @blur="alertTemp(i.temp, i.temp_unit)"
                          >
                            <!-- <option value disabled selected></option> -->
                            <option value="C" selected>°C</option>
                            <option value="F">°F</option>
                          </select>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          v-model="observation.assessment_inver[index].o2sat"
                          @blur="alertO2Sat(i.o2sat)"
                          :class="checkO2Sat(i.o2sat)"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text p-1 bg-white"
                            >%</span
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <select
                        id="pain_score"
                        class="form-control non-arrow"
                        v-model="observation.assessment_inver[index].pain_score"
                        :class="checkPainScore(i.pain_score)"
                        @blur="alertPainScore(i.pain_score)"
                      >
                        <option value disabled selected hidden></option>
                        <option value="0" selected>0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-secondary m-2"
                        @click="
                          removeRowList(
                            'observation',
                            'assessment_inver',
                            index
                          )
                        "
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-tab>
            <b-tab title="OTHER ASSESSMENT">
              <table class="table table-bordered mt-2">
                <thead class="text-center bg-general">
                  <tr>
                    <th rowspan="2" width="15%">Date</th>
                    <th rowspan="2" colspan="4">GLASGOW COMA SCALE (GCS)</th>
                    <th colspan="2">FLUID INPUT AND OUTPUT (I and O)</th>
                    <th rowspan="2">Random Blood Sugar<br />(RBS / DTX)</th>
                    <th rowspan="2"></th>
                  </tr>
                  <tr>
                    <th>input</th>
                    <th>output</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(i, index) in observation.assessment_inver"
                    :key="index"
                  >
                    <td>
                      <flat-pickr
                        class="form-control"
                        :config="$store.state.stdDateTimeConfig"
                        style="background-color: #fff;"
                        v-model="observation.assessment_inver[index].date_time"
                      ></flat-pickr>
                    </td>
                    <td width="10%">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label"
                            >E</span
                          >
                        </div>
                        <select
                          class="form-control border-left-0 non-arrow"
                          v-model="observation.assessment_inver[index].gcs_e"
                          :class="checkGcsE(i.gcs_e)"
                        >
                          <option value disabled selected hidden></option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                      </div>
                    </td>
                    <td width="10%">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label"
                            >V</span
                          >
                        </div>
                        <select
                          class="form-control border-left-0 non-arrow"
                          v-model="observation.assessment_inver[index].gcs_v"
                          :class="checkGcsV(i.gcs_v)"
                        >
                          <option value disabled selected hidden></option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                      </div>
                    </td>
                    <td width="10%">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label"
                            >M</span
                          >
                        </div>
                        <select
                          class="form-control border-left-0 non-arrow"
                          v-model="observation.assessment_inver[index].gcs_m"
                          :class="checkGcsM(i.gcs_m)"
                        >
                          <option value disabled selected hidden></option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                        </select>
                      </div>
                    </td>
                    <td width="10%">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text p-1 bg-white label"
                            >Total
                          </span>
                        </div>
                        <input
                          type="text"
                          class="form-control border-left-0"
                          :value="
                            i.gcs_e && i.gcs_v && i.gcs_m
                              ? getGcsTotal(i.gcs_e, i.gcs_v, i.gcs_m)
                              : null
                          "
                          :class="
                            checkGcsTotal(
                              getGcsTotal(i.gcs_e, i.gcs_v, i.gcs_m)
                            )
                          "
                        />
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="observation.assessment_inver[index].io_in"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="observation.assessment_inver[index].io_out"
                      />
                    </td>
                    <td>
                      <div class="input-group" style="width: 190px;">
                        <input
                          type="text"
                          class="form-control border-right-0"
                          v-model="observation.assessment_inver[index].dtx"
                          :class="checkDTX(i.dtx, i.dtx_unit)"
                          @change="alertDTX(i.dtx, i.dtx_unit)"
                        />
                        <div class="input-group-append">
                          <select
                            class="form-control input-group-text bg-white"
                            v-model="
                              observation.assessment_inver[index].dtx_unit
                            "
                          >
                            <!-- <option value disabled selected></option> -->
                            <option value="mg/dl" selected>mg/dl</option>
                            <option value="mmol/L">mmol/L</option>
                          </select>
                        </div>
                      </div>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-secondary m-2"
                        @click="
                          removeRowList(
                            'observation',
                            'assessment_inver',
                            index
                          )
                        "
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-tab>
            <b-tab title="NURSING PROCESS">
              <table class="table table-bordered mt-2">
                <thead class="text-center bg-general">
                  <tr>
                    <th width="10%">Date</th>
                    <th width="40%">INTERVENTION</th>
                    <th>EVALUATION</th>
                    <th width="15%">ATTENDING NURSE</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(i, index) in observation.assessment_inver"
                    :key="index"
                  >
                    <td>
                      <flat-pickr
                        class="form-control"
                        :config="$store.state.stdDateTimeConfig"
                        style="background-color: #fff;"
                        v-model="observation.assessment_inver[index].date_time"
                      ></flat-pickr>
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="
                          observation.assessment_inver[index].intervention
                        "
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="observation.assessment_inver[index].evaluation"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        v-model="
                          observation.assessment_inver[index].attending_nurse
                        "
                      />
                    </td>
                    <td class="text-center">
                      <button
                        type="button"
                        class="btn btn-secondary m-2"
                        @click="
                          removeRowList(
                            'observation',
                            'assessment_inver',
                            index
                          )
                        "
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-tab>
          </b-tabs>
        </div>
        <div class="text-center">
          <button
            class="btn btn-primary m-2"
            @click="addRowList('observation', 'assessment_inver', 1)"
          >
            <i class="fa fa-plus"></i> ADD TABLE
          </button>
        </div>
        <hr />
        <div class="mt-3">
          <button
            v-if="hideGuide"
            class="btn btn-outline-secondary"
            @click.prevent="hideGuide = false"
          >
            <span style="float: right;">GUIDE</span>
          </button>
          <button
            v-if="!hideGuide"
            class="btn btn-outline-secondary"
            @click.prevent="hideGuide = true"
          >
            <span style="float: right;">GUIDE</span>
          </button>
        </div>
        <transition name="fade">
          <div v-if="!hideGuide">
            <div class="label-h mt-3">GUIDE VALUES:</div>
            <div class="mt-2">
              <table
                class="table table-bordered text-center"
                style="width: 40%; margin-left: auto; margin-right: auto;"
              >
                <thead>
                  <tr class="bg-general">
                    <th>MAP Normal Values</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>70-100 mmHG</td>
                  </tr>
                  <tr>
                    <td class="bg-warning-red text-white">
                      &lt;70 abnormal, &gt;100 abnormal
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-xl-6">
                <div class="label-b">BODY TEMPERATURE</div>
                <div class="p-3 text-center">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th rowspan="2">Normal Range</th>
                        <th colspan="4" style="background-color: #cccccc;">
                          Abnomal Range
                        </th>
                      </tr>
                      <tr>
                        <th class="bg-warning-red">Hypothermia</th>
                        <th class="bg-warning-red">Low Fever</th>
                        <th class="bg-warning-red">Moderate Fever</th>
                        <th class="bg-warning-red">High Fever</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>36.5-37.4°C</td>
                        <td class="bg-warning-red text-white">&lt;36.5°C</td>
                        <td class="bg-warning-red text-white">37.5-38.3°C</td>
                        <td class="bg-warning-red text-white">38.4-39.4°C</td>
                        <td class="bg-warning-red text-white">&gt;39.5°C</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="label-b">OXYGEN SATURATION % (O2Sat %)</div>
                <div class="p-3">
                  <table
                    class="table table-bordered w-75"
                    style="table-layout: fixed;"
                  >
                    <tbody>
                      <tr>
                        <td>Normal</td>
                        <td>95-100%</td>
                      </tr>
                      <tr>
                        <td>Normal (COPD)</td>
                        <td>88-92%</td>
                      </tr>
                      <tr style="background-color: #b6d7a8;">
                        <td>● Mild Hypoxemia</td>
                        <td>90-94%</td>
                      </tr>
                      <tr style="background-color: #fff2cc;">
                        <td>● Moderate Hypoxemia</td>
                        <td style="color: red;">85-89%</td>
                      </tr>
                      <tr style="background-color: #dc0000ff; color: #fff">
                        <td>● Severe Hypoxemia</td>
                        <td>&lt;85%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="label-b">PAIN SCORE 1-10</div>
                <div class="p-3">
                  <table
                    class="table table-bordered w-75"
                    style="table-layout: fixed;"
                  >
                    <tbody>
                      <tr>
                        <td>Normal</td>
                        <td>0</td>
                      </tr>
                      <tr style="background-color: #b6d7a8;">
                        <td>*Mid Pain</td>
                        <td>1-3</td>
                      </tr>
                      <tr style="background-color: #fff2cc;">
                        <td>*Moderate Pain</td>
                        <td>4-6</td>
                      </tr>
                      <tr style="background-color: #dc0000ff; color: #fff">
                        <td>*Severe Pain</td>
                        <td>7-10</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="label-b">HEART RATE (HR) bpm</div>
                <div class="p-3">
                  <table class="table table-bordered text-center">
                    <thead>
                      <tr>
                        <th>AGE</th>
                        <th style="background-color: #dc0000ff; color: #fff">
                          Sinus Bradycardia
                        </th>
                        <th>Normal Range</th>
                        <th style="background-color: #dc0000ff; color: #fff">
                          Sinus Tachycardia
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1-12 months</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;90
                        </td>
                        <td>90-190</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &gt;190
                        </td>
                      </tr>
                      <tr>
                        <td>1-2 years</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;80
                        </td>
                        <td>80-140</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &gt;140
                        </td>
                      </tr>
                      <tr>
                        <td>3-5 years</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;65
                        </td>
                        <td>65-120</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &gt;120
                        </td>
                      </tr>
                      <tr>
                        <td>6-11 years</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;58
                        </td>
                        <td>58-118</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &gt;118
                        </td>
                      </tr>
                      <tr>
                        <td>&gt; 12 years up</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;50
                        </td>
                        <td>50-100</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &gt;100
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-bordered text-center">
                    <thead>
                      <tr>
                        <th colspan="2">
                          NORMAL HEART RATE FOR ADULTS 18-65 Years Old
                        </th>
                        <th class="bg-warning-red text-white">Abnormal</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><b>RESTING HEART RATE (sitting, relaxing)</b></td>
                        <td><b>60-90 bpm</b><br />(40-60 bpm Athlete)</td>
                        <td class="bg-warning-red text-white">
                          &lt;60, &gt;90 BPM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="label-b">Respiratory RATE (RR) bpm</div>
                <div class="p-3">
                  <table class="table table-bordered text-center">
                    <thead>
                      <tr>
                        <th>AGE</th>
                        <th>Normal Range</th>
                        <th style="background-color: #dc0000ff; color: #fff">
                          Abnomal
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0-3 months</td>
                        <td>33-55</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;35, &gt;55
                        </td>
                      </tr>
                      <tr>
                        <td>3-6 months</td>
                        <td>30-45</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;30, &gt;45
                        </td>
                      </tr>
                      <tr>
                        <td>6-12 months</td>
                        <td>22-38</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;22, &gt;38
                        </td>
                      </tr>
                      <tr>
                        <td>1-3 years</td>
                        <td>22-30</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;22, &gt;30
                        </td>
                      </tr>
                      <tr>
                        <td>3-6 years</td>
                        <td>20-24</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;20, &gt;24
                        </td>
                      </tr>
                      <tr>
                        <td>6-12 years</td>
                        <td>16-22</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;16, &gt;22
                        </td>
                      </tr>
                      <tr>
                        <td>&gt;12 years</td>
                        <td>12-20</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;12, &gt;20
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="label-b">BLOOD PRESSURE mmHg (BP)</div>
                <div class="p-3">
                  <table class="table table-bordered text-center">
                    <thead>
                      <tr>
                        <th></th>
                        <th style="background-color: #dc0000ff; color: #fff">
                          Hypotension
                        </th>
                        <th></th>
                        <th style="background-color: #dc0000ff; color: #fff">
                          Hypotension
                        </th>
                      </tr>
                      <tr>
                        <th>AGE</th>
                        <th>SBP</th>
                        <th>DBP</th>
                        <th>MAP (mmHg)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1-12 months</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;70
                        </td>
                        <td>&lt;37</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;65
                        </td>
                      </tr>
                      <tr>
                        <td>1-2 years</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;72
                        </td>
                        <td>&lt;41</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;65
                        </td>
                      </tr>
                      <tr>
                        <td>2-3 years</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;74
                        </td>
                        <td>&lt;44</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;65
                        </td>
                      </tr>
                      <tr>
                        <td>3-4 years</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;76
                        </td>
                        <td>&lt;47</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;65
                        </td>
                      </tr>
                      <tr>
                        <td>4-5 years</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;78
                        </td>
                        <td>&lt;50</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;65
                        </td>
                      </tr>
                      <tr>
                        <td>5-6 years</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;80
                        </td>
                        <td>&lt;53</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;65
                        </td>
                      </tr>
                      <tr>
                        <td>6-7 years</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;82
                        </td>
                        <td>&lt;59</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;65
                        </td>
                      </tr>
                      <tr>
                        <td>7-8 years</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;84
                        </td>
                        <td>&lt;60</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;65
                        </td>
                      </tr>
                      <tr>
                        <td>8-9 years</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;86
                        </td>
                        <td>&lt;60</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;65
                        </td>
                      </tr>
                      <tr>
                        <td>9-10 years</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;88
                        </td>
                        <td>&lt;61</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;65
                        </td>
                      </tr>
                      <tr>
                        <td>&gt;10 years</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;90
                        </td>
                        <td>&lt;62</td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;65
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="label-b">BP for Age 1-13 years</div>
                <div class="p-3">
                  <table class="table table-bordered text-center">
                    <thead>
                      <tr>
                        <th style="color: #dc0000ff;">Alert</th>
                        <th>SBP</th>
                        <th>DBP</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="background-color: #b6d7a8;">
                          <b>Prehypertension</b>
                        </td>
                        <td style="background-color: #b6d7a8;">&gt;120</td>
                        <td style="background-color: #b6d7a8;">&gt;80</td>
                      </tr>
                      <tr>
                        <td style="background-color: #fff2cc;">
                          <b>Hypertension Stage 1</b>
                        </td>
                        <td style="background-color: #fff2cc;">130-139</td>
                        <td style="background-color: #fff2cc;">80-89</td>
                      </tr>
                      <tr>
                        <td style="background-color: #dc0000ff; color: #fff">
                          <b>Hypertension Stage 2</b>
                        </td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &gt;140
                        </td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &gt;90
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="label-b">BP for Age &ge; 18 years</div>
                <div class="p-3">
                  <table class="table table-bordered text-center">
                    <thead>
                      <tr>
                        <th style="color: #dc0000ff;">Alert</th>
                        <th>SBP</th>
                        <th>DBP</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="background-color: #dc0000ff; color: #fff">
                          <b>Hypotension</b>
                        </td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;90
                        </td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &lt;60
                        </td>
                      </tr>
                      <tr>
                        <td style="background-color: #b6d7a8;">
                          <b>Prehypertension</b>
                        </td>
                        <td style="background-color: #b6d7a8;">120-139</td>
                        <td style="background-color: #b6d7a8;">80-89</td>
                      </tr>
                      <tr>
                        <td style="background-color: #fff2cc;">
                          <b>Hypertension Stage 1</b>
                        </td>
                        <td style="background-color: #fff2cc;">140-159</td>
                        <td style="background-color: #fff2cc;">90-99</td>
                      </tr>
                      <tr>
                        <td style="background-color: #f4cccc;">
                          <b>Hypertension Stage 2</b>
                        </td>
                        <td style="background-color: #f4cccc;">160-179</td>
                        <td style="background-color: #f4cccc;">100-109</td>
                      </tr>
                      <tr>
                        <td style="background-color: #dc0000ff; color: #fff">
                          <b>Hypertension Crisis</b>
                        </td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &gt;180
                        </td>
                        <td style="background-color: #dc0000ff; color: #fff">
                          &gt;110
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table class="table table-bordered text-center">
                    <thead class=" text-center">
                      <tr>
                        <th>Blood Sugar (DTX)</th>
                        <th>mg/dl</th>
                        <th>mmol/L</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="bg-warning-red text-white">
                        <td rowspan="5">Hypoglycemia (Low Blood Sugar)</td>
                        <td>30</td>
                        <td>1.7</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td>40</td>
                        <td>2.2</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td>50</td>
                        <td>2.8</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td>60</td>
                        <td>3.3</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td>70</td>
                        <td>3.9</td>
                      </tr>
                      <tr>
                        <td rowspan="4">Normal Blood Sugar</td>
                        <td>70</td>
                        <td>3.9</td>
                      </tr>
                      <tr>
                        <td>80</td>
                        <td>4.4</td>
                      </tr>
                      <tr>
                        <td>90</td>
                        <td>5.0</td>
                      </tr>
                      <tr>
                        <td>100</td>
                        <td>5.5</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td rowspan="4">*Pre-Diabetic Range</td>
                        <td>101</td>
                        <td>5.6</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td>110</td>
                        <td>6.1</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td>120</td>
                        <td>6.7</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td>125</td>
                        <td>6.9</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td rowspan="11">*Diabetic Range</td>
                        <td>126</td>
                        <td>7.0</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td>140</td>
                        <td>7.8</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td>155</td>
                        <td>8.6</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td>160</td>
                        <td>8.9</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td>175</td>
                        <td>9.7</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td>190</td>
                        <td>10.6</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td>200</td>
                        <td>11.1</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td>250</td>
                        <td>13.9</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td>300</td>
                        <td>16.7</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td>400</td>
                        <td>22.2</td>
                      </tr>
                      <tr class="bg-warning-red text-white">
                        <td>600</td>
                        <td>33.3</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <hr />
        <div class="text-center">
          <button
            type="button"
            class="btn btn-success btn-lg"
            style="width: 160px;"
          >
            Save
          </button>
          <button
            style="margin-left: 10px; width: 200px;"
            type="button"
            class="btn btn-primary btn-lg"
          >
            Send Patient to
          </button>
          <button
            style="margin-left: 10px; width: 160px;"
            type="button"
            class="btn btn-danger btn-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import observationMixins from "../../../mixins/observation";
import vitalSignMixins from "../../../mixins/vital-sign";

export default {
  mixins: [observationMixins, vitalSignMixins],
  props: {
    patient: {
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      hideGuide: false
    };
  },
  methods: {}
};
</script>
<style lang="scss" scoped>

.vital-red {
  background-color: #dc0000ff;
  color: #fff;
}
.vital-mid-red {
  background-color: #ea9999;
}
.vital-soft-red {
  background-color: #f4cccc;
}
//Main css
.non-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.label-h {
  // color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}

.center {
  text-align: center;
}

.label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.box-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  // width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.label-b {
  color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
  margin-left: 15px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.tableScroll {
  width: 100%;
  margin-bottom: 15px;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border: 1px solid #000000;
}

table,
th,
td {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-collapse: collapse;
  font-size: 17px;
}
</style>
