import { render, staticRenderFns } from "./PatientSearch.vue?vue&type=template&id=6cdc1207"
import script from "./PatientSearch.vue?vue&type=script&lang=js"
export * from "./PatientSearch.vue?vue&type=script&lang=js"
import style0 from "./PatientSearch.vue?vue&type=style&index=0&id=6cdc1207&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports