<template>
  <b-modal :id="id" title="Order Against Medical Advice" size="xl" hide-footer>
    <div class="mb-2">
      <div class="container-fluid mt-2" v-for="(item, mIndex) in value" :key="mIndex">
        <h5>Against Medical Advice</h5>
        <h5>Select type: </h5>

        <div class="text-success d-flex flex-wrap">
          <div class="menu-item shadow m-2" v-for="(menu, index) in menus" :key="index" @click="item.against_medical_advice = menu.name">
            <div class="menu-icon" :class="{'text-white': menu.name === item.against_medical_advice, 'bg-success': menu.name === item.against_medical_advice}" >
              <i :class="menu.icon"></i>
            </div>
            <div class="mt-1">{{menu.name}}</div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <MazSelect
              v-model="item.recommend"
              search
              placeholder="recommended investigation, treatment, management"
              :options="against_dropdown"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <MazInput
              v-model="item.possible_risk"
              placeholder="possible risks"
            ></MazInput>
          </div>
          <div class="col-5">
            <MazSelect
              v-model="item.reason"
              search
              placeholder="Reason"
              :options="reasons.map(r => {
                return { label: r, value: r }
              })"
            />
          </div>
          <div class="col-1">
            <button class="btn btn-light" @click="value.splice(index, 1)"><i class="fas fa-trash"></i></button>
          </div>
        </div>
      </div>

      <button class="btn btn-outline-success btn-block mt-2" @click="value.push({date_time: new Date()})">
        <i class="fas fa-plus"></i> Against Medical Advice
      </button>

    </div>
    <hr>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary mr-2" @click="confirmOrder">Confirm Order</button>
      <button class="btn btn-danger mr-2" @click.prevent="$bvModal.hide(id)">Cancel</button>
    </div>
  </b-modal>
</template>

<script>
import opdMixins from "../../../mixins/opd";
const reasons = [
  "Financial Problem",
  "No Insurance",
  "Insurance don't cover",
  "Time not enough",
  "Patient have own medicine",
  "Patient feels better",
  "Communication Problem"
]
export default {
  mixins: [opdMixins],
  data() {
    return {
      id: 'order-against',
      menus: [
        { name: 'Investigation', icon: 'fas fa-x-ray', action: () => { this.$bvModal.show('order-investigation'); this.currentMenu = 'Investigation'; }},
        { name: 'Laboratory', icon: 'fas fa-microscope', action: () => { this.$bvModal.show('order-laboratory'); this.currentMenu = 'Laboratory';} },
        { name: 'Procedure', icon: 'fas fa-stethoscope', action: () => { this.$bvModal.show('order-procedure'); this.currentMenu = 'Procedure'; } },
        { name: 'Medicine', icon: 'fas fa-capsules', action: () => { this.$bvModal.show('order-medicine'); this.currentMenu = 'Medicine';} },
        { name: 'Supplies', icon: 'fas fa-medkit', action: () => { this.$bvModal.show('order-supplies'); this.currentMenu = 'Supplies';} },
        { name: 'Special Orders', icon: 'fas fa-paste', action: () => { this.$bvModal.show('order-special'); this.currentMenu = 'Special Orders';} },
        { name: 'Admit', icon: 'fas fa-bed', action: () => { this.$bvModal.show('order-admit'); this.currentMenu = 'Admit';} },
        { name: 'Refer', icon: 'fas fa-ambulance', action: () => { this.$bvModal.show('order-refer'); this.currentMenu = 'Refer';} },
        { name: 'All', icon: 'fas fa-redo', action: () => { this.$bvModal.show('order-refer'); this.currentMenu = 'Refer';} },
      ],
      reasons
    }
  },
  props: {
    value: {
      default: () => {
        return [{against_medical_advice: null,date_time: new Date()}]
      }
    },
    onClose: {
      default: () => {}
    },
    onConfirm: {
      default: () => {}
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === this.id)
      this.onClose()
    })

    console.log('[+] against medical advice', this.opd)
  },
  methods: {
    confirmOrder() {
      this.$bvModal.hide(this.id)
      this.onConfirm()
    }
  },
  computed: {
    against_dropdown() {
      let dropdown = []

      for(let item of this.opd.invest_lab.invest_select) {
        dropdown.push({
          label: `[INVESTIGATION] ${item.invest_type || ''} ${item.invest_ekg_type || ''} ${item.invest_ekg_type_other || ''} ${item.invest_organ_view || ''} ${item.invest_organ_view_other || ''}`,
          value: item
        })
      }
      for(let item of this.opd.invest_lab.lab_select) {
        dropdown.push({
          label: `[LAB] ${item.type || ''} ${item.lab_test || ''}` ,
          value: item
        })
      }
      for(let item of this.opd.treatment.med_supply) {
        dropdown.push({
          label: `[MEDICINE] ${item.med_supply_medicine || ''}` ,
          value: item
        })
      }
      for(let item of this.opd.special_orders) {
        dropdown.push({
          label: `[SPECIAL ORDER] ${item.special_order || ''} (${item.note || ''})` ,
          value: item
        })
      }

      return dropdown
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-item {
  width: 120px;
  height: 120px;
  text-align: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 0.25em;
}

.menu-item-active {
  color: white;
  background-color: greenyellow;
}

.menu-icon {
  font-size: 48px;
  padding: 5px;
  border-radius: 0.25em;
  background-color: white;
}
</style>