import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";
import Login from "../pages/Login.vue";
import Home from "../pages/Home.vue";

import dashboardRoutes from "./dashboard";
import registerRoutes from "./register";
import patientRoutes from "./patient";
import patientSummaryRoutes from "./patientsummary";
import printRoutes from "./print";
import staffRoutes from "./staff";
import StatisticRoutes from './statistic';
import billingRoutes from "./billing";
import InsuranceRoutes from "./insurance";
import SettingRoutes from "./setting";
import ExpenseRoutes from "./expense";
import PatientSearch from "../pages/PatientSearch";
import PatientChart from "../pages/PatientChart";
import OperationReport from "../pages/OperationReport";
import Account from "../pages/Account.vue";
// import Setting from "../pages/Setting";
// import Zone from "../pages/Setting/Zone.vue";
import ProductRoutes from "./product";
import ServiceRoutes from "./service";
import PackageRoutes from "./package";
import SaleRoutes from "./sale";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/",
    name: "Home",
    component: Home
  },
  ...dashboardRoutes,
  ...registerRoutes,
  ...patientRoutes,
  ...patientSummaryRoutes,
  ...printRoutes,
  {
    path: "/patient-chart",
    name: "Patient Chart",
    component: PatientChart
  },
  {
    path: "/patient-search",
    name: "Patient Search",
    component: PatientSearch
  },
  {
    path: "/operation-report",
    name: "Operation Report",
    component: OperationReport
  },
  ...billingRoutes,
  ...InsuranceRoutes,
  ...ProductRoutes,
  ...ServiceRoutes,
  ...PackageRoutes,
  ...SaleRoutes,
  ...ExpenseRoutes,
  ...staffRoutes,
  ...StatisticRoutes,
  ...SettingRoutes,
  {
    path: "/account",
    name: "Account",
    component: Account
  },

  // {
  //   path: "/setting",
  //   name: "Setting",
  //   component: Setting
  // },
  // {
  //   path: "/setting/zone",
  //   name: "Hotel Zone",
  //   component: Zone
  // }
  //...ProductRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    next();
  } else {
    store.getters.user ? next() : router.push("/login");
  }
});

export default router;
