<template>
  <div class="container-fluid">
    <div class="bg-white" v-if="opd">
      <div class="pd10">
        <div class="d-flex mt-3">
          <div class="flex-even">
            <div class="bg-lab p-3">
              <span class="label-b text-light"
                ><i class="fa fa-heartbeat" aria-hidden="true"></i>
                Investigation ({{opd.invest_lab.invest_select.length}})</span
              >
              <!-- <i
                class="fas fa-edit pt-1"
                style="float: right; color: #fff; font-size: 20px; cursor: pointer;"
              ></i> -->
            </div>
            <div class="bg-gray p-3 pt-5 pb-5">
              <div
                class="d-flex mt-1"
                v-for="(i, index) in opd.invest_lab.invest_select"
                :key="'I'+index"
              >
                <div class="text-success flex-2 ml-1" v-if="i.invest_date_time.toDate ==='function'">
                  {{ i.invest_date_time.toDate() | moment('DD-MM-YYYY HH:mm')}}
                </div>
                <div class="text-success flex-2 ml-1" v-else>
                  {{ i.invest_date_time | moment('DD-MM-YYYY HH:mm')}}
                </div>
                <div class="ml-2 flex-3">
                  <b>{{ i.invest_type }}</b>
                </div>
                <div class="ml-2 flex-3" v-if="i.invest_ekg_type">
                  {{i.invest_ekg_type}}
                </div>
                <div class="ml-2 flex-3" v-if="i.invest_organ_view">
                  {{i.invest_organ_view}}
                </div>
              </div>
            </div>
          </div>
          <div class="flex-even pl-3">
            <div class="bg-lab p-3">
              <span class="label-b text-light"
                ><i class="fa fa-flask" aria-hidden="true"></i> Laboratory ({{opd.invest_lab.lab_select.length}})
              </span>
              <!-- <i
                class="fas fa-edit pt-1"
                style="float: right; color: #fff; font-size: 20px; cursor: pointer;"
              ></i> -->
            </div>
            <div class="bg-gray p-3 pt-5 pb-5">
              <div
                class="row mt-1"
                v-for="(i, index) in opd.invest_lab.outlab_select"
                :key="'OL'+index"
              >
                <div class="text-success ml-2 col-2">
                  <b>{{ i.lab_type }}</b>
                </div>
                <div class="ml-2 col-2">
                  <b>{{ i.lab_test }}</b>
                </div>
                <div class="ml-2 col-2">
                  {{ i.specimen }}
                </div>
                <div class="ml-2 col-2">
                  {{ i.sample }}
                </div>
                <div class="ml-2 col-2">
                  {{ i.turn_around_time }}
                </div>
              </div>
              <div
                class="row mt-1"
                v-for="(i, index) in opd.invest_lab.lab_select"
                :key="'L'+index"
              >
                <div class="text-success ml-2 col-2">
                  <b>{{ i.lab_type }}</b>
                </div>
                <div class="ml-2 col-2">
                  <b>{{ i.lab_test }}</b>
                </div>
                <div class="ml-2 col-2">
                  {{ i.specimen }}
                </div>
                <div class="ml-2 col-2">
                  {{ i.sample }}
                </div>
                <div class="ml-2 col-2">
                  {{ i.turn_around_time }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-nursing p-3 mt-3">
          <span class="label-b text-light"
            ><i class="fas fa-syringe" aria-hidden="true"></i> Procedure & Medicines ({{opd.treatment.med_supply.length+opd.treatment.procedure.length}})
          </span>
          <!-- <i
            class="fas fa-edit pt-1"
            style="float: right; color: #fff; font-size: 20px; cursor: pointer;"
          ></i> -->
        </div>
        <div class="bg-gray p-5">
          <div
            class="d-flex mt-1"
            v-for="(i, index) in opd.treatment.procedure"
            :key="'P'+index"
          >
            <div class="text-success flex-2 ml-1" v-if="i.procedure_date_time.toDate ==='function'">
              {{ i.procedure_date_time.toDate() | moment('DD-MM-YYYY HH:mm')}}
            </div>
            <div class="text-success flex-2 ml-1" v-else>
              {{ i.procedure_date_time | moment('DD-MM-YYYY HH:mm')}}
            </div>
            <div class=" flex-1 ml-3">
              {{ i.procedure_name }}
            </div>
            <div class="pl-3 flex-2" style="min-width: 320px;">
              {{ i.procedure_notes}}
            </div>
          </div>
          <div
            class="d-flex mt-1"
            v-for="(i, index) in opd.treatment.med_supply"
            :key="'M'+index"
          >
            <div class="text-success flex-2 ml-1" v-if="i.med_supply_date_time.toDate ==='function'">
              {{ i.med_supply_date_time.toDate() | moment('DD-MM-YYYY HH:mm')}}
            </div>
            <div class="text-success flex-2 ml-1" v-else>
              {{ i.med_supply_date_time | moment('DD-MM-YYYY HH:mm')}}
            </div>
            <div class=" flex-1 ml-3">
              {{ i.med_supply_medicine }}
            </div>
            <div class="pl-3 flex-2" style="min-width: 320px;">
              {{ i.med_supply_amount}}
            </div>
          </div>
        </div>
        <div class="bg-nursing p-3 mt-3">
          <span class="label-b text-light"><i class="fa fa-user-md" aria-hidden="true"></i> Follow-Up & Recommendation </span>
        </div>
        <div class="bg-gray p-5">
          <div>
            <span class="label-b"><b>ADVICE</b></span>
            <div class="d-flex">
              <div class="pl-3" style="min-width: 320px;">
                <span class="detail-info">{{opd.follow_up.advice}}</span>
              </div>
              <div>
                <span class=" pl-5"></span>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <span class="label-b"><b>AGAINST MEDICAL ADVICE</b></span>
            <div class="d-flex" style="color: #ff6666">
              <div class="pl-3" style="min-width: 320px;">
                <span class="detail-info">{{opd.follow_up.against_medical_reason}} {{opd.follow_up.against_medical_detail}}</span>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <span class="label-b"><b>RECOMMENTDATION</b></span>
            <div class="d-flex">
              <div class="pl-3">
                <span class="detail-info"></span>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <span class="label-b"><b>FOLLOW-UP</b></span>
            <div class="d-flex">
              <div class="pl-3" style="min-width: 150px;">
                <span class="detail-info"><b>Date:</b> {{opd.follow_up.follow_up_date}}</span>
              </div>
              <div>
                <span class=" pl-5"></span>
              </div>
            </div>
            <div class="d-flex">
              <div class="pl-3" style="min-width: 150px;">
                <span class=""><b>Time: </b>{{opd.follow_up.follow_up_time}}</span>
              </div>
              <div>
                <span class="pl-5"></span>
              </div>
            </div>
            <div class="d-flex">
              <div class="pl-3" style="min-width: 150px;">
                <span class=""><b>Doctor:</b> {{opd.follow_up.follow_up_doctor}} ({{opd.follow_up.follow_up_department}})</span>
              </div>
              <div>
                <span class=" pl-5"></span>
              </div>
            </div>
            <div v-for="(item, key) in opd.follow_up.reason_list" :key="key">
              <div class="d-flex">
                <div class="pl-3" style="min-width: 150px;" >
                  <span class=""><b>Reason:</b> {{item.reason}}</span>
                </div>
              </div>
              <div class="d-flex">
                <div class="pl-3" style="min-width: 150px;">
                  <span class=""><b>F/U Plan:</b> {{item.plan}}</span>
                </div>
                <div>
                  <span class="pl-5"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <span class="label-b"><b>PLAN OF TREATMENT</b></span>
            <div class="d-flex">
              <div class="pl-3">
                <span class="detail-info">{{opd.follow_up.plan_of_treatment}}</span>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <span class="label-b"><b>COMMENT</b></span>
            <div class="d-flex">
              <div class="pl-3">
                <span class="detail-info">{{opd.follow_up.comment}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <hr />
        <div class="text-center">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            style="color: black; width: 190px;"
          >
            Confirm Order
          </button>
          <button
            style="margin-left: 10px; width: 160px;"
            type="button"
            class="btn btn-danger btn-lg"
          >
            Cancel
          </button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    opd: {
      default: null
    }
  },
  async mounted() {
    console.log({opd: this.opd})
  },
  data() {
    return {
      invest: [],
      laboratory: [],
      precedure_treatment: [],
      // date picker
      stdDateConfig: {
        altInput: true,
        altFormat: "d/m/Y"
      },
      stdDateTimeConfig: {
        altInput: true,
        altFormat: "d/m/Y H:i",
        enableTime: true,
        enableSeconds: false,
        time_24hr: true
      },
      stdTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      }
    };
  },
  methods: {
    statusCondition(status) {
      switch (status) {
        case 'pending':
          return 'status-pending'
        case 'completed':
          return 'status-completed'
        case 'against':
          return 'status-error'
        default:
          return 'status-default'
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.status-pending {
  color: white;
  padding: 5px;
  width: 140px;
  font-weight: 500;
  background-color: #E69138;
}
.status-completed {
  color: white;
  padding: 5px;
  width: 140px;
  font-weight: 500;
  background-color: #38761D;
}
.status-against {
  color: white;
  padding: 5px;
  width: 140px;
  font-weight: 500;
  background-color: #9A0000;
}
.status-default {
  color: white;
  padding: 5px;
  width: 140px;
  font-weight: 500;
  background-color: grey;
}
.center {
  text-align: center;
}
//margin padding

.label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.label-b {
  color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}

button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.status-box {
  border: 2px solid rgba(0, 0, 0, 0.3);
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
.status-green {
  background-color: #b6d7a8;
}
.status-gray {
  background-color: #d9d9d9;
}
.status-orange {
  background-color: #f9cb9c;
}
.dis-text {
  -webkit-text-decoration-line: line-through; /* Safari */
  text-decoration-line: line-through;
}
input[type="checkbox"] {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}
</style>
