import { render, staticRenderFns } from "./Account.vue?vue&type=template&id=f17fa326&scoped=true"
var script = {}
import style0 from "./Account.vue?vue&type=style&index=0&id=f17fa326&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f17fa326",
  null
  
)

export default component.exports