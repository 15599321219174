<template>
  <b-modal :id="id" title="Order IVF" size="xl" hide-footer>
    <div class="mb-2">
      <div class="container-fluid">
        <div v-for="(item, index) in value" :key="index">
          <div>
            <div class="row d-flex">
              <div class="col-3">
                <div class="label">Time</div>
                <MazPicker
                  v-model="item.date_time"
                  format="DD/MM/YYYY HH:mm"
                  formatted="DD/MM/YYYY HH:mm"
                />
              </div>
              <div class="col-3">
                <div class="label">Fluid Type/ Volume</div>
                <MazSelect
                  v-model="item.fluid_type"
                  search
                  :options="IVF.map(fluid => {
                    return { value: fluid, label: fluid }
                  })"
                />
                <input
                  type="text"
                  class="form-control"
                  placeholder="Other"
                  v-model="item.fluid_type_other"
                  v-if="item.fluid_type === 'Other'"
                />
              </div>
              <div class="col-2">
                <div class="label">IV Rate (ml/hr)</div>
                <MazInput
                  v-model="item.iv_rate"
                  placeholder="ml/hr"
                ></MazInput>
              </div>
              <div class="col-1">
                <div class="label">Amount</div>
                <MazSelect
                  v-model="item.amount"
                  search
                  :options="amounts.map(a => {
                    return { value: a, label: a }
                  })"
                />
                <input
                  type="text"
                  class="form-control"
                  placeholder="Other"
                  v-model="item.amount_other"
                  v-if="item.amount === 'Other'"
                />
              </div>
              <div class="col-2">
                <div class="label">Note</div>
                <MazInput
                  v-model="item.note"
                ></MazInput>
              </div>
              <div class="ml-auto col-1">
                <button class="btn btn-light mt-4" @click="value.splice(index, 1)"><i class="fas fa-trash"></i></button>
              </div>
            </div>
          </div>
        </div>
        <button class="btn btn-outline-success btn-block mt-2" @click="value.push({date_time: new Date()})">
          <i class="fas fa-plus"></i> Order
        </button>
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary mr-2" @click.prevent="confirmOrder">Confirm Order</button>
      <button class="btn btn-danger mr-2" @click.prevent="$bvModal.hide(id)">Cancel</button>
    </div>
  </b-modal>
</template>

<script>
import opdMixins from "../../../mixins/opd";
import IVF from "../../../assets/iv_fluid"
const amounts = [
  "others",
  "1 bottle",
  "2 bottle",
  "3 bottle",
  "4 bottle",
  "5 bottle",
  "6 bottle",
  "7 bottle",
  "8 bottle",
  "9 bottle",
  "10 bottle"
]
export default {
  mixins: [opdMixins],
  data() {
    return {
      id: 'order-ivf',
      rows: [{}],
      IVF,
      amounts
    }
  },
  props: {
    value: {
      default: () => {
        return [{date_time: new Date()}]
      }
    },
    onClose: {
      default: () => {}
    },
    onConfirm: {
      default: () => {}
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === this.id)
      this.onClose()
    })
  },
  methods: {
    confirmOrder() {
      this.$bvModal.hide(this.id)
      this.onConfirm()
    }
  }
}
</script>