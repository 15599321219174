
export default {
  data() {
    return {
      discharge_status: {
        time: null,
        bp_syst: null,
        bp_dias: null,
        pr: null,
        rr: null,
        temp: null,
        temp_unit: null,
        o2sat: null,
        pain_score: null,
        pain_location: null,
        vs_e: null,
        vs_v: null,
        vs_m: null,
        pupil_lt_value: null,
        pupil_lt: null,
        pupil_rt_value: null,
        pupil_rt: null,
        note: null,
        discharge_to: null,
        prior_to_discharge: null,
        prior_to_discharge_fullname: null,
        prior_to_discharge_select: null,
        is_instruction_doc: null,
        instruction_doc: null,
        instruction_doc_edu: null,
        primary_language: null,
        is_refer_to: null,
        refer_to_fullname: null,
        rn: null,
        doc_time: null
      }
    };
  }
};
