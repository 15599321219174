<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-6 col-sm-12">
        <table class="table table-bordered table-striped">
          <thead>
            <th colspan="4" class="text-center">Categories</th>
          </thead>
          <thead>
            <th class="text-left">Topic</th>
            <th class="text-right">Amount</th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedExpenses" :key="'expense-graph' + index">
              <td class="text-left">{{ item.topic }}</td>
              <td class="text-right">{{ item.amount.toLocaleString() }}</td>
            </tr>
          </tbody>
        </table>
        <table-pagination :items="groupTopics" :onChange="(items) => { paginatedExpenses = items}" :perPage="10"/>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="d-flex">
          <h5 class="text-primary">Expenses</h5>
          <button class="btn btn-primary ml-auto" @click="$bvModal.show('expense-graph-setting')"><i class="fas fa-cog"></i></button>
        </div>
        <Doughnut :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'"
          :chart-id="'insurance-graph'" :width="400" :height="400" />

        <modal-graph-color-setting 
          @change="saveColor"
          v-model="colorList" 
          :id="'expense-graph-setting'" 
          :legends="legendList"/>
      </div>
    </div>
  </div>
</template>

<script>
import ModalGraphColorSetting from '../../components/Modal/GraphColorSetting.vue'
import GraphColorSettingMixins from '../../mixins/graph-color-setting'
import {
  Doughnut
} from 'vue-chartjs/legacy'

export default {
  props: ['expenses'],
  mixins: [
    GraphColorSettingMixins
  ],
  components: {
    Doughnut,
    ModalGraphColorSetting
  },
  data() {
    return {
      GRAPH_ID: 'ExpenseGraph',
      chartOptions: {
        maintainAspectRatio: false,
        maxHeight: 400,
        plugins: {
          datalabels: {
            color: '#000',
            anchor: 'center',
            align: 'center',
            formatter: (value, context) => {
              const dataset = context.dataset;
              const total = dataset.data.reduce((sum, currentValue) => sum + currentValue, 0);
              if (value / total > 0.05) {
                const percentage = ((value / total) * 100).toFixed(2);
                return `${value.toLocaleString()}`;
              } else {
                return ''
              }
            }
          },
        },
      },
      paginatedExpenses: [],
    }
  },
  watch:{
    expenses: {
      handler() {
        this.setLegendList(this.groupTopics.map( item => item.topic))
        this.setColorList(this.generateBackgroundColor(this.groupTopics, 'topic'))
      },
      immediate: true
    },
    colorSetting: {
      handler() {
        this.setLegendList(this.groupTopics.map( item => item.topic))
        this.setColorList(this.generateBackgroundColor(this.groupTopics, 'topic'))
      },
      immediate: true
    },
  },
  computed: {
    groupTopics() {
      let data = []

      for (let item of this.expenses) {

        if (item.amount > 0) {
          let findIndex = this._.findIndex(data, { topic: item.topic })
          if (findIndex > -1) {
            data[findIndex].amount += item.amount
            data[findIndex].count++
          } else {
            data.push({
              topic: item.topic,
              amount: 0 + item.amount,
              count: 1,
              index: data.length
            })
          }
        }
      }

      return data
    },
    chartData() {
      let chartData = {
        labels: this.groupTopics.map(d => d.topic),
        datasets: [{
          label: 'Other',
          data: this.groupTopics.map(d => d.amount),
          backgroundColor: this.colorList
        }]
      }

      return chartData
    }
  }
}
</script>