<template>
  <div class="container-fluid">
    <div class="head">
      <h3 class="text-center text-success">PROGRESS NOTE LIST</h3>
      <h5 class="text-center text-success">30 Dec 10:00</h5>
    </div>

    <vue-good-table
      :columns="columns"
      :rows="rows"
      :group-options="{
        enabled: true
      }"/>

    <button class="btn btn-outline-success btn-block mt-2" @click.prevent="$bvModal.show('progress-note')">
      <i class="fas fa-plus"></i> Progress Notes
    </button>

    <b-modal id="progress-note" title="Progress Notes" size="lg" hide-footer>
      <div class="container">
          <div class="mb-3">
            <label class="form-label text-success">Subjective</label>
            <input type="text" class="form-control">
          </div>
          <div class="mb-3">
            <label class="form-label text-success">Objective</label>
            <input type="text" class="form-control">
          </div>
          <div class="mb-3">
            <label class="form-label text-success">Assessment</label>
            <input type="text" class="form-control">
          </div>
          <div class="mb-3">
            <label class="form-label text-success">Plan</label>
            <input type="text" class="form-control">
          </div>
          <div class="mb-3">
            <label class="form-label text-success">Attach File</label>
            <input type="file" class="form-control">
          </div>
          <hr>
          <button class="btn btn-primary btn-block">Save</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        { field: 'date', label: 'Date&Time'},
        { field: 'doctor', label: 'Doctor'},
        { field: 'note', label: 'Notes'},
        { field: 'attachments', label: 'Attachments'},
      ],
      rows: [
        {
          mode: 'span', // span means this header will span all columns
          label: '06 August 2021', // this is the label that'll be used for the header
          children: [
            { date: 'hh:mm', doctor: 'Dr. Aphilak K.', attachments: '', notes: '' },
            { date: 'hh:mm', doctor: 'Dr. Aphilak K.', attachments: '', notes: '' },
            { date: 'hh:mm', doctor: 'Dr. Aphilak K.', attachments: '', notes: '' },
          ]
        },
        {
          mode: 'span', // span means this header will span all columns
          label: '07 August 2021', // this is the label that'll be used for the header
          children: [
            { date: 'hh:mm', doctor: 'Dr. Aphilak K.', attachments: '', notes: '' },
            { date: 'hh:mm', doctor: 'Dr. Aphilak K.', attachments: '', notes: '' },
            { date: 'hh:mm', doctor: 'Dr. Aphilak K.', attachments: '', notes: '' },
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.head {
  background-color: #efefef;
  padding: 5px;
}
</style>