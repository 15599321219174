var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"sheet padding-10mm invoice-form"},[_c('div',{staticClass:"d-flex row mt-2 justify-content-between"},[_c('h3',{staticClass:"text-primary"},[_vm._v("Inventory Report")]),_c('span',[_vm._v(" "+_vm._s(_vm.$route.query.start)+" - "+_vm._s(_vm.$route.query.end))])]),_c('div',{staticClass:"row mt-4 d-flex flex-column"},[_c('h4',{staticClass:"text-primary"},[_vm._v("Summary Of Revenue")]),_c('vue-good-table',{attrs:{"columns":_vm.summaryColumns,"rows":[..._vm.serviceSummary, ..._vm.productSummary, ..._vm.totalSummary],"use-dynamic-row-height":true,"styleClass":"table condensed table-bordered vgt-table-report-print","sort-options":{
          enabled: false,
        },"group-options":{
          enabled: false,
        },"pagination-options":{
          dropdownAllowAll: false,
        }}})],1),_c('div',{staticClass:"row mt-4 d-flex flex-column"},[_c('h4',{staticClass:"text-primary"},[_vm._v("Service Revenue")]),_c('vue-good-table',{attrs:{"columns":_vm.serviceColumns,"rows":[..._vm.services, ..._vm.serviceSummary],"use-dynamic-row-height":true,"styleClass":"table condensed table-bordered vgt-table-report-print","sort-options":{
          enabled: false,
        },"group-options":{
          enabled: false,
        },"pagination-options":{
          dropdownAllowAll: false,
        }}})],1),_c('div',{staticClass:"row mt-4 d-flex flex-column"},[_c('h4',{staticClass:"text-primary"},[_vm._v("Product Revenue")]),_c('vue-good-table',{attrs:{"columns":_vm.serviceColumns,"rows":[..._vm.products, ..._vm.productSummary],"use-dynamic-row-height":true,"styleClass":"table condensed table-bordered vgt-table-report-print","sort-options":{
          enabled: false,
        },"group-options":{
          enabled: false,
        },"pagination-options":{
          dropdownAllowAll: false,
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }