<template>
  <div class="container-fluid">
    <div class="bg-doctor p-3 mt-2 d-flex">
      <div>
        <span class="label-b text-light">
          <i class="fas fa-syringe" aria-hidden="true"></i> Procedure (0)
          <a href title="Select Templade">
            <i
              class="fas fa-file-medical text-light ml-3"
              aria-hidden="true"
            ></i>
          </a>
        </span>
      </div>
      <div class="ml-auto">
        <button class="btn bg-white" @click="againstAllItem('procedure')">
          <span class="txt-warning-red">
            <i class="fa fa-times" aria-hidden="true"></i> Against All Advice
          </span>
        </button>
      </div>
    </div>
    <div class="bg-gray p-3">
      <div class="d-flex mt-2">
        <div class="flex-2 ml-5">Date & Time</div>
        <div class="flex-5">Procedure Name</div>
        <div class="flex-4">Dosage</div>
        <div class="flex-1"></div>
        <div class="flex-2 text-center"><b>Against Medical Advice</b></div>
      </div>
      <div
        class="d-flex mt-2"
        v-for="(i, index) in value.procedure"
        :key="index"
      >
        <div style="min-width: 35px;">
          <i
            v-if="value.procedure[index].against_reason"
            class="far fa-times-circle"
            style="font-size: 27px; color: red; margin-top: 10px;"
          ></i>
        </div>
        <div class="flex-2 ml-2">
          <MazPicker
            v-model="value.procedure[index].procedure_date_time"
            format="DD/MM/YYYY HH:mm"
            formatted="DD/MM/YYYY HH:mm"
            :disabled="checkDisabled('procedure', index)"
          />
        </div>
        <div class="flex-5 pl-1">
          <MazSelect
            v-model="value.procedure[index].procedure_name"
            search
            :options="
              $store.state.dd_procedure.map(item => {
                return {
                  label: item.name,
                  value: item.name
                };
              })
            "
            :disabled="checkDisabled('procedure', index)"
          />
          <input
            type="text"
            class="form-control"
            placeholder="Other"
            v-model="value.procedure[index].procedure_name_other_input"
            v-if="value.procedure[index].procedure_name === 'Other'"
          />
        </div>
        <div class="flex-4 pl-1">
          <MazInput
            v-model="value.procedure[index].procedure_notes"
            placeholder="Enter Dosage"
            :disabled="checkDisabled('procedure', index)"
          ></MazInput>
        </div>
        <div class="pl-1 flex-1">
          <button
            type="button"
            class="btn btn-outline-secondary ml-2 mt-1"
            title="Delete Advice"
            @click="removeChildRowList('procedure', index)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
        <div class="pl-1 flex-2" v-if="!value.procedure[index].against_reason">
          <input
            type="checkbox"
            class="form-control center-block"
            v-model="value.procedure[index].is_against"
          />
        </div>
        <div
          class="pl-1 pt-2 flex-2 text-center"
          v-if="value.procedure[index].against_reason"
        >
          {{ value.procedure[index].against_reason }}
        </div>
      </div>
      <div class="d-flex mt-2">
        <div class="ml-auto mr-4">
          <b-button
            variant="danger"
            v-b-modal.against-advice-treatment
            @click="passingModal('procedure')"
            >Against Advice</b-button
          >
        </div>
      </div>
      <div class="text-center">
        <button
          class="btn btn-primary m-2"
          @click="addChildRowList('procedure', 1)"
        >
          <i class="fa fa-plus"></i> ADD ORDER
        </button>
      </div>
    </div>
    <div class="bg-doctor p-3 mt-4 d-flex">
      <div>
        <span class="label-b text-light">
          <i class="fas fa-notes-medical" aria-hidden="true"></i> Medicines and
          Supplies (0)
          <a href title="Select Templade">
            <i
              class="fas fa-file-medical text-light ml-5"
              aria-hidden="true"
            ></i>
          </a>
        </span>
      </div>
      <div class="ml-auto">
        <button class="btn bg-white" @click="againstAllItem('med_supply')">
          <span class="txt-warning-red">
            <i class="fa fa-times" aria-hidden="true"></i> Against All Advice
          </span>
        </button>
      </div>
    </div>
    <div class="bg-gray p-3">
      <div class="d-flex mt-2">
        <div class="flex-2 ml-5">Date&Time</div>
        <div class="flex-5">Medicine/Supply</div>
        <div class="flex-4">Dosage</div>
        <div class="flex-1"></div>
        <div class="flex-2 text-center"><b>Against Medical Advice</b></div>
      </div>
      <div
        class="d-flex mt-2"
        v-for="(i, index) in value.med_supply"
        :key="`${i}_${index}`"
      >
        <div style="min-width: 35px;">
          <i
            v-if="value.med_supply[index].against_reason"
            class="far fa-times-circle"
            style="font-size: 27px; color: red; margin-top: 10px;"
          ></i>
        </div>
        <div class="flex-2 ml-2">
          <MazPicker
            v-model="value.med_supply[index].med_supply_date_time"
            format="DD/MM/YYYY HH:mm"
            formatted="DD/MM/YYYY HH:mm"
            :disabled="checkDisabled('med_supply', index)"
          />
        </div>
        <div class="flex-5 pl-1">
          <MazSelect
            v-model="value.med_supply[index].med_supply_medicine"
            search
            :options="
              $store.state.dd_medicine.map(item => {
                return {
                  label: item.brand_name,
                  value: item.brand_name
                };
              })
            "
            :disabled="checkDisabled('med_supply', index)"
          />
          <input
            type="text"
            class="form-control"
            placeholder="Other"
            v-model="value.med_supply[index].med_supply_other_input"
            v-if="value.med_supply[index].med_supply_medicine === 'Other'"
          />
        </div>
        <div class="flex-3 pl-1">
          <MazInput
            v-model="value.med_supply[index].med_supply_dosage"
            placeholder="Enter Dosage"
            :disabled="checkDisabled('med_supply', index)"
          ></MazInput>
        </div>
        <div class="pl-1 flex-1">
          <button
            type="button"
            class="btn btn-outline-secondary ml-2 mt-1"
            title="Delete Advice"
            @click="removeChildRowList('med_supply', index)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
        <div class="pl-1 flex-2" v-if="!value.med_supply[index].against_reason">
          <input
            type="checkbox"
            class="form-control center-block"
            v-model="value.med_supply[index].is_against"
          />
        </div>
        <div
          class="pl-1 pt-2 flex-2 text-center"
          v-if="value.med_supply[index].against_reason"
        >
          {{ value.med_supply[index].against_reason }}
        </div>
      </div>
      <div class="d-flex mt-2">
        <div class="ml-auto mr-4">
          <b-button
            variant="danger"
            v-b-modal.against-advice-treatment
            @click="passingModal('med_supply')"
            >Against Advice</b-button
          >
        </div>
      </div>
      <div class="text-center">
        <button
          class="btn btn-primary m-2"
          @click="addChildRowList('med_supply', 1)"
        >
          <i class="fa fa-plus"></i> ADD ORDER
        </button>
      </div>
    </div>
    <div class="d-flex mt-3">
      <div class="ml-auto">
        <label for>Total price</label>
        <input class="form-control" type="text" name id />
      </div>
    </div>
    <!-- modal -->
    <b-modal
      id="against-advice-treatment"
      title="Against Medical Advice"
      hide-footer
    >
      <div class="mt-2">
        <label>Enter Against Advice reason for selected item:</label>
        <select class="form-control" v-model="value.temp_against_reason">
          <option v-for="dd in dd_reason" :key="dd" :value="dd">{{
            dd
          }}</option>
        </select>
      </div>
      <button
        class="btn btn-block btn-primary mt-3"
        @click="confirmAgainstAdvice(value.modal_focus)"
      >
        Confirm
      </button>
    </b-modal>
  </div>
</template>

<script>
import opdMixins from "../../mixins/opd";
export default {
  mixins: [opdMixins],
  props: ["value"]
};
</script>

<style lang="scss" scoped>
.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}
.bg-hblue {
  background-color: rgb(17, 85, 204);
}
.bg-horange {
  background-color: rgb(230, 145, 56);
}

.label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.label-b {
  color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}
.label-h {
  // color: rgb(6, 132, 216);
  padding-top: 10px;
  font-size: 20px;
}

.form-check {
  margin-left: 10px;
}
button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

input[type="checkbox"] {
  -webkit-appearance: initial;
  appearance: initial;
  background: gray;
  width: 45px;
  height: 40px;
  border: none;
  position: relative;
}
input[type="checkbox"]:checked {
  background: red;
}
input[type="checkbox"]:checked:after {
  /* Heres your symbol replacement */
  content: "X";
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.center-block {
  margin: auto;
  display: block;
}
.form-control {
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
  height: 2.855rem;
  min-height: 2.855rem;
  margin-top: 0.032rem;
  border-color: #eee;
}
</style>
