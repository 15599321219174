const COLLECTION_NAME = 'GraphColorSetting'
import {db} from '../db'
import color from '../assets/color.json'
export default {
  data() {
    return {
      colorSetting: {},
      GRAPH_ID: 'test',
      colorList: [],
      legendList: []
    }
  },
  firestore() {
    return {
      colorSetting: db.collection(COLLECTION_NAME).doc(this.GRAPH_ID)
    }
  },
  methods:{
    async saveColor({ color, legend }) {
      let loader = this.$loading.show({})
      let data = {}
      data[legend] = color
      await db.collection(COLLECTION_NAME).doc(this.GRAPH_ID).set(data, { merge: true })

      loader.hide()
    },
    setColorList(list) {
      this.colorList = this._.clone(list)
    },
    setLegendList(list) {
      this.legendList = this._.clone(list)
    },
    generateBackgroundColor(list, keyName) {
      let items = []
      let setting = this.colorSetting
      list.forEach((item, index)=> {
        if (setting && setting[item[keyName]]) {
          items.push(setting[item[keyName]])
        } else {
          items.push(color[(index + 2) % 7])
        }
      })
      return items
    }
  },
}