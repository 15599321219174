import Setting from '../pages/Setting/index.vue'
import Branch from '../pages/Setting/Branch.vue'
import Role from '../pages/Setting/Role.vue'
import Zone from '../pages/Setting/Zone.vue'
import Hotel from '../pages/Setting/Hotel.vue'
import Insurance from '../pages/Setting/Insurance.vue'
import AssistInsurance from '../pages/Setting/AssistInsurance.vue'
export default [
  {
    path: "/setting",
    name: "Setting",
    component: Setting,
    redirect: { name: "Setting Branch" },
    children: [
      // {
      //   path: "",
      //   name: "Setting Role",
      //   component: Role
      // },
      {
        path: "/branch",
        name: "Setting Branch",
        component: Branch
      },
      {
        path: "/setting-insurance",
        name: "Setting Insurance",
        component: Insurance
      },
      {
        path: "/setting-assistinsurance",
        name: "Setting Assist Insurance",
        component: AssistInsurance
      },
      {
        path: "/zone",
        name: "Setting Zone",
        component: Zone
      },
      {
        path: "/hotel-list",
        name: "Setting Hotel",
        component: Hotel
      },
    ]
  }
];
