<template>
  <div class="container-fluid">
    <div class="d-flex mb-2">
      <div>
        <label>Select Date</label>
        <flat-pickr
          class="form-control"
          :config="{
            altFormat: 'd m Y',
            dateFormat: 'd/m/Y'
          }"
          placeholder="Select date"
          v-model="selectedDate"
          @input="test"
        ></flat-pickr>
      </div>
    </div>
    <vue-good-table
      :columns="columns"
      :rows="appointments"
      :pagination-options="{
        enabled: true
      }"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.label == 'No.'">
          {{ props.row.originalIndex + 1 }}
        </div>
        <div v-else-if="props.column.label === 'Time'">
          {{props.row.follow_up.follow_up_time}}
        </div>
        <div v-else-if="props.column.label == 'Patient Details'">
          <div class="text-dark">
            <b>{{ props.row.name }}</b>
          </div>
          <div class="text-muted">
            <router-link target="_blank" :to="`/patient/${props.row.HN}`"><i>HN: {{ props.row.HN }}</i></router-link>
          </div>
          <div v-if="props.row.phone_th">
            <a :href="`tel:${props.row.phone_th}`"
              ><i class="fas fa-phone"></i>
              {{ props.row.phone_th }}</a
            >
          </div>
          <div v-if="props.row.phone_inter">
            <a :href="`tel:${props.row.phone_inter}`"
              ><i class="fas fa-phone"></i>
              {{ props.row.phone_inter }}</a
            >
          </div>
          <div v-if="props.row.email">
            <a :href="`mailto:${props.row.email}`"
              ><i class="fas fa-envelope"></i>
              {{ props.row.email }}</a
            >
          </div>
        </div>
        <div v-else-if="props.column.label ==='Symptoms/Diagnosis'">
          {{props.row.cc}} {{props.row.dx}}
        </div>
        <div v-else-if="props.column.label ==='Visit Type'">
          {{props.row.follow_up.visit_type}}
        </div>
        <div v-else-if="props.column.label ==='DR'">
          {{props.row.follow_up.follow_up_doctor}}
        </div>
        <div v-else-if="props.column.field == 'action'">
          <button class="btn btn-block btn-warning">change</button>
          <button class="btn btn-block btn-light mt-2">cancel</button>
        </div>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  props: ['visits'],
  data() {
    return {
      columns: [
        {
          label: "No.",
          field: "id"
        },
        {
          label: "Time",
          field: "time"
        },
        {
          label: "Patient Details",
          field: "details"
        },
        {
          label: "Symptoms/Diagnosis",
          field: "symtoms"
        },
        {
          label: "Visit Type",
          field: "visit_type"
        },
        {
          label: "Appointment Status",
          field: "appointment_status"
        },
        {
          label: "Plan",
          field: "plan"
        },
        {
          label: "Finance",
          field: "finance"
        },
        {
          label: "DR",
          field: "doctor"
        },
        {
          label: "Note",
          field: "appointment_note"
        },
        {
          label: "Actions",
          field: "action"
        }
      ],
      patients: [],
      selectedDate: this.$moment().format('DD/MM/YYYY'),
    };
  },
  methods: {
    onRowClick(params) {
      this.$router.push(`/patient/${params.row.hn}`);
    },
    test() {
      console.log(this.selectedDate)
    }
  },
  computed: {
    appointments() {
      let appointments = []
      for(let visit of this.visits) {
        if(visit.follow_up && (visit.follow_up.follow_up_date===this.selectedDate)) {
          visit.follow_up.visit_type='follow up'
          appointments.push(visit)
        }
      }
      console.log({appointments})
      return appointments
    },
    
  }
};
</script>

<style></style>
