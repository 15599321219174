<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <div class="p-3">
          <general-header />
          <hr />
          <div>
            <span class="label-b">List of all Other Patient Documents</span>
            <button
              class="btn btn-outline-secondary mb-2"
              style="float: right;"
              v-if="hideVN"
              @click.prevent="hideVN = false"
            >
              <span>Show VN/AN</span>
            </button>
            <button
              class="btn btn-outline-secondary mb-2"
              style="float: right;"
              v-if="!hideVN"
              @click.prevent="hideVN = true"
            >
              <span>Hide VN/AN</span>
            </button>
          </div>
          <div>
            <table class="table table-bordered text-center">
              <thead class="bg-gray">
                <tr>
                  <th v-if="!hideVN">VN/AN</th>
                  <th>Uploaded Date</th>
                  <th>Document Type</th>
                  <th>Document Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-if="!hideVN"></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <span class="label-b"
              ><i class="fas fa-cloud-upload-alt"></i> Upload Files</span
            >
          </div>
          <div class="d-flex mt-2">
            <div class="mw-3">
              <select class="form-control">
                <option value disabled selected hidden></option>
              </select>
            </div>
            <div class="pl-2 mw-3">
              <input type="text" class="form-control" />
            </div>
            <div class="pl-2">
              <button class="btn btn-outline-secondary" placeholder="File Name">
                Upload
              </button>
            </div>
            <div class="pl-2">
              <button class="btn btn-outline-secondary">
                <i class="fas fa-camera"></i>
              </button>
            </div>
            <div class="pl-2">
              <button class="btn btn-outline-primary">
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hideVN: true
    };
  }
};
</script>

<style lang="scss" scoped>
.label-b {
  color: rgb(6, 132, 216);
  font-size: 20px;
}
</style>
