<template>
  <div>
    <div class="d-flex">
      <div class="stamp-box flex-3">
        <h4 class="text-center" style="margin-top: 270px;">STAMP</h4>
      </div>
      <div class="detail-box text-center flex-5 bg-gray">
        <div class="d-flex justify-content-center" style="margin-top: 100px;">
          <div>
            <p>Attending Physician</p>
          </div>
          <div class="ml-2" style="width: 270px;">
            <p class="ul-solid"></p>
            <span class="text-muted mr-3">{{form.name_th}}</span>
            <div class="d-flex text-muted">
              <div class="ml-4 text-left">
                <span>Dr.</span>
              </div>
              <div class="ml-5">
                <span>{{form.name_en}}</span>
              </div>
            </div>
            <div class="d-flex text-muted">
              <div class="ml-4 text-left">
                <span>Medical Lic. No.</span>
              </div>
              <div class="ml-5">
                <span>{{form.license_no}}</span>
              </div>
            </div>
            <div class="d-flex text-muted">
              <div class="ml-4 text-left">
                <span>Date:</span>
              </div>
              <div class="ml-5">
                <span>{{ today | moment('DD/MM/YYYY')}}</span>
              </div>
            </div>
          </div>
          <div class="ml-2">
            <p class="text-muted">MD</p>
          </div>
        </div>
      </div>
    </div>
    <div class="wmc-addr-box bg-gray">
      <div>
        <p>
          <b>Hospital</b> 125/ 131-133 M.7, Phiphi Island, T. Aonang, MuangKrabi, Krabi, Thailand, 81210
        </p>
        <p>
          <b>Tel. No.</b> +66- 95-424-0444
        </p>
        <p>
          <b>E-mail:</b> insurance@worldmedcenter.com
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      default: () => {
        return {}
      }
    }
  },
  date() {
    return {
      today: new Date()
    }
  }
}
</script>

<style lang="scss" scoped>
.ul-solid {
  border-bottom: 2px solid;
  padding-left: 20px;
  padding-right: 20px;
  height: 25px;
  border-color: rgba(0, 0, 0, 0.3);
}

.stamp-box {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
}
.wmc-addr-box {
  // border-top: 1px solid #aaaaaa;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 6px;
  color: #2366d8;
}
.detail-box {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
