<template>
  <div class="container-fluid">
    <div class="head mb-2">
      <h3 class="text-center text-success">PHYSICIAN'S ORDER SHEET</h3>
      <h5 class="text-center text-success">30 Dec 2020 10:00</h5>
    </div>
    

    <div class="row">
      <div class="col-3">
        <button class="btn btn-outline-success">
          <i class="fas fa-plus"></i> Active Problem List
        </button>

        <table class="table-bordered mt-2 bg-blue">
          <tbody>
            <tr>
              <td class="p-2">03 Feb 2020</td>
              <td class="p-2">Hypokalemia</td>
            </tr>
            <tr>
              <td class="p-2">04 Feb 2020</td>
              <td class="p-2">Diarrhea</td>
            </tr>
            <tr>
              <td class="p-2">05 Feb 2020</td>
              <td class="p-2">-</td>
            </tr>
            <tr>
              <td class="p-2">06 Feb 2020</td>
              <td class="p-2">-</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        <div class="main-menu">
          <div class=" shadow text-success d-flex flex-wrap">
            <div class="menu-item mr-1" v-for="(menu, index) in menus" :key="index" @click.prevent="menu.action" >
              <div class="menu-icon" :class="{'text-white': menu.name === currentMenu, 'bg-success': menu.name === currentMenu}">
                <i :class="menu.icon"></i>
              </div>
              <div class="mt-1">{{menu.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex mt-2">
      <h3 class="mr-auto">
        <i class="fas fa-filter"></i> Admission Day
      </h3>
      <div class="text-success">
        Show / Hide Actions
      </div>
    </div>

    <vue-good-table
      :columns="columns"
      :rows="rows"/>

    <order-investigation :onClose="onClosed"/>
    <order-procedure :onClose="onClosed"/>
    <order-medicine :onClose="onClosed"/>
    <order-special :onClose="onClosed"/>
    <order-dhf :onClose="onClosed"/>
    <order-lab :onClose="onClosed"/>
    <order-ivf :onClose="onClosed"/>
    <order-supplies :onClose="onClosed"/>
    <order-refer :onClose="onClosed"/>
    <order-against-medical-advice :onClose="onClosed"/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        { name: 'Investigation', icon: 'fas fa-x-ray', action: () => { this.$bvModal.show('order-investigation'); this.currentMenu = 'Investigation'; }},
        { name: 'Procedure', icon: 'fas fa-stethoscope', action: () => { this.$bvModal.show('order-procedure'); this.currentMenu = 'Procedure'; } },
        { name: 'Medicine', icon: 'fas fa-capsules', action: () => { this.$bvModal.show('order-medicine'); this.currentMenu = 'Medicine';} },
        { name: 'Special Orders', icon: 'fas fa-paste', action: () => { this.$bvModal.show('order-special'); this.currentMenu = 'Special Orders';} },
        { name: 'Discharge,Home Med,Follow Up', icon: 'fas fa-book-medical', action: () => { this.$bvModal.show('order-dhf'); this.currentMenu = 'Discharge,Home Med,Follow Up';} },
        { name: 'Laboratory', icon: 'fas fa-microscope', action: () => { this.$bvModal.show('order-laboratory'); this.currentMenu = 'Laboratory';} },
        { name: 'IVF', icon: 'fas fa-syringe', action: () => { this.$bvModal.show('order-ivf'); this.currentMenu = 'IVF';} },
        { name: 'Supplies', icon: 'fas fa-medkit', action: () => { this.$bvModal.show('order-supplies'); this.currentMenu = 'Supplies';} },
        { name: 'Refer', icon: 'fas fa-ambulance', action: () => { this.$bvModal.show('order-refer'); this.currentMenu = 'Refer';} },
        { name: 'Against Medical Advice', icon: 'fas fa-user-md', action: () => { this.$bvModal.show('order-against'); this.currentMenu = 'Against Medical Advice';} },
      ],
      columns: [
        { label: 'Progress Note', field: 'progres_note'},
        { label: 'One Day', field: 'one_day'},
        { label: 'Continuous', field: 'continuous'},
      ],
      rows: [],
      currentMenu: null
    }
  },
  methods: {
    onClosed() {
      this.currentMenu = null
    }
  }
}
</script>

<style lang="scss" scoped>
.head {
  background-color: #efefef;
  padding: 5px;
}

.main-menu {
  background-color: #efefef;
  padding: 5px;
  border-radius: 0.25em;
  min-height: 100px;
}

.menu-item {
  width: 120px;
  height: 160px;
  text-align: center;
  cursor: pointer;
  padding: 5px;
}

.menu-item-active {
  color: white;
  background-color: greenyellow;
}

.menu-icon {
  font-size: 48px;
  padding: 5px;
  border-radius: 0.25em;
  background-color: white;
}

.bg-blue {
  background-color: skyblue;
}
</style>