<template>
  <div>    
    <div class="d-flex  mt-2 mb-2">
      <h3 class="text-primary mr-auto">Expense</h3>
      <button class="btn btn-primary" @click="openModal()"><i class="fas fa-plus"></i> Add Expense</button>
    </div>
    <vue-good-table
      :columns="columns"
      :rows="filteredItems"
      :sort-options="{
        enabled: false,
      }"
      @on-row-click="openModal"
      :pagination-options="{
        enabled: !this.$route.meta.print
      }">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.label == 'Date'">
          {{ props.formattedRow[props.column.field].toDate() | moment('DD/MM/YYYY HH:mm') }}
        </div>
        <div v-else-if="props.column.label == 'Amount'">
          {{ props.formattedRow[props.column.field].toLocaleString() }}
        </div>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <b-modal id="expense-modal" :title="expense.topic? expense.topic :'new expense'" hide-footer>
      <div class="row mt-2">
        <div class="col-3">
          <label>Catagory</label>
        </div>

        <div class="col-9">
          <addable-select 
            v-model="expense.topic"
            :model="'ExpenseCatagory'"
            :placeholder="`Select Catagory`"
            :options="catagories.map(c => c.name)"/>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-3">Description</label>
        <div class="col-9">
          <input ref="description-input" type="text" class="form-control" v-model="expense.description">
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-3">Amount</label>
        <div class="col-9">
          <input-number v-model="expense.amount"></input-number>
        </div>
      </div>
      <div class="d-flex mt-2">
        <button class="btn btn-primary btn-block" @click="saveExpense"><i class="fas fa-check"></i> Confirm</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {db,Timestamp} from '../../db'
export default {
props: ['filter'],
  firestore() {
    return {
      expenses: db.collection('Expense').orderBy("created_at", "desc"),
      catagories: db.collection('ExpenseCatagory')
    }
  },
  data() {
    return {
      columns: [
        {
          label: 'Date',
          field: 'created_at',
        },
        {
          label: 'Topic',
          field: 'topic',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Amount',
          field: 'amount',
        },
      ],
      expenses: [],
      expense: {},
      new_catagory: null
    }
  },
  methods: {
    openModal(row) {
      if(row){
        this.expense = {
          ...row.row
        }
      }else{
        this.expense = {}
      }
      this.$bvModal.show('expense-modal')
    },
    async saveExpense() {
      if(!this.expense.topic) {
        this.$alert('please select topic', null, 'error')
        return
      }
      if(!this.expense.description) {
        this.$alert('please input description', null, 'error')
        return
      }
      if(!this.expense.amount) {
        this.$alert('please input amount', null, 'error')
        return
      }
      let loader = this.$loading.show({})
      if(!this.expense.id) {
        this.expense.id = db.collection('Expense').doc().id
        this.expense.created_at = Timestamp.fromDate(new Date())
      }
      let expense = {
        id: this.expense.id,
        topic: this.expense.topic,
        description: this.expense.description,
        amount: Number(this.expense.amount),
        created_at: this.expense.created_at,
        updated_at: Timestamp.fromDate(new Date()),
      }
      console.log({expense})
      await db.collection('Expense').doc(expense.id).set(expense)
      loader.hide()
      await this.$alert('Success',null,'success')
      this.$bvModal.hide('expense-modal')
      this.expense = {}
    },
    async addCatagory(name){
      if(name) {
        await db.collection('ExpenseCatagory').doc().set({
          name
        })
        this.expense.topic = name
        this.new_catagory = null
        this.$refs['description-input'].focus()
      }
    },
  },
  computed: {
    filteredItems() {
      let from = this.$moment(this.filter.from, 'DD/MM/YYYY')
      let to = this.$moment(this.filter.to, 'DD/MM/YYYY')

      return this._.filter(this.expenses, item => {
        if (item.issue_date) {
          return this.$moment(item.issue_date.toDate()).isAfter(from.startOf('day')) && this.$moment(item.issue_date.toDate()).isBefore(to.endOf('day'))
        } else {
          return this.$moment(item.created_at.toDate()).isAfter(from.startOf('day')) && this.$moment(item.created_at.toDate()).isBefore(to.endOf('day'))
        }
      })
    }
  }
}
</script>