var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('general-header'),_c('hr'),_c('b-nav',{staticClass:"custom-sub-tab",attrs:{"tabs":"","justified":""}},[_c('b-nav-item',{attrs:{"active":this.$route.name === 'List of all tests',"to":{
        name: 'List of all tests',
        params: { id: this.$route.params.id }
      }}},[_vm._v("List of all tests")]),_c('b-nav-item',{attrs:{"active":this.$route.name === 'Laboratory',"to":{ name: 'Laboratory', params: { id: this.$route.params.id } }}},[_vm._v("Laboratory")]),_c('b-nav-item',{attrs:{"active":this.$route.name === 'Radiology and Imaging',"to":{
        name: 'Radiology and Imaging',
        params: { id: this.$route.params.id }
      }}},[_vm._v("Radiology & Imaging")]),_c('b-nav-item',{attrs:{"active":this.$route.name === 'Invest Others',"to":{ name: 'Invest Others', params: { id: this.$route.params.id } }}},[_vm._v("Others")])],1),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('router-view',{staticClass:"mt-2"})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }