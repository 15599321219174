<template>
  <div>
    <b-button variant="primary" v-b-modal.new-admission
      ><i class="fas fa-plus"></i> Create New Admission</b-button
    >
    <b-modal id="new-admission" title="Create New Admission" hide-footer>
      <div class="mt-2">
        <label>Select Branch</label>
        <select class="form-control" v-model="selectedBranch">
          <option value="">--- select branch ---</option>
          <option
            v-for="b in $store.getters.branches"
            :key="b.code"
            :value="b.code"
            >{{ b.name_en }}</option
          >
        </select>
      </div>
      <div class="mt-2">
        <label>Select Department</label>
        <select class="form-control" v-model="selectedDept">
          <option value="">--- select department ---</option>
          <option
            v-for="d in $store.getters.departments"
            :key="d.code"
            :value="d.code"
            >{{ d.name_en }}</option
          >
        </select>
      </div>
      <button
        :disabled="!selectedBranch || !selectedDept"
        class="btn btn-block btn-primary mt-2"
        @click="newAdmission"
      >
        <i class="fas fa-check"></i> Create New Admission
      </button>
    </b-modal>
  </div>
</template>

<script>
import { db } from "../db";
export default {
  data() {
    return {
      selectedDept: "",
      selectedBranch: ""
    };
  },
  methods: {
    async newAdmission() {},
    async generateAN(branch, department) {
      let countAdmission = await db
        .collection("Admission")
        .where(
          "admission_date",
          ">",
          this.$moment()
            .startOf("day")
            .toDate()
        )
        .where(
          "admission_date",
          "<",
          this.$moment()
            .endOf("day")
            .toDate()
        )
        .get();

      let paddedCountAdmission = countAdmission.docs.length
        .toString()
        .padStart("3", "0");
      return `01-${branch}-${department}-${paddedCountAdmission}-${this.$moment().format(
        "DDMMYYYY"
      )}`;
    }
  }
};
</script>
