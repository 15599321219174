<template>
    <div>
        <section class="sheet padding-10mm invoice-form">
            <div class="d-flex row mt-2 ">
                <h3 class="text-primary">Transfer Report</h3>
                <div class="ml-auto">
                    {{ this.$route.query.from === 'null' ? '' : (this.$route.query.from === '0' ? 'Main Stock' :
                        $store.state.branches[this.$route.query.from - 1]?.name_en) + ' ->' }}
                    {{ this.$route.query.to === 'null' ? '' : (this.$route.query.to === '0' ? 'Main Stock' :
                        $store.state.branches[this.$route.query.to - 1]?.name_en) }}
                </div>
                
            </div>
            <div class="d-flex">
                <div class="ml-auto"> {{ $route.query.start }} - {{ $route.query.end }} </div>
            </div>
            <div class="row mt-4 d-flex flex-column">
                <vue-good-table :columns="transferColumns" :rows="transfers" :use-dynamic-row-height="true"
                    :sort-options="{
                        enabled: false,
                    }" :group-options="{
                        enabled: false,
                    }" :pagination-options="{
                        dropdownAllowAll: false,
                    }">
                    <template slot="table-row" slot-scope="props">

                        <div v-if="props.column.field == 'item_name'" style="display: block;">
                            <span style="display: block;">{{ props.row.item_name }}</span>
                            <span style="font-size: smaller; text-decoration: underline; display: block;">
                                {{ props.row.item_other_name }}
                            </span>
                        </div>
                        <div v-else-if="props.column.field === 'expire_date'">
                            {{ formatDate(props.row.expire_date) }}
                        </div>

                    </template>
                </vue-good-table>
            </div>
        </section>
    </div>
</template>
<script>
import { db } from "../../db"
import moment from "moment"
export default {
    data() {
        return {
            transfers: [],
            transferColumns: [
                {
                    label: "Item code",
                    field: "item_code",
                },
                {
                    label: "Item name",
                    field: "item_name",
                },
                {
                    label: "Transfer",
                    field: "transfer",
                    formatFn: (value) => value.toLocaleString(),
                    thClass: 'text-right',
                    tdClass: 'text-right',
                },
                {
                    label: "Expire date",
                    field: "expire_date"
                },


            ],
            medicines: [],

        }
    },
    firestore() {
        return {
            medicines: db.collection("Product").orderBy('item_code', 'asc'),
        }
    },
    async mounted() {
        document.body.className = 'A4'
        this.fetchTransfers(
            this.$moment(this.$route.query.start, "DD/MM/YYYY").startOf("day").format("DD/MM/YYYY HH:mm"),
            this.$moment(this.$route.query.end, "DD/MM/YYYY").endOf("day").format("DD/MM/YYYY HH:mm"),
        )

    },
    methods: {
        formatDate(dateString) {
            dateString = dateString.replaceAll('-','/').slice(0,10)
            const myArray = dateString.split('/')
            dateString = myArray[2] + '/' + myArray[1] + '/' + myArray[0]            
            return dateString
        },

        async fetchTransfers(start, end) {

            let products = []
            let transferRef = []
            if (this.$route.query.from === 'null' && this.$route.query.to === 'null') {
                transferRef = await db
                    .collection("Transfer")
                    .where("issue_date", ">=", start)
                    .where("issue_date", "<", end)
                    .where("frombranch", "==", null)
                    .where("tobranch", "==", null)
                    .get()
            }
            else {
                transferRef = await db
                    .collection("Transfer")
                    .where("issue_date", ">=", start)
                    .where("issue_date", "<", end)
                    .where("frombranch", "==", Number(this.$route.query.from))
                    .where("tobranch", "==", Number(this.$route.query.to))
                    .get()
                
            }            
            transferRef.docs.forEach((transfer) => {
                const data = transfer.data()
                products = products.concat(this.getName(data.details))
            })

            let transferRef2 = []
            if (this.$route.query.from !== null && this.$route.query.to !== null) {
                transferRef2 = await db
                    .collection("Transfer")
                    .where("issue_date", ">=", start)
                    .where("issue_date", "<", end)
                    .where("frombranch", "==", Number(this.$route.query.to))
                    .where("tobranch", "==", Number(this.$route.query.from))
                    .get()

                transferRef2.docs.forEach((transfer) => {
                    const data = transfer.data()

                    products = products.concat(this.getName2(data.details))
                })
            }

            const formattedProducts = _.chain(products)
                .groupBy(product => `${product.item_code}-${product.expire_date}`)
                .map((groupedProducts, key) => {
                    const firstProduct = groupedProducts[0]
                    return {
                        item_code: firstProduct.item_code,
                        item_name: firstProduct.item_name,
                        item_other_name: firstProduct.item_other_name,
                        transfer: _.sumBy(groupedProducts, 'transfer'),
                        expire_date: firstProduct.expire_date
                    }
                })
            this.transfers = formattedProducts.value()

            this.transfers.sort((a, b) => {
                if (a.item_code < b.item_code) return -1
                if (a.item_code > b.item_code) return 1
            })


        },
        getName(details) {
            if (!details || !details.length) return []
            return details.map((item) => {
                return {
                    item_code: item.item_code,
                    item_name: item.item_name,
                    item_other_name: item.item_other_name,
                    transfer: item.quantity,
                    expire_date: item.expire_date,
                }
            })
        },
        getName2(details) {
            if (!details || !details.length) return []
            return details.map((item) => {
                return {
                    item_code: item.item_code,
                    item_name: item.item_name,
                    item_other_name: item.item_other_name,
                    transfer: -item.quantity,
                    expire_date: item.expire_date,
                }
            })
        },


    },
    computed: {

    },
}
</script>
<style lang="scss">
@page {
    size: portrait;
}

.sheet {
    overflow: visible;
    height: auto !important;
}
</style>