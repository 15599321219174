var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.patients,"pagination-options":{
      enabled: true
    }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label =='Daily Meds Summary')?_c('span',[_c('div',{staticClass:"text-center"},[_vm._v("Daily Meds")]),_c('div',{staticClass:"text-center"},[_vm._v("Summary")])]):(props.column.label =='V/S I/O Diet Endorsement')?_c('span',[_c('div',{staticClass:"text-center"},[_vm._v("V/S")]),_c('div',{staticClass:"text-center"},[_vm._v("I/O")]),_c('div',{staticClass:"text-center"},[_vm._v("Diet")]),_c('div',{staticClass:"text-center mt-2"},[_vm._v("Endorsement")])]):(props.column.label =='Admission Date/Time Discharge Plan')?_c('span',[_c('div',{staticClass:"text-center"},[_vm._v("Admission")]),_c('div',{staticClass:"text-center"},[_vm._v("Date/Time")]),_c('div',{staticClass:"text-center mt-4"},[_vm._v("Discharge Plan")])]):(props.column.label =='Finance Passport Travel Plan')?_c('span',[_c('div',{staticClass:"text-center"},[_vm._v("Finance")]),_c('div',{staticClass:"text-center"},[_vm._v("Passport")]),_c('div',{staticClass:"text-center mt-4"},[_vm._v("Travel Plan")])]):_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field == 'details')?_c('div',[_c('div',{staticClass:"text-dark"},[_c('b',[_vm._v(_vm._s(props.formattedRow.details.name))])]),_c('div',{staticClass:"text-dark"},[_c('b',[_vm._v(_vm._s(props.formattedRow.details.age))])]),_c('div',{staticClass:"esi-box",style:({
            color: props.formattedRow.details.esi == 5 ? 'dark' : 'white',
            'background-color': _vm.getEsiColor(props.formattedRow.details.esi)
          })},[_vm._v(" ESI level "+_vm._s(props.formattedRow.details.esi)+" ")]),_c('div',{staticClass:"text-muted"},[_c('i',[_vm._v("AN: "+_vm._s(props.formattedRow.details.AN))])])]):(props.column.field == 'allergies')?_c('div',_vm._l((props.formattedRow.allergies),function(item){return _c('div',{key:item,staticClass:"text-danger"},[_c('b',[_vm._v(_vm._s(item))])])}),0):(props.column.field == 'actions')?_c('div',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.new-admission",modifiers:{"new-admission":true}}],staticClass:"btn btn-block btn-primary mt-2 shadow"},[_vm._v("Register Admission")]),_c('button',{staticClass:"btn btn-block btn-light mt-2 shadow"},[_vm._v("Cancel")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('b-modal',{attrs:{"id":"new-admission","size":"lg","hide-footer":""}},[_c('h3',{staticClass:"text-center text-success"},[_vm._v("Admission Registration Form")]),_c('h5',{staticClass:"text-center text-success"},[_vm._v(_vm._s(_vm._f("moment")(Date(),'DD MMMM YYYY HH:mm')))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('MazSelect',{attrs:{"placeholder":"Branch","options":[
              { label: 'Beach Front (Hospital)', value: '001' },
              { label: 'Loh Dalum (Clinic)', value: '002' },
            ]},model:{value:(_vm.admission.branch),callback:function ($$v) {_vm.$set(_vm.admission, "branch", $$v)},expression:"admission.branch"}})],1),_c('div',{staticClass:"col-4"},[_c('MazSelect',{staticClass:"mb-2",attrs:{"placeholder":"Visit Type","options":[
              { label: 'Others', value: '000' },
              { label: 'OPD', value: '001' },
              { label: 'ER', value: '002' },
              { label: 'Scheduled', value: '003' },
              { label: 'Hotel Call', value: '004' },
            ]},model:{value:(_vm.admission.type),callback:function ($$v) {_vm.$set(_vm.admission, "type", $$v)},expression:"admission.type"}})],1),_c('div',{staticClass:"col-4"},[_c('MazPicker',{staticClass:"mb-2",attrs:{"placeholder":"Admission Date/Time"},on:{"formatted":function($event){_vm.pickerFormatted = $event}},model:{value:(_vm.admission.date),callback:function ($$v) {_vm.$set(_vm.admission, "date", $$v)},expression:"admission.date"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('MazSelect',{staticClass:"mb-2",attrs:{"placeholder":"Admit From","options":[
            { label: 'OPD', value: 'OPD' },
            { label: 'ER', value: 'ER' },
          ]},model:{value:(_vm.admission.from),callback:function ($$v) {_vm.$set(_vm.admission, "from", $$v)},expression:"admission.from"}}),_c('MazPicker',{staticClass:"mb-2",attrs:{"placeholder":"Time Received from ER/OPD"},on:{"formatted":function($event){_vm.pickerFormatted = $event}},model:{value:(_vm.admission.received_time),callback:function ($$v) {_vm.$set(_vm.admission, "received_time", $$v)},expression:"admission.received_time"}}),_c('MazSelect',{staticClass:"mb-2",attrs:{"placeholder":"Transfer type (to room)","options":[
            { label: 'Others', value: 'Others' },
            { label: 'Ambulatory', value: 'Ambulatory' },
            { label: 'Wheelchair', value: 'Wheelchair' },
            { label: 'Stretcher', value: 'Stretcher' },
            { label: 'Crutches', value: 'Crutches' },
          ]},model:{value:(_vm.admission.from),callback:function ($$v) {_vm.$set(_vm.admission, "from", $$v)},expression:"admission.from"}}),_c('MazSelect',{staticClass:"mb-2",attrs:{"placeholder":"Admitting Doctor","options":[
            { label: 'Others', value: 'Others' },
            { label: 'Dr. Aphilak Kritsanasakul', value: 'Dr. Aphilak Kritsanasakul' },
            { label: 'Dr. Watcharobon Kritsanasakul', value: 'Dr. Watcharobon Kritsanasakul' },
            { label: 'Dr. Santisuk Wongprasert', value: 'Dr. Santisuk Wongprasert' },
          ]},model:{value:(_vm.admission.admittingg_doctor),callback:function ($$v) {_vm.$set(_vm.admission, "admittingg_doctor", $$v)},expression:"admission.admittingg_doctor"}})],1),_c('div',{staticClass:"col-6"},[_c('MazSelect',{staticClass:"mb-2",attrs:{"placeholder":"Room Number","options":[
            { label: '201 A', value: '201 A' },
            { label: '201 B', value: '201 B' },
            { label: '201 C', value: '201 C' },
            { label: '202 A', value: '202 A' },
            { label: '202 B', value: '202 B' },
            { label: '203 A', value: '203 A' },
            { label: '203 B', value: '203 B' },
            { label: '204 A', value: '204 A' },
            { label: '204 B', value: '204 B' },
            { label: '205 A', value: '205 A' },
            { label: '205 B', value: '205 B' },
            { label: '206 A', value: '206 A' },
            { label: '206 B', value: '206 B' },
            { label: '301 A', value: '301 A' },
            { label: '301 B', value: '301 B' },
            { label: '302 A', value: '302 A' },
            { label: '302 B', value: '302 B' },
            { label: '303 A', value: '303 A' },
            { label: '303 B', value: '303 B' },
            { label: '304 A', value: '304 A' },
            { label: '304 B', value: '304 B' },
            { label: '305 A', value: '305 A' },
            { label: '305 B', value: '305 B' },
            { label: '306 A', value: '306 A' },
            { label: '306 B', value: '306 B' },
          ]},model:{value:(_vm.admission.room_no),callback:function ($$v) {_vm.$set(_vm.admission, "room_no", $$v)},expression:"admission.room_no"}}),_c('MazSelect',{staticClass:"mb-2",attrs:{"placeholder":"Type of payment","options":[
            { label: 'Others', value: 'Others' },
            { label: 'Insurance', value: 'Insurance' },
            { label: 'Self Pay', value: 'Self Pay' },
          ]},model:{value:(_vm.admission.payment_type),callback:function ($$v) {_vm.$set(_vm.admission, "payment_type", $$v)},expression:"admission.payment_type"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('label',[_vm._v("Staff on duty (Receiving)")])]),_c('div',{staticClass:"col-4"},[_c('MazSelect',{staticClass:"mb-2",attrs:{"placeholder":"RN list","options":[]},model:{value:(_vm.admission.rn),callback:function ($$v) {_vm.$set(_vm.admission, "rn", $$v)},expression:"admission.rn"}})],1),_c('div',{staticClass:"col-4"},[_c('MazSelect',{staticClass:"mb-2",attrs:{"placeholder":"NA list","options":[]},model:{value:(_vm.admission.na),callback:function ($$v) {_vm.$set(_vm.admission, "na", $$v)},expression:"admission.na"}})],1)]),_c('MazSelect',{staticClass:"mb-2",attrs:{"placeholder":"Attending Doctor","options":[
            { label: 'Others', value: 'Others' },
            { label: 'Dr. Aphilak Kritsanasakul', value: 'Dr. Aphilak Kritsanasakul' },
            { label: 'Dr. Watcharobon Kritsanasakul', value: 'Dr. Watcharobon Kritsanasakul' },
            { label: 'Dr. Santisuk Wongprasert', value: 'Dr. Santisuk Wongprasert' },
          ]},model:{value:(_vm.admission.attending_doctor),callback:function ($$v) {_vm.$set(_vm.admission, "attending_doctor", $$v)},expression:"admission.attending_doctor"}})],1)]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn-block btn-primary"},[_vm._v("Save")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn-block btn-danger"},[_vm._v("Cancel")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }