import { db } from '../db'

export const getTotalPaymentApplied = async (invoice) => {
  if (invoice.invoice_id) {
    try {
      const payments = await db.collection('Payment').where('invoice_id', '==', invoice.invoice_id).get()
      let total_payment_applied = 0
      for (const payment of payments.docs) {
        total_payment_applied += payment.data().total_payment
      }
      return total_payment_applied
    } catch (error) {
      return 0
    }
  } else {
    return 0
  }
}

export const getTotalOutstanding = async (invoice) => {
  return invoice.total_invoiced - (await getTotalPaymentApplied(invoice))
}