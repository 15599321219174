<template>
  <div class="container">
    <pdf src="/files/PADI.pdf"></pdf>
    <a target="_blank" href="/files/PADI.pdf" class="btn btn-info btn-block">
      <i class="fas fa-file-pdf"></i> Print Medical Certificate for SCUBA dive
      (PADI)
    </a>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  components: {
    pdf
  }
};
</script>
