<template>
  <div>
    <button
      class="btn bg-white ml-3 shadow"
      style="height: 55px; width: 140px"
      @click="$bvModal.show(id + 'modal')"
      :id="id"
      :ref="id"
    >
      Add
      <img class="pl-2" src="/images/body.png" height="43" />
    </button>
    <b-modal :id="id + 'modal'" size="lg" hide-footer title="Select Body Part">
      <div class="row" v-if="!selectedPartGroup">
        <div
          class="col-6 mt-2"
          v-for="(item, partGroup) in partGroups"
          :key="partGroup"
        >
          <button
            class="btn btn-primary btn-block mt-2"
            @click="selectedPartGroup = partGroup"
          >
            {{ partGroup }}
          </button>
        </div>
      </div>

      <div v-else>
        <div class="row">
          <div
            class="col-6 mt-2"
            v-for="(item, index) in partGroups[selectedPartGroup]"
            :key="'organ' + index"
          >
            <div
              @click="selectedPart=item"
              class="organ-image shadow"
              :class="{ 'organ-image-active': selectedPart&& (selectedPart.name === item.name) }"
              :style="`background-image: url('${item.url}')`"
            ></div>
            <div class="text-center">{{ item.name }}</div>
          </div>
        </div>
      </div>

      <hr />
      <div class="row" v-if="selectedPartGroup">
        <div class="col-6">
          <button
            class="btn btn-light btn-block"
            @click="selectedPartGroup = null"
          >
            Back
          </button>
        </div>
        <div class="col-6">
          <button class="btn btn-primary btn-block" @click="selectImage">
            Confirm
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
const partGroups = {
  ENT: [
    { name: "Oral", url: "/images/organs/ENT/oral.png" },
    { name: "Left Ear", url: "/images/organs/ENT/left_ear.png" },
    { name: "Right Ear", url: "/images/organs/ENT/right_ear.jpg" },
  ],
  FEET: [
    { name: "Feet Anterior", url: "/images/organs/FEET/feet anterior.jpg" },
    { name: "Feet", url: "/images/organs/FEET/feet.png" },
  ],
  "FULL BODY": [
    { name: "Male", url: "/images/organs/FULL_BODY/male.png" },
    { name: "Female", url: "/images/organs/FULL_BODY/female.png" },
  ],
  GENITALIA: [
    { name: "Anal", url: "/images/organs/GENITALIA/ANAL/inspection.png" },
    {
      name: "Female Genital",
      url: "/images/organs/GENITALIA/FEMALE/female_genital.png",
    },
  ],
  HANDS: [{ name: "Hands", url: "/images/organs/HANDS/HANDS.png" }],
  HEAD: [
    { name: "Sides of head", url: "/images/organs/HEAD/SIDES_OF_HEAD.png" },
  ],
  TEETH: [
    {
      name: "Upper and Lower Teeth",
      url: "/images/organs/TEETH/upper_lower.png",
    },
  ],
};
export default {
  props: {
    id: {
      default: "body_part",
    },
    onSelectImage: {
      default: () => {},
    },
  },
  data() {
    return {
      selectedPart: null,
      selectedPartGroup: null,
      partGroups,
    };
  },
  methods: {
    selectImage() {
      this.onSelectImage(this.selectedPart);
      this.$bvModal.hide(this.id + 'modal')
    },
  },
};
</script>

<style lang="scss" scoped>
.organ-image {
  border-radius: 0.25em;
  height: 250px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  margin: 10px;
}

.organ-image-active {
  border: 5px solid green;
}
</style>