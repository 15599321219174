<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 mt-2">
        <div class="card shadow">
          <div class="card-body">
            <h5 class="text-primary">Hotels</h5>
            <div class="h-flex container--hotel">
              <div v-for="zone in zones" :key="zone.id" class="table--hotel flex-1">
                <div>
                  <div class="text-center my-2">
                    <strong>Zone {{ zone.name }}</strong>
                  </div>
                  <table class="table table-bordered">
                    <thead>
                      <th>Name</th>
                      <th>Action</th>
                    </thead>
                    <tbody v-if="(zone.hotels && zone.hotels.length)">
                      <tr v-for="(row, index) in zone.hotels" :key="index">
                        <td>{{row}}</td>
                        <td class="d-flex justify-content-center">
                          <button class="btn btn-primary" @click="showHotel(row, zone)"><i class="fas fa-edit"></i></button>
                          <!-- <button class="btn btn-danger ml-1" @click="deleteHotel(row, zone)"><i class="fa fa-trash"></i></button> -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <button class="btn btn-primary btn-block mt-2" @click="showHotel(null)">
              <i class="fas fa-plus"/> add zone
            </button>
          </div>
        </div>
      </div>
    </div>
    
    <b-modal id="hotel-modal" ref="hotel-modal" hide-footer title="Hotel" size="lg">
      <MazInput
        class="mt-2"
        :placeholder="'Hotel Name'"
        v-model="hotel.name"
      />
      <MazSelect
        class="mt-2"
        v-model="hotel.zone"
        :options="
          dd_zone.map(item => {
            return {
              label: item,
              value: item
            };
          })
        "
        />
      <button class="btn btn-success btn-block mt-2" @click="saveHotel"><i class="fas fa-save"></i> Save</button>
    </b-modal>
  </div>
</template>

<script>
import {db} from '../../db'
export default {
  data() {
    return {
      zones: [],
      hotel: {
        name: null,
        zone: null
      },
      mode: 'create',
      dd_zone: [],
      payload: null,
      hotelSelected: null,
      zoneSelected: null
    }
  },
  firestore() {
    return {
      zones: db.collection('Zone').orderBy("id")
    }
  },
  mounted() {
  },
  methods: {
    showHotel(hotel, zone) {
    this.hotelSelected = hotel
    console.log('this.hotelSelected : ', this.hotelSelected );
    this.zoneSelected = zone
    console.log('this.zoneSelected: ', this.zoneSelected);
    this.dd_zone = this.zones && this.zones.length && this.zones.map(item => item.name)
    console.log('this.dd_zone: ', this.dd_zone);
    console.log('this.zones: ', this.zones);
      if(hotel) {
        this.mode = 'edit'
        this.hotel.name = this.hotelSelected
        this.hotel.zone = this.zoneSelected?.name
      }else{
        this.mode = 'create'
        this.hotel = {
          name: null,
          hotels: null
        }
      }

      this.$bvModal.show('hotel-modal')
    },
    async saveHotel() {
      let loader = this.$loading.show({})
      try {
        if (this.mode === 'create') {
          let dbZone = this.zones.find(item => item.name === this.hotel.zone)
          let dbZoneHotel = dbZone?.hotels
          dbZoneHotel.push(this.hotel.name)
          await db.collection('Zone').doc(dbZone.id).update({
            hotels: dbZoneHotel || [],
          })
        }
        if (this.mode === 'edit') {
          // ZONE NOT CHANGE
          if (this.zoneSelected.name === this.hotel.zone) {
            console.log('case1');
            let dbZone = this.zones.find(item => item.name === this.hotel.zone)
            let dbZoneHotel = dbZone?.hotels
            let indexOf = dbZoneHotel.indexOf(this.hotelSelected)
            if (indexOf >= 0) {
              dbZoneHotel[indexOf] = this.hotel.name
              await db.collection('Zone').doc(dbZone.id).update({
                hotels: dbZoneHotel || [],
              })
            }
          }
          // ZONE CHANGE
          if (this.zoneSelected.name !== this.hotel.zone) {
            console.log('case2');
            let dbZone = this.zones.find(item => item.name === this.zoneSelected.name)
            let dbZoneHotel = dbZone?.hotels
            let indexOf = dbZoneHotel.indexOf(this.hotelSelected)
            let dbZoneNew = this.zones.find(item => item.name === this.hotel.zone)
            let dbZoneHotelNew = dbZoneNew?.hotels
            console.log('indexOf: ', indexOf);
            if (indexOf >= 0) {
              dbZoneHotel.splice(indexOf, 1)
              await db.collection('Zone').doc(dbZone.id).update({
                hotels: dbZoneHotel || [],
              })
              dbZoneHotelNew.push(this.hotel.name)
              await db.collection('Zone').doc(dbZoneNew.id).update({
                hotels: dbZoneHotelNew || [],
              })
              // dbZoneHotel[indexOf] = this.hotel.name

            }
          }
        }
        loader.hide()
        this.$bvModal.hide('hotel-modal')
        this.$alert("Success!", null, "success");
      } catch (error) {
        loader.hide()
        this.$alert("Error!", error.message, "error");
        console.log(error)
      }
      loader.hide()
      this.$bvModal.hide('hotel-modal')
    }
  }
}
</script>

<style scoped>
  .btn {
    padding-top: 0.1rem !important;
    padding-bottom: 0.1rem !important;
    padding-left: 0.3rem !important;
    padding-right: 0.3rem !important;
  }
  .table th, .table td {
    padding: 0.25rem !important;
  }
  .h-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  @media (min-width: 48em) {
    .container--hotel {
      height: 2600px;
      max-height: 2600px;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .table--hotel {
      width: 30%;
    }
  }
</style>