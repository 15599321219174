<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-8 col-sm-12">
        <h5 class="text-primary">Insurance payment Inter/Local</h5>
        <table class="table table-bordered table-striped">
          <thead>
            <th colspan="4" class="text-center">Inter</th>
          </thead>
          <thead>
            <th class="text-center">Names</th>
            <th class="text-center">Country</th>
            <th class="text-center">Time</th>
            <th class="text-center">Amount</th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in interPayments" :key="'inter' + index">
              <td><a :href="`/print/receipt/${item.payment_id}`" target="_blank">{{ item.patient_name }}</a></td>
              <td>{{ item.country }}</td>
              <td>{{ item.issue_date.toDate() | moment('DD/MM/YYYY HH:mm') }}</td>
              <td class="text-right">{{ item.insurance.toLocaleString() }}</td>
            </tr>
            <tr>
              <td colspan="3">Total</td>
              <td class="text-right">{{ item.insurance.toLocaleString() }}</td>
            </tr>
          </tbody>
        </table>
        <table class="table table-bordered table-striped">
          <thead>
            <th colspan="3" class="text-center">Local</th>
          </thead>
          <thead>
            <th class="text-center">Name</th>
            <th class="text-center">Time</th>
            <th class="text-center">Amount</th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in localPayments" :key="'local' + index">
              <td><a :href="`/print/receipt/${item.payment_id}`" target="_blank">{{ item.patient_name }}</a></td>
              <td>{{ item.issue_date.toDate() | moment('DD/MM/YYYY HH:mm') }}</td>
              <td class="text-right">{{ item.cash.toLocaleString() }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-4 col-sm-12">
        <h5 class="text-primary">Local/Inter Cases</h5>
        <Doughnut :chart-options="chartOptions" :chart-data="chartData[0]" :dataset-id-key="'label'"
          :chart-id="'insurance-inter-local'" :width="800" :height="400" />
        <h5 class="text-primary">Local/Resident Amount</h5>
        <Doughnut :chart-options="chartOptions" :chart-data="chartData[1]" :dataset-id-key="'label'"
          :chart-id="'insurance-inter-local-amount'" :width="800" :height="400" />
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-8 col-sm-12">
        <div> </div>
      </div>
      <div class="col-md-4 col-sm-12">
        
      </div>
    </div>
  </div>
</template>

<script>
import { db } from '../../db'
import color from '../../assets/color.json'
import {
  Doughnut
} from 'vue-chartjs/legacy'

export default {
  props: ['payments'],
  components: {
    Doughnut
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        indexAxis: 'y',
        plugins: {
          datalabels: {
            color: '#000',
            anchor: 'center',
            align: 'center',
            formatter: value => {
              return value.toLocaleString();
            }
          },
        }
      },
      preparedPayments: []
    }
  },
  watch: {
    payments: {
      async handler() {
        let loader = this.$loading.show({})

        this.preparedPayments = await Promise.all(this.payments.map(async (item) => {
          let p = await db.collection('Patient').doc(item.HN).get()
          if (p.data().is_resident) {
            item.p_type = 'local'
          } else {
            item.p_type = 'inter'
            item.country = p.data().home_country
          }

          return item
        }))

        loader.hide()
      },
      immediate: true
    }
  },
  computed: {
    interPayments() {
      return this._.filter(this.preparedPayments, (p) => {
        return p.p_type === 'inter' && p.insurance > 0
      })
    },
    localPayments() {
      return this._.filter(this.preparedPayments, (p) => {
        return p.p_type === 'local' && p.insurance > 0
      })
    },
    data() {
      let data = [
        {
          label: 'Inter',
          amount: 0,
          quantity: 0,
          index: 0
        },
        {
          label: 'Local',
          amount: 0,
          quantity: 0,
          index: 1
        }
      ]

      for (let item of this.preparedPayments) {
        if (item.p_type === 'inter' && item.insurance) {
          data[0].amount += item.insurance
          data[0].quantity++
        }
        if (item.p_type === 'local' &&item.insurance) {
          data[1].amount += item.insurance
          data[1].quantity++
        }
      }

      return data
    },
    chartData() {

      let chartData = {
        labels: this.data.map(item => item.label),
        datasets: [{
          label: 'Insurance',
          data: this.data.map(d => d.quantity),
          backgroundColor: this.data.map(d => color[d.index + 2])
        }]
      }
      let chartData2 = {
        labels: this.data.map(item => item.label),
        datasets: [{
          label: 'Insurance',
          data: this.data.map(d => d.amount),
          backgroundColor: this.data.map(d => color[d.index + 2])
        }]
      }

      return [chartData, chartData2]
    }
  }
}
</script>