<template>
  <div class="row mt-2">
    <div class="col-md-2 col-sm-12 text-center">
      <MazPicker
        v-model="from"
        no-time
        format="DD/MM/YYYY"
        formatted="DD/MM/YYYY"
        placeholder="Start Date"
      />
    </div>
    <div class="col-md-2 col-sm-12 text-center">
      <MazPicker
        v-model="to"
        no-time
        format="DD/MM/YYYY"
        formatted="DD/MM/YYYY"
        placeholder="End Date"
      />
    </div>
    <div class="col-md-8 col-sm-12">
      <b class="mr-2">Period: </b>
      <button class="btn mr-2 shadow" v-for="(item, index) in periods" :key="`period${index}`" @click="handlePeriod(item)" :class="{ 'btn-primary': selectedPeriod.name === item.name }">
        <div><b>{{ item.name }}</b></div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['items'],
  data() {
    return {
      from: this.$moment().add(-1, 'day').format('DD/MM/YYYY'),
      to: this.$moment().format('DD/MM/YYYY'),
      periods: [
        { name: 'Day', from: this.$moment().add(-1, 'day').format('DD/MM/YYYY') , to: this.$moment().format('DD/MM/YYYY') },
        { name: 'Week', from: this.$moment().add(-1, 'week').format('DD/MM/YYYY') , to: this.$moment().format('DD/MM/YYYY') },
        { name: 'Month', from: this.$moment().add(-1, 'month').format('DD/MM/YYYY') , to: this.$moment().format('DD/MM/YYYY') },
        { name: 'Year', from: this.$moment().add(-1, 'year').format('DD/MM/YYYY') , to: this.$moment().format('DD/MM/YYYY') },
      ],
      selectedPeriod: { name: 'Day', from: this.$moment().add(-1, 'day').format('DD/MM/YYYY'), to: this.$moment().format('DD/MM/YYYY') }
    }
  },
  methods: {
    handlePeriod(item){
      this.selectedPeriod=item
      this.from = item.from
      this.to = item.to
    }
  },
  watch: {
    from: {
      handler() {
        this.$emit('input', this.filteredItems)
      }
    },
    to: {
      handler() {
        this.$emit('input', this.filteredItems)
      }
    },
  },
  computed: {
    filteredItems() {
      let from = this.$moment(this.from,'DD/MM/YYYY')
      let to = this.$moment(this.to,'DD/MM/YYYY')

      return this._.filter(this.items, item => {
        if(item.issue_date) {
          return this.$moment(item.issue_date.toDate()).isAfter(from.startOf('day')) && this.$moment(item.issue_date.toDate()).isBefore(to.endOf('day'))
        }else{
          return this.$moment(item.created_at.toDate()).isAfter(from.startOf('day')) && this.$moment(item.created_at.toDate()).isBefore(to.endOf('day'))
        }
      })
    }
  }
}
</script>