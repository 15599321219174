<template>
  <div class="container-fluid">
    <vue-good-table
      :columns="columns"
      :rows="patients"
      :pagination-options="{
        enabled: true
      }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'index'">
          {{ props.row.originalIndex + 1 }}
        </span>
        <div v-else-if="props.column.label == 'DR / RN'">
          <div>
            <b>{{ props.row.doctor }}</b>
          </div>
          <div>{{ props.row.nurse }}</div>
        </div>
        <div v-else-if="props.column.field == 'details'">
          <div class="text-dark">
            <b>{{ props.formattedRow.details.name }}</b>
          </div>
          <div class="text-primary">
            HN: {{ props.formattedRow.details.HN }}
          </div>
          <div
            class="text-primary"
            v-if="props.formattedRow.details.appointment"
          >
            <i>Appointment: {{ props.formattedRow.details.appointment }}</i>
          </div>
          <div>
            <a :href="`tel:${props.formattedRow.details.tel}`"
              ><i class="fas fa-phone"></i>
              {{ props.formattedRow.details.tel }}</a
            >
          </div>
          <div>
            <a :href="`mailto:${props.formattedRow.details.email}`"
              ><i class="fas fa-envelope"></i>
              {{ props.formattedRow.details.email }}</a
            >
          </div>
        </div>
        <span v-else-if="props.column.field == 'esi'">
          <i class="fa-stack" :style="`color: ${props.formattedRow.esi};`">
            <i class="far fa-circle fa-stack-2x"></i>
            <i class="fas fa-circle fa-stack-1x"></i>
          </i>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        {
          label: "No.",
          field: "id"
        },
        {
          label: "Time",
          field: "time"
        },
        {
          label: "Patient Details",
          field: "details"
        },
        {
          label: "Diagnosis",
          field: "diagnosis"
        },
        {
          label: "DR / RN",
          field: "doctor"
        },
        {
          label: "Send to",
          field: "sendto"
        },
        {
          label: "Patient status",
          field: "status"
        },
        {
          label: "Finance",
          field: "payment_status"
        },
        {
          label: "Note",
          field: "note"
        }
      ],
      patients: []
    };
  },
  methods: {
    onRowClick(params) {
      this.$router.push(`/patient/${params.row.hn}`);
    }
  }
};
</script>

<style></style>
