var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('general-header'),_c('hr'),_c('b-nav',{staticClass:"custom-sub-tab",attrs:{"tabs":"","justified":""}},[_c('b-nav-item',{attrs:{"active":this.$route.name === 'Up Coming Appointments',"to":{
        name: 'Up Coming Appointments',
        params: { id: this.$route.params.id }
      }}},[_vm._v("Up-coming Appointments")]),_c('b-nav-item',{attrs:{"active":this.$route.name === 'Passed Appointments',"to":{
        name: 'Passed Appointments',
        params: { id: this.$route.params.id }
      }}},[_vm._v("Passed Appointments")])],1),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('router-view',{staticClass:"mt-2"})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }