var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('patient-info',{attrs:{"patient":_vm.patient}}),_vm._m(0),(_vm.showAdmission===false)?_c('vue-good-table',{staticClass:"mt-3",attrs:{"columns":_vm.columns,"rows":_vm.admissions,"fixed-header":true,"sort-options":{
      enabled: false
    },"group-options":{
      enabled: true
    }},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.label == 'ICD-10 & Principal Diagnosis')?_c('div',[_c('div',{staticClass:"esi-box",staticStyle:{"background-color":"#629B49","color":"white"}},[_vm._v(" ESI level 3 ")]),_c('div',[_vm._v("Acute gastroenteritis, A09")])]):_c('div',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,1737798564)}):_vm._e(),(_vm.showAdmission===true)?_c('div',[_c('div',{staticClass:"d-flex mt-2"},[_c('b-nav',{staticClass:"mt-2",attrs:{"tabs":"","justified":""}},[_c('b-nav-item',{staticClass:"text-success",attrs:{"active":_vm.$route.name === 'Patient Admission OPD doc',"to":{
            name: 'Patient Admission OPD doc',
            params: { id: _vm.$route.params.id, visitId: _vm.$route.params.visitId }
          }}},[_vm._v(" OPD DOCS ")]),_c('b-nav-item',{attrs:{"active":_vm.$route.name === 'Patient Admission IPD doc',"to":{
            name: 'Patient Admission IPD doc',
            params: { id: _vm.$route.params.id, visitId: _vm.$route.params.visitId }
          }}},[_vm._v(" IPD DOCS ")])],1)],1),_c('router-view',{staticStyle:{"margin-top":"15px"},attrs:{"patient":_vm.patient}})],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"text-primary mt-2"},[_vm._v(" Admission Summary "),_c('i',{staticClass:"fas fa-filter"})])
}]

export { render, staticRenderFns }