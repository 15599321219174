<template>
  <div class="mt-2 alter">
    <patient-info :patient="patient" :allergy_alert="true" :nursing="nursing" :opd="opd"></patient-info>

    <div class="d-flex">
      <div class="title-tab">
        <router-link custom :to="{
          name: 'Nurse Assessment',
          params: { id: $route.params.id, visitId: $route.params.visitId }
        }">OPD Docs</router-link>
      </div>
      <!-- <div class="title-tab">
        <router-link 
          :to="{
          name: 'Patient Visit Billing',
          params: { id: $route.params.id, visitId: $route.params.visitId }
        }">BILLING</router-link>
      </div> -->
    </div>
    <div v-if="$route.meta.is_opd_docs">
      <div class="green-bar"></div>
      <div class="d-flex">
        <div class="dropdown menu-tab" :class="{ 'menu-tab-active': $route.meta.nursing_sheet === true ? 'active' : '' }">
          NURSING SHEETS
          <div class="dropdown-content">
            <div class="dropdown-item">
              <router-link custom :to="{
                name: 'Nurse Assessment',
                params: { id: $route.params.id, visitId: $route.params.visitId }
              }">Nurse Assessment</router-link>
            </div>
            <div class="dropdown-item disabled-item">
              <!-- <router-link :to="{
                name: 'Observation Sheet',
                params: { id: $route.params.id, visitId: $route.params.visitId }
              }">Observation Sheet</router-link> -->
              Observation Sheet (WIP.)
            </div>
            <div class="dropdown-item">
              <router-link :to="{
                name: 'Discharge Status',
                params: { id: $route.params.id, visitId: $route.params.visitId }
              }">Discharge Status</router-link>
            </div>
            <div class="dropdown-item">
              <router-link :to="{
                name: 'Transfer',
                params: { id: $route.params.id, visitId: $route.params.visitId }
              }">Transfer Form</router-link>
            </div>
          </div>
        </div>
        <div class="dropdown menu-tab" :class="{ 'menu-tab-active': $route.meta.doctor_sheet === true ? 'active' : '' }">
          DOCTOR SHEETS
          <div class="dropdown-content">
            <div class="dropdown-item">
              <router-link :to="{
                name: 'OPD Card',
                params: { id: $route.params.id, visitId: $route.params.visitId }
              }">OPD Card</router-link>
            </div>
          </div>
        </div>
        <div class="menu-tab" :class="{ 'menu-tab-active': $route.name === 'Investigation Results' ? 'active' : '' }">
          <router-link :to="{
            name: 'Investigation Results',
            params: { id: $route.params.id, visitId: $route.params.visitId }
          }">INVESTIGATION</router-link>
        </div>
        <div class="dropdown menu-tab" :class="{ 'menu-tab-active': $route.meta.medcert === true ? 'active' : '' }">
          CERTIFICATES
          <div class="dropdown-content">
            <div class="dropdown-item" style="opacity: 0.5;">
              General Medical Certificate
              <!-- <router-link 
                disabled
                :to="{
                name: 'General Medical Certificate',
                params: { id: $route.params.id, visitId: $route.params.visitId }
              }">General Medical Certificate</router-link> -->
            </div>
            <div class="dropdown-item" style="opacity: 0.5;">
              Fit to fly
              <!-- <router-link :to="{
                name: 'Fit to fly Certificate',
                params: { id: $route.params.id, visitId: $route.params.visitId }
              }">Fit to fly</router-link> -->
            </div>
            <div class="dropdown-item" style="opacity: 0.5;">
              Work Permit
              <!-- <router-link :to="{
                name: 'Medical Certificate for Work Permit',
                params: { id: $route.params.id, visitId: $route.params.visitId }
              }">Work Permit</router-link> -->
            </div>
            <div class="dropdown-item" style="opacity: 0.5;">
              Insurance
              <router-link :to="{
                name: 'Medical Certificate for Insurance',
                params: { id: $route.params.id, visitId: $route.params.visitId }
              }">Insurance</router-link>
            </div>
            <div class="dropdown-item">
              <router-link :to="{
                name: 'Medical Certificate for SCUBA PADI',
                params: { id: $route.params.id, visitId: $route.params.visitId }
              }">SCUBA (PADI)</router-link>
            </div>
          </div>
        </div>

        <div class="ml-auto">
          <div class="right-item">
            DOCUMENTS
          </div>
        </div>
        <div class="ml-2">
          <div class="right-item">
            <router-link :to="{
              name: 'Doctor Order',
              params: { id: $route.params.id, visitId: $route.params.visitId }
            }">VIEW ORDER SUMMARY</router-link>
          </div>
        </div>
      </div>
      <div class="green-bar"></div>
    </div>
    <div class="card">
      <div class="card-body">
        <router-view class="mt-2" :patient="patient" :opd="opd" :nursing="nursing"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../../../db";
import moment from "moment"
export default {
  props: ["patient"],
  data() {
    return {
      nursing: {},
      opd: {}
    };
  },
  async mounted() {
    if (this.$route.params.visitId) {
      let visitId = await this.$route.params.visitId;
      let nursing = await db
        .collection("NursingAssessment")
        .where("visit", "==", db.collection("Visit").doc(visitId))
        .limit(1)
        .get();

      let visit = await db.collection('Visit').doc(visitId).get()
      let branch = await db.collection('Branch').doc(visit.data().branch.id).get()
      let department = await db.collection('Department').doc(visit.data().department.id).get()
      if (nursing.docs.length > 0) {
        let nursingFetch = nursing.docs[0].data();
        this.nursing = {
          id: visitId,
          ...nursingFetch,
          branch: { ...branch.data() },
          department: { ...department.data() },
        };
        
        console.log(`[Visit]`, this.nursing)
      }


      let opd = await db.collection("OpdCard")
        .where("visit", "==", db.collection("Visit").doc(visitId))
        .limit(1)
        .get()

      if (opd.docs.length > 0) {
        this.opd = {
          id: opd.docs[0].id,
          ...opd.docs[0].data()
        }

      }

    }
  }
};
</script>


<style lang="scss" scoped>
.title-tab {
  background-color: #ededed;
  color: green;
  padding: 10px;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border: 1px solid #adadad;
  width: 200px;
  margin-top: 10px;
  text-align: center;
  font-weight: 600;
}

.menu-tab {
  background-color: #fafafa;
  color: #1d1d1d;
  padding: 15px;
  border: 1px solid #adadad;
  min-width: 100px;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
}

.menu-tab-active {
  color: white;
  background-color: green;
  border: 1px solid green;
}

.green-bar {
  height: 10px;
  background-color: green;
  width: 100%;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  color: green !important;
}

.dropdown-item {
  padding: 16px;
  border: 1px solid #adadad;
  text-align: left;
}

.dropdown-item:hover {
  background-color: green;
}

.dropdown-item>a {
  color: #1d1d1d !important;
  padding: 16px;
  font-weight: 600;
  text-decoration: none;
}

.dropdown-item:hover>a {
  background-color: green;
  color: white !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.menu-tab:hover {
  background-color: #00a32a;
  color: white;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: white;
  text-decoration: none;
}

.right-item {
  padding: 8px;
  margin: 5px;
  color: black;
  background-color: lightgray;
  border-radius: 0.25em;
}
</style>