import { db } from "../db";
const breatingList = [
  { name: "Ambu Bag Adult", qty: 1 },
  { name: "Ambu Bag Child", qty: 1 },
  { name: "O2 Mask with Bag (Adult)", qty: 1 },
  { name: "O2 Mask with Bag (Child)", qty: 1 },
  { name: "Cannula Adult", qty: 1 },
  { name: "Cannula Child", qty: 1 },
  { name: "Nebulizer Adult", qty: 1 },
  { name: "Nebulizer Child", qty: 1 },
  { name: "Ventolin", qty: 5 },
  { name: "Mouth Gag", qty: "3/3" },
  { name: "Nipple Knot", qty: 1 }
];
const airwayList = [
  { name: "ETT 7/7.5/8", qty: "1/1/1" },
  { name: "Guide Wire", qty: 3 },
  { name: "KY Gel", qty: 1 },
  { name: "Fixomull", qty: 2 },
  { name: "Razor", qty: 1 },
  { name: "Laryngoscope", qty: 1 }
];
const medEquipList1 = [
  { name: "Thermometer", qty: 1 },
  { name: "DTX", qty: 1 },
  { name: "O2 Sat", qty: 1 },
  { name: "Mask", qty: 2 },
  { name: "Plastic Bag", qty: 2 },
  { name: "Flash light/ Torch", qty: 1 },
  { name: "Bandage Scissor", qty: 1 },
  { name: "OPD ER Form", qty: 1 },
  { name: "BP Apparatus", qty: 1 }
];
const medEquipList2 = [
  { name: "AED", qty: null },
  { name: "Emergency Bag", qty: null },
  { name: "Neck Collar", qty: null },
  { name: "Spinal Board", qty: null },
  { name: "Leg & Arm Stabilizer", qty: null },
  { name: "Crutches", qty: null },
  { name: "Arm and Leg Splint", qty: null },
  { name: "Blanket", qty: null },
  { name: "Pillow", qty: null },
  { name: "Oxygen Tank", qty: null }
];
const medicineIVList = [
  { name: "Adrenalin", qty: 10 },
  { name: "Atropine", qty: 5 },
  { name: "Diazepam", qty: 5 },
  { name: "Dopamine", qty: 5 },
  { name: "Dexamethasone", qty: 5 },
  { name: "NAHC03", qty: 2 },
  { name: "Glucose 50%", qty: 2 }
];
const medicineOralList = [
  { name: "Aspirin 81 mg", qty: 10 },
  { name: "Aspirin 325 mg", qty: 10 },
  { name: "Clopidogrel 75 mg ", qty: 10 },
  { name: "Isordil 5 mg", qty: 10 },
  { name: "Paracetamol", qty: 10 }
];
const suppliesList = [
  { name: "IV Set", qty: 2 },
  { name: "Extension", qty: 1 },
  { name: "Three Way", qty: 1 },
  { name: "IV Cath 18/22/24", qty: "2/2/2" },
  { name: "Needle 18/22/27", qty: "2/2/2" },
  { name: "Syringe 10/5/1", qty: "2/2/2" },
  { name: "Tube red, purple, green", qty: "2/2/2" },
  { name: "cotton with alcohol", qty: 1 },
  { name: "Tegaderm", qty: 2 },
  { name: "Dry Cotton", qty: 1 },
  { name: "Plastic Bag for dispose", qty: 5 },
  { name: "Plastic Bag", qty: 2 },
  { name: "Gloves 6.5/7/7.5/8", qty: "1/1/1/1" },
  { name: "NSS 1000ml", qty: 1 },
  { name: "NSS 100ml", qty: 1 },
  { name: "NSS 5ml", qty: 2 },
  { name: "Transpore", qty: 1 }
];
export default {
  data() {
    return {
      transfer_eqiupment_list: {
        breating_list: breatingList,
        airway_list: airwayList,
        med_equip_list_1: medEquipList1,
        med_equip_list_2: medEquipList2,
        medicine_iv_list: medicineIVList,
        medicine_oral_list: medicineOralList,
        supplies_list: suppliesList,
        extra_list: [
          {
            name: null,
            qty: null
          }
        ]
      }
    };
  },
  methods: {
    addRowList(modelGrp, model, value) {
      if (modelGrp && model) {
        this[modelGrp][model].push({});
        this[modelGrp][model + "Length"] += value;
      } else {
        this[model].push({});
        this[model + "Length"] += value;
      }
    },
    removeRowList(modelGrp, model, index) {
      if (modelGrp && model) {
        if (this[modelGrp][model].length > 1) {
          this[modelGrp][model].splice(index, 1);
        }
      }
    }
  }
};
