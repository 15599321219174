<template>
  <b-modal id="new-invoice-modal" hide-footer size="xl" header-bg-variant="gray"
    :title="`${invoice.invoice_id ? 'Invoice #' + invoice.invoice_id : 'New Invoice'}`">
    <div class="container-fluid">
      <div class="row mt-2">
        <div class="col-1">
          <div class="text-primary">From</div>
        </div>
        <div class="col-3">
          <b>WORLDMED CENTER</b>
          <p>125/131-133, M.7 Phi Phi Island, T.Aonang A.MuangKrabi Krabi, 81210 Thailand</p>
          <div class="mt-2">
            <b>WORLDMED CENTER Co., Ltd.</b>
            <div>0835557004354</div>
          </div>
        </div>
        <div class="col-4">
          <div class="d-flex mb-2">
            <label class="text-primary mr-auto">Billed to:</label>
            <object-select class="ml-auto" v-model="invoice.HN" @input="handlePatientNameSelect" :options="preparedPatients.map(p => {
              return {
                id: p.HN,
                name: p.name
              }
            })" />
          </div>
          <div class="d-flex mb-2">
            <label class="text-primary">Concession type:</label>
            <multiselect class="ml-2" selectLabel="" v-model="invoice.concession_type"
              :options="['Resident', 'Foreigner', 'Insurance']" @input="handleConcessionChanged" />
          </div>
          <div class="d-flex mb-2" v-if="invoice.concession_type === 'Insurance'">
            <label class="text-primary">Insurance: </label>
            <multiselect class="ml-2" selectLabel="" v-model="invoice.insurance_name" :options="[
              'Other',
              ..._.sortBy($store.state.insurances.map(c => {
                return c.name_en
              }))
            ]" />

          </div>
          <div class="d-flex mb-2" v-if="invoice.insurance_name === 'Other'">
            <label class="text-primary">Other insurance: </label>
            <input type="text" class="form-control ml-2" v-model="invoice.insurance_name_other"
              placeholder="Other Insurance Name">
          </div>
          <div class="d-flex mb-2">
            <label class="text-primary">Status: </label>
            <object-select class="ml-2" v-model="invoice.status" :options="[
              { id: 'unpaid', name: 'Unpaid' },
              { id: 'waiting for payment', name: 'Waiting for Payment' },
              { id: 'outstanding', name: 'Outstanding' },
              { id: 'paid', name: 'Paid' },
              { id: 'canceled', name: 'Canceled' },
            ]" />
          </div>
          <div class="d-flex mb-2">
            <label class="text-primary">Type:</label>
            <multiselect class="ml-2" selectLabel="" v-model="invoice.type" :options="['OPD', 'IPD']" />
          </div>
        </div>

        <div class="col-4">
          <div class="d-flex mb-2">
            <label class="text-secondary mr-auto">Issue Date</label>
            <MazPicker v-model="invoice.issue_date" :format="`DD/MM/YYYY HH:mm`" :formatted="`DD/MM/YYYY HH:mm`"
              placeholder="Issue Date" />
          </div>
          <div class="d-flex mb-2" v-if="invoice.invoice_id">
            <label class="text-primary mr-auto">Invoice#</label>
            <div class="text-right">{{ invoice.invoice_id }}</div>
          </div>
          <div class="d-flex mb-2">
            <label class="text-primary mr-auto">HN</label>
            <div class="text-right">{{ invoice.HN }}</div>
          </div>
          <div class="d-flex mb-2">
            <label class="text-primary mr-auto">Visit Number (VN)</label>
            <div class="text-right">{{ invoice.VN }}</div>
          </div>
          <div class="d-flex mb-2">
            <label class="text-primary mr-auto">Visit Date</label>
            <MazPicker v-model="invoice.visit_date" :format="`DD/MM/YYYY HH:mm`" :formatted="`DD/MM/YYYY HH:mm`"
              placeholder="Visit Date" />
          </div>
          <div class="d-flex mb-2">
            <label class="text-primary mr-auto">Patient</label>
            <div class="text-right">{{ invoice.patient_name }}</div>
          </div>
          <div class="d-flex mb-2">
            <label class="text-primary mr-auto">Practitioner</label>
            <multiselect class="ml-2" selectLabel="" v-model="invoice.practitioner" :options="doctors.map(d => {
              return d.displayName
            })" />
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <div class="d-flex">
            <label class="text-primary">Extra Patient Info:</label>
            <textarea class="form-control" v-model="invoice.note" />
          </div>
        </div>
      </div>
    </div>

    <hr>


    <div class="d-flex p-2 bg-light">
      <h5 class="text-primary">BILLABLE ITEMS</h5>
    </div>
    <div class="container-fluid">
      <draggable v-model="invoice.billable_items" group="billable_items" @start="drag = true" @end="drag = false">
        <div class="row mb-2" v-for="(item, index) in invoice.billable_items" :key="index">
          <div class="col-1">
            <div class="m-4 p-2">
              <i class="fas fa-bars" style="color: grey"></i>
            </div>
          </div>
          <div class="col-3">
            <label class="text-primary">Item</label>
            <object-select class="ml-auto" v-model="item.item_code" :options="prepared_billable_items.map(i => {
              return { id: i.item_code, name: i.item_name }
            })" @input="handleBillableItemChanged(index)" />
          </div>
          <div class="col-1">
            <label class="text-primary">Quantity</label>
            <input type="number" class="form-control" v-model="item.quantity">
          </div>
          <div class="col-2">
            <label class="text-primary">Unit Price</label>
            <input-number v-model="item.price"></input-number>
          </div>
          <div class="col-2">
            <label class="text-primary">Discount</label>
            <b-input-group append="%">
              <b-form-input v-model="item.discount" type="number"></b-form-input>
            </b-input-group>
          </div>
          <div class="col-2 text-right">
            <label class="text-primary ml-auto">Total</label>
            <div v-if="item.quantity && item.price">{{ ((item.price - (item.price * item.discount / 100)) *
              item.quantity).toLocaleString() }}</div>
          </div>
          <div class="col-1">
            <button class="btn btn-light" @click="invoice.billable_items.splice(index, 1)"><i
                class="fas fa-trash"></i></button>
          </div>
          <hr>
        </div>
      </draggable>
    </div>

    <div class="d-flex p-2 bg-light">
      <h5 class="text-primary">PRODUCTS ITEMS</h5>
    </div>
    <div class="container-fluid">
      <draggable v-model="invoice.product_items" group="invoice.product_items" @start="drag = true" @end="drag = false">
        <div class="row mb-2" v-for="(item, index) in invoice.product_items" :key="index">
          <div class="col-1">
            <div class="m-4 p-2">
              <i class="fas fa-bars" style="color: grey"></i>
            </div>
          </div>
          <div class="col-3">
            <label class="text-primary">Item</label>
            <object-select class="ml-auto" v-model="item.item_code" :options="prepared_products.map(i => {
              return { id: i.item_code, name: i.item_name }
            })" @input="handleProductChanged(index)" />
          </div>
          <div class="col-1">
            <label class="text-primary">Quantity</label>
            <input type="number" class="form-control" v-model="item.quantity">
          </div>
          <div class="col-2">
            <label class="text-primary">Unit Price</label>
            <input type="number" class="form-control" v-model="item.price">
          </div>
          <div class="col-2">
            <label class="text-primary">Discount</label>
            <b-input-group append="%">
              <b-form-input v-model="item.discount" type="number"></b-form-input>
            </b-input-group>
          </div>
          <div class="col-2 text-right">
            <label class="text-primary ml-auto">Total</label>
            <div v-if="item.quantity && item.price">{{ ((item.price - (item.price * item.discount / 100)) *
              item.quantity).toLocaleString() }}</div>
          </div>
          <div class="col-1">
            <button class="btn btn-light" @click="invoice.product_items.splice(index, 1)"><i
                class="fas fa-trash"></i></button>
          </div>
          <hr>
        </div>
      </draggable>

    </div>

    <div class="d-flex p-2 bg-gray">
      <button class="btn btn-primary mr-2" @click="addBillableItem">
        <i class="fas fa-plus"></i> Add Billable Item
      </button>
      <button class="btn btn-primary mr-2" @click="addProduct">
        <i class="fas fa-plus"></i> Add Product
      </button>
      <button class="btn btn-primary mr-2" @click="showPackage">
        <i class="fas fa-plus"></i> Add Package
      </button>
    </div>

    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-7">
          <label class="text-primary">Note</label>
          <textarea class="form-control" v-model="invoice.note"></textarea>
        </div>
        

        <div class="col-5">
          <div class="d-flex mb-2">
            <b class="mr-auto">Total Discount</b>
            <div>{{ totalDiscount.toLocaleString() }}
            </div>
          </div>
          <div class="d-flex mb-2">
            <b class="mr-auto">Total Amount</b>
            <div>{{ totalAmount.toLocaleString() }}</div>
          </div>
        </div>

        

      </div>

      <label class="text-primary mt-4" v-if="invoice.history">
        <button @click="toggleHistory">
          {{ showHistory ? 'Hide History' : 'Show History' }}
        </button>
        
      </label>
      <div class="row mt-2" v-if="showHistory && invoice.history && invoice.history.length > 0">
        <div class="col-12 text-grey" v-for="(item, index) of invoice.history" :key="index">
          [{{ item.datetime.toDate() | moment('DD/MM/YYYY HH:mm') }}] User: <span
            class="text-primary">{{ item.user.displayName }} ({{ item.user.uid }})</span> Action:<span
            class="text-primary">{{ item.type }}</span>
        </div>
      </div>
    </div>

    <div class="d-flex mt-2 mb-2">
      <router-link v-if="invoice.invoice_id" :to="`/print/invoice/${invoice.invoice_id}`" target="_blank"
        class="btn btn-light" style="margin-left: 10px; width: 150px;">
        <i class="fas fa-print"></i> Print Invoice
      </router-link>
      <button v-if="invoice.invoice_id && invoice.status !== 'paid'" class="btn btn-primary ml-2"
        @click="showNewPayment(invoice)">
        <i class="fas fa-money-bill-wave"></i> New Payment
      </button>
      <button class="btn btn-success ml-auto" @click="saveInvoice"><i class="fas fa-save"></i> Save Invoice</button>
    </div>
  </b-modal>
</template>

<script>
import { db, Timestamp } from '../../db'
import draggable from 'vuedraggable'
export default {
  props: ["patient", "visitId"],
  components: {
    draggable
  },
  data() {
    return {
      showHistory: false,
      doctors: [],
      invoice: {
        invoice_id: null,
        issue_date: this.$moment().format('DD/MM/YYYY HH:mm'),
        visit_date: this.$moment().format('DD/MM/YYYY HH:mm'),
        concession_type: 'Foreigner',
        insurance_name: null,
        insurance_name_other: null,
        HN: null,
        VN: null,
        patient_name: null,
        status: 'unpaid',
        product_items: [],
        billable_items: [],
        note: null,
        payment_applied: 0,
        outstanding: 0,
        total_invoiced: 0
      },
      selectedPackage: null,
      keyword: null,
      invoices: [],
      payments: [],
      patients: [],
      packages: [],
      products: [],
      billable_items: [],
      total_invoiced: 0,
      payment_applied: 0,
      creditcards: [],
      new_credit_bank_name: null
    }
  },
  firestore() {
    return {
      patients: db.collection('Patient').orderBy("created_at", "desc"),
      products: db.collection('Product'),
      billable_items: db.collection('BillableItem'),
      packages: db.collection('Package'),
      doctors: db.collection('User').where('roles', 'array-contains', 'doctor').where('isApproved', '==', true),
      creditcards: db.collection('CreditCard')
    }
  },
  methods: {
    toggleHistory() {
      this.showHistory = !this.showHistory
    },
    capitalizeFirstChar(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    async saveInvoice() {
      let loader = this.$loading.show({});
      let isNewInvoice = !this.invoice.invoice_id

      if (!this.invoice.patient_name) {
        loader.hide()
        this.$alert('Please select patient')
        return
      }
      if (!this.invoice.type) {
        loader.hide()
        this.$alert('Please select type OPD/IPD')
        return
      }

      if (isNewInvoice) {
        this.invoice.history = [{
          type: 'create',
          datetime: Timestamp.fromDate(new Date()),
          user: {
            uid: this.$store.state.user.uid,
            displayName: this.$store.state.user.displayName,
            email: this.$store.state.user.email
          }
        }]
      } else {
        this.invoice.history.push({
          type: 'edit',
          datetime: Timestamp.fromDate(new Date()),
          user: {
            uid: this.$store.state.user.uid,
            displayName: this.$store.state.user.displayName,
            email: this.$store.state.user.email
          }
        })
      }

      try {
        let sod = this.$moment().startOf('day').toDate()
        let eod = this.$moment().endOf('day').toDate()
        let latestInvoice = await db.collection('Invoice')
          .where('issue_date', '<', eod)
          .where('issue_date', '>=', sod)
          .get()
        let invoicesToday = latestInvoice.docs.length
        console.log('current invoice today', invoicesToday)

        let invoice_id
        if (this.invoice.invoice_id) {
          invoice_id = this.invoice.invoice_id
        } else {
          invoice_id = `${String(this.$store.state.branch).padStart(2, '0')}-${this.$moment().format('YYYY')}-${String(invoicesToday + 1).padStart(4, '0')}-${this.$moment().format('MM-DD')}`
        }
        console.log({ invoice_id })
        this.invoice.invoice_id = invoice_id
        let total_invoiced = 0
        for (let item of this.invoice.billable_items) {
          item.quantity = Number(item.quantity)
          item.price = Number(item.price)
          item.discount = Number(item.discount)
          if (Number(item.discount) < 0 || Number(item.discount) > 100) {
            this.$alert('discount should be 0-100%')
            loader.hide()
            return
          }
          if (Number(item.price) < 0) {
            this.$alert('price can not below 0')
            loader.hide()
            return
          }
          if (Number(item.quantity) < 0) {
            this.$alert('quantity can not below 0')
            loader.hide()
            return
          }
          total_invoiced += (Number(item.price) - Number(Number(item.price) * item.discount / 100)) * Number(item.quantity)
        }
        for (let item of this.invoice.product_items) {
          item.quantity = Number(item.quantity)
          item.price = Number(item.price)
          item.discount = Number(item.discount)
          if (Number(item.discount) < 0 || Number(item.discount) > 100) {
            this.$alert('discount should be 0-100%')
            loader.hide()
            return
          }
          if (Number(item.price) < 0) {
            this.$alert('price can not below 0')
            loader.hide()
            return
          }
          if (Number(item.quantity) < 0) {
            this.$alert('quantity can not below 0')
            loader.hide()
            return
          }
          total_invoiced += (Number(item.price) - Number(Number(item.price) * item.discount / 100)) * Number(item.quantity)
        }
        this.invoice.total_invoiced = total_invoiced
        if (!this.invoice.payment_applied) this.invoice.payment_applied = 0

        let invoice = {
          ...this.invoice
        }

        invoice.issue_date = this.$moment(invoice.issue_date, 'DD/MM/YYYY HH:mm').toDate()
        invoice.visit_date = this.$moment(invoice.visit_date, 'DD/MM/YYYY HH:mm').toDate()

        await db.collection('Invoice').doc(invoice_id).set(invoice)
        loader.hide()
        this.$alert("Save invoice success.", null, "success");
        // this.$bvModal.hide('new-invoice-modal')
      } catch (error) {
        console.error(error)
        this.$alert(`error ${error}`, null, "error");
        loader.hide()
      }


    },
    async showNewInvoice() {
      this.invoice = {
        invoice_id: null,
        issue_date: this.$moment().format('DD/MM/YYYY HH:mm'),
        visit_date: this.$moment().format('DD/MM/YYYY HH:mm'),
        concession_type: 'Foreigner',
        insurance_name: null,
        insurance_type: null,
        insurance_name_other: null,
        HN: null,
        VN: null,
        patient_name: null,
        status: 'unpaid',
        product_items: [],
        billable_items: [],
        note: null,
        payment_applied: 0,
        outstanding: 0,
        total_invoiced: 0,
        practitioner: null
      }

      if (this.patient) {
        this.invoice.HN = this.patient.HN
        this.invoice.patient_name = `${this.patient.first_name} ${this.patient.last_name}`

        if (this.patient.travel_insurance) {
          this.invoice.concession_type = 'Insurance'
          this.invoice.insurance_name = this.patient.travel_insurance?.name_en || null
          this.invoice.insurance_type = this.patient.travel_insurance?.type || null
          this.invoice.insurance_name_other = this.patient.travel_insurance_other || null
        } else if (this.patient.is_resident || this.patient.is_resident) {
          this.invoice.concession_type = 'Resident'
        } else {
          this.invoice.concession_type = 'Foreigner'
        }

      }

      if (this.visitId) {
        this.invoice.VN = this.visitId
        if (this.invoice.VN) {
          let visitRef = await db.collection('Visit').doc(this.invoice.VN).get()
          if (visitRef.exists) {
            this.invoice.visit_date = this.$moment(visitRef.data().visit_date?.toDate()).format('DD/MM/YYYY HH:mm')
          }
        }
      }

      this.$bvModal.show('new-invoice-modal')
    },
    async showInvoice(invoiceId) {
      let loader = this.$loading.show({});
      try {
        let invoiceRef = await db.collection('Invoice').doc(invoiceId).get()
        this.invoice = {
          ...invoiceRef.data(),
          issue_date: this.$moment(invoiceRef.data().issue_date?.toDate()).format('DD/MM/YYYY HH:mm'),
          visit_date: this.$moment(invoiceRef.data().visit_date?.toDate()).format('DD/MM/YYYY HH:mm'),
        }
      } catch (error) {
        loader.hide()
        this.$alert('error: ', error)
        return
      }
      loader.hide()
      this.$bvModal.show('new-invoice-modal')
    },
    addProduct() {
      this.invoice.product_items.push({ price: 0, quantity: 1, discount: 0 })
    },
    addBillableItem() {
      this.invoice.billable_items.push({ price: 0, quantity: 1, discount: 0 })
    },
    showPackage() {
      this.selectedPackage = null
      this.$bvModal.show('package-modal')
    },
    handleProductChanged(index) {

      switch (this.invoice.concession_type) {
        case 'Insurance':
          this.invoice.product_items[index].price = _.find(this.prepared_products, { 'item_code': this.invoice.product_items[index].item_code }).price_insurance
          break;
        case 'Resident':
          this.invoice.product_items[index].price = _.find(this.prepared_products, { 'item_code': this.invoice.product_items[index].item_code }).price_th
          break;
        default:
          this.invoice.product_items[index].price = _.find(this.prepared_products, { 'item_code': this.invoice.product_items[index].item_code }).price_inter
          break;
      }
      this.invoice.product_items[index].item = _.find(this.prepared_products, { 'item_code': this.invoice.product_items[index].item_code })
    },
    handleBillableItemChanged(index) {

      switch (this.invoice.concession_type) {
        case 'Insurance':
          this.invoice.billable_items[index].price = _.find(this.prepared_billable_items, { 'item_code': this.invoice.billable_items[index].item_code }).price_insurance
          break;
        case 'Resident':
          this.invoice.billable_items[index].price = _.find(this.prepared_billable_items, { 'item_code': this.invoice.billable_items[index].item_code }).price_th
          break;
        default:
          this.invoice.billable_items[index].price = _.find(this.prepared_billable_items, { 'item_code': this.invoice.billable_items[index].item_code }).price_inter
          break;
      }

      this.invoice.billable_items[index].item = _.find(this.prepared_billable_items, { 'item_code': this.invoice.billable_items[index].item_code })
    },
    handleConcessionChanged() {
      for (let item of this.invoice.product_items) {
        switch (this.invoice.concession_type) {
          case 'Insurance':
            item.price = _.find(this.prepared_products, { 'item_code': item.item_code })?.price_insurance
            break;
          case 'Resident':
            item.price = _.find(this.prepared_products, { 'item_code': item.item_code })?.price_th
            break;
          default:
            item.price = _.find(this.prepared_products, { 'item_code': item.item_code })?.price_inter
            break;
        }
      }
      for (let item of this.invoice.billable_items) {
        switch (this.invoice.concession_type) {
          case 'Insurance':
            item.price = _.find(this.prepared_billable_items, { 'item_code': item.item_code })?.price_insurance
            break;
          case 'Resident':
            item.price = _.find(this.prepared_billable_items, { 'item_code': item.item_code })?.price_th
            break;
          default:
            item.price = _.find(this.prepared_billable_items, { 'item_code': item.item_code })?.price_inter
            break;
        }
      }
    },
    async handlePatientNameSelect(data) {
      let loader = this.$loading.show({})

      try {
        let patientRef = await db.collection('Patient').doc(data).get()
        let patient = {
          ...patientRef.data()
        }
        if (patient.travel_insurance) {
          let insuranceRef = await db.collection('Insurance').doc(patient.travel_insurance.id).get()
          patient.travel_insurance = {
            id: patient.travel_insurance.id,
            ...insuranceRef.data()
          }
        }

        if (patient) {
          this.invoice.HN = patient.HN
          this.invoice.patient_name = `${patient.first_name || ''} ${patient.last_name || ''}`
          if (patient.travel_insurance) {
            this.invoice.concession_type = 'Insurance'
            this.invoice.insurance_name = patient.travel_insurance?.name_en || null
            this.invoice.insurance_type = patient.travel_insurance?.type || null
            if (patient.travel_insurance.id === 'Other') {
              this.invoice.insurance_name_other = patient.travel_insurance_other
            }
          } else if (patient.is_resident || patient.is_resident) {
            this.invoice.concession_type = 'Resident'
          } else {
            this.invoice.concession_type = 'Foreigner'
          }
        }
        loader.hide()
        this.handleConcessionChanged()
      } catch (error) {
        loader.hide()
        this.$alert(`error:${error}`, null, 'error')
      }
    },
  },
  computed: {
    preparedPatients() {
      return this.patients.map(p => {
        return {
          name: `${p.first_name || ''} ${p.last_name || ''}`,
          HN: p.HN
        }
      })
    },
    totalDiscount() {
      let sum_product = _.sum(this.invoice.product_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * Number(item.price) * Number(item.discount) / 100
        } else {
          return 0
        }
      }))
      let sum_billable_items = _.sum(this.invoice.billable_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * Number(item.price) * Number(item.discount) / 100
        } else {
          return 0
        }
      }))

      return sum_product + sum_billable_items
    },
    totalAmount() {
      let invoice = this.invoice
      let sum = _.sum(invoice.product_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * (Number(item.price) - (Number(item.price) * Number(item.discount) / 100))
        } else {
          return 0
        }
      })) + _.sum(invoice.billable_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * (Number(item.price) - (Number(item.price) * Number(item.discount) / 100))
        } else {
          return 0
        }
      }))
      return sum
    },
    prepared_products() {
      return this.products.map(i => {
        return {
          item_name: i.item_name || null,
          item_type: i.item_type || null,
          item_code: i.item_code || null,
          price_th: Number(i.price_th) || null,
          price_inter: Number(i.price_inter) || null,
          price_insurance: Number(i.price_insurance) || null
        }
      })
    },
    prepared_billable_items() {
      return this.billable_items.map(i => {
        return {
          item_name: i.item_name || null,
          item_type: i.item_type || null,
          item_code: i.item_code || null,
          price_th: Number(i.price_th) || null,
          price_inter: Number(i.price_inter) || null,
          price_insurance: Number(i.price_insurance) || null
        }
      })
    },
    prepared_packages() {
      return this.packages.map(i => {
        return {
          package_name: i.package_name,
          package_code: i.package_code,
          billable_items: i.billable_items,
          product_items: i.product_items
        }
      })
    }
  }
}
</script>
