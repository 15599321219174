
export default {
  data() {
    return {
      medcert_for_insurance: {
        admitted_date: "01/01/2020",
        estimated_admission_day: null,
        chief_complain: [
          { cc_name: "diarrhea" },
          { cc_name: "weakness" },
          { cc_name: "nausea" },
          { cc_name: "fever" }
        ],
        present_illness: [{ pi_name: "present condition" }],
        patient_history: [
          { ph_name: "No known allergy" },
          { ph_name: "No known illness" }
        ],
        temp: 37,
        pr: 50,
        rr: 50,
        bp_syst: 50,
        bp_diat: 50,
        ga_check: null,
        ga: null,
        skin_check: null,
        skin: null,
        heent_check: null,
        heent: null,
        rs_check: null,
        rs: null,
        cvs_check: null,
        cvs: null,
        abdomen_check: null,
        abdomen: null,
        extrem_check: null,
        extrem: null,
        neuro_check: null,
        neuro: null,
        pe_other: null,
        initial_diagnosis: [
          {
            initial_diagnosis_name: null
          }
        ],
        medication: [{ medication_name: null }],
        laboratory: [
          {
            laboratory_name: "X-RAY",
            laboratory_detail: "Left Foot (AP, Lateral)"
          },
          { laboratory_name: "EKG", laboratory_detail: "12 Lead" },
          {
            laboratory_name: "LABORATORY",
            laboratory_detail: "CBC, BUN, Crea, E’Lyte"
          }
        ],
        follow_up_lab: "Follow up",
        follow_up_lab_list: [
          {
            follow_up_lab_name: "STOOL EXAMINATION",
            follow_up_lab_status: "waiting for the pt to collect"
          }
        ],
        plan: null
      }
    };
  },
  methods: {
    addRowList(modelGrp, model, value) {
      if (modelGrp && model) {
        this[modelGrp][model].push({});
        this[modelGrp][model + "Length"] += value;
      } else {
        this[model].push({});
        this[model + "Length"] += value;
      }
    },
    removeRowList(modelGrp, model, index) {
      if (modelGrp && model) {
        if (this[modelGrp][model].length > 1) {
          this[modelGrp][model].splice(index, 1);
        }
      }
    }
  }
};
