var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.scopedOccupation !== 'Other Occupation')?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.scopedOccupation),expression:"scopedOccupation"}],attrs:{"id":"occupation","name":"occupation"},on:{"input":function($event){return _vm.updateValue($event.target.value)},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.scopedOccupation=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","selected":"selected","disabled":"disabled"}},[_vm._v("Select Occupation")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('optgroup',{attrs:{"label":"Other Occupations:"}},[_c('option',{attrs:{"value":"Military"}},[_vm._v("- Military")]),_c('option',{attrs:{"value":"Merchant"}},[_vm._v("- Merchant")]),_c('option',{attrs:{"value":"Homemaker"}},[_vm._v("- Homemaker")]),_c('option',{attrs:{"value":"Not Applicable"}},[_vm._v("- Not Applicable")]),(_vm.prefillOccupation)?_c('option',{domProps:{"value":_vm.occupation}},[_vm._v("- "+_vm._s(_vm.occupation))]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('optgroup',{attrs:{"label":"Healthcare Practitioners and Technical Occupations:"}},[_c('option',{attrs:{"value":"Chiropractor"}},[_vm._v("- Chiropractor")]),_c('option',{attrs:{"value":"Dentist"}},[_vm._v("- Dentist")]),_c('option',{attrs:{"value":"Nutritionist"}},[_vm._v("- Nutritionist")]),_c('option',{attrs:{"value":"Optometrist"}},[_vm._v("- Optometrist")]),_c('option',{attrs:{"value":"Pharmacist"}},[_vm._v("- Pharmacist")]),_c('option',{attrs:{"value":"Physician"}},[_vm._v("- Physician")]),_c('option',{attrs:{"value":"Physician Assistant"}},[_vm._v("- Physician Assistant")]),_c('option',{attrs:{"value":"Podiatrist"}},[_vm._v("- Podiatrist")]),_c('option',{attrs:{"value":"Registered Nurse"}},[_vm._v("- Registered Nurse")]),_c('option',{attrs:{"value":"Therapist"}},[_vm._v("- Therapist")]),_c('option',{attrs:{"value":"Veterinarian"}},[_vm._v("- Veterinarian")]),_c('option',{attrs:{"value":"Health Technician"}},[_vm._v("- Health Technician")]),_c('option',{attrs:{"value":"Healthcare Practitioner"}},[_vm._v("- Healthcare Practitioner")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('optgroup',{attrs:{"label":"Business, Executive, Management, and Financial Occupations:"}},[_c('option',{attrs:{"value":"Chief Executive"}},[_vm._v("- Chief Executive")]),_c('option',{attrs:{"value":"General and Operations Manager"}},[_vm._v("- General and Operations Manager")]),_c('option',{attrs:{"value":"Manager"}},[_vm._v("- Advertising, Marketing, Promotions, Public Relations, and Sales Manager")]),_c('option',{attrs:{"value":"Operations Specialties Manager"}},[_vm._v("- Operations Specialties Manager (e.g., IT or HR Manager)")]),_c('option',{attrs:{"value":"Accountant, Auditor"}},[_vm._v("- Accountant, Auditor")]),_c('option',{attrs:{"value":"Financial Specialist"}},[_vm._v("- Financial Specialist")]),_c('option',{attrs:{"value":"Business Owner"}},[_vm._v("- Business Owner")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('optgroup',{attrs:{"label":"Architecture and Engineering Occupations:"}},[_c('option',{attrs:{"value":"Architect"}},[_vm._v("- Architect, Surveyor, or Cartographer")]),_c('option',{attrs:{"value":"Engineer"}},[_vm._v("- Engineer")]),_c('option',{attrs:{"value":"Surveyor"}},[_vm._v("- Surveyor")]),_c('option',{attrs:{"value":"Cartographer"}},[_vm._v("- Cartographer")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('optgroup',{attrs:{"label":"Education, Training, and Library Occupations:"}},[_c('option',{attrs:{"value":"Postsecondary Teacher"}},[_vm._v("- Postsecondary Teacher (e.g., College Professor)")]),_c('option',{attrs:{"value":"Teacher"}},[_vm._v("- Primary, Secondary, or Special Education School Teacher")]),_c('option',{attrs:{"value":"Student"}},[_vm._v("- Student")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('optgroup',{attrs:{"label":"Other Professional Occupations:"}},[_c('option',{attrs:{"value":"Computer Specialist"}},[_vm._v("- Computer Specialist")]),_c('option',{attrs:{"value":"Social Service Specialist"}},[_vm._v("- Counselor, Social Worker, or Other Community and Social Service Specialist")]),_c('option',{attrs:{"value":"Lawyer"}},[_vm._v("- Lawyer")]),_c('option',{attrs:{"value":"Judge"}},[_vm._v("- Judge")]),_c('option',{attrs:{"value":"Life Scientist"}},[_vm._v("- Life Scientist (e.g., Animal, Food, Soil, or Biological Scientist, Zoologist)")]),_c('option',{attrs:{"value":"Physical Scientist"}},[_vm._v("- Physical Scientist (e.g., Astronomer, Physicist, Chemist, Hydrologist)")]),_c('option',{attrs:{"value":"Religious Worker"}},[_vm._v("- Religious Worker (e.g., Clergy, Director of Religious Activities or Education)")]),_c('option',{attrs:{"value":"Social Scientist"}},[_vm._v("- Social Scientist and Related Worker")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('optgroup',{attrs:{"label":"Office and Administrative Support Occupations:"}},[_c('option',{attrs:{"value":"Financial Clerk"}},[_vm._v("- Financial Clerk")]),_c('option',{attrs:{"value":"Administrative Assistant"}},[_vm._v("- Secretary or Administrative Assistant")]),_c('option',{attrs:{"value":"Dispatching Worker"}},[_vm._v("- Material Recording, Scheduling, and Dispatching Worker")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('optgroup',{attrs:{"label":"Services Occupations:"}},[_c('option',{attrs:{"value":"Protective Service"}},[_vm._v("- Protective Service (e.g., Fire Fighting, Police Officer, Correctional Officer)")]),_c('option',{attrs:{"value":"Chef or Head Cook"}},[_vm._v("- Chef or Head Cook")]),_c('option',{attrs:{"value":"Cook or Food Preparation Worker"}},[_vm._v("- Cook or Food Preparation Worker")]),_c('option',{attrs:{"value":"Food and Beverage Serving Worker"}},[_vm._v("- Food and Beverage Serving Worker (e.g., Bartender, Waiter, Waitress)")]),_c('option',{attrs:{"value":"Personal Care and Service"}},[_vm._v("- Personal Care and Service (e.g., Hairdresser, Concierge)")]),_c('option',{attrs:{"value":"Airline Cabin Crew"}},[_vm._v("- Airline Cabin Crew")]),_c('option',{attrs:{"value":"Sales Supervisor"}},[_vm._v("- Sales Supervisor, Retail Sales")]),_c('option',{attrs:{"value":"Retail Sales Worker"}},[_vm._v("- Retail Sales Worker")]),_c('option',{attrs:{"value":"Insurance Sales Agent"}},[_vm._v("- Insurance Sales Agent")]),_c('option',{attrs:{"value":"Sales Representative"}},[_vm._v("- Sales Representative")]),_c('option',{attrs:{"value":"Real Estate Sales Agent"}},[_vm._v("- Real Estate Sales Agent")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('optgroup',{attrs:{"label":"Agriculture, Maintenance, Repair, and Skilled Crafts Occupations:"}},[_c('option',{attrs:{"value":"Construction and Extraction"}},[_vm._v("- Construction and Extraction (e.g., Construction Laborer, Electrician)")]),_c('option',{attrs:{"value":"Farming, Fishing, and Forestry"}},[_vm._v("- Farming, Fishing, and Forestry")]),_c('option',{attrs:{"value":"Installation, Maintenance and Repair"}},[_vm._v("- Installation, Maintenance, and Repair")]),_c('option',{attrs:{"value":"Production Occupations"}},[_vm._v("- Production Occupations")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('optgroup',{attrs:{"label":"Transportation Occupations:"}},[_c('option',{attrs:{"value":"Aircraft Pilot"}},[_vm._v("- Aircraft Pilot")]),_c('option',{attrs:{"value":"Motor Vehicle Operator"}},[_vm._v("- Motor Vehicle Operator (e.g., Ambulance, Bus, Taxi, or Truck Driver)")]),_c('option',{attrs:{"value":"Boat Driver"}},[_vm._v("- Boat Driver, Coxswain")])])
}]

export { render, staticRenderFns }