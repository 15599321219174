<template>
  <b-modal :id="id" title="Special Orders" size="xl" hide-footer>
    <div class="mb-2">
      <div class="container-fluid">
        <div class="content-header">
          Special Orders ({{value.special_orders.length}})
        </div>
        <div class="content-order">
          <div v-for="(item, index) in value.special_orders" :key="index">
            <div>
              <div class="row d-flex">
                <div class="col-3">
                  <div class="label">Time</div>
                  <MazPicker
                    v-model="item.date_time"
                    format="DD/MM/YYYY HH:mm"
                    formatted="DD/MM/YYYY HH:mm"
                  />
                </div>
                <div class="col-3">
                  <div class="label">Special Orders</div>
                  <MazSelect
                    v-model="item.special_order"
                    search
                    :options="special_orders.map(o => {
                      return { value: o, label: o }
                    })"
                  />
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Other"
                    v-model="item.other_special_order"
                    v-if="item.special_order === 'Other'"
                  />
                </div>
                <div class="col-4">
                  <div class="label">Note</div>
                  <MazInput
                    v-model="item.note"
                  ></MazInput>
                </div>
                <div class="ml-auto col-1">
                  <button class="btn btn-light" @click="value.special_orders.splice(index, 1)"><i class="fas fa-trash"></i></button>
                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-outline-success btn-block mt-2" @click="value.special_orders.push({date_time: new Date()})">
            <i class="fas fa-plus"></i> Order
          </button>
        </div>
      </div>
    </div>
    <div class="mb-2">
      <div class="container-fluid">
        <div class="content-header">
          Special Orders ({{value.notes.length}})
        </div>
        <div class="content-order">
          <div v-for="(item, index) in value.notes" :key="index">
            <div>
              <div class="row d-flex">
                <div class="col-3">
                  <div class="label">Time</div>
                  <MazPicker
                    v-model="item.date_time"
                    format="DD/MM/YYYY HH:mm"
                    formatted="DD/MM/YYYY HH:mm"
                  />
                </div>
                <div class="col-7">
                  <div class="label">Note</div>
                  <MazInput
                    v-model="item.note"
                  ></MazInput>
                </div>
                <div class="ml-auto col-1">
                  <button class="btn btn-light" @click="value.notes.splice(index, 1)"><i class="fas fa-trash"></i></button>
                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-outline-success btn-block mt-2" @click="value.notes.push({date_time: new Date()})">
            <i class="fas fa-plus"></i> Order
          </button>
        </div>
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary mr-2" @click.prevent="confirmOrder">Confirm Order</button>
      <button class="btn btn-danger mr-2" @click.prevent="$bvModal.hide(id)">Cancel</button>
    </div>
  </b-modal>
</template>

<script>
import special_orders from '../../../assets/special_order.json'
import opdMixins from "../../../mixins/opd";

export default {
  mixins: [opdMixins],
  data() {
    return {
      id: 'order-special',
      special_orders
    }
  },
  props: {
    value: {
      default: () => {
        return {
          special_orders: [{date_time: new Date()}],
          notes: [{date_time: new Date()}]
        }
      }
    },
    onClose: {
      default: () => {}
    },
    onConfirm: {
      default: () => {}
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === this.id)
      this.onClose()
    })
  },
  methods: {
    confirmOrder() {
      this.$bvModal.hide(this.id)
      this.onConfirm()
    }
  }
}
</script>

<style lang="scss" scoped>
.content-header {
  padding: 15px;
  background-color: green;
  color: white;
  font-weight: 600;
}

.content-info {
  background-color: #adadad;
  padding: 15px;
}
</style>