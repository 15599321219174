<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <b-nav class="mt-2" tabs justified>
          <b-nav-item-dropdown text="ADMISSION SHEETS">
            <b-dropdown-item :to="{
              name: 'Admission Form',
              params: { id: $route.params.id, admissionId: $route.params.admissionId }
            }">
              Admission Form
            </b-dropdown-item>
            <b-dropdown-item :to="{
              name: 'Consent Form',
              params: { id: $route.params.id, admissionId: $route.params.admissionId }
            }">
              Consent Form
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Doctor Sheet">
            <b-dropdown-item :to="{
              name: 'Progress Note List',
              params: { id: $route.params.id, admissionId: $route.params.admissionId }
            }">
              Progress Note List
            </b-dropdown-item>
            <b-dropdown-item :to="{
              name: `Physician's Order Sheet`,
              params: { id: $route.params.id, admissionId: $route.params.admissionId }
            }">
              Physician's Order Sheet
            </b-dropdown-item>
            <b-dropdown-item :to="{
              name: `View Lab`,
              params: { id: $route.params.id, admissionId: $route.params.admissionId }
            }">
              View Lab
            </b-dropdown-item>
            <b-dropdown-item :to="{
              name: `View Investigation`,
              params: { id: $route.params.id, admissionId: $route.params.admissionId }
            }">
              View Investigation
            </b-dropdown-item>
            <b-dropdown-item :to="{
              name: `View History`,
              params: { id: $route.params.id, admissionId: $route.params.admissionId }
            }">
              View History
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item
            :active="$route.name === 'Patient Admission IPD doc'"
            :to="{
              name: 'Patient Admission IPD doc',
              params: { id: $route.params.id, admissionId: $route.params.admissionId }
            }"
          >
            NURSING SHEETS
          </b-nav-item>
          <b-nav-item
            :active="$route.name === 'Patient Admission IPD doc'"
            :to="{
              name: 'Patient Admission IPD doc',
              params: { id: $route.params.id, admissionId: $route.params.admissionId }
            }"
          >
            CERTIFICATES
          </b-nav-item>
        </b-nav>
      </div>
      <div class="col-6">
        <div class="d-flex mt-4">
          <button class="btn btn-secondary ml-auto mr-2">DOCUMENTS</button>
          <button class="btn btn-secondary">VIEW PATIENT DATA</button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <router-view style="margin-top: 15px;" :patient="patient"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['patient']
}
</script>

<style lang="scss" scoped>
.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}
</style>