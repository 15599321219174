<template>
  <div>
    <b-button variant="primary" v-b-modal.new-visit
      ><i class="fas fa-plus"></i> Create New Visit</b-button
    >
    <b-modal id="new-visit" title="Create New Visit" hide-footer>
      <div class="mt-2">
        <label>Select Branch</label>
        <select class="form-control" v-model="selectedBranch">
          <option value="">--- select branch ---</option>
          <option
            v-for="b in $store.state.branches"
            :key="b.id"
            :value="Number(b.id)"
            >{{ b.name_en }}</option
          >
        </select>

      </div>
      <div class="mt-2">
        <MazPicker v-model="visit_date" format="DD/MM/YYYY HH:mm" formatted="DD/MM/YYYY HH:mm" placeholder="Visit Date" />
      </div>
<!-- 
      <div class="mt-2">
        <select v-modal="visit_type" class="form-control">
          <option value="Follow up">Follow up</option>
          <option value="New Patient">New Patient</option>
        </select>
      </div> -->
      <button
        class="btn btn-block btn-primary mt-2"
        @click.prevent="newVisit($route.params.id, selectedBranch, selectedDept, $moment(visit_date,'DD/MM/YYYY HH:mm').toDate(), 'Follow up')"
      >
        <i class="fas fa-check"></i> Create New Visit
      </button>
    </b-modal>
  </div>
</template>

<script>
import visit from '../mixins/visit'
export default {
  mixins: [ visit ],
  props: {
    visit_type: {
      default: 'Follow up'
    }
  },
  data() {
    return {
      selectedDept: 1,
      selectedBranch: 1,
      visit_date: this.$moment().format('DD/MM/YYYY HH:mm')
    };
  }
};
</script>
