<template>
  <div class="container-fluid">
    <vue-good-table
      :columns="columns"
      :rows="patients"
      :pagination-options="{
        enabled: true
      }"
    >
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.label =='Daily Meds Summary'">
            <div class="text-center">Daily Meds</div>
            <div class="text-center">Summary</div>
        </span>
        <span v-else-if="props.column.label =='V/S I/O Diet Endorsement'">
            <div class="text-center">V/S</div>
            <div class="text-center">I/O</div>
            <div class="text-center">Diet</div>
            <div class="text-center mt-2">Endorsement</div>
        </span>
        <span v-else-if="props.column.label =='Admission Date/Time Discharge Plan'">
            <div class="text-center">Admission</div>
            <div class="text-center">Date/Time</div>
            <div class="text-center mt-4">Discharge Plan</div>
        </span>
        <span v-else-if="props.column.label =='Finance Passport Travel Plan'">
            <div class="text-center">Finance</div>
            <div class="text-center">Passport</div>
            <div class="text-center mt-4">Travel Plan</div>
        </span>
        <span v-else>
            {{props.column.label}}
        </span>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'index'">
          {{ props.row.originalIndex + 1 }}
        </span>
        <div v-else-if="props.column.field == 'details'">
          <div class="text-primary">
            <b>RM: {{props.formattedRow.details.RM}}</b>
          </div>
          <div class="text-dark">
            <b>{{ props.formattedRow.details.name }}</b>
          </div>
          <div class="text-dark">
            <b>{{ props.formattedRow.details.age }}</b>
          </div>
          <div class="text-muted">
            <i>AN: {{ props.formattedRow.details.AN }}</i>
          </div>
        </div>
        <div v-else-if="props.column.label == 'Diagnosis'">
          <div
            class="esi-box"
            :style="{
              color: props.formattedRow.details.esi == 5 ? 'dark' : 'white',
              'background-color': getEsiColor(props.formattedRow.details.esi)
            }"
          >
            ESI level {{ props.formattedRow.details.esi }}
          </div>
          <div class="text-muted">
            <i>{{ props.formattedRow.diagnosis }}</i>
          </div>
        </div>
        <div v-else-if="props.column.field == 'allergies'">
          <div class="text-danger" v-for="item of props.formattedRow.allergies" :key="item"><b>{{ item }}</b></div>
        </div>
        <div v-else-if="props.column.field == 'active_problems'">
          <ul>
            <li v-for="item of props.formattedRow.active_problems" :key="item">
              <div ><b>{{ item }}</b></div>
            </li>
          </ul>
        </div>
        <div v-else-if="props.column.field == 'endorsement'">
          <div class="text-center"><i>{{props.formattedRow.endorsement['v/s']}}</i></div>
          <div class="text-center"><i>{{props.formattedRow.endorsement['i/o']}}</i></div>
          <div class="text-center"><b>{{props.formattedRow.endorsement.diet}}</b></div>
          <input type="text" class="form-control text-center" readonly :value="props.formattedRow.endorsement.endorsement">
        </div>
        <div v-else-if="props.column.field == 'admission_date'">
          <div class="text-center">{{props.formattedRow.admission_date.date}}</div>
          <div class="text-center">{{props.formattedRow.admission_date.time}}</div>
          <input type="text" class="form-control" readonly :value="props.formattedRow.endorsement.discharge_plan">
        </div>
        <div v-else-if="props.column.field == 'daily_meds_summary'">
          <div class="text-danger">{{props.formattedRow.daily_meds_summary[0]}}</div>
          <div class="text-danger">{{props.formattedRow.daily_meds_summary[1]}}</div>
          <div class="text-primary">{{props.formattedRow.daily_meds_summary[2]}}</div>
          <div class="text-primary">{{props.formattedRow.daily_meds_summary[3]}}</div>
          <div class="text-primary">{{props.formattedRow.daily_meds_summary[4]}}</div>
          <div class="text-primary">{{props.formattedRow.daily_meds_summary[5]}}</div>
        </div>
        <div v-else-if="props.column.field == 'finance_info'">
          <div class="text-center"><b>{{props.formattedRow.finance_info.finance}}</b></div>
          <div class="text-center mb-2">{{props.formattedRow.finance_info.passport}}</div>
          <div class="text-center">{{props.formattedRow.finance_info.travel_plan.date}}</div>
          <div class="text-center">{{props.formattedRow.finance_info.travel_plan.time}}</div>
        </div>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        {
          label: "No.",
          field: "id"
        },
        {
          label: "Patient Details",
          field: "details"
        },
        {
          label: "Diagnosis",
          field: "diagnosis"
        },
        {
          label: "Allergy",
          field: "allergies"
        },
        {
          label: "Active Problem",
          field: "active_problems"
        },
        {
          label: "Daily Meds Summary",
          field: "daily_meds_summary"
        },
        {
          label: "V/S I/O Diet Endorsement",
          field: "endorsement"
        },
        {
          label: "Admission Date/Time Discharge Plan",
          field: "admission_date"
        },
        {
          label: "Finance Passport Travel Plan",
          field: "finance_info"
        }
      ],
      patients: []
    };
  },
  methods: {
    onRowClick(params) {
      this.$router.push(`/patient/${params.row.hn}`);
    },
    getEsiColor(esi) {
      switch (esi) {
        case 1:
          return "#CB0000";
          break;
        case 2:
          return "#EA9A99";
          break;
        case 3:
          return "#F1C331";
          break;
        case 4:
          return "#629B49";
          break;
        case 5:
          return "#FFFFFF";
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.esi-box {
  padding: 5px;
  font-weight: 800;
  border-radius: 0.25em;
  border: 1px solid #adadad;
}
</style>
