<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 mt-2">
        <div class="card shadow">
          <div class="card-body">
            <h5 class="text-primary">Zone</h5>
            <table class="table table-bordered">
              <thead>
                <th>ID</th>
                <th>Name</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr v-for="(row, index) in zones" :key="index">
                  <td>{{row.id}}</td>
                  <td>{{row.name}}</td>
                  <td><button class="btn btn-primary" @click="showZone(row)"><i class="fas fa-edit"></i></button></td>
                </tr>
              </tbody>
            </table>
            <button class="btn btn-primary btn-block mt-2" @click="showZone(null)">
              <i class="fas fa-plus"/> add zone
            </button>
          </div>
        </div>
      </div>
    </div>
    
    <b-modal id="zone-modal" ref="zone-modal" hide-footer title="Zone" size="lg">
      <MazInput
        class="mt-2"
        :placeholder="'Zone ID'"
        v-model="zone.id"
        :disabled="mode === 'edit'"
      />
      <MazInput
        class="mt-2"
        :placeholder="'Zone Name'"
        v-model="zone.name"
      />
      <button class="btn btn-success btn-block mt-2" @click="saveZone"><i class="fas fa-save"></i> Save</button>
    </b-modal>
  </div>
</template>

<script>
import {db} from '../../db'
export default {
  data() {
    return {
      zones: [],
      zone: {
        id: null,
        name: null,
        hotels: []
      },
      mode: 'create'
    }
  },
  firestore() {
    return {
      zones: db.collection('Zone').orderBy("id")
    }
  },
  methods: {
    showZone(zone) {
      if(zone) {
        this.mode = 'edit'
        this.zone.id = zone.id
        this.zone.name = zone.name
        this.zone.hotels = zone.hotels
      }else{
        this.mode = 'create'
        this.zone = {
          id: null,
          name: null,
          hotels: []
        }
      }

      this.$bvModal.show('zone-modal')
    },
    async saveZone() {
      let loader = this.$loading.show({})
      try {
        if (this.mode === 'create') {
          await db.collection('Zone').doc(this.zone.id).set({
            id: this.zone.id,
            name: this.zone.name || null,
            hotels: this.zone.hotels || [],
          })
        }
        if (this.mode === 'edit') {
          await db.collection('Zone').doc(this.zone.id).update({
            name: this.zone.name || null,
            hotels: this.zone.hotels || [],
          })
        }
        loader.hide()
        this.$bvModal.hide('zone-modal')
        this.$alert("Success!", null, "success");
      } catch (error) {
        loader.hide()
        this.$alert("Error!", error.message, "error");
        console.log(error)
      }
      loader.hide()
      this.$bvModal.hide('zone-modal')
    }
  }
}
</script>

<style>

</style>