<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-8 col-sm-12">
        <table class="table table-bordered table-striped">
          <thead>
            <th colspan="4" class="text-center">Insurance Amount</th>
          </thead>
          <thead>
            <th>Name</th>
            <th>Insurance</th>
            <th>Time</th>
            <th class="text-right">Amount</th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in _.filter(this.payments, p => p.insurance>0)" :key="'inter'+index">
              <td><a :href="`/print/receipt/${item.payment_id}`" target="_blank" rel="noopener noreferrer">{{item.patient_name}}</a></td>
              <td>{{item.insurance_name}} <span v-if="item.insurance_name==='Other'">({{ item.insurance_name_other }})</span></td>
              <td>{{item.issue_date.toDate() | moment('DD/MM/YYYY HH:mm a')}}</td>
              <td class="text-right">{{item.insurance.toLocaleString()}}</td>
            </tr>
            <tr>
              <td colspan="3">Total</td>
              <td class="text-right">{{ _.sumBy(_.filter(this.payments, p => p.insurance>0), i => i.insurance).toLocaleString()}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-4 col-sm-12">
        <h5 class="text-primary">Insurance Amount</h5>
        <Doughnut  :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'" :chart-id="'insurance-graph'"
          :width="400" :height="400" />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-8 col-sm-12">
        <table class="table table-bordered table-striped">
          <thead>
            <th colspan="4" class="text-center">Insurance Cases</th>
          </thead>
          <thead>
            <th>Name</th>
            <th class="text-right">Cases</th>
            <th class="text-right">Amount</th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in insuranceData" :key="'inter'+index">
              <td>{{item.insurance_name}}</td>
              <td class="text-right">{{item.count.toLocaleString()}}</td>
              <td class="text-right">{{item.amount.toLocaleString()}}</td>
            </tr>
            <tr>
              <td colspan="2">Total</td>
              <td class="text-right">{{ _.sumBy(insuranceData, d => d.amount).toLocaleString()}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-4 col-sm-12">
        <h5 class="text-primary">Insurance Cases</h5>
        <Doughnut  :chart-options="chartOptions" :chart-data="quantityChart" :dataset-id-key="'label'" :chart-id="'insurance-graph'"
          :width="400" :height="400" />
      </div>
    </div>
  </div>
</template>

<script>
import color from '../../assets/color.json'
import {
  Doughnut 
} from 'vue-chartjs/legacy'

export default {
  props: ['payments'],
  components: {
    Doughnut 
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        indexAxis: 'x',
        plugins: {
          datalabels: {
            color: '#000',
            anchor: 'center',
            align: 'center',
            formatter: value => {
              return value.toLocaleString()
            }
          },
        }
      },
      preparedPayments: []
    }
  },
  computed: {
    insuranceData() {
      let data = []

      for (let item of this.payments) {
        
        if(item.insurance > 0) {
          let findIndex = this._.findIndex(data, {insurance_name: item.insurance_name})
          if(findIndex>-1) {
            data[findIndex].amount += item.insurance
            data[findIndex].count ++
          }else{
            data.push({
              insurance_name: item.insurance_name,
              amount: 0+item.insurance,
              count: 1,
              index: data.length
            })
          }
        }
      }

      return data

    },
    chartData() {
      let data = []

      for (let item of this.payments) {
        
        if(item.insurance > 0) {
          let findIndex = this._.findIndex(data, {insurance_name: item.insurance_name})
          if(findIndex>-1) {
            data[findIndex].amount += item.insurance
            data[findIndex].count ++
          }else{
            data.push({
              insurance_name: item.insurance_name,
              amount: 0+item.insurance,
              count: 1,
              index: data.length
            })
            console.log({item})
          }
        }
      }

      let chartData = {
        labels: data.map(d => d.insurance_name),
        datasets: [{
          label: 'Other',
          data: data.map(d => d.amount),
          backgroundColor: data.map(d => color[(d.index+2)&7])
        }]
      }

      return chartData
    },
    quantityChart() {
      let data = []

      for (let item of this.payments) {
        
        if(item.insurance > 0) {
          let findIndex = this._.findIndex(data, {insurance_name: item.insurance_name})
          if(findIndex>-1) {
            data[findIndex].amount += item.insurance
            data[findIndex].count ++
          }else{
            data.push({
              insurance_name: item.insurance_name,
              amount: 0+item.insurance,
              count: 1,
              index: data.length
            })
            console.log({item})
          }
        }
      }

      let chartData = {
        labels: data.map(d => d.insurance_name),
        datasets: [{
          label: 'Other',
          data: data.map(d => d.count),
          backgroundColor: data.map(d => color[(d.index+4)%7])
        }]
      }

      return chartData
    }
  }
}
</script>