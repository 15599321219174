<template>
  <b-modal :id="id" title="Order Medicine" size="xl" hide-footer>
    <div class="mb-2">
      <div class="container-fluid">
        <div v-for="(item, index) in value" :key="index">
          <div class="row d-flex">
            <div class="col-3">
              <div class="label">Time</div>
              <MazPicker
                v-model="item.med_supply_date_time"
                format="DD/MM/YYYY HH:mm"
                formatted="DD/MM/YYYY HH:mm"
              />
            </div>
            <div class="col-3">
              <div class="label">Medicine / Supply</div>
              <MazSelect
                v-model="item.med_supply_medicine"
                search
                :options="
                  [...$store.state.dd_medicine.map(item => {
                    return {
                      label: `${item.brand_name} (${item.generic_name})`,
                      value: item.brand_name
                    };
                  }), {label: 'Other', value: 'Other'}]
                "
              />
              <input
                type="text"
                class="form-control mt-2"
                placeholder="Other"
                v-model="item.med_supply_other_input"
                v-if="item.med_supply_medicine === 'Other'"
              />
            </div>
            <div class="col-1">
              <div class="label">Amount</div>
              <MazInput
                placeholder=""
                v-model="item.med_supply_amount"
              ></MazInput>
            </div>
            <div class="col-2">
              <div class="label">Unit</div>
              <MazSelect
                placeholder="select unit"
                v-model="item.med_supply_unit"
                search
                :options="
                  units.map(item => {
                    return {
                      label: item,
                      value: item
                    };
                  })
                "
              />
            </div>
            <div class="col-3">
              <div class="label">Dosage</div>
              <MazInput
                placeholder="enter dosage"
                v-model="item.med_supply_dosage"
              ></MazInput>
            </div>
            <div class="ml-auto col-1">
              <button class="btn btn-light" @click="value.splice(index, 1)"><i class="fas fa-trash"></i></button>
            </div>
          </div>
        </div>
        <button class="btn btn-outline-success btn-block mt-2" @click="value.push({med_supply_date_time: new Date()})">
          <i class="fas fa-plus"></i> Order
        </button>
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary mr-2" @click.prevent="confirmOrder">Confirm Order</button>
      <button class="btn btn-danger mr-2" @click.prevent="$bvModal.hide(id)">Cancel</button>
    </div>
  </b-modal>
</template>

<script>
import opdMixins from "../../../mixins/opd";
const units = [
  'Unit',
  'Tab',
  'Cab',
  'ml',
  'mg'
]
export default {
  mixins: [opdMixins],
  data() {
    return {
      id: 'order-medicine',
      units
    }
  },
  props: {
    value: {
      default: () => {
        return [{med_supply_date_time: new Date()}]
      }
    },
    onClose: {
      default: () => {}
    },
    onConfirm: {
      default: () => {}
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === this.id)
      this.onClose()
    })
  },
  methods: {
    confirmOrder() {
      this.$bvModal.hide(this.id)
      this.onConfirm()
    }
  }
}
</script>