<template>
  <div>
    <div class="row p-2">
      <div class="col-12">
        <h3 class="text-primary text-center">Time</h3>
        <hr>
      </div>
      <div class="col-12">
        <div class="d-flex">
          <label>between: </label>
          <MazPicker v-model="from" clearable no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" />
          <label>and</label>
          <MazPicker v-model="to" clearable no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" />
        </div>
      </div>
    </div>

    <h3 class="text-center">Tourist Range of stay chart</h3>
    <div class="row p-2">
      <div class="col-6 mt-2">
        <table class="table table-bordered">
          <thead>
            <th></th>
            <th># of Patients</th>
          </thead>
          <tr v-for="(label, index) in chartData.labels" :key="label">
            <td>{{  label  }}</td>
            <td>{{  chartData.datasets[0].data[index]  }}</td>
          </tr>
          <tr>
            <td>SUM</td>
            <td>{{ chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
          </tr>
        </table>
      </div>
      <div class="col-6 mt-2">
        <Pie :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'"
          :chart-id="'patients_number_chart'" :width="400" :height="400" />
      </div>
    </div>


  </div>
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'
import color from '../../assets/color.json'
import Chart from 'chart.js/auto';

const length_of_stay = [
  {
    label: 'less than 1 day',
    min: 0,
    max: 1
  },
  {
    label: '1-2 Nights',
    min: 1,
    max: 2
  },
  {
    label: '3 Nights  -  1 Week',
    min: 2,
    max: 7
  },
  {
    label: '1 Week - 1 Month',
    min: 7,
    max: 30
  },
  {
    label: 'More than 1 Month',
    min: 7,
    max: 999
  },
]

export default {
  props: ['patients'],
  components: {
    Pie
  },
  data() {
    return {
      from: this.$moment().add(-1, 'month').format('DD/MM/YYYY'),
      to: this.$moment().format('DD/MM/YYYY'),
      chartOptions: {
        responsive: true
      }
    }
  },
  computed: {
    chartData() {

      let filteredPatients = []

      let fromMoment = this.$moment(this.from, 'DD/MM/YYYY')
      let toMoment = this.$moment(this.to, 'DD/MM/YYYY')

      for(let p of this.patients) {
        if (p.created_at && typeof p.created_at !== 'string') {
          if (
              this.$moment(p.created_at.toDate()).isSameOrBefore(toMoment) && 
              this.$moment(p.created_at.toDate()).isSameOrAfter(fromMoment)
            ) filteredPatients.push(p)
        }
      }

      //label
      let labels = length_of_stay.map(l => l.label)
      let data = [0, 0, 0, 0, 0]

      for (let p of filteredPatients) {
        if (p.arrival_date && p.departure_date) {
          let days_stay = this.$moment(p.departure_date.toDate()).diff(this.$moment(p.arrival_date.toDate()), 'day')

          length_of_stay.forEach((item, index) => {
            if(days_stay >=  item.min && days_stay < item.max) data[index]++
          })
        }
      }

      let sortedData = []
      for(let i = 0;  i < data.length; i++) {
        sortedData.push({
          label: labels[i],
          value: data[i]
        })
      }

      return {
        labels: this._.orderBy(sortedData, 'value', 'desc').map( obj => obj.label),
        datasets: [
          {
            backgroundColor: color,
            data: this._.orderBy(sortedData, 'value', 'desc').map( obj => obj.value)
          }
        ]
      }
    }
  }
}
</script>