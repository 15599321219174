<template>
  <div class="container-fluid">
    <patient-info :esi="true" :patient="patient"></patient-info>
    <div class="container-fluid mt-2">
      <b-nav class="row" tabs v-if="!$route.meta.print">
        <b-nav-item :active="(currentMenu === menu.name)" v-for="menu in menus" :key="menu.name" @click="(currentMenu = menu.name)" class="mt-2">{{ menu.name }}</b-nav-item>
      </b-nav>

      <patient-invoice
        :patient="patient"
        v-if="currentMenu === 'Invoice'"
        @displayPaymentModal="displayPaymentModal"
        @displayNewPaymentModal="displayNewPaymentModal"
      />

      <patient-payment
        :patient="patient"
        v-if="currentMenu === 'Payment'"
        @displayPaymentModal="displayPaymentModal"
      />

      <patient-payment-modal
        :payment="payment"
        :total_invoiced="total_invoiced"
        :payment_applied="payment_applied"
        :total_outstanding="total_outstanding"
        @displayPaymentModal="displayPaymentModal"
      />
    </div>
  </div>
</template>

<script>
import { db, Timestamp } from '../../../db'
import { getTotalPaymentApplied, getTotalOutstanding } from '../../../helper/payment'

import PatientInvoice from './PatientInvoice.vue'
import PatientPayment from './PatientPayment.vue'

export default {
  props: ["patient"],
  components: {
    'patient-invoice': PatientInvoice,
    'patient-payment': PatientPayment,
  },
  data() {
    return {
      currentMenu: 'Invoice',
      menus: [
        { name: 'Invoice' },
        { name: 'Payment' },
      ],
      payment: {
        payment_id: null,
        issue_date: this.$moment(new Date(), 'HH:mm DD/MM/YYYY').toDate(),
        HN: null,
        VN: null,
        insurance_name: null,
        insurance_name_other: null,
        patient_name: null,
        cash: 0,
        credit: 0,
        credit_bank_name: null,
        insurance: 0,
        other: 0,
        internet_banking: 0
      },
      total_invoiced: 0,
      payment_applied: 0,
      total_outstanding: 0,
    }
  },
  methods: {
    async displayPaymentModal(paymentId = null) {
      const loader = this.$loading.show({})

      try {
        let paymentRef = await db.collection('Payment').doc(paymentId).get()
        this.payment = {
          ...paymentRef.data()
        }
        let invoiceRef = await db.collection('Invoice').doc(this.payment.invoice_id).get()
        let invoice = {
          ...invoiceRef.data()
        }
        // console.log(invoice)
        this.total_invoiced = invoice.total_invoiced
        this.payment_applied = await getTotalPaymentApplied(invoice)
        this.total_outstanding = await getTotalOutstanding(invoice)
      } catch (error) {
        loader.hide()
        this.$alert('error: ', error)
        return
      }
      loader.hide()
      this.$bvModal.show('new-patient-payment-modal')
    },
    async displayNewPaymentModal(invoice) {
      const loader = this.$loading.show({})
      this.payment = {
        payment_id: null,
        invoice_id: invoice.invoice_id,
        issue_date: Timestamp.fromDate(new Date()),
        HN: invoice.HN || null,
        VN: invoice.VN || null,
        patient_name: invoice.patient_name || null,
        billed_to: invoice.billed_to || null,
        cash: 0,
        credit: 0,
        insurance: 0,
        other: 0,
        internet_banking: 0,
        concession_type: invoice.concession_type,
        insurance_name: invoice.insurance_name || null,
        insurance_type: invoice.insurance_type || null,
        insurance_name_other: invoice.insurance_name_other || null,
        practitioner: invoice.practitioner,
        type: invoice.type,
        note: invoice.note || null,
        extra_charge: 0.03
      }
      this.total_invoiced = invoice.total_invoiced
      this.payment_applied = await getTotalPaymentApplied(invoice)
      this.total_outstanding = await getTotalOutstanding(invoice)
      loader.hide()

      this.$bvModal.show('new-patient-payment-modal')
    }
  }
}
</script>