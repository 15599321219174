var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex"},[_vm._m(0),_c('div',{staticClass:"detail-box text-center flex-5 bg-gray"},[_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"margin-top":"100px"}},[_vm._m(1),_c('div',{staticClass:"ml-2",staticStyle:{"width":"270px"}},[_c('p',{staticClass:"ul-solid"}),_c('span',{staticClass:"text-muted mr-3"},[_vm._v(_vm._s(_vm.form.name_th))]),_c('div',{staticClass:"d-flex text-muted"},[_vm._m(2),_c('div',{staticClass:"ml-5"},[_c('span',[_vm._v(_vm._s(_vm.form.name_en))])])]),_c('div',{staticClass:"d-flex text-muted"},[_vm._m(3),_c('div',{staticClass:"ml-5"},[_c('span',[_vm._v(_vm._s(_vm.form.license_no))])])]),_c('div',{staticClass:"d-flex text-muted"},[_vm._m(4),_c('div',{staticClass:"ml-5"},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.today,'DD/MM/YYYY')))])])])]),_vm._m(5)])])]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stamp-box flex-3"},[_c('h4',{staticClass:"text-center",staticStyle:{"margin-top":"270px"}},[_vm._v("STAMP")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("Attending Physician")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-4 text-left"},[_c('span',[_vm._v("Dr.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-4 text-left"},[_c('span',[_vm._v("Medical Lic. No.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-4 text-left"},[_c('span',[_vm._v("Date:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-2"},[_c('p',{staticClass:"text-muted"},[_vm._v("MD")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wmc-addr-box bg-gray"},[_c('div',[_c('p',[_c('b',[_vm._v("Hospital")]),_vm._v(" 125/ 131-133 M.7, Phiphi Island, T. Aonang, MuangKrabi, Krabi, Thailand, 81210 ")]),_c('p',[_c('b',[_vm._v("Tel. No.")]),_vm._v(" +66- 95-424-0444 ")]),_c('p',[_c('b',[_vm._v("E-mail:")]),_vm._v(" insurance@worldmedcenter.com ")])])])
}]

export { render, staticRenderFns }