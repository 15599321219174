<template>
  <div class="container">
    <div class="card shadow">
      <div class="card-body">
        <h3 class="text-primary text-center">Welcome to Worldmed Center</h3>
        <hr />
        <div class="row">
          <div class="col-12">
            <div class="welcome" :style="`background-image: url('/images/worldmed.jpg')`"></div>
          </div>
        </div>

        <div class="mt-4">
          <h3 class="text-primary">worldmedapp v.{{$store.getters.current_version}}</h3>
          <hr>
          <div class="mt-2" v-for="v in versions" :key="v.date">
            <h5>{{v.date}}</h5>
            <ul>
              <li v-for="(update, index) in v.updates" :key="index">{{update}}</li>
            </ul>
            <hr>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import versions from '../assets/versions.json'
export default {
  data() {
    return {
      versions
    }
  }
}
</script>

<style lang="scss" scoped>
.welcome {
  border-radius: 0.25em;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
  width: 100%;
}
</style>
